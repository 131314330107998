// Import Swiper React components
import React from "react";
// Import css files
import styled from "@emotion/styled";
import AwesomeSlider from "react-awesome-slider";

import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/styles.css";

import * as ui from "../../../styles/uiVars";

import withAutoplay from "react-awesome-slider/dist/autoplay";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const SliderVideo = styled.div`
  position: absolute;
  /* padding: 1em; */
  /* margin: 1em; */
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 0;

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0;
    margin: 0;
  }
`;
export const SliderContentContainerLow = styled.div`
  position: absolute;
  padding: 1em;
  margin: auto;
  top: 2em;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 640px) {
    max-width: 50%;
    top: 0;
  }
`;

export const SliderContentContainer = styled.div`
  position: absolute;
  padding: 1em;
  margin: 1em;
  top: 1.5em;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${ui.tablet}) {
    top: 0.15em;
    padding: 0;
    /* display: none; */
  }
`;
export const SlideText = styled.div`
  /* background: #135c74; */
  background: linear-gradient(to right, #023f2e, #171b65);
  font-family: Arial, Helvetica, sans-serif;
  max-width: 270px;
  padding: 1.6em;
  border-radius: 2px;
  margin: 0.5em 0;
  color: white;
  line-height: 1.2;
  font-size: 1.12em;
  font-weight: 800;
  text-align: left;
  border: 1px solid #ffffff;

  &:hover {
    background: #09965c;
  }

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0.42em 0.54em;
    font-size: 0.8em;
    line-height: 1.3;
    ${({ hideMobile }) =>
      hideMobile &&
      `
      display: none;
      `}
  }
`;

export const SlideTextHeader = styled(SlideText)`
  background: #023f2e;
  background: #171b65;
  background: linear-gradient(to right, #00253c, #1f26aa);
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 1em;

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0.32em 0.54em;
    font-size: 0.8em;
    line-height: 1.3;
  }
`;

export const SlideLink = styled.a`
  color: #f7d809;
  padding: 0 0.42em;
`;

export const SlideTextFeature = styled(SlideText)`
  max-width: 270px;
  background: #8c7505;
  background: #403602;
  background: #00253c;
  background: linear-gradient(to right, #00253c, #171b65);
  color: #fefefe;
  padding: 1.2em;
  line-height: 1.42;
  text-align: left;
  border: 1px solid #ffffff;
  &:hover {
    color: black;
    font-weight: 900;
    background: #f7d809;
  }
  ${({ hideMobile }) =>
    hideMobile &&
    `
    @media screen and (max-width: 640px){
      display: none;
    }
  `}

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0.2em 0.4em;
    font-size: 0.8em;
    line-height: 1.3;
  }
`;

export const SliderContainer = styled.div`
  /* max-width: 80vw; */
  /* height: 40vw; */
  /* margin: 0.25em; */
  margin-top: 0.5em;
  max-height: 100vh;
  padding: 0.61em 0;
  padding-bottom: 2.5em;
  @media screen and (max-width: 600px) {
    /* padding: 0; */
    /* margin: 1em 0; */
  }
  .awssld {
    --content-background-color: #00000030;
    --organic-arrow-color: #030f31;
    --control-bullet-color: #2d3985;
    --control-bullet-active-color: #1d1a65;
    --slider-height-percentage: 50%;
  }
  .awssld__controls button {
    z-index: 11;
  }

  @media screen and (max-width: ${ui.desktop}) {
    .awssld {
      --slider-height-percentage: 60%;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    /* padding: 0;
  margin: 0;
  .awssld {
  } */
  }
`;

export const SliderImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StartTracking = styled.div`
  background: #ff5500;
  background: #d15b01;
  background: #2b3bd3;
  /* border-radius: 2px; */
  padding: 1em 0.4em;
  margin-bottom: 1em;
  padding: 0.5em 0.8em;
  width: 100%;
  height: 30px;
  max-width: 150px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  /* font-family: Metropolis, sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.13em;
  line-height: 1;
  z-index: 4;
  color: #ffffff;

  cursor: pointer;
  border: 1px solid #ffffff;

  &:hover {
    background: #ff5500;
  }

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0.2em 0.4em;
    font-size: 0.8em;
  }
`;

export const HomeScroller = ({ goToSignUp }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <SliderContainer>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={6000}
      >
        <div>
          <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_003.jpg" />
        </div>

        <div>
          <SliderVideo>
            <iframe
              width="auto"
              height="100%"
              src="https://www.youtube.com/embed/yyX17OgQR8U?si=yXNozveOz1UGO3Mk&rel=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </SliderVideo>
        </div>
        <div>
          <SliderContentContainer>
            <SlideTextHeader>Ride Together and Earn!</SlideTextHeader>
            <SlideTextFeature>
              Earn <strong> 3% </strong>
              {/* <br/> */}
              of our service fees on paid rides! For paid rides your referrals
              take!
            </SlideTextFeature>
            <SlideText hideMobile>
              You could be one invite away from earning passive income!
            </SlideText>

            <StartTracking onClick={() => goToSignUp()}>
              - Tap To Join! -
            </StartTracking>
            {/* <div> hi you can click here</div> */}
          </SliderContentContainer>
          <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_001.jpg" />
        </div>
        <div>
          <SliderContentContainerLow>
            <SlideTextFeature hideMobile>
              Since ancient times, asking a lot of different people you know for
              rides was tough, ...until now!
            </SlideTextFeature>
            <SlideText>
              With Dropkins, you can easily request or accept free, donation
              based, or paid rides.
            </SlideText>

            {/* <SlideText>
              If they go on to be drivers, you will earn a percentage from every ride!
            </SlideText> */}

            <StartTracking onClick={() => goToSignUp()}>
              - Sign Up -
            </StartTracking>
            {/* <div> hi you can click here</div> */}
          </SliderContentContainerLow>
          <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_004.jpg" />
        </div>
        <div>
          <SliderContentContainerLow>
            <SlideTextFeature hideMobile>
              Did you accept some rides or earn some extra cash? 💰
            </SlideTextFeature>
            {/* <SlideText>
                With Dropkins, you can easily request or accept free, donation based, or paid rides.
            </SlideText>
            <SlideText>
                Maybe you'll accept some rides yourself to earn some extra cash. 💰
            </SlideText> */}
            <SlideTextHeader>
              Anything fun happen on the ride? Share your story with us!
              <SlideLink
                target="_blank"
                href="https://www.instagram.com/dropkins.app/"
              >
                @dropkins.app
              </SlideLink>
            </SlideTextHeader>
            {/* <SlideText>
              If they go on to be drivers, you will earn a percentage from every ride!
            </SlideText> */}

            <StartTracking onClick={() => goToSignUp()}>
              - Sign Up -
            </StartTracking>
            {/* <div> hi you can click here</div> */}
          </SliderContentContainerLow>
          <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_005.jpg" />
        </div>
        {/* <div>
        <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_004.jpg" />
        </div> */}
        {/* <div>
          <div> hi you can click here
          </div>
          Slide 2
        </div>
        <div>
        <div> hi you can click here
          </div>
          Slide 3</div>
        <div>Slide 4</div> */}
      </AutoplaySlider>
    </SliderContainer>
  );
};
