
import styled from '@emotion/styled';
import * as ui from './uiVars';

export const ActivePanel = styled.div<{active: boolean}>`
display: ${({active}) => active ? 'block' : 'none'};
position: fixed;
top: 6%;
z-index: 12;
background: linear-gradient(180deg, #121212 10.7%, rgba(0, 0, 0, 0.00) 59.45%), #121251;
/* min-width: 80vw; */
/* min-width: 60vw; */
/* min-height: 50vh; */
left: 60px;
animation: ${({active}) => !active ? 'panelAnimationMobile 0.5s ease-in-out' : 'panelAnimationDesktop 0.5s ease-in-out'};
max-width: 600px;

@media screen and (max-width: ${ui.tablet}) {
  left: 0;
  top: initial;
  bottom: 12.6%;
  max-height: 90%;
  animation: ${({active}) => active ? 'panelAnimationMobile 0.5s ease-in-out' : 'panelAnimationDesktop 0.5s ease-in-out'};
  
}

@keyframes panelAnimationMobile {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes panelAnimationDesktop {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
`;
export const FriendListContainer = styled.div`
  display: flex;
  width: 88%;
  margin: 0 auto;
  flex-direction: column;
  
  
  
`;
export const UserNameText = styled.div`
  display: flex;
  /* margin: 0 0.5em; */
  flex-direction: column;
  
`;

export const RequestRideBtn = styled.div`
  display: flex;
  margin: 0.5em 0;
  max-width: 300px;
  padding: 0.5em;
  background: #0de045;
  text-align: center;
  margin: auto;
  width: 100%;
`;

export const NavBoxContainer = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  min-height: 130px;

    /* Custom scrollbar styles */
    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: #121212;
    }

    ::-webkit-scrollbar-thumb {
      background: #7673c9;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #2ec1f7;
    }

  
`;

export const NavBox = styled.div<{active:boolean}>`
  flex-direction: column;
  background: #040325ea;
  border-radius: 8px;
  padding: 0.75em;
  margin: 0.5em;
  cursor: pointer;
  align-items: center;
  display: flex;
  border: 1px solid #7673c9;
  max-width: 65px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.476);
  
  &:hover {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.626);
    background: #0e0b67;
    border: 1px solid #2ec1f7;
  }
  
  ${({active}) => active && `
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.805);
    background: #0e0b67;
    border: 1px solid #2ec1f7;

  `}
`;
export const NavTitle = styled.div`
font-weight: 700;
`;
export const NavIcon = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 50px;
  display: flex;
  width: 60px;
  justify-content: space-evenly;
  img {
    /* width: 100%; */
    min-width: 60px;
    max-width: 60px;
  }
`;
export const NavText = styled.div`
  margin-top: 0.5em;
  font-weight: 700;
`;
export const NavSubText = styled.div`
`;
export const ActiveDriverDistance = styled.div`
  display: flex;
  margin: 0.25em 0;
  font-size: 0.75rem;
`;
export const ActiveDriverContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  flex-basis: 94%;
  margin: 0.4em;
`;
export const ActiveDriverAvatar = styled.div`
  /* display: flex; */
  background: #274751e8;
  padding: 0.5em;
  height: 60px;
  width: 60px;
  border-radius: 1%;
`;

export const ActiveDriverImg = styled.img`
  width: 100%;
`;

export const ActiveDriverDisplayName = styled.div`
  font-weight: 600;
  font-size: 0.875em;
`;
export const ActiveDriverName = styled.div`
  /* display: flex; */
  position: relative;

  text-shadow: 0 2px 6px #000000;
  line-height: 1.2;
`;
export const ActiveFriendsContainer = styled.div<{active: boolean}>`
  display: flex;
  flex-direction: column;
  /* border: 1px solid #476aa6; */
  border-radius: 2px;
  text-align: left;
  padding: 0.4em;
  ${({active}) => !active && `display: none;`}
`;

export const ActiveFriendsListContainer = styled(ActiveFriendsContainer)`
  flex-direction: column;

`;

export const ActiveDriverSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActiveDriverContainer = styled.div`
    display: flex;
    background: #04032581;
    color: white;
    font-weight: 600;
    border-radius: 2px;
    text-transform: capitalize;
    align-items: flex-start;
    padding: 0.25em;
      justify-content: flex-start;
      margin: 0.25em;
      flex-basis: 45%;
`;

export const PanelBreadCrumbs = styled.div`
  display: flex;
  margin: 0.4em 0.984em;
  cursor: pointer;
  &:hover {
    color: #2ec1f7;
  }


  position: absolute;
  top: 0;
  color: #ffffff;
`;

export const PanelSubHeader = styled.div`
  font-weight: 700;
  /* text-decoration: underline; */
  font-size: 1.125rem;
  margin: 0.75em 0;
  /* text-align: center; */
  /* font-family: 'Inter', sans-serif; */
  color: var(--Schemes-On-Primary, #FFF);
/* font-size: 32px; */
font-style: normal;
font-weight: 400;
display: flex;
justify-content: center;
line-height: 24px; /* 75% */
max-width: 100%;

@media screen and (max-width: ${ui.tablet}) {
  /* max-width: 80%; */
}

`;
export const PanelHeader = styled.h1`
  /* color: #040325ea;
  color: #ffffffea;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0.25em 0;
  */
  margin: 0.25em 0;
  text-align: left;
  /* font-family: 'Roboto', sans-serif; */
  color: var(--Schemes-On-Primary, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 2.6em;
  font-style: normal;
  font-weight: 500;
  line-height: 1; /* 46.368px */
  max-width: 100%;
  @media screen and (max-width: ${ui.tablet}) {
  /* max-width: 300px; */
}
`;
export const FriendContainer = styled.div`
  display: flex;
      background: black;
    color: white;
    font-weight: 600;
    border-radius: 0.135em;
    text-transform: capitalize;
    align-items: center;
    padding: 0.15em;
      justify-content: space-between;
      margin: 0.25em;
          overflow-y: auto;
    max-height: 239px;
    margin: auto;
    width: 100%;
    margin-bottom: 0.6em;
    flex-wrap: wrap;

    @media screen and (max-width: ${ui.tablet}) {
      flex-direction: column;
      max-height: 100%;
      margin: auto;
      margin-bottom: 0.6em;
      align-items: normal;
      justify-content: center;
    }
`;





export const FriendPanelContainer = styled.div`
  /* background:   #14239382;
  background: #1b2b51ba;
  background: #071331ba;
  padding: 0.014rem;
  border-radius: 2px;
  color: white;
  border: 1px solid #09065d;
  flex-wrap: wrap;
  justify-content: center;
  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;
  display: flex;
  background-size: cover; */
    display: flex;
  max-width: 100%;
  height: auto;
  flex-wrap: wrap;
  /* flex-shrink: 0; */
  
  
  
  @media screen and (max-width: ${ui.tablet}) {
    flex-direction: column;
    max-height: 100%;
    margin: auto;
    margin-bottom: 0.6em;
    align-items: normal;
    justify-content: center;
    /* flex-basis: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px; */
  }
`;

export const FriendInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  border-radius: 2%;
  
  padding: 0.965em;
  background: #131037;
  
  
  /* margin: 0.5em; */
`;

export const ManageFriendListWrapper = styled.div`
  overflow-y: auto;
  height: 40vh;
  max-width: 60%;

  @media screen and (max-width: ${ui.tablet}) {
    max-width: 100%;
    
  }
`;

export const ManageFriendListContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 0.5em;
    /* background: #060442ab; */
    padding: 0.5em 0;
    border-radius: 0.135em;
    box-shadow: 2px 2px 4px 0px rgba(75, 75, 75, 0.396);
`;

export const ManageFriendListAvatar = styled.div<{approved: boolean}>`
  /* display: flex; */
  background: #274751e8;
  /* padding: 0.5em; */
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;

  @media screen and (max-width: ${ui.tablet}) {
    height: 40px;
  width: 40px;
  }

  ${({approved}) => approved && `

border: 3px solid #CAEB07;

  `}
`;
export const ManageFriendListUserName = styled.div`
  color: var(--Schemes-On-Primary, #FFF);
font-family: "Inter", sans-serif;
font-size: 1em;
font-style: normal;
font-weight: 400;
line-height: 1.5;
flex-basis: 30%;

@media screen and (max-width: ${ui.tablet}) {
   font-size: 0.86em;
  }

`;
export const ManageFriendListChat = styled.div`
  
`;
export const ManageFriendListClose = styled.div`
  
`;
export const ActivePanelContainer =  styled.div`
  display: flex;
  /* flex-direction: column; */

  flex-direction: row;
  @media screen and (max-width: ${ui.tablet}) {
  }
  
`;
export const ActivePanelButton = styled.button`
  /* display: flex; */
/* width: 15px; */
/* height: 18px; */
height: 48px;
/* margin: 0.4em; */
font-weight: 700;
max-width: 250px;
padding: 4px 20px;
justify-content: center;
font-size: 0.9em;
align-self: center;
gap: 1px;
border-radius: 10px;
background: #70edde;
background: #70edde;
margin-left: 3px;
cursor: pointer;

animation: pulse 2s infinite;

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(112, 237, 222, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(112, 237, 222, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(112, 237, 222, 0);
  }
}
/* margin: 0.5em; */

@media screen and (max-width: ${ui.tablet}) {
  /* height: 38px; */
  max-width: 150px;
  padding: 4px 12px;
  font-weight: 600;
  font-size: 0.895em;
  }


`;
export const ActiveFriendContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  /* flex-basis: 100%; */
  margin: 1em 0.4em;
  
  
  @media screen and (max-width: ${ui.tablet}) {
    margin: 1em 0.4em;

  }
`;

export const ActiveContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  /* flex-basis: 74%; */
  padding: 0.964em;
  gap: 16px;
  /* margin-bottom: 2em; */
  margin: 2em;
  border-radius: 0px 8px 8px 0px;
background: linear-gradient(180deg, #2E2E75 52.51%, #000 99.99%);
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

 @media screen and (max-width: ${ui.tablet}) {
  padding: 0.62em;
 }

`;

export const ActiveContentText = styled.div`
  /* font-family: "Inter", sans-serif; */
  max-width: 100%;
  position: relative;
    display: flex;
    flex-wrap: wrap;
  overflow: auto;
  font-size: 0.91761em;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  min-width: 108px;
  line-height: 1.21; 
  text-decoration: underline;
  text-underline-offset: 3px; // Adjust this value as needed

  @media screen and (max-width: ${ui.tablet}) {
    font-size: 0.71em;
  }
`;

export const ActiveContentHeader = styled.div`
  color: var(--Schemes-On-Primary, #FFF);
  font-family: "Inter", sans-serif;
  font-size: 1.25em;
  /* font-style: normal; */
  font-weight: 500;
  line-height: 1.13;
  margin: 0.5em 0;
  margin-top: 0;

  @media screen and (max-width: ${ui.tablet}) {
    font-size: 1em;
  }
`;

export const ActiveFriendPanelContainer = styled(FriendPanelContainer)`
  flex-direction: row;
  max-width: 86%;
  /* flex-direction: row; */
  margin: 5px auto;
  justify-content: flex-start;
  color: white;
  display: flex;
  max-height: 60vh;

  overflow-y: auto;
  @media screen and (max-width: ${ui.tablet}) {
    flex-direction: row;
   min-width: 80%;
   max-height: 60vh;
  }
  
  /* max-width: 80%; */
  
  
  
`;

export const UserNameContainerPanel = styled(FriendPanelContainer)`
  margin: 0.24em;
  background: #050830cc;
  flex-basis: 48%;
  background: linear-gradient(180deg, #2E2E75 0%, #000 100%);
`;



export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: auto;
  /* margin: 0 0 1rem 0; */

  /* background:   #0c4488c4; */
  /* margin: 0.4rem; */
  margin: 0.24rem;
  min-width: 400px;
  border-radius: 2px;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  /* width: 40%; */
  flex-basis: 34%;
  max-width: 68%;
  max-width: 99%;
  

  @media screen and (max-width: ${ui.tablet}) {
    flex-basis: 100%;
    margin: auto;
    flex-direction: row;
    width: auto;
    max-width: 93vw;
    min-width: 280px;
  }
  @media screen and (max-width: ${ui.mobile}) {
    flex-direction: column;
  }
`;

export const ApprovedDriverToggle = styled.img<{approved: boolean}>`
  height: auto;
  width: 60px;
  cursor: pointer;
  transition: transform .4s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
  /* background: ${({approved}) => approved ? `#27b82b` : `#6c766c`}; */
  /* background: ${({approved}) => approved ? `#0eec15` : `#6c766c`}; */
  background: ${({approved}) => approved ? `#1cc822` : `#6c766c`};
  @media screen and (max-width: ${ui.tablet}) {
    height: 40px;
  width: 40px;
  }


`;


export const AvatarImageContainer = styled.div<{small?: boolean}>`
  width: 140px;
  height: 140px; // Ensure the container is a square to maintain the circular shape
  border-radius: 50%;
  overflow: hidden;
  display: flex; // Added to center the Avatar image
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  ${({small}) => small && `
    width: 66px;
    height: 66px; // Adjust height as well to maintain the circular shape
    margin-right: 2em;
  `}
`;

export const Avatar = styled.img<{small?: boolean}>`
  width: 100%;
  height: 100%; // Adjust height to fill the container
  object-fit: cover; // Ensure the image covers the area without losing its aspect ratio
  border-radius: 50%; // This is optional since the container already clips the image
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  animation: pulsate 2s infinite;

  @keyframes pulsate {
    0% {
      border: 2px solid #207ef063;
    }
    50% {
      border: 2px solid #20e2f0dd;
    }
    100% {
      border: 2px solid #207ef099;
    }
  }
`;


// export const AvatarImageContainer = styled.div<{small?: boolean}>`
//   width: 100px;
// /* height: 50px; */
// /* border-radius: 50%; */
// /* overflow: hidden; */
//  ${({small}) => small && `
//     width: 66px;
//     margin-right: 2em;
//   `}
// `;

// export const Avatar = styled.img<{small?: boolean}>`
//   width: 100%;
//   height: auto;
//   /* height: 150px; */
//   border-radius: 50%;
//   margin: 0 0 0.25rem 0;
//   align-self: center;
//   justify-self: center;
//   box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);

 

//   @media screen and (max-width: ${ui.tablet}) {
//     /* height: 100px; */
//   }

  
//   animation: pulsate 2s infinite;

//   @keyframes pulsate {
//     0% {
//       border: 2px solid #207ef063;
//     }
//     50% {
//       border: 2px solid #20e2f0dd;
//     }
//     100% {
//       border: 2px solid #207ef099;
//     }
//   }
// `;

export const UserName = styled.div`
  padding: 0.4em 0;

  font-weight: 700;
  text-align: left;
  color: var(--Schemes-On-Primary, #FFF);
/* font-family: Inter; */
font-size: 1.1em;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 100% */
`;
export const UserNameContainer = styled.div`
  display: flex;
  

`;

export const FriendContentText = styled.div`
  color: #F0F0F0;
font-family: "Inter", sans-serif;
font-size: 0.86em;
text-align: left;
font-style: normal;
font-weight: 400;
line-height: 1.2; /* 150% */
`
export const RecentNotificationContainer = styled.div`
  display: flex;
`;

export const UpdateUsernameContainer = styled.div<{toggle?: boolean}>`
  position: relative;
  /* background:   #070d39; */
  max-width: 100%;
  /* padding: 0.4rem; */
  border-radius: 2px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-bottom: 0.5em;
  ${({toggle}) => toggle && `
    display: none;
  `}
`;

export const UserNameTextArea = styled.textarea`
margin: 0.5em 0;
  padding: 0.5em;
  font-weight: 600;
  height: 30px;
  border-radius: 4px;
  /* width: 100%; */
`;

export const UserNamesContainer = styled.div`
    display: flex;
    gap: 10px;

    @media screen and (max-width: ${ui.mobile}) {
      flex-wrap:wrap;
      gap: 0;
    }

`;



export const UserNameInput = styled.input`
  margin: 0.5em 0;
  padding: 0.5em;
  font-weight: 600;
  height: 30px;
  border-radius: 4px;
  text-transform: capitalize;
  font-family: "inter", sans-serif;

  @media screen and (max-width: ${ui.tablet}) {
    /* width: -webkit-fill-available; */
    height: 20px;
    
  }

`;

export const PendingButton = styled.button<{active: boolean}>`
  display: ${({active}) => active ? 'none' : 'block'};
  color: black;
  margin: 0.2em;
  border-radius: 10px;
background: #FFC700;

display: flex;
width: 72px;
height: 24px;
padding: 24px 40px;
justify-content: center;
align-items: center;
gap: 1px;
flex-shrink: 0;

`;
export const AcceptButton = styled.button<{active: boolean}>`
  display: ${({active}) => active ? 'none' : 'flex'};
  margin: 0.2em;

  border-radius: 10px;
background: #59E5D5;

width: 72px;
height: 24px;
padding: 24px 40px;
justify-content: center;
align-items: center;
gap: 1px;
flex-shrink: 0;

`;
export const UserNameButton = styled.button`
  cursor: pointer;
  padding: 0.75em;
  background: #2debf9;
  background: #e2fe2c;
  border: 1px solid #ebfeff;
  font-weight: 600;
  border-radius: 2px;
  /* flex-basis: 40%; */

  &:nth-of-type(2) {
    margin-left: 0.25em;
  }
  &:hover {
    background: #05dbea;
    border: 1px solid #ebfeff;
  }

  @media screen and (max-width: ${ui.tablet}) {
    flex-basis: 100%;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    &:nth-of-type(2) {
      margin: auto;

  }
    
  }


`;

export const FriendPanelTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 0.5em 0;
  color: white;
  /* text-decoration: underline; */
  width: 100%;
  font-family: 'Inter' sans-serif;
`;

export const NotificationContainer = styled.div<{alert: boolean, success: boolean}>`
color: #dae9f9;
font-weight: 700;
min-width: 100px;
padding: 0.5em;
margin-bottom: 0.25rem;
${({alert}) => alert && `background: #610606; z-index: 99;`}
${({success}) => success && `background: #0b7b0b; z-index: 99;`}
`;


export const NotifyContainer = styled.div`
  padding: 0.4rem;
    max-width: 387px;
    margin: auto;

    display: flex;
    justify-content: center;
    transition: transform .4s ease-in-out;
`;
export const AlertsContainer  = styled(NotifyContainer)`
  background: #ff0000;
  & div:nth-of-type(odd) {
    background: #680e0e;
    color: #c2c2c2;
  }
`;
export const SuccessContainer = styled(NotifyContainer)`
  background: #159c15;
  /* even of-type color */
  & div:nth-of-type(odd) {
    background: #0e680e;
    color: #783131;
  }
`;



