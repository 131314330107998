import React, { useState } from 'react';
import styled from '@emotion/styled';

import * as ui from "../../../styles/uiVars";


import {
  AvatarContainer,
  Avatar,
  UserNameContainer,
  UserName,
  UpdateUsernameContainer,
  FriendContentText,
  UserNameInput,
  UserNameButton,
  FriendPanelContainer,
  FriendInputContainer,
  PanelHeader,
  PanelSubHeader,
  UserNameContainerPanel,
  ActiveFriendPanelContainer,
  ActiveFriendContent,
  ActiveContentText,
  ActiveContentHeader,
  ActivePanelButton,
  ManageFriendListContainer,
  ManageFriendListWrapper,
  ManageFriendListAvatar,
  ManageFriendListUserName,
  ManageFriendListChat,
  ManageFriendListClose,

  // Notifcations,
  AlertsContainer,
  SuccessContainer,
  ActivePanel,
  FriendContainer,
  FriendListContainer,
  ApprovedDriverToggle,
  AcceptButton,
  FriendPanelTitle,
  PendingButton,
  ChatPanel,
  ChatPanelCloseBtn,
  ChatPanelHeader,
  ChatPanelHeaderImg,
  ChatPanelHeaderText,
  ChatPanelBody,
  ChatPanelBodyText,
  ChatPanelFooter,
  ChatPanelFooterInput,
  ChatPanelFooterBtn,
  ChatMessageContainer,
  ChatMessageText,
  UserNameText,
  SelectInputContainer,
  GlowBox,
  PassengerCountContainer,
  PassengerCountBtn,
  PassengerCountBtnContent,
  RideCostEstimate,
  SubscriptionGate,
  SubscriptionGateHeader,
  SubscriptionGateSubHeader,
  SubscriptionGateContent,
  SubscriptionGateText,
  SubscriptionGateImg,
} from "../../../styles";
import TribalLeader from '../Listings/TribalLeader'

import { UserListings } from '../Listings'

const ListingHeader = styled.h2`
  display: flex;
  font-size: 1.8em;
  font-weight: 700;
`;

const LeaderContainer = styled.div`


`;

const ListingControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    background: #09175d;
    width: 0.5em;
  }
  background: #09175d;
  background: linear-gradient(180deg, #000  0%, #0e0e4a 100%);
  color: #fff;
  position: relative;

  @media screen and (max-width: ${ui.tablet}){
    /* padding-top: 8em; */
  }
  `;

const CloseButton = styled.button`
position: absolute;
top: 10px;
top: 1.65em;
right: 20px;
background: none;
border: none;
font-size: 24px;
color: wheat;
z-index: 1;
cursor: pointer;
`;


const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        {children}
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};


const ListingsPanel = ({handleClose}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);

  // Function to handle opening the modal and setting the selected listing
  const handleOpenModal = (listing) => {
    setSelectedListing(listing);
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setSelectedListing(null);
    setShowModal(false);
  };

  // Function to render the list of listings
  const renderListings = () => {
    // Replace this with your actual data from Firestore
  //   getListings().then(listings => {

  //   // const listings = [
  //   //   { id: 1, type: 'errand', name: 'Errand 1' },
  //   //   { id: 2, type: 'house', name: 'House 1' },
  //   //   // Add more listings here
  //   // ];

  //   return listings.map((listing) => (
  //     <div key={listing.id}>
  //       <h3>{listing.name}</h3>
  //       <button onClick={() => handleOpenModal(listing)}>View Details</button>
  //     </div>
  //   ));
  // });

  };

  return (
    <ListingContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <ListingHeader>
        Listings Panel
      </ListingHeader>

      <LeaderContainer>
        <TribalLeader />

      </LeaderContainer>
      {/* {renderListings()} */}
        <ListingControlsContainer>
         {/* <UserName>Add Listing</UserName> */}
            <UpdateUsernameContainer>
            <FriendContentText>
              Need to move a couch, or help with an ikea desk? 
            </FriendContentText>
            <FriendContentText>
              Set your rate, and see if someone in your network can help!
            </FriendContentText>
            </UpdateUsernameContainer> 
          </ListingControlsContainer>
          <UserListings />


      {selectedListing && (
        <Modal isOpen={showModal} onClose={handleCloseModal}>
          {/* Render the modal content here */}
          <h3>{selectedListing.name}</h3>
          {/* Add more details about the listing */}
        </Modal>
      )}
    </ListingContainer>
  );
};

export default ListingsPanel;