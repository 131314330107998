import React, { createContext, useEffect, useState, useRef } from "react";
import { getUserId, Sendrequest } from "./firebase";
import {
  kinDb,
  appFirebase,
  providers,
  db,
  kindAuth,
  messaging,
  cloudFunctions,
  // messagingToken,
} from "./firebase-config";
import { loadStripe } from "@stripe/stripe-js";
import { getMessaging, getToken } from "firebase/messaging";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  getStripePayments,
  onCurrentUserSubscriptionUpdate,
} from "@stripe/firestore-stripe-payments";
import { httpsCallable } from "@firebase/functions";
import {
  doc,
  getDoc,
  query,
  where,
  collection,
  getDocs,
  setDoc,
} from "firebase/firestore";

export const AuthContext = createContext({
  currentUser: null,
});

const { Provider } = AuthContext;

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPrevUser, setCurrentPrevUser] = useState(null);
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const [messageToken, setMessageToken] = useState('');

  // UI Specific
  const [notification, setNotification] = useState([]);
  const [fiestaMessage, setFiestaMessage] = useState([]);
  const [triggerShow, setTriggerShow] = useState(false);
  const [clearTogglesActive, setClearTogglesActive] = useState(false);
  const [dashPanel, setDashPanel] = useState("monitor");
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState("");
  const [confirmationAlertText, setConfirmationAlertText] = useState("");
  const [confirmationHeaderText, setConfirmationHeaderText] = useState("");
  const [termsToggle, setTermsToggle] = useState(false);
  const [termsPanelToggle, setTermsPanelToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [panelTrigger, setPanelTrigger] = useState(null);
  const [panelTriggerCount, setPanelTriggerCount] = useState(0);
  const [ridePanelToggle, setRidePanelToggle] = useState(false);
  const [searchPanelToggle, setSearchPanelToggle] = useState(false);
  const [openPanel, setOpenPanel] = useState(2);
  const [openSpeedModal, setOpenSpeedModal] = useState(false);

  const [loginToggle, setLoginToggle] = useState(false);
  const [signupToggle, setSignupToggle] = useState(false);
  const [manageFriendToggle, setManageFriendToggle] = useState(false);

  const [onCancel, setOnCancel] = useState(() => () => {});
  const [onConfirm, setOnConfirm] = useState(() => () => {});

  // User Specific
  const [activeFriends, setActiveFriends] = useState([]);
  const [pendingFriends, setPendingFriends] = useState([]);
  const [activeFriendsUpdate, setActiveFriendsUpdate] = useState([]);
  const [resetDriverMarker, setResetDriverMarker] = useState("show");
  const [driverMode, setDriverMode] = useState(false);
  const [startLocation, setStartLocation] = useState();
  const [destination, setDestination] = useState();
  const [activeFriendPanel, setActiveFriendPanel] = useState(1);
  const [activeDriverData, setActiveDriverData] = useState({});
  const [friends, setFriends] = useState([]);
  const [mapState, setMapState] = useState({ loading: true });
  const [userNotifications, setUserNotifications] = useState([]);
  
  const [lastLocation, setLastLocation] = useState("");
  const [lastDestination, setLastDestination] = useState("");
  const [userName, setUserName] = useState("");
  const [userData, setUserData] = useState({});
  const [customerData, setCustomerData] = useState({});
  const [grabLocation, triggerLocationGrab] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [setReferrer, referrer] = useState(null);

  // Friend / Chat Specific
  const [friendId, setFriendId] = useState("");
  const [chatId, setChatId] = useState("");
  const [inviteCode, setInviteCode] = useState("");

  const [chatPanelUser, setChatPanelUser] = useState("");
  const [chatMessages, setMessages] = useState("");
  const [chatPanelToggle, setChatPanelToggle] = useState(false);

  // Map Specific
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [location, setLocation] = useState("");
  const [triggerUpdateDriverDistance, updateDriverDistance] = useState(
    () => {}
  );
  const [latestMarker, setLatestMarker] = useState({});

  // Carpool Specific
  const [freeRideToggle, setFreeRideToggle] = useState("paid");
  const [rideEstimate, setRideEstimate] = useState(null);
  const [rideTimeEstimate, setRideTimeEstimate] = useState(null);
  const [passengerCount, setPassengerCount] = useState(3);
  const [passengerCountFilter, setPassengerCountFilter] = useState(null);
  const [rideDistanceEstimate, setRideDistanceEstimate] = useState(null);

  // Stripe Specific
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [setupIntent, setSetupIntent] = useState(false);
  const [paymentMethodId, setPaymentMethodId,] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [stripeAccountLink, setStripeAccountLink] = useState(null);
  const [freeRides, setFreeRides] = useState(0);
  const [stripeAccountStatus, setStripeAccountStatus] = useState({
    isAccountSetupComplete: false,
  });
  const [stripeSubscription, setStripeSubscription] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const topRef = useRef(null);


  // const options = {
  //   mode: 'payment',
  //   amount: 1099,
  //   currency: 'usd',
  //   // Fully customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };

  const valueMap = {
    activeFriends,
    setActiveFriends,
    activeFriendsUpdate,
    setActiveFriendsUpdate,
    resetDriverMarker,
    setResetDriverMarker,
    driverMode,
    setDriverMode,
    startLocation,
    setStartLocation,
    destination,
    setDestination,
    activeFriendPanel,
    setActiveFriendPanel,
    activeDriverData,
    setActiveDriverData,
    friends,
    setFriends,
    mapState,
    setMapState,
    ridePanelToggle,
    setRidePanelToggle,
    directionsResponse,
    setDirectionsResponse,
    lastLocation,
    setLastLocation,
    lastDestination,
    setLastDestination,
    notification,
    setNotification,
    fiestaMessage,
    setFiestaMessage,
    userName,
    setUserName,
    location,
    setLocation,
    position,
    setPosition,
    friendId,
    setFriendId,
    chatPanelUser,
    setChatPanelUser,
    chatMessages,
    setMessages,
    chatId,
    setChatId,
    chatPanelToggle,
    setChatPanelToggle,
    userData,
    setUserData,
    clearTogglesActive,
    setClearTogglesActive,
    triggerShow,
    setTriggerShow,
    pendingFriends,
    setPendingFriends,
    grabLocation,
    triggerLocationGrab,
    triggerUpdateDriverDistance,
    updateDriverDistance,
    freeRideToggle,
    setFreeRideToggle,
    rideEstimate,
    setRideEstimate,
    rideDistanceEstimate,
    setRideDistanceEstimate,
    setupIntent,
    setSetupIntent,
    clientSecret,
    setClientSecret,
    customerId,
    setCustomerId,
    userEmail,
    setUserEmail,
    dashPanel,
    setDashPanel,
    stripeAccountStatus,
    setStripeAccountStatus,
    toggleConfirmationModal,
    setToggleConfirmationModal,
    confirmationModalText,
    setConfirmationModalText,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
    confirmationAlertText,
    setConfirmationAlertText,
    confirmationHeaderText,
    setConfirmationHeaderText,
    termsToggle,
    setTermsToggle,
    termsPanelToggle,
    setTermsPanelToggle,
    loading,
    setLoading,
    profileImage,
    setProfileImage,
    inviteCode,
    setInviteCode,
    passengerCount,
    setPassengerCount,
    customerData,
    setCustomerData,
    passengerCountFilter,
    setPassengerCountFilter,
    rideTimeEstimate, setRideTimeEstimate,
    paymentMethodId, setPaymentMethodId,
    panelTrigger, setPanelTrigger,
    panelTriggerCount, setPanelTriggerCount,
    latestMarker, setLatestMarker,
    searchPanelToggle, setSearchPanelToggle,
    openPanel, setOpenPanel,
    topRef,
    signupToggle, setSignupToggle,
    loginToggle, setLoginToggle,
    manageFriendToggle, setManageFriendToggle,
    freeRides, setFreeRides,
    setReferrer, referrer,
    messageToken, setMessageToken,
    isSubscribed,
    userNotifications, setUserNotifications,
    openSpeedModal, setOpenSpeedModal,

  };

  const appState = {};


    

  useEffect(() => {

    const saveToken = httpsCallable(
      cloudFunctions,
      "saveToken"
    );
      // console.log('checking token', currentUser?.uid,);
    if (!currentUser?.uid) return;
    // const saveToken = cloudFunctions.httpsCallable("saveToken");
    
      const storeToken = async () => {
        // console.log('going to store token');
      try {

      // if (messaging) {
      //   // const messagingFirebase = getMessaging(appFirebase);
      //   messaging.requestPermission().then(() => {
      //     // Notification permission granted.
      //     //...
      //    return messaging.getToken();
          
      //     // console.log('Notification permission granted.');
      //   }).then((token) => {
      //     console.log('token', token);
      //     setMessageToken(token);
      //     saveToken({
      //       token: token,
      //       uid: currentUser.uid,
      //     }).then((res) => {
      //       console.log('token saved');
      //     }).catch((err) => {
      //       console.log('error saving token', err);
      //     });
      //   }).catch((err) => {
      //     // Notification permission denied.
      //     //...
      //     // console.log('Unable to get permission to notify.', err);
      //   });

      //   // await messagingFirebase.onTokenRefresh(async () => {
      //     // try {
      //     //   console.log('refreshing token');
      //     //   const refreshedToken =  getToken(messagingFirebase, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
      //     //     console.log('current token', currentToken);
      //     //     return currentToken;
      //     //   });
      //     //   console.log('Refreshed FCM token:', refreshedToken);
      //     //   setMessageToken(refreshedToken);
      //     //   // Send the new token to your server to save it.
      //     //   // Call the function with the token and uid
      //     //   const result = await saveToken({ token: refreshedToken, uid: currentUser?.uid });
      //     //   // Handle the result
      //     //   console.log(result.data);
      //     // } catch (err) {
      //     //   console.log('Unable to retrieve refreshed token ', err);
      //     // }
      //   // });

      // } else {
        const messagingFirebase = getMessaging(appFirebase);
        // console.log('messagingFirebase', messagingFirebase);

        // messagingFirebase.requestPermission().then(() => {
          // Notification permission granted.
          //...
         return getToken(messagingFirebase, { vapidKey: process.env.REACT_APP_VAPID_KEY })
          
          // console.log('Notification permission granted.');
        // })
        .then((token) => {
          // console.log('token', token);
          if(token) {
            setMessageToken(token);
          }
          saveToken({
            token: token,
            uid: currentUser.uid,
          }).then((res) => {
            // console.log('token saved');
          }).catch((err) => {
            // console.log('error saving token', err);
          });
        }).catch((err) => {
          // Notification permission denied.
          //...
          // console.log('Unable to get permission to notify.', err);
        });
        // messagingFirebase.onTokenRefresh(async () => {
        //   try {
        //     console.log('refreshing token');
        //     const refreshedToken = await getToken(messagingFirebase, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
        //       console.log('current token', currentToken);
        //       return currentToken;
        //     });
        //     console.log('Refreshed FCM token:', refreshedToken);
        //     setMessageToken(refreshedToken);
        //     // Send the new token to your server to save it.
        //     // Call the function with the token and uid
        //     const result = await saveToken({ token: refreshedToken, uid: currentUser?.uid });
        //     // Handle the result
        //     console.log(result.data);
        //   } catch (err) {
        //     console.log('Unable to retrieve refreshed token ', err);
        //   }
        // });
        // try {
        //   // Sendrequest();
        //   const token = await messagingToken
        //   console.log('token', token);
        //   setMessageToken(token);
        //   const result = await saveToken({ token: token, uid: currentUser?.uid });
        //   console.log(result.data);
        // } catch (error) {
        //   console.log('error', error);
        //   // User doesn't have a device token yet
        //   console.log('No registration token available. Request permission to generate one.');
        // }
      // }

        // const refreshedToken = await messaging.getToken();
        
      } catch (error) {
        // Handle any errors
        // console.log('token fail')
        console.error(error);
      }
      };

      storeToken();
    }, [currentUser]);

  

  useEffect(() => {
    // console.log(currentUser)
    const unsubscribe = kindAuth.onAuthStateChanged(setCurrentUser);
    return () => unsubscribe;
  }, []);

  useEffect(() => {
    // console.log({ stripeAccountStatus });
    if (!kindAuth.currentUser) return;
    if (!currentUser?.uid) return;
    if(!customerData?.userId) return;
    // update customer data with stripe account id
    const updateCustomerData = async () => {
      // get customers collection with current user id
      const customerCol = collection(db, "customers");
      const customerDoc = doc(customerCol, customerData?.userId);
      const customerDocSnap = await getDoc(customerDoc);
      const customerSnapData = customerDocSnap?.data();
      const customerDataId = customerDocSnap?.id;
      // console.log("## update customer data", { customerData });
      let stripeAccountId = customerData?.stripeAccountId || customerSnapData?.stripeAccountId;
      // if customer data exists and stripe account id exists
      if (stripeAccountStatus && stripeAccountStatus?.account?.id) {
        // if stripe account id is not equal to stripe account id in customer data
        if (
          stripeAccountId !== stripeAccountStatus?.account?.id
        ) {
          // update customer data with stripe account id
          await setDoc(
            customerDocSnap?.ref,
            { stripeAccountId: stripeAccountStatus?.account?.id },
            { merge: true }
          );
          // update current user with stripe account id
          setCurrentUser({
            ...currentUser,
            stripeAccountId: stripeAccountStatus?.account?.id,
          });
          // update customer data with stripe account id
          setCustomerData({
            ...customerData,
            stripeAccountId: stripeAccountStatus?.account?.id,
          });
        }
      }

      const freeRides = customerSnapData?.freeRides;
      if(freeRides) {
        // console.log('setting free rides', freeRides);
        setFreeRides(freeRides);
      }
    };
    // console.log("## update customer data", { currentUser, customerData });
    updateCustomerData();
    // const unsubscribe = kindAuth.onAuthStateChanged(setCurrentUser);
    // return () => unsubscribe;
  }, [stripeAccountStatus, customerData, currentUser]);

  useEffect(() => {
    const setCustomerDat = () => {
      async function fetchUserData() {
        // console.log("fetchingCustomer Data", { currentUser });
        if (!currentUser?.uid) return;
        // console.log("fetchingCustomer Data", currentUser?.uid);
        // const cUserRef = doc(db, 'customers', currentUser?.uid);
        // const cUserSnapshot = await getDoc(cUserRef);
        // const cUserData = cUserSnapshot?.data();
        // query users collection where userid equals current user id
        const usersCollection = collection(db, "users");
        const q = query(
          usersCollection,
          where("userId", "==", currentUser?.uid)
        );
        const querySnapshot = await getDocs(q);
        const queryDoc = querySnapshot.docs[0];
        const cUserData = queryDoc?.data();
        const sUserDatId = queryDoc?.id;
        // console.log("fetchingCustomer Data", cUserData);

        // query customers collection with doc id being current user id
        const customersCollection = collection(db, "customers");
        if(!cUserData?.userId) {
          // console.log("fetchingCustomer Data", "no userId");
          setCustomerData({...cUserData, id: sUserDatId });
          return
        
        };
        const q2 = doc(customersCollection, cUserData?.userId);
        const querySnapshot2 = await getDoc(q2);
        const queryDoc2 = querySnapshot2?.data();

        setCustomerData({ ...queryDoc2, ...cUserData, id: sUserDatId });
      }
      // console.log("fetch request", { currentUser });
      fetchUserData();
    };

    setCustomerDat();
  }, [currentUser,]);

  useEffect(() => {
    // console.log({ currentUser });
    if (!kindAuth.currentUser) return;

    if (currentUser?.photoURL) {
      setProfileImage(currentUser?.photoURL);
    }

    if (currentUser?.email) {
      setUserEmail(currentUser?.email);

      if (!currentUser?.stripeAccountId) {
        // checkStripeAccountSetup();
        if (currentPrevUser === currentUser) return;
      }
    }
  }, [currentUser]);

  // useEffect(() => {
  //   console.log({customerData})
  //   const setupCustomerData = () => {
  //     console.log('## set customer', {customerData}, {userData})
  //   if (userData) {
  //     const {stripeAccountId,
  //       stripeLink,
  //       userId,

  //     } = userData;

  //     const {id} = customerData;
  //     const updateCurrentUser =
  //       {...currentUser,
  //         stripeAccountId,
  //         stripeLink,
  //         userId,
  //         id,
  //       }

  //     if ( updateCurrentUser !== currentUser) {

  //       setCurrentUser(updateCurrentUser);
  //     }
  //   }
  // }

  //   if(!customerData) return;
  //   setupCustomerData();
  // }, [customerData, currentUser?.uid]);

  useEffect(() => {
    async function checkStatus() {

      // console.log('$$ checking subscription customer data', {customerData})

      if(!customerData?.userId) return;
      const checkSubscriptionStatus = httpsCallable(
        cloudFunctions,
        "checkSubscriptionStatus"
      );
      
      setLoading(true);
      try {
        // console.log("## checkSubscriptionStatus",  customerData?.stripeCustomerId, customerData?.userId);
      const response = await checkSubscriptionStatus({
        customerId: customerData.stripeCustomerId,
        userId: customerData?.userId,
        email: customerData?.email,
      });

      // console.log("## checkSubscriptionStatus", { response });
      setLoading(false);
      
      setIsSubscribed(response.isSubscribed); 
      setStripeSubscription(response.subscription);
    } catch (error) {
        setLoading(false);
        // console.log("## checkSubscriptionStatus error", { error });
      }
    }
    checkStatus();
  }, [customerData]);

  // Call Firebase function to create setup intent
  useEffect(() => {
    if (!kindAuth.currentUser) return;

    if (!currentUser?.uid || currentUser?.setupIntent) {
      return;
    }
    if (currentUser?.setupIntent && currentUser?.stripeCustomerId) {
      setClientSecret(currentUser?.setupIntent);
      setCustomerId(currentUser?.stripeCustomerId);
      return;
    }
    // console.log({ currentUser });

    const createSetupIntent = async () => {
      // Assume you have a method to call Firebase functions
      // const response = await createPaymentIntent();
      // setClientSecret(response.clientSecret);
      // setCustomerId(response.customerId);
      // };

      if (userEmail && !currentUser?.setupIntent) {
        // console.log("setupIntent", setupIntent);
        // console.log("setupIntent", userEmail);

        // createSetupIntent();
        // console.log("email exists progressing");
        const createSetupIntentCall = httpsCallable(
          cloudFunctions,
          "createSetupIntent"
        );
        // const createPaymentIntent = httpsCallable<unknown, PaymentIntentResponse>(cloudFunctions, 'createPaymentIntent');
        const setupIntentResponse = await createSetupIntentCall({
          email: userEmail,
          userId: currentUser?.uid,
        }); // Set the amount
        // console.log("setupIntentResponse", setupIntentResponse);
        // console.log(
          // "setupIntentResponse",
          // setupIntentResponse?.data?.clientSecret
        // );
        setClientSecret(setupIntentResponse?.data?.clientSecret);
        setCustomerId(setupIntentResponse?.data?.customerId);
        // return setupIntentResponse?.data?.paymentIntentId;
        return;
        // setClientSecret(response.clientSecret);
        // setCustomerId(response.customerId);
        // }

        // createSetupIntent();
      }
    };

    // return () => createSetupIntent();
  }, [userEmail]);


  useEffect(() => {
    // console.log(currentUser)
    const checkStripeAccountSetup = async () => {
      // console.log("## checking strip account status", {customerData});

      if (!customerData?.stripeAccountId) return;
      // console.log("## has stripe id", customerData?.stripeAccountId);

      if (!kindAuth.currentUser) return;
      // console.log("checkStripeAccountSetup")
      // Call the Cloud Function
      const checkStripeSetup = httpsCallable(
        cloudFunctions,
        "checkStripeAccountSetup"
        );
        
        try {
        // console.log("## checking strip account status", {
        //   stripeAccountId: customerData?.stripeAccountId,
        // });
        const result = await checkStripeSetup({
          stripeAccountId: customerData?.stripeAccountId,
        });
        // console.log("!! result from stripe check", result);
        // console.log(result.data); // Process or display the results as needed
        if (!result.data) return;
        if (
          stripeAccountStatus?.isAccountSetupComplete ===
          result.data?.isAccountSetupComplete
        )
          return;
          if (JSON.stringify(stripeAccountStatus) !== JSON.stringify(result.data)) {
            setStripeAccountStatus(result.data);
          }
        // setStripeAccountStatus(result.data);
      } catch (error) {
        // console.error("Error calling checkStripeAccountSetup:", error);
        // Handle the error
      }
    };
    checkStripeAccountSetup();
  }, [customerData, currentUser, stripeAccountStatus]);
  // useEffect(() => {
  //   // Get registration token. Initially this makes a network call, once retrieved
  //   // subsequent calls to getToken will return from cache.
  //   messaging.getToken({ vapidKey: "BOR0KIy703NFdcQkgfuewMKfpQBuOQNTC59pGyL7dnJGhHQrzZdC-jx878Y8VMw7i61HOkC5XmOkaw6G_6VMfYk" }).then((currentToken) => {
  //     if (currentToken) {
  //       // Send the token to your server and update the UI if necessary
  //       setMessageToken(currentToken);
  //       // ...
  //     } else {
  //       // Show permission request UI
  //       console.log('No registration token available. Request permission to generate one.');
  //       // ...
  //     }
  //   }).catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //     // ...
  //   });
  // }, []);

  return (
    <Provider value={{ currentUser, ...valueMap, ...position, error }}>
      <Elements stripe={stripePromise} options={{}}>
        {children}
      </Elements>
    </Provider>
  );
};
