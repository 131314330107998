import React, { useState, useEffect, useContext, useCallback } from "react";
import SpaceMap from "./SpaceMap";
import { FormBox, FormLabel, FormQuestion } from "./SetMarker";
import CheckInsList from "./CheckInsList";
import WarningsPanel from "./WarningsPanel";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import Draggable from "react-draggable";
import { optionalCallExpression } from "@babel/types";
import NotificationWrapper from "./UI/Notifications";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { debounce } from "lodash";
import { NavMap, PassengerMap } from "./UI/maps";
import classNames from "classnames";

import {
  sendHeartTo,
  getUserId,
  getHeartCount,
  getTreasureData,
  setDontShowOnboard,
  checkDontShowOnboard,
  writeMarker,
  fireAuth,
  loadChatMessages,
  listenForFriendChanges,
  logOut,
} from "../firebase";
import { db, kinDb, kindAuth, cloudFunctions } from "../firebase-config";
import { collection } from "firebase/firestore/lite";
import SetMarker from "./SetMarker";
import { getDoc, getDocs, where, query, updateDoc } from "firebase/firestore";
import { ClosePanel } from "../modules/general";
import { httpsCallable } from "@firebase/functions";
import { ErrorField, PanelFocusText } from "./SignUp";
import { set, useForm } from "react-hook-form";
import * as ui from "../styles/uiVars";

import { SHA256 } from "crypto-js";
import {storeSMSPreferences} from "../firebase";
import { storeInvitedUser, checkForExistingInvite } from "../storage/email";

import {
  SearchPanelHeader,
  SearchSubHeader,
  SearchCopy,
  SearchCopyBtn,
  OutlineContainer,
  OutlineColumnContainer,
  OutlineNavColumnContainer,
  ResponsiveColumnContainer,
  SearchHeader,
  CheckBoxContainer,
  UseCurrentBtn,
  UseCurrentText,
  ButtonColumnContainer,
  AvatarColumnContainer,
  OutlineAvatarContainer,
  CheckInForm,
} from "./SetMarker/styles";

import {
  TreasureInfoContent,
  ActiveDriverContainer,
  ActiveDriverAvatar,
  ActiveDriverSubContainer,
  ActiveDriverDistance,
  ActiveDriverName,
  ActiveDriverContent,
  RequestRideBtn,
  ActiveDriverImg,
  ActiveFriendsContainer,
  ActiveFriendsListContainer,
  UserNameButton,
  NotificationContainer,
  SuccessContainer,
  AlertsContainer,
  CancelRideBtn,
  EndRideBtn,
  ChatButton,
  NavBoxContainer,
  NavTitle,
  NavIcon,
  NavText,
  NavSubText,
  NavBox,
  GlowBox,
  PassengerCountContainer,
  PassengerCountBtn,
  PassengerCountBtnContent,
  ActiveDriverDisplayName,
  ActiveContentText,
  ActivePanelButton,
  ActivePanelContainer,
  ActiveContentContainer,
  ActiveContentHeader,
  ActiveFriendPanelContainer,
  FriendContentText,
  UserName,
  UserNameInput,
  UpdateUsernameContainer,
  RecentNotificationContainer,
  ContentText,
  
} from "../styles";
import { StripeLinkButton, StripeUpdateButton } from "./UI/stripe/elements";
import { AuthContext } from "../Auth";

import { RefreshUiBtn, NavActionBtn } from "./SetMarker/styles";

import {
  activateDriverMode,
  getDriverMode,
  toggleActiveRideRequest,
  acceptRideRequest,
  cancelActiveRide,
  confirmPickup,
  addFriend,
  confirmDropOff,
  endRide,
} from "../firebase";

import  UserNotificationList  from "../components/UI/Shared/UserNotificationList";
import { ConfirmationModal } from "../components/UI/Shared/Alerts";

import {
  LinkBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  NotificationsForm,
  LoadingContainer,
  LoadingSpinner,
  MainText,
  MainContentContainer,
  CenterContainer,
} from "../styles/shared";


const PlusText = styled.div`
  font-size: 0.77em;
   letter-spacing: 2px;
   font-weight: 600;
   font-family: "Inter", sans-serif;
  padding:4px;
   line-height: 1.15;

   @media screen and (max-width: ${ui.mobile}) {
    fontSize: 0.6em;
    padding: 2px;
    
   }
`;

const PlusButton = styled.div`
  position: relative;
  cursor: pointer;
  padding: 10px 0;
  /* width: 100%; */
  background: #0ac4d4;
  height: 3vh;
color: #07335a;
  /* width: 6vw; */
  border: 1px solid #539ee0;
  border-radius: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5em 0.2em;

  @media screen and (max-width: ${ui.mobile}) {
    /* width: 10vw; */
    height: 3vh;
    
  }
  

  :hover {
    color: #0c696e;
    background: #4edded;
  }

  animation: ${({ openSpeedModal }) => openSpeedModal ? 'rotate 3s' : 'none'};

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /* top: 10px; */
`;

const SpeedCloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.853em;
  cursor: pointer;
  font-weight: 600;
  /* add underline */
  text-decoration: underline;
  /* add space between text and underline */
  text-underline-offset: 0.2em;


  &:hover {
    color: #0b1130;
  }

`;


const SpeedModal = styled.div`
  position: absolute;
  left: 6em;
  bottom: 10vh;
  padding: 2em 1em;
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid #2beead;
  border-radius: 10px;
  

  background: #0b1130cf;
  @media screen and (max-width: ${ui.mobile}) {
    left: 0;
    top: 20vh;
    min-width: 250px;
    min-height: 40vh;
    overflow: auto;
    
  }

  ${({ openSpeedModal }) => openSpeedModal && `
    z-index: 14;
  `}
`;
const HomeLayout = styled.div`
  /* display: grid; */
  color: #2beead;
  color: #ebfef3;
  font-family: Metropolis, Arial, Helvetica, sans-serif;

  background: #0c1a61;

  li {
    list-style: none;
  }
  /* max-height:100vh; */
`;

const CheckInWrapper = styled.div`
  /* max-height: 100%;
  grid-area: 1 / 2 / 3 / 3;  */
`;

const LogOutContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  /* Existing styles */

  animation: pulse 4.5s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.99);
    }
    100% {
      transform: scale(1);
    }
  }
`;
const LogOut = styled.div`
  /* text-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.75);
  margin-top: 1em;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.5);
  font-family: Arial, Helvetica, sans-serif;
  width: 4.5em;
  font-weight: 800;
  display: block;
  right: 5em;
  background: #00a3d4;
  color: white;
  line-height: 1.5;
  font-size: 14px; */
  font-family: "Inter", sans-serif;

  z-index: 5;
  right: 0;
  top: 3.2em;
  /* position: fixed; */
  border-radius: 4px;
  background: var(--Schemes-On-Primary-Container, #18124a);
  display: flex;
  max-width: 140px;
  height: 4%;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  /* flex-shrink: 0; */
  /* background: url("/images/dropkins/ui/buttons/tech_buttons_001.jpg") no-repeat; */
  background-size: cover;
  background-position: center;
  /* border-radius: 2px; */
  cursor: pointer;

  :hover {
    background: #0082a1;
  }
`;

const SpaceLogo = styled.div`
  grid-area: 1 / 1 / 2 / 2;
`;
const SpaceMapWrapper = styled.div`
  /* height: 99.5vh; */
  height: calc(var(--vh, 1vh) * 94);
  background: #08113d;

  @media screen and (max-width: ${ui.tablet}) {
    height: calc(var(--vh, 1vh) * 89.6);
  }
  /* grid-area: 1 / 1 / 4 / 4; */
`;

const UserBar = styled.div`
  /* pointer-events: none; */
  top: 4em;
  z-index: 11;
  display: block;
  width: 100%;
  height: 1vh;
  position: absolute;
`;

const CheckMeIn = styled.div`
  grid-area: 1 / 2 / 3 / 3;
  display: grid;
`;

const LogoText = styled.h1`
  padding: 0.3em;
  background: #315f88;
  grid-area: 1 / 2 / 3 / 3;
`;

const OverlayPanel = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;

  ${({ toggle }) =>
    !toggle &&
    `
    display: none;
  `}
`;

const CloseOverlayPanel = styled.div`
  position: absolute;
  display: block;
  top: 0;
  width: 20px;
  height: 20px;
  align-self: flex-end;
  display: flex;
  right: 1.5em;
  z-index: 11;
  margin-top: 1.51em;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;

  &:hover {
    transform: rotate(360deg);
  }
`;

const OnBoardContainer = styled.div`
  background: #07103b;
  border-radius: 4px;
  padding: 1em 2em;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  margin-top: 4em;
  display: none;

  ${({ toggle }) =>
    !toggle &&
    `
    display: none;
  `}
`;

const OnboardPanel = styled.div`
  display: none;
  ${({ toggle }) =>
    !toggle &&
    `
    display: none;
  `}
`;

const OnboardPanelText = styled.div`
  max-width: 200px;
  width: 100%;
  font-size: 0.78em;
  align-items: center;
  line-height: 1.3;
`;

const OnboardPanelNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.88em;
`;
const OnboardNavBtn = styled.div`
  line-height: 2;
  flex-grow: 1;
  background: #ff9300;
  cursor: pointer;
  font-size: 0.85em;

  ${({ toggle }) =>
    !toggle &&
    `
    display: none;
  `}

  &:last-of-type {
    margin-left: 0.2em;
  }
`;

const OverlayHeader = styled.div`
  margin-top: 0.5em;
  font-size: 0.88em;
  line-height: 1.6;
  font-weight: 600;
  text-align: left;
  line-height: 1.1;
  
`;

export const OverlaySubHeader = styled.div`
  font-size: 0.75em;
  padding: 1em 0;
`;

const pulseAnimation = keyframes`
  0% {
    background-color: #081348;
  }
  50% {
    background-color: #0c1d6e;
  }
  100% {
    background-color: #081348;
  }
`;

export const OverlayCTAHeader = styled.div`
  font-size: 1em;
  padding: 0.5em 0;
  font-weight: 700;
  cursor: initial;
`;

const SmallOverlayContainer = styled.div`
  background: #081348;
  border-radius: 4px;
  padding: 1em 2em;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;
  top: 10%;
  margin-top: 5em;

  ${({ toggle }) =>
    !toggle &&
    `
    display: none;
  `}
`;

const smallOverlayButton = styled.div`
  line-height: 1.2;
  flex-grow: 1;
  background: #ff9300;
  cursor: pointer;
  font-size: 0.85em;
  padding: 0.5em 0.5em;
  flex-basis: 50%;
  align-self: center;
  min-width: 115px;

  ${({ toggle }) =>
    !toggle &&
    `
      display: none;
    `}

  &:last-of-type {
    margin-left: 0.2em;
  }
`;

const InfoPanel = styled(OverlayPanel)``;

const InfoHeader = styled.div`
  font-size: 1.1em;
  color: #fff;
`;

const InfoSubHeader = styled(OverlaySubHeader)`
  max-width: 300px;
`;

const TreasureOverlaySubHeader = styled(OverlaySubHeader)`
  max-width: 200px;

  a {
    color: #eca0a0;
    font-weight: 600;
  }
`;

const TreasurePanelBtns = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.88em;
`;

const TreasureGone = styled(smallOverlayButton)``;

const TreasureThere = styled(smallOverlayButton)``;

const TreasureMarkerContainer = styled(SmallOverlayContainer)``;

const SentLoveContainer = styled(SmallOverlayContainer)``;

const TreasureUGCConfirmation = styled(SmallOverlayContainer)``;

const SendLoveContainer = styled(SmallOverlayContainer)``;

const ShowLove = styled.div`
  font-size: 0.85em;
  padding: 1em 0;
`;

const SendLoveBtn = styled.div`
  background: #ff9300;
  max-width: 190px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  line-height: 2;

  cursor: pointer;
`;

const HeartGraphic = styled.div`
  background: url("/svgs/purple-heart-filled.cvg") no-repeat;
`;

const ShowLoveText = styled.div`
  margin-top: 1em;
  max-width: 200px;
  width: 100%;
  font-size: 0.68em;
  align-items: center;
  line-height: 1.3;
`;

const ShowLoveStats = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
`;

const AboutContainer = styled(SmallOverlayContainer)``;

const SpecialPartnersContainer = styled(SmallOverlayContainer)``;

const Header = styled.div`
  background: #07103c;
  height: 3em;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 3;
  width: 100%;
`;

const HeaderLogo = styled.div``;

const Footer = styled.div`
  height: 3em;
  position: absolute;
  bottom: 0;
  background: #384274;
  z-index: 3;
  width: 100%;
`;

const CovDropsLogo = styled.div`
  position: fixed;
  display: flex;
  z-index: 0;
  bottom: 2.73em;
  right: 3.54em;
  justify-content: center;

  img {
    width: 160px;
  }
`;
const DriveModeHeader = styled.div`
  font-weight: 600;
  font-size: 0.6em;
  padding: 0.1em;
  text-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.75);
  background: #0a1449c9;
  border-radius: 4px;
`;

const DriveModeBtn = styled.div`
  padding: 0.41em;
  width: 66px;
  width: 48px;
  height: 20px;
  margin: 0.53em auto;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: ${ui.mobile}) {
    
  }
`;

const ActiveRidePanel = styled.div`
  display: flex;
  background: #1f2648f3;
  padding: 2em;
  border-radius: 8px;
  border-top: 2px solid #72f4dada;
  top: 7.6vh;
  position: absolute;
  z-index: 11;
  z-index: 10;
  max-width: 400px;
  overflow-y: auto;
  right: 10vw;
  max-height: 80vh;
  max-width: 66%;
  border-left: 2px dotted #2467b9;
  border-right: 2px dotted #2467b9;
  flex-direction: column;
  gap: 4px;
  /* position: fixed; */
  text-align: left;
  ${({ toggle }) =>
    !toggle &&
    `
      display: none;
    `}

  @media screen and (max-width: ${ui.tablet}) {
    right: 5vw;
    padding: 2em;
    min-width: 376px;
  }
  @media screen and (max-width: ${ui.mobile}) {
    right: 1vw;
    padding: 0.5em;
    min-width: 91vw;
    width: 90%;
  }
`;

const ActiveRideHeader = styled.div`
  font-weight: 600;
  font-size: 1.1em;
  margin-bottom: 0.5em;
`;

const ColumnContainer = styled.div`
  /* margin-top: 1em; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* height: 100%; */
  align-items: center;
`;

const ConfirmPickup = styled.div`
  background: #2c522c;
  /* max-width: 190px; */
  width: 100%;
  color: white;
  padding: 0.5em 0.5em;
  border: 1px solid white;
  /* display: flex; */
  justify-content: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  align-items: center;
  border-radius: 8px;
  /* line-height: 2; */
  /* color: #997171; */
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: black;
    background: #38cb38;
  }
`;

export const ActiveRideMap = styled.div`
  /* min-height:326px; */
  /* height: 500px; */
  /* width: 500px; */
  flex-basis: 100%;
  margin-bottom: 1.5em;
  display: flex;

  /* @media screen and (max-width: ${ui.tablet})  {
    flex-direction: column;
    min-height: 500px;
  }
  @media screen and (max-width: ${ui.mobile})  {
    min-width: 300px;
    
  } */
`;

const ActiveRideSubHeader = styled.div`
  font-size: 0.85em;
  padding: 0.6em 0.4em;
  /* #every other one { */
  &:nth-of-type(odd) {
    background: #141726;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: orange;
    }
  }

  &.typewriter {
    animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  }
`;

const DriveModePanel = styled.div`
  /* border-top: 2px solid #72f4dada; */
  flex-wrap: wrap;
  position: fixed;
  display: flex;
  left: 0.0125em;
  padding: 0.2em;
  /* margin-bottom: 0.08em; */
  
  bottom: 0.7em;
  justify-content: flex-start;
  flex-direction: column;
  background: #16181ff1;
  border-radius: 4px;
  /* background: url("/images/dropkins/ui/buttons/tech_buttons_002.jpg") no-repeat; */
  background-size: cover;
  /* background-position: center; */
  
  z-index: 13;

  @media screen and (max-width: ${ui.mobile}) {
    /* bottom: 0.25%; */
    top: 0.1em;
    bottom: initial;
    z-index: 10;

    ${({toggleConfirmationModal}) => toggleConfirmationModal && `
    z-index: 15;
  `}
  }

  ${({toggleConfirmationModal}) => toggleConfirmationModal && `
    z-index: 15;
  `}

  ${({ toggleView }) =>
    toggleView &&
    `
    display: none;
  `}
  ${({ active }) =>
    active &&
    `
  background: url('/images/dropkins/ui/buttons/tech_buttons_001.jpg') no-repeat;
  background-size: cover;
  
  `}
`;

const RidePanel = styled(DriveModePanel)`
  background: #1f2648da;
  padding: 2em;
  border-radius: 6px;
  /* top: 10vh; */
  z-index: 12;
  width: 600px;
  flex-wrap: initial;
  left: 6%;
  top: 5%;

  /* max-height: 80vh; */
  overflow-y: auto;
  flex-direction: column;
  max-width: 60%;
  position: fixed;
  text-align: left;
  /* background: url("/images/dropkins/landing/bg/home_bg_001.jpg") no-repeat; */
  background-size: cover;

  transition: all 0.5s ease-in-out;
  /* Add the following line to trigger the animation */

  ${({ active }) =>
    active &&
    `
    /* ...existing styles... */
    /* Add the following line to trigger the animation */
    transform: translateY(${({ toggle }) => (toggle ? "0" : "100%")});
  `}

  ${({ toggle }) =>
    !toggle &&
    `
    transition: all 1.5s ease-in-out;
      display: none;
    `}

  background: linear-gradient(180deg, #142dbe -26.18%, #040e3e 32.62%), #05114c;
  @media screen and (max-width: ${ui.tablet}) {
    padding: 1em;
    max-height: 82%;
    z-index: 12;
    left: ${({ toggle }) => (toggle ? "1%" : "-100%")};
    top: initial;
    bottom: ${({ toggle }) => (toggle ? "8%" : "-100%")};
    max-width: 90%;
  }
`;

const InfoNav = styled.div`
  position: fixed;
  display: flex;
  z-index: 0;
  bottom: 1em;
  justify-content: center;
  width: 100%;
`;

const InfoLink = styled.div`
  color: white;
  margin: auto;
  background: #3842745e;
  padding: 0.25em;
  font-size: 0.7em;
  margin: 0 0.25em;
  cursor: pointer;
`;

const InfoHref = styled.a`
  color: white;
  font-weight: bold;
`;

const TermsPanel = styled.div`
  display: none;
  position: fixed;
  background: #0b1130;
  color: black;
  bottom: 80px;
  width: 100%;
  height: 70%;
  max-height: 76vh;
  max-width: 700px;
  overflow: auto;
  max-width: 60%;
  padding: 40px;
  border: 1px solid white;

  right: 5%;
  @media screen and (max-width: ${ui.tablet}) {
    right: 1%;
    max-width: 46%;
  }

  ${({ active }) =>
    active &&
    `
    display: flex;
    justify-content: center;
    z-index: 1;
    `}

  z-index: 11;
  iframe {
    background: #fff;
    width: 100%;
  }
`;

const TermsClose = styled.div`
  position: absolute;
  top: 12px;
  color: black;
  right: 7px;
`;

const DistanceSlider = styled.input`
border: 1px solid white;
  width: 90%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin: 16px 0;
  padding: 2px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #0ef215;
    cursor: pointer;
    border: 1px solid #107040;
  }

  &::-moz-range-thumb {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }
`;

const Home = () => {
  const [error, updateError] = useState(null);
  const [resetMarkers, triggerMarkerReset] = React.useState(false);
  const [currentWarnings, setCurrentWarnings] = React.useState([]);
  const [currentWarningsUGC, setCurrentWarningsUGC] = React.useState([]);
  const [resetMarker, setResetMarker] = React.useState(false);
  const [resetContagionMarker, setResetContagionMarker] = React.useState(false);
  const [resetWarningMarker, setResetWarningMarker] = React.useState(false);

  const [sendLove, setSendLove] = React.useState(false);
  const [overlayToggle, setOverlayToggle] = React.useState(true);
  const [overlayData, setOverlayData] = React.useState({});

  const [heartCount, setHeartCount] = React.useState(0);
  const [heartMarkerCount, setHeartMarkerCount] = React.useState(0);
  const [showOnboardContainer, setShowOnboardContainer] = useState(1);
  const [activeOnboardPanel, setActiveOnboardPanel] = useState(0);

  const [friendPanelToggle, setFriendPanelToggle] = useState(false);
  const [statusPanelToggle, setStatusPanelToggle] = useState(false);
  const [activateAlertModal, setActivateAlertModal] = useState(false);
  const [sentLoveContainer, setSentLoveContainer] = useState(false);
  const [treasurePanel, setTreasurePanel] = useState(false);
  const [hopePanelToggle, setHopePanelToggle] = useState(false);
  const [treasureConfirmations, setTreasureConfirmations] = useState({
    confirmations: 0,
    contested: 0,
  });
  const [treasureUGCConfirmation, setTreasureUGCConfirmation] = useState(false);
  const [infoPanelToggle, setInfoPanelToggle] = useState(false);
  const [aboutContainer, setAboutContainer] = useState(false);
  const [specialPartnersContainer, setSpecialPartnersContainer] =
    useState(false);
  const [showOnboardToggle, setShowOnboardToggle] = useState(false);

  const [hopeData, setHopeData] = React.useState({});
  const [foodBankData, setFoodBankData] = React.useState([]);
  const [freeRideToggleFilter, setFreeRideToggleFilter] = useState("free");

  const [distanceFilter, setDistanceFilter] = useState(50);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
  } = useForm();

  const mapZoom = 16;

  const {
    activeFriends,
    setActiveFriends,
    activeFriendsUpdate,
    setActiveFriendsUpdate,
    resetDriverMarker,
    setResetDriverMarker,
    driverMode,
    setDriverMode,
    startLocation,
    setStartLocation,
    destination,
    setDestination,
    ridePanelToggle,
    setRidePanelToggle,
    activeDriverData,
    setActiveDriverData,
    friends,
    setFriends,
    pendingFriends,
    setPendingFriends,
    fiestaMessage,
    setFiestaMessage,
    notification,
    setNotification,
    friendId,
    setFriendId,
    chatPanelUser,
    setChatPanelUser,
    chatMessages,
    setMessages,
    chatId,
    setChatId,
    chatPanelToggle,
    setChatPanelToggle,
    userData,
    setUserData,
    clearTogglesActive,
    setClearTogglesActive,
    setTriggerShow,
    triggerShow,
    triggerLocationGrab,
    grabLocation,
    stripeAccountStatus,
    setStripeAccountStatus,
    dashPanel,
    setDashPanel,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
    toggleConfirmationModal,
    setToggleConfirmationModal,
    confirmationModalText,
    setConfirmationModalText,
    confirmationAlertText,
    setConfirmationAlertText,
    confirmationHeaderText,
    setConfirmationHeaderText,
    termsToggle,
    setTermsToggle,
    termsPanelToggle,
    setTermsPanelToggle,
    loading,
    setLoading,
    inviteCode,
    setInviteCode,
    currentUser,
    customerData,
    passengerCountFilter,
    setPassengerCountFilter,
    latestMarker,
    isSubscribed,
    setLatestMarker,
    searchPanelToggle,
    setSearchPanelToggle,
    setOpenPanel,
    setPanelTrigger,
    freeRides,
    setMessageToken,
    setActiveFriendPanel,
    openSpeedModal, setOpenSpeedModal,
    
  } = useContext(AuthContext);

  //  const generateInviteLink = (inviteCode) => {
  //   const inviteLink = `https://dropkins.com/invite?code=${inviteCode}`;
  //     // const inviteLink = `https://dropkins.com/invite/${inviteEmail}`;
  //     return inviteLink;
  //   };

  const [searchParams] = useSearchParams();
  const getCode = searchParams.get("code"); // replace 'inviteCode' with the name of your parameter
 // replace 'inviteCode' with the name of your parameter

  // Function to encode invite code// Function to encode invite code
  const encodeInviteCode = (inviteCode) => {
    // console.log(btoa(inviteCode));
    // console.log(decodeInviteCode(btoa(inviteCode)));
    return btoa(inviteCode);
  };

  // Function to decode invite code
  const decodeInviteCode = (encodedInviteCode) => {
    return atob(encodedInviteCode);
  };

  const formatPhoneNumber = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/-/g, "");
    const numberFormat = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    return numberFormat;
  };

  

  const resetNotifications = async () => {
    setNotification(null);
    setFiestaMessage(null);
  };

  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };

  const generateInviteCode = (userId) => {
    const inviteCode = `dropkins_${userId}_invite`;
    const encodedInviteCode = encodeInviteCode(inviteCode);
    return encodedInviteCode;
  };

  const generateInviteLink = (encodedInviteCode) => {
    const inviteCode = generateInviteCode(userData?.userId);
    const inviteLink = `https://dropkins.com/signup?code=${inviteCode}`;
    return inviteLink;
  };

  const [isCopied, setIsCopied] = React.useState(false);
  const CopyToClipboard = ({ text }) => {
    const copyToClipboard = () => {
      navigator.clipboard.writeText(text);
      setIsCopied(true);
    };
    return (
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "0.5em" }}>
        <button onClick={copyToClipboard}>{isCopied? "Copied" : "Copy Referral"}</button>
      </div>
    );
  };
  //   const generateInviteCode = (userId) => {

  //     const currentDate = getCurrentDate();
  //     const inviteCode = `dropkins_${userId}_invite`;

  //   // Create a SHA256 hash of the inviteCode
  //     const hashedInviteCode = SHA256(inviteCode).toString();
  //     // // Create a sha256 hash of the inviteCode
  //     // const hash = crypto.createHash('sha256');
  //     // hash.update(inviteCode);
  //     // const hashedInviteCode = hash.digest('hex');

  //     return hashedInviteCode;
  // }

  const submitNotificationDetails = useCallback(async () => {
    const { smsPhone, smsName, enableSMS } = getValues();
    const checkSmsPhone = await trigger("smsPhone");
    const checkSmsName = await trigger("smsName");
    
    // console.log("submitNotificationDetails called", smsPhone, smsName);
    if (!checkSmsPhone) {
      // console.log("submit email errors", errors.inviteEmail);
      setLoading(false);
      notifier("Please enter a valid phone number - US only");
      return;
    }
    if (!checkSmsName) {
      // console.log("submit email errors", errors.inviteEmail);
      setLoading(false);
      notifier("Please enter a valid Name");
      return;
    }

    // const userDataRef = firebase.database().ref(`users/${currentUser.uid}/userData`);
    storeSMSPreferences(smsPhone, smsName, enableSMS, setLoading, fiesta);
  }, []);

  const submitEmailInvite = useCallback(async () => {
    // e.preventDefault()
    // console.log("submitEmailInvite called");
    // generate unique code that ingest userid for unique invite code
    const inviteCode = userData?.inviteCode
      ? userData?.inviteCode
      : generateInviteCode(userData?.userId);

    const checkInviteEmail = await trigger("inviteEmail");
    if (!checkInviteEmail) {
      // console.log("submit email errors", errors.inviteEmail);
      setLoading(false);
      notifier("Please enter a valid email address");
      return;
    }

    // console.log('handle called')
    const { inviteEmail } = getValues();
    // const { email, password } = getValues();

    if (inviteEmail === currentUser?.email) {
      notifier("You cannot invite yourself!");
      setLoading(false);
      return;
    }
    const inviteLink = generateInviteLink(inviteEmail, inviteCode);

    const emailExists = await checkForExistingInvite(inviteEmail);
    // console.log("emailExists", emailExists, inviteEmail);

    if (!emailExists) {
      // Usage example
      await storeInvitedUser(inviteEmail, inviteCode, inviteLink);
      try {
        // const sendInviteEmail = httpsCallable(cloudFunctions, "sendInviteEmail");
        const emailData = {
          email: inviteEmail,
          fromEmail: "contact@dropkins.com",
          templateId: "d-5ebfde1c8d1547779657185341de943c",
          senderName: userData?.userName,
          inviteLink,
        };

        // Function's endpoint URL
        const functionUrl = process.env.REACT_APP_FUNCTION_SEND_INVITE_MAIL;
        // Use Fetch API to make a POST request

        // fiesta("Success! Invite Sent!");
        // setLoading(false);
        // console.log("Success:");

        // Re-enable when ready to send emails

        fetch(functionUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailData),
        })
          .then((response) => {
            // console.log({ response });
            setLoading(false);
            setTriggerShow(true);
            return response?.json();
          })
          .then((data) => {
            fiesta("Success! Invite Sent!");
            // console.log("Success:", data);
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false);
            setTriggerShow(true);
            notifier("Uht Oh! Something went wrong!");
          });
      } catch (error) {
        // setTriggerShow(true);
        // console.log("invite error", error);
        setLoading(false);
        notifier(`error! Invite Sent!`);
        // setError("inviteEmail", "general", error.message);
        // setLoading(false);
      }
    } else {
      // console.log("email already exists");

      notifier("Email already invited!");
      setError("inviteEmail", "general", "Email already invited");
      setLoading(false);
    }
  }, []);

  const getCurrentDate = () => {
    return Date.now().toString();
  };

  const onboardPanelCount = 3;

  useEffect(() => {
    setInviteCode(getCode);
  }, []);

  useEffect(() => {
    const unsubscribe = getDriverMode(setDriverMode, notifier);
    return () => unsubscribe;
  }, []);

  const debouncedListenFor = debounce(listenForFriendChanges, 600);

  useEffect(() => {
    const { smsName, smsPhone, smsEnabled } = customerData || {};
    if (smsName && smsPhone) {
      // console.log('setting phone num', smsName, smsPhone);
      setValue("smsName", smsName);
      setValue("smsPhone", smsPhone);
    } 

    smsEnabled && setValue("enableSMS", true);
  }, [customerData]);

  useEffect(() => {
    const listenfor = debouncedListenFor(
      setFriends,
      setPendingFriends,
      setStartLocation,
      setDestination,
      setClearTogglesActive,
      setUserData,
      setResetDriverMarker,
      activeFriends,
      setActiveFriends,
      notifier
    );
    return () => listenfor;
  }, []);

  useEffect(() => {
    // console.log("!!!", { userData });
    if (userData) {
      if (userData?.activeRide) {
        setRidePanelToggle(false);
        setSearchPanelToggle(false);
      }
      if (userData?.activePassenger) {
        setRidePanelToggle(false);
        setSearchPanelToggle(false);
      }
    }
  }, [userData]);
  useEffect(() => {
    // console.log("!!!**", activeFriends);
  }, [activeFriends]);
  useEffect(() => {
    // console.log("!!!**", { termsPanelToggle });
  }, [termsPanelToggle]);

  // useEffect(() => {
  //   console.log({ ridePanelToggle });
  // }, [ridePanelToggle]);

  // useEffect(() => {
  //   console.log({ activeFriends });
  // }, [activeFriends]);

  // useEffect(() => {
  //   console.log("homelocation change", { startLocation, destination });
  //   setStartLocation(startLocation);
  //   setDestination(destination);
  //   // getPlacePredictions(startLocation);
  // }, [startLocation, destination]);

  // useEffect(() => {
  // console.log(overlayData);
  // if ( overlayData && overlayData.userId) {
  //   // console.log('seting hears count')
  //   const userId = overlayData.userId;
  //   getHeartCount(userId).then((res) => {
  //     // console.log(res);
  //     setHeartMarkerCount(res);
  //   })

  // }

  // if ( overlayData && overlayData.treasureId) {
  //   getTreasureData(overlayData.treasureId).then((res) => {
  //     // console.log(res);
  //     setTreasureConfirmations(res);
  //   });
  // }

  // }, [overlayData]);

  useEffect(() => {
    // console.log(overlayData)
    // const unsubscribe = checkDontShowOnboard({
    //   setShowOnboardContainer,
    //   setShowOnboardToggle,
    // });
    // const unsubscribe = db.collection('send-love').where('userId', '==', getUserId())
    // .onSnapshot((hearts) => {
    //   // console.log(hearts )
    //   setHeartCount(hearts.size);
    // })
    // return () => unsubscribe;
  }, []);

  const submitSendLove = async () => {
    await sendHeartTo(overlayData.markerId);
    setOverlayData({});
    setSentLoveContainer(true);
  };

  const getSelectedImage = () => {
    const item = overlayData.selectedItem;
    const itemImages = {
      tp: "images/tp-icon-small.png",
    };

    // console.log(item, itemImages[item])

    return item && itemImages[item];
  };

  const confirmTreasureSighting = async () => {
    const treasureId = overlayData.treasureId;
    const treasureMarkersRef = collection(kinDb, "treasureMarkers");
    const treasureQuery = query(
      treasureMarkersRef,
      where(firebase.firestore.FieldPath.documentId(), "==", treasureId)
    );

    const treasureSnapshot = await getDocs(treasureQuery);
    const treasureMarker = treasureSnapshot.docs[0];

    if (treasureMarker.exists) {
      const treasureData = treasureMarker.data();
      const confirmationsRef = collection(
        kinDb,
        "treasureMarkers",
        treasureId,
        "confirmations"
      );

      await updateDoc(confirmationsRef, {
        confirmations: treasureData.confirmations + 1,
      });

      firebase.analytics().logEvent("Confirm Treasure");
      setOverlayData({});
      setTreasurePanel(false);
      setTreasureUGCConfirmation(true);
    } else {
      // console.log("No treasure found with the given treasureId");
    }
  };

  const treasureGone = async () => {
    const treasureId = overlayData.treasureId;
    getDocs(collection(kinDb, "treasureMarkers", treasureId))
      // await db.collection('treasureMarkers').doc(treasureId).get()
      .then(async function (treasureMarker) {
        const treasure = treasureMarker && treasureMarker.data();
        //  treasure.userId
        // console.log(treasure && treasure.userId, 'contesting treasure', treasureId, treasureMarker.data());
        //  if(treasureMarker && !treasureMarker.empty) {  treasureMarker.data().userId) }
        if (treasure && treasureId) {
          // await db.collection('treasureMarkers').doc(treasureId).get()
          // .then( async function (res) {
          // console.log(address);
          if (treasureMarker.empty) {
          } else {
            const treasureData = treasureMarker.data();

            await db
              .collection("treasureMarkers")
              .doc(treasureId)
              .update({
                contested: treasureData.contested + 1,
              });
            firebase.analytics().logEvent("Contest Treasure");

            setOverlayData({});
            setTreasurePanel(false);
            setTreasureUGCConfirmation(true);
          }

          // console.log(treasureMarker.empty);
          // treasureMarker.docs.map(doc => {console.log(doc.data())});
          return treasureMarker.empty;
          //  });
        }
      });
  };

  const clearInfoPanel = () => {
    setAboutContainer(false);
    setSpecialPartnersContainer(false);
  };

  const handleImageError = (e) => {
    e.target.src = "images/ui/sidePane/space_astronaut.svg";
  };

  // overlayData.userId && console.log('we in this', getHeartCount(overlayData.userId).then(res => console.log(res)))

  return (
    <>
      <HomeLayout>
        {/* <Header>
          <HeaderLogo>

          </HeaderLogo>
        </Header> */}
        <UserBar>
          {/* <SpaceLogo>
            <LogoText>SocialSpacing</LogoText>
          </SpaceLogo> */}
          <CheckInWrapper>
            <SetMarker
              error={error}
              updateError={updateError}
              resetMarkers={resetMarkers}
              triggerMarkerReset={triggerMarkerReset}
              resetMarker={resetMarker}
              setResetMarker={setResetMarker}
              resetWarningMarker={resetWarningMarker}
              setResetWarningMarker={setResetWarningMarker}
              currentWarnings={currentWarnings}
              setCurrentWarnings={setCurrentWarnings}
              currentWarningsUGC={currentWarningsUGC}
              setCurrentWarningsUGC={setCurrentWarningsUGC}
              searchPanelToggle={searchPanelToggle}
              setSearchPanelToggle={setSearchPanelToggle}
              friendPanelToggle={friendPanelToggle}
              setFriendPanelToggle={setFriendPanelToggle}
              statusPanelToggle={statusPanelToggle}
              setStatusPanelToggle={setStatusPanelToggle}
              setHopePanelToggle={setHopePanelToggle}
              hopePanelToggle={hopePanelToggle}
              activateAlertModal={activateAlertModal}
              setActivateAlertModal={setActivateAlertModal}
              resetContagionMarker={resetContagionMarker}
              setResetContagionMarker={setResetContagionMarker}
              heartCount={heartCount}
              latestMarker={latestMarker}
              setLatestMarker={setLatestMarker}
              hopeData={hopeData}
              setHopeData={setHopeData}
              foodBankData={foodBankData}
            />
          </CheckInWrapper>
        </UserBar>

        {/* Temp Hiding for live */}
        {/* <CheckInsList 
              resetMarkers={resetMarkers}
              triggerMarkerReset={triggerMarkerReset}
              resetMarker={resetMarker}
              setResetMarker={setResetMarker}
              resetWarningMarker={resetWarningMarker}
              setResetWarningMarker={setResetWarningMarker}
             /> */}

        <WarningsPanel
          currentWarnings={currentWarnings}
          setCurrentWarnings={setCurrentWarnings}
          currentWarningsUGC={currentWarningsUGC}
          setCurrentWarningsUGC={setCurrentWarningsUGC}
        />

        <OverlayPanel toggle={overlayToggle}>
          <TreasureMarkerContainer toggle={treasurePanel}>
            <CloseOverlayPanel
              onClick={() => {
                setOverlayToggle(false);
                setTreasurePanel(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            {overlayData.title && (
              <OverlayHeader>{overlayData.title}</OverlayHeader>
            )}
            <OverlayHeader>{overlayData.address}</OverlayHeader>

            {overlayData.address && (
              <TreasureOverlaySubHeader>
                <a
                  href={`https://maps.google.com/?q=${overlayData.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Click for Directions
                </a>
              </TreasureOverlaySubHeader>
            )}
            {overlayData.phone && (
              <TreasureOverlaySubHeader>
                Phone:{" "}
                <a href={`tel:${overlayData.phone}`}>{overlayData.phone}</a>
              </TreasureOverlaySubHeader>
            )}
            <TreasureInfoContent>{overlayData.details}</TreasureInfoContent>
            {overlayData.url && (
              <TreasureOverlaySubHeader>
                <a href={`${overlayData.url}`} target="_blank" rel="noreferrer">
                  Click here to view Link
                </a>
              </TreasureOverlaySubHeader>
            )}
            <TreasureOverlaySubHeader>
              Reported: {Date(overlayData.time)}
            </TreasureOverlaySubHeader>
            <ShowLove>
              <ShowLoveStats>
                <img src={getSelectedImage()} alt="" /> Please Confirm Reports
                Below{" "}
              </ShowLoveStats>
            </ShowLove>
            <ShowLoveStats>
              confirmed reports: {treasureConfirmations.confirmations}{" "}
            </ShowLoveStats>
            <ShowLoveStats>
              no longer available reports: {treasureConfirmations.contested}{" "}
            </ShowLoveStats>
            <TreasurePanelBtns>
              <TreasureThere
                toggle={overlayData.showTreasureContainer}
                onClick={() => confirmTreasureSighting()}
              >
                Confirm Report
              </TreasureThere>
              <TreasureGone
                toggle={overlayData.showTreasureContainer}
                onClick={() => treasureGone()}
              >
                Not Available
              </TreasureGone>
            </TreasurePanelBtns>
          </TreasureMarkerContainer>

          <SendLoveContainer toggle={overlayData.showLoveContainer}>
            <CloseOverlayPanel
              onClick={() => {
                setOverlayToggle(false);
                setOverlayData({});
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            <OverlayHeader>{overlayData.address}</OverlayHeader>
            {/* <OverlaySubHeader>
              {getCurrentDate()}
            </OverlaySubHeader> */}
            <ShowLoveText>
              If this was helpful you can thank the user for updating their
              status and send them heart felt love and support as they navigate
              covid.
            </ShowLoveText>
            <ShowLove>
              <HeartGraphic filled={overlayData.hasHearts} />
              <ShowLoveStats>
                <img src="/svgs/purple-heart-filled.svg" alt="" />{" "}
                {`${heartMarkerCount} People Sent Love`}
              </ShowLoveStats>
            </ShowLove>

            <SendLoveBtn onClick={() => submitSendLove()}>
              send love
            </SendLoveBtn>
          </SendLoveContainer>

          <SentLoveContainer toggle={sentLoveContainer}>
            <CloseOverlayPanel
              onClick={() => {
                setOverlayToggle(false);
                setSentLoveContainer(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            <OverlayHeader>Your Love is on the way!</OverlayHeader>
            <OverlaySubHeader>{Date(getCurrentDate())}</OverlaySubHeader>
            <ShowLove>
              <HeartGraphic filled={overlayData.hasHearts} />
              <ShowLoveStats>
                <img src="/svgs/purple-heart-filled.svg" alt="" />{" "}
                {`${heartMarkerCount} People Sent Love`}
              </ShowLoveStats>
            </ShowLove>
          </SentLoveContainer>

          <TreasureUGCConfirmation toggle={treasureUGCConfirmation}>
            <CloseOverlayPanel
              onClick={() => {
                setOverlayToggle(false);
                setTreasureUGCConfirmation(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            <OverlayHeader>Thanks for confirming for us!</OverlayHeader>
            <OverlaySubHeader>{Date(getCurrentDate())}</OverlaySubHeader>
            <ShowLove>
              <HeartGraphic filled={overlayData.hasHearts} />
              <ShowLoveStats>We've updated the marker stats</ShowLoveStats>
            </ShowLove>
          </TreasureUGCConfirmation>

          <OnBoardContainer toggle={showOnboardContainer}>
            <CloseOverlayPanel
              onClick={() => {
                setOverlayToggle(false);
                setShowOnboardContainer(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>

            <OnboardPanel toggle={!activeOnboardPanel}>
              <OverlayHeader>Welcome Friend!</OverlayHeader>
              <OverlaySubHeader>
                Some simple tips for orientation:
              </OverlaySubHeader>
              <OnboardPanelText>
                <img src="/svgs/orange-pin-marker.svg" alt="" />
              </OnboardPanelText>
              <OnboardPanelText>
                Only you can see your ride markers. If any of us start
                exhibiting symptoms, we can elect to report them and alert
                others to take precautions and even trigger clean up efforts for
                locations you've visited in the last 14 days.
              </OnboardPanelText>
              <OnboardPanelNav>
                <OnboardNavBtn
                  toggle={activeOnboardPanel}
                  onClick={() => setActiveOnboardPanel(activeOnboardPanel - 1)}
                >
                  Prev
                </OnboardNavBtn>
                <OnboardNavBtn
                  toggle={activeOnboardPanel < onboardPanelCount}
                  onClick={() => setActiveOnboardPanel(activeOnboardPanel + 1)}
                >
                  Next
                </OnboardNavBtn>
              </OnboardPanelNav>
            </OnboardPanel>
            <OnboardPanel toggle={activeOnboardPanel === 1}>
              <OverlayHeader>Updating your Status</OverlayHeader>
              <OverlaySubHeader>
                So how do we alert others you ask?
              </OverlaySubHeader>
              <OnboardPanelText>
                <img src="/svgs/nav-status-btn.svg" alt="" />
              </OnboardPanelText>
              <OnboardPanelText>
                Clicking on the status icon allows you to manage your status.
                Updating it to say you're exhibiting symptoms, will start to
                populate unconfirmed alerts on the map and protect your
                community.
              </OnboardPanelText>
              <OnboardPanelNav>
                <OnboardNavBtn
                  toggle={activeOnboardPanel}
                  onClick={() => setActiveOnboardPanel(activeOnboardPanel - 1)}
                >
                  Prev
                </OnboardNavBtn>
                <OnboardNavBtn
                  toggle={activeOnboardPanel < onboardPanelCount}
                  onClick={() => setActiveOnboardPanel(activeOnboardPanel + 1)}
                >
                  Next
                </OnboardNavBtn>
              </OnboardPanelNav>
            </OnboardPanel>

            <OnboardPanel toggle={activeOnboardPanel === 2}>
              <OverlayHeader>Map Markers</OverlayHeader>
              <OverlaySubHeader>What do alerts mean?</OverlaySubHeader>
              <OnboardPanelText>
                <img src="/svgs/alert-btn.svg" alt="" />
              </OnboardPanelText>
              <OnboardPanelText>
                The alert itself is simply to inform you to consider possible
                precautions. When you check in you may be alerted by proximty of
                recent or present covid reports. In an effort to protect each
                other, unconfirmed covid report markers will display on the map
                for as long as reasonably relevant or verified clean up efforts
                have been initiated.
              </OnboardPanelText>
              <OnboardPanelNav>
                <OnboardNavBtn
                  toggle={activeOnboardPanel}
                  onClick={() => setActiveOnboardPanel(activeOnboardPanel - 1)}
                >
                  Prev
                </OnboardNavBtn>
                <OnboardNavBtn
                  toggle={activeOnboardPanel === onboardPanelCount - 1}
                  onClick={() => {
                    setOverlayToggle(false);
                    setShowOnboardContainer(false);
                    setSearchPanelToggle(true);
                  }}
                >
                  Done
                </OnboardNavBtn>
              </OnboardPanelNav>
            </OnboardPanel>
            <FormQuestion
              onClick={() => {
                setShowOnboardToggle(!showOnboardToggle);
                setDontShowOnboard(!showOnboardToggle);
              }}
            >
              <FormBox active={showOnboardToggle} />{" "}
              <FormLabel> Never Show Again</FormLabel>
            </FormQuestion>
          </OnBoardContainer>
        </OverlayPanel>
        <InfoPanel toggle={infoPanelToggle}>
          <AboutContainer toggle={aboutContainer}>
            <CloseOverlayPanel
              onClick={() => {
                setInfoPanelToggle(false);
                setAboutContainer(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            <InfoHeader>About Us</InfoHeader>
            <InfoSubHeader>
              Site is still in Alpha phase but we wanted to get this to you as
              soon as possible! :)
            </InfoSubHeader>

            <ShowLove>
              <ShowLoveStats>
                Founder + Developer -{" "}
                <InfoHref
                  target="_blank"
                  href="https://www.linkedin.com/in/mackhasham/"
                >
                  {" "}
                  Mack Hasham
                </InfoHref>
              </ShowLoveStats>
            </ShowLove>
            <ShowLove>
              <ShowLoveStats>
                Co-Founder -{" "}
                <InfoHref
                  target="_blank"
                  href="https://www.linkedin.com/in/stephanieh/"
                >
                  {" "}
                  Stephanie Hasham
                </InfoHref>{" "}
              </ShowLoveStats>
            </ShowLove>
            <ShowLove>
              <ShowLoveStats>Hasham Inc. - Further Together.</ShowLoveStats>
            </ShowLove>
          </AboutContainer>

          <SpecialPartnersContainer toggle={specialPartnersContainer}>
            <CloseOverlayPanel
              onClick={() => {
                setInfoPanelToggle(false);
                setSpecialPartnersContainer(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            <InfoHeader>Special Partners</InfoHeader>
            <InfoSubHeader>
              We'd like to express our endless gratitude to our special
              partners. If you're interested in supporting this effort you can
              fill out the form{" "}
              <InfoHref
                target="_blank"
                href="https://forms.gle/d5At4RjJKVnraYXBA"
              >
                {" "}
                here.
              </InfoHref>
            </InfoSubHeader>
            <OverlayHeader>Team Partners</OverlayHeader>
          </SpecialPartnersContainer>
        </InfoPanel>

        <Draggable cancel=".btn">
          <RidePanel id={"ride-drag"} toggle={ridePanelToggle}>
            <CloseOverlayPanel
              className={"btn"}
              onClick={() => {
                setRidePanelToggle(false);
                setOverlayToggle(false);
              }}
            >
              <img src="/svgs/close-btn.svg" alt="" />
            </CloseOverlayPanel>
            <ColumnContainer>
              {userData?.userName && (
                <UserName>{userData?.userName}'s Dashboard</UserName>
              )}
              <UserName className={"typewriter"}>Dropkins Alpha 0.01a</UserName>
            </ColumnContainer>

            <NavBoxContainer className="btn">
              <NavBox
                active={dashPanel === "monitor"}
                onClick={() => setDashPanel("monitor")}
              >
                <NavTitle></NavTitle>
                <NavIcon>
                  <img src="/svgs/nav-ride-request.svg" alt="" />
                </NavIcon>
                <NavText>Monitor Rides</NavText>
                <NavSubText></NavSubText>
              </NavBox>
              <NavBox
                active={dashPanel === "invite"}
                onClick={() => setDashPanel("invite")}
              >
                <NavTitle></NavTitle>
                <NavIcon>
                  <img src="/svgs/chat-nav.svg" alt="Share Invites" />
                </NavIcon>
                <NavText>Share Invites</NavText>
                <NavSubText></NavSubText>
              </NavBox>
              <NavBox
                active={dashPanel === "bank"}
                onClick={() => setDashPanel("bank")}
              >
                <NavTitle></NavTitle>
                <NavIcon>
                  <img src="/svgs/nav-search-btn.svg" alt="Check Earnings" />
                </NavIcon>
                <NavText>Check Earnings</NavText>
                <NavSubText></NavSubText>
              </NavBox>
              <NavBox
                active={dashPanel === "notifications"}
                onClick={() => setDashPanel("notifications")}
              >
                <NavTitle></NavTitle>
                <NavIcon>
                  <img src="/svgs/chat-nav.svg" alt="Contact Settings" />
                </NavIcon>
                <NavText>Contact Settings</NavText>
                <NavSubText></NavSubText>
              </NavBox>
            </NavBoxContainer>

            {userData?.userName && (
              <>
                {/* <SearchCopy className={'btn'}>

                      This site is currently in Alpha, many features are still in
                      development.
                    </SearchCopy> */}
                {/* <SearchCopy className={'btn'}>
                      We have expedited access, so you can make use of it sooner
                    </SearchCopy> */}
              </>
            )}

            <ActiveFriendsContainer
              className={"btn"}
              active={
                userData?.activeRideRequest &&
                userData?.userName &&
                dashPanel === "monitor"
              }
            >
              <GlowBox>
                {/* <MainText>Start: {userData?.activeRideRequest?.location}</MainText> */}
                <MainContentContainer>

                <MainText>Pick Up Location: <strong>{startLocation}</strong></MainText>
                <MainText>Destination: <strong>{destination}</strong></MainText>
                </MainContentContainer>
                <CenterContainer>
                <LinkBtn
                  className={"btn"}
                  onClick={() => {
                    // setStartLocation("");
                    // setDestination("");
                    setTriggerShow(true);

                    toggleActiveRideRequest(
                      false,
                      userData,
                      setUserData,
                      setClearTogglesActive,
                      setTriggerShow,
                      setLoading,
                      notifier,
                      fiesta
                    );
                  }}
                >
                  Cancel Ride Request
                </LinkBtn>

                </CenterContainer>
              </GlowBox>
              <ActiveFriendPanelContainer>
                {/* <ActiveContentHeader className={"btn"}>
                These are your approved friends who are currently active
                drivers.

              </ActiveContentHeader> */}
              </ActiveFriendPanelContainer>
              {userData?.activeRideRequest && !activeFriends?.length && (
                <ActiveContentContainer>
                  <ActiveContentText>
                    First one to approve your request will be assigned to your
                    ride.
                  </ActiveContentText>
                  <GlowBox>
                    <SearchSubHeader>
                      Sorry, you have no active friends available for rides
                    </SearchSubHeader>
                  </GlowBox>
                  <ActiveContentText>
                    You can toggle driver approval on/off in your profile to
                    control who sees your ride requests.
                  </ActiveContentText>
                  <LinkBtn
                    active={friendPanelToggle}
                    onClick={() => {
                      setRidePanelToggle(false);
                      setFriendPanelToggle(true);
                    }}
                  >
                    Manage Network
                  </LinkBtn>
                  {/* <ActiveContentHeader>
                    We make inviting friends easy, and potentially lucrative!
                  </ActiveContentHeader>
                  <ActiveContentHeader>
                    Keep growing your network, and establishing community.
                  </ActiveContentHeader> */}
                </ActiveContentContainer>
              )}

              {activeFriends && (
                <ActiveFriendsListContainer
                  active={userData?.activeRideRequest}
                  className={"btn"}
                >
                  {activeFriends.map((friend, i) => {
                    // console.log(
                    //   userData?.activeRideRequest,
                    //   "home friends active",
                    //   friend
                    // );
                    if (friend.approved === false) return null;
                    // if (!friend.driverMode) return null;
                    // console.log("home friends active", friend);
                    return (
                      <ActiveDriverContainer key={friend.id + i}>
                        <ActiveDriverAvatar>
                          {/* <img src={friend.friendUserAvatar} alt=""/> */}
                          <ActiveDriverImg
                            alt="friend avatar"
                            src={
                              friend?.photoURL
                                ? friend?.photoURL
                                : "images/ui/sidePane/space_astronaut.svg"
                            }
                            onError={handleImageError}
                          />
                        </ActiveDriverAvatar>
                        <AvatarColumnContainer>
                          <ActiveDriverContent>
                            <ActiveDriverName>
                              {friend.friendUserName}
                            </ActiveDriverName>
                            {friend.distanceFromStart && (
                              <ActiveDriverDistance>
                                {friend.distanceFromStart} away from pickup
                                location
                              </ActiveDriverDistance>
                            )}
                            {/* <RequestRideBtn onClick={() => {}}>
                        Send Message
                      </RequestRideBtn> */}
                            <ActivePanelButton
                              onClick={async () => {
                                await setFriendId(friend?.id);
                                await setChatPanelUser(friend?.friendUserName);
                                await loadChatMessages(
                                  `${friend?.id}`,
                                  setMessages,
                                  setChatId,
                                  chatId,
                                  setChatPanelUser,
                                );
                                // setFriendPanelToggle(false);
                                setChatPanelToggle(true);
                              }}
                            >
                              Chat
                            </ActivePanelButton>
                          </ActiveDriverContent>
                        </AvatarColumnContainer>
                      </ActiveDriverContainer>
                    );
                  })}
                </ActiveFriendsListContainer>
              )}
            </ActiveFriendsContainer>

            {!userData?.userName && (
              <OutlineContainer className="btn">
                <GlowBox>
                  <SearchSubHeader>
                    Must set a username to get started
                  </SearchSubHeader>
                </GlowBox>
                <ActiveContentHeader>
                  Required: Click on Manage Network to define your unique
                  username.
                  <br />
                  <br />
                  Your Username is how people can find and add you to their
                  network.
                </ActiveContentHeader>
                <ActivePanelButton
                  className="btn"
                  active={friendPanelToggle}
                  onClick={() => {
                    setRidePanelToggle(false);
                    setFriendPanelToggle(true);
                  }}
                >
                  Manage Network
                </ActivePanelButton>
              </OutlineContainer>
            )}
            <DashboardPanel className={"btn"} toggle={dashPanel === "monitor"}>
              <ActiveFriendsListContainer
                className={"btn"}
                active={
                  userData?.driverMode && userData?.friendRideRequests?.length
                }
              >
                
                <OutlineContainer>
                <OverlaySubHeader>Your friends need Rides!</OverlaySubHeader>
                <ActiveDriverName style={{marginTop: '10px'}}>
               
                    {/* display distanceFilter value */}
                  </ActiveDriverName>
                   
                  <ActiveDriverName style={{marginTop: '10px'}}>
                    How many people can you fit?:{" "}
                    {passengerCountFilter ? passengerCountFilter : null}
                  </ActiveDriverName>

                  
                  <PassengerCountContainer>
                    <PassengerCountBtn
                      seats={1}
                      active={
                        !passengerCountFilter || passengerCountFilter === 1
                      }
                      onClick={() => setPassengerCountFilter(1)}
                    >
                      <PassengerCountBtnContent>1</PassengerCountBtnContent>
                    </PassengerCountBtn>
                    <PassengerCountBtn seats={2}
                      active={!passengerCountFilter || passengerCountFilter === 2}
                      onClick={() => setPassengerCountFilter(2)}
                    >
                      <PassengerCountBtnContent>
                        2
                      </PassengerCountBtnContent>
                    </PassengerCountBtn>
                    <PassengerCountBtn
                      seats={3}
                      active={
                        !passengerCountFilter || passengerCountFilter === 3
                      }
                      onClick={() => setPassengerCountFilter(3)}
                    >
                      <PassengerCountBtnContent>3</PassengerCountBtnContent>
                    </PassengerCountBtn>
                    <PassengerCountBtn
                      seats={4}
                      active={
                        !passengerCountFilter || passengerCountFilter === 4
                      }
                      onClick={() => setPassengerCountFilter(4)}
                    >
                      <PassengerCountBtnContent>4</PassengerCountBtnContent>
                    </PassengerCountBtn>
                    <PassengerCountBtn
                      seats={6}
                      active={
                        !passengerCountFilter || passengerCountFilter === 6
                      }
                      onClick={() => setPassengerCountFilter(6)}
                    >
                      <PassengerCountBtnContent>6</PassengerCountBtnContent>
                    </PassengerCountBtn>
                  </PassengerCountContainer>

                  
                  <DistanceSlider
                  
  type="range"
  onChange={(event) => setDistanceFilter(event.target.value)}
  min={0}
  max={50}
  value={distanceFilter}
  step={0.25}
/>
<ActiveDriverName>Drag slider to Filter Distance: {distanceFilter} Miles</ActiveDriverName>
                </OutlineContainer>

                <ButtonColumnContainer>
                  <CheckBoxContainer
                    active={freeRideToggleFilter === "free"}
                    onClick={(e) => {
                      setFreeRideToggleFilter("free");

                      // setTriggerShow(true);
                      // GetRideEstimate(
                      //   rideEstimate,
                      //   setRideEstimate,
                      //   location,
                      //   dropLocation,
                      //   position,
                      //   rideDistanceEstimate,
                      //   setRideDistanceEstimate,
                      //   notifier,
                      //   fiesta
                      // );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggleFilter === "free"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>Free</UseCurrentText>
                  </CheckBoxContainer>

                  <CheckBoxContainer
                    active={freeRideToggleFilter === "donation"}
                    toggle={false}
                    onClick={(e) => {
                      setFreeRideToggleFilter("donation");
                      // setTriggerShow(true);
                      // GetRideEstimate(
                      //   rideEstimate,
                      //   setRideEstimate,
                      //   location,
                      //   dropLocation,
                      //   position,
                      //   rideDistanceEstimate,
                      //   setRideDistanceEstimate,
                      //   notifier,
                      //   fiesta
                      // );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggleFilter === "donation"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>$ Donation</UseCurrentText>
                  </CheckBoxContainer>

                  <CheckBoxContainer
                    active={freeRideToggleFilter === "paid"}
                    toggle={false}
                    onClick={(e) => {
                      setFreeRideToggleFilter("paid");
                      // setTriggerShow(true);
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggleFilter === "paid"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>$ Paid</UseCurrentText>
                  </CheckBoxContainer>

                  
                </ButtonColumnContainer>

                

                {userData?.friendRideRequests && (
                  <ActiveFriendsListContainer
                    active={userData?.friendRideRequests}
                  >
                    {userData?.friendRideRequests.map((friend, i) => {
                      if (
                        (passengerCountFilter &&
                          passengerCountFilter < friend.passengerCount) ||
                        friend.approved === false
                      )
                        return null;

                        console.log({friend}, 'working with friend')
                        const distanceFromPickup = parseFloat(friend.distance);

                        if(distanceFromPickup > distanceFilter){
                          return null;
                        }

                      if (freeRideToggleFilter !== friend?.rideType)
                        return null;
                      return (
                        <ActiveDriverContainer key={friend?.id + i}>
                          <ActiveDriverAvatar>
                            {friend.rideType !== "free" && (
                              <ActiveDriverName>
                                Est: ${(friend.rideEstimate * 0.8).toFixed(2)}
                              </ActiveDriverName>
                            )}
                            {/* <img src={friend.friendUserAvatar} alt=""/> */}
                            <ActiveDriverImg
                              alt="friend avatar"
                              src={
                                friend?.photoURL || friend?.profilePhoto
                                  ? friend?.photoURL || friend?.profilePhoto
                                  : "images/ui/sidePane/space_astronaut.svg"
                              }
                              onError={handleImageError}
                            />
                            <ActiveDriverSubContainer>
                            <ActiveDriverDisplayName>
                              {friend.userName}
                            </ActiveDriverDisplayName>
                            <ActiveDriverName>
                              {friend.passengerCount}
                              {Array.from(
                                { length: friend.passengerCount },
                                (_, i) => (
                                  <span
                                    key={i}
                                    style={{
                                      paddingLeft: "0.4em",
                                      fontSize: "0.75rem",
                                      position: "relative",
                                      marginLeft: "-0.98em",
                                      left: `${i * 0.1}em`, // Adjust the positioning as needed
                                    }}
                                  >
                                    🧍
                                  </span>
                                )
                              )}

                              {friend.rideType}
                            </ActiveDriverName>
                          </ActiveDriverSubContainer>
                          </ActiveDriverAvatar>

                          <GlowBox>
                            <ActiveDriverContent>
                              <ActiveDriverName>
                                Request From: {friend.userName}
                              </ActiveDriverName>
                              {friend.distance && (
                                <ActiveDriverDistance>
                                  You are {friend.distance} away from pickup
                                </ActiveDriverDistance>
                              )}
                              {friend.rideTimeEstimate && (
                                <ActiveDriverDistance>
                                  Ride will take around:{" "}
                                  {friend.rideTimeEstimate}
                                </ActiveDriverDistance>
                              )}
                              {friend.rideDistanceEstimate && (
                                <ActiveDriverDistance>
                                  Total ride distance is around:{" "}
                                  {friend.rideDistanceEstimate}
                                </ActiveDriverDistance>
                              )}
                              {/* <RequestRideBtn onClick={() => {}}>
                        Send Message
                      </RequestRideBtn> */}
                              {/* Accept Ride Request */}
                              <OutlineColumnContainer style={{ gap: "16px", justifyContent: "center" }}>
                                <LinkBtn
                                  onClick={async () => {
                                    // await setFriendId(friend?.id);
                                    // await setChatPanelUser(friend?.userName);
                                    setTriggerShow(true);
                                    await acceptRideRequest(
                                      friend?.id,
                                      setLoading,
                                      setTriggerShow,
                                      notifier,
                                      fiesta
                                    );
                                    // setFriendPanelToggle(false);
                                    // setChatPanelToggle(true);
                                  }}
                                >
                                  Accept Ride Request
                                </LinkBtn>

                                {/* TODO: fix chat button */}
                                {/* <UserNameButton
                                  onClick={async () => {
                                    await setFriendId(friend?.id);
                                    await setChatPanelUser(friend?.userName);
                                    const chats = userData?.chats;
                                    console.log(chats, {friend})
                                    const chatId = chats && chats[userData?.userId] ? chats[userData?.userId] : null;
                                    console.log({chatId})
                                    await loadChatMessages(
                                      `${friend?.id}`,
                                      setMessages,
                                      setChatId,
                                      chatId
                                    );
                                    // setFriendPanelToggle(false);
                                    setChatPanelToggle(true);
                                  }}
                                >
                                  Chat
                                </UserNameButton> */}
                              </OutlineColumnContainer>
                            </ActiveDriverContent>
                          </GlowBox>
                        </ActiveDriverContainer>
                      );
                    })}
                  </ActiveFriendsListContainer>
                )}
              </ActiveFriendsListContainer>

              {userData?.userName && !userData?.activeRideRequest && !userData?.driverMode && (
                <ActiveContentContainer className="btn">
                  <GlowBox>
                    <SearchSubHeader>
                      You don't have an active ride request
                    </SearchSubHeader>
                  </GlowBox>
                  <FriendContentText>
                    Whether you requested a ride, or offering one,
                    <br /> <br />
                    this is where you can see what's going on.
                  </FriendContentText>
                  <LinkBtn
                    className="btn"
                    active={friendPanelToggle}
                    onClick={() => {
                      setRidePanelToggle(false);
                      setPanelTrigger("clearPanel");
                      setOpenPanel(2);
                      // setSearchPanelToggle(false);
                    }}
                  >
                    Request Ride
                  </LinkBtn>
                </ActiveContentContainer>
              )}
              {!userData?.friendRideRequests?.length &&
                userData?.driverMode && (
                  <OutlineContainer className="btn">
                    <GlowBox>
                      <SearchSubHeader>
                        None of your friends are requesting rides.
                      </SearchSubHeader>
                    </GlowBox>
                    <ActiveContentHeader>
                      Request will show up here if they come in.
                    </ActiveContentHeader>
                    {/* <LinkBtn
                    className="btn"
                    active={friendPanelToggle}
                    onClick={() => {
                      setRidePanelToggle(false);
                      setSearchPanelToggle(true);
                    }}
                  >
                    Request Ride
                  </LinkBtn> */}
                  </OutlineContainer>
                )}
            </DashboardPanel>

            <DashboardPanel className={"btn"} toggle={dashPanel === "invite"}>
              {/* <GlowBox> */}

              <GlowBox>
                <InviteForm onSubmit={handleSubmit(submitEmailInvite)}>
                  {/* <PanelHeader>
              Social Distancing
              A friends and friends of friends platform.
            </PanelHeader> */}
                  {/* Add loading  */}
                  {/* <OutlineAvatarContainer> */}
                  <LoadingContainer loading={!!loading}>
                    {loading && <LoadingSpinner />}
                  </LoadingContainer>

                  <InputContainer>
                    {/* <FormSubHeader>Track places you've visited, and recieve alerts if possibly exposed.</FormSubHeader> */}
                    {/* <InputContainer> */}
                    <ActiveContentHeader>
                      Invite people you'd trust for rides.
                    </ActiveContentHeader>
                      {/* <FormLabel></FormLabel> */}
                      {errors?.inviteEmail && (
                        <ErrorField> {errors.inviteEmail?.message}</ErrorField>
                      )}
                      {/* general errors */}
                      {/* <ErrorField> {errors?.general?.message}</ErrorField> */}
                      <input
                        type="email"
                        // onBlur={() => trigger("inviteEmail")}
                        // name="inviteEmail"
                        placeholder="Enter their email: friend@email.com"
                        {...register("inviteEmail", {
                          required: true,
                          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        })}
                      />
                      {/* <input required {...register('inviteEmail')} autoComplete='false' type="email" name="inviteEmail" placeholder="Friend's email"  ref={register({ required: true, minLength: 4 })} /> */}
                      {/* <input {...register('inviteEmail', { required: true, minLength: 4 })} name={'inviteEmail'} autoComplete="off" type="email" placeholder="Friend's email" /> */}
                    
                      <LinkBtn
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();

                        const check = trigger("inviteEmail");
                        // return check;
                        //  setLoading(true);
                        // const watchedInviteEmail = watch('inviteEmail');
                        const { inviteEmail } = getValues();
                        // console.log('email submit', check, inviteEmail);

                        if (check) {
                          // console.log("checkemail success", check, inviteEmail);
                          // notifier('success')
                          setTriggerShow(true);
                          // notifier("success");
                          setLoading(true);

                          submitEmailInvite();
                        } else {
                          // console.log("check error", check, inviteEmail);
                          // console.log(check);
                          setTriggerShow(true);
                          fiesta(
                            "Oops, something went wrong. Please try again."
                          );
                          setLoading(false);
                        }

                        // const check = Object.keys(errors).length;
                        // console.log(check, 'check', errors)
                        // if (!check){ console.log(check); submitEmailInvite(e)}}
                        // };
                      }}
                    >
                      Send Invite
                    </LinkBtn>
                    </InputContainer>
                    
                  {/* </InputContainer> */}
                  {/* <InputContainer> */}
                  {/* <InputContainer> */}

                  {/* </InputContainer> */}
                  {/* </InputContainer> */}
                  {/* </OutlineAvatarContainer> */}
                </InviteForm>
                <OutlineAvatarContainer 
                  style={{display: 'flex', justifySelf: 'center', maxWidth: '70%', margin: '10px auto'}}
                className="btn">
                  <InputContainer>
                    <ActiveContentHeader>
                      Here's your unique referral link
                    </ActiveContentHeader>
                    <ActiveContentText>
                      {generateInviteLink()}
                    </ActiveContentText>
                  </InputContainer>
                  <CopyToClipboard text={generateInviteLink()}>
                              <LinkBtn onClick={() => alert("Link copied to clipboard!")}>
                              Copy Link
                              </LinkBtn>
                            </CopyToClipboard>
                  {/* <InputContainer>
                    <UserName>
                      Here's your unique referral code
                    </UserName>
                    <ActiveContentText>
                    {generateInviteCode(userData?.userId)}
                    </ActiveContentText>
                  </InputContainer> */}
                </OutlineAvatarContainer>
              </GlowBox>
              {/* </GlowBox> */}
            </DashboardPanel>

            <DashboardPanel className={"btn"} toggle={dashPanel === "bank"}>
              {/* <GlowBox> */}
              <GlowBox className="btn">
                <InputContainer>
                  <ActiveContentHeader>Earnings Dashboard</ActiveContentHeader>
                  <ContentText>
                    Stripe Users Total Earnings: ${stripeAccountStatus?.totalEarnings?.toFixed(2)}
                  </ContentText>
                  <OutlineContainer>
                    <GlowBox>
                      <UserName>
                        {" "}
                        Free Ride Requests: {freeRides} available
                      </UserName>
                    </GlowBox>
                  </OutlineContainer>
                  {/* <ActivePanelButton> */}
                  {/* {userData?.stripeLink && (
                    <LinkBtn small href={userData?.stripeLink}>
                      Click to Manage Pay Account
                    </LinkBtn>
                  )}  */}
                  {/* </ActivePanelButton>  */}

                  <OverlayHeader>
                    Registering as an independent vendor and confirming your identity is required to be a paid driver. 
                    Vendor account settings can be updated below.
                  </OverlayHeader>
                  {!userData?.stripeLink &&
                    !stripeAccountStatus?.isAccountSetupComplete && (
                      <StripeLinkButton className={"btn"} />
                    )}
                  {stripeAccountStatus?.isAccountSetupComplete && (
                    <StripeUpdateButton className={"btn"} />
                  )}
                </InputContainer>
              </GlowBox>
              {/* </GlowBox> */}
            </DashboardPanel>

            <DashboardPanel className={"btn"} toggle={dashPanel === "notifications"}>
              {/* <GlowBox> */}
              <GlowBox className="btn">
                {!isSubscribed && <InputContainer>
                  <ActiveContentHeader>Contact Settings</ActiveContentHeader>
                  <NotificationsForm onSubmit={handleSubmit(submitNotificationDetails)}></NotificationsForm>
                  <OutlineContainer>
                    <GlowBox>
                      <UserName>
                        {" "}
                        { customerData?.smsName ? `Name: ${formatPhoneNumber(customerData?.smsName)}` : "Please complete the form below"}
                        <br />
                        { customerData?.smsPhone ? `Number: ${formatPhoneNumber(customerData?.smsPhone)}` : "to recieve SMS notifications"}
                      </UserName>
                    </GlowBox>
                  </OutlineContainer>
                  <InputContainer>
                    {/* <FormSubHeader>Track places you've visited, and recieve alerts if possibly exposed.</FormSubHeader> */}
                    {/* <InputContainer> */}

                    <GlowBox className="btn">
                    

{/* // add toggle to enable and disable sms notifications */}
<div
  style={{
    display: "flex",
    borderRadius: "10px",
    padding: "10px",
    background: `${getValues("enableSMS") ? "#090b4b" : "#101012b1"}`,
    border: "1px solid white",
    cursor: "pointer",
    width: "130px",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
    
  }}
  onClick={() => setValue("enableSMS", !getValues("enableSMS"))}
>
  <input
    style={{ flexBasis: "20%", cursor: "pointer" }}
    type="checkbox"
    {...register("enableSMS")}
  />
  <label
    style={{
      width: "100px",
      fontWeight: "bold",
      display: "flex",
      cursor: "pointer",
      flexBasis: "100%",
      flexDirection: "column"
    }}
    htmlFor="enableSMS"
  >
    Enable SMS
  </label>
</div>
{errors?.smsName && (
  <ErrorField> {errors.smsName?.message}</ErrorField>
)}

                      {/* <ErrorField> {errors?.general?.message}</ErrorField> */}
                      <input
                        type="text"
                        // onBlur={() => trigger("inviteEmail")}
                        // name="inviteEmail"
                        placeholder="What should we call you?"
                        {...register("smsName", {
                          required: true,
                          pattern: /^[A-Za-z]+$/,
                          message: "Please enter what you'd like us to call you",
                        })}
                      />



                      
                      {/* general errors */}
                      {/* <ErrorField> {errors?.general?.message}</ErrorField> */}
                      <input
                        type="phone"
                        // onBlur={() => trigger("inviteEmail")}
                        // name="inviteEmail"
                        placeholder="Enter your Phone Number"
                        {...register("smsPhone", {
                          required: true,
                          pattern: {
                            value: /^\d{10}$/,
                            message: "Phone number must be 10 digits",
                          },
                        })}
                      />
                      {errors?.smsPhone && (
                        <ErrorField> {errors.smsPhone?.message}</ErrorField>
                      )}

<LinkBtn
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();

                        const checkPhone = trigger("smsPhone");
                        const checkName = trigger("smsName");
                        // return check;
                        //  setLoading(true);
                        // const watchedInviteEmail = watch('inviteEmail');
                        // const { inviteEmail } = getValues();
                        // console.log('email submit', check, inviteEmail);

                        if (checkPhone && checkName) {
                          // console.log("checkemail success", check, inviteEmail);
                          // notifier('success')
                          setTriggerShow(true);
                          // notifier("success");
                          setLoading(true);

                          submitNotificationDetails();
                        } else {
                          // console.log("check error", check, inviteEmail);
                          // console.log(checkPhone);
                          setTriggerShow(true);
                          fiesta(
                            "Oops, something went wrong. Please try again."
                          );
                          setLoading(false);
                        }

                        // const check = Object.keys(errors).length;
                        // console.log(check, 'check', errors)
                        // if (!check){ console.log(check); submitEmailInvite(e)}}
                        // };
                      }}
                    >
                      Update Preferences
                    </LinkBtn>
                    <OverlayHeader>
                      Must Submit Contact Preferences to enable SMS notifications
                    </OverlayHeader>

                    </GlowBox>
                  </InputContainer>
                  
                  {/* <ActivePanelButton> */}
                  {/* {userData?.stripeLink && (
                    <LinkBtn small href={userData?.stripeLink}>
                      Click to Manage Pay Account
                    </LinkBtn>
                  )}  */}
                  {/* </ActivePanelButton>  */}

                 
                </InputContainer>}
              </GlowBox>
              {/* </GlowBox> */}
            </DashboardPanel>

            {userData?.userName &&
              !stripeAccountStatus?.isAccountSetupComplete && (
                <InputContainer className={"btn"}>
                  <OverlayHeader>
                    Make sure to finalize Bank information to enable paid rides
                    or commissions from friends you invited!:
                  </OverlayHeader>
                  <StripeLinkButton className={"btn"} />
                </InputContainer>
              )}

            {/* <OverlaySubHeader>
            {overlayData.address}
          </OverlaySubHeader>
          <OverlaySubHeader>
            {overlayData.time}
          </OverlaySubHeader>
          <OverlaySubHeader>
            {overlayData.distance}
          </OverlaySubHeader>
          <OverlaySubHeader>
            {overlayData.duration}
          </OverlaySubHeader>
          <OverlaySubHeader>
            {overlayData.price}
          </OverlaySubHeader>

             */}
          </RidePanel>
        </Draggable>

        <LogOutContainer>
          <LogOut onClick={() => logOut()}>Sign Out</LogOut>
        </LogOutContainer>
        {/* <CheckMeIn>
          Check In
        </CheckMeIn> */}
        <SpaceMapWrapper>
          <SpaceMap
            isMarkerShown
            resetMarkers={resetMarkers}
            triggerMarkerReset={triggerMarkerReset}
            resetMarker={resetMarker}
            setResetMarker={setResetMarker}
            resetWarningMarker={resetWarningMarker}
            setResetWarningMarker={setResetWarningMarker}
            resetContagionMarker={resetContagionMarker}
            setResetContagionMarker={setResetContagionMarker}
            setOverlayData={setOverlayData}
            setOverlayToggle={setOverlayToggle}
            setTreasurePanel={setTreasurePanel}
            latestMarker={latestMarker}
            setLatestMarker={setLatestMarker}
            setHopePanelToggle={setHopePanelToggle}
            hopeData={hopeData}
            setHopeData={setHopeData}
            setFoodBankData={setFoodBankData}
          />
        </SpaceMapWrapper>
        {/* <Footer></Footer> */}

        <Draggable cancel=".btn">
          <DriveModePanel
            active={userData?.driverMode}
            toggleView={userData?.currentRide?.started || !userData?.userName}
            toggleConfirmationModal={toggleConfirmationModal}
          >
            {toggleConfirmationModal && (
              <ConfirmationModal
                toggle={toggleConfirmationModal}
                onConfirm={() => onConfirm()}
                onCancel={() => onCancel()}
                confirmationAlertText={confirmationAlertText}
                confirmationModalText={confirmationModalText}
                confirmationHeaderText={confirmationHeaderText}
                termsToggle={termsToggle}
                setTermsToggle={() => setTermsToggle}
                setTermsPanelToggle={setTermsPanelToggle}
                setLoading={setLoading}
              />
            )}
            <DriveModePanel
              toggleView={
                toggleConfirmationModal ||
                userData?.activeRide ||
                userData?.activePassenger ||
                userData?.activeRideRequest ||
                stripeAccountStatus?.isAccountSetupComplete
              }
            >
              {/* <NavActionBtn className="btn">
              Required to accept paid rides
              {!userData?.stripeLink &&
                    !stripeAccountStatus?.isAccountSetupComplete && (
                      <StripeLinkButton className={"btn"} />
                    )} 
                    
                   
              </NavActionBtn> */}
            </DriveModePanel>
            <DriveModePanel
              toggleView={
                toggleConfirmationModal ||
                userData?.activeRide ||
                userData?.activePassenger
                //||
                // userData?.activeRideRequest
                // || !stripeAccountStatus?.isAccountSetupComplete
              }
            >
              {triggerShow && (
                <NotificationWrapper
                  setTriggerShow={() => setTriggerShow}
                  triggerShow={triggerShow}
                  setFiestaMessage={() => setFiestaMessage}
                  setNotification={() => setNotification}
                  success={fiestaMessage?.length > 0}
                  alert={notification?.length > 0}
                >
                  {/* <> {fiestaMessage?.length && (
                      <SuccessContainer>{fiestaMessage}</SuccessContainer>
                    )}
                    {notification?.length && (
                      <AlertsContainer>{notification}</AlertsContainer>
                    )}
                    </> */}

                  {fiestaMessage?.length > 0 && fiestaMessage}

                  {notification?.length > 0 && notification}
                </NotificationWrapper>
              )}
              <DriveModeHeader>{userData?.userName} </DriveModeHeader>
              
              



             
              
              <DriveModeHeader>Driver Mode</DriveModeHeader>
              {driverMode ? (
                <DriveModeBtn
                  toggle={
                    userData?.activeRide ||
                    userData?.activePassenger ||
                    userData?.activeRideRequest
                  }
                  className={"btn"}
                  onClick={() => {
                    setOnCancel(() => () => setToggleConfirmationModal(false)); // Update the function reference
                    setConfirmationModalText(
                      "Are you sure you want to turn off driver mode?"
                    );
                    setConfirmationAlertText(
                      "You will no longer be able to accept rides."
                    );
                    setConfirmationHeaderText("Turning Off Driver Mode");
                    setTermsToggle(false);

                    setOnConfirm(() => () => {
                      activateDriverMode(
                        false,
                        setDriverMode,
                        setResetDriverMarker,
                        notifier,
                        fiesta,
                        triggerLocationGrab,
                        setMessageToken,
                      );
                      setTriggerShow(true);
                      setToggleConfirmationModal(false);
                    }); // Update the function reference
                    setToggleConfirmationModal(true);
                  }}
                >
                  <img src="/svgs/drive-mode-on.svg" alt="" />
                </DriveModeBtn>
              ) : (
                <DriveModeBtn
                  toggle={
                    userData?.activeRide ||
                    userData?.activePassenger ||
                    userData?.activeRideRequest
                  }
                  className={"btn"}
                  onClick={() => {
                    setOnCancel(() => () => setToggleConfirmationModal(false));
                    setConfirmationHeaderText("Turning On Driver Mode");
                    setTermsToggle(true);
                    setConfirmationAlertText(
                      "By continuing you agree to all terms and conditions."
                    );
                    setConfirmationModalText(
                      "GPS Location is required for this feature. Your friends will get general data like distance from pickup location. You will be able to accept ride requests from friends, and earn money for your time and gas."
                    );
                    setOnConfirm(() => () => {
                      activateDriverMode(
                        true,
                        setDriverMode,
                        setResetDriverMarker,
                        notifier,
                        fiesta,
                        triggerLocationGrab,
                        setMessageToken,
                      );
                      setTriggerShow(true);
                      setToggleConfirmationModal(false);
                      setTermsToggle(false);
                    });
                    setToggleConfirmationModal(true);
                  }}
                >
                  <img src="/svgs/drive-mode-off.svg" alt="" />
                </DriveModeBtn>
              )}

<LoadingContainer loading={!!loading}>
                {loading && <LoadingSpinner />}
              </LoadingContainer>
              <PlusButton
                    className={classNames("fa plus-icon btn", { "open": openSpeedModal })}
                    onClick={() => setOpenSpeedModal(!openSpeedModal)}
                  >
<PlusText  style={{}}>
<i className="fas fa-plus"></i>
</PlusText>


                  </PlusButton>
            </DriveModePanel>
            


            {openSpeedModal && (
                <SpeedModal isOpen={openSpeedModal} className="btn">

                  {/* SpeedModal content goes here */}
                  {/* error */}
                  {error && <ErrorField>{error}</ErrorField>}
                  {/* add close button */}
                  <SpeedCloseButton className="btn" onClick={() => setOpenSpeedModal(!openSpeedModal)}>Close</SpeedCloseButton>
                  
                        <UpdateUsernameContainer>
                        <UserName>Add Friend</UserName>
                          <ActivePanelContainer >

                          <UserNameInput
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            // value={friendsUsername}
                            {...register("addFriendFieldB", {
                              required: true,
                              pattern: /^[a-z0-9_]{4,20}$/,
                            })}
                            placeholder="Email or Username"
                            type="text"
                            name={"addFriendFieldB"}
                          />
                          <ActivePanelButton
                            onClick={() => {
                              // await trigger('updateUserNameField');
                              const check = 0; //Object.keys(errors).length;
                              // console.log('add f')
                              const friendUser = getValues('addFriendFieldB');
                              // console.log(friendUser);
                              if (!check && friendUser?.length) {
                                // const { updateUserNameField } = getValues();
                                // console.log(check, newUserName);
                                setLoading(true);
                                setTriggerShow(true);
                                addFriend(
                                  friendUser,
                                  setActiveFriendPanel,
                                  setFriends,
                                  setPendingFriends,
                                  setLoading,
                                  notifier,
                                  fiesta,
                                  setMessageToken,
                                );

                              }
                            }}
                          >
                            +
                          </ActivePanelButton>
                          </ActivePanelContainer>

                          {/* <button onClick={() => addOrRemoveFriend(true, friendId, setFriendId, friends)}>Update Usernme</button> */}
                        </UpdateUsernameContainer>

                        <UpdateUsernameContainer> 
                        {/* <UserName>Recent Notifications:</UserName> */}
                        {openSpeedModal && <UserNotificationList />}

                        <RecentNotificationContainer>

                        </RecentNotificationContainer>

                        </UpdateUsernameContainer>
                </SpeedModal>
              )}


          </DriveModePanel>
        </Draggable>

        {userData?.activePassenger?.status === "accepted" && (
          <>
            {/* // #Create active ride panel with driver name and distance from start location */}
            <ActiveRidePanel toggle={true}>
              {userData?.activePassenger?.started ? null : (
                <CancelRideBtn
                  className={"btn"}
                  onClick={() =>
                    cancelActiveRide(
                      userData?.activePassenger?.driverId,
                      setUserData,
                      setTriggerShow,
                      setLoading,
                      notifier,
                      fiesta
                    )
                  }
                >
                  Cancel Ride
                </CancelRideBtn>
              )}
              <ActiveRideHeader>
                Caught one! They are heading towards you.{" "}
              </ActiveRideHeader>
              <AvatarColumnContainer className="btn">
                {userData?.activePassenger?.started === true ? (
                  <ActiveRideSubHeader className="typewriter">
                    You're ride with{" "}
                    <strong>{userData?.activePassenger?.driverName} </strong>{" "}
                    has started, enjoy!
                  </ActiveRideSubHeader>
                ) : (
                  <ActiveRideSubHeader className="typewriter">
                    <strong>{userData?.activePassenger?.driverName} </strong>{" "}
                    accepted your ride, and is heading towards pickup location.
                  </ActiveRideSubHeader>
                )}

                <ActivePanelButton
                  onClick={async () => {
                    // console.log({ friend });
                    await setFriendId(userData?.activePassenger?.driverId);
                    await setChatPanelUser(
                      userData?.activePassenger?.driverName
                    );
                    await loadChatMessages(
                      `${userData?.activePassenger?.driverId}`,
                      setMessages,
                      setChatId,
                      chatId,
                      setChatPanelUser,
                    );
                    // setFriendPanelToggle(false);
                    setChatPanelToggle(true);
                  }}
                >
                  Chat with {userData?.activePassenger?.driverName}
                </ActivePanelButton>
              </AvatarColumnContainer>

              {userData?.activePassenger?.started === true
                ? userData?.activePassenger?.driverDistance && (
                    <ActiveRideSubHeader>
                      We are{" "}
                      <strong>
                        {userData?.activePassenger?.driverDistance}
                      </strong>{" "}
                      away from our destination.
                    </ActiveRideSubHeader>
                  )
                : userData?.activePassenger?.driverDistance && (
                    <ActiveRideSubHeader>
                      They are{" "}
                      <strong>
                        {userData?.activePassenger?.driverDistance}
                      </strong>{" "}
                      away from Pick Up Location.
                    </ActiveRideSubHeader>
                  )}
              <ActiveRideSubHeader>
                Pick Up Location: <br />
                {userData?.activePassenger?.location}
              </ActiveRideSubHeader>
              <ActiveRideSubHeader>
                Destination: <br /> {userData?.activePassenger?.dropLocation}
              </ActiveRideSubHeader>
              {userData?.activePassenger?.status === "accepted" && (
                <PassengerMap
                  currentRideStarted={userData?.activePassenger?.started}
                />
              )}
              {userData?.activePassenger?.initialDriverLocation?.lat &&
                userData?.activePassenger?.initialDriverLocation?.lng && (
                  <iframe
                    title="active-driver-map"
                    width={600}
                    height={200}
                    style={{ border: 0, maxWidth: "100%" }}
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/directions?origin=${
                      userData?.activePassenger?.started !== true
                        ? `${userData?.activePassenger?.location}`
                        : `${userData?.activePassenger?.initialDriverLocation?.lat},${userData?.activePassenger?.initialDriverLocation?.lng}`
                    }&destination=${
                      userData?.activePassenger?.started === true
                        ? userData?.activePassenger?.dropLocation
                        : userData?.activePassenger?.location
                    }&key=${
                      process.env.REACT_APP_GOOGLE_MAPS_API_KINDROP_API_KEY
                    }
                    `}
                    // &zoom=${mapZoom}
                  ></iframe>
                )}
            </ActiveRidePanel>
          </>
        )}

        {userData?.currentRide?.status === "accepted" && (
          <>
            {/* // #Create active ride panel with driver name and distance from start location */}
            <ActiveRidePanel toggle={true}>
              {userData?.currentRide?.started &&
              !userData?.currentRide?.atDropOffLocation ? (
                <ActivePanelButton
                  className={"btn"}
                  onClick={() => {
                    setLoading(true);
                    endRide(
                      userData?.currentRide?.driverId,
                      userData?.currentRide?.passengerId,
                      setTriggerShow,
                      setLoading,
                      notifier,
                      fiesta
                    );
                  }}
                >
                  End Ride
                </ActivePanelButton>
              ) : (
                <CancelRideBtn
                  className={"btn"}
                  onClick={() =>
                    cancelActiveRide(
                      userData?.currentRide?.passengerId,
                      setUserData,
                      setTriggerShow,
                      setLoading,
                      notifier,
                      fiesta
                    )
                  }
                >
                  Cancel Ride
                </CancelRideBtn>
              )}
              <ActiveRideHeader>Ride Confirmed</ActiveRideHeader>
              <RefreshUiBtn onClick={() => window.location.reload(false)}>
                Refresh UI
              </RefreshUiBtn>
              {!userData?.currentRide?.started &&
                userData?.currentRide?.atPickupLocation && (
                  <ColumnContainer>
                    <ActiveRideSubHeader className={"btn"}>
                      <GlowBox>
                        <ActiveRideSubHeader className="typewriter">
                          You are at pickup location:
                        </ActiveRideSubHeader>
                        <ConfirmPickup
                          onClick={() => {
                            confirmPickup(
                              userData?.currentRide?.passengerId,
                              setUserData,
                              setTriggerShow,
                              notifier,
                              fiesta
                            );
                          }}
                        >
                          {" "}
                          Click to Start {
                            userData?.currentRide?.passengerName
                          }{" "}
                          Ride Clock.
                        </ConfirmPickup>
                      </GlowBox>
                    </ActiveRideSubHeader>
                  </ColumnContainer>
                )}
              {userData?.currentRide?.started &&
                userData?.currentRide?.atDropOffLocation && (
                  <OutlineContainer>
                    <ActiveRideSubHeader className={"btn"}>
                      <GlowBox>
                        <ActiveRideSubHeader className="typewriter">
                          You are at Drop Off location:
                        </ActiveRideSubHeader>
                        <ConfirmPickup
                          onClick={() => {
                            endRide(
                              userData?.currentRide?.driverId,
                              userData?.currentRide?.passengerId,
                              setTriggerShow,
                              setLoading,
                              notifier,
                              fiesta
                            );
                            setLoading(false);
                          }}
                        >
                          Click to finalize{" "}
                          {userData?.currentRide?.passengerName} drop off.
                        </ConfirmPickup>
                      </GlowBox>
                    </ActiveRideSubHeader>
                  </OutlineContainer>
                )}

              <ActiveRideSubHeader className={"btn"}>
                <strong>Click on Confirm Start </strong> to begin the ride and{" "}
                <strong> Drop off</strong> When you've arrived. Enjoy the drive!
              </ActiveRideSubHeader>
              <ActiveRideMap className={"btn"}>
                {userData?.currentRide?.status === "accepted" && (
                  <NavMap currentRideStarted={userData?.currentRide?.started} />
                )}

                {/* {!userData?.currentRide?.started && userData?.currentRide?.initialDriverLocation && userData?.currentRide && userData?.currentRide?.status === "accepted" && userData?.currentRide?.location &&
              <NavMap 
                  start={{latitude: userData?.currentRide?.initialDriverLocation?.lat, longitude: userData?.currentRide?.initialDriverLocation?.lng}}
                  end={userData?.currentRide?.location}
                  // zoom={16}
              />  
              } */}
              </ActiveRideMap>
              {userData?.currentRide &&
                userData?.currentRide?.status === "accepted" &&
                userData?.currentRide?.location && (
                  <ActiveRideMap className={"btn"}>
                    {/* <NavMap 
                  start={
                    ? 
                    userData?.currentRide?.location
                    :
                    {latitude: userData?.currentRide?.initialDriverLocation?.lat, longitude: userData?.currentRide?.initialDriverLocation?.lng}}
                  end={ userData?.currentRide?.started === true
                    ? userData?.currentRide?.dropLocation
                    : userData?.currentRide?.location }
                /> */}
                    {/* <NavMap 
                  start={userData?.currentRide?.started === true
                    ? 
                    userData?.currentRide?.location
                    :
                    (userData?.currentRide?.initialDriverLocation?.lat && userData?.currentRide?.initialDriverLocation?.lng) ? {latitude: userData?.currentRide?.initialDriverLocation?.lat, longitude: userData?.currentRide?.initialDriverLocation?.lng} : null}
                  end={ userData?.currentRide?.started === true
                    ? userData?.currentRide?.dropLocation
                    : userData?.currentRide?.location }
                />  */}
                  </ActiveRideMap>
                )}

              {/* ride details section */}

              <ResponsiveColumnContainer className="btn">
                <OutlineContainer>
                  <ActiveRideSubHeader>
                    {userData?.currentRide?.started ? (
                      <div>
                        You are{" "}
                        <strong>{userData?.currentRide?.driveDistance} </strong>
                        away from Destination.
                      </div>
                    ) : (
                      <div>
                        You are{" "}
                        <strong>
                          {userData?.currentRide?.distanceFromPickup}{" "}
                        </strong>
                        away from Pick Up Location.
                      </div>
                    )}
                  </ActiveRideSubHeader>
                </OutlineContainer>
                <OutlineContainer>
                  {userData?.currentRide?.started ? (
                    <ActiveRideSubHeader>
                      <strong>{userData?.currentRide?.passengerName}</strong> is{" "}
                      <strong>Onboard</strong>, have a great ride!
                    </ActiveRideSubHeader>
                  ) : (
                    <ActiveRideSubHeader>
                      We let{" "}
                      <strong>{userData?.currentRide?.passengerName}</strong>{" "}
                      know you're headed to pickup Location.
                    </ActiveRideSubHeader>
                  )}

                  <ActivePanelButton
                    className={"btn"}
                    onClick={async () => {
                      // console.log({ friend });
                      await setFriendId(userData?.currentRide?.passengerId);
                      await setChatPanelUser(
                        userData?.currentRide?.passengerName
                      );
                      await loadChatMessages(
                        `${userData?.currentRide?.passengerId}`,
                        setMessages,
                        setChatId,
                        chatId,
                        setChatPanelUser,
                      );
                      // setFriendPanelToggle(false);
                      setChatPanelToggle(true);
                    }}
                  >
                    Chat with {userData?.currentRide?.passengerName}
                  </ActivePanelButton>
                </OutlineContainer>

                <ActiveRideSubHeader className={"btn"}>
                  Pick Up Location: <br /> {userData?.currentRide?.location}
                </ActiveRideSubHeader>
                <ActiveRideSubHeader className={"btn"}>
                  Destination: <br /> {userData?.currentRide?.dropLocation}
                </ActiveRideSubHeader>
              </ResponsiveColumnContainer>

              {/* ride details above section */}

              <OutlineColumnContainer>
                <OutlineContainer className="btn">
                  <ActiveRideSubHeader
                    className={
                      userData?.currentRide?.started ? "" : "typewriter"
                    }
                  >
                    If you prefer google maps Navigation click:{" "}
                    <strong>"More Options" or "View Larger Map"</strong> Link on
                    the map below for google maps directions.
                  </ActiveRideSubHeader>

                  {userData?.currentRide?.initialDriverLocation?.lat &&
                    userData?.currentRide?.initialDriverLocation?.lng && (
                      <iframe
                        title="active-driver-map"
                        width={400}
                        className={"btn"}
                        height={200}
                        style={{ border: 0, maxWidth: "100%" }}
                        loading="lazy"
                        allowFullScreen
                        src={`https://www.google.com/maps/embed/v1/directions?origin=${`${userData?.currentRide?.initialDriverLocation?.lat},${userData?.currentRide?.initialDriverLocation?.lng}`}&destination=${
                          userData?.currentRide?.started === true
                            ? userData?.currentRide?.dropLocation
                            : userData?.currentRide?.location
                        }&key=${
                          process.env.REACT_APP_GOOGLE_MAPS_API_KINDROP_API_KEY
                        }
                    `}
                        // &zoom=${mapZoom}
                      ></iframe>
                    )}
                </OutlineContainer>
              </OutlineColumnContainer>

              {/* <ActiveRideSubHeader>
              {activeDriverData.distanceFromStart} miles away from pickup
              location
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.distanceFromDestination} miles away from
              destination
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.timeFromStart} minutes away from pickup location
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.timeFromDestination} minutes away from
              destination
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.price} dollars
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.rideStatus}
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.startLocation}
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.destination}
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.userName}
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.userAvatar}
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.friendUserName}
            </ActiveRideSubHeader>
            <ActiveRideSubHeader>
              {activeDriverData.friendUserAvatar}
            </ActiveRideSubHeader> */}
            </ActiveRidePanel>
          </>
        )}

        <TermsPanel active={termsPanelToggle}>
          <TermsClose>{ClosePanel(setTermsPanelToggle)}</TermsClose>
          {/* {Terms()} */}
          <iframe
            src="https://app.termly.io/document/terms-of-use-for-website/aaf8775d-6969-4662-a8bf-fee77acb3fb4"
            frameBorder="0"
          ></iframe>
          {/* <div name="termly-embed" data-id="dc1ec492-b2f1-4566-ab3d-df562933fafd" data-type="iframe"></div> */}
        </TermsPanel>

        <InfoNav>
          <NotificationWrapper
            setTriggerShow={() => setTriggerShow}
            triggerShow={triggerShow}
            setFiestaMessage={() => setFiestaMessage}
            setNotification={() => setNotification}
            success={fiestaMessage?.length > 0}
            alert={notification?.length > 0}
          >
            {/* <> {fiestaMessage?.length && (
                <SuccessContainer>{fiestaMessage}</SuccessContainer>
              )}
              {notification?.length && (
                <AlertsContainer>{notification}</AlertsContainer>
              )}
              </> */}

            {fiestaMessage?.length > 0 && fiestaMessage}

            {notification?.length > 0 && notification}
          </NotificationWrapper>

          {/* <InfoLink
            onClick={() => {
              clearInfoPanel();
              setInfoPanelToggle(true);
              setAboutContainer(true);
            }}
          >
            About Dropkins
          </InfoLink> */}
          {/* <InfoLink onClick={ () => {clearInfoPanel(); setInfoPanelToggle(true); setSpecialPartnersContainer(true)}}>
            Special partners
          </InfoLink> */}
        </InfoNav>
        {/* <CovDropsLogo>
          <img src="/svgs/covdrops-overlay.svg" alt=""/>
        </CovDropsLogo> */}
      </HomeLayout>
    </>
  );
};

export default Home;
