import { useEffect, useState, useRef, useCallback, useContext } from "react";
import GoogleMapsApiLoader from "google-maps-api-loader";
import axios from 'axios';
import { AuthContext } from '../Auth';
import { gKey, kindAuth } from "../firebase-config";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const eventsMapping = {
  onCenterChanged: ["center_changed", map => map?.getCenter()],
  onBoundsChanged: ["bounds_changed", map => map?.getBounds()],
  onZoomChanged: ["zoom_changed", map =>  map?.getZoom()],
  // onZoomChanged: ["zoom_changed", map => { console.log('week', map.getZoom()); return map.getZoom()}],
};


const nightStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8ec3b9"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1a3646"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#64779e"
        }
      ]
    },
    {
      "featureType": "administrative.province",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#4b6878"
        }
      ]
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#334e87"
        }
      ]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#6f9ba5"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3C7680"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#304a7d"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#2c6675"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [
        {
          "color": "#255763"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#b0d5ce"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#023e58"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#98a5be"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1d2c4d"
        }
      ]
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#283d6a"
        }
      ]
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3a4762"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#0e1626"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#4e6d70"
        }
      ]
    }
  ];

  export const getGeoCode = async (location) => {
    // const key = 'AIzaSyAalCGPrquwmaUguvk6nGaAIvPqfoXLB7E';
    if(!kindAuth.currentUser) return;
      try {
            // console.log('map geocode',{location})
            // setLoading(true);
            const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(location)}&key=${gKey}`);
            // console.log('map geocode',{location}, res)
            const result = res?.data?.results[0]?.geometry?.location;
            // console.log('map geocode',{location}, result, result.lat, result.lng)
        
            if (result.lat !== null && result.lng !== null) {
              return {lat: result.lat, lng: result.lng};
              // return new window.google.maps.LatLng(result.lat, result.lng);
              // return await new window.google.maps.LatLng(result.lat, result.lng);
            }
            else {
              // updateError(true);
            }
            // setLoading(false);
        } 
      catch (error) {
        // setLoading(false);
        console.log(error)
        // updateError(true);
      }

  }

export default function useGoogleMap({ zoom, center, events, mapHook }) {
  const [mapLocal, setMapLocal] = useState(/** @type google.maps.Map */ (null));

  const { 
    mapState, setMapState,
    startLocation, destination,
    directionsResponse,
    setClearTogglesActive,
    setDirectionsResponse,
    clearTogglesActive,
    userData,
  } = useContext(AuthContext);

  const mapRef = useRef();
  let map;
  const directionsRenderer = useRef(null)

  // const directionsRenderer = useRef(
  //   /** @type google.maps.DirectionsRenderer */ (
  //     new window.google.maps.DirectionsRenderer()
  //   ),
  // );

  // const directionsService = useRef(
  //   // /** @type window.google.maps.DirectionsService */ (
  //     // eslint-disable-next-line no-undef
  //     new window.google.maps.DirectionsService()
  // );

  // create a directionsservice ref that uses the mapState
  // const directionsService = useRef(



  
  


  useEffect(() => {
    const unsubscribe = async () => {
      console.log('clear Toggles changed', {clearTogglesActive});

      if(clearTogglesActive === true) {
        setClearTogglesActive(false);
        if (mapRef.current) {
        const mapInstance = new window.google.maps.Map(mapRef?.current, { zoom, center,  mapId: "24aa237eddc3608a" });
          
          Object.keys(events).forEach(eventName =>
            mapInstance?.addListener(eventsMapping[eventName][0], () =>
              events[eventName](eventsMapping[eventName][1](mapInstance))
              )
            );
          setMapLocal(mapInstance);

          await directionsRenderer.current?.setMap(null);
    

        const directionsRendererInstance = new window.google.maps.DirectionsRenderer();
        
        await directionsRendererInstance?.setMap(mapInstance);
        directionsRenderer.current = directionsRendererInstance;
        setMapState({ maps: mapState?.maps, map: mapInstance, loading: false });
      
        }
      }
    }
    return () => unsubscribe();
      // mapState?.map && directionsRenderer?.current?.setMap(mapState?.map)
    }, [userData?.activeRideRequest]);

  useEffect(() => {
    // if(directionsRenderer){
    //       directionsRenderer.setMap(null);
    //   }
    // console.log('map triggered update')
    // directionsRenderer = new window.google.maps.DirectionsRenderer()
    if (startLocation && destination) {
      // console.log("(#(#(@#(@#(@#((@#@", { startLocation, destination });
      const directionsService = new window.google.maps.DirectionsService()
      
      
        directionsService.route(
          {
            origin: startLocation,
            destination: destination,
            travelMode: 'DRIVING'
          },
            function(response, status) {
            // console.log('in dir serv',{ response, status });
            if (status === 'OK') {
              // console.log('Directions request succeeded', { response });
              // directionsRenderer?.curent?.setDirections(response);
              // var directionsData = response.routes[0].legs[0]; // Get data about the mapped route
              
              
              // console.log("####000-----", directionsRenderer);
              
              
              directionsRenderer?.current?.setMap(null);
              directionsRenderer?.current?.setDirections(response);
              directionsRenderer?.current?.setMap(mapLocal);
       
              
              // if(response !== directionsResponse){
                // setDirectionsResponse(response);

              // }
              // directionsRenderer?.setDirections(response);
            } else {
              // console.clear();
              console.log('Directions request failed due to ' + status);
            }
          })
     
    }
  }, [startLocation, destination, setDirectionsResponse, mapState?.map, directionsResponse]);

    
 
 

  useEffect(() => { 
    const unsubscribe = () => {
      // console.log('mapState changed', {mapLocal}, mapState?.map);
      // mapState?.map && directionsRenderer?.current?.setMap(mapState?.map)
      // if (directionsResponse && directionsRenderer) {
      //   directionsRenderer?.current?.setMap(null);
      //   directionsRenderer?.current?.setMap(mapState?.map);
      //   directionsRenderer?.current?.setDirections(directionsResponse);
      //   mapRef.current.panTo(getGeoCode(startLocation))
      // }
    }
    return () => unsubscribe();
    
  }, [ mapLocal])

  useEffect(() => {
    const unsubscribe = () => {

        // directionsRenderer?.current?.setMap(null);
        // mapState?.map && directionsRenderer?.current?.setMap(mapLocal);
        // directionsRenderer?.current?.setDirections(response);
    }
    return ()=> unsubscribe();
  }, [mapLocal, mapState?.map]);

  useEffect(() => {
    GoogleMapsApiLoader({ apiKey }).then(google => {
      if (mapRef.current) {
      const mapInstance = new google.maps.Map(mapRef.current, { zoom, center,  mapId: "24aa237eddc3608a" });
      setMapLocal(mapInstance);
      // console.log(events)
      Object.keys(events).forEach(eventName =>
        mapInstance.addListener(eventsMapping[eventName][0], () =>
          events[eventName](eventsMapping[eventName][1](mapInstance))
        )
      );
      const directionsRendererInstance = new google.maps.DirectionsRenderer();
      
      directionsRendererInstance?.setMap(mapInstance);
      directionsRenderer.current = directionsRendererInstance;
      setMapState({ maps: google.maps, map: mapInstance, loading: false });
      }
    });
  }, []);

  const scrollToMarker = async (mapState) => {
    // console.log(mapState, 'hooke changed', mapHook)
    mapState && mapState?.map && await mapState.map.panTo({lat: mapHook?.latitude, lng: mapHook?.longitude})
    mapState && mapState?.map && await mapState.map.setZoom(14);
    // debugger
  };

  useEffect(() => {
    const unsubscribe = () => {
      if (mapHook && mapHook?.latitude && mapHook?.longitude) {
        scrollToMarker(mapState);
      }
    };
    return () => unsubscribe();
  }, [mapHook]);
  
  return { mapRef, ...mapState };
}
