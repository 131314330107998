import React, { useState, useEffect } from 'react';

import { getTribalLeaderData } from '../../../storage/friends';
import styled from "@emotion/styled";




const MayorHeadlineContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const MayorHeaderContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
const MayorContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: column;
  flex-wrap: wrap;
`;


const MayorImageContainer = styled.div`
  display: flex;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;
`;


const MayorImage = styled.img`
  width: 100%;

`;

const TribalLeader = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Fetch data from an API or any other data source
    // and update the state with the retrieved data
    const fetchData = async () => {
      try {
        getTribalLeaderData().then(tLeader => {
          console.log({tLeader});
          setData(tLeader);
        });
        // console.log({tLeader});
        // setData(tLeader);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {data ? (
        <MayorContainer>
          <MayorHeaderContainer>
          <MayorImageContainer>
            <MayorImage src={data.photoURL} alt="Profile" />

          </MayorImageContainer>
          <MayorHeadlineContainer>

          <h2> 👑 Mayor - {data.userName}</h2>
          </MayorHeadlineContainer>

          </MayorHeaderContainer>
          
          <div className="columns">
            <div className="listings-column">
              <h3>Listings</h3>
              {/* Render listings data */}
            </div>
            <div className="posts-column">
              <h3>Posts</h3>
              {/* Render posts data */}
            </div>
          </div>
        </MayorContainer>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default TribalLeader;