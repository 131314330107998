
import React, {useContext} from 'react';
import moment from 'moment';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';

import 'firebase/compat/auth';
import 'firebase/firestore';
import { kinDb, appFirebase, providers, kindAuth } from '../firebase-config';
import userEvent from '@testing-library/user-event';
import { collection, doc, getDocs, onSnapshot, query, where, getFirestore, getDoc, updateDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getRedirectResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updatePassword } from 'firebase/auth';

import { getUserId } from '../firebase';
import { snap } from 'gsap';
import { Marker } from 'react-google-maps';


const db = getFirestore(appFirebase);

//Hope markers

export const getUserLocation = async (panToLocation: boolean, setMapLat: any, setMapLng: any) => {
  const uid = getUserId();
  if(!uid) return;
  console.log('getting user location', uid)
  const cUserRef = collection(db, 'users');

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);

  await userSnaps?.forEach(async (userSnap) => {
    
  if (userSnap.exists()) {
  const user = userSnap.data();

  if( panToLocation) {
    setMapLat(user?.location?.lat);
    setMapLng(user?.location?.lng);
  }
  return user?.location;
  }
})
}



export const setUsersLocation = async (position: any) => {
  // console.log('!setting user location')
  if (!kindAuth.currentUser) return;
  const uid = getUserId();
  if(!uid) return;
  // const userRef = doc(db, "users", `${userId}`);

  const cUserRef = collection(db, 'users');
  // console.log("setUsersLocation", position, uid)

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);

  userSnaps?.forEach(async (userSnap) => {
    
  if (userSnap.exists()) {
    await updateDoc(userSnap.ref, {
      location: {
        lat: position.lat,
        lng: position.lng,
      }
    });
  }
})
}



export const hopeMarkersSnap = ( setHopeMarkers: any ) => {
  const treasureDbRef = collection(kinDb, 'treasureMarkers')

  const q = query(treasureDbRef);
  return onSnapshot(q, (querySnapshot) => {
    const hopes: never[] = []
    querySnapshot.forEach((snap) =>  ({
      id: snap.id,
      ...snap.data(),
    }))
    // console.log("Current cities in CA: ", snap.data());
    !!setHopeMarkers && setHopeMarkers(hopes);
  });


  

}


// Treasure markers


export const treasureMarkersSnap =  (setTreasures: ((arg0: any) => void) | undefined) => {
  // const treasureDbRef = collection(kinDb, 'treasureMarkers')
  //  # query treasuremarkers and update setTreasures with whats returned
  console.log("treasureMarkersSnap")
    const treasureRef = collection(db, "treasureMarkers");
    const q = query(treasureRef);
    return onSnapshot(q, (querySnapshot) => {
      let treasures: any[] = [];  
      querySnapshot.forEach((snap) =>  {
        treasures.push({
          id: snap.id,
          ...snap.data(),
        })
      })
      // console.log("Current treasure markers: ", treasures)
      
      !!setTreasures && setTreasures(treasures);
    });
  // const q = query(treasureDbRef);
}





  //  return onSnapshot(doc(db, "treasureMarkers"), (snapDoc) => {
  //   const source = snapDoc.metadata.hasPendingWrites ? "Local" : "Server";
  //   console.log(source, " data: ", snapDoc.data());

  //   const treasures = snapDoc.data();
  //   treasures?.filter((snapDoc: { data: () => any; }) => {
  //     const treasure = snapDoc.data();
  //     // console.log(Date.now() - treasure.time)
  //     return ((Date.now() - treasure.time) < 1818083) ? true : false;

  //   }).map((snapDoc: { id: any; data: () => any; }) => ({
  //     id: snapDoc.id,
  //     ...snapDoc.data(),
  //   }));
  //   !!setTreasures && setTreasures(treasures);

  // });
  

// }