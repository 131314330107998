import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import * as ui from '../../../../styles/uiVars';


export const HomeMiddleContainer = styled.div`
  display: flex;
  /* background: white; */
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  /* border-bottom: 1px solid #EDEDED; */
background: #2E2E75;
@media screen and (max-width: ${ui.tablet}) {
  flex-direction: column;
  
}

`;


export const HomeImageContainer = styled.div`
  /* width: 708px; */
  /* height: 528px; */
  flex-basis: 50%;
  background: url('/images/dropkins/landing/middle/ride_together_001.jpg') no-repeat;
  background-size: cover;

  min-height: 400px;
  @media screen and (max-width: ${ui.tablet}) {
  width: 100%;

  
}
  
  `;

export const HomeMiddleImage = styled.img`
 width: 100%;

 @media screen and (max-width: ${ui.tablet}) {
  display: none;
  
}
`;


export const HomeContentContainer = styled.div`
  flex-basis: 50%;
  display: flex;

  /* align-items: center; */
  color: #FAFAFA;
  /* margin: auto; */
  
  
  align-items: center;
  /* padding-right: 112px; */
  flex-direction: column;
  text-align: left;
  @media screen and (max-width: ${ui.tablet}) {
    padding: 2em 0;
    justify-content: center;
  
}
  /* justify-content: center; */
  `;
  
export const HomeContentTitle = styled.div`
  display: flex;
    font-feature-settings: 'clig' off, 'liga' off;
    text-align: left;
    /* Desktop/Headline 3 */
    font-family: "Inter", sans-serif;
    font-family: "Inter", sans-serif;
    font-size: 31px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 38px; /* 122.581% */
    letter-spacing: -0.651px;
    margin-bottom: 1em;
    max-width: 69%;
    color: white;
    
    @media screen and (max-width: ${ui.tablet}) {
      max-width: 80%;

  
}
    
    
    `;

    export const HomeContentText = styled.div`

font-family: "Inter", sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 400;
max-width: 500px;
line-height: 32px; /* 160% */
max-width: 84%;
color: #D4D4D4;

@media screen and (max-width: ${ui.tablet}) {
      max-width: 90%;

  
}
    `;
export const HomeContentWrapper = styled.div`
display: flex;
flex-direction: column;
max-width: 80%;
gap: 0.51em; 



`;
export const HomeMiddle = () => {
  return (
    <HomeMiddleContainer>
      <HomeImageContainer>
        {/* <HomeMiddleImage src={"/images/dropkins/landing/middle/ride_together_001.jpg"} /> */}
      </HomeImageContainer>
      <HomeContentContainer>
      <HomeContentWrapper> 
        <HomeContentTitle>
        Ride together, 
        earn together.
        </HomeContentTitle>
        <HomeContentText>
          If someone you invite accepts a paid ride, you can earn 3% on every ride!
          <br /><br />
          That's passive income, just for spreading the word.
          {/* Earn 3% oAs you connect more, your opportunities for both rides and earnings grow! */}
        </HomeContentText>
        </HomeContentWrapper>
      </HomeContentContainer>
    </HomeMiddleContainer>

  )

};

export default HomeMiddle;