import React, { useEffect, useState, useContext } from "react";
// import firebase from 'firebase';
import { db, kinDb, kindAuth, appFirebase, cloudFunctions } from "../../../firebase-config";
import PlacesAutocomplete from 'react-places-autocomplete';
// import { storage } from 'firebase'; // Import the Firebase Storage SDK
import { getStorage, ref } from "firebase/storage"
import styled from "@emotion/styled";
import {uploadHomeImages, getListings} from "../../../storage/utils/user"
import {
  sendHeartTo,
  getUserId,
  getHeartCount,
  getTreasureData,
  setDontShowOnboard,
  checkDontShowOnboard,
  writeMarker,
  fireAuth,
  loadChatMessages,
  listenForFriendChanges,
  logOut,
} from "../../../firebase";
import { AuthContext } from "../../../Auth";
import * as ui from "../../../styles/uiVars";
import {
  getDoc,
  getDocs,
  where,
  addDoc,
  doc,
  query,
  updateDoc,
  collection,
} from "firebase/firestore";

import { useForm } from "react-hook-form";

import { EmojiPicker } from "./EmojiPicker";


import {
  LinkBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  LoadingContainer,
  LoadingSpinner,
} from "../../../styles/shared";
import { setDoc } from "firebase/firestore/lite";
// import { InputWrapper } from '../../LogIn';

export const ListingsForm = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const ListinigsLabel = styled.label`
  text-align: left;
`;

export const ColumnContainer = styled.div`
  display: flex;
`;

export const ListingHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;
  @media screen and (max-width: ${ui.tablet}) {
    flex-wrap: wrap;
    min-width: initial;
  }
`;
export const ListingContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 300px;
  margin-bottom: 0.25em;

  @media screen and (max-width: ${ui.tablet}) {
    flex-wrap: wrap;
    min-width: initial;
  }
`;

export const ListingsContainer = styled.div`
  display: flex;
  background: #ffffff;
  position: relative;
  border-radius: 6px;
  width: 76%;
  min-width: 350px;
  flex-wrap: wrap;
  margin-top: 0.11em;
  color: black;
  background: #071159;
  gap: 5px;
  
    justify-content: flex-start;
`;

export const ListOptionsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ListingSubHeader = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 0.6581em;
  color: #242c53;
  line-height: 1.1;
  align-self: flex-start;
  margin-bottom: 0.8em;
  max-width: 480px;
  /* display: flex; */
  @media screen {
    
  }
`;


export const RemoveImageBtn = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5em;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  cursor: pointer;
`;
export const ListingHeader = styled.div`
  display: flex;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  font-size: 1.2em;
`;
export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2em;
  width: 100%;
  flex-direction: column;

  .input {
    width: 90%;
  }
`;

export const ListingContent = styled.div``;
export const Panel = styled.div`
  display: none;
  display: ${({ active }) => (active ? "flex" : "none")};
  width: 100%;
`;

export const ProfileImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin: 0.1em;
  height: 6em;
  @media screen and (max-width: ${ui.mobile}) {
    height: 3em;
    
  }
`;

export const ProfileImage = styled.img`
  height: 100%;
  width: auto;
  border-radius: 8px;
`;

export const InputContainerUi = styled(InputContainer)`
  width: 100%;
  position: relative;
  background: #f0f1f8;
  background: linear-gradient(-45deg, #b6bbde 0%, #bac2d7 100%);
  /* add linear gradient */
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.06);

  padding: 1em;

  
  @media screen and (max-width: ${ui.tablet}) {
    max-width: 100%;
    flex-wrap: wrap;
    padding: 0.5em;
    margin: 0.1em;
    gap: 10px;
  }
`;


export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media screen and (max-width: ${ui.tablet}) {
    flex-wrap: wrap;
    justify-content: center;
    
  }
`;
export const Button = styled(LinkBtn)`
  margin: 1em;
  @media screen and (max-width: ${ui.tablet}) {
    margin: 0.31em;
  }
`;


export const AddressSuggestion = styled.div`
  display: flex;
  padding: 0.5em;
  margin: 0.2em;
`;




  export const ImageGridContainer = styled.div` 
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  
  `;
  export const ImageGrid = styled.div`
position: relative;
    
  `;
  export const GridImage = styled.img` 
    width: 100%;
  `;
// export const PlacesAutocomplete = styled.div`
//   width: 100%;
//   position: relative;
//   background: #f0f1f8;
//   display: flex;
//   align-items: center;
// `;

export const UserListings = () => {
  const [listings, setListings] = useState([]);
  const userId = getUserId();
  const [toggleListingsEditor, setToggleListingsEditor] = useState(false);
  const [toggleHomeListingsEditor, setToggleHomeListingsEditor] = useState(false);
  const [location, setLocation] = useState("");
  const [locationHome, setLocationHome] = useState("");
  const [locationError, setLocationError] = useState(null);
  const [locationSuggestions, setLocationSuggestions] = useState([]);

  const [toggleListings, setToggleListings] = useState(false);
  const [customError, setCustomError] = useState(null);

  // Define a state to store the selected images
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentPanel, setCurrentPanel] = useState(0);
  const [currentPanelHome, setCurrentPanelHome] = useState(0);
  const [homeImages, setHomeImages] = useState([]);
  const [homeImagesUploaded, setHomeImagesUploaded] = useState(false);
  // const [listings, setListings] = useState([]);
  // const userId = getUserId();
  // const [toggleListingsEditor, setToggleListingsEditor] = useState(false);
  // const [toggleListings, setToggleListings] = useState(false);
  // const [customError, setCustomError] = useState(null);


  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
  } = useForm();

  const { profileImage,
    setLoading, setTriggerShow,
    fiestaMessage, setFiestaMessage,
    setNotification,
  
  } = useContext(AuthContext);
  const storage = getStorage(appFirebase);

  const handleLocationChange = (value) => {
    setLocation(value);
  };
  // const handleLocationError = (error) => {
  //   setLocationError(error);
  // };
  // const handleLocationSuggestions = (suggestions) => {
  //   setLocationSuggestions(suggestions);
  // };
  const handleLocationSelect = (value) => {
    setLocation(value);
    setLocationError(null);
  };
  
  const handleLocationChangeHome = (value) => {
    setLocationHome(value);
  };
  const handleLocationSelectHome = (value) => {
    setLocationHome(value);
    // setLocationHomeError(null);
  };

  const panels = [
    {
      label: "Set your title.",
      inputId: "title",
      fieldName: "title",
      errorMessage: "Title is required",
    },
    {
      label: "Location",
      inputId: "location",
      fieldName: "location",
      errorMessage: "Location is required",
    },
    {
      label: "Description",
      inputId: "description",
      fieldName: "description",
      errorMessage: "Description is required",
    },
    {
      label: "Price",
      inputId: "price",
      fieldName: "price",
      errorMessage: "Price is required",
    },
    {
      label: "Select a Category",
      inputId: "category",
      fieldName: "category",
      errorMessage: "Category is required",
    },
  ];
  const homePanels = [
    {
      label: "Set your post' title.",
      inputId: "postTitle",
      fieldName: "postTitle",
      errorMessage: "Title is required",
    },
    {
      label: "Set Address",
      inputId: "locationHome",
      fieldName: "locationHome",
      errorMessage: "Location is required",
    },
    {
      label: "Set Price",
      inputId: "postPrice",
      fieldName: "postPrice",
      errorMessage: "Price is required",
    },
    // {
    //   label: "Set your title.",
    //   inputId: "title",
    //   fieldName: "title",
    //   errorMessage: "Title is required",
    // },
    {
      label: "Describe The Home",
      inputId: "postDescription",
      fieldName: "postDescription",
      errorMessage: "Description is required",
    },
    
    {
      label: "Upload Images",
      inputId: "postImages",
      fieldName: "postImages",
      errorMessage: "Post at least one image",
    },
    
    // {
    //   label: "Price",
    //   inputId: "price",
    //   fieldName: "price",
    //   errorMessage: "Price is required",
    // },
    // {
    //   label: "Location",
    //   inputId: "location",
    //   fieldName: "location",
    //   errorMessage: "Location is required",
    // },
    // {
    //   label: "Category",
    //   inputId: "category",
    //   fieldName: "category",
    //   errorMessage: "Category is required",
    // },
  ];

  const handleNext = async () => {
    const { fieldName } = panels[currentPanel];
    const isFieldValid = await trigger(fieldName);

    if (isFieldValid) {
      setCurrentPanel(currentPanel + 1);
    }
  };

  const handleBack = () => {
    setCurrentPanel(currentPanel - 1);
  };


  const handleNextHome = async () => {
    const { fieldName } = panels[currentPanelHome];
    const isFieldValid = await trigger(fieldName);

    if (isFieldValid) {
      setCurrentPanelHome(currentPanelHome + 1);
    }
  };

  const handleBackHome = () => {
    setCurrentPanelHome(currentPanelHome - 1);
  };

  // const addListing = async () => {
  //   const { title, description, price } = getValues();

  //   // Trigger form validation
  //   const isFormValid = await trigger();

  //   if (!isFormValid) {
  //     // Handle validation errors
  //     return;
  //   }

  //   // Proceed with adding the listing
  //   // ...

  //   console.log("### title", title);
  // };

  // const { register, handleSubmit, watch, formState: {errors}, getValues, clearErrors, setError, trigger } = useForm();

  useEffect(() => {
    setValue("price", 0.0);
  }, []);


  useEffect(() => {

  }, [toggleListingsEditor]);


  useEffect(() => {
    const fetchListings = async () => {
      try {

        const retrieveListings = getListings();
        console.log("### retrieveListings", retrieveListings);
        setListings(retrieveListings);
        // const listingsRef = collection(kinDb, "listings");
        
        // // id of the listing will correspond to the userid
        // const listingDocs = getDocs(listingsRef, userId);
        // if (listingDocs?.size > 0) {
        //   console.log(listingDocs.docs);
        //   const listingData = listingDocs.docs.map((doc) => doc.data());
        //   setListings(listingData);
        // }

        // const snapShot = await kinDb.collection('listings').where('userId', '==', userId).get();

        // const fetchedListings = snapShot?.docs?.map((doc) => doc.data());
        // setListings(fetchedListings);
      } catch (error) {
        console.error("Error fetching listings:", error);
      }
    };

    fetchListings();
  }, []);

  const title = watch("title");
  const description = watch("description");
  const category = watch("category");
  const price = watch("price");
  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(price);

  const categories = ["Errand", "Gig", "Pet Sitting", "House Sitting", "Moving", "Other"];

  

                    // ...


  // Function to handle image selection
  const handleImageSelection = (event) => {
    const files = event.target.files;
    const imagesArray = Array.from(files);
    
    setSelectedImages(imagesArray);
  };

  // Function to handle image upload
  const handleImageUpload = (docRef) => {
    console.log("### docRef", docRef);
    uploadHomeImages(docRef, selectedImages, setHomeImages, setHomeImagesUploaded, setLoading, setTriggerShow, fiesta, notifier);
    
  };

  const storeHomeListing = async () => {


    const newHomeListing = {
      postTitle,
      postDescription,
      postPrice,
      postPriceMonthly,
      locationHome,
      userId,
      createdAt: new Date().toISOString(),
    };
    console.log("### newHomeListing", newHomeListing);

    if (!postTitle || !postDescription || !postPrice || !locationHome) {
      setCustomError("Please fill in all required fields.");
      return;
    }
    if (postPrice < 0) {
      setCustomError("Price cannot be negative.");
      return;
    }
    if (postPriceMonthly < 0) {
      setCustomError("Price cannot be negative.");
      return;
    }
    if (selectedImages.length < 1) {
      setCustomError("Please upload at least one image.");
      return;
    }
    console.log("### newHomeListing", newHomeListing);
    try {
      const listingsRef = collection(kinDb, "homeListings");
      // add a new home listing to the listings collection update after a query to check if it exists with the same address
      let docRef;
      
      const q = query(listingsRef, where("locationHome", "==", newHomeListing.locationHome));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.log('No home with the same address exists.', {listingsRef});
    // No home with the same address exists, add a new one
     docRef = await addDoc(listingsRef, newHomeListing);
    console.log("### docRef", docRef);
  } else {
    console.log("A home with the same address already exists.");
    // update the home listing with the new home listing
    const docRefNew = querySnapshot.docs[0].ref;
    console.log("### docRef", docRefNew);
    
    const docData = await getDoc(docRefNew);
    if (docData.exists() && docData.data().userId === newHomeListing.userId) {
      const updatedDoc = {
        ...newHomeListing,
        id: docRefNew.id,
      };
      console.log("### updatedDoc", updatedDoc);
      docRef =  await updateDoc(docRefNew, updatedDoc);
    } else {
      console.log("User does not have permission to update this document.");
    }

  }

      // // 
      // const querySnapshot = await getDocs(listingsRef);
      // const listings = [];
      // await querySnapshot.forEach((doc) => {
      //   if (doc) {
      //     listings.push(doc.data());
      //   }
      // });
      // console.log("### listings", listings);
      // const newHomeListingId = (listings.length + 1) || 1;
      // const newHomeListingWithId = {
      //   ...newHomeListing,
      //   id: newHomeListingId,
      // };
      // console.log("### newHomeListingWithId", newHomeListingWithId);
      // // update listings with the new home listing and retrieve the doc.id
      // const docRef = await addDoc(listingsRef, newHomeListingWithId);

      // Update doc at docref with newHomeListingWithId values
      // await updateDoc(docRef, newHomeListingWithId);

      // const docRef = await addDoc(listingsRef, newHomeListingWithId);
      // await addDoc(listingsRef, newHomeListingWithId);
      console.log("### docRef", docRef);
      handleImageUpload(docRef);
      setTriggerShow(true);
      setLoading(false);
      notifier("Home Listing Successfully Created");
  } catch (error) {
    console.error("Error fetching listings:", error);
  }
  };

  const handleRemoveFile = (index) => {
    // Assuming `images` is the state variable that holds your images
    const newImages = [...selectedImages];
    newImages.splice(index, 1);
    setSelectedImages(newImages);
  }


  const submitListing = async () => {
    const { title, description, price, category } = getValues();  
    // Trigger form validation
    const isFormValid = await trigger();
    if (!isFormValid) {
      // Handle validation errors
      return;
    }
    // Proceed with adding the listing
    //...
    const newListing = {
      title,
      description,
      price,
      category,
      userId,
      location,
      createdAt: new Date().toISOString(),
    };


    console.log("### newListing", newListing);

    try {
      const listingsRef = collection(kinDb, "listings");
      const querySnapshot = await getDocs(listingsRef);
      const listings = [];
      querySnapshot.forEach((doc) => {
        if (doc) {
          listings.push({ id: doc.id, ...doc.data() });
        }
      });
      const userListing = listings.find((listing) => listing && listing.userId === doc.id);
      if (userListing) {
        const docRef = doc(kinDb, "listings", userListing.id);
        await updateDoc(docRef, newListing);
        console.log("### docRef", docRef);
      } else {
        const userListingRef = collection(kinDb, "listings");
        const docRef = await addDoc(userListingRef, { ...newListing, userId, id: userId });
        console.log("### docRef", docRef);
      }
      setToggleListingsEditor(false);
      // notifier('Listing added successfully!');
    } catch (error) {
      console.error("Error adding listing:", error);
    }

  };

  const postTitle = watch("postTitle");
  const postDescription = watch("postDescription");
  const postImages = watch("postImages");
  const priceDaily = watch("priceDaily");
  const postPrice = watch("postPrice");
  const postPriceMonthly = watch("postPriceMonthly");
  const formattedPriceDaily = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(priceDaily);
  const formattedPriceMonthly = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(postPriceMonthly);

  const [isExpanded, setIsExpanded] = useState(false);

  const addListing = async () => {
    // const { title, description, price } = getValues();

    // Trigger form validation
    const isFormValid = await trigger();

    if (!isFormValid) {
      // Handle validation errors
      return;
    }

    // Proceed with adding the listing
    // ...

    console.log("### title", title);
  };

 

  
  

  const handleChange = (fieldName, value) => {
    // Update the form input value using the setValue function from react-hook-form
    // const formattedPrice = value.toLocaleString("en-US", {
    //   style: "currency",
    //   currency: "USD",
    // });
    setValue(fieldName, value);
  };

  return (
    <div>
      {toggleListingsEditor && (
        <ListingsForm onSubmit={() => handleSubmit(submitListing)}>
          <ListingsContainer>
            <InputContainerUi>
              <ListingHeaderContainer>
                <ListingHeader>Add A Listing</ListingHeader>
                <LinkBtn
                  onClick={() => setToggleListingsEditor(!toggleListingsEditor)}
                  small
                >
                  Close
                </LinkBtn>
              </ListingHeaderContainer>
              <ListingSubHeader>
                Have a couch to move? Dog to walk? Plants to water? Post your task here!
                
              </ListingSubHeader>
              <ListingsContainer>
            <ColumnContainer>
              <ProfileImageWrapper>
                {profileImage && (
                  <ProfileImage src={profileImage} alt="profile" />
                )}
              </ProfileImageWrapper>
              <InputContainerUi>
                <ListingContentContainer>
                  {!!title && <ListingHeader>{title}</ListingHeader>}
                  {!!price && <ListingContent>{formattedPrice}</ListingContent>}
                </ListingContentContainer>
                <ListingContentContainer>
                  {location && (
                    <ListingContent>{location}</ListingContent>
                  )}
                </ListingContentContainer>
                <ListingContentContainer>
                  {description && (
                    <ListingContent>{description}</ListingContent>
                  )}
                </ListingContentContainer>
                <ListingContentContainer>
                  {category && (
                    <ListingContent>{category}</ListingContent>
                  )}
                </ListingContentContainer>
               
              
              </InputContainerUi>
            </ColumnContainer>
          </ListingsContainer>
              {panels.map((panel, index) => (
                <Panel key={index} active={index === currentPanel}>
                  <InputWrapper>
                    {/* <ListingContentContainer> */}
                    <ListinigsLabel htmlFor={panel.inputId}>
                      {panel.label}
                    </ListinigsLabel>
                    {panel.inputId === "title" && (<>
  <input
    type="text"
    id={panel.inputId}
    {...register(panel.fieldName, { required: true })}
  />
  <EmojiPicker  isExpanded={isExpanded} setIsExpanded={setIsExpanded} getValues={getValues} setValue={setValue} inputId={panel.inputId} fieldName={panel.fieldName}  onEmojiClick={(emoji) => {}} />
  </>
)}

                    {panel.inputId === "description" && (
                      <textarea
                        type="text"
                        id={panel.inputId}
                        {...register(panel.fieldName, { required: true })}
                      />
                    )}
                    {panel.inputId === "price" && (
                      <input
                        type="number"
                        id={panel.inputId}
                        {...register(panel.fieldName, { required: true })}
                        step="0.01"
                        min="0"
                        // value={values[panel.fieldName]}
                        onBlur={(e) => {
                          const value = parseFloat(e.target.value).toFixed(2);
                          handleChange(panel.fieldName, value);
                        }}
                      />
                    )}

                    {panel.inputId === "category" && (
                      <select
                        id={panel.inputId}
                        {...register(panel.fieldName, { required: true })}
                      >
                        <option value="No Category">Select a category</option>
                        {categories.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </select>
                    )}

                    {panel.inputId === "location" && (
                      <PlacesAutocomplete
                        value={location}
                        onChange={handleLocationChange}
                        onSelect={handleLocationSelect}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              type="text"
                              id={panel.inputId}
                              {...getInputProps({
                                placeholder: 'Enter location...',
                                className: 'location-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div style={{padding: "10px"}}>Loading...</div>}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <AddressSuggestion
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </AddressSuggestion>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    )}
                  </InputWrapper>
                </Panel>
              ))}

              
              <ButtonContainer>
                {currentPanel > 0 && <Button onClick={handleBack}>Back</Button>}
                {currentPanel < panels.length - 1 && (
                  <Button onClick={handleNext}>Next</Button>
                )}
                {currentPanel === panels.length - 1 && (
                  <Button onClick={() => submitListing()} type="submit">Submit</Button>
                )}
              </ButtonContainer>
              <ListingSubHeader>
                 Only purchase from friends you trust. Must be 18 or older. Must have finalized payment account setup to receive payments. Do Not post sensitive information. By
                submitting your listing, you agree to our.
                <ColumnContainer>
                  <LinkBtn tiny>Terms of Service</LinkBtn>
                  <LinkBtn tiny>Privacy Policy</LinkBtn>
                </ColumnContainer>
                .
              </ListingSubHeader>
            </InputContainerUi>
          </ListingsContainer>
         
        </ListingsForm>
      )}
   




{toggleHomeListingsEditor && (
        <ListingsForm onSubmit={handleSubmit(storeHomeListing)}>
          <ListingsContainer>
            <InputContainerUi>
              <ListingHeaderContainer>
                <ListingHeader>List a Home</ListingHeader>
                <LinkBtn
                  onClick={() => setToggleHomeListingsEditor(!toggleHomeListingsEditor)}
                  small
                >
                  Close
                </LinkBtn>
              </ListingHeaderContainer>
              <ListingSubHeader>
                List your home so your friends can see!
                
              </ListingSubHeader>

              <ListingsContainer>
                {// display error

                  // <Error>
                    errors  && <ListingSubHeader>{errors.messsage}</ListingSubHeader>
                  // </Error>
                
                }
            <ColumnContainer>
              <ProfileImageWrapper>
                {profileImage && (
                  <ProfileImage src={profileImage} alt="profile" />
                )}
              </ProfileImageWrapper>
              <InputContainerUi>
                <ListingContentContainer>
                  {!!postTitle && <ListingHeader>{postTitle}</ListingHeader>}
                </ListingContentContainer>
                <ListingContentContainer>
                  {locationHome && (
                    <ListingContent>{locationHome}</ListingContent>
                  )}
                </ListingContentContainer>
                <ListingContentContainer>
                  {!!postPrice && postPrice > 0 && <ListingContent>${postPrice} / Day</ListingContent>}
                  <br />
                  {!!postPriceMonthly && postPriceMonthly > 0 && <ListingContent>${postPriceMonthly}/Month</ListingContent>}
                </ListingContentContainer>
                
               
                <ListingContentContainer>
                <ImageGridContainer>
                {/* <ListingContentContainer> */}
                {selectedImages.map((image, index) => (
      <ImageGrid key={index}>
        <RemoveImageBtn onClick={() => handleRemoveFile(index)}>X</RemoveImageBtn>
        <GridImage src={URL.createObjectURL(image)} alt={`Image ${index}`}
        onClick={() => handleRemoveFile(index)}
        />
      </ImageGrid>
    ))}
                {/* </ListingContentContainer> */}
                </ImageGridContainer>
                </ListingContentContainer>

                <ListingContentContainer>
                  {postDescription && (
                    <ListingContent>{postDescription}</ListingContent>
                  )}
                </ListingContentContainer>

                {homeImagesUploaded && 
                <ListingContentContainer style={{width: "30%",display: "flex", justifyContent: "center", padding: "0.5em", background: "#14f72b", borderRadius: "4px"}}>
                  <ListingContent>Images Have been Uploaded</ListingContent>
                </ListingContentContainer>}
                
              </InputContainerUi>
            </ColumnContainer>
          </ListingsContainer>
              {homePanels.map((panel, index) => (
                <Panel key={index} active={index === currentPanelHome}>
                  <InputWrapper>
                    {/* <ListingContentContainer> */}
                    <ListinigsLabel htmlFor={panel.inputId}>
                      {panel.label}
                    </ListinigsLabel>
                    {panel.inputId === "postTitle" && (<>
  <input
    type="text"
    id={panel.inputId}
    {...register(panel.fieldName, { required: true })}
  />
  <EmojiPicker  isExpanded={isExpanded} setIsExpanded={setIsExpanded} getValues={getValues} setValue={setValue} inputId={panel.inputId} fieldName={panel.fieldName}  onEmojiClick={(emoji) => {}} />
  </>
)}

                    {panel.inputId === "postDescription" && (
                      <textarea
                        type="text"
                        id={panel.inputId}
                        {...register(panel.fieldName, { required: true })}
                      />
                    )}
                    {panel.inputId === "postImages" && (
                    

                    // ...

                    // Inside the render function
                        <div>
                        <input
                          type="file"
                          id={"homeImageUpload"}
                          onChange={handleImageSelection}
                          multiple
                        />
                        <button style={{ 
                            cursor: "pointer",
                            marginTop: '20px',
                            padding: "0.5em",
                            background: "#18607633",
                            transition: "background-color 0.3s",

                          }} 
                          onClick={handleImageUpload}>Upload Images</button>
                        </div>
                      )}


            {panel.inputId === "postPrice" && (
                      <div>
                        <input
                        type="number"
                        id={panel.inputId}
                        {...register(panel.fieldName, { required: true })}
                        step="0.01"
                        min="0"
                        style={{ marginTop: '10px' }}
                        // value={values[panel.fieldName]}
                        placeholder="Set Daily Price"
                        onBlur={(e) => {
                          const value = parseFloat(e.target.value).toFixed(2) || 0;
                          handleChange(panel.fieldName, value);
                        }}
                      />
                      <label htmlFor="postPrice">Set Daily Rate</label>


                      <input
                        type="number"
                        id={"postPriceMonthly"}
                        {...register("postPriceMonthly", { required: false })}
                        step="0.01"
                        min="0"
                        placeholder="Set Monthly Price"
                        // value={values[panel.fieldName]}
                        onBlur={(e) => {
                          const value = parseFloat(e.target.value).toFixed(2) || null;
                          handleChange("postPriceMonthly", value);
                        }}
                      />
                      <label htmlFor="postPriceMonthly">Set Monthly Price</label>
                      </div>
                    )}


{panel.inputId === "locationHome" && (
                      <PlacesAutocomplete
                        value={locationHome}
                        onChange={handleLocationChangeHome}
                        onSelect={handleLocationSelectHome}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              type="text"
                              id={panel.inputId}
                              {...getInputProps({
                                placeholder: 'Enter location...',
                                className: 'location-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div style={{padding: "10px"}}>Loading...</div>}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                const style = suggestion.active
                                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                  <AddressSuggestion
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </AddressSuggestion>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    )}
              





                    {/* {panel.inputId === "price" && (
                      <input
                        type="number"
                        id={panel.inputId}
                        {...register(panel.fieldName, { required: true })}
                        step="0.01"
                        min="0"
                        // value={values[panel.fieldName]}
                        onBlur={(e) => {
                          const value = parseFloat(e.target.value).toFixed(2);
                          handleChange(panel.fieldName, value);
                        }}
                        />
                        )} */}

                        {/* {panel.inputId === "location" && (
                        <PlacesAutocomplete
                          value={location}
                          onChange={handleLocationChange}
                          onSelect={handleLocationSelect}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                          <input
                          type="text"
                          id={panel.inputId}
                          {...getInputProps({
                            placeholder: 'Enter location...',
                            className: 'location-input',
                          })}
                          />
                          <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                            return (
                            <div
                            key={index}
                            {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                            })}
                            >
                            <span>{suggestion.description}</span>
                            </div>
                            );
                          })}
                          </div>
                          </div>
                          )}
                        </PlacesAutocomplete>
                        )} */}

                        {errors[panel.fieldName] && <p>{panel.errorMessage}</p>}
                        {/* </ListingContentContainer> */}
                  </InputWrapper>
                </Panel>
              ))}


              <ButtonContainer>
                {currentPanelHome > 0 && <Button onClick={handleBackHome}>Back</Button>}
                {currentPanelHome < panels.length - 1 && (
                  <Button onClick={handleNextHome}>Next</Button>
                )}
                {currentPanelHome === panels.length - 1 && (
                  <Button type="submit" onClick={storeHomeListing} >Save</Button>
                )}
              </ButtonContainer>
              <ListingSubHeader>
                 Only purchase from friends you trust. Must be 18 or older. Must have verified vendor account setup to receive payments on the platform. <strong>Do Not</strong> post sensitive information. By
                submitting your listing, you agree to our.
                <ColumnContainer>
                  <LinkBtn tiny>Terms of Service</LinkBtn>
                  <LinkBtn tiny>Privacy Policy</LinkBtn>
                </ColumnContainer>
                .
              </ListingSubHeader>
            </InputContainerUi>
          </ListingsContainer>
         
        </ListingsForm>
      )}

<ListOptionsContainer>



{!toggleListingsEditor && (
        <LinkBtn
          small
          onClick={() => {
            setToggleListingsEditor(!toggleListingsEditor);
          }}
        >
          Post Listing
        </LinkBtn>
      )}
      {!toggleHomeListingsEditor && (
        <LinkBtn
          small
          onClick={() => {
            setToggleHomeListingsEditor(!toggleHomeListingsEditor);
          }}
        >
          List Your Place
        </LinkBtn>
      )}


</ListOptionsContainer>




      {listings.length === 0 && <p>No listings found</p>}
      {listings.map((listing) => (
        <div key={listing.id}>
          <h3>{listing.title}</h3>
          <p>{listing.description}</p>
        </div>
      ))}
    </div>
  );
};

export default UserListings;
