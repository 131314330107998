import React, { Children, useContext } from "react";
import moment from "moment";
import app from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";

import "firebase/compat/auth";
import "firebase/firestore";
import { kinDb, appFirebase, providers, kindAuth } from "../firebase-config";
// import { getFirestore, collection, getDocs, addDoc, doc, deleteDoc, QuerySnapshot, DocumentData, where, CollectionReference, and, getDoc, updateDoc } from 'firebase/firestore/lite';
import userEvent from "@testing-library/user-event";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  addDoc,
  query,
  setDoc,
  where,
  updateDoc,
  getDoc,
  getFirestore,
  deleteDoc,
} from "firebase/firestore";
import { getUserId, Sendrequest } from "../firebase";
import firebase from "firebase/compat/app";
import useDirectionsMap from "../hooks/useMapDirections";

import { getStorage, ref } from "firebase/storage"
import { storeInvitedUser, checkForExistingInvite } from "../storage/email";
import { get } from "http";

export const db = getFirestore(appFirebase);
export const storage = getStorage(appFirebase);

export const getFriends = async (setFriends: any, setPendingFriends: any) => {
  const userId = getUserId();
  if (!userId) return;

  console.log("updating friends list");
  const cUserRef = collection(db, "users");
  const userRef = doc(cUserRef, userId);

  const collectionRef = collection(db, "users");
  const q = query(collectionRef, where("userId", "==", userId));

  const docSnap = await getDocs(q);

  if (!docSnap.empty) {
    let friends;
    let myFriends: any = [];
    let pendingFriends: any = [];
    onSnapshot(q, async (querySnapshot) => {
      //  await getDocs(q).then(async(querySnapshot) => {
      if (querySnapshot.empty) return;
      querySnapshot?.forEach((snap) => {
        // console.log(snap?.id, " => ", snap.data());
        friends = snap.data()?.friends?.forEach(async (friend: any) => {
          // console.log('current friend', friend)
          const qFriend = query(
            collectionRef,
            where(firebase.firestore.FieldPath.documentId(), "==", friend?.id)
          );

          const docSnapsFriend = await getDocs(qFriend);

          if (!docSnapsFriend?.empty) {
            docSnapsFriend?.forEach((docSnapFriend) => {
              // const docSnapFriend = await getDoc(friendRef);
              if (docSnapFriend.exists()) {
                // console.log("Document data:", docSnapFriend.data());
                // console.log(friend, docSnapFriend.data())
                // docSnapFriend.data()?.forEach((friend: any) => {
                // if(friend.id !== docSnapFriend.id){
                // console.log(docSnapFriend.data())
                friend["userName"] = docSnapFriend.data().userName;
                if (friend.pending === true) {
                  pendingFriends.push(friend);
                } else {
                  if (friend.accepted === true && friend.blocked === false) {
                    myFriends.push(friend);
                  }
                }
                // }

                // const aFriends = docSnapFriend.data()?.friends;
                // if(friends) {
                // const newFriends = friends.map((friend: any) => {
                //   if (friend?.pending ) return friend ;
                //   return friend;
                // });

                // await updateDoc(doc(db, 'users', friendId), {friends: newFriends});
                // await updateDoc(friendRef, {friends: newFriends});
                // setPendingFriends(newFriends);
                // fiesta('Friend Accepted :)');
                // setActiveFriendPanel(2);
                // }
                // return docSnapFriend.data();
              } else {
                // doc.data() will be undefined in this case
                console.log("No matching friend!");
                // notifier('Friend not found!')
                // return;
              }
            });

            // console.log('friends are', pendingFriends, myFriends)
          }
          // await setPendingFriends(pendingFriends);
          const dedupedActiveFriends = myFriends?.filter(
            (ele: { jobid: any; id: any }, ind: any) =>
              ind ===
              myFriends.findIndex(
                (elem: { jobid: any; id: any }) =>
                  elem.jobid === ele.jobid && elem.id === ele.id
              )
          );
          // await setFriends(dedupedActiveFriends);
        });
        if (friends?.length! > 0) {
          // setPendingFriends(pendingFriends);
          const dedupedActiveFriends = myFriends?.filter(
            (ele: { jobid: any; id: any }, ind: any) =>
              ind ===
              myFriends.findIndex(
                (elem: { jobid: any; id: any }) =>
                  elem.jobid === ele.jobid && elem.id === ele.id
              )
          );
          // setFriends(dedupedActiveFriends);
        }
      });
    });
    // .catch((error) => {
    //     // notifier(`${error}`)
    //     console.log('Error getting document:', error);
    //     console.log("No such document!");
    //     return;
    //   })
  } else {
    console.log("no friends");
    return;
  }
};

export const listenForFriendChanges = async (
  setFriends: any,
  setPendingFriends: any,
  setStartLocation: any,
  setDestination: any,
  setClearTogglesActive: any,
  setUserData: any,
  setResetDriverMarker: any,
  activeFriends: any,
  setActiveFriends: any,
  notifier: any
) => {
  const userId = getUserId() || "";

  try {
    // Get the current user's reference
    // const currentUserRef = doc(db, "users", userId);
    // const currentUserSnap = await getDoc(currentUserRef);
    // const currentDocRef = currentUserSnap.ref;
    // const currentUserData = currentUserSnap.data();
    const collectionRef = collection(db, "users");
    const qMe = query(collectionRef, where("userId", "==", userId));
    const docSnap = await getDocs(qMe);
    // console.log("listenForFriendChanges", docSnap, userId);

    if (docSnap.empty || !docSnap?.docs[0]?.ref) return;
    // Set up a listener for the current user's document
    onSnapshot(docSnap?.docs[0]?.ref, async (userSnapshot) => {
      // console.log(userSnapshot?.exists(), userSnapshot?.data());
      userSnapshot?.exists() && setUserData(userSnapshot?.data());
      // const activeRideRequest = userSnapshot.get("activeRideRequest");
      const activeRideRequest = userSnapshot.data()?.activeRideRequest;
      const currentRideRequest = userSnapshot.data()?.currentRideRequest;
      const docSnapsFriends: any[] = []; // Initialize an array to store friends data
      // const currentRideRequest = userSnapshot.get("currentRideRequest");
      const userSnap = userSnapshot?.data();
      // console.log("activeRideRequest", activeRideRequest);
      // console.log("currentRideRequest", currentRideRequest);

      if (
        currentRideRequest?.location &&
        currentRideRequest?.dropLocation &&
        activeRideRequest
      ) {
        setStartLocation(currentRideRequest.location);
        setDestination(currentRideRequest.dropLocation);

        // Handle friends data
        const friends = userSnap?.friends || [];
        // console.log("#getting drive distance, currentRideRequest", {
        //   currentRideRequest,
        // });
        const driveDistance = await useDirectionsMap({
          startLocation: currentRideRequest?.location,
          destination: currentRideRequest?.dropLocation,
        }).then((res: any) => {
          // console.log({ res });
          // const driverDistance = res && Number(res).toFixed(2);
          const driverDistance = res?.text;
          // console.log('suup',{friend, driverDistance});
          //  console.log({driverRebuild})
          // return  driverRebuild;
          return driverDistance;
          // return driverRebuild;
        });

        // console.log("*friends", friends);

        // Iterate through friends
        const friendLoop = await friends.map(async (friend: any) => {
          if (
            friend?.accepted &&
            friend?.approved &&
            !friend?.blocked &&
            !friend?.pending
          ) {
            const friendId = friend?.id;
            const friendRef = doc(db, "users", friend?.id);
            // where driverMode is true
            const friendQ = query(
              collectionRef,
              where(firebase.firestore.FieldPath.documentId(), "==", friendId),
              where("driverMode", "==", true)
            );
            // get freindQ
            const friendSnap = await getDocs(friendQ);
            // const friendSnap = await getDoc(friendRef);

            // console.log("friendSnap", friendSnap.empty, friendSnap.docs);

            if (!friendSnap.empty) {
              const friendData = friendSnap.docs[0].data();
              let driverDistance;

              // console.log("friend matched listen for", { friendData });
              if (friendData?.driverMode) {
                const friendUserName = friendData.userName;
                // console.log(`startLocation: ${currentRideRequest?.location},
                //   destination: {lng: ${friendData?.location?.lng}, lat: ${friendData?.location?.lat}},`);
                let friendDistanceVal;
                const distance = await useDirectionsMap({
                  startLocation: currentRideRequest?.location,
                  destination: {
                    lng: friendData?.location?.lng,
                    lat: friendData?.location?.lat,
                  },
                }).then((res: any) => {
                  // console.log({ res });
                  const driverDistance = res?.text; //&& Number(res).toFixed(2);
                  friendDistanceVal = res?.value;
                  // console.log('suup',{friend, driverDistance});
                  const driverRebuild = {
                    ...friend,
                    distanceFromStart: driverDistance,
                  };
                  //  console.log({driverRebuild})
                  // return  driverRebuild;
                  return driverDistance;
                  // return driverRebuild;
                });

                // console.log("*** distance", { distance }, friendDistanceVal);

                // const distance = getDistance(
                //   friendData.location,
                //   currentUserData?.location
                //   );
                // console.log("driver activated", friendData?.userName);

                if (!friendDistanceVal || friendDistanceVal === undefined) {
                  // console.log(
                  //   "Friend missing distand exiting",
                  //   friendUserName,
                  //   friendData
                  // );
                  // notifier("Friend missing distand exiting", friendUserName);
                  // Handle distance logic here
                  return;
                }

                // console.log(`Distance to friend ${friendUserName} is ${friendDistanceVal} km`);
                
                if (
                  friendDistanceVal !== undefined &&
                  friendDistanceVal < 66666
                ) {
                  
                  // console.log("photo", friendData?.photoURL);

                  const friendObj = {
                    id: friendId,
                    resetDriverMarker: friendData.driverMode ? "show" : "hide",
                    approved: friend.approved,
                    rideType: currentRideRequest?.rideType,
                    passengerCount: currentRideRequest?.passengerCount,
                    rideEstimate: currentRideRequest?.rideEstimate,
                    distanceFromPickup: friendDistanceVal,
                    rideDistanceEstimate:
                      currentRideRequest?.rideDistanceEstimate?.distance?.text,
                    rideTimeEstimate:
                      currentRideRequest?.rideDistanceEstimate?.duration?.text,
                    friendUserName: friendUserName,
                    lng: friendData.location?.lng,
                    lat: friendData.location?.lat,
                    distanceFromStart: distance,
                    photoURL: friendData?.photoURL,
                    driveDistance,
                    startLocation: currentRideRequest?.location,
                    destination: {
                      lng: friendData?.location?.lng,
                      lat: friendData?.location?.lat,
                    },
                  };

                  if (friendData?.photoURL) {
                    friendObj.photoURL = friendData.photoURL;
                  }

                  let driverRebuild = null;
                  // docSnapsFriends.push({...friendObj});
                  // docSnapsFriends.push(friendObj);

                  // Check if the friend object is already in the array before adding it
                  if (!docSnapsFriends.some((f) => f.id === friendId)) {
                    driverRebuild = { ...friendObj };
                    docSnapsFriends.push({ ...friendObj });
                  }

                  if (driverRebuild) return driverRebuild;
                }
                if (
                  friendDistanceVal !== undefined &&
                  friendDistanceVal > 66666
                ) {
                  console.log("listen fo far friend");
                }

                // // Update the state with the latest friends data
                // setActiveFriends([...docSnapsFriends]);
                // setResetDriverMarker("show");
              }
            }

            // Set up a listener for the friend's document
            //   const unsubscribeFriend = onSnapshot(friendRef, (friendSnapshot) => {
            //     const friendData = friendSnapshot.data();

            //     if (friendData?.driverMode) {
            //       const friendUserName = friendData.userName;
            //       const distance = getDistance(
            //         friendData.location,
            //         currentUserData?.location
            //       );

            //       if (distance !== undefined) {
            //         // Handle distance logic here
            //       }

            //       const friendObj = {
            //         id: friendId,
            //         resetDriverMarker: friendData.driverMode ? "show" : "hide",
            //         approved: friend.approved,
            //         friendUserName: friendUserName,
            //         lng: friendData.location?.lng,
            //         lat: friendData.location?.lat,
            //       };

            //       // Check if the friend object is already in the array before adding it
            //       if (!docSnapsFriends.some((f) => f.id === friendId)) {
            //         docSnapsFriends.push(friendObj);
            //       }

            //       // Update the state with the latest friends data
            //       setActiveFriends([...docSnapsFriends]);
            //       setResetDriverMarker("show");
            //     }
            //   });

            //   // Remember to unsubscribe from the friend's listener when it's no longer needed
            //   return () => unsubscribeFriend();
          }
        });

        // Promise.all(docSnapsFriends).then((res) => {
        // console.log('res listen', docSnapsFriends)
        // console.log('res checkFriends listen', checkFriends)
        // setActiveFriends(res);
        // setResetDriverMarker("show");

        //  # after friendLoop is done
        // console.log("friendLoop", friendLoop);

        const checkFriends = docSnapsFriends?.length > 0 ? docSnapsFriends : [];
        // console.log("checkFriends", checkFriends);

        Promise.allSettled(friendLoop).then(async (res) => {
          // console.log("res checkFriends listen", docSnapsFriends, res);

          // console.log("active snap friends are", docSnapsFriends);

          await setActiveFriends([]);

          docSnapsFriends?.forEach(async (friend: any) => {
            // console.log(
            //   "usersnap",
            //   { userSnap },
            //   friend?.id,
            //   userSnap?.userName
            // );

            const docFriendRef = doc(db, "users", friend.id);

            const docFriendSnap = await getDoc(docFriendRef);

            // console.log(
            //   "docFriendSnap",
            //   docFriendSnap.exists(),
            //   docFriendSnap.data()
            // );
            if (docFriendSnap.exists()) {
              // console.log("*listen docFriendSnap exists", docFriendSnap.data());
              // #check friendRideRequests to see if friend.id
              const friendRideRequestsRaw =
                docFriendSnap?.data()?.friendRideRequests;

// check that they are not more than 24hrs old and filter out
              const friendRideRequests = friendRideRequestsRaw?.filter(
                (friendRideRequest: any) =>
                  friendRideRequest?.id === userSnap?.userId &&
                  friendRideRequest?.time >
                    Date.now() - 6400000
              )
              // console.log({ friendRideRequests }, friend?.id);
              const checkFriendRideRequests = friendRideRequests?.filter(
                (friendRideRequest: any) =>
                  friendRideRequest?.id === userSnap?.userId
              );
              // console.log({ checkFriendRideRequests });
              if (
                !checkFriendRideRequests ||
                checkFriendRideRequests?.length === 0
              ) {
                // console.log(
                //   "no friend ride requests",
                //   userSnap?.photoURL,
                //   friend?.photoURL
                // );

                // console.log('## prepping friend', currentRideRequest?.passengerCount)

                const friendObject = {
                  id: userSnap?.userId,
                  rideType: currentRideRequest?.rideType,
                  passengerCount: currentRideRequest?.passengerCount,
                  rideEstimate: currentRideRequest?.rideEstimate,
                  distanceFromPickup: friend?.distanceFromPickup,
                  rideDistanceEstimate:
                    currentRideRequest?.rideDistanceEstimate?.distance?.text,
                  rideTimeEstimate:
                    currentRideRequest?.rideDistanceEstimate?.duration?.text,
                  userName: userSnap?.userName,
                  time: new Date(),
                  active: true,
                  rideAccepted: false,
                  expired: false,
                  distance: friend?.distanceFromStart,
                  photoURL: userSnap?.photoURL || null,
                };

                if (userSnap?.photoURL) {
                  friendObject.photoURL = userSnap.photoURL;
                }

                // console.log('*** friendObject', friendObject, );
                await updateDoc(docFriendSnap.ref, {
                  friendRideRequests: [
                    ...(friendRideRequests || []),
                    { ...friendObject },
                  ],
                });

                // console.log('**updated', [
                //   ...(friendRideRequests || []),
                //   {...friendObject},
                // ])
              }
            }
          });

          docSnapsFriends?.length && console.log("longer than 1");

          docSnapsFriends?.length && (await setActiveFriends(docSnapsFriends));

          // setResetDriverMarker("show");
        });

        // console.log("*** updatings", docSnapsFriends === activeFriends);
        // console.log("*** docs", { docSnapsFriends });

        // if (docSnapsFriends.length === 0) {
        //   // setResetDriverMarker("hide");
        //   setActiveFriends([]); // Clear friends data when no active ride request
        // } else {
        //   console.log('active friends are', docSnapsFriends)
        //   setActiveFriends({docSnapsFriends});
        //   // setResetDriverMarker("show");
        // }
        // });
      } else {
        setStartLocation(null);
        setDestination(null);
        setClearTogglesActive(true);
        setActiveFriends([]); // Clear friends data when no active ride request
      }

      if (!userSnapshot?.exists()) return;
      // console.log(
      //   "snapshot",
      //   userSnapshot.exists(),
      //   "docSnap",
      //   docSnap?.docs,
      //   docSnap?.empty
      // );
      const friends = userSnapshot
        ?.data()
        ?.friends?.filter((doc: any) => !doc?.pending)
        .map((doc: any) => ({ id: doc.id, ...doc }));
      // const friends = userSnapshot.data().map((doc) => ({ id: doc.id, ...doc.data() }));
      // console.log("setting friends", friends);
      setFriends(friends);

      //  if(!userSnapshot?.data()?.friends) return;
      const pendingFriends = userSnapshot
        ?.data()
        ?.friends?.filter((doc: any) => doc?.pending);
      // .map((doc: any) => ({ id: doc.id, ...doc.data() }));
      // console.log("setting pending friends", pendingFriends);
      setPendingFriends(pendingFriends);
    });

    // Remember to unsubscribe from the current user's listener when it's no longer needed
    // return () => unsubscribeCurrentUser();
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error gracefully
  }
};

// export const listenForFriendChangesOld = async (
//   setFriends: any,
//   setPendingFriends: any,
//   setStartLocation: any,
//   setDestination: any,
//   setClearTogglesActive: any,
//   setUserData: any,
//   setResetDriverMarker: any,
//   activeFriends: any,
//   setActiveFriends: any,

// ) => {
//   const userId = getUserId() || "";
//   const cUserRef = doc(db, "users", userId);

//   const collectionRef = collection(db, "users");
//   const friendsRef = collection(cUserRef, "friends");

//   console.log('friendsRef', friendsRef)
//   // const unsubscribeFriends = onSnapshot(friendsRef, (snapshot) => {
//   //   const friends = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
//   //   setFriends(friends);
//   // });
//   const qMe = query(collectionRef, where("userId", "==", userId));
//   const docSnap = await getDocs(qMe);
//   let docSnapsFriends: any = [];

//   // Listen to the friends subcollection for changes
//   const friendsSubcollectionRef = collection(docSnap.docs[0].ref, "friends");
//   const currentUser = docSnap.docs[0].data();
//   console.log('currentUser', currentUser)
//   setUserData(currentUser);

//   onSnapshot(docSnap.docs[0].ref, (snapshot) => {

//     const activeRideRequest = snapshot.data()?.activeRideRequest;
//     console.log('activeRideRequest', activeRideRequest)
//     // check if currentriderequest
//     const currentRideRequest = snapshot.data()?.currentRideRequest;
//     console.log('currentRideRequest', currentRideRequest)

//     if(currentRideRequest?.location && currentRideRequest?.dropLocation && activeRideRequest) {
//        setStartLocation(currentRideRequest.location);
//        setDestination(currentRideRequest.dropLocation);
//       // setClearTogglesActive(!currentRideRequest);
//       // setClearTogglesActive(false);

//       const friends = docSnap.docs[0].data()?.friends;
//       if (friends) {
//         let friendUserName: string;
//     // const userSnap =  getDoc(userRef);
//         const collectionRef = collection(db, "users");

//         const s = new Set(docSnapsFriends.map((o: any) => Object.values(o).join("+")));

//         const addNewObj = (docSnapsFriends: any[], obj: any) => {
//            if (!s.has(Object.values(obj).join("+"))) {
//              docSnapsFriends.push(obj);
//            }
//          };

//         console.log('my friends are', friends)
//         friends?.forEach(async (friend: any) => {
//           if (
//             friend?.accepted &&
//             friend?.approved &&
//             !friend?.blocked &&
//             !friend?.pending
//           ) {
//             const friendId = friend?.id;
//             const friendQ = query(
//               collectionRef,
//               where(firebase.firestore.FieldPath.documentId(), "==", friendId),
//               // #where driveractivemode is true
//               where("driverMode", "==", true)
//             );

//             onSnapshot(friendQ, async (snapShot) => {
//               // docSnapsFriends = [];
//               // console.log("getting on snap reset", resetDriverMarker);
//               // console.log({snapShot})
//               // if (snapShot.empty ) {
//               //   // docSnapsFriends = [];
//               //   // setResetDriverMarker('hide')
//               //   return;
//               // }
//               snapShot.forEach(async (doc) => {
//                 console.log('*a*', doc.data());
//                 // console.log('current active friend', doc.data())

//                 if ( doc.data()?.driverMode) {
//                 friendUserName = doc.data().userName;
//                 console.log("friendUserName", friendUserName);
//                 // Check if friends are withing X miles using lat lng
//                 const distance = getDistance(
//                   doc.data().location,
//                   currentUser?.location
//                 );

//                 console.log({ distance });
//                 if (distance !== undefined && distance < 5) {
//                   console.log("user snap close friend");
//                 }
//                 if (distance !== undefined && distance > 5) {
//                   console.log("user snap far friend");
//                 }
//                 if (distance !== undefined ) {
//                   console.log('distance failed');
//                 }

//                 addNewObj(docSnapsFriends, {
//                   id: friendId,
//                   resetDriverMarker: doc.data()?.driverMode ? "show" : "hide",
//                   approved: friend?.approved,
//                   friendUserName: friendUserName,
//                   lng: doc.data()?.location?.lng,
//                   lat: doc.data()?.location?.lat,
//                 });

//               }
//               });

//               console.log("*** updatings", docSnapsFriends === activeFriends);
//               console.log("*** docs", {docSnapsFriends});
//               if (docSnapsFriends === activeFriends) return;

//               if (docSnapsFriends.length === 0) {
//                 console.log("no docs");
//                 setActiveFriends([]);
//                 await setResetDriverMarker("hide");
//               } else {
//                 console.log("active snap friends are", docSnapsFriends);
//                 await setActiveFriends(docSnapsFriends);
//                 // if (driverMode) {
//                 await setResetDriverMarker("show");
//                 // }
//               }
//             });
//           }
//         });
//         // if (docSnapsFriends.length === 0) {
//         //   console.log('no docs')
//         //   // setResetDriverMarker('hide')
//         // }
//         // console.log('active friends are', docSnapsFriends)
//         //   setActiveFriends(docSnapsFriends)
//         //  setClearTogglesActive(false);
//       }  else {
//         setActiveFriends([]);

//       }

//     } else {
//       setStartLocation(null);
//       setDestination(null);
//       setClearTogglesActive(true);
//       setActiveFriends([]);
//     }

//     // Check if Active Ride Request
//     // const activeRideRequest = snapshot.data()?.activeRideRequest;
//     // if(activeRideRequest) {
//     //   setRideRequestActive(activeRideRequest);
//     //   setClearTogglesActive(!activeRideRequest);
//     // }

//     //
//     console.log('snapshot', snapshot.exists(), 'docSnap', docSnap.docs, docSnap.empty)
//     const friends = snapshot.data()?.friends.filter((doc: any) => !doc?.pending).map((doc: any) => ({ id: doc.id, ...doc }));;
//     // const friends = snapshot.data().map((doc) => ({ id: doc.id, ...doc.data() }));
//     console.log('setting friends', friends)
//      setFriends(friends);

//     const pendingFriends = snapshot.data()?.friends
//     .filter((doc: any) => doc?.pending)
//       // .map((doc: any) => ({ id: doc.id, ...doc.data() }));
//     console.log('setting pending friends', pendingFriends)
//      setPendingFriends(pendingFriends);
//   });

// };

//   const unsubscribePendingFriends = onSnapshot(
//     pendingFriendsRef,
//     (snapshot) => {
//       console.log('friendsRef', friendsRef, 'pendingFriendsRef', pendingFriendsRef)
//       const pendingFriends = snapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
//       setPendingFriends(pendingFriends);
//     }
//   );

//   return () => {
//     unsubscribeFriends();
//     unsubscribePendingFriends();
//   };
// };

const encodeInviteCode = (inviteCode: string) => {
  // console.log(btoa(inviteCode));
  // console.log(decodeInviteCode(btoa(inviteCode)));
  return btoa(inviteCode);
};

const generateInviteCode = () => {
  const userId = getUserId();
  const inviteCode = `dropkins_${userId}_invite`;
  const encodedInviteCode = encodeInviteCode(inviteCode);
  return encodedInviteCode;
};

const generateInviteLink = () => {
  const userId = getUserId();
  const inviteCode = generateInviteCode();
  const inviteLink = `https://dropkins.com/signup?code=${inviteCode}`;
  return inviteLink;
};

export const addFriend = async (
  friendUserName: any,
  setActiveFriendPanel: any,
  setFriends: any,
  setPendingFriends: any,
  setLoading: any,
  notifier: any,
  fiesta?: any,
  setMessageToken?: any,
) => {
  const userId = getUserId();
  if (!userId) return;

  console.log("adding friend");

  const cUserRef = collection(db, "users");
  const userRef = doc(cUserRef, userId);
  let myid: string;
  let friendId: string;
  let newFriend: any;
  const newPendingFriends: any[] = [];
  // let friendUserName: string;
  Sendrequest(setMessageToken);

  
  const filterFriend = friendUserName?.toLowerCase();
  let isEmail = false;
  let q;
  // check if filterFriend is an email or username

  const collectionRef = collection(db, "users");
  const customerRef = collection(db, "customers");
  const userDoc = doc(collectionRef, userId);
  const userSnap = await getDoc(userDoc);
  const userData = userSnap?.data();

  if (filterFriend.includes("@")) {
    isEmail = true;
    console.log('friend info', filterFriend, isEmail);
    const customer = await query(customerRef, where("email", "==", filterFriend));
    // run query and return id
    const docSnap = await getDocs(customer);
    console.log("docSnap", docSnap);
    if (docSnap.empty) {
      notifier("No user found, but we sent them an invite!");
      setLoading(false);

      const inviteCode = generateInviteCode();
      const inviteLink = generateInviteLink();
      await storeInvitedUser(filterFriend, inviteCode);
      try {
        // const sendInviteEmail = httpsCallable(cloudFunctions, "sendInviteEmail");
        const emailData = {
          email: filterFriend,
          fromEmail: "contact@dropkins.com",
          templateId: "d-5ebfde1c8d1547779657185341de943c",
          senderName: userData?.userName,
          inviteLink,
        };

        // Function's endpoint URL
        const functionUrl = process.env.REACT_APP_FUNCTION_SEND_INVITE_MAIL;
        // Use Fetch API to make a POST request

        // fiesta("Success! Invite Sent!");
        // setLoading(false);
        // console.log("Success:");

        // Re-enable when ready to send emails

        if (!functionUrl) {
          // throw new Error("functionUrl is undefined");
          console.log('functionUrl error')
        } else {
          fetch(functionUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          })
            .then((response) => {
              // console.log({ response });
              setLoading(false);
              // setTriggerShow(true);
              return response?.json();
            })
            .then((data) => {
              fiesta("Success! Invite Sent!");
              // console.log("Success:", data);
            })
            .catch((error) => {
              console.error("Error:", error);
              setLoading(false);
              // setTriggerShow(true);
              notifier("Uht Oh! Something went wrong!");
            });
        }

        
      } catch (error) {
        // setTriggerShow(true);
        // console.log("invite error", error);
        setLoading(false);
        notifier(`error! Invite Sent!`);
        // setError("inviteEmail", "general", error.message);
        // setLoading(false);
      }
      
      return;
    }
    // get first doc and return id
    const doc = docSnap.docs[0];
    friendId = doc.id;

    q = query(collectionRef, where("userId", "==", friendId));
  } else {
    q = query(collectionRef, where("userName", "==", filterFriend));

  }


  


  const qMe = query(collectionRef, where("userId", "==", userId));

  // const qFriend = query(collectionRef,
  //   where(firebase.firestore.FieldPath.documentId(), '==', friendId)
  // )

  const docSnap = await getDocs(q);

  docSnap.forEach((snap) => {
    // {
    // console.log(snap.id, " add friend => ", snap.data());
    // }
    friendId = snap.id;
    newFriend = snap.data();

    // friendUserName = snap.data().userName;
  });
  // const docFriendSnap = await getDocs(qMe);

  if (!docSnap.empty) {
    // Query logged in user
    getDocs(qMe)
      .then((querySnapshot) => {
        let friends: any[];
        let username;
        if (querySnapshot.empty) return;
        // console.log("add friend docsnap", docSnap, querySnapshot.docs);

        // Cycle through friends to check if friend already exists
        querySnapshot.forEach(async (snap) => {
          // docSnap.forEach(async (snap) => {
          // console.log(snap.id, "add friend qsnap => ", snap.data());

          const myid = snap.id;
          const friends = snap.data().friends;
          const username = snap.data().userName;
          // console.log('friends are', friends)
          // setFriends(friends);

          // console.log(snap.id, " => ", snap.data());

          // console.log('username is', username, 'new is', friendsUsername)
          // if(username !== friendsUsername) {
          // const docRef = doc(db, "users", snap.id);
          let friendExists = false;

          const getPendingFriends = friends
            ?.map((friend: any) => {
              // console.log("***** friend exists", friend?.id, friendId);
              // console.log("freind accepted", friend?.accepted);

              if (friend?.id === friendId) {
                friendExists = true;
                notifier("Already friends");
                return friend;
              }
              if (friend?.accepted === false && friendExists === false) {
                newPendingFriends.push(friend);
                return friend;
              }
              return null;
            })
            .filter(Boolean);
          // if(getPendingFriends.length > 0) {
          const updatePendingFriends = getPendingFriends
            ?.map((friend: any) => {
              // console.log("add friend newPendingFriends", newPendingFriends, {
              //   getPendingFriends,
              // });
              if (friend.id === friendId || friendExists === true) {
                return friend;
              }
              // return null;
            })
            .filter(Boolean);

          if (
            updatePendingFriends?.length! > 0 &&
            friendExists === false &&
            newFriend
          ) {
            newFriend["pending"] = true;
            newFriend["accepted"] = false;
            newPendingFriends.push(newFriend);
            getPendingFriends.push(newFriend);
          }

          // console.log("add friend newPendingFriends", newPendingFriends, {
          //   updatePendingFriends,
          //   getPendingFriends,
          // });
          // }

          // await setPendingFriends(getPendingFriends);

          //  await docSnap.forEach(async (friendSnap) => {
          //     const username = friendSnap.id;
          //     friendId = friendSnap.id;
          //     friendUserName = friendSnap.data().userName;

          //     // console.log(username, friendsUsername);

          //     // if(username === friendsUsername) {

          //       friends?.forEach(async (friend: any) => {
          //         // console.log({friend})
          //         if(friend?.id === friendSnap.id) {
          //           friendExists = true;
          //           notifier('Already friends')
          //           return;
          //         }

          //       })

          //       // friends?.forEach(async (friend: any) => {

          //     // }

          //   })

          // notifier('Friend request sent!');

          // console.log(
          //   "getting friend data",
          //   friendId,
          //   friendUserName,
          //   myid,
          //   username,
          //   friendExists
          // );


          const docFriendRef = doc(db, "users", friendId);
          const userImageRef = ref(storage, "profile_images/" + userId);
          const friendImageRef = ref(storage,
            "profile_images/" + newFriend.userId
          );

          // Get the download URL
          const userPhoto = userImageRef.fullPath;
            // .then((url) => {
            //   // `url` is the download URL for 'profile_images/{userId}'
            //   console.log('url is', url)
            //   return url;
            // })
            // .catch((error) => {
            //   console.log(error);
            //   // Handle any errors
            // });
          // Get the download URL
          const friendPhoto = friendImageRef.fullPath;
            // .getDownloadURL()
            // .then((url) => {
            //   // `url` is the download URL for 'profile_images/{userId}'
            //   console.log("url is", url);
            //   return url;
            // })
            // .catch((error) => {
            //   console.log(error);
            //   // Handle any errors
            // });

          if (friendId !== myid && !friendExists) {
            await updateDoc(docFriendRef, {
              friends: app.firestore.FieldValue.arrayUnion({
                id: myid,
                accepted: false,
                blocked: false,
                pending: true,
                profilePhoto: userPhoto || null,
                userName: username,
              }),
            });

            const docMeref = doc(db, "users", myid);

            await updateDoc(docMeref, {
              friends: app.firestore.FieldValue.arrayUnion({
                id: friendId,
                accepted: true,
                blocked: false,
                profilePhoto: friendPhoto || null,
                pending: true,
                userName: friendUserName,
              }),
            });

            fiesta && fiesta("Friend request sent!");
            setActiveFriendPanel(3)
            setLoading(false);
          } else {
            setLoading(false);
            friendExists
              ? notifier("You've already sent a friend request to this user")
              : notifier("You cannot add yourself as a friend");

          }

          // setFriendsUsername(friendsUsername);
          // } else {
          // console.log('username already exists')
          // notifier('Username already exists')
          // return;
          // }

          //   if( !docFriendSnap.empty ) {
          //     getDocs(qMe).then((queryFSnapshot) => {
          //       let username;
          //       if(queryFSnapshot.empty) return;
          //       queryFSnapshot.forEach(async (snap) => {
          //         console.log(snap.id, " => ", snap.data());
          //         username = snap.data().userName;
          //         console.log('username is', username, 'new is', friendsUsername)
          //         if(username !== friendsUsername) {
          //           const docRef = doc(db, "users", snap.id);
          //           await updateDoc(docRef, {
          //             friends: app.firestore.FieldValue.arrayUnion(snap.id),
          //           });
          //           setFriendsUsername(friendsUsername);
          //         } else {
          //           console.log('username already exists')
          //           notifier('Username already exists')
          //           return;
          //         }
          //       });
          //     })

          // }
        });
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        console.log("No such document!");
        setLoading(false);
        return;
      });

    setLoading(false);
  } else {
    console.log("no existo");
    notifier("Username does not exist");
    setLoading(false);
    return;
  }

  // const docSnap = await getDocs(q);

  // if( !docSnap.empty ) {
  //   getDocs(q).then((querySnapshot) => {
  //     let username;
  //     if(querySnapshot.empty) return;
  //     querySnapshot.forEach(async (snap) => {
  //       console.log(snap.id, " => ", snap.data());
  //       username = snap.data().userName;
  //       console.log('username is', username, 'new is', friendsUsername)
  //       if(username !== friendsUsername) {
  //         const docRef = doc(db, "users", snap.id);
  //         await updateDoc(docRef, {
  //           friends: app.firestore.FieldValue.arrayUnion(userId),
  //         });
  //         setFriendsUsername(friendsUsername);
  //       } else {
  //         console.log('username already exists')
  //         notifier('Username already exists')
  //         return;
  //       }
  //     });
  //   }).catch((error) => {
  //       console.log('Error getting document:', error);
  //       console.log("No such document!");
  //       return;
  //     })
  // } else {
  //   notifier('Username does not exist')
  //   return;
  // }
};

export const updateFriends = async (
  setFriends: any,
  setPendingFriends: any,
  fiesta: any
) => {
  const userId = getUserId();
  if (!userId) return;

  const collectionRef = collection(db, "users");

  const q = query(collectionRef, where("userId", "==", userId));

  const docSnap = await getDocs(q);

  if (!docSnap.empty) {
    onSnapshot(q, (querySnapshot) => {
      // getDocs(q).then((querySnapshot) => {
      // console.log("querySnapshot", querySnapshot);
      let friends: any[];
      let myFriends: any[];
      let pendingFriends: any[];
      if (querySnapshot.empty) return;
      querySnapshot?.forEach(async (snap) => {
        // console.log(snap.id, " => ", snap.data());
        friends = snap.data()?.friends;
        friends?.forEach((friend: any) => {
          !friend?.pending
            ? myFriends.push(friend)
            : pendingFriends.push(friend);
        });
        // console.log('friends are', friends)

        const dedupedMyFriends = myFriends?.filter(
          (ele, ind) =>
            ind ===
            myFriends.findIndex(
              (elem) => elem.jobid === ele.jobid && elem.id === ele.id
            )
        );
        // setFriends(dedupedMyFriends);
        // setPendingFriends(pendingFriends);
      });

      // fiesta('Friends updated!');
    });
    // }).catch((error) => {
    //     console.log('Error getting document:', error);
    //     console.log("No such document!");
    //     return;
    //   })
  } else {
    fiesta("No friends yet");
    return;
  }
};

// #function to store user specific options and data on firestore
export const storeUser = async (userId: string, data: any) => {
  const userRef = doc(db, "users", userId);
  await setDoc(userRef, data);
};

export const getTribalLeaderData = async () => {
  const userId = getUserId();
  let email;
  if (!userId) return;
console.log('Getting tribal leader data for user:', userId);


  const collectionRef = collection(db, "users");
  const collectionInvitesRef = collection(db, "invites");

  const q = query(collectionRef, where("userId", "==", userId));
  

  const customerRef = doc(db, "customers", userId);
const customerSnap = await getDoc(customerRef);


if (customerSnap.exists()) {

  const customerData = customerSnap.data();
  email = customerData.email;
  // Do something with the customer data

} else {
  // Customer document does not exist
}

const qInvite = query(collectionInvitesRef, where("email", "==", email));

// get collection invites
const invitesSnapshot = await getDocs(qInvite);
// get userId from the docs returned by the query
const inviteDocs = invitesSnapshot.docs[0].data();


console.log({inviteDocs})


  const qMayor = query(collectionRef, where("userId", "==", inviteDocs.userId));

const mayorCollection = await getDocs(qMayor);
const mayDocs = mayorCollection?.docs[0].data();
console.log({mayDocs})

  // filter invites to get only pending invites for current user
  const pendingInvites = invitesSnapshot.docs.filter(invite => {
    return invite.data().to === userId && invite.data().status !== 'pending'
  })

  console.log({pendingInvites})

  const docSnap = await getDocs(q);

  if (!docSnap.empty) {
    const userData = docSnap.docs[0].data();
    return mayDocs;
  } else {
    return null;
  }
}






export const updateBio  = async (
  bioField: string,
  firstName: string,
  lastName: string,
  mayorHeadlineField: string,
  setTriggerShow: any,
  setLoading: any,
  notifier: any,
  fiesta: any,
) => {
  // const userId = getUserId();
  const collectionRef = collection(db, "users");
  const q = query(collectionRef, where("userId", "==", getUserId()));

  const docSnap = await getDocs(q);

  // if (!docSnap.empty) {
    console.log('updating')

    updateDoc(docSnap.docs[0].ref, {
      bioField: bioField || "",
      firstName: firstName,
      lastName: lastName || "",
      mayorHeadlineField: mayorHeadlineField || "",
    })
    .then(() => {
      setTriggerShow(true);
      setLoading(false);
      fiesta('Profile updated!');
    })
  // }


}

export const updateUsername = async (
  userName: string,
  setUserName: any,
  userData: any,
  setUserData: any,
  setTriggerShow: any,
  setLoading: any,
  notifier?: any,
  fiesta?: any
) => {
  const userId = getUserId();
  if (!userId) return;

  const niceUserName = userName.toLowerCase().replace(/\s/g, "");

  // console.log({userId, userName})
  // const docRef = doc(collection(kinDb, 'users'), `${userId}`);
  // const db = getFirestore(appFirebase);

  // const usersRef = collection(db, 'users');

  // const q = query(usersRef, where("userId", "==", getUserId()));

  const cUserRef = collection(db, "users");
  const userRef = doc(cUserRef, userId);

  const collectionRef = collection(db, "users");
  const q = query(collectionRef, where("userId", "==", getUserId()));

  const docSnap = await getDocs(q);

  if (!docSnap.empty) {
    getDocs(q)
      .then(async (querySnapshot) => {
        let username;
        if (querySnapshot.empty) return;

        querySnapshot?.forEach(async (snap) => {
          // console.log(snap.id, " => ", snap.data());
          username = snap.data().userName;
          // console.log("username is", username, "new is", niceUserName);
          if (username !== niceUserName) {
            const qExisting = query(
              collectionRef,
              where("userName", "==", niceUserName)
            );
            const docExistingSnap = await getDocs(qExisting);
            if (!docExistingSnap.empty) {
              setTriggerShow(true);
              notifier("Username already exists");
              setLoading(false);
              return;
            }

            const friends = snap.data()?.friends;

            friends?.map((friend: any) => {
              const friendId = friend?.id;
              const qFriend = query(
                collectionRef,
                where(firebase.firestore.FieldPath.documentId(), "==", friendId)
              );

              getDocs(qFriend).then(async (querySnapshot) => {
                querySnapshot?.forEach(async (snap) => {
                  // console.log(snap.id, " => ", snap.data());
                  const myFriends = snap.data()?.friends;
                  myFriends?.map((newFriend: any) => {
                    if (newFriend?.id === userId) {
                      newFriend["userName"] = niceUserName;
                    }
                    return newFriend;
                  });
                  await updateDoc(doc(db, "users", snap.id), {
                    friends: myFriends,
                  });
                });

                // await updateDoc(doc(db, 'users', friendId), {friends: newFriends});
              });
            });

            await updateDoc(doc(db, "users", snap.id), {
              userName: niceUserName,
            }).then(async (resp) => {
              await setUserName(niceUserName);
              if (userData.userName !== niceUserName) setLoading(false);
              setUserData({
                ...userData,
                userName: niceUserName,
                userId: userId,
              });
              setTriggerShow(true);
              fiesta("Updated username!");
            });
          } else {
            setTriggerShow(true);
            console.log("username already exists");
            notifier("Username already exists");
            setLoading(false);
            return;
          }
        });

        // return username
      })
      .catch((error) => {
        console.log("Error getting document:", error);
        console.log("No such document!");
        return;
      });

    //   console.log('docSnap', docSnap)
    //   console.log('docSnap.data()', docSnap.data())
    //   await updateDoc(doc(db, "users", `${userId}`), {
    //     displayName: userName
    //   })
  } else {
    // console.log('no docSnap')
    // const newDoc = doc(db, 'users', `${userId}`);
    // await addDoc(doc(db, "users", `${userId}`), {
    //   userName: userName}
    //   )

    await addDoc(cUserRef, {
      userName: niceUserName,
      userId: userId,
    }).then((resp) => {
      setUserName(niceUserName);
      setTriggerShow(true);
      fiesta("Updated username!");
      setLoading(false);
      if (userData.userName !== niceUserName)
        setUserData({ ...userData, userName: niceUserName, userId: userId });
    });

    // await setDoc(userRef, {
    //   userName: userName}, {merge: true}
    //   )
    // // return true;

    // await setDoc(userRef, {
    //   displayName: userName
    // })
    // .then((docRef) => {
    //   console.log("Document written with ID: ", docRef);

    // }).catch(error => {
    //   console.log(error);
    // })

    console.log("no docSnap");
  }

  // #Update users data on Firestore
  // const userRef = doc(db, "users", userId);
  // await updateDoc(userRef, {
  //   displayName: userName
  // })
};

/**
 * Queries the Firestore database for the friend document of a user and updates the friend's driver status.
 * @param toggle - A boolean value indicating whether to activate or deactivate the friend's driver status.
 * @param friendId - The ID of the friend whose driver status is being updated.
 * @param setFriends - A function to set the updated list of friends.
 * @param activeFriends - The current list of active friends.
 * @param setActiveFriends - A function to set the updated list of active friends.
 * @param notifier - A function to display a notification message.
 * @param fiesta - A function to display a success message.
 */

export const activateDriver = async (
  toggle: boolean,
  friendId: string,
  setFriends: any,
  activeFriends: any,
  setActiveFriends: any,
  setTriggerShow: any,
  notifier: any,
  fiesta: any
) => {
  try {
    const friendRef = doc(db, "friends", friendId);
    const collectionRef = collection(db, "users");
    const q = query(collectionRef, where("userId", "==", getUserId()));
    const currentUserDocs = await getDocs(q);
    await setTriggerShow(true);
    currentUserDocs.forEach(async (doc) => {
      // console.log("doc.data()", doc.data());
      const friends = doc.data()?.friends;

      const updatedFriends = await friends?.map(async (friend: any) => {
        // console.log("friend", friend);
        if (friend.id === friendId) {
          const updatedFriend = {
            ...friend,
            approved: !toggle,
            photoURL: friend?.photoURL || null,
          };
          // toggle ? (friend["approved"] = false) : (friend["approved"] = true);
          // friend["approved"] = !toggle;
          // const checkUpdate = friend.id === friendId ? updatedFriend : friend;
          // await updateDoc(doc?.ref, { friends: [...friends.filter((f: any) => f.id !== friendId), checkUpdate] });
          // await setFriends((prevFriends: any) =>
          //   prevFriends.map((friend: any) =>
          //     friend.id === friendId ? updatedFriend : friend
          //   )
          // );
          await setTriggerShow(true);
          // console.log("updated friend", updatedFriend);
          // notifier("Friend approval updated successfully.");
          // fiesta();
          return { ...updatedFriend };
          // return friend;
        }
        return friend;
      });

      // remove

      await Promise.all(updatedFriends).then(async (updatedFriends) => {
        // console.log("updatedFriends", updatedFriends);
        await updateDoc(doc?.ref, { friends: updatedFriends });
        await setTriggerShow(true);
        // await setFriends(updatedFriends);
        await fiesta("Friend approval updated successfully.");
        // await fiesta();
      });

      // return; // Add this line to prevent the code from continuing to execute after the first matching friend is found and updated
    });
  } catch (error) {
    console.error(error);
    notifier("Error updating friend approval.");
  }
};

export const deleteFriend = async (
  friendId: string,
  setFriends: any,
  setActiveFriends: any,
  prevFriends: any,
  prevActiveFriends: any,
  notifier: any,
  fiesta: any
) => {
  try {
    const userId = getUserId();
    const friendRef = doc(db, "users", friendId);
    const userRef = doc(db, "users", `${userId}`);
    // console.log("delete friend", friendId, userId);
    const qMe = query(collection(db, "users"), where("userId", "==", userId));
    // const qFriend = query(collection(db, "users"), where("userId", "==", friendId));

    // const docSnaps = await getDocs(q);
    // let docSnapFriends: any = [];
    // let originalFriends: any = [];
    // // onSnapshot(friendsRef, (docSnaps) => {
    // console.log("docSnaps", docSnaps);
    // if (!docSnaps.empty) {
    //   docSnaps.forEach(async (docSnap) => {
    //     docId = docSnap.id;

    //     const friends = docSnap.data()?.friends;

    // const friendDocRef = doc(db, `users/${friendId}/friends/${userId}`);
    // const userDocRef = doc(db, `users/${userId}/friends/${friendId}`);

    try {
      const userSnaps = await getDocs(qMe);
      // const friendSnaps = await getDocs(qFriend);
      const friendDoc = await getDoc(friendRef);
      // const userDoc = await getDoc(userRef);
      // const userDocs = await getDocs(q);
      // console.log({ friendDoc }, friendDoc?.exists(), friendDoc?.data());
      if (!userSnaps?.empty && friendDoc?.exists()) {
        // if (!userSnaps?.empty && !friendDoc?.exists()) {
        // const friendData = friendSnaps.docs.map((doc) => doc.data());
        const userData = userSnaps.docs[0].data();
        const userDataRef = userSnaps.docs[0].ref;
        // console.log({ userSnaps, userData }, userSnaps?.docs[0].data());
        const friendData = friendDoc.data();
        // const userData = userDoc.data();
        // console.log({ friendData, userData });
        // console.log("delete friend", friendId, userData?.id, friendData?.id);

        const updatedUserData = userData?.friends?.filter(
          (friend: any) => friend.id !== friendId
        );
        const updatedFriendData = friendData?.friends?.filter(
          (friend: any) => friend.id !== userSnaps.docs[0]?.id
        );
        // console.log({  updatedUserData });
        // console.log({ updatedFriendData, updatedUserData });

        await updateDoc(friendDoc?.ref, { friends: updatedFriendData });
        await updateDoc(userDataRef, { friends: updatedUserData });

        console.log("Friend deleted successfully!");
        fiesta("Friend deleted successfully!");
      } else {
        console.error("Friend document does not exist.");
        notifier("Failed to delete friend.");
      }
    } catch (error) {
      console.error(error);
      notifier("Failed to delete friend.");
    }

    fiesta("Friend deleted successfully!");
  } catch (error) {
    console.error(error);
    notifier("Failed to delete friend.");
  }
};

// export const activateDriverOld = async (
//   toggle: boolean,
//   friendId: string,
//   setFriends: any,
//   activeFriends: any,
//   setActiveFriends: any,
//   notifier: any,
//   fiesta: any
// ) => {
//   // const userRef = doc(db, "users", `${getUserId()}`);

//   // const cUserRef = collection(db, 'users');
//   const collectionRef = collection(db, "users");
//   const q = query(collectionRef, where("userId", "==", getUserId()));
//   let friendMessage: string;
//   const qFriend = query(
//     collectionRef,
//     where(firebase.firestore.FieldPath.documentId(), "==", friendId)
//   );

//   console.log("*** --- ***", friendId, toggle, "*** --- ***");

//   let docId = "";

//   let friendUserName: string;
//   // const docSnaps =  await getDocs(q);

//   // const friendsRef = collection(db, 'users', `${friendId}`, 'friends');
//   // onSnapshot(q, async (docSnaps) => {
//   const docSnaps = await getDocs(q);
//   let docSnapFriends: any = [];
//   let originalFriends: any = [];
//   // onSnapshot(friendsRef, (docSnaps) => {
//   console.log("docSnaps", docSnaps);
//   if (!docSnaps.empty) {
//     docSnaps.forEach(async (docSnap) => {
//       docId = docSnap.id;

//       const friends = docSnap.data()?.friends;
//       console.log("activate friends", { friends });
//       if (friends?.length > 0) {
//         const activeFriends = friends
//           ?.map(async (friend: any) => {
//             console.log(
//               toggle,
//               docId,
//               { friend },
//               friend?.id,
//               friendId,
//               docSnap.id
//             );

//             if (
//               friend?.id &&
//               friend?.accepted &&
//               !friend?.blocked &&
//               !friend?.pending
//             ) {
//               // const friendId = friend?.id;
//               // const friendQ = query(collectionRef,
//               //   where(firebase.firestore.FieldPath.documentId(), '==', friendId),
//               //   // #where driveractivemode is true
//               //   where( "driverMode", "==", true)
//               // )

//               // const checkFriends = await getDocs(friendQ);
//               // const checkFriends = doc
//               // console.log({checkFriends}, checkFriends?.empty)

//               // checkFriends?.forEach(async (snapShot) => {
//               console.log({ friend });
//               // if (snapShot.empty ) {
//               //   // docSnapFriends = [];
//               //   // setResetDriverMarker('hide')
//               //   return;
//               // }
//               // console.log({friendQ})

//               // console.log('current active friend', doc.data())
//               friendUserName = friend?.userName;

//               // await docSnapFriends.push({
//               //   id: friendId,
//               //   resetDriverMarker: friend?.driverMode ? 'show' : 'hide',
//               //   approved: !toggle,
//               //   friendUserName: friendUserName,
//               //   lng: friend?.location?.lng,
//               //   lat: friend?.location?.lat,
//               // })
//               // })

//               // friend['']
//               // console.log('*** --- ***', {docSnapFriends})
//               // console.log('*** activating driver',  docSnapFriends === activeFriends)
//               // if ( docSnapFriends === activeFriends) return;

//               console.log("active snap friends are", docSnapFriends);
//               // await setActiveFriends(docSnapFriends)
//               // return friend;
//               // if (driverMode) {
//               // }

//               if (friend?.id === friendId) {
//                 await originalFriends.push({ friend });
//                 friendMessage = !toggle
//                   ? `${friend?.userName} Approved as Driver:)`
//                   : `${friend?.userName} Unapproved as Driver`;

//                 !toggle
//                   ? (friend["approved"] = true)
//                   : (friend["approved"] = false);
//                 await docSnapFriends.push({ friend });

//                 // await updateDoc(doc(db, 'users', docId), {friends: activeFriends});
//                 // await setFriends(activeFriends);
//                 // fiesta(friendMessage);

//                 return friend;
//               } else {
//                 await docSnapFriends.push({ friend });
//                 return null;
//               }
//             }

//             console.log(
//               "*** --- *** -- Ids match",
//               friend?.id,
//               friend?.userName,
//               friendId,
//               docSnap.id
//             );
//             return null;
//           }).filter(Boolean);

//         console.log({ docSnapFriends, friends });

//         Promise.all(activeFriends).then(async (activeFriends) => {
//           if (originalFriends === docSnapFriends) return;

//           if (friends?.length !== docSnapFriends?.length) return;

//           console.log(
//             "activeFriends",
//             activeFriends
//           );
//           console.log("!##### --- activeFriends", activeFriends);
//           if (
//             docSnapFriends &&
//             docSnapFriends.length > 0
//           ) {
//             // if (docSnapFriends === originalFriends) return;
//             await updateDoc(doc(db, "users", docId), {
//               friends: docSnapFriends,
//             });
//             if (activeFriends === originalFriends) return;
//             // check if active friends objects same as the original friends
//             // const dedupedActiveFriends = activeFriends?.filter( (ele, ind) => ind === activeFriends?.findIndex( elem => elem?.jobid === ele?.jobid && elem?.id === ele?.id))
//             await setFriends(activeFriends);
//             fiesta(friendMessage);
//           }
//         });
//       }
//     });
//   }
//   // });
// };

export const acceptFriend = async (
  friendId: string,
  setPendingFriends: any,
  setActiveFriendPanel: any,
  setFriends: any,
  notifier: any,
  fiesta: any
) => {
  // const userRef = doc(db, "users", `${getUserId()}`);

  const cUserRef = collection(db, "users");
  const userRef = doc(cUserRef, `${getUserId()}`);
  const friendRef = doc(cUserRef, friendId);

  const collectionRef = collection(db, "users");
  const q = query(collectionRef, where("userId", "==", getUserId()));
  const qFriend = query(
    collectionRef,
    where(firebase.firestore.FieldPath.documentId(), "==", friendId)
  );

  // const docSnap = await getDocs(q);
  let docId = "";

  const docSnaps = await getDocs(q);

  // const friendsRef = collection(collectionRef, `${friendId}`, 'friends');

  // onSnapshot(friendsRef, (docSnaps) => {
  // console.log("docSnaps accept", docSnaps);

  if (!docSnaps.empty) {
    docSnaps.forEach(async (docSnap) => {
      docId = docSnap.id;
      // console.log("Document data:", docSnap.data());
      const friends = docSnap.data()?.friends;
      if (friends) {
        const newPendingFriends: any[] = [];

        const newFriends = friends
          ?.map((friend: any) => {
            // console.log(docId, friendId, friend?.id, docSnap.id);
            if (friend?.id === friendId) {
              friend["accepted"] = true;
              friend["pending"] = false;
              return friend;
            } else {
              // if (!!friend) {
              // friend["accepted"] = false;
              // friend["pending"] = true;
              newPendingFriends.push(friend);
              // }
              return friend;
            }
          })
          .filter(Boolean);

        // console.log("newFriends", newFriends);
        // await updateDoc(userRef, {friends: newFriends});
        if (newFriends && newFriends.length > 0) {
          await updateDoc(doc(db, "users", docId), { friends: newFriends });
          // await setFriends(newFriends);
          if (newPendingFriends && newPendingFriends.length > 0) {
            await setPendingFriends(newPendingFriends);
          }
        }
      }

      return docSnap.data();
    });
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    // return;
    notifier("Could not update user!");
  }
  // });

  const docSnapsFriend = await getDocs(qFriend);

  if (!docSnapsFriend.empty) {
    docSnapsFriend.forEach(async (docSnapFriend) => {
      // const docSnapFriend = await getDoc(friendRef);
      if (docSnapFriend.exists()) {
        // console.log("Document data:", docSnapFriend.data());
        const friends = docSnapFriend.data()?.friends;
        if (friends) {
          const newFriends = friends.map((friend: any) => {
            if (friend?.id === docId) {
              friend["accepted"] = true;
              friend["pending"] = false;
            }
            return friend;
          });

          await updateDoc(doc(db, "users", friendId), { friends: newFriends });
          // await updateDoc(friendRef, {friends: newFriends});
          fiesta("Friend Accepted :)");
          setActiveFriendPanel(2);
        }
        return docSnapFriend.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No matching friend!");
        notifier("Friend not found!");
        // return;
      }
    });
  } else {
    // doc.data() will be undefined in this case
    console.log("No pending request!");
    notifier("No Pending Requests!");
    // return;
  }

  // {id: snap.id, accepted: false, blocked: false, pending: true}
  // #update accepted state for friend
  // const q = query(friendRef,
  //   where("id", "==", friendId),
  //   where("accepted", "==", false),

  // );

  // await updateDoc(friendRef, {
  //   "accepted": 13,
  // });

  // // #update accepted state for self
  // const q = query(collectionRef,
  //   where("id", "==", userId),
  //   where("accepted", "==", false),
  //   where("blocked", "==", false),
  //   where("pending", "==", true),

  // );
  // await updateDoc(doc(db, 'users', friendId), {
  //   friends:app.firestore.FieldValue.arrayUnion(friendId),
  // });

  // await updateDoc(userRef, {
  //   friends: app.firestore.FieldValue.arrayUnion(friendId),
  // });
  // await updateDoc(friendRef, {
  //   friends: app.firestore.FieldValue.arrayUnion(userId),
  // });
};

export const getUserName = async (
  userId: string,
  setUserName: any,
  setFriends: any,
  setPendingFriends: any,
  notifier?: any
) => {
  // console.log("getting username", userId);
  if (!userId) return;
  // const userRef = doc(db, "users", userId);
  console.log("passed ref");
  let username: any;
  let friends = [];

  // const friendRef = doc(db, "users", userId);
  await getFriends(setFriends, setPendingFriends);

  const collectionRef = collection(db, "users");

  // const qFriends = query(collectionRef,
  //   where("userId", "==", userId),
  // )
  const q = query(collectionRef, where("userId", "==", getUserId()));

  await getDocs(q)
    .then(async (querySnapshot) => {
      let friendList: any = [];
      let friendPendingList: any = [];
      if (querySnapshot.empty) return;
      // console.log("getdocs", querySnapshot.docs, getUserId());
      querySnapshot.forEach(async (doc) => {
        // console.log(doc.id, " => ", doc.data());
        username = doc.data().userName;
        friends = doc.data().friends;
        // console.log(doc.data().friends);

        await setUserName(username);
        // console.log("username is", username);

        //  friends?.forEach(async (friend: any) => {
        //     console.log({friend})
        //     // if(friend?.id === userId) {
        //     //   notifier('Already friends')
        //     //   return;
        //     // }
        //     // if(friend === userId) {
        //     // }
        //     const q = query(collectionRef,
        //       where(firebase.firestore.FieldPath.documentId(), '==', friend?.id)
        //     )

        //     getDocs(q).then((qSnaps) => {
        //       qSnaps.forEach((doc) => {
        //         if(doc.exists()) {
        //       console.log({doc}, doc?.data())
        //       const friendData = doc?.data();
        //       friendData['accepted'] = friend?.accepted;
        //       friendData['friendId'] = friend?.id;
        //       friend?.pending ?  friendPendingList?.push(friendData) : friendList?.push(friendData);

        //         }
        //     })

        //   })})

        //   await setFriends(friendList)
        //   await setPendingFriends(friendPendingList)
      });
      // });
      return username;
    })
    .catch((error) => {
      console.log("Error getting document:", error);
      console.log("No such document!");
      return;
    });

  // const getSnap = async () => {
  // const cUserRef = collection(db, "users");
  // const docRef = doc(db, 'users',  `${userId}`);
  // console.log('getting snap')

  // getDoc(q).then(async (docSnap) => {
  //   console.log('geting doc')
  //   if (docSnap.exists()) {
  //     username = docSnap.data().displayName;
  //     if(!username) return
  //     console.log('username is', username);
  //   } else {
  //     console.log('No such document!');
  //   }
  // }).catch((error) => {
  //   console.log('Error getting document:', error);
  //   console.log("No such document!");
  // })

  // }

  // getSnap()
  // console.log({username})
};

export const getDriverMode = async (setDriverMode: any, notifier?: any) => {
  const userId = getUserId();
  const cUserRef = collection(db, "users");

  const q = query(cUserRef, where("userId", "==", userId));

  const userSnaps = await getDocs(q);

  userSnaps?.forEach(async (docSnap) => {
    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      const driverMode = docSnap.data()?.driverMode;
      if (driverMode) {
        setDriverMode(true);
      } else {
        setDriverMode(false);
      }
    }
  });
};

/**
 * Writes the active ride request to the current user's document in Firestore.
 * @param location The location of the ride request.
 * @param dropLocation The drop location of the ride request.
 * @param userData The current user's data.
 * @param setUserData A function to update the current user's data.
 * @param notifier A function to display notifications.
 * @param fiesta A function to display success messages.
 * @param setTriggerShow A function to update the trigger show state.
 */
export const writeToActiveRideRequest = async (
  location: any,
  dropLocation: any,
  userData: any,
  setUserData: any,
  freeRideToggle: any,
  passengerCount: any,
  rideEstimate: any,
  rideDistanceEstimate: any,
  notifier: any,
  fiesta: any,
  setTriggerShow: any,
  setLoading: any,
  setMessageToken: any,
) => {
  const userId = getUserId();
  if (!userId) return;
  // console.log("writing to active ride request", {
  //   location,
  //   dropLocation,
  //   userData,
  //   setUserData,
  //   passengerCount,
  //   freeRideToggle,
  //   rideEstimate,
  //   rideDistanceEstimate,
  // });

  const collectionRef = collection(db, "users");
  // const userRef = doc(collection(db, "users"), `${getUserId()}}`);
  const qMe = query(collectionRef, where("userId", "==", userId));
  const userSnaps = await getDocs(qMe);

  const rideDistanceEstimateData = {
    duration: rideDistanceEstimate?.duration,
    distance: rideDistanceEstimate?.distance,
  };

  Sendrequest(setMessageToken);

  userSnaps?.forEach(async (docSnap) => {
    // console.log({ docSnap });
    try {
      await updateDoc(docSnap.ref, {
        currentRideRequest: {
          location,
          dropLocation,
          passengerCount,
          rideType: freeRideToggle,
          rideEstimate,
          rideDistanceEstimate: rideDistanceEstimateData || {},
          status: "pending",
          timestamp: new Date(),
        },
      });

      await updateDoc(docSnap.ref, {
        pastRideRequests: app.firestore.FieldValue.arrayUnion({
          location,
          rideType: freeRideToggle,
          rideEstimate,
          rideDistanceEstimate: rideDistanceEstimateData || {},
          passengerCount,
          dropLocation,
          timestamp: new Date(),
        }),
      });
      setLoading(false);
      fiesta("Ride request updated successfully!");
    } catch (error) {
      console.error("Error updating ride request: ", error);
      setLoading(false);
      notifier("Error updating ride request. Please try again later.");
    }
  });
  await setLoading(false);
  await setTriggerShow(true);
};

export const activateDriverMode = async (
  driverMode: any,
  setDriverMode: any,
  setResetDriverMarker: any,
  notifier: any,
  fiesta: any,
  triggerLocationGrab: any,
  setMessageToken: any,
) => {
  const userId = getUserId();
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("activating driver mode", { driverMode });
  // const cUserRef = collection(db, 'users');
  // if (!driverMode) {
  //   triggerLocationGrab(true);
  // }

  // const q = query(cUserRef, where("userId", "==", userId));
  Sendrequest(setMessageToken);
  const collectionRef = collection(db, "users");
  const qMe = query(collectionRef, where("userId", "==", userId));

  const userSnaps = await getDocs(qMe);

  userSnaps?.forEach(async (docSnap) => {
    if (docSnap.exists()) {
      // console.log("!!!", { driverMode });
      // console.log("Document data:", docSnap.data());
      // const driverMode = docSnap.data()?.driverMode;
      if (!driverMode) {
        await updateDoc(docSnap.ref, {
          driverMode: false,
        });
        // await setResetDriverMarker('hide')
        await setDriverMode(false);
        fiesta("Driver Mode Deactivated");
      } else {
        await updateDoc(docSnap.ref, {
          driverMode: true,
        });
        // await setResetDriverMarker('show')
        await setDriverMode(true);
        fiesta("Driver Mode Activated");
      }
    } else {
      // doc.data() will be undefined in this case
      // await setResetDriverMarker('hide')
      console.log("No such document!");
      notifier("Could not update user!");
      // return;
    }
  });
};

export const getDistance = (start: any, end: any) => {
  // #use googlemaps to calculate distance
  // const distance = window?.google?.maps.geometry.spherical.computeDistanceBetween(start, end);
  // return distance;
};

export const activeFriendsOnSnapshot = async (
  activeFriends: any,
  setActiveFriends: any,
  resetDriverMarker: any,
  setResetDriverMarker: any,
  driverMode: any,
  notifier: any,
  fieasta: any
) => {
  // # get the current users friend list and scroll through them to see which ones have driverMode on
  const userId = getUserId();

  // console.log("getting reset", { resetDriverMarker });
  if (!userId) return;

  const cUserRef = collection(db, "users");
  const userRef = doc(cUserRef, userId);

  const q = query(cUserRef, where("userId", "==", userId));

  const userSnaps = await getDocs(q);
  let docSnapsFriends: any = [];

  userSnaps?.forEach(async (userSnap) => {
    let myid: string;
    let friendId: string;
    let friendUserName: string;
    // const userSnap = await getDoc(userRef);
    const collectionRef = collection(db, "users");
    const userSnapData = userSnap?.data();

    if (!userSnapData?.driverMode || !userSnapData?.friendRideRequest) return;

    if (userSnap.exists()) {
      const friends = userSnap.data()?.friends;
      if (friends && friends.length > 0) {
        // console.log("my friends are", friends);
        friends?.forEach(async (friend: any) => {
          if (
            friend?.accepted &&
            friend?.approved &&
            !friend?.blocked &&
            !friend?.pending
          ) {
            const friendId = friend?.id;
            const friendQ = query(
              collectionRef,
              where(firebase.firestore.FieldPath.documentId(), "==", friendId),
              where("driverMode", "==", true)
            );

            onSnapshot(friendQ, async (snapShot) => {
              // console.log("getting active on snap reset", resetDriverMarker);
              // docSnapsFriends = [];
              // console.log({snapShot})
              // if (snapShot.empty ) {
              //   // docSnapsFriends = [];
              //   // setResetDriverMarker('hide')
              //   return;
              // }

              if (snapShot.empty) {
                // console.log("no docs", friend?.userName);
                // console.log({ docSnapsFriends }, docSnapsFriends?.length);
                // #filter out the current friend from the active friends list
                const filteredFriends = docSnapsFriends?.filter(
                  (f: any) => f?.userId !== friend?.userId
                );
                await setActiveFriends(filteredFriends);
                // await setResetDriverMarker('hide');
                return;
              }
              // console.log({ snapShot });
              snapShot.forEach(async (doc) => {
                // console.log("current active friend", doc.data());
                friendUserName = doc.data().userName;

                // #get doc based on friend id
                const qFriend = query(
                  cUserRef,
                  where("userId", "==", doc.data().userId)
                );
                const friendSnap = await getDocs(qFriend);

                friendSnap?.forEach(async (friendSnap) => {
                  if (friendSnap.exists()) {
                    // console.log("friend snap", friendSnap.data());

                    const curFriend = friendSnap.data();

                    // console.log(doc.data().driverMode)
                    // Check if friends are withing X miles using lat lng
                    // getDistance(
                    //   doc.data().location,
                    //   userSnap.data().location
                    // );
                    if (
                      !curFriend?.location?.lng ||
                      !curFriend?.location?.lat ||
                      !userSnap.data()?.currentRideRequest?.location
                    ) {
                      console.log("no location");
                      return;
                    }
                    // console.log("onSnap", userSnap.data());
                    let distanceValue;
                    const distance = await useDirectionsMap({
                      startLocation:
                        userSnap.data()?.currentRideRequest?.location,
                      destination: {
                        lng: curFriend?.location?.lng,
                        lat: curFriend?.location?.lat,
                      },
                    }).then((res: any) => {
                      // console.log({ res });
                      const driverDistance = res.text; //&& Number(res).toFixed(2);
                      distanceValue = res?.value;
                      // console.log('suup',{friend, driverDistance});
                      const driverRebuild = {
                        ...friend,
                        distanceFromStart: driverDistance,
                      };
                      //  console.log({driverRebuild})
                      // return  driverRebuild;
                      return driverDistance;
                      // return driverRebuild;
                    });

                    // console.log("#driverDistance", { distanceValue });
                    // console.log({ distance });

                    if (distanceValue !== undefined && distanceValue < 666) {
                      console.log("close friend");
                      if (
                        !distanceValue ||
                        distanceValue === undefined ||
                        distanceValue === "NaN"
                      ) {
                        console.log("distance failed returning");
                        return;
                      } else {
                        console.log("distance passed");

                        const friendObj = {
                          id: friendId,
                          resetDriverMarker: doc.data()?.driverMode
                            ? "show"
                            : "hide",
                          approved: friend?.approved,
                          friendUserName: friend?.userName,
                          lng: doc.data()?.location?.lng,
                          lat: doc.data()?.location?.lat,
                          distanceFromStart: distance,
                        };

                        if (
                          !docSnapsFriends.some((f: any) => f.id === friendId)
                        ) {
                          // driverRebuild = { ...friendObj, distanceFromStart: driverDistance };
                          await docSnapsFriends.push(friendObj);
                          return friendObj;
                        }

                        // await docSnapsFriends.push(friendObj);
                        // console.log({ docSnapsFriends });
                      }
                    }
                    if (distanceValue !== undefined && distanceValue > 666) {
                      console.log("far friend");
                    }
                  }
                });
              });

              Promise.allSettled(docSnapsFriends)?.then(async (res) => {
                // console.log(
                //   "!*!*",
                //   { docSnapsFriends },
                //   res,
                //   docSnapsFriends?.length
                // );
                // #check if docSnapsFriends is empty

                await setActiveFriends([]);
                docSnapsFriends?.length && console.log("active longer than 1");
                // #loop through docsnap friend and increment for each Children
                //
                let docLengthArray: any = [];
                docSnapsFriends.map((docSnap: any) => {
                  // console.log("snapshot", docSnap);
                  docLengthArray.push(docSnap);
                  return "check";
                });

                // console.log("docLengthArray", docLengthArray?.length);
                // const docLength = docSnapsFriends.forEach()
                if (!docLengthArray?.length && docLengthArray?.length === 0) {
                  // console.log(
                  //   "no docs",
                  //   docSnapsFriends,
                  //   docSnapsFriends?.length
                  // );
                  await setActiveFriends([]);
                  // await setResetDriverMarker('hide');
                  return;
                } else {
                  // console.log("active snap friends are", docSnapsFriends);
                  // #getcurrent user collection by getUserId
                  // const userRef = doc(cUserRef, userId);
                  // const userDoc = (await getDoc(userRef))?.data();
                  // const userSnap = (await getDoc(userRef))?.data();

                  await docSnapsFriends?.forEach(async (friend: any) => {
                    const docFriendRef = doc(db, "users", friend.id);

                    const docFriendSnap = await getDoc(docFriendRef);
                    if (!docFriendSnap.exists()) return;
                    // #check friendRideRequests to see if friend.id
                    const friendRideRequests =
                      docFriendSnap?.data()?.friendRideRequests;
                    // console.log({ friendRideRequests });
                    const checkFriendRideRequests = friendRideRequests?.filter(
                      (friendRideRequest: any) =>
                        friendRideRequest?.id === friend?.id
                    );
                    // console.log(
                    //   "*checkFriend",
                    //   checkFriendRideRequests?.length,
                    //   { checkFriendRideRequests }
                    // );
                    if (
                      checkFriendRideRequests?.length === 0 &&
                      friend?.id !== userId &&
                      docFriendSnap?.ref
                    ) {
                      // console.log(
                      //   "no friend ride requests",
                      //   docFriendSnap?.ref
                      // );
                      // console.log("photo", userSnapData?.photoURL);

                      const friendObj = {
                        id: userSnapData?.userId,
                        userName: userSnapData?.userName,
                        time: new Date(),
                        passengerCount:
                          userSnap.data()?.currentRideRequest?.passengerCount,
                        rideType: userSnap.data()?.currentRideRequest?.rideType,
                        distanceFromPickup: friend?.distanceFromPickup,
                        rideEstimate:
                          userSnap.data()?.currentRideRequest?.rideEstimate,
                        rideDistanceEstimate:
                          userSnap.data()?.currentRideRequest
                            ?.rideDistanceEstimate?.distance?.text,
                        rideTimeEstimate:
                          userSnap.data()?.currentRideRequest
                            ?.rideDistanceEstimate?.duration?.text,
                        active: true,
                        photoURL: userSnapData?.photoURL || null,
                        rideAccepted: false,
                        expired: false,
                        distance: friend?.distanceFromStart,
                      };
                      if (userSnapData?.photoURL) {
                        friendObj.photoURL = userSnapData.photoURL;
                      }

                      await updateDoc(docFriendSnap?.ref, {
                        friendRideRequests: [
                          ...(friendRideRequests || []),
                          {
                            ...friendObj,
                          },
                        ],
                      });
                    }
                  });

                  await setResetDriverMarker("show");
                  await setActiveFriends(docSnapsFriends);
                  // if (driverMode) {
                  // }
                }
                //  await setActiveFriends(docSnapsFriends);

                // setResetDriverMarker("show");
              });
            });
          }
        });

        console.log("trying to update");

        // await Promise.allSettled(friendLoop)?.then(async (res) => {
        //   console.log('res checkFriends active', docSnapsFriends, res)
        //   docSnapsFriends?.length && console.log('active longer than 1')
        //   if (!docSnapsFriends?.length && docSnapsFriends?.length === 0) {
        //     console.log('no docs')
        //     await setActiveFriends([]);
        //     // await setResetDriverMarker('hide');
        //     return;
        //   } else {
        //     console.log('active snap friends are', docSnapsFriends)
        //     // #getcurrent user collection by getUserId
        //     const userRef = doc(cUserRef, userId);
        //     const userSnap = (await getDoc(userRef))?.data();

        //     await docSnapsFriends?.forEach(async(friend: any) => {

        //       const docFriendRef = doc(db, "users", friend.id);

        //       const docFriendSnap = await getDoc(docFriendRef);
        //       // #check friendRideRequests to see if friend.id
        //       const friendRideRequests = docFriendSnap?.data()?.friendRideRequests;
        //       console.log({friendRideRequests})
        //       const checkFriendRideRequests = friendRideRequests?.filter((friendRideRequest: any) => friendRideRequest?.id === friend?.id);
        //       console.log({checkFriendRideRequests})
        //       if (checkFriendRideRequests?.length === 0 && friend?.id !== userId) {
        //         console.log('no friend ride requests')
        //         await updateDoc(docFriendSnap.ref, {
        //           friendRideRequests: [
        //             ...(friendRideRequests || []),
        //             {
        //               id: userSnap?.userId,
        //               userName: userSnap?.userName,
        //               time: new Date(),
        //               active: true,
        //               rideAccepted: false,
        //               expired: false,
        //               distance: friend?.distanceFromStart,
        //             },
        //           ],
        //         });
        //       }

        //     });

        //     await setActiveFriends(docSnapsFriends);
        //     // if (driverMode) {
        //     await setResetDriverMarker("show");
        //     // }
        //   }
        //   // docSnapsFriends?.length && await setActiveFriends(docSnapsFriends);

        //   // setResetDriverMarker("show");
        // });

        // console.log({docSnapsFriends})
        // console.log("*** user snap updatings", docSnapsFriends === activeFriends);
        // console.log("*** user snap updatings", docSnapsFriends, activeFriends);
        //       if (docSnapsFriends === activeFriends) return;

        //       if (docSnapsFriends.length === 0) {
        //         console.log("no docs");
        //         await setActiveFriends([]);
        //         // await setResetDriverMarker("hide");
        //       } else {
        //         console.log("active snap friends are", docSnapsFriends);
        //         await setActiveFriends(docSnapsFriends);
        //         // if (driverMode) {
        //         await setResetDriverMarker("show");
        //         // }
        //       }

        // if (docSnapsFriends.length === 0) {
        //   console.log('no docs')
        //   // setResetDriverMarker('hide')
        // }
        // console.log('active friends are', docSnapsFriends)
        //   setActiveFriends(docSnapsFriends)
      }
    }
  });
};

// #function to encode a and decode a longer string to a short shareable one
export const encode = (str: string) => {
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/\=+$/, "");
};
export const decode = (str: string) => {
  return atob(str.replace(/-/g, "+").replace(/_/g, "/"));
};

export async function createUsername(username: string) {
  const userRef = doc(collection(db, "usernames"), `${getUserId()}}`);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    return false;
  } else {
    await setDoc(userRef, { username: username });
    return true;
  }
}

export async function checkUsername(username: string) {
  const userRef = doc(db, "usernames", username);
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    return true;
  } else {
    return false;
  }
}
