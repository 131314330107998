import React, { useCallback, useState, useEffect, useRef } from 'react';

import styled from '@emotion/styled';

const ClosePanelDiv = styled.div`
position: absolute; 
right: 0.5em;
cursor: pointer;

`;

export const ClosePanel = (setState) => { 
  return(
  <ClosePanelDiv onClick={ () => setState(false)}>
    <img src="/svgs/close-btn.svg" alt=""/>
  </ClosePanelDiv>
  )
}