import React, { useState, useEffect } from 'react';
import { NotificationContainer } from '../../styles';
import { clear } from 'console';
interface NotificationProps {
  alert: boolean;
  success: boolean;
  triggerShow: boolean;
  setTriggerShow: any;
  setFiestaMessage: any;
  setNotification: any; // Added setNotification to the interface
  children: any;
}

const NotificationWrapper: React.FC<NotificationProps> = ({ setFiestaMessage, setNotification, alert, triggerShow, children, success, setTriggerShow }) => {
  const [show, setShow] = useState(true);

  // useEffect(() => {
  //   // const unsubscribe = () => {
  //   console.log('triggerShow unsub', triggerShow)
  //   console.log('**** NotificationWrapper useEffect', {triggerShow, show, alert, success})
  //   // setShow(false);
  //   if (triggerShow) {
  //     setShow(true);
  //     console.log('triggerShow', triggerShow, {show})
  //     const timeoutId = setTimeout(() => {
  //       setFiestaMessage('');
  //       setNotification('');
  //       setShow(false);
  //       console.log('triggerShow timeout', triggerShow, {show})
  //       setTriggerShow(false);
  //     }, 2000);
  
  //     // Return a cleanup function
  //     return () => clearTimeout(timeoutId);
  //   } else {
  //     console.log('triggerShow else', triggerShow, {show})
  //     setShow(false);
  //     setTriggerShow(false);
  //   }
  // // }
    
  // // return unsubscribe();
  // }, [triggerShow]);

  useEffect(() => {
    // console.log('triggerShow unsub', triggerShow)
    if (triggerShow) {
      setShow(true);
      const timeoutId = setTimeout(() => {
        setShow(false);
        // console.log('triggerShow timeout', triggerShow, {show}) 
        setFiestaMessage('');
        setNotification('');
        setTriggerShow(false);
      }, 4000);
  
      // Return a cleanup function
      return () => clearTimeout(timeoutId);
    } 
  }, [ triggerShow ]);

  // console.log('**** NotificationWrapper useEffect', { show, alert, success})
  return (
    <>
      {show && (
        <NotificationContainer alert={alert} success={success}>
        {React.Children.map(children, (child) => {
          return <div>{child}</div>;
        })}
      </NotificationContainer>
      )}
    </>
  );
};




export default NotificationWrapper;

