import React, {  } from 'react';
// import { useLocation } from 'react-router-dom';
// import { withRouter } from '../utils/withRouter';
// import { AuthContext } from '../Auth';
// import { httpsCallable } from "@firebase/functions";
// import {
//   kinDb,
//   appFirebase,
//   providers,
//   db,
//   kindAuth,
//   messaging,
//   cloudFunctions,
// } from "../firebase-config";
import styled from '@emotion/styled';
import * as ui from '../../../../styles/uiVars';
import TopScroller from './TopScroller';
import HowItWorks from './HowItWorks';

import {
  LinkBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  LoadingContainer,
  LoadingSpinner,
  FullWidthContainer,
} from "../../../../styles/shared";

export const HomeTopContainer = styled.div`
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background: black; */
  width:100%;
  max-width: 100vw;
  overflow: hidden;
  background: white;
  /* background: url('/images/dropkins/ui/bg/content_bg_001.jpg') no-repeat; */
  /* background: linear-gradient(180deg, #2E2E75 0%, #89E8E2 53%, #8997E8 95.5%); */
  /* background: linear-gradient(180deg, #151548 0%, #4dd9d0 53%, #2b339b 95.5%); */
  
  /* background-size: cover; */
  /* margin-top: 2em; */

  padding: 3em 0;
  padding-bottom: 0em;

  display: flex;
/* height: 355px; */
/* padding: 60px 128px; */
  flex-direction: column;
/* align-items: flex-start; */
/* gap: 10px; */
/* flex-shrink: 0; */
  @media screen and (max-width: ${ui.tablet}) {
    padding: 0.51em;
  }
`;

export const HomeCTA = styled.div`
  display: flex; 
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.61em 3em;
`;


export const Headline = styled.div`
/* color: #FFF;
font-style: normal;


font-weight: 400;
line-height: 64px; */
/* max-width: 80vw; */

max-width: 800px;
justify-self: center;
font-size: 2.4em;
font-family: 'Inter', sans-serif;
color: #FFF;
color: #2E2E75;
/* font-family: Inter; */
/* font-size: 56px; */
padding: 0 5vw;
font-style: normal;
font-weight: 600;
line-height: 64px; /* 114.286% */
font-size: 2.5em;
    line-height: 48px;
    text-align: left;


@media screen and (max-width: ${ui.tablet}) {
  font-size: 1.71em;
  line-height: 48px;
  padding: 0.5em;
  
}
`;
const DropkinsVideo= styled.div`
  /* Add your styling for the banner container here */
  /* height: 60vh; */
  display: flex;
  flex-basis: 100%;
  align-self: flex-start;
  /* top: 30px; */
  width:100%;
  /* min-width: 46vw; */
  min-height: 47vh;
  position: relative;
  border-radius: 25%;
  align-self: center;
  margin-bottom: 3em;

  .player {
    border-radius: 20px;
  }

  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  @media screen and (max-width: ${ui.tablet}) {
    top: 0px;
    height: 60vh;
    min-width: 86vw;
  height: 36vh;
    
  }

  iframe {
    min-width: 100%;
  }

  

`;
export const HomeTop = ({goToSignUp}) => {
  return (
    <HomeTopContainer>
      <Headline>
      🚗 🌍 A Community Driven platform. 🌟
      </Headline>
      {/* <h1>Welcome to HomeTop</h1> */}
      <TopScroller />
      <DropkinsVideo ><iframe src="https://player.vimeo.com/video/909913499?badge=0&autopause=0&portrait=0&byline=0&player_id=0&app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" style={{position:'relative',top:"0",left:0,width:"100%",height:"100%"}} title="Dropkins - Introduction"></iframe></DropkinsVideo>
      <HowItWorks goToSignUp={goToSignUp} />

      <FullWidthContainer>

      
      {/* <HomeCTA>
        <LinkBtn onClick={goToSignUp}> Get Started! </LinkBtn>

      </HomeCTA> */}
      </FullWidthContainer>
      
    </HomeTopContainer>
  );
};

export default HomeTop;
