import React, {useContext} from 'react';
import moment from 'moment';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';

import 'firebase/compat/auth';
import 'firebase/firestore';
import { kinDb, appFirebase, providers, db, kindAuth } from '../firebase-config';
import userEvent from '@testing-library/user-event';
import { collection, doc, getDocs, onSnapshot, query, where, addDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getRedirectResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updatePassword } from 'firebase/auth';

import { getUserId } from '../firebase';
import { snap } from 'gsap';
import { Marker } from 'react-google-maps';





// Write Markers


export const writeMarker =  (address:string, longitude:any, latitude:any, covid:boolean, type:string, associatedAddress?:string) => {
  // console.log('write mark', longitude, latitude)
  let collectionName = covid ? 'covidMarkers' : 'allMarkers';
  let authorizedPost = true;
  const writeToCollection = async (collectionCustom: string ) => {
   
    try {

      await addDoc(collection(kinDb, collectionCustom), {
          address,
          longitude,
          latitude,
          userId: getUserId(),
          time: Date.now(),
          type: covid ? 'covid' : '',
        });

      // appFirebase.analytics().logEvent('Added Checkin');

   
    }
    catch (e) {
      console.error("Error adding document: ", e);
    }
  
  }
  

  const writeToCustomCollection = async (collectionCustom: string, address:string, longitude:any, latitude:any, covid:boolean, type:string, associatedAddress?:string) => {
  // (collection: string, address:string, longitude:any, latitude:any, covid:boolean, type:string, associatedAddress?:string) => {
    await addDoc(collection(kinDb, collectionCustom), {
      address,
      longitude,
      latitude,
      userId: getUserId(),
      time: Date.now(),
      associatedAddress: associatedAddress || address,
      type: covid ? 'covid' : type,
    })
    .then(function(docRef: any) {
      // console.log("Document written with ID: ", docRef.id);
    })
    .catch(function(error: any) {
      console.error("Error adding document: ", error);
    });
  }
  
  let expected = [ 'warning'];
  if ( expected.includes(type) ) {
    authorizedPost = false;
    collectionName = 'warningMarkers';
    const retrieve = async (address:string, longitude:any, latitude:any, covid:boolean, type:string, associatedAddress?:string ) => {
     const refWarningMarkers = collection(kinDb, 'warningMarkers');
     
     const q = query(refWarningMarkers, where("address", "==", address ));
     await getDocs(q).then((res: { empty: any; }) => {
     
        // console.log(address);
        if(res.empty) { 
          writeToCustomCollection(collectionName, address, longitude, latitude, covid, type, associatedAddress);  
        } 
        // console.log(res.empty); 
        // res.docs.map(doc => {console.log(doc.data())}); 
        return res.empty;
       });
    // console.log(authorizedPost, query(address, longitude, latitude, covid, type))
  }
  let expected = [ 'warning-ugc'];
  if ( expected.includes(type) ) {
    // console.log('posting warning')
    authorizedPost = false;
    collectionName = 'warning-markers-ugc';
    const collectionRef = collection(kinDb, collectionName);
    const retrieve = async (address:string, longitude:any, latitude:any, covid:boolean, type:string, associatedAddress?:string ) => {
    const q = query(collectionRef, where("address", "==", address ));

    await getDocs(q).then((res: { empty: any; }) => {

        // console.log(address);
        if(res.empty) { 
          writeToCustomCollection(collectionName, address, longitude, latitude, covid, type, associatedAddress);  
        } 
        // console.log(res.empty); 
        // res.docs.map(doc => {console.log(doc.data())}); 
        return res.empty;
       });
    // console.log(authorizedPost, query(address, longitude, latitude, covid, type))
  }


  if ( authorizedPost ){
    writeToCollection(collectionName);
  }
}
  }
}





//  Query Markers




interface MarkerData {
  selfQuarantined?: any;
  showingSymptoms?: any;
  mandatoryQuarantine?: any;
  covidPositive?: any;
  id?: any;
  data?: any;
  userId?: any;
  time?: any;
  lat?: any;
  lng?: any;
  location?: any;
  name?: any;
  address?: any;
  city?: any;
  state?: any;
  zip?: any;
  country?: any;
  phone?: any;
  website?: any;
  hours?: any;
  notes?: any;
  source?: any;
  verified?: any;
  closed?: any;
  closedNotes?: any;
  open?: any;
  openNotes?: any;
  delivery?: any;
  deliveryNotes?: any
  // ...
}


export async function warningMarkersSnap ( {
  setWarningMarker
}: any) {
  // const q = query(collection(db, "warningMarkers"), where("userId", "==",  kindAuth?.currentUser?.uid));
  // await onSnapshot(q, (snapShot) => {
  //   const data: MarkerData[] = []
  //   snapShot.forEach((doc) =>  {
  //     data.push({
  //       id: doc.id,
  //       ...doc.data(),
  //     })
  //   })
  //   !!setWarningMarker && setWarningMarker(data);
  // })

}

export async function covidMarkersSnap ({
  setContagionMarker,
}: any) {

  var dateOffset = (24*60*60*1000) * 14; //5 days
    var myDate = new Date();
    //console.log(myDate.getTime())
    myDate.setTime(myDate.getTime() - dateOffset);
    //console.log(myDate.getTime())

    const q = query(collection(db, "covidMarkers"), where("time", '>=', myDate.getTime()))
    await onSnapshot(q, async (snapShot) => {
      // const data: MarkerData[] = []
      const markers = snapShot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setContagionMarker(markers);
    })
       

}


export async function setContagionMarkersSnap ({
  setContagionMarkersYours,
}: any) {

  var myDate = new Date();
  var dateOffset = (24*60*60*1000) * 14; //14 days
  myDate.setTime(myDate.getTime() - dateOffset);
  const q = query(collection(db, "covid-ugc-markers"), where("userId", "==",  kindAuth?.currentUser?.uid ));
  await onSnapshot(q, async (snapShot) => {

    const markers = snapShot.docs.filter((doc) => {
      const covidUgcMarker = doc.data();
      // console.log(Date.now() - covidUgcMarker.time)
      return (covidUgcMarker.time > myDate.getTime()) ? true : false;

    }).map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // console.log('settingContagionYours Mar', {markers})
    setContagionMarkersYours(markers);
  })

}


export async function covidMarkersSnapUGC ({
  setContagionMarkersUGC,
  tempUGCStatus,
}: any) {

  let newMarkers: any[] = [];
    var dateOffset = (24*60*60*1000) * 14; //14 days
    var myDate = new Date();
    myDate.setTime(myDate.getTime() - dateOffset);
    const q = query(collection(db, "covid-ugc-markers"), where("time", '>=', myDate.getTime()))
    await onSnapshot(q, async (snapShot) => {
  
      snapShot.docs.filter((docs) => {
        const ugc = docs.data();
        return ugc.userId === kindAuth?.currentUser?.uid ? false : true;

        
      }).map(async (doc) => {
        const markerData = doc.data();
        let status = tempUGCStatus;

        // (async () => {
          const qUgc = query(collection(db, "covid-ugc"), where("userId", "==", markerData.userId));
          const res = await getDocs(qUgc);
          // await db.collection('covid-ugc').where('userId', '==', markerData.userId).get()
          //   .then(async (res) => {
              if(!res.empty) {
                // console.log('not empty foo')
                  await res.forEach( async function (ugc) {
                  // console.log('ugc', ugc.data())
                    const data = ugc.data();
                    status = {
                      type: 'covid-ugc',
                      covidPositive: data.covidPositive,
                      showingSymptoms: data.showingSymptoms,
                    }
                });
              }
            // });
          // })();

        newMarkers.push({
          id: doc.id,
          ...doc.data(),
          status,
        })
          
        return {
              id: doc.id,
              ...doc.data(),
              status,
          };
        
      }); 
        
      // console.log({newMarkers})
      // console.log('settingContagionUGC Mar', {markers})
      setContagionMarkersUGC(newMarkers);
    })

  }


export function allMarkersSnap ( {
  setSelfMarkers,
  setLatestMarker, 
  setRecoveringMarkers,
  setMandatoryMarkers,
  setSymptomsMarkers, 
  // setRideMarkers,
  setMarkers,
  }: {
  setSelfMarkers: (markers: MarkerData[]) => void ,
  setLatestMarker: (marker: MarkerData) => void,
  setRecoveringMarkers: (markers: MarkerData[]) => void,
  setMandatoryMarkers: (markers: MarkerData[]) => void,
  setSymptomsMarkers: (markers: MarkerData[]) => void,
  setSymptomaticMarkers: (markers: MarkerData[]) => void,
  setCovidPositiveMarkers: (markers: MarkerData[]) => void,
  // setRideMarkers: (markers: MarkerData[]) => void,
  setMarkers: (markers: MarkerData[]) => void,
   
}) {
  const q = query(collection(db, "allMarkers"), where("userId", "==",  kindAuth?.currentUser?.uid ))
  return onSnapshot(q, async (snapShot) => {
    
    // console.log('noticed snap changed')
    // var d = new Date();
    // d.setDate(d.getDate()-5);
    const ugcQ = query(collection(db, 'covid-ugc'), where('userId', '==', getUserId()));
    const querySnapshotUgc = await getDocs(ugcQ);
    querySnapshotUgc.forEach((covidUgc) => {
      if (!querySnapshotUgc.empty) {

        // res.forEach(  function (covidUgc) {

          const status = covidUgc.data();
          const {selfQuarantined, showingSymptoms, mandatoryQuarantine, covidPositive} = status;
          const selected = selfQuarantined || mandatoryQuarantine || showingSymptoms || covidPositive;
          
          
          if (!selected) {
            const markers = snapShot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              selfQuarantined: status.selfQuarantined,
              showingSymptoms: status.showingSymptoms,
              mandatoryQuarantine: status.mandatoryQuarantine,
              covidPositive : status.covidPositive,
            }));

            setMarkers(markers);
            if(markers && markers.length){
              const finalMarker = markers[markers.length-1];
              setLatestMarker(finalMarker);
            }

          } else {

            
            
            const markerHash = {
              'self' : () => {
                const selfMarkers = snapShot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                  selfQuarantined: status.selfQuarantined,
                  showingSymptoms: status.showingSymptoms,
                  mandatoryQuarantine: status.mandatoryQuarantine,
                  covidPositive : status.covidPositive,
                }));
    
                setSelfMarkers(selfMarkers);
                if(selfMarkers && selfMarkers.length){
                  const finalMarker = selfMarkers[selfMarkers.length-1];
                  setLatestMarker(finalMarker);
                }

              },
              'mandatory' : () => {
                const mandatoryMarkers = snapShot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                  selfQuarantined: status.selfQuarantined,
                  showingSymptoms: status.showingSymptoms,
                  mandatoryQuarantine: status.mandatoryQuarantine,
                  covidPositive : status.covidPositive,
                }));
    
                setMandatoryMarkers(mandatoryMarkers);
                if(mandatoryMarkers && mandatoryMarkers.length){
                  const finalMarker = mandatoryMarkers[mandatoryMarkers.length-1];
                  setLatestMarker(finalMarker);
                }
              }, 
              'symptoms' : () => {
                const symptomsMarkers = snapShot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                  selfQuarantined: status.selfQuarantined,
                  showingSymptoms: status.showingSymptoms,
                  mandatoryQuarantine: status.mandatoryQuarantine,
                  covidPositive : status.covidPositive,
                }));
    
                setSymptomsMarkers(symptomsMarkers);
                if(symptomsMarkers && symptomsMarkers.length){
                  const finalMarker = symptomsMarkers[symptomsMarkers.length-1];
                  setLatestMarker(finalMarker);
                }
              }, 
              'recovering' : () => {
                const recoveringMarkers = snapShot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                  selfQuarantined: status.selfQuarantined,
                  showingSymptoms: status.showingSymptoms,
                  mandatoryQuarantine: status.mandatoryQuarantine,
                  covidPositive : status.covidPositive,
                }));
    
                setRecoveringMarkers(recoveringMarkers);
                if(setRecoveringMarkers && setRecoveringMarkers.length){
                  const finalMarker = recoveringMarkers[setRecoveringMarkers.length-1];
                  setLatestMarker(finalMarker);
                }
              }
            }

            if(selfQuarantined){ markerHash['self'](); }
            if(mandatoryQuarantine){ markerHash['mandatory'](); }
            if(showingSymptoms){ markerHash['symptoms'](); }
            if(covidPositive){ markerHash['recovering'](); }

          }
        }
          
        })
      })
    // })
  }



