import { useEffect, useState, useRef, useCallback } from "react";
import { getGeoCode } from "./useGoogleMap";
import axios from "axios";
import { getDistance } from "../storage";


export const reverseGeoCode = ({ lat, lng }) => {
  // console.clear();
  // console.log("# reverse geo",{ lat, lng });
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const res = async () => await axios.get(url).then((res) => {
    // console.log('reverse geocode', {res})
    return res.data.results[0].formatted_address
  });
  // console.log('!**', { res });
  return res();
};

export async function haversine_distance({ startLocation, destination, map, moreData = false }) {
  // console.log({startLocation, destination})
  if(startLocation && destination) {
  const mk1 = new window.google.maps.Marker({
    position: {...startLocation},
    map: map,
  });
  const mk2 = new window.google.maps.Marker({
    position: {...destination},
    map: map,
  });

  // console.log({mk1, mk2})

  const directionsService = new window.google.maps.DirectionsService()
      
  let curDistance;
  await directionsService?.route(
    {
      origin: startLocation,
      destination: destination,
      travelMode: 'DRIVING'
    },
      function(response, status) {
      // console.log('in dir serv',{ response, status });
      if (status === 'OK') {
        curDistance = response.routes[0].legs[0].distance;
        // console.log('have Directions request succeeded', { response });
        
      } else {
        console.log('Directions request failed due to ' + status);
      }
    }
  ).then((responseRoute) => {
    // console.log({responseRoute})
    // console.log('use map', {responseRoute}) 
    curDistance = moreData ? responseRoute.routes[0].legs[0] : responseRoute.routes[0].legs[0].distance;
    return moreData ? responseRoute.routes[0].legs[0] : responseRoute.routes[0].legs[0].distance;
    // return res;
  }
  ).catch((err) => {
    console.log({err})
  })
  // console.log(routeService)
  // console.log({curDistance})

  // const curDistance = getDistance(startLocation, destination).then((res) => {
  //   console.log({res})
  //   return res;
  // })
  // console.log({curDistance}, directions())
  // return curDistance;

  



  var R = 3958.8; // Radius of the Earth in miles
  var rlat1 = startLocation.lat * (Math.PI / 180); // Convert degrees to radians
  var rlat2 = destination.lat * (Math.PI / 180); // Convert degrees to radians
  var difflat = rlat2 - rlat1; // Radian difference (latitudes)
  var difflon = (destination.lng - startLocation.lng) * (Math.PI / 180); // Radian difference (longitudes)

  var d =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    );
    // console.log('haversine', {d})
  // return d;
  return curDistance;
      }
}

export const useDirectionsMap = async ({ startLocation, destination, moreData = false }) => {
  // console.log({ startLocation, destination });
  const geocoder = new window.google.maps.Geocoder();
  let driverDistance;
  // console.log({startLocation, destination})
  if (startLocation && destination) {

    if( typeof(destination) === 'string') {
      destination = await getGeoCode(destination);
      // console.log({destination})
    }
    

    let startLatLng;
    let destinationLatLng;
    

    const setStartLng = async () => {
      if( typeof(startLocation) === 'string') {
        await getGeoCode(startLocation).then((res) => {
          // console.log({res})
          startLocation = res;
          driverDistance = haversine_distance({startLocation, destination, moreData }).then((res) => {
            // console.log({res})
            // console.log({res}, 'setStartLng get geocode')
            return res;
          })
        }
        )
      //  await geocoder.geocode({ address: startLocation }, function (results, status) {
      //   console.log({ startLocation}, );
       
      //   if (status == "OK") {

      //     console.log({results}, 'setStartLng' )
      //     const latLng = {
      //       lat: results[0].geometry.location.lat(),
      //       lng: results[0].geometry.location.lng(),
      //     };
      //     // console.log(latLng);
      //     // startLatLng =  new window.google.maps.LatLng( {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()});
      //     // destinationLatLng = new window.google.maps.LatLng( {lat: destination.lat, lng: destination.lng});
      //     // console.log(startLatLng, destinationLatLng)
      //     if (latLng && destination){
      //       console.log( latLng, destination, haversine_distance({startLocation: latLng, destination }));
      //     driverDistance = haversine_distance({startLocation: latLng, destination });
      //       return driverDistance;
      //     }
      //   } else {
      //     console.log(
      //       "Geocode was not successful for the following reason: " + status
      //     );
      //   }
      // });
    } else {
      if (startLocation && destination){
          // console.log('is not string', startLocation, destination)

        // console.log( startLocation, destination, haversine_distance({startLocation: startLocation, destination }));
      driverDistance = haversine_distance({startLocation: startLocation, destination, moreData });
      // console.log('driverDistance', driverDistance)
        return driverDistance;
      }
    }
  };
    // const setEndLatLng = async () => {
    //   await geocoder.geocode({ address: destination }, function (results, status) {
    //     console.log({results, destination});
       
    //     console.log({latLng});
    //     if (status == "OK") {
    //       var latLng = {
    //         lat: results[0].geometry.location.lat(),
    //         lng: results[0].geometry.location.lng(),
    //       };
    //       destinationLatLng = latLng;
    //     } else {
    //       console.log(
    //         "Geocode was not successful for the following reason: " + status
    //       );
    //     }
    //   });
    // };

    
    await setStartLng()
    
    // setEndLatLng()
    return driverDistance;
   

  }

  
  return "big";
  // return haversine_distance({startLocation, destination});
};

// export default function useDirectionsMaps({
//   // mapDirections,
//   startLocation,
//   destination,
//   // setDirections,
//   map,
//  })
//  {

//    useEffect(() => {
//     console.log('directionshook', { startLocation, destination})

//   }, [startLocation, destination]);
// }

export default useDirectionsMap;
