// import React from 'react';

// import 'firebase/firestore';
import { collection, doc, getDocs, onSnapshot, query, where, getFirestore, getDoc, updateDoc, and, addDoc } from 'firebase/firestore';

import { kinDb, appFirebase, cloudFunctions, providers, kindAuth } from '../firebase-config';
import { getUserId } from '../firebase';
// import useDirectionsMap from "../hooks/useMapDirections";
// import { getGeoCode } from "../hooks/useGoogleMap";
// import app from "firebase/compat/app";
// import { onCurrentUserSubscriptionUpdate,getProducts, createCheckoutSession } from "@stripe/firestore-stripe-payments";
// import { get } from 'http';

// import { httpsCallable } from 'firebase/functions';

const db = getFirestore(appFirebase);

export const checkForExistingInvite = async (inviteEmail) => {

  const uid = getUserId();
  // console.log('### checkForExistingInvite', inviteEmail);
  // Add your code here


  const cUserRef = collection(db, 'invites');
  const q = query(cUserRef, where("email", "==", inviteEmail));
  const cUserSnapshot = await getDocs(q);
  if(cUserSnapshot?.empty) {
    return false;
  } else
  if( cUserSnapshot?.docs?.length > 0) {


    
    // const q = query(inviteCollectionRef, where("email", "==", inviteEmail));
    // const inviteSnapshot = await getDocs(q);
    const inviteDocs = cUserSnapshot?.docs;
    
    if (inviteDocs.length > 0) {
      const invite = inviteDocs[0].data();
      // console.log('### invite', invite);
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

// Store invite code and email of user invited to collection
export const storeInvitedUser = async ( email, inviteCode) => {
  const uid = getUserId();
  // console.log('### storeInvitedUser', inviteCode, email, uid);

  try {
    // append invite to the users collection
    const inviteTime = Date.now();
    const userCollectionRef = collection(db, 'users');
    // console.log('### userCollectionRef', userCollectionRef)
    // where userId = uid
    const q = query(userCollectionRef, where("userId", "==", uid));
    const userSnapshot = await getDocs(q);

    userSnapshot?.docs?.forEach(async (doc) => {

      // console.log('### doc', doc?.data());
      const userDoc = doc?.data();
      const userRef = doc?.ref;
      const userInvitesCollectionRef = collection(doc?.ref, 'invites');
      // console.log(inviteCode, email, inviteTime )
      await addDoc(userInvitesCollectionRef, { inviteCode, email, inviteTime  });
      console.log('User invited successfully');
    })

    // const userSnapshot = await getDocs(q);
    
    // create or append to users's invites collection
    // const userInvitesCollectionRef = collection(userDoc, 'invites');
    
    // append the new invite to the user's invites collection
    // await addDoc(userInvitesCollectionRef, { inviteCode, email});
    console.log('User invited successfully');
    


    const inviteCollectionRef = collection(db, 'invites');
    // const inviteDoc = doc(inviteCollectionRef, inviteCode);
    await addDoc(inviteCollectionRef, { inviteCode, email, inviteTime, userId: uid });
    console.log('User invited successfully');
  } catch (error) {
    console.error('Error inviting user:', error);
  }
};



