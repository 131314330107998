import styled from '@emotion/styled';
import {ErrorField} from '../SignUp';

import * as ui from '../../styles/uiVars';


export const AdminInput = styled.div`
  width: 100%;
  margin-bottom: 1em;
`;

export const MarkerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  padding: 0.5em;
  z-index: 1;
  pointer-events: none;
`;

export const MarkerInner = styled.div`
  width: 100%;
  height: 100%;
  background: #2c2f33;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
`;

export const Header = styled.div`
  background: #ffb9b9;
  color: #000;
  padding: 0.25em 0.5em;
  text-align: center;
  font-size: 1.1em;
  font-weight: 600;
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
`;  

export const Footer = styled.div`
  border-top: 1px solid #444;
  padding: 0.25em 0.5em;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Status = styled.div`
  padding: 0.25em 0;
`;

export const StatusText = styled.div`
  font-size: 0.85em;
  text-align: left;
  max-width: 19em;
  font-weight: normal;
  font-size: 0.81em;
  line-height: 1.3;
`;

export const Button = styled.button`
  border: none;
  border-radius: 3px;
  padding: 0.5em 1em;
  font-size: 1em;
  cursor: pointer;

  &.cancel {
    background: #eee;
    color: #555;
  }
  
  &.confirm {
    background: #37d0ff;
    color: white;
  }
`;

export const ThankYouText = styled.div`
  margin-top: 3em;
  line-height: 1.7;
  font-size: 0.58em;
  text-align:center;
  max-width: 224px;
  width: 100%;
  color: #FFF;
`;

export const WithLove = styled.div`
  margin-top: 3em;
  line-height: 1.7;
  font-size: 0.58em;
  text-align:center;
  max-width: 224px;
  width: 100%;
  color: #FFF;
`;


export const CovidButton = styled.div`

  padding: 0.23em; 
  background: #315f88;
  font-size: 1em;
  background: rgba(0,0,0,0.6);
  margin: 0;
  ${({ covid }) => covid && `
    background: green;
  `
  }
   ${({ contracted }) => contracted && `
    background: red;
  `}
`;

export const ExtraToggles = styled.div`
  display:flex;
`;

export const SearchCurrentLocation = styled.div`
  padding: 0.23em; 
  background: #315f88;
  font-size: 1em;
  background: rgba(0,0,0,0.6);
  margin: 0;
`;



export const SearchMarkerForm = styled.form`
  padding: 0.25em;
  /* display: grid; */
  max-height: 100%;
  grid-area: 1 / 2 / 3 / 3; 

  input {
    padding: 0.75em;
  }
  /* label {
    padding: 0.2em;
  } */
`;


export const SearchSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 312px;
  width: 100%;
    margin: auto;
    margin-bottom: 1em;

    ${({ toggle }) => !toggle && `
      display: none;
    `}

`;

export const SubmitCheckIn = styled.div`
  /* padding: 0.64em; */
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 1.5;
  /* identical to box height */
  text-align: center;

  width: 100%;
  
  color: #FFF;
  /* background: #FF9300; */
  border-radius: 2px;
  ${({ active }) => active && `
    cursor: pointer;
    
  `}
`;



export const SearchHeaderText = styled.h1`
  padding: 0.4em; 
  background: #315f88;
  font-size: 1em;
  background: rgba(0,0,0,0.6);
  margin: 0;
  color: white;
`;

export const LocationAutoComplete = styled.div`
  display:flex;
  flex-direction: column;

`;

export const ClearInputBtn = styled.div`
  position: absolute;
  align-self: flex-end;
  right: 0.88em;
  margin-top: 0.975em;
  
  cursor: pointer;
`;

export const SubClearInputBtn = styled(ClearInputBtn)`
  margin: inherit; 
  right: 0;
  top: 13px;
`;

export const TreasureClearInputBtn = styled(ClearInputBtn)`
  right: 1em;
`;


export const Prediction = styled.div`
  /* width: 8em; */
  margin: auto; 
  padding: 1em 0.75em;
  margin-top: 0.25em;
  border-bottom: 1px solid #5C6CBD;
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.2;
  max-width: 25em;
/* identical to box height */

  text-align: left;

  color: #FFFFFF;

  width: 90%;
  cursor: pointer;


  &:hover {
    background: rgba(0,0,0,0.6);
  }
`;

export const FoodErrorField = styled(ErrorField)`
  color: black;
  font-weight: 600;
`;


export const LocationInputContainer = styled.div`
  display:flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0.5em 0;
  width: 100%;
  position: relative;
    margin: 0.22sem auto;
    /* max-width: 312px; */

    align-content: center;
`;

export const Location = styled.input`
  background: #0e1d23;
  border-radius: 2px;
  
  padding-left: 1em;
  height: 3em;
  max-width: 94%;
  padding-right: 2.05em;
  color: #d5f2fb;
  min-width: 225px;
  &:nth-of-type(1) input:-webkit-autofill {
    background-color: #0e1d23 !important;
    color: #ffffff !important;
}

  &::-webkit-input-placeholder {
  font-weight: bold;
  color: #d5f2fb;
  }

  &::placeholder {
  font-weight: bold;
  color: #d5f2fb;
  }
`;

export const SubLocation = styled(Location)`
  width: 100%; 
  max-width: 100%;
  padding-right: 0;

`;

export const SubLocationInputContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 100%;

`;

export const SubSpacer = styled.div`
  padding: 1em;
`;




export const SearchContainer = styled.div`
  position: absolute;
  
  /* top: 1em; */
  border-radius: 8px;
  border-top: 2px solid #72f4dada;
  display: flex;
  /* background: #384274; */
  max-width: 800px;
  max-height: 81vh;
  @media screen and (min-width: ${ui.tablet}) {
    left: 5%; 
  }
`;

export const TogglePanel = styled.div`
  display: flex;
  flex-direction: column;
  background: #4F5CA0;
  background: #09144b;
  background: #01061e;
  
  
  @media screen and (max-width: ${ui.mobile}) {
    max-width: 60px;
    
    img {
      /* height: 100%; */
    }
  }
`;

export const NavBtn = styled.div`
 display: flex;
  cursor: pointer;
  min-height: 0.78em;
  max-width: 18vw;
  min-width: 40px;
  align-items: center;
  color: white;
  position: relative;
  background: #09175d;
  /* background: rgb(45 65 164); */
  ${({ active }) => active && `
    background: #4F5CA0;
    background: #384274;
    background: #253cb3;
    border: 1px solid #fff;
  `
}

  img {
    max-width: 80px;
    @media screen and (max-width: ${ui.tablet}) {
      max-width: 50px;
    }
  }

  justify-content: center;
  padding: 0.35em;
  margin: 0.51em 0.15em;
`;

export const FriendBtn = styled(NavBtn)`
  padding-bottom: 0.81em;
  display: flex;
  flex-direction: column;
  
  
`;
export const ToggleBtn = styled(NavBtn)`
  padding-bottom: 0.81em;
  display: flex;
  flex-direction: column;

  
`;

export const ToggleRideBtn = styled(NavBtn)`
  padding-bottom: 0.81em;
  display: flex;
  flex-direction: column;
  
`;

export const CheckListBtn = styled(NavBtn)`
  /* border-top: 1px solid #0f248e; */
  padding: 1.11em 0.35em;
`;

export const HopeBtn = styled(NavBtn)`
  border-top: 1px solid #49548e;
  padding: 1.11em 0.35em;
  ${({ active }) => !active && `
    display:none;
  `};
`;

const AdminBtn = styled(NavBtn)`
  border-top: 1px solid #182d97;
  padding: 1.11em 0.35em;
`;

export const StatusBtn = styled(NavBtn)`

  border-top: 1px solid #112585;
  
`;

export const TreasureBtn = styled(NavBtn)`
    border-top: 1px solid #162b94;
    padding-top: 0.81em;
    display: flex;
    flex-direction: column;

  img {
    width: 3em;
  }


`;



export const AlertBtn = styled(NavBtn)`

  border-top: 1px solid #49548e;

  ${({ active }) => active && `
    background: #4F5CA0;
    background: #384274;
    background: #253cb3;
  `
  }

  ${({ toggle }) => toggle && `
      display: none;
  `
  }

  
  
`;


  export const AlertIcon = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
    }

  `;


export const AlertCount = styled.div`
  position: absolute;
  font-size:8px;
  top: 0.75em;
  right: 0.75em;
`;

export const AlertModalBtn = styled.div`
  line-height: 2;
  padding: 0 0.5em;
  font-size: 0.88em;
  cursor: pointer;

`;

export const CloseAlertModal = styled.div`
  position: absolute; 

  cursor: pointer;
  right: 0.25em;
  top: 0.5em;
`;

export const AlertsModal = styled.div`
  display: flex;
  max-width: 25em;
  /* margin: auto; */

  z-index: 5;

  background: rgba(255, 147, 1, 0.62);
  background: rgba(255, 147, 1, 0.87);
  background: #ff9300e3;
  padding: 1em;
  height: auto;
  position: fixed;
  top: 9.5em;
  right: 0;
  border-radius: 4px;
  color: black;
  

  @media screen and (min-width: 720px) {
      /* left: 1em; */
      top: 4em;
      right: 2em;
      max-width: 25em;
  }
  
  ${({ toggle }) => toggle && `
      display: none;
  `
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  color: black;
  font-weight: 600;
  margin: auto;

  
`;

export const AlertIconWrapper = styled.div`
  /* background: #384274; */
  flex-basis: 15%;
  display: flex;
  align-items: center;
`;

export const AlertContentWrapper = styled.div`
  flex-basis: 80%;
`;

export const AlertModalHeader = styled.div`
  margin-top: 0.25em;

  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
  line-height: 1.3;

`;


export const SearchHeader = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.15em;
  margin-top: 0.25em;
`;
export const SearchPanelHeader = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.15em;
`;




export const SearchSubHeader = styled.div`
  margin-top: 0.5em;
  font-size: 0.978em;
  line-height: 1.2;
  /* text-transform: uppercase; */
  padding-bottom: 0.5em;
  text-align: left;
  color: #fefefe;
  background: #3842748c;
  font-weight: 600;
`;

export const SearchCopyBtn = styled.div`
  padding: 0.23em;
  background: #315f88;
  cursor: pointer;
  &:hover {
    background: #b32725;
    border: 1px solid #fff;
    /* animation: hoverAnimation 0.3s ease-in-out; */
  }
`;

export const SearchCancelBtn = styled(SearchCopyBtn)`
  background: #101e4e;
  width: 225px;
  border: 1px solid #fff;
  text-align: center;
  padding: 0.5em;
  font-size: 0.9em;
  align-self: flex-end;
  &:hover, &:active {
    background: #a70b1d;
  }
`;


export const SearchCopy = styled.div`
  margin-top: 0.51em;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875em;
  line-height: 1.4;
  /* word-break: break-all; */
  /* background: #f1f5f5a7; */
  background: #1b4251bb;
  background: #094e9cba;
  padding: 0.525em;
  /* add dropshadow to text */
  
  /* box-shadow: 0 0 0.5em #000; */
  text-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.75);
  /* dropshadow: 0 0 0.5em #000; */
    /* width: 100%; */
    max-width: 502px;
  /* or 17px */
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
font-weight: 600;
  color: #DACFF7;
  color: #fff;
`;

export const CenteredTextContainer = styled(SearchCopy)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  `;

export const SearchTextContent = styled(SearchCopy)`
  background: #2c2872;
  background: #34334e;
  font-size: 0.75em;


`;


export const SearchSuggestMore = styled.div`
  font-size: 0.6em;
  margin-top: 0.7em;

`;

export const SearchSuggestMoreLink = styled.a`
  color: #fff;
  padding-left: 0.5em;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
  border: 1px solid #6775BF;
  padding: 0.25em;
  cursor: pointer;
  margin: 0.1em;
 

  ${({ active }) => active && `
    background: #4F5CA0;
  `
  }
  ${({ toggle }) => toggle && `
    display: none;
  `
  }


  /* margin-left: 0.25em; */
`;

export const OutlineContainer = styled.div`
  border: 1px solid #6775BF;
  border: 1px solid #465fe8;
  border-radius: 6px;
  padding: 0.35em;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  background: #1b5151bc;
  background: #122f81bc;
  position: relative;

  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;
  /* justify-content: center; */
  /* align-items: center; */
  ${({ toggle }) => toggle && `
      display: none;
  `}
`;

export const AvatarColumnContainer = styled.div`
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  

`;
export const OutlineAvatarContainer = styled(OutlineContainer)`
  margin-top: 0%;
  padding: 0.25em;
  padding-top: 0.5em;
  border-radius: 8px;
  background: #00030cb9;
  display: flex;
  max-width: 100%;
  background: linear-gradient(180deg, #2E2E75 0%, #000 100%);
min-width: 366px;
min-width: 286px;
  @media screen and (max-width: ${ui.tablet}) {
    max-width: 100%;
    
  }
`;
export const SetDonationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  align-items: center;
`;

export const SetDonationInput = styled.input`

  background: #F1F5F5;
  border-radius: 2px;
  color: #03373b;
  padding: 0.5em;
  margin-top: 0.5em;
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 0.91em;
  line-height: 2.1;
  /* identical to box height */
  width: 100%;
  max-width: 95%;

  ${({ toggle }) => !toggle && `
      display: none;

  `}
`;
export const OutlineColumnContainer = styled(OutlineContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
   ${({ toggle }) => toggle && `
      display: none;
  ` }
`;

export const OutlineNavColumnContainer = styled(OutlineColumnContainer)`

@media screen and (max-width: ${ui.tablet}) {
  flex-direction: column;
}
 
`;

export const ResponsiveColumnContainer = styled(OutlineColumnContainer)`


    @media screen and (max-width: ${ui.tablet}) {
      flex-direction: column;
    }

`;

export const ButtonColumnContainer = styled(OutlineColumnContainer)`

    justify-content: normal;
    @media screen and (max-width: ${ui.mobile}) {
      flex-wrap: wrap;
      flex-direction: row;
    }

`;

export const UseCurrentLocationContainer = styled.div`
  margin-top: 0.133em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 0.25em #000;
  text-shadow: 0 2px 0.25em #000;
  cursor: pointer;
  border: 1px solid #303b73;
  padding: 0.3em;
`;

export const NavActionBtn = styled.div`
  font-size: 0.68em;
  margin: 0.2em;
  width: 100px;
  cursor: pointer;
  top: 0;
  padding: 0.25em;
  margin-bottom: 60px;
  border: 1px solid #5b6ab4;
  background: #0b1857;
`;
export const RefreshUiBtn = styled.div`
  position: absolute;
  right: 0;
  font-size: 0.68em;
  margin: 0.2em;
  cursor: pointer;
  top: 0;
  padding: 0.25em;
  border: 1px solid #5b6ab4;
  background: #0b1857;
`;

export const UseCurrentText = styled.div`
  font-size: 0.708em;
  text-transform: uppercase;
  line-height: 1.3;
  font-weight: 600;
  text-align: center;
  font-family: Roboto, Helvetica, sans-serif;

  &:hover {
    color: #bff377;
  }

  ${({ toggle }) => toggle && `
      display: none;
  `}
`;
export const UseCurrentBtn = styled.div`
  height: 1.33em;
  width: 1.33em;
  background: #F1F5F5;
  border-radius: 2px;
  margin-right: 1.33em;

  img {
      display: none;
    }

    ${({ clearMargin }) => clearMargin && `
      margin-right: 0.35em;
      margin-left: 0.15em;
    `}

  ${({ useCurrent }) => useCurrent && `
    img {
      display: inherit;
    }
  `}

`;


//  Start alert panel


export const LastReportedContainer = styled.div`
  margin-top: 1em;
  display: flex;
  align-items: center;

  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.53em;
  line-height: 1.2;
  /* or 13px */


  color: #FFFFFF;
`;

export const LastReportedGraphic = styled.div`
  flex-basis: 40%;
  max-width: 54em;
  min-width: 75px;

  img {
    width: 100%;
  }
`;

export const LastReportedStats = styled.div`
  border-left: 1px solid #6775BF;
  padding: 0 1.5em;
  flex-grow: 1;
`;

export const LastReportedDate = styled.div`
  margin: 0.5em 0;
  line-height: 1.5;
  font-size: 12px;
  max-width: 200px;
`;

export const LastReportedStatus = styled.div`
  margin: 0.5em 0;
  line-height: 1.4;
  /* font-size: 0.54em; */
`;


export const AlertHeader = styled.div`
  margin-top: 0.75em;

  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.81em;
  line-height: 1.3;

  max-width: 23em;
  text-align: left;
  align-self: flex-start;

  color: #FFFFFF;

  display: flex;
  flex-direction: column;
`;

export const AlertHeaderText = styled.div`
  flex-basis: 100%;
`;

export const AlertSubHeader = styled.div`
    font-family: Metropolis, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 0.8em;
    line-height: 1.2;
    margin-top: 1em;
    /* or 17px */

    text-align: left;
    color: #FFFFFF;

`;

export const AlertRecommendations = styled.div`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  
  
`;

export const AlertRecommendation = styled.div`
  background: #4F5CA0;
  margin: 0.25em 0;
  padding: 0.25em;
  max-width: 275px;
  width: 100%;
  line-height: 1.2;
  text-align: center;
  font-size: 0.95em;
  font-size: 14px;
  cursor: pointer;

  ${({ contracted }) => contracted && `
    background: #e25600;
  `}

  a {
    color: white; 
    text-decoration: none;
    display: block;
  }
`;

export const AlertExposureLocations = styled.div`
  margin: 1em 0;
  display: flex;
  justify-content: center;
  flex-flow: wrap;

  min-height: 9em;
  overflow: auto;
`;

export const AlertExposureList = styled.div`
    flex-basis: 50%;
    text-align: center;

    @media screen and (max-width: 768px) {
      flex-basis: 100%;
    }
`;

export const ExposureContainer = styled.div`
  
`;
export const WarnList = styled(Prediction)``;
export const WarnListUGC = styled(Prediction)``;

// end Alert Panel



// 
//  // Start Thank You Panel

// 

export const SubmitThankYouPanel = styled.div`
    color: white;
    text-align: left;
    padding: 0.5em 1em;
    display: flex;
    border-left: 1px solid #4F5CA0;

    ${({ toggle }) => !toggle && `
      display: none;
    `}

    background: #4F5CA0;
    flex-flow: wrap;
    flex-direction: column;

    padding-bottom: 3em;
`;


export const MarkerAddedContainer = styled.div`
  display: flex;
    justify-content: center;
    flex-flow: wrap;
    /* background: #6776BF; */

    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;

    line-height: 2;
    padding: 0 2em;
    flex-direction: column;
    margin: auto;
    position: relative;
    margin-top: 4em;

    @media screen and (max-width: 480px) {
      padding: 0 1em;
    }
`;

export const MarkerAddedPin = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 169px;
    width: 100%;
    justify-self: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: -2.35em;
`;

export const MarkerAddedHeader = styled.div`
  font-size: 0.9em;
  font-family: sans-serif;
`;

export const MarkerAddedSubHeader = styled.div`
    font-size: 0.5em;
    text-align: center;
`;

export const SubmitThankYouMessage = styled.div`
      margin-top: 4em;
      line-height: 1.7;
      font-size: 0.7em;
      text-align:center;
      max-width: 224px;
      width: 100%;
      color: #D8D8D8;

`;



// 
//  // End Thank You Panel

// 



// 
//  // Start Status Panel

// 


export const StatusFormQuestions = styled.div`
  font-size: 0.85em;
  padding-bottom: 1em;
`;


export const StatusUpdateBtn = styled.div`
  line-height: 2; 
  max-width: 312px;
  background: #FF9300;
  color: white; 
  font-size: 0.8;
  text-align: center;

  /* opacity: 0.7; */
  cursor: pointer;
  margin: 1em auto;
  /* background: #73C700; */
  width: 100%;

  ${({enabled}) => enabled && `
    // opacity: 1;
    background: #73C700;
    background: #FF9300;
    
  `};
`; 

export const FormQuestion = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.35em 0;
  
`;

export const StatusFormHeader = styled.div`
  font-size: 0.8em;
  font-weight: 400;
  padding: 1em 0;

`;

export const StatusLastReportedStats = styled.div`
  border-left: 1px solid #6775BF;
  padding: 0 1.5em;
  flex-grow: 1;
  max-width: 14em;
  font-size: 14px;
  line-height: 1.5;
`;

export const FormBox = styled.div`
  flex-basis: 5%;
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  background: url('/svgs/checkbox-empty.svg') no-repeat;
  
  ${({ active }) => active && `
    background: url('/svgs/checkbox-checked.svg') no-repeat;
  `}

  cursor: pointer;
  background-size: contain;

`;


export const FormLabel = styled.div`
  cursor: pointer;
  flex-basis: 86%;
`;


// 
//  // End Status Panel

// 



// 
//  // Start Treasure Panel

// 


export const SelectItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Treasure = styled.div`
  margin: 0.4em;
  flex-basis: 28%;
  /* padding: 0.5em; */
  padding: 0.5em 0;
  border: 1px solid #5C6CBD;
  cursor: pointer;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 500px) {
    flex-basis: 41%;
  }

  ${({active}) => active && `
    background: #4F5CA0;
  `}
  

  &:hover {
    background: #4F5CA0;
    border: 1px solid #4F5CA0;
  }

  img {
    cursor: pointer;
    height: 75px; 
    width: auto;
  }
`; 

export const TreasureForm = styled.form`
  margin-top: 0.5em;

`;

export const HopeForm = styled.form`
  margin-top: 0.5em;

`;

export const FoodBankForm = styled(HopeForm)`

`;

export const HopeFoodBank = styled.div`
  display: flex;
  background: #fff;
  color: black;
  border: 1px solid #F1F5F5;
  padding: 1em;
  cursor: pointer;

  ${({active}) => active && `
    display: none;
  `}
  
`;

export const SelectedHopeFoodBank = styled(HopeFoodBank)`
  flex-wrap: wrap;
  flex-direction: column;
  
 
`;

export const SelectedFoodBankSent = styled.div`
  /* display: flex;  */
  flex-wrap: wrap;
  flex-direction: column;
  ${({active}) => !active && `
    display: none;
  `}
`;

export const GoBack = styled.div`
  cursor: pointer;
`;

export const FoodBankLogo = styled.div`
  flex-basis: 25%;

`;

export const FoodBankContent = styled.div`
  flex-basis: 75%;
`;

export const SelectedFoodBankContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 1em;
`;

export const SelectedFoodBankForm = styled.form`
  padding: 1em 0;
  display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
`;

export const SelectedFoodSubmit = styled.div`
  background: #FF9300;
  width: 230px;
  height: 54px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 1em;
`;

export const FieldTitle = styled.div`
  font-size: 14px;
`;

export const FieldInput = styled.input`
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 2px;
  width: 300px;
  height: 32px;
  margin: 5px 0;
  padding-left: 5px;
`;

export const FoodBankHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 2;
`;

export const FoodSearchCopy = styled(SearchCopy)`
  color: black;
  font-size: 14px;
`;


export const FoodSearchDetails = styled(SearchCopy)`
  padding-top: 1em;
  color: black;
  font-size: 10px;
`;

export const FoodSearchDetailsRed = styled(FoodSearchDetails)`
  color: #FF0000;
`;

export const FoodBankDetails = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2;
`;

export const FoodBankTel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 2;
`;

export const FoodBankArrow = styled.div`

`;

export const TreasureInputContainer = styled(LocationInputContainer)`
  position: relative;
`;

export const SelectedTitleInput = styled.textarea`
  
  border: none;
  padding: 0.75em;
  text-align: left;
  margin: auto;
  color: white;
  font-weight: 600;
  font-size: 1em;
  word-break: break-all;
  /* background: #2b0e69; */
  width: 100%;
  pointer-events: none;
  cursor: none;
  /* background: #384274; */
  background: #081762;

  @media screen and (max-width: 500px) {
    height: 6vh;
  }
  
  ${({active}) => active && `
    border-bottom:1px solid #4F5CA0;
  `}
`;

export const SelectedHidden = styled.input`
  display: none;
`;

export const SetTreasureButton = styled.button`
   width: 8em;
  margin: auto; 
  background: #315f88;
  color: white;
  padding: 0.5em;
  margin-top: 0.25em;
  background: #73C700;
  background: #00a3d4;
  border-radius: 2px;
  margin-top: 0.5em;
  width: 100%;
  font-size: 1.0188em;
  cursor: pointer;
`;
// 
//  // End Treasure Panel

// 

export const AppPanel = styled.div`
  color: white;
  text-align: left;
  padding: 0.25em 0.375em;
  display: flex;
  flex-direction: column;
  background: #4F5CA0;
  background: #051044;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 80vh;
  overflow-y: auto;
  opacity: 0.93;

  ${({ toggle }) => !toggle && `
    display: none;
  `}

  @media screen and (max-width: ${ui.mobile}) {
    padding: 0.1em;
  }

`;

export const TreasurePanel = styled(AppPanel)`
  

`;


export const AlertPanel = styled(AppPanel)`
   /* color: white;
  text-align: left;
  padding: 0.5em 1em;
  display: flex;
  flex-direction: column;

  ${({ toggle }) => !toggle && `
    display: none;
  `} */

  max-height: 25em;
  overflow: auto;
  margin-right: -1em;
  
`;


export const StatusPanel = styled(AppPanel)`
  

`;


export const FriendPanel = styled(AppPanel)`
  /* background: url('/images/dropkins/ui/sidePane/freinds_bg_001.jpg'), lightgray 50%; */
  /* background-repeat: no-repeat; */
  /* background-size: contain; */

  background: url('/images/ui/bg/panel_bg_004.jpg');
  background: linear-gradient(180deg, #2E2E75 0%, #000 100%);
  background-repeat: no-repeat;
  background-size: cover;
  left: 90px;
  color: black;
  opacity: 0.93;
  width: 100%;
  z-index: 13;

`;
export const SearchPanel = styled(AppPanel)`
  
  background: url('/images/ui/bg/panel_bg_001.jpg'), lightgray 50%;
  background-size: cover;
  /* padding: 1em; */

`;

export const AdminPanel = styled(AppPanel)`
  

`; 

export const HopePanel =  styled(AppPanel)`
  padding: 0.5em 0;
`;

export const HopeHeader = styled.div`
  padding: 1em;
  padding-bottom: 2em;
`;

export const CheckInForm = styled.form`

`; 


export const CheckInPanel = styled(AppPanel)`
  

`;


export const SubPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
`;


export const CheckInsList = styled.div`
  display:flex;
  flex-flow: wrap;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  overflow: auto; 
  max-height: 12em;
  
`;

export const CheckedLocation = styled.div`
   /* width: 8em; */
   /* margin: auto;  */
  /* padding: 1em 0; */
  margin-top: 0.33em;
  background: #4F5CA0;
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1;
/* identical to box height */

  text-align: left;

  color: #FFFFFF;

  width: 80%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.9em;


  &:hover {
    background: rgba(0,0,0,0.6);
  }
`;

export const CheckInText = styled.div`
  font-family: Metropolis;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;

  color: #FFFFFF;

`;

export const CheckInDelete = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  /* identical to box height */


  color: #FFFFFF;

`;

export const ClosePanel = styled.div`
  position: absolute; 
  right: 0.75em;
  top: 0.4em;
  cursor: pointer;
  transition: transform .7s ease-in-out;
  z-index: 12;

  &:hover {
    transform: rotate(180deg);
  }
  
`;