import React, {useContext} from 'react';
import moment from 'moment';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, doc, deleteDoc, QuerySnapshot, DocumentData, where, CollectionReference, and, getDoc, updateDoc } from 'firebase/firestore/lite';
import 'firebase/compat/auth';
import 'firebase/firestore';
import { appFirebase, providers, kindAuth, kinDb, messaging, cloudFunctions } from './firebase-config';
import userEvent from '@testing-library/user-event';
import { Firestore, query, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getRedirectResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updatePassword } from 'firebase/auth';
import { onMessage } from 'firebase/messaging';
import { httpsCallable } from "@firebase/functions";
import { getMessaging, getToken } from "firebase/messaging";
import { AuthContext } from './Auth';

// #can you please import all from ./storage
export * from './storage';

  //Initialize Firebase
  export const db = getFirestore(appFirebase);
  // const db = kinDb;

  export const Sendrequest = (setMessageToken:any) => {

  //   const {
  //   setMessageToken,
  // } = useContext(AuthContext);
    console.log("Requesting User Permission......");
    Notification.requestPermission().then(async (permission) => {
      if (permission === "granted") {
        
        const messagingFirebase = getMessaging(appFirebase);
        
        const refreshedToken =  await getToken(messagingFirebase, { vapidKey: process.env.REACT_APP_VAPID_KEY });
            // console.log('Refreshed FCM token:', refreshedToken);
            setMessageToken(refreshedToken);
            // Send the new token to your server to save it.
            // Call the function with the token and uid
           
        // console.log({messagingToken});
        console.log("Notification User Permission Granted.");
      } else {
        console.log("User Permission Denied.");
      }
    });
  }


export async function storeSMSPreferences(smsPhone: any, smsName: any, enableSMS: any, setLoading: any, fiesta: any) {
  // const uid = getUserId();
  // if(!uid) return;


  // const docSnap = doc(kinDb, "customers", uid);
  // console.log(docSnap);

  const uid = getUserId();
  if(!uid) return;
  // console.log({db,})

  const customerRef = doc(db, "customers", uid);

  // Assuming smsPhone and smsName are the fields you want to update
  await updateDoc(customerRef, {
    smsPhone: smsPhone,
    smsName: smsName,
    smsEnabled: enableSMS,
  });

  setLoading(false);
  fiesta('SMS preferences saved');

}
  
  

  export const onMessager = () =>

    new Promise((resolve) => {

    onMessage(messaging, (payload) => {

    resolve(payload);

    });

});

export const fireBaseLogin = async (loginType: string | number) => {
   await signInWithPopup(kindAuth, providers[loginType as keyof typeof providers])
    .then((result: { user: any; }) => {
      const user = result.user;
      // console.log(user);
      return;
    }).catch( (e: any) => console.log(e))
  }
  
  
  export const fireBaseLoginRedirect = async (loginType: string) => {
    await signInWithRedirect(kindAuth, providers[loginType as keyof typeof providers])
  
  
    const result = await getRedirectResult(kindAuth);
    // console.log({result})
    if (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // var token = result.credential.accessToken;
      // console.log(result.credential)
      // ...
      
      // The signed-in user info.
      var user = result.user;
      // console.log('red', user)
    } 
  }
  
  export const createUserEmail = async (email:string, password:any) => {
    const userCredential = await createUserWithEmailAndPassword(kindAuth, email, password);
    const newUser = userCredential.user;
    // console.log('newUser', {newUser})

    if (newUser) {
      // Trigger Cloud Function to create a Stripe Connect account
      const createUserFunction = httpsCallable(cloudFunctions, 'createStripeConnectAccount');
      const result = await createUserFunction({ uid: newUser?.uid, email: newUser?.email });
      // console.log('Stripe account created:', result.data);
    }
  
  }
    
  
  export const signInEmail = async (email:string, password:any) =>
    await signInWithEmailAndPassword(kindAuth, email, password )
  
  export const resetPass = async (email:string) => {
    
    try {
      await sendPasswordResetEmail(kindAuth, email);
      // console.log('email sent')
      return {success: true, message: 'email sent', email: email}
    } catch (error) {
      // console.log(error)
      return {success: false, message: 'Failed to Send', email: email}
    }

  
  };

  export const updateUserPassword = (password:string) => {
    const curUser = kindAuth.currentUser;
   
    curUser && updatePassword(curUser, password).then(() => {
      // Update successful.
      // console.log('password updated')
    }).catch((error) => {
      // An error ocurred
      // ...
    });
  
  }
    // fireAuth.currentUser && fireAuth.currentUser.updatePassword(password);
  
  
  export const logOut = () => kindAuth.signOut();
  
  export const getUserId = () => {
  
    return kindAuth.currentUser && kindAuth.currentUser.uid;
  }



export const addOrRemoveFriend = async (username:string, friendId:string, currentUserId:string) => {
  // const userRef = doc(db, "users", getUserId());
  const friendRef = doc(db, "users", friendId);
  const docSnap = await getDoc(friendRef);
  if (docSnap.exists()) {
    return false;
  } else {
    await setDoc(friendRef, {username: username});
    return true;
  }
}



  // Manipulate Data

  // Check Ins

  export const deleteCheckIn = async (id:string, address:string) => {
    let warningId;
    await deleteDoc(doc(kinDb, "allMarkers", id))
    .then( async function() {
      console.log("CheckIn successfully deleted!");
  
      const collectionRef = collection(kinDb, 'covid-ugc-markers');
      const q = query(collectionRef, and(
        where("userId", "==", getUserId()),
        where("address", "==", address))
        )
  
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (queryDocument) => {
      // console.log('rem-ugc', queryDocument.id, ' => ', queryDocument.data());

      warningId = queryDocument.id;

      deleteDoc(doc(kinDb, "covid-ugc-markers", queryDocument.id))
        .then(async function () {
          await console.log("Warning covid-ugc successfully deleted!");
        }).catch((error: any) => {
          console.error("Error removing Warning Marker: ", error);
        });
    });
  }
  );
      
        
      const warningMarkersCollection = collection(kinDb, 'warning-markers-ugc');
      const warningMarkerDocs = await getDocs(warningMarkersCollection)
   
      warningMarkerDocs.forEach(  async function (warningMarker) {
        // console.log('warningMarkerdon', warningMarker.data())
        if(!warningMarkerDocs.empty) {
          const data = warningMarker.data();
            // console.log(doc.id, ' => ', doc.data());
            warningId = warningMarker.id;
  
            // const q = query(warningMarkersCollection, where("userId", "==", getUserId()));
  
  
            const warningMarkerUgc = doc(db, 'warning-markers-ugc', warningId);
            const docSnap = await getDoc(warningMarkerUgc);
  
          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            await deleteDoc(doc(kinDb, "warning-markers-ugc", warningId)).then( async function() {
              await console.log("Warning Marker successfully deleted!");
            }).catch(function(error: any) {
              console.error("Error removing Warning Marker: ", error);
            });
          } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
          }
  
  
        
        } 
      })
    
     
     
        
    // console.log('after delete')
    return warningId;
    
  }


export const sendHeartTo = async (markerId: string) => {
  const covidUGCMarkersDocRef = doc(kinDb, 'covid-ugc-markers', markerId);
  
  const docSnap = await getDoc(covidUGCMarkersDocRef)


if (docSnap.exists()) {
//  console.log("Document data:", docSnap.data());
 const data = docSnap.data();;
     
   const mData = docSnap && docSnap.data();
   //  mData.userId
   // console.log(mData && mData.userId, 'sending heart', markerId, docSnap.data());
 //  if(docSnap && !docSnap.empty) {  docSnap.data().userId) }
 if (mData && mData.userId) {
   const sendLoveRef = collection(kinDb, 'send-love');
   const q = query(sendLoveRef, where("userId", "==", mData.userId))
   
   const querySnapshot = await getDocs(q);
   querySnapshot.forEach(async (qSnap) => {
     // console.log('qSnapdon', qSnap.data())
     // .then( function (res: { empty: any; forEach: (arg0: (love: any) => void) => void; }) { 
     if (querySnapshot.empty) {
       await addDoc(sendLoveRef, {
         userId: mData.userId,
         time: Date.now(),
         count: 1,
         senderId: getUserId(),
         markerId: markerId,
       });
       // appFirebase.analytics().logEvent('Sent Love');
     } else {
       // console.log('trying to update heart now', querySnapshot)
       querySnapshot.forEach(async function (love: { data: () => any; id: any; }) {

         const heart = love.data();

         const sendLoveRef = doc(kinDb, "send-love", love.id);

         // Set the "capital" field of the city 'DC'
         await updateDoc(sendLoveRef, {
           count: heart.count + 1,
         });




               })
     }

     // console.log(res.empty); 
     // res.docs.map(doc => {console.log(doc.data())}); 
     return querySnapshot.empty;
   });
   }
 }
 }


 export default kinDb;