import React from 'react';
import styled from '@emotion/styled';
import * as ui from '../../../styles/uiVars';

export const ConfirmationModalContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 400px;
  width: 300px;
  z-index: 15;
  overflow-y: auto;
  max-height: 80vh;

  border-radius: 0px 8px 8px 0px;
  background: #2E2E75;
  background: linear-gradient(180deg, #2E2E75 52.51%, #000 99.99%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  /* Style the scroller */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #1c222c;
  }

  ::-webkit-scrollbar-thumb {
    background: #4d5a71;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6b7896;
  }




  
  /* display:none; */
  ${({ toggle }) => toggle && `
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;
  // background: rgba(0,0,0,0.5);
  // background: url('/images/dropkins/ui/buttons/tech_buttons_002.jpg') no-repeat;
  background-size: cover;
  z-index: 1000;
  margin: 1em;
  `
  }
  /* back */
`;
export const ConfirmTestimonialLink = styled.div`
  position: relative;
  font-size: 0.81em;
  padding: 1em;
  text-align: left;
  background: #1c222c;
  cursor: pointer;
  text-align: center;
  margin: 0.5em;
  border: 1px solid #4d5a71;
  

`;
export const ConfirmationModalText = styled.div`
  position: relative;
  font-size: 1em;
  padding: 1em;
  text-align: left;
  background: #1c222c;
  line-height: 1.3;


`;

export const ConfirmationButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1em;
  button {
    width: 50%;
    height: 2em;
    border-radius: 5px;
    border: none;
    outline: none;
    background: ${ui.colors.primary};
    color: ${ui.colors.white};
    font-size: 1em;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const ConfirmationHeader = styled.div`
  position: relative;
  font-size: 1.2em;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  margin: 1em 0;

`;

export const ConfirmationButton = styled.button`
  position: relative;
  width: 50%;
  height: 2em;
  border-radius: 5px;
  border: none;
  outline: none;
  background: ${ui.colors.primary};
  color: ${ui.colors.white};
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  margin: 1em;
`;

export const ConfirmationTestimonialText = styled.div`
  position: relative;
  font-size: 0.8em;
  padding: 1em;
  display: none;
  flex-direction: column;
  margin: 1em;

  ${({ toggle }) => toggle && `
    display: flex;
    `}
`;
export const ConfirmationTestimonial = styled.div`
  /* position: relative; */
  
  display: none;
  

  ${({ toggle }) => toggle && `
    display: flex;
    margin: 1em;
    width: 80%;
    padding: 1.5em;
  height: 200px;
  font-weight: 700;
  max-width: 300px;
  min-height: 200px;
  color: black;
  line-height: 1.2;
  overflow: hidden;
  overflow-y: scroll;
  font-size: 10px;
  background: ${ui.colors.white};
    `}
`;

export const ConfirmationModal = ({ onConfirm, onCancel, toggle, termsToggle, confirmationModalText, confirmationAlertText, confirmationHeaderText, setTermsPanelToggle, setLoading }) => {
  const handleConfirm = () => {
    if (window.confirm(`${confirmationAlertText}`)) {
      setLoading(false);
      onConfirm();
    } else {
      onCancel();
      setLoading(false);
    }
  };
  
  const handleCancel = () => {
    // console.log('!cancel', toggle, onCancel)
    setLoading(false);
    onCancel();
  };

  return (
    <ConfirmationModalContainer toggle={toggle} className={'btn'}>
      <ConfirmationHeader>{confirmationHeaderText ? confirmationHeaderText : 'Before we proceed,'}</ConfirmationHeader>
      <ConfirmationModalText className={'btn'}>{confirmationModalText}</ConfirmationModalText>
      {/* <ConfirmationTestimonial className={'btn'}
        toggle={termsToggle}
      >
        <iframe width="100%" height="200px" src="https://app.termly.io/document/terms-of-use-for-website/aaf8775d-6969-4662-a8bf-fee77acb3fb4" frameBorder="0"></iframe>
      </ConfirmationTestimonial> */}
      <ConfirmationTestimonial toggle={true}>
      Legal Disclaimer for Dropkins
          <br /><br />
        1. Acceptance of Terms:
        By accessing and using Dropkins, you agree to the terms and conditions outlined in this disclaimer. If you do not agree with these terms, please do not use Dropkins.
        <br /><br />
        2. Nature of Service:
        Dropkins is a platform that facilitates connections between users for the purposes of sharing rides. It is important to note that Dropkins is not a ride service provider, transportation carrier, or agent for transportation services. The platform simply acts as an online bulletin board, similar to services like Craigslist, where users can post and respond to ride-sharing opportunities within their network.
        <br /><br />
        3. User Responsibility:
        All arrangements and agreements for ride-sharing are solely between the users of the platform. Dropkins has no involvement or control over the terms, conditions, legality, suitability, or any aspect of the ride-sharing agreements made between users. Users are responsible for ensuring their own safety, the legality of their actions, and compliance with all applicable laws and regulations.
        <br /><br />
        4. No Liability:
        Dropkins, its affiliates, officers, directors, employees, agents, and successors are not liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising from your use of the platform or any arrangements made based on information obtained from the platform. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or the inability to use our services.
        <br /><br />
        5. Indemnification:
        You agree to indemnify and hold harmless Dropkins and its affiliates, officers, agents, and employees from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of Dropkins, your connection to Dropkins, your violation of the Terms of Service, or your violation of any rights of another user.
        <br /><br />
        6. No Endorsement of Users:
        Dropkins does not endorse any users, nor does it investigate or vet any users of the platform. It is the responsibility of each user to determine the trustworthiness and suitability of their ride-sharing counterparts.
        <br /><br />
        7. Modification of Disclaimer:
        Dropkins reserves the right to modify this disclaimer at any time, and such modifications shall be effective immediately upon posting of the modified disclaimer. Your continued use of the platform shall be deemed your conclusive acceptance of the modified disclaimer.
        <br /><br />
        8. Governing Law:
        This disclaimer shall be governed by and construed in accordance with the laws of the jurisdiction in which Dropkins operates, without giving effect to any principles of conflicts of law.
        <br /><br />
        9. Acknowledgement:
        By using Dropkins, you acknowledge that you have read this disclaimer and agree to all its terms and conditions. You have entered into this agreement voluntarily, and not based on any representations, promises, or inducements not stated herein.


      </ConfirmationTestimonial>
        <ConfirmationTestimonialText className={'btn'}
          toggle={termsToggle}
        >
         {/* <ConfirmTestimonialLink className={'btn'} onClick={() => setTermsPanelToggle(true)}>
          Click to Expand Terms</ConfirmTestimonialLink> */}
        </ConfirmationTestimonialText>
        <ConfirmationTestimonialText className={'btn'}
          toggle={termsToggle}
        > By clicking "Accept" you agree to the above terms and conditions. </ConfirmationTestimonialText>
      <ConfirmationButtonsContainer className={'btn'}>
        <ConfirmationButton onClick={handleConfirm}>Accept</ConfirmationButton>
        <ConfirmationButton onClick={handleCancel}>Cancel</ConfirmationButton>
      </ConfirmationButtonsContainer>
    </ConfirmationModalContainer>
  );
};

export default ConfirmationModal;
