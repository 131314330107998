import React, { useState, useEffect, useContext } from "react";

import { useStripe } from "@stripe/react-stripe-js";

import {
  appFirebase,
  providers,
  kindAuth,
  kinDb,
  messaging,
  cloudFunctions,
} from "../../../firebase-config";
import { AuthContext } from "../../../Auth";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as ui from "../../../styles/uiVars";
import { httpsCallable } from "@firebase/functions";

const SubscriptionGateSubscribe = styled.button`
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: #384274;
    background: #253cb3;
    background: #4f5ca0;
    border: 1px solid #fff;
    animation: hoverAnimation 0.3s ease-in-out;
  }
`;

export const SubscriptionGateBtn = ({ onClick, children }) => {
  const stripe = useStripe();
  const {
    customerData,

    setNotification,
    setFiestaMessage,
  } = useContext(AuthContext);


  const resetNotifications = async () => {
    setNotification(null);
    setFiestaMessage(null);
  };
  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };



  const handleSubscribeClick = async () => {
    // Call your server to create a checkout session and get the session ID

    const createCheckoutSession = httpsCallable(
      cloudFunctions,
      "createCheckoutSession"
    );

    try {
      const result = await createCheckoutSession({
        customerId: customerData?.stripeCustomerId,
        userId: customerData?.userId,
        email: customerData?.email,
        priceId: "price_0Oe0YAs9aDFvbawN7UNpCfJb",
      });

      console.log('### create checkout result',{ result });

      const sessionId = result.data.id;

      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      // Handle any errors

      if (error) {
        console.log(error);
        notifier("Something went wrong, please try again later");
      }

    } catch (error) {}
  };

  
  // Get Stripe Pricing for product
  // const getStripePlans = async () => {
  //   const stripePlans = [];
  //   const plans = await kinDb.collection("plans").get();
  //   plans.forEach((plan) => {
  //     stripePlans.push({
  //       id: plan.id,
  //       price: plan.data().price,
  //       name: plan.data().name,
  //     });
  //   });
  //   return stripePlans;
  // };

  // ...

  return (
    <SubscriptionGateSubscribe onClick={handleSubscribeClick}>
      Subscribe Now
    </SubscriptionGateSubscribe>
  );
};

export default SubscriptionGateBtn;
