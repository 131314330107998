import { useEffect, useState } from "react";

const eventMapping = {
  onClick: "click",
  onDoubleClick: "dblclick"
};

export default function useGoogleMapMarker({
  position,
  type,
  maps,
  map,
  events,
  title,
  status,
}) {
  const [marker, setMarker] = useState();
  useEffect(() => {
    // const styles = markerStyle(type);
    
    const marker = new maps.Marker({
      position,
      map,
      title,
      // animation: maps.Animation.DROP,
    });

    function toggleBounce() {
      if (marker.getAnimation() !== null) {
        marker.setAnimation(null);
      } else {
        marker.setAnimation(maps.Animation.DROP);
      }
    } 

    const getUGCImage = () => {
      let image = '/images/markers/status-possible.png';

      const statusImage = {
        covidPositive: '/images/markers/status-recovering.png',
        showingSymptoms: '/images/markers/status-showing-symptoms.png',
      };

      // console.log({type})
      if (type && type.covidPositive) {
        image = statusImage['covidPositive'];
      }

      if (type && type.showingSymptoms) {
        image = statusImage['showingSymptoms'];
      }
      // console.log(image)

      return image;
    }

    const getMarkerImage = () => {
      let image = '/images/status/status-self.png';

      const statusImage = {
        selfQuarantined: '/images/status/status-self.png',
        mandatoryQuarantine: '/images/status/status-mandatory.png',
        showingSymptoms: '/images/status/status-symptoms.png',
        covidPositive: '/images/status/status-recovering.png',
        freeShelter: '/images/treasure/free-shelter.svg',
        medicStation: '/images/treasure/medic-icon.png',
        freeFood: '/images/treasure/free-food.svg',
        requestHelp: '/images/treasure/request-help.svg',
        ride: '/svgs/ride-taxi.svg',
        
      };
      console.log({type})
      
      if (type && type.mandatoryQuarantine) {
        image = statusImage['mandatoryQuarantine'];
      }
      if (type && type.selfQuarantined) {
        image = statusImage['selfQuarantined'];
      }
      if (type && type.showingSymptoms) {
        image = statusImage['showingSymptoms'];
      }
      if (type && type.covidPositive) {
        image = statusImage['covidPositive'];
      }
      if (type && type.freeShelter) {
        image = statusImage['freeShelter'];
      }
      if (type && type.medicStation) {
        image = statusImage['medicStation'];
      }
      if (type && type.freeFood) {
        image = statusImage['freeFood'];
      }
      if (type && type.requestHelp) {
        image = statusImage['requestHelp'];
      }
 
      return image;
    }

    if ( type === 'covid') {
      marker.setIcon("/images/covid-confirmed.png");
    } else if ( type == 'warning') {
      marker.setIcon("medical-doctor.png");
    } else if ( type == 'warning-ugc') {
      marker.setIcon("warn-ugc-icon-a.png");
    } else if ( type && type.type === 'covid-ugc') {
      marker.setIcon({
        url: getUGCImage(),
        // size: new maps.Size(25, 25),
        // origin: new maps.Point(0, 0),
        // anchor: new maps.Point(17, 34),
        scaledSize: new maps.Size(140, 120),
      })
    } else if ( type === 'covid-yours') {
      marker.setIcon('/images/covid-yours.png');
    } else if ( type === 'tp') {
      marker.setIcon('/images/tp-icon-small-b.png');
    } else if ( type === 'lysolWypes') {
      marker.setIcon('/images/wypes-icon-small-b.png');
    } else if ( type === 'lysolSpray') {
      marker.setIcon('/images/lysol-icon-small-a.png');
    } else if ( type === 'yeast') {
      marker.setIcon('/images/yeast-icon-small-d.png');
    } else if ( type === 'batteries') {
      marker.setIcon('/images/treasure/batteries.png');
    } else if ( type === 'hopeMarker') {
      marker.setIcon('/images/markers/hope-marker.png');
    } else if ( type === 'waterBottle') {
      marker.setIcon('/images/treasure/water-bottle.png');
    } else if ( type === 'medicStation') {
      marker.setIcon('/images/treasure/medic-icon.svg');
    } else if ( type === 'protest') {
      marker.setIcon('/images/treasure/blackpower.png');
    } else if ( type === 'freeShelter') {
      marker.setIcon('/images/treasure/free-shelter.svg');
    } else if ( type === 'freeFood') {
      marker.setIcon('/images/treasure/free-food.svg');
    } else if ( type === 'fundsRequest') {
      marker.setIcon('/images/treasure/funds-request.svg');
    } else if ( type === 'safeList') {
      marker.setIcon('/images/treasure/safe-list.svg');
    } else if ( type === 'alertMarker') {
      marker.setIcon('/images/treasure/alert-marker.svg');
    } else if ( type === 'requestHelp') {
      marker.setIcon('/images/treasure/request-help.svg');
    } else if ( type === 'ride') {
      marker.setIcon('/svgs/ride-taxi.svg');
    } else {
      // console.log({type})
      marker.setIcon(getMarkerImage())
    }
    
    Object.keys(events).forEach(eventName =>
      marker.addListener(eventMapping[eventName], events[eventName])
    );
    setMarker(marker);
  }, []);

  return marker;
}
