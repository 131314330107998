import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import * as ui from '../../../../styles/uiVars';


const BannerContainer = styled.div`
  /* Add your styling for the banner container here */
  height: 56vh;
  display: flex;
  width: 100%;

  background: url("/images/dropkins/landing/top/light_mode.jpg") no-repeat;
  background-size: cover;
  /* background-position: top; */
  
  @media screen and (max-width: ${ui.tablet}) {
    height: 24vh;
  }
  `;

const BannerImage= styled.div`
  /* Add your styling for the banner container here */
  width: 100%;
  height: auto;

  `;
  
export const HomeLandingBanner = () => {
    useEffect(() => {
      // Add any necessary logic or side effects here
    }, []);
  
    return (
      <BannerContainer>
     
        {/* Add your banner image and any other content here */}
        {/* <BannerImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_001.jpg" /> */}
        {/* <BannerImage src="" /> */}
      </BannerContainer>
    );
  };

export default HomeLandingBanner;
