import React from 'react';
// Import css files
import styled from '@emotion/styled';
import { keyframes } from "@emotion/react";
import * as ui from '../uiVars';

const pulseAnimation = keyframes`
  0% {
    background-color: #d9e4f0;
  }
  50% {
    background-color: #c9d0ee;
  }
  100% {
    background-color: #daddf3;
  }
`;


export const DashboardPanel = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background: #09175d; */
  background: #09175d;
  justify-content: center;
  display: none;
  text-shadow: 1px 0px 1px #393030;

  background: url('/images/dropkins/ui/bg/content_bg_001.jpg') no-repeat;
  background-size: cover;
  ${({ toggle }) => (toggle && `
    display: flex;
  `)}
`;


export const InviteForm = styled.form`

    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 0.5em;
    border-radius: 8px;
    /* padding: 0.251em; */
    background: #09175d;
    background: linear-gradient(180deg, #2E2E75 0%, #000 100%);
`;
export const NotificationsForm = styled.form`

    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 0.5em;
    /* padding: 0.251em; */
    background: #09175d;
    background: linear-gradient(180deg, #2E2E75 0%, #000 100%);
`;

export const LoadingSpinner = styled.div`
  display: flex;
  /* spinner animation */
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }
  @-webkit-keyframes spinner {
    to {-webkit-transform: rotate(360deg);}
  }
  /* end spinner animation */
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #333;
    animation: spinner .6s linear infinite;
    -webkit-animation: spinner .6s linear infinite;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #09175d; */
  background: #09175d;
  height: 36px;
  display: none;
  ${({ loading }) => (loading && `
    display: flex;
  `)}
`;

export const InputWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0;
max-width: 80%;
`;
export const InputContainer = styled.div`
  align-items: center;
  
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0.5em;
  padding: 1em;
  margin-bottom: 1em;
  /* width: 50%; */
  /* align-self: center; */
  /* max-width: 600px; */
  width: 100%;

  /* @media screen and (max-width: ${ui.desktop}) { */

    max-width: 90%;
    flex-wrap: wrap;
    /* padding: 0.5em; */
    margin: 0.5em;
    gap: 10px;
  
  /* } */

  
  input, textarea {
    margin-top: 0.5em;
    /* padding: 1em; */
    background: #F2F1F1;
    border-radius: 2px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.871em;
    line-height: 3.1;
    padding: 0 0.75em;
    /* identical to box height */
    width: 100%;
    /* animation: ${pulseAnimation} 2s infinite; */
    
    color: #2d2b2b;
    color: #470c0c;
    color: #0c0e47;
    /* color: #edeeff; */
    
    background: #F2F1F1;
    background: #2bc8ce;
    background: #a7eaed;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 2px;

    border-radius: 8px;
    border: 1px solid #C4C4C4;
    background: #FBFBFB;
  }
  label {
    padding: 0.2em;
  }

  
`;

export const MainText = styled.div`
  font-size: 0.82em;
  font-family: "Inter", sans-serif;

`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const MainContentContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
text-align: left;
background: black;
padding: 0.6em;
border-radius: 8px;

`;

export const FullWidthContainer = styled.div`
  display: flex;
  width: 100%;
  /* padding: 2em; */
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0%;
    
  }
`;