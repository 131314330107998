import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import * as ui from "../../../styles/uiVars";

const ScrollContainer = styled.div`    
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  /* overscroll-behavior: contain; */
   /* New */

  /* For Chrome, Safari, and Opera */
  &::-webkit-scrollbar {
    display: none;
  }


    
  
  @media screen and (max-width: ${ui.mobile}){
    margin-right: 0;
  }
  `;

export const ScrollWrapper = styled.div`
    margin: 0 1em;
    padding: 0 2em;
    margin-bottom: 1.6em;
    &.scrolling {
      border-bottom: 1px solid #000; /* Adjust as needed */
    }

    @media screen and (max-width: ${ui.mobile}){
      margin: 0 0.5em;
      padding: 0 0em;
    }
  `;

const HorizontalScrollingDiv = ({ children, width = '300px', height = 'auto' }) => {
  const [selectedItem, setSelectedItem] = React.useState(null);
  const itemRefs = useRef([]);
  const scrollToRef = useRef(null); 

  const handleItemClick = (event, index) => {
    setSelectedItem(index);
  
    // Get the bounding rectangles of the container and the item
    const containerRect = scrollToRef.current.getBoundingClientRect();
    const itemRect = itemRefs.current[index].getBoundingClientRect();
  
    // Calculate the scroll position
    const scrollLeft = itemRect.left - containerRect.left + scrollToRef.current.scrollLeft;
  
    // Scroll the container
    scrollToRef.current.scrollTo({ left: scrollLeft, behavior: 'smooth' });
  };

  return (
    <ScrollContainer ref={scrollToRef} style={{ display: 'flex',
        overflowX: 'auto', scrollSnapType: 'x mandatory',
        scrollbarWidth: 'none', /* For Firefox */
        msOverflowStyle: 'none', /* For Internet Explorer and Edge */
      }}>
      <ScrollWrapper style={{ display: 'flex', justifyContent: 'center', 
        scrollSnapAlign: 'center',
        scrollbarWidth: 'none', /* For Firefox */
        msOverflowStyle: 'none', /* For Internet Explorer and Edge */
      }}>
        {React.Children.map(children, (child, index) => (
          <div
            ref={(el) => (itemRefs.current[index] = el)}
            style={{
              width: width,
              height: height,
              display: 'flex',
            }}
            onClick={(e) => handleItemClick(e, index)}
          >
            {child}
          </div>
        ))}
      </ScrollWrapper>
    </ScrollContainer>
  );
};

export default HorizontalScrollingDiv;