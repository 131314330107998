import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import app from 'firebase/compat/app';
import 'firebase/firestore';
import { GoogleAuthProvider, getAuth, EmailAuthProvider } from 'firebase/auth';
// import { getStripePayments } from "@invertase/firestore-stripe-payments";
import { getStripePayments,  } from "@stripe/firestore-stripe-payments";
import { getFunctions } from 'firebase/functions';
import { httpsCallable } from "@firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
// const captchaKey = process.env.REACT_APP_CAPTCHA_PUBLIC_KEY;

const prodFirebaseConfig = {
  databaseURL: "https://kin-drops-default-rtdb.firebaseio.com",
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const activeConfig = process.env.NODE_ENV === 'production' ? prodFirebaseConfig : prodFirebaseConfig;
export const initApp = initializeApp(activeConfig);
export const providers = {
  'emailProvider': new EmailAuthProvider(),
  'googleProvider': new GoogleAuthProvider(),

}
// console.log('initApp', captchaKey);

try {
  if (!process.env.REACT_APP_CAPTCHA_PUBLIC_KEY) {
    throw new Error('reCAPTCHA key is missing');
  }
  // console.log('App Check can be initialized successfully');
} catch (error) {
  // console.error('Failed to initialize App Check:', error);
}

export const appCheck = initializeAppCheck(initApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_CAPTCHA_PUBLIC_KEY),
  isTokenAutoRefreshEnabled: true
});
export const kindAuth = getAuth(initApp);
export const appFirebase = initApp;
export const messaging = getMessaging(appFirebase);



// Register service worker
// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//       .then((registration) => {
//         console.log('ServiceWorker registration successful:', registration);
//       })
//       .catch((error) => {
//         console.log('ServiceWorker registration failed:', error);
//       });
//   });
// }
// export const messagingToken = getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
// // export const messagingToken = getToken(messaging)
// .then((currentToken) => {
//   if (currentToken) {
//     // Send the token to your server and update the UI if necessary
//   } else {
//     console.log('No registration token available. Request permission to generate one.');
//     // Show a message to the user to request notification permissions
//   }
// })
// .catch((err) => {
//   if (err.code === 'messaging/permission-blocked') {
//     console.log("Permission wasn't granted. Allow notifications from the settings of your browser.");
//     // Show a message to the user explaining how to enable notifications
//   } else {
//     console.log('An error occurred while retrieving token. ', err);
//     // Handle other errors
//   }
// }); //, { vapidKey: process.env.REACT_APP_VAPID_KEY });
export const kinDb = getFirestore(appFirebase);
export const db = kinDb;
export const googleProvider = new GoogleAuthProvider();
// export const gKey = 'AIzaSyAalCGPrquwmaUguvk6nGaAIvPqfoXLB7E';
export const gKey = process.env.REACT_APP_GOOGLE_GEOCODE_KEY;
export const analytics = getAnalytics(initApp);

// Initialize Cloud Functions
export const cloudFunctions = getFunctions(appFirebase);


// try {
//   const saveToken = httpsCallable(cloudFunctions, "saveToken");
//   messaging.onTokenRefresh(() => {
//     messaging.getToken()
//       .then((refreshedToken) => {
//         console.log('Refreshed FCM token:', refreshedToken);
//         // Send the new token to your server to save it.
// // Call the function with the token and uid
// saveToken({ token: 'refreshedToken', uid: 'your_uid' })
//   .then((result) => {
//     // Handle the result
//     console.log(result.data);
//   })
//   .catch((error) => {
//     // Handle any errors
//     console.error(error);
//   });

//       })
//       .catch((err) => {
//         console.log('Unable to retrieve refreshed token ', err);
//       });
//   });

// } catch (error) {
//   // console.error('Failed to initialize App Check:', error);
// }


// export const payments = getStripePayments(initApp, {
//   productsCollection: "products",
//   customersCollection: "customers",
// });

// getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY}).then((currentToken) => {;
