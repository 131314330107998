import styled from '@emotion/styled';
import * as ui from './uiVars';

export const SelectInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ContentHeader = styled.div`
  /* add text shadow */
  text-shadow: 1px 2px 3px rgba(15, 8, 57, 0.402);
  display: flex;
  font-family: 'Inter', sans-serif;
  font-size: 1em;
  font-weight: bold;
  padding: 0.5em;
  max-width: 200px;
  margin-bottom: 0.5em;
`;

export const TreasureInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  border-radius: 0.25em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid #c7c9d9;
`;

export const MainNavText = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin: 0.5em;
  color: #c7c9d9; 
`;

export const TreasureLabel = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin: 0.5em;
  color: #c7c9d9; 
`;

export const ShareFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 500px; */
  border-radius: 0.25em;
  margin: 0.5em;
  padding: 0.5em;
  border: 1px solid #c7c9d9;
  `;

  export const ShareFormHeader = styled.div`
  font-size: 1em;
  font-weight: bold;
  margin: 0.5em;
  color: #c7c9d9; 
  `;

  export const ShareFormSubHeader = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  margin: 0.5em;
  color: #c7c9d9; 
  `;

  export const ShareFormHeaderText = styled.div`
  font-size: 0.8em;
  /* font-weight: bold; */
  margin: 0.5em;
  color: #c7c9d9; 
  `;
  export const ShareFormSubHeaderText = styled.div`
  font-size: 0.65em;
  /* font-weight: bold; */
  margin: 0.5em;
  color: #c7c9d9; 
  `;

  export const ChatButton = styled.div` 
  font-size: 0.75em;
  font-weight: bold;
  margin: 0.5em;
  padding: 0.5em;
  text-align: center;
  background: #2254b8ea;
  max-width: 100px;
  border-radius: 8px;
  color: #c7c9d9;
  cursor: pointer; 
  &:hover {
    background: #818ff1;
    color: black;
  }
  `;


export const CancelRideBtn = styled.div`
  font-size: 0.75em;
  font-weight: bold;
  position: absolute;
  right: 20px;
  font-size: 0.75em;
  font-weight: bold;
  margin: 0.5em;
  padding: 0.5em;
  text-align: center;
  background: #4f3560ea;
  max-width: 100px;
  border-radius: 8px;
  color: #c7c9d9;
  cursor: pointer; 
  &:hover {
    background: #818ff1;
    color: black;
  }
  `;
  
  
  export const EndRideBtn = styled(CancelRideBtn)`
    background: #752424;

  `;

export const ContentBox = styled.div`
background: #343f6ccd;
background: #1c2e73ac;
border: 1px solid #7585fc;
border-radius: 2px;
padding: 1em;
max-width: 600px;
text-align: left;
/* font-family: Arial, Helvetica, sans-serif; */
position: relative;
margin: 0.5em 0;
/* margin-left: 15px; */
      /* #every other one { */
  &:nth-of-type(even) {
    background: #141726;
    background: #3c498d;
    scale: 0.97;
  }

  /* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

  &.typewriter {
     animation: 
    typing 2.5s steps(40, end);
}

`;

export const GlowBox = styled.div`
  background: hsl(237.6, 39.682539682539684%, 24.705882352941178%);
  background: #071439ea;
  padding: 16px 24px;
  padding: 6px 20px;
  /* border: 1px solid #5063f1; */
  position: relative;
  border-radius: 8px;
  /* margin: 0.5em; */
  box-shadow: 0 0 0 1px rgba(0,0,0,.01);
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #818ef18d;
    background: #00094d8d;
    background: #1498af8d;
    background: #0c5d6c8d;
    background: #1638c18d;
    /* color: black; */
  }

  &::after {
    position: absolute;
    content: "";
    top: 5px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }

  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.2em;
    padding: 4px 10px;
  }

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
`;


