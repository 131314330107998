import { useEffect } from "react";
import useGoogleMapMarker from '../hooks/useGoogleMapMarker';

const activeIcon =
  "https://a0.muscache.com/airbnb/static/select_pdp/home_icon-9999d1852c239e9a93c7d7975441c254.png";
// const inactiveIcon =
//   "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png";

const inactiveIcon = "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png";

export default function Marker({
  position,
  type,
  maps,
  map,
  events,
  active = false,
  resetMarker,
  resetWarningMarker,
  title,
  resetContagionMarker,
  resetDriverMarker,
}) {
  const marker = useGoogleMapMarker({
    position,
    type,
    maps,
    map,
    events,
    title
  });

  // useEffect(
  //   () => {
  //     marker &&
  //       (active ? marker.setIcon(activeIcon) : marker.setIcon(inactiveIcon));
  //   },
  //   [active]
  // );

  useEffect(
    () => {
      if (resetMarker) {
        // console.log('trying to reset', resetMarker)
        const {id, status} = resetMarker;
        // console.log("marker id: "+ status + id)
        if ( status !== 'delete') {
          if(marker && marker.title === "marker id: "+ status + id) {
            marker?.setMap(map);
          }
          if(marker && marker?.title !== "marker id: "+ status + id) {
            marker?.setMap(null);
          }
        } else {
          const checkin = marker && marker.title && marker.title.includes(id);
          // console.log(checkin)
          if(marker && checkin) {
            marker?.setMap(null);
          }
        }

        // marker && console.log( marker.title);
      }
    }, [resetMarker]
  );

  useEffect(
    () => {
      
      if(marker && marker.title === "contagion: " + resetContagionMarker) {
        marker?.setMap(null);
      }
    }, [resetContagionMarker]
  );

  useEffect(
    () => {
      
      // console.log('resetdriver', {resetDriverMarker}, {marker})
      if(marker && resetDriverMarker) {
        marker?.setMap(null);
      }
    }, [resetDriverMarker]
  );

  
  useEffect(() => {
      if(marker && marker.title === "marker id: " + resetWarningMarker) {
        marker?.setMap(null);
      }
      // marker && console.log( marker.title);
    }, [resetWarningMarker]
  );

  return null;
}
