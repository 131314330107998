import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
// import { kinDb } from '../firebase-config';
import { collection, doc, getDocs,deleteDoc, onSnapshot, query, updateDoc, where, addDoc, setDoc } from 'firebase/firestore';
// import { useForm } from 'react-hook-form';
import { db, kinDb } from '../firebase-config';
import firebase, { fireAuth, getUserId, 
  deleteCheckIn,
  getWarningMarkersSnap,
  getWarningMarkersUGCSnap
 } from '../firebase';

const disabled = false;
const WarningsLayoutContainer = styled.div`
  position: absolute;
  right:4em;
  bottom:2em;
  z-index:2;
  width: 30vw;
  min-width: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  

  ${({ toggle }) => toggle && `
    display: none;
  `}
`;

const WaringsContainer = styled.div`
  display: flex; 
  padding:0.5em;
  
  flex-wrap: wrap;
  color: white;
  background: rgba(0,0,0,0.6);
  ${({ toggle }) => toggle && `
    display: none;
  `}
`;

const WarningHeader = styled.div`
  text-align:center;
  font-size: 1.3em;
  font-weight: 700;
`;

const WarningSubHeader = styled.div`
display: flex; 
padding: 0.25em;
  
`;

const WaringsContainerToggle = styled.div`
  display: flex;
    z-index: 2;
    background: rgba(0,0,0,0.6);
    width: 7em;
    padding:0.25em;
`;
const WarnList = styled.li`
  display:flex;
  padding:0.25em;
  color: #ffb9b9;
`;

const WarnListUGC = styled(WarnList)`
  color: #37d0ff;
`;



export const WarningsPanel = ({currentWarnings, setCurrentWarnings, currentWarningsUGC, setCurrentWarningsUGC}) => {

  const [warningsPanelToggle, toggleWarningsPanel] = useState(true);
  
  useEffect(() => {

    // const unsubscribe = getWarningMarkersSnap({setCurrentWarnings});
    // return ()=> unsubscribe;
    
  }, [disabled]);

  useEffect(() => {
    // const unsubscribe = getWarningMarkersUGCSnap({setCurrentWarningsUGC})
    // return ()=> unsubscribe;
    
  }, [disabled]);

  useEffect(() => {
    toggleWarningsPanel(!currentWarningsLength)
  }, [currentWarnings]);

  useEffect(() => {
    toggleWarningsPanel(!currentWarningsUGCLength)
  }, [currentWarningsUGC]);

  const toggleWarnings = (warnings) => {
    return warnings && warnings.length ? true : false;
  };

  const checkWarningLengths = () => {
    // console.log(!currentWarningsLength , !currentWarningsUGCLength)
    return !currentWarningsLength || !currentWarningsUGCLength;
  }

  const currentWarningsLength = currentWarnings && currentWarnings.length;
  const currentWarningsUGCLength = currentWarningsUGC && currentWarningsUGC.length;
return(
  <WarningsLayoutContainer toggle={!checkWarningLengths()}>
    {/* <WaringsContainerToggle onClick={() => toggleWarningsPanel(!warningsPanelToggle)}> Toggle Warnings</WaringsContainerToggle> */}
    <WaringsContainer toggle={warningsPanelToggle}>
      <WarningHeader> 14 Day Chance of exposure </WarningHeader>
      <WarningSubHeader> 
          We've detected {currentWarningsLength} verified location/s you may have 
          crossed path with a contagion. 
      </WarningSubHeader>
      <WarningSubHeader> 
          {currentWarningsUGC && `
            We have detect ${currentWarningsUGCLength} user report locations you've shared.
          `}
      </WarningSubHeader>
      
      
      <ul>
      {currentWarnings.map((warn, index) => {
        return (<WarnList>{warn.address}</WarnList>)
      })}
      {currentWarningsUGC.map((warn, index) => {
        return (<WarnListUGC>{warn.address}</WarnListUGC>)
      })}
      </ul>

    </WaringsContainer>
  </WarningsLayoutContainer>
)


}

export default WarningsPanel;