import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { withRouter } from '../utils/withRouter';
import { AuthContext } from '../Auth';
import { httpsCallable } from "@firebase/functions";
import {
  kinDb,
  appFirebase,
  providers,
  db,
  kindAuth,
  messaging,
  cloudFunctions,
} from "../firebase-config";
import styled from '@emotion/styled';

export const SubscriptionSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  background: black;
  
  background: url('/images/dropkins/ui/bg/content_bg_001.jpg') no-repeat;
  background-size: cover;
  margin-top: 2em;
`;

export const SubscriptionSuccessImg = styled.img`
  width: 40vw;
  max-width: 100%;
  margin: 1em;
  padding: 1em;
  border-radius: 18px;

`;

export const SubHeader = styled.h1`
  font-weight: 800;
  font-size: 2em;
  font-family: 'Roboto', sans-serif;
  color: white;
  background: #123554bd;
  padding: 1em;
  position: absolute;

`;

export const SubContinueBtn = styled.div`
position: absolute;
margin: auto;
padding: 1em;
font-weight: 800;
background: #315f88;
`

const SubscriptionSuccess = () => {
  const location = useLocation();
  const {
    customerData,
    setNotification,
    setFiestaMessage,
  } = useContext(AuthContext);

  useEffect(() => {
    if(!customerData?.userId) return;
    const urlParams = new URLSearchParams(location.search);
    const sessionId = urlParams?.get('session_id');

    // confirm checkout succeed with session id
    const confirmCheckout = httpsCallable(cloudFunctions, "confirmCheckout");
    confirmCheckout({ sessionId, userId: customerData?.userId }).catch(error => {
      console.error(`Failed to confirm checkout: ${error.message}`);
    });

    // Now you can use the session ID
    // console.log(sessionId);
  }, [location]);

  setTimeout(() => {
    window.location.href = '/';
  }, 4000);

  return (
    <SubscriptionSuccessWrapper>
      <SubHeader>Lets get you back.
      <SubContinueBtn onClick={() => {
        window.location.href = '/';
      }}>
        Continue
      </SubContinueBtn>
      </SubHeader>
      <SubscriptionSuccessImg src={"/images/dropkins/ui/success/dropkins_flag_v001.jpg"} />
    </SubscriptionSuccessWrapper>
  );
};

export default withRouter(SubscriptionSuccess);