import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from '@emotion/styled';

import axios from "axios";
import { set, useForm } from "react-hook-form";
import { httpsCallable } from "@firebase/functions";
import {
  CardElement,
  // Elements,
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { AuthContext } from "../../../Auth";
import { kinDb, gKey, cloudFunctions, kindAuth } from "../../../firebase-config";

import { reverseGeoCode } from '../../../hooks/useMapDirections';
import SubscriptionGateBtn from "../../UI/stripe/SubscriptionGate";




import {
  fireAuth,
  writeMarker,
  getUserId,
  deleteCheckIn,
  addOrRemoveFriend,
  warningMarkersSnap,
  getUserName,
  updateUsername,
  db,
  addFriend,
  acceptFriend,
  updateFriends,
  getFriends,
  sendChatMessage,
  loadChatMessages,
  activateDriver,
  toggleActiveRideRequest,
  activateDriverMode,
  writeToActiveRideRequest,
  setRideIntent,
  deleteFriend,
  GetRideEstimate,
  SetupIntentInterface,
} from "../../../firebase";

import {
  PassengerCountContainer,
  PassengerCountBtnContent,
  PassengerCountBtn,
  // UserNameText,
  // SelectInputContainer,
  GlowBox,
  ActiveContentText,
  ActiveContentHeader,
  ActivePanelButton,
  ActiveContentContainer,
  // SubscriptionGateSubHeader,
  
  

} from '../../../styles'

import {
  LinkBtn,
  LinkHrefBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  LoadingContainer,
  LoadingSpinner,
} from "../../../styles/shared";




// Styled Bellow

import * as ui from "../../../styles/uiVars";

 const RefreshUiBtn = styled.div`
  position: absolute;
  right: 0;
  font-size: 0.68em;
  margin: 0.2em;
  cursor: pointer;
  top: 0;
  padding: 0.25em;
  border: 1px solid #5b6ab4;
  background: #0b1857;
`;


export const SubscriptionGateImg = styled.img`
  height: 250px;
  width: 250px;
  margin: 0.5em;
`;

const SubscriptionGateText = styled.div`

  font-size: 1.5em;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #fff;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;


const SubscriptionGateHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;

const SubscriptionGateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;


const SubscriptionGate = styled.div`
  position: absolute;
  top: 0;
  height: 400px;
  min-height: 400px;
  width: 300px;
  /* background: #fff; */
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }

  ${({ toggle }) => !toggle && `
      display: none;
  `}
`;

const RideCostEstimate = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  align-items: center;
  margin: 0.15em;

   ${({ toggle }) => toggle && `
      display: none;
  `}

`;


const SetDonationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25em;
  align-items: center;
  text-align:left;
`;


const SearchCopy = styled.div`
  margin-top: 0.51em;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.875em;
  line-height: 1.4;
  /* word-break: break-all; */
  /* background: #f1f5f5a7; */
  background: #1b4251bb;
  background: #094e9cba;
  padding: 0.525em;
  /* add dropshadow to text */
  
  /* box-shadow: 0 0 0.5em #000; */
  text-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.75);
  /* dropshadow: 0 0 0.5em #000; */
    /* width: 100%; */
    max-width: 502px;
  /* or 17px */
  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
font-weight: 600;
  color: #DACFF7;
  color: #fff;
`;

const SetDonationInput = styled.input`

  background: #F1F5F5;
  border-radius: 2px;
  color: #03373b;
  padding: 0.5em;
  margin-top: 0.5em;
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 0.91em;
  line-height: 2.1;
  /* identical to box height */
  width: 100%;
  max-width: 95%;

  ${({ toggle }) => !toggle && `
      display: none;

  `}
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  `;

const UseCurrentText = styled.div`
  font-size: 0.76708em;
  text-transform: uppercase;
  line-height: 1.23;
  font-weight: 600;
  text-align: left;
  padding-bottom: 4px;
  font-family: "Inter", sans-serif, Helvetica, sans-serif;

  /* target strong */
  strong {
    font-weight: 800;
    font-size: 1.196708em;
  }

  /* target hover */

  &:hover {
    color: #bff377;
  }

  ${({ toggle }) => toggle && `
      display: none;
  `}
`;

const UseCurrentBtn = styled.div`
  height: 1.33em;
  width: 1.33em;
  background: #F1F5F5;
  border-radius: 2px;
  margin-right: 1.33em;

  img {
      display: none;
    }

    ${({ clearMargin }) => clearMargin && `
      margin-right: 0.35em;
      margin-left: 0.15em;
    `}

  ${({ useCurrent }) => useCurrent && `
    img {
      display: inherit;
    }
  `}

`;

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5em;
  border: 1px solid #6775BF;
  padding: 0.25em;
  cursor: pointer;
  margin: 0.1em;
 

  ${({ active }) => active && `
    background: #4F5CA0;
  `
  }
  ${({ toggle }) => toggle && `
    display: none;
  `
  }


  /* margin-left: 0.25em; */
`;

const ClearInputBtn = styled.div`
  position: absolute;
  align-self: flex-end;
  right: 0.88em;
  margin-top: 0.975em;
  
  cursor: pointer;
`;

const Prediction = styled.div`
  /* width: 8em; */
  margin: auto; 
  padding: 1em 0.75em;
  margin-top: 0.25em;
  border-bottom: 1px solid #5C6CBD;
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 1.2;
  max-width: 25em;
/* identical to box height */

  text-align: left;

  color: #FFFFFF;

  width: 80%;
  cursor: pointer;


  &:hover {
    background: rgba(0,0,0,0.6);
  }
`;

const LocationAutoComplete = styled.div`
display:flex;
flex-direction: column;

`;


export const Location = styled.input`
  background: #0e1d23;
  border-radius: 2px;
  
  padding-left: 1em;
  height: 3em;
  max-width: 94%;
  padding-right: 2.05em;
  color: #d5f2fb;
  min-width: 225px;
  &:nth-of-type(1) input:-webkit-autofill {
    background-color: #0e1d23 !important;
    color: #ffffff !important;
}

  &::-webkit-input-placeholder {
  font-weight: bold;
  color: #d5f2fb;
  }

  &::placeholder {
  font-weight: bold;
  color: #d5f2fb;
  }
`;

 const LocationInputContainer = styled.div`
  display:flex;
  position: relative;
  justify-content: flex-start;
  flex-direction: column;
  margin: 0.5em 0;
  width: 100%;
  position: relative;
    margin: 0.22sem auto;
    /* max-width: 312px; */

    align-content: center;

    @media screen and (max-width: ${ui.tablet}) {
      max-width: 80%;
    }
`;


const OutlineContainer = styled.div`
  border: 1px solid #6775BF;
  border: 1px solid #465fe8;
  gap: 5px;
  border-radius: 2px;
  padding: 0.35em;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  background: #1b5151bc;
  background: #122f81bc;
  position: relative;

  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;

  @media screen and (max-width: ${ui.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 10px;
    
  }

  .card-element {
    width: 100%;
  }
  /* justify-content: center; */
  /* align-items: center; */
  ${({ toggle }) => toggle && `
      display: none;
  `}
`;



const OutlineColumnContainer = styled(OutlineContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
   ${({ toggle }) => toggle && `
      display: none;
  ` }
`;

const RidesPanelWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #121212 10.7%, rgba(0, 0, 0, 0.00) 59.45%), #2E2E75;
  background: linear-gradient(180deg, #121212 10.7%, rgba(0, 0, 0, 0.00) 59.45%), #121251;
  position: relative;
  display: flex;
  max-height: 88%;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
  padding-top: 6em;
  overflow-y: auto;
  
  padding-bottom: 4em;
  
  @media screen and (max-width: ${ui.tablet}) {
      /* bottom: 2em; */
      
    }
  
`;


const ErrorField = styled.div`
  font-size: 13px;
  color: #da1e1e;
  color: #fed3c5;
  font-weight: 600;
  margin-top: 0.2em;
  line-height: 1.1;
  display: flex;
  max-width: 250px;
  padding: 0.25em 0.25em;
  text-shadow: 2px 6px 2px #000000;
  /* margin: 0.5em auto; */
`;


const RidesPanelContainer = styled.div`
    position: relative;
    max-width: 96%;
    z-index: 13;


    /* overflow-y: scroll; */
    /* justify-self: center; */

    /* Styles for Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background: #454e91; /* Color of the scroll thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #26365a; /* Color of the scroll thumb on hover */
    }

    /* Styles for IE and Edge */
    scrollbar-width: thin;
    scrollbar-color: #4a517f #f1f1f1;

    ${({ toggle }) => !toggle && `
      display: none;
    
    `}
      


`;

const RidesPanelSubHeader = styled.div`
  color: #FFF;
text-align: center;
font-feature-settings: 'clig' off, 'liga' off;

/* Subtitle 1 */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 1.5; /* 150% */
letter-spacing: 0.15px;

`;
const RidesPanelHeader = styled.div`
  display: flex;
  color: #FFF;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  justify-content: center;
  /* Headline/H4 */
  font-family: Roboto;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 117.647% */
  letter-spacing: 0.25px;
`;

const RidesPanelImage = styled.img`
  width: 222px;
  transition: all 0.5s ease-in-out;

    ${({ toggle }) => toggle && `
      width: 100px;
    
    `}
  @media screen and (max-width: 768px) {
    width: 150px;
    ${({ toggle }) => toggle && `
      width: 60px;
    
    `}
    
  }

`;

// const InputContainer = styled.div`
//   padding: 20px;
//   max-width: 400px;
//   display: flex;
//   flex-direction: column;
// `;

const Label = styled.label`
  font-size: 16px;
  margin-bottom: 10px;
  color: #FDFDFD;

  /* material-theme/body/small */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
`;

// const Input = styled.input`
//   padding: 10px;
//   margin-bottom: 20px;
// border-radius: 8px;
// border: 1px solid #C4C4C4;
// background: #FBFBFB;
// width: 300px;

// `;

// const Button = styled.div`
//   padding: 10px;
//   margin: 0.25em;
// `;

// const BackButton = styled.div`
//   padding: 10px;
//   margin-bottom: 20px;
//   margin: 0.25em;
// `;


export const RideForm = styled.form`


`;

const PanelContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: 400px;
  scroll-snap-type: x mandatory; // Add this line
  /* margin-top: 1.5em; */
  margin-top: 0.5em;
  /* padding-bottom: 2.6em; */
  padding-bottom: 0.6em;
  /* max-height: 43vh; */
  /* max-height: 35vh; */
  max-height: 41vh;
  overflow-y: auto;

  /* Style the scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #1d2390;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #301ce7;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  
`;

const Panel = styled.div`
  flex: none;
  width: 400px;
  scroll-snap-align: start; // Add this line
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
  overflow-y: auto; // Add this line
  
  
  ${({ shrink }) => shrink && `
      // scale: 0.9;
      // max-height: 100px;
  `}

  @media screen and (max-width: ${ui.tablet}) {
    width: 100%;
    
  }
  
`;

const SvgContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  
  @media screen and (max-width: ${ui.tablet}){
    bottom: 0.61em
  }
`;
const CloseButton = styled.button`
  position: absolute;
  top: 1.5em;
  color: white;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  @media screen and (max-width: ${ui.tablet}) {
    /* top: 3em; */
    
  }
`;

const RideInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  max-width: 300px;

`;

 const SearchSubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  /* max-width: 112px; */
  /* width: 100%; */
    /* margin: auto; */
    /* margin-bottom: 1em; */

    ${({ toggle }) => !toggle && `
      display: none;
    `}

`;

const SubmitCheckIn = styled.div`
  /* padding: 0.64em; */
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 1.5;
  /* identical to box height */
  text-align: center;

  width: 100%;
  
  color: #FFF;
  /* background: #FF9300; */
  border-radius: 2px;
  ${({ active }) => active && `
    cursor: pointer;
    
  `}
`;

const PanelsButton = styled.div`
  display: flex;
  position: relative;
  flex-direction: row; /* Align buttons vertically */
  gap: 10px; /* Add space between buttons */
  justify-content: center;
  margin-top: 2em;
`;

const Button = styled.button`
  background: #f1f1f1; /* Background color */
  border: 1px solid #888; /* Border */
  padding: 10px; /* Padding */
  border-radius: 10px;
  /* background: #59E5D5; */
  background: #7dede0;
  cursor: pointer; /* Cursor style */
  flex-basis: 30%; /* Set the width of the buttons */
  color: var(--material-theme-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 1em;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  
  &:hover {
    background: #888; /* Background color on hover */
    color: #fff; /* Text color on hover */
  }
`;

const BackButton = styled(Button)`
  /* Additional styles for BackButton if needed */
  
`;

// const NextButton = styled(Button)`
//   /* Additional styles for NextButton if needed */
// `;


const RideInput = styled(Location)`
  padding: 10px;
  margin-bottom: 0.5em;
  border-radius: 8px;
  border: 1px solid #C4C4C4;
  background: #FBFBFB;
  color: #000;
  width: 300px;
   /* Set placeholder color */
   ::placeholder {
    color: #333; /* Dark color */
  }
`;
























// Component
// ***********************************************




export const RidesPanel = ({handleClose, maps}) => {


  const [step, setStep] = useState(1);
  const [destination, setDestination] = useState('');
  const [pickup, setPickup] = useState('');
  const [time, setTime] = useState('');
  const [payment, setPayment] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [dropPredictions, setDropPredictions] = useState([]);
  const [predictionsToggle, setPredictionsToggle] = useState(false);
  const [predictionsDropToggle, setPredictionsDropToggle] = useState(false);
  const [useCurrent, setUseCurrent] = useState(false);
  const [emptyResponseCounter, setEmptyResponseCounter] = useState(0);


  const [isCardEmpty, setIsCardEmpty] = useState(true);

  const [stepCleared, setStepCleared] = useState(false);

  
  const stripe = useStripe();
  const elements = useElements();

  const handleCardDetailsChange = (event) => {
    setIsCardEmpty(event.empty);
  };
  
  // Call this function when a step is cleared
  const handleStepCleared = (clearState) => {
    setStepCleared(clearState);
  };

  const {
    notification,
    setNotification,
    fiestaMessage,
    setFiestaMessage,
    setDropLocation,
    setPickupLocation,
    setTriggerShow,
    rideEstimate,
    setRideEstimate,
    rideDistanceEstimate,
    setRideDistanceEstimate,
    position,
    setPosition,
    setStartLocation,
    setLastLocation,
    lastLocation,
    dropLocation,
    setDropLocationInput,
    setPickupLocationInput,
    passengerCount,
    setPassengerCount,
    userData,
    setUserData,
    setDriverMode,
    setResetDriverMarker,
    setClearTogglesActive,
    setTriggerLocationGrab,
    covid,
    setRidePanelToggle,
    setThankYouToggle,
    setSearchPanelToggle,
    freeRideToggle,
    setFreeRideToggle,
    setLoading,
    setLatestMarker,
    triggerLocationGrab,
    setOnCancel,
    toggleConfirmationModal,
    setToggleConfirmationModal,
    confirmationModalText,
    setConfirmationModalText,
    confirmationAlertText,
    setConfirmationAlertText,
    confirmationHeaderText,
    setConfirmationHeaderText,
    termsToggle,
    setTermsToggle,
    termsPanelToggle,
    setTermsPanelToggle,
    profileImage,
    setProfileImage,
    loading,
    setupIntent,
    setSetupIntent,
    clientSecret,
    setClientSecret,
    userEmail,
    setUserEmail,
    onConfirm,
    setOnConfirm,
    onCancel,
    inviteCode,
    setInviteCode,
    userId,
    stripeAccountStatus,
    setStripeAccountStatus,
    customerData,
    paymentMethodId,
    setPaymentMethodId,
    setPanel,
    latestMarker,
    
    openPanel, setOpenPanel,
    setPanelTrigger,
    panelTriggerCount, setPanelTriggerCount,
    freeRides,
    setMessageToken,

  } = useContext(AuthContext);

  

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    clearError,
    setError,
    setValue,
    trigger,
    clearErrors,
  } = useForm();

  const autocomplete = useRef();

  if (!autocomplete.current) {
    // autocomplete.current = new window.google.maps.places.AutocompleteService();
    autocomplete.current = maps?.places?.AutocompleteService
      ? new window.google.maps.places.AutocompleteService()
      : null;
  }



  
  const watchedAmount = watch("amount");
  // Watch the value of 'setDropLocation' input
  const watchedDropLocation = watch("setDropLocation");
  // Register the input and watch its value
  const watchedLocation = watch("setPickupInput");


  const watchedPredictionInputSelected = watch("predictionInputSelected");
  const watchedDropPredictionInputSelected = watch(
    "predictionDropInputSelected"
  );



  const checkCardRequirements = () => {
    const paymentRequired =
      freeRideToggle === "paid" || freeRideToggle === "donation" ? true : false;
    if (!isCardEmpty || !paymentRequired) return true;
    if (isCardEmpty && paymentRequired) return false;
    return false;
  };


  // const resetNotifications = async () => {
  //   setNotification(null);
  //   setFiestaMessage(null);
  // };
  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };






  
  const RIDE_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        color: "#2f3566",
        margin: "0.5em",
        backgroundColor: "#fff",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };




  // ******************************************************
  
  // Translates to lat long and writes to firestore
  
  // ******************************************************


////

  //
  // Reverse GeoCode Prediction

  ////
  const getGeoCode = async (write = false) => {
    const key = gKey;

    if (!kindAuth.currentUser) return;

    try {
      // setLoading(true);
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
          watchedLocation
        )}&key=${key}`
      );
      const result = res.data.results[0].geometry.location;
      // console.log(watchedLocation, result.lat, result.lng);

      if (result.lat !== null && result.lng !== null) {
        await setDestination(watchedDropLocation);
        await setLatestMarker({ latitude: result.lat, longitude: result.lng });
        // await setStartLocation({lat: result.lat, lng: result.lng})
        await setStartLocation(watchedLocation);
        await setPosition({ latitude: result.lat, longitude: result.lng });
        // await setSearchPanelToggle(false);
        await setOpenPanel(null);
        
        await writeToActiveRideRequest(
          watchedLocation,
          watchedDropLocation,
          userData,
          setUserData,
          freeRideToggle,
          passengerCount,
          rideEstimate,
          rideDistanceEstimate,
          notifier,
          fiesta,
          setTriggerShow,
          setLoading,
          setMessageToken,
        );
        await activateDriverMode(
          false,
          setDriverMode,
          setResetDriverMarker,
          notifier,
          fiesta,
          triggerLocationGrab,
          setMessageToken,
        );

        // clearInput();
        // await setThankYouToggle(true);

        await setRidePanelToggle(true);
        // write &&
        //   (await writeMarker(
        //     location,
        //     result.lng,
        //     result.lat,
        //     covid,
        //     "marker"
        //   ));
        await toggleActiveRideRequest(
          true,
          userData,
          setUserData,
          setClearTogglesActive,
          setTriggerShow,
          setLoading,
          notifier,
          fiesta
        );
      } else {
        // updateError(true);
        // console.log("No results found");
        notifier("Please check address and retry.");
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      // console.log(error);
      notifier("Please enter a valid address");
      // updateError(true);
    }
  };








  // ******************************************************
  
  // Submit function to process the ride request
  
  // ******************************************************



  const submitRideRequest = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    if (!cardElement && freeRideToggle !== "free") {
      setLoading(false);
      setTriggerShow(true);
      notifier("Credit Card Info Missing");
      return;
    }


    const predCheck = await trigger("setPickupInput");
    const predDropCheck = await trigger("setDropLocation");

    const predOfficialCheck = await trigger(
      "predictionInputSelected"
    );

    const predOfficialDropCheck = await trigger(
      "predictionDropInputSelected"
    );


    const donationCheck = await trigger("amount");
    if(freeRideToggle === "paid" || freeRideToggle === "donation") {
      const cardCheck = await trigger("cardNumber");
      const expiryCheck = await trigger("expiry");
      const cvcCheck = await trigger("cvc");
      const nameCheck = await trigger("name");
      const amountCheck = await trigger("amount");
      if(!CardNumberElement)
      if (!cardCheck ||!expiryCheck ||!cvcCheck ||!nameCheck ||!amountCheck) {
        setTriggerShow(true);
        notifier("Please enter all required fields");
      }
      if (!donationCheck) {
        setTriggerShow(true);
        notifier("Please enter proper donation amount");
      }
    }
    if (predCheck || predDropCheck) {
      // console.log("Validation passed");
    } else {
      // console.log("Validation failed");
    }
    if (predOfficialDropCheck || predOfficialCheck) {
      // console.log("Validation passed");
    } else {
      // console.log("Validation failed");
    }
    // console.log("errors", errors);

    
    if (freeRideToggle === "donation" && !donationCheck) {
      setTriggerShow(true);
      notifier("Please enter proper donation amount");
    }

    // / Check for errors
    if (errors?.predictionInputSelected) {
      // console.log(
      //   "errors pred",
      //   errors?.predictionInputSelected?.message
      // );
      setTriggerShow(true);
      notifier("Please re-select a pickup location");
    }

    const errorsList =
      predCheck &&
      predDropCheck &&
      donationCheck &&
      predOfficialCheck &&
      predOfficialDropCheck;

    const check = Object.keys(errors).length;
    if (errorsList) {
      setOnCancel(
        () => () => setToggleConfirmationModal(false)
      ); // Update the function reference
      setConfirmationModalText(
        `Submitting Ride request will alert approved drivers in your network.
          Pick up at: ${watchedLocation} and drop off at: ${watchedDropLocation}.
          Only ride with people you trust, you are responsible for your network. We simply help to connect.
        `
      );
      setConfirmationAlertText(
        "By submitting this ride request you agree to the terms and conditions of the app."
      );
      setConfirmationHeaderText("Submitting Ride request");
      setToggleConfirmationModal(true);
      setTermsToggle(true);

      setOnConfirm(() => async () => {
        setToggleConfirmationModal(false);
        setTermsToggle(false);
        if (freeRideToggle !== "free") {
          // console.log("Aint Free");
          const cardElement =
            elements.getElement(CardElement);

          await setSetupIntent(true);

          // console.log("clientSecret", clientSecret);
          if (userEmail && clientSecret) {
            const {
              error,
              setupIntent: confirmedSetupIntent,
            } = await stripe.confirmCardSetup(
              clientSecret,
              {
                payment_method: {
                  card: cardElement,
                  billing_details: { email: userEmail }, // Replace with actual user email
                },
              }
            );

            if (error) {
              // console.log("Error:", error);
              // console.log(
              //   error?.setup_intent?.last_payment_error
              //     ?.message
              // );
              // console.log(error?.setup_intent?.status);

              if (
                error?.setup_intent?.status === "succeeded"
              ) {
                // console.log(
                //   "SetupIntent confirmed - succeeded:",
                //   error?.setup_intent?.payment_method
                // );
                // store payment intent id in customers collection
                const paymentMethod =
                  error?.setup_intent?.payment_method;
                // const paymentIntentId = paymentIntent;
                setPaymentMethodId(paymentMethod);

                if (paymentMethod) {
                  addMarker(paymentMethod);
                } else {
                  setLoading(false);
                  setTriggerShow(true);
                  notifier(
                    error?.setup_intent?.last_payment_error
                      ?.message
                  );
                }
                return;
              } else {
                setLoading(false);
                setTriggerShow(true);
                notifier(error?.message);
              }
            } else {
              // console.log(
              //   "SetupIntent confirmed:",
              //   confirmedSetupIntent
              // );
              // Optionally handle further logic here
              const paymentMethod =
                confirmedSetupIntent?.payment_method;
              setPaymentMethodId(paymentMethod);
              addMarker(paymentMethod);
            }
          } else {
            // console.log("no email or client secret");

            const createSetupIntentCall = httpsCallable(
              cloudFunctions,
              "createSetupIntent"
            );
            const setupIntentResponse =
              await createSetupIntentCall({
                email: userEmail,
                userId: customerData?.userId,
                stripeCustomerId: customerData?.stripeCustomerId,
              }); // Set the amount
            // console.log(
            //   "setupIntentResponse",
            //   setupIntentResponse, {customerData,} , customerData?.stripeCustomerId
            // );
            // console.log(
            //   "setupIntentResponse",
            //   setupIntentResponse?.data?.clientSecret
            // );
            
              const clientSecret = setupIntentResponse?.data?.clientSecret;

              if (!clientSecret) {
                throw new Error('Client secret is missing');
              }

              await setClientSecret(clientSecret);

            const {
              error,
              setupIntent: confirmedSetupIntent,
            } = await stripe.confirmCardSetup(
              clientSecret,
              {
                payment_method: {
                  card: cardElement,
                  billing_details: { email: userEmail },
                },
              }
            );

            if (error) {
              // console.log("no secret Error: -", error);
              // setPaymentMethodId(error?.setup_intent?.payment_method);

              if (
                error?.setup_intent?.status === "succeeded"
              ) {
                // console.log(
                //   "SetupIntent confirmed - succeeded:",
                //   error?.setup_intent?.payment_method
                // );
                // store payment intent id in customers collection
                const paymentMethod =
                  error?.setup_intent?.payment_method;
                // const paymentIntentId = paymentIntent;
                setPaymentMethodId(
                  error?.setup_intent?.payment_method
                );

                if (error?.setup_intent?.payment_method) {
                  addMarker(
                    error?.setup_intent?.payment_method
                  );
                } else {
                  setLoading(false);
                  setTriggerShow(true);
                  notifier(
                    error?.setup_intent?.last_payment_error
                      ?.message
                  );
                }
                return;
              } else {
                setLoading(false);
                setTriggerShow(true);
                notifier(error?.message);
              }
            } else {
              // console.log(
              //   "SetupIntent confirmed no secret no error:",
              //   confirmedSetupIntent
              // );
              const paymentMethod =
                confirmedSetupIntent?.payment_method;
              setPaymentMethodId(paymentMethod);
              addMarker(paymentMethod);
            }
          }
        } else {
          setLoading(false);
          addMarker();
        }
      });
    } else {
      setLoading(false);
      setTriggerShow(true);
      notifier("Please check errors and retry");
    }
    // console.log(check);
  }






  



  // ******************************************************
  
  // Main function to process the ride request
  
  // ******************************************************



  const addMarker = async (paymentMethodId) => {
    // console.log( location, lastLocation)
    // if (location !== lastLocation) {
    // console.log("!!! add marker");
    // console.log('addmarker', position)
    // console.log({ paymentMethodId });

    if (watchedLocation && watchedLocation !== "Current Location") {
      await setLastLocation(watchedLocation);
      await setDestination(watchedDropLocation);

      if (freeRideToggle !== "free") {
        await setRideIntent(
          paymentMethodId,
          rideEstimate,
          rideDistanceEstimate,
          passengerCount,
          userData,
          notifier,
          fiesta,
          setTriggerShow
        );
      }
      setLoading(true);
      const lngLat = getGeoCode(true);

      // firebase.analytics().logEvent('Adding Checkin');
      // console.log(lngLat)
    } else {
      // console.log("adding current location marker");
      const { latitude, longitude } = position;
      // const latitudeD = dropLocation?.latitude;
      // const longitudeD = dropLocation?.longitude;
      // console.clear();
      // console.log("*addMarker", { latitude, longitude }, position);

      if (latitude && longitude) {
        if (freeRideToggle !== "free") {
          await setRideIntent(
            paymentMethodId,
            rideEstimate,
            rideDistanceEstimate,
            passengerCount,
            freeRideToggle,
            userData,
            notifier,
            fiesta,
            setTriggerShow
          );

          // Write to customers collection
        }

        await setDestination(dropLocation);
        await setStartLocation({ lat: latitude, lng: longitude });
        // await setStartLocation(location);

        await setSearchPanelToggle(false);
        // await clearInput();
        // await setThankYouToggle(true);
        // await setRidePanelToggle(true);
        // console.log("getting reverse geo", { latitude, longitude });
        const reverseGeo = await reverseGeoCode({
          lat: latitude,
          lng: longitude,
        });
        // console.log({ reverseGeo });

        await writeMarker(reverseGeo, longitude, latitude, covid, "marker");

        await setPosition({ latitude, longitude });
        await writeToActiveRideRequest(
          reverseGeo,
          dropLocation,
          userData,
          setUserData,
          freeRideToggle,
          passengerCount,
          rideEstimate,
          rideDistanceEstimate,
          notifier,
          fiesta,
          setTriggerShow,
          setLoading,
          setMessageToken,
        );
        // await setRideIntent(rideEstimate, rideDistanceEstimate, userData, setUserData, notifier, fiesta, setTriggerShow)

        await activateDriverMode(
          false,
          setDriverMode,
          setResetDriverMarker,
          notifier,
          fiesta,
          triggerLocationGrab,
          setMessageToken,
        );
        await toggleActiveRideRequest(
          true,
          userData,
          setUserData,
          setClearTogglesActive,
          setTriggerShow,
          setLoading,
          notifier,
          fiesta
        );

        setLoading(false);
        // firebase.analytics().logEvent('Adding Current Location Checkin');
      } else {
        setTriggerShow(true);
        setLoading(false);
        notifier("Please enable location services");
      }
    }
    // }

  };
  




  const panelRef = useRef(null);



  // ******************************************************
  
  // Main function to capture form data and add marker
  
  // ******************************************************


 


  const getPlacePredictions = (inputVal) => {
    // console.log('getting place pred', inputVal, watchedPredictionInputSelected)
    
    if (!kindAuth.currentUser) return;
    if (watchedPredictionInputSelected?.length > 0 && watchedPredictionInputSelected === watchedLocation) {
      // console.log('failing pred check')
      setPredictionsToggle(false);
      return;
    }
    if (
      inputVal &&
      inputVal?.length >= 2 &&
      watchedPredictionInputSelected !== inputVal
    ) {
      // console.log("getPlacePredictions", inputVal);
      setPredictionsToggle(true);
      autocomplete.current.getPlacePredictions(
        { input: inputVal },
        (predictions) => {
          // console.log(predictions);
          predictions &&
            setPredictions(
              predictions.map((prediction) => prediction.description)
              .slice(0, 3)
            );
        }
      );
    }
    // console.log(predictions)
  };

  const selectDropPrediction = async (dropPrediction) => {
    setValue("setDropLocation", dropPrediction);
    await setValue("predictionDropInputSelected", dropPrediction);
    await setPredictionsDropToggle(false);

    // console.log("started selectDropPrediction");
    clearErrors("predictionDropInputSelected");
    clearErrors("setDropLocation");
    // console.log("finished selectDropPrediction");
    await trigger("predictionDropInputSelected");

    // trigger('setDropLocation');
  };
  

  const selectPrediction = async (prediction) => {
    // console.log(prediction)
    await setValue("setPickupInput", prediction);
    await setValue("predictionInputSelected", prediction);
    await setPredictionsToggle(false);

    await Promise.all([
      setValue("setPickupInput", prediction),
      setValue("predictionInputSelected", prediction),
      setPredictionsToggle(false),
      clearErrors("predictionInputSelected"),
      clearErrors("setPickupInput"),
      // setLocation(prediction),
      trigger("predictionInputSelected"),
    ]);

  };

  const clearDropInput = async () => {
    // setDropLocation("");
    await setValue("setDropLocation", "");
    await setValue("predictionDropInputSelected", "");
    await setPredictionsDropToggle(false);
    clearErrors();
  };


  const handleNext = async () => {
    if (step < 4) {
      switch (step) {
        case 1:
          if(watchedDropPredictionInputSelected?.length > 0 && watchedDropPredictionInputSelected === watchedDropLocation) {
            await handleStepCleared(true);
          } else {
            await handleStepCleared(false);

          }
          break;
        case 2:
          if(watchedDropPredictionInputSelected?.length > 0 && watchedDropPredictionInputSelected === watchedDropLocation &&
            watchedPredictionInputSelected?.length > 0 && watchedPredictionInputSelected === watchedLocation ) {
            await handleStepCleared(true);
          } else {
            await handleStepCleared(false);

          }

          break;
        case 3:
          if(watchedDropPredictionInputSelected?.length > 0 && watchedDropPredictionInputSelected === watchedDropLocation &&
            watchedPredictionInputSelected?.length > 0 && watchedPredictionInputSelected === watchedLocation && passengerCount ) {
            await handleStepCleared(true);
          } else {
            await handleStepCleared(false);

          }

          break;
        case 4:

          break;
        default:
          break;
      }
      
      
      setStep(step + 1);
      panelRef.current.scrollTo({
        left: step * 400,
        behavior: 'smooth'
      });
    }
  };
  
  const handleBack = () => {
    if (step > 1) {
      handleStepCleared(true);
      setStep(step - 1);
      panelRef.current.scrollTo({
        left: (step - 2) * 400,
        behavior: 'smooth'
      });
    }
  };

  const handleInputChange = (event) => {
    if (step === 1) {
      setDestination(event.target.value);
    } else if (step === 2) {
      setPickup(event.target.value);
    } else if (step === 3) {
      setTime(event.target.value);
    } else if (step === 4) {
      setPayment(event.target.value);
    }
  };

  const getDropPlacePredictions = async (input) => {
    // console.log('getting drop pred')
    if (!kindAuth.currentUser) return;
    // console.log("getDropPlacePredictions", input);
    if (input && input.length >= 2) {
      await setPredictionsDropToggle(true);
      // console.log("getting drop predictions");
      autocomplete.current.getPlacePredictions(
        { input },
        (dropPredictions) => {
          // console.log("setting pred", { dropPredictions });
          if (dropPredictions?.length > 0) {
            setDropPredictions(
              dropPredictions.map((prediction) => prediction.description)
              .slice(0, 3)
            );
            // emptyResponseCounter = 0; // Reset the counter if there are predictions
            setEmptyResponseCounter(0); // Reset the counter if there are predictions
          } else {
            let responseCounter = emptyResponseCounter + 1; // Increment the counter for empty responses
            setEmptyResponseCounter(responseCounter) // Increment the counter for empty responses
            // console.log("empty response", responseCounter);
            if (emptyResponseCounter === 5) {
              // console.log("reloading window");
              window.location.reload(); // Refresh the window if the counter hits 5
            }
          }
        }
      );
      // console.log("got drop predictions");  
    }

    // console.log(predictions)
  };

useEffect(() => {
  function initAutocompleteService() {
    if (!autocomplete.current) {
      autocomplete.current = new window.google.maps.places.AutocompleteService();
    }
  }

  if (window.google && window.google.maps && window.google.maps.places) {
    // If the API has already loaded, initialize AutocompleteService immediately
    initAutocompleteService();
  } else {
    // If the API hasn't loaded, add a listener to initialize AutocompleteService when it does
    window.addEventListener('load', initAutocompleteService);
  }

  // Cleanup: Remove the event listener when the component unmounts
  return () => {
    window.removeEventListener('load', initAutocompleteService);
  };
}, []);

  useEffect(() => {

    if(watchedDropPredictionInputSelected?.length > 0) {
      // console.log('watchedDropPredictionInputSelected', watchedDropPredictionInputSelected)
      handleStepCleared(true);
    }

  }, [watchedDropPredictionInputSelected])



  useEffect(() => {
    // This effect will run whenever `watchedLocation` changes
    const unsubscribe = async () => {
      // console.log("watchedLocation", watchedLocation, {watchedPredictionInputSelected});
      const pickupInput = getValues("setPickupInput");
      // setLocation(pickupInput);

      // setValue("setPickupInput", pickupInput);
      await clearErrors("setPickupInput");
      // clearErrors("predictionInputSelected");
      await setUseCurrent(false);
      if (watchedLocation?.length < 2) {
        if (watchedPredictionInputSelected?.length > 1 && pickupInput !== watchedPredictionInputSelected) {
          // console.log('clearing predInput')
            await setValue("predictionInputSelected", "");
            await setPredictionsToggle(true);
        }
        
        // await setPredictionsToggle(false);
      }  else {
        if ( watchedLocation !== watchedPredictionInputSelected) {
          // console.log('clearing predInput long')
            await setPredictionsToggle(true);
            //  setValue("predictionInputSelected", "");
        }
        // await setPredictionsToggle(true);
        // console.log('greelight predictions')
        // await getPlacePredictions(pickupInput);
      }
    };
    return () => unsubscribe();
  }, [watchedLocation, watchedPredictionInputSelected]);


  useEffect(() => {
    // This effect will run whenever `watchedLocation` changes
    const unsubscribe = async () => {
      // console.log("watchedLocation", watchedLocation);
      const pickupInput = getValues("setPickupInput");
      // setLocation(pickupInput);

      // setValue("setPickupInput", pickupInput);
     
        if(pickupInput !== watchedPredictionInputSelected) {
          // console.log('toggling predictions on', {watchedPredictionInputSelected, pickupInput, watchedLocation}, watchedLocation === watchedPredictionInputSelected)
          setPredictionsDropToggle(true);
        } else {
          // console.log('toggling predictions off', {watchedPredictionInputSelected, pickupInput, watchedLocation}, watchedLocation === watchedPredictionInputSelected)
          setPredictionsDropToggle(false);

        }

    };
    return () => unsubscribe();
  }, [watchedPredictionInputSelected]);

  useEffect(() => {
    const unsub = () => {
      if (!kindAuth.currentUser) return;
      // console.log("effect dropl", watchedDropLocation);
      if (watchedDropLocation && watchedDropLocation.length <= 2) {
         setPredictionsDropToggle(false);
        // setValue("predictionDropInputSelected", "");
        return;
      } else {
        if (
          watchedDropLocation?.length &&
          watchedDropLocation === watchedDropPredictionInputSelected
        ) {
          // console.log('watchedDropLocation', watchedDropLocation)
           setPredictionsDropToggle(false);
        } else {
            // setPredictionsDropToggle(true);
          // console.log("greelight drop predictions");
           getDropPlacePredictions(watchedDropLocation);
        }
      }
    };
    // if (watchedDropLocation && watchedDropLocation.length > 0) {
    return () => unsub();
    // }
  }, [watchedDropLocation]);

  
  useEffect(() => {
    const unsubscribe = async () => {
      const checkPred = await trigger("watchedPredictionInputSelected");
      const checkDropPred = await trigger("watchedDropPredictionInputSelected");
      const setPickupInput = getValues("setPickupInput");
      const setDropLocationInput = getValues("setDropLocation");
      const dropPred = getValues("predictionDropInputSelected");
      const pred = getValues("predictionInputSelected");
      // console.log({ pred, dropPred, setPickupInput, setDropLocationInput });
      // console.log(
      //   checkPred,
      //   checkDropPred,
      //   setPickupInput,
      //   setDropLocationInput,
      //   { watchedPredictionInputSelected, watchedDropPredictionInputSelected }
      // );
      if (
        checkPred &&
        checkDropPred &&
        pred?.length > 3 &&
        dropPred?.length > 3
      ) {
        setTriggerShow(true);
        await GetRideEstimate(
          rideEstimate,
          setRideEstimate,
          setPickupInput,
          setDropLocationInput,
          position,
          rideDistanceEstimate,
          setRideDistanceEstimate,
          notifier,
          fiesta
        );
        // console.log({ checkPred, checkDropPred });
      }
    };
    return () => unsubscribe();
  }, [watchedPredictionInputSelected, watchedDropPredictionInputSelected]);

  useEffect(() => {
    const unsub = async () => {
      // console.log("### watched toggle" ,'place predictions', watchedLocation)
      const predictionInputSelected = getValues("predictionInputSelected");
      const locationInput = getValues("setPickupInput");
      if (!kindAuth.currentUser) return;
      // console.log("### watched toggle", {
      //   locationInput,
      //   watchedLocation,
      //   watchedPredictionInputSelected,
      // });
      if (
        locationInput?.length <= 3 ||
        locationInput === predictionInputSelected
      ) {
        // console.log("clearing preds", {locationInput, predictionInputSelected})
        setPredictionsToggle(false);
        return;
      } else {
        // console.log("greelight predictions");
        if(watchedLocation !==  watchedPredictionInputSelected) {
          // console.log({watchedLocation, watchedPredictionInputSelected, locationInput})
          setPredictionsToggle(true);
        }
      }
      if (
        locationInput !== predictionInputSelected &&
        locationInput?.length > 3
        ) {
          if(locationInput !==  watchedPredictionInputSelected) {
          setPredictionsToggle(true);
          await getPlacePredictions(locationInput);
        } else {

          // setPredictionsToggle(false);
        }
        // setValue("predictionInputSelected", "");
        // setPredictionsToggle(true);
        // console.log("locationInput", locationInput);
        // const pickupInput = getValues("setPickupInput");
      }

      // if(locationInput?.length > 3 && locationInput !== predictionInputSelected) {
      // }
    };
    return () => unsub();
    // return () => unsub();
  }, [watchedLocation, watchedPredictionInputSelected]);








  // ******************************************************
  // Main return function
  // ******************************************************
  

  return (
    <RidesPanelWrapper>
      <CloseButton onClick={handleClose}>X</CloseButton>
         <SvgContainer>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="53" viewBox="0 0 375 53" fill="none" preserveAspectRatio="none">
            <path d="M0 53H375V0C375 0 313.859 37.1001 187.5 37.1001C61.1413 37.1001 0 0 0 0V53Z" fill="black"/>
          </svg>
        </SvgContainer>
        <RidesPanelContainer  toggle={userData?.activeRideRequest}>
          {/* <ActiveContentContainer> */}
            <RidesPanelHeader> You Already have an active ride request!</RidesPanelHeader>
            <RidesPanelImage toggle={ watchedDropPredictionInputSelected?.length > 1 } src="/images/dropkins/ui/rides/rides_panel_image_001.png" />
            <RidesPanelSubHeader>
              Pickup: {userData?.currentRideRequest?.location}
            </RidesPanelSubHeader>
            <RidesPanelSubHeader>
              Drop off: {userData?.currentRideRequest?.dropLocation}
            </RidesPanelSubHeader>
            <CenteredContainer>

            <LinkBtn onClick={async() => {
              await setPanelTrigger("ridePanel");
              await setOpenPanel(null);
              await setPanelTriggerCount(panelTriggerCount + 1);
              
            }}> Head To Dashboard</LinkBtn>
            </CenteredContainer>
          {/* </ActiveContentContainer> */}
        </RidesPanelContainer>
      <RidesPanelContainer toggle={!userData?.activeRideRequest}>
        <RidesPanelHeader>
          Let's Go!
        </RidesPanelHeader>
        <RidesPanelSubHeader>
          let’s see who’s available for the ride
        </RidesPanelSubHeader>
        <RidesPanelImage toggle={ watchedDropPredictionInputSelected?.length > 1 } src="/images/dropkins/ui/rides/rides_panel_image_001.png" />
        <PanelContainer ref={panelRef}>
          
          <Panel shrink={step !== 1}>
            <RideInputContainer>
              <RideForm onSubmit={handleSubmit(addMarker)}>
              <ActiveContentHeader>Enter your destination:</ActiveContentHeader>
              <RefreshUiBtn onClick={() => window.location.reload(false)}>
                Refresh UI
              </RefreshUiBtn>
              <LocationInputContainer key={2}>
              {errors?.predictionDropInputSelected && (
                      <ErrorField>
                        {" "}
                        Please Enter Location and Select address from the list
                        bellow
                      </ErrorField>
                    )}
                    <LocationAutoComplete>
                      {predictionsDropToggle &&
                        dropPredictions &&
                        dropPredictions.map((dropPrediction) => (
                          <Prediction
                            key={dropPrediction}
                            onClick={(e) => {
                              e.preventDefault();
                              selectDropPrediction(dropPrediction);

                            }}
                          >
                            {dropPrediction}
                          </Prediction>
                        ))}
                    </LocationAutoComplete>
                    <RideInput
                      type="text"
                      autoComplete='off'
                      placeholder="Where do you want to go?"
                      {...register("setDropLocation", {
                        required: true,
                      })}
                    />
                    {errors?.setDropLocation &&
                      errors?.setDropLocation.type === "minLength" && (
                        <ErrorField>
                          {"Must be longer than 4 Characters - "}
                          <br />
                          {errors?.setDropLocation.message}
                        </ErrorField>
                      )}
                   
                    <ClearInputBtn
                      onClick={() => {
                        clearDropInput();
                        clearErrors("predictionDropInputSelected");
                      }}
                    >
                      <img src="/svgs/close-btn.svg" alt="" />
                    </ClearInputBtn>
                  </LocationInputContainer>
                  </RideForm>
            { watchedDropPredictionInputSelected?.length > 1 && <iframe
                    title="active-driver-map"
                    width={600}
                    height={200}
                    style={{ border: 0, maxWidth: "100%" }}
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/place?q=${watchedDropLocation}&key=${gKey}&zoom=18
                    &maptype=satellite`}
                  />}
            </RideInputContainer>
          </Panel>
          <Panel shrink={step !== 2}>
          <RideInputContainer>
            <ActiveContentHeader>Enter your pickup location:</ActiveContentHeader>
            <LocationInputContainer key={2}>
                    {/* <RideInput
                      type="text"
                      placeholder="Where should they pick you up?"
                      {...register("setLocation", {
                        required: true,
                      })}
                    /> */}

{errors?.predictionInputSelected && (
                      <ErrorField>
                        {" "}
                        Please Enter Location and Select address from the list
                        bellow
                      </ErrorField>
                    )}
                    <LocationAutoComplete>
                      {predictionsToggle &&
                        predictions &&
                        predictions.map((prediction) => (
                          <Prediction
                            key={prediction}
                            onClick={(e) => {
                              e.preventDefault();
                              selectPrediction(prediction);

                            }}
                          >
                            {prediction}
                          </Prediction>
                        ))}
                    </LocationAutoComplete>
                    <RideInput
                      type="text"
                      autoComplete='off'
                      placeholder="Where should they pick you up?"
                      {...register("setPickupInput", {
                        required: true,
                      })}
                    />
                    {errors?.setPickupInput &&
                      errors?.setPickupInput.type === "minLength" && (
                        <ErrorField>
                          {"Must be longer than 4 Characters - "}
                          <br />
                          {errors?.setPickupInput.message}
                        </ErrorField>
                      )}
                 
                    <ClearInputBtn
                      onClick={() => {
                        clearDropInput();
                        clearErrors("predictionDropInputSelected");
                      }}
                    >
                      <img src="/svgs/close-btn.svg" alt="" />
                    </ClearInputBtn>
                  </LocationInputContainer>
                  { watchedPredictionInputSelected?.length > 1 && <iframe
                    title="active-driver-map"
                    width={600}
                    height={200}
                    style={{ border: 0, maxWidth: "100%" }}
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/place?q=${watchedLocation}&key=${gKey}&zoom=18
                    &maptype=satellite`}
                  />}
                 
            </RideInputContainer>
          </Panel>


          {/* Passenger Count Panel */}
          <Panel shrink={step !== 3}>
          <RideInputContainer>
          {watchedPredictionInputSelected?.length > 1 && <iframe
                    title="active-driver-map"
                    width={600}
                    height={200}
                    style={{ border: 0, maxWidth: "100%" }}
                    loading="lazy"
                    allowFullScreen
                    src={`https://www.google.com/maps/embed/v1/directions?origin=${watchedPredictionInputSelected}&destination=${watchedDropPredictionInputSelected}&key=${gKey}`}
                  />}
            <ActiveContentText>How many people need a ride? {passengerCount}</ActiveContentText>
            <PassengerCountContainer>
                <PassengerCountBtn
                  seats={1}
                  active={passengerCount === 1}
                  onClick={() => setPassengerCount(1)}
                >
                  <PassengerCountBtnContent>1</PassengerCountBtnContent>
                </PassengerCountBtn>
                <PassengerCountBtn
                  seats={2}
                  active={passengerCount === 2}
                  onClick={() => setPassengerCount(2)}
                >
                  <PassengerCountBtnContent>2</PassengerCountBtnContent>
                </PassengerCountBtn>
                <PassengerCountBtn
                  seats={3}
                  active={passengerCount === 3}
                  onClick={() => setPassengerCount(3)}
                >
                  <PassengerCountBtnContent>3</PassengerCountBtnContent>
                </PassengerCountBtn>
                <PassengerCountBtn
                  seats={4}
                  active={passengerCount === 4}
                  onClick={() => setPassengerCount(4)}
                >
                  <PassengerCountBtnContent>4</PassengerCountBtnContent>
                </PassengerCountBtn>
                <PassengerCountBtn
                  seats={6}
                  active={passengerCount === 6}
                  onClick={() => setPassengerCount(6)}
                >
                  <PassengerCountBtnContent>6</PassengerCountBtnContent>
                </PassengerCountBtn>
              </PassengerCountContainer>
              
            </RideInputContainer>
          </Panel>

{/* Payment Method Panel */}


          <Panel shrink={step !== 4}>
          <RideInputContainer>
            <GlowBox>
             { freeRides && <OutlineColumnContainer>
            { <Label> {freeRides} Free Ride Request credits available</Label>}
              </OutlineColumnContainer>}
            </GlowBox>
            {customerData?.subscription?.active && <Label> Unlimited Free ride request credits available</Label>}
             <OutlineColumnContainer
                  toggle={
                    !watchedPredictionInputSelected &&
                    !watchedDropPredictionInputSelected
                  }
                >
                  <CheckBoxContainer
                    active={freeRideToggle === "free"}
                    onClick={(e) => {
                      setFreeRideToggle("free");

                      // setTriggerShow(true);
                      // GetRideEstimate(
                      //   rideEstimate,
                      //   setRideEstimate,
                      //   location,
                      //   dropLocation,
                      //   position,
                      //   rideDistanceEstimate,
                      //   setRideDistanceEstimate,
                      //   notifier,
                      //   fiesta
                      // );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggle === "free"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>Free Ride</UseCurrentText>
                  </CheckBoxContainer>

                  <CheckBoxContainer
                    active={freeRideToggle === "donation"}
                    toggle={false}
                    onClick={(e) => {
                      setFreeRideToggle("donation");
                      // setTriggerShow(true);
                      // GetRideEstimate(
                      //   rideEstimate,
                      //   setRideEstimate,
                      //   location,
                      //   dropLocation,
                      //   position,
                      //   rideDistanceEstimate,
                      //   setRideDistanceEstimate,
                      //   notifier,
                      //   fiesta
                      // );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggle === "donation"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>$ Offer Donation</UseCurrentText>
                  </CheckBoxContainer>

                  <CheckBoxContainer
                    active={freeRideToggle === "paid"}
                    toggle={false}
                    onClick={(e) => {
                      setFreeRideToggle("paid");
                      // setTriggerShow(true);
                      GetRideEstimate(
                        rideEstimate,
                        setRideEstimate,
                        watchedLocation,
                        watchedDropLocation,
                        position,
                        rideDistanceEstimate,
                        setRideDistanceEstimate,
                        notifier,
                        fiesta
                      );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggle === "paid"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>$ Paid Ride</UseCurrentText>
                  </CheckBoxContainer>
                </OutlineColumnContainer>

               

                <OutlineContainer
                  toggle={
                    !watchedPredictionInputSelected?.length > 0 &&
                    !watchedDropPredictionInputSelected?.length > 0
                  }
                >
                  <SetDonationContainer>

                  {(!customerData?.subscription?.active && freeRides < 1) && (
                  <SubscriptionGate toggle={freeRideToggle === "free"}>
                    <OutlineContainer>
                      <SubscriptionGateHeader>
                        Sign up for unlimited free ride requests
                      </SubscriptionGateHeader>
                      <SubscriptionGateContent>
                        <SubscriptionGateImg src="/images/dropkins/ui/rides/sub_required_v001.jpg" />
                        <SubscriptionGateText>
                          <strong>Missing Subscription</strong>
                        </SubscriptionGateText>
                        <SubscriptionGateBtn />
                      </SubscriptionGateContent>
                    </OutlineContainer>
                  </SubscriptionGate>
                )}
                    <UseCurrentText toggle={false}>
                      {/* - Ride Summary -{" "} */}
                      {/* <OutlineContainer>
                        <strong>{freeRideToggle}</strong>
                      </OutlineContainer> */}
                      {/* <ActivePanelButton
                        onClick={(e) => {
                          setFreeRideToggle(freeRideToggle);
                          setTriggerShow(true);
                          // // trigger('setPickupInput');
                          // // trigger('setDropLocation');

                          GetRideEstimate(
                            rideEstimate,
                            setRideEstimate,
                            watchedLocation,
                            watchedDropLocation,
                            position,
                            rideDistanceEstimate,
                            setRideDistanceEstimate,
                            notifier,
                            fiesta
                          );
                        }}
                      >
                        Update Ride Estimates
                      </ActivePanelButton> */}
                    </UseCurrentText>
                    <UseCurrentText toggle={false}>Ride Request set as: <strong>{freeRideToggle}</strong>
                    </UseCurrentText>
                    {rideDistanceEstimate?.duration?.text && (
                      <UseCurrentText toggle={!freeRideToggle?.length}>
                        Est. Ride Length is:{" "}
                        <strong>{rideDistanceEstimate?.duration?.text}</strong>
                      </UseCurrentText>
                    )}
                    {rideDistanceEstimate?.distance?.text && (
                      <UseCurrentText toggle={!freeRideToggle}>
                        Est. Ride Distance is:{" "}
                        <strong>{rideDistanceEstimate?.distance?.text}</strong>
                      </UseCurrentText>
                    )}
                    {rideEstimate && (
                      <RideCostEstimate toggle={freeRideToggle !== "free"}>
                        <OutlineContainer>
                          <UseCurrentText toggle={freeRideToggle !== "free"}>
                            Projected Ride Cost:{" "}
                            <strong>GoodWill and cheer</strong>
                          </UseCurrentText>
                        </OutlineContainer>
                      </RideCostEstimate>
                    )}
                    {rideEstimate > 0 && (
                      <RideCostEstimate toggle={freeRideToggle !== "paid"}>
                        <OutlineContainer>
                          <UseCurrentText toggle={freeRideToggle !== "paid"}>
                            Estimated Ride Total
                          </UseCurrentText>

                          <strong>${rideEstimate}</strong>

                        </OutlineContainer>
                      </RideCostEstimate>
                    )}
                    {rideEstimate > 0 && (
                      <UseCurrentText toggle={freeRideToggle !== "donation"}>
                        Minimum ammount: <strong>$3.00</strong>
                      </UseCurrentText>
                    )}
                    {rideEstimate > 0 && (
                      <RideCostEstimate toggle={freeRideToggle !== "donation"}>
                        <OutlineContainer>
                          <UseCurrentText
                            toggle={freeRideToggle !== "donation"}
                          >
                            Offer Amount <strong>${watchedAmount}</strong>
                          </UseCurrentText>
                        </OutlineContainer>
                      </RideCostEstimate>
                    )}
                  </SetDonationContainer>
                  <SetDonationInput
                    toggle={freeRideToggle === "donation" ? true : false}
                    type="text"
                    className="amount"
                    placeholder="$5.00"
                    {...register("amount", {
                      required: freeRideToggle === "donation" ? true : false,
                      pattern: {
                        value: /^\d+(\.\d{0,2})?$/,
                        message: "Invalid currency format",
                      },
                      minLength: {
                        value: 1,
                        message: "Must be over 1 characters",
                      },
                      min: {
                        value: 3.0,
                        message: "Minimum value is 3.00",
                      },
                    })}
                    onBlur={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        // console.log("value", value.toFixed(2));
                        const honorMin =
                          value.toFixed(2) > 3.0 ? value.toFixed(2) : 3.0;
                        const honorTax = honorMin + honorMin * 0.1;
                        setValue("amount", honorMin);
                        setRideEstimate(honorMin);
                      } else {
                        setValue("amount", 3.0);
                        setRideEstimate(3.0);
                      }
                      trigger("amount");
                    }}
                  />
                </OutlineContainer>

                <OutlineContainer >
                  <UseCurrentText toggle={freeRideToggle === "free"}>
                    Enter Payment Details:
                  </UseCurrentText>

                  {freeRideToggle !== "free" && (
                   <CardElement
                   options={{
                     style: {
                       base: {
                         fontSize: '16px',
                         backgroundColor: '#fff',
                         color: '#333',
                         '::placeholder': {
                           color: '#999',
                         },
                       },
                       invalid: {
                         color: '#e5424d',
                       },
                     },
                     hidePostalCode: false,
                     hideCVC: false, // Add this line to show the CVV field
                   }}
                   onChange={handleCardDetailsChange}
                   className="card-element"
                 />
                  )}

                </OutlineContainer>

                {/* <ActiveContentText>
                  Think of a good joke for the ride! 😄
                </ActiveContentText> */}

                
               
            </RideInputContainer>
          </Panel>
        </PanelContainer>
          <PanelsButton>
          {step > 1 && (    <BackButton onClick={handleBack}>Back</BackButton> )}
        {stepCleared && step !== 4 && (
            <Button onClick={handleNext}>Next</Button>
            )
          }

      { step === 4 &&  <SearchSubmitContainer
                  toggle={
                    watchedPredictionInputSelected &&
                    watchedDropPredictionInputSelected &&
                    freeRideToggle === "free"
                      ? customerData?.subscription?.active || freeRides >= 1
                      : true
                  }
                >
                 
                  
                    <ActivePanelButton
                      active={
                        freeRideToggle === "free"
                          ? true
                          : !!stripe || checkCardRequirements
                      }
                      type="submit"
                      onClick={submitRideRequest}
                    >
                      Request Ride
                    </ActivePanelButton>
                  </SearchSubmitContainer>}
          </PanelsButton>
      </RidesPanelContainer>
     
    </RidesPanelWrapper>
  );
};

export default RidesPanel;