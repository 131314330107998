
import React, {useContext} from 'react';
import moment from 'moment';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';
import { collection, getDocs, addDoc, doc, deleteDoc, 
  QuerySnapshot, DocumentData, where, CollectionReference, and, getDoc, updateDoc } from 'firebase/firestore/lite';
import 'firebase/compat/auth';
import 'firebase/firestore';
import {  appFirebase, providers, kindAuth, kinDb } from '../firebase-config';
import userEvent from '@testing-library/user-event';
import { query, setDoc, getFirestore } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getRedirectResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updatePassword } from 'firebase/auth';

import { getUserId } from '../firebase';

 const db = getFirestore(appFirebase);

export async function setDontShowOnboard (toggle) {
  // console.log('setDontshowOnboard', toggle);
  // const noOnboardRef = collection(kinDb, 'no-onboard');
  if(toggle) {
   await setDoc(doc(db, 'noOnboard', getUserId()), {
    // addDoc(noOnboardRef, {
      userId: getUserId(),
      hide: true,
    }, { merge: true });
  } else {

    // #find the firestore doc that matches id and delete the document with the new firebase

    const onboardRef = collection(db, 'onboard');
    await getDocs(onboardRef, `${getUserId()}`).then( async (noOnboardDocs) => {
    // const noOnboardDocs = await getDocs(collection(kinDb, 'no-onboard'));
 
      noOnboardDocs?.forEach( async function (check) {
        // console.log('checkdon', check.data())
        if(!noOnboardDocs.empty) {
          const data = check.data();
          if (data.userId === getUserId()){
            const id = getUserId();
            id && await deleteDoc(doc(db, "no-onboard", id));
          }
        } 
      })
    
    })
  }
}

export async function checkDontShowOnboard ( {setShowOnboardContainer,
    setShowOnboardToggle}) {
  let status = false;

  
// console.log('collection empty', collection(db, 'noOnboard'));
  const noOnboardRef = collection(db, 'noOnboard');
    const q = query(noOnboardRef, where("userId", "===", getUserId()));

  const querySnapshot = await getDocs(q);
  if(!querySnapshot?.empty) {
    const data = querySnapshot.docs[0].data();
    if (data.userId === getUserId()){
      status = true;

    }
    setShowOnboardContainer(!status);
    setShowOnboardToggle(status);
  }

  // querySnapshot.forEach(function (check) {
  // // console.log('checkdon', check.data())
  //   if(!querySnapshot?.empty) {
  //     const data = check.data();
  //     if (data.userId === getUserId()){
  //       status = true;

  //     }
  //     setShowOnboardContainer(!status);
  //     setShowOnboardToggle(status);
  //   }
  // });


  // await collection(db, 'noOnboard').where('userId', '==', getUserId()).get()
  //   .then( async (res: { forEach: (arg0: (check: any) => void) => any; empty: any; }) => {
  //     // console.log(res, res.empty)
  //     await res.forEach(  function (check: { data: () => any; }) {
  //       // console.log('checkdon', check.data())
  //       if(!res.empty) {
  //         const data = check.data();
  //         if (data.userId === getUserId()){
  //           status = true;
  //         }
  //       } 
  //     })
    

    // });

  return status;
}
