import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as ui from "../styles/uiVars";

export const PassengerCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;

export const PassengerCountBtnContent = styled.div`
  display: flex;
  background: #00000071;
  /* border-radius: 25%; */
  padding: 0.15em;
  align-items: center;
`;

export const PassengerCountBtn = styled.div`
  height: 60px;
  width: 60px;
  display: flex;
  color: #f2f8ff;
  cursor: pointer;
  font-weight: 600;
  font-family: "Roboto", sans-serif;

  font-size: 1.5em;
  border-radius: 2px;

  ${({ seats }) =>
    seats &&
    seats === 1 &&
    `
    background: url('images/rides/seats_1_bg.png') no-repeat;
  `}
  ${({ seats }) =>
    seats &&
    seats === 2 &&
    `
    background: url('images/rides/seats_2_bg.png') no-repeat;
  `}
  ${({ seats }) =>
    seats &&
    seats === 3 &&
    `
    background: url('images/rides/seats_3_bg.png') no-repeat;
  `}
  ${({ seats }) =>
    seats &&
    seats === 4 &&
    `
    background: url('images/rides/seats_4_bg.png') no-repeat;
  `}
  ${({ seats }) =>
    seats &&
    seats === 6 &&
    `
    background: url('images/rides/seats_6_bg.png') no-repeat;
  `}

  ${({ active }) =>
    active &&
    `
      // background: #4F5CA0;
      // background: #384274;
      // background: #253cb3;
    border: 1px solid #fff;
    animation: hoverAnimation 0.3s ease-in-out;
    scale: 1.1;
  
  `}
  margin: 0.25em;
  background-size: contain;

  &:hover {
    scale: 1.1;
    font-size: 1.75em;
    font-weight: 800;
    box-shadow: 0px 2px 4px rgba(229, 200, 200, 0.25); // Add drop shadow
  }

  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.15em;
  }
`;

export const RideCostEstimate = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 700;
  align-items: center;
  margin: 0.5em;

   ${({ toggle }) => toggle && `
      display: none;
  `}

  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;

export const SubscriptionGate = styled.div`
  position: absolute;
  top: 0;
  height: 400px;
  min-height: 400px;
  width: 300px;
  /* background: #fff; */
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }

  ${({ toggle }) => !toggle && `
      display: none;
  `}
`;

export const SubscriptionGateText = styled.div`

  font-size: 1.5em;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #fff;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;


export const SubscriptionGateContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;

export const ContentText = styled.div`
  font-size: 1.25em;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  color: #fff;

  @media screen and (max-width: ${ui.mobile}) {
    font-size: 1.015em;
  }

`;

export const SubscriptionGateHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em;
  @media screen and (max-width: ${ui.mobile}) {
    margin: 0.1em;
  }
`;

export const SubscriptionGateImg = styled.img`
  height: 250px;
  width: 250px;
  margin: 0.5em;
`;