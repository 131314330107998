
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
// Import css files
import styled from '@emotion/styled';

import ReactMapGL, { Marker, Popup, NavigationControl, Source, Layer } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { WebMercatorViewport } from 'viewport-mercator-project';
import * as turf from '@turf/turf';
import * as ui from '../../../styles/uiVars';
import { AuthContext } from '../../../Auth';
import {updateDoc, where, query, collection, getDocs } from 'firebase/firestore';
import useDirectionsMap from "../../../hooks/useMapDirections";
import {
  kinDb,
  appFirebase,
  providers,
  kindAuth,
  cloudFunctions,
} from "../../../firebase-config";

import 'mapbox-gl/dist/mapbox-gl.css';

// Polyfill for mapbox-gl on browsers that don't support WebGL.
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// import MapboxWorker from 'workerize-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// mapboxgl.workerClass = MapboxWorker;


// const YOUR_DISTANCE_THRESHOLD = 6; // in metres
const YOUR_DISTANCE_THRESHOLD = 13; // in metres

const YOUR_OFF_COURSE_THRESHOLD = 13; // in metres

const COST_PER_MILE = 0.22;
const COST_PER_MINUTE = 0.66;


export const NavMapContainer = styled.div`
  position: relative;
  /* top: 0; */
  /* height: 360px; */
  /* max-height: 80vh; */
  /* max-width: 70vw; */
  /* width: 60vw; */
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  /* flex-wrap: wrap; */
  
  /* top: 0;
  left: 0; */
  flex-basis: 100%;

  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;

  border: 2px solid #21339a;
  height: auto;
  /* width: ${props => props.isFullscreen ? '80vw' : '76vw'}; */
  /* height: ${props => props.isFullscreen ? '90vh' : 'auto'}; */
  /* max-height: ${props => props.isFullscreen ? '100%' : '80vh'}; */
  /* max-width: ${props => props.isFullscreen ? '100%' : '70vw'}; */
  /* min-width: ${props => props.isFullscreen ? '66vw' : '100%'}; */
  z-index: ${props => props.isFullscreen ? '9999' : 'auto'};
  /* min-height: ${props => props.isFullscreen ? '400px' : '400px'}; */
  
  flex-direction: ${props => props.isFullscreen ? 'column' : 'row'}; 
  
  
  /* flex-direction: ${props => props.isFullscreen ? 'column' : 'row'};  */
  @media screen and (max-width: ${ui.tablet})  {
    flex-direction: column;
    /* flex-direction: column;
    /* flex-direction: ${props => props.isFullscreen ? 'row' : 'column'}; 
    min-height: 400px;
    ${({isFullscreen}) => isFullscreen && `

          height: 700px;

      `} */
  }
  /* @media screen and (max-width: ${ui.mobile})  { */
    /* flex-direction: ${props => props.isFullscreen ? 'row' : 'column'}; */
    /* min-width: 300px; */
    /* height: 500px; */
    /* ${({isFullscreen}) => isFullscreen && `

height: 100vh;

`} */
    
  /* } */

  
  
`;

const NavMapWrapper = styled.div`
  /* height: 100%; */
  /* min-height: 100%; */
  /* height: 360px; */

  width: ${props => props.isFullscreen ? '100%' : '100%'};
  height: ${props => props.isFullscreen ? '60vh' : '36vh'};
  
  /* flex-basis: ${props => props.isFullscreen ? '100%' : '60%'}; */

`;

export const NavDirectionDetailsContainer = styled.div`
  position: relative;
  padding: 0.25em 0.5em;
  cursor: pointer;
  flex-basis: 40%;
  line-height: 1.1;
  &:hover {
    background: #1c3258;
    color: white;
    font-weight: 600;
  }
  /* display: flex; */

  ${({ toggle }) => toggle ? `
  display: initial;

  ` : `
  display: none;
  `
  }


  
  ${({ active }) => active && `
    background: #041431bd;
    color: white;
    font-weight: 800;
    border-right: 4px solid #33486e;
   
  `}
  
  
  /* &:first-of-type {
    border-right: 3px solid #4d5a71;
    background: #1c222c;
  } */
  `;
  
  export const DriverImg = styled.img`
    width: 100%;
    height: auto;
    border-radius: 50%;
  `;

  export const DriverMarker = styled.div`
    height: 36px;
    width: 36px;
  `
export const ActiveMapBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  background: #24243372;
  flex-direction: column;
  padding: 0.5em;
  display: flex;
  flex-wrap: wrap;
  max-width:250px;
  
  /* @media screen and (max-width: ${ui.mobile}) {
    flex-direction: row;
    
  } */

`;
export const NavInstruction = styled.div`
  position: relative;

`;
export const NavFullScreenToggleButton = styled.div`
  position: relative;
  background: #231;

position: absolute;
right: 0;
padding: 0.36em;
margin: 0.25em;
border: 1px dotted #b6b2b2;
background: ${props => props.isFullscreen ? '#324' : '#507764' };

/* &:hover {
  background: #384;
} */
`;
export const NavDistance = styled.div`
  position: relative;

`;


export const NavDirectionsContainer = styled.div`
  position: relative;
  top: 0;
  /* height: 300px; */
  /* max-height: 100%; */
  border-left: 1px dotted #ccc; 
  
   margin: 0.25em;
   margin-bottom: 0.75em;
   flex-basis: ${props => props.isFullscreen ? '100%' : '40%'};
   max-height: ${props => props.isFullscreen ? '300px' : '260px'};
   background: ${props => props.isFullscreen ? '#445c94a2' :'#203b784f'};
   /* top: ${props => props.isFullscreen ? '-860px' :'0'}; */
   /* flex-wrap: wrap; */
   
   max-width: ${props => props.isFullscreen ? '46vw' : '400px'};
  /* max-width: 400px; */
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  overflow: hidden;
  overflow-y: auto;

 

  /* background: #24314f; */

  align-self: center;
  @media screen and (max-width: ${ui.tablet})  {
    /* flex-direction: row; */
    min-height: 200px;
    /* max-width: 100%; */
    
  }

  
`;

const NavDirectionsBannerContainer = styled(NavDirectionsContainer)`
  margin: 0;
  font-size: 0.8em;
  align-self: flex-start;


   ${({active}) => active && `
    background: #363d50d015;

  `}
  ${({next}) => next && `
    background: #353367bc;
    font-size: 0.7em;

  `}

@media screen and (max-width: ${ui.tablet}) {
    min-height: auto;;
    
  }

`;

function calculateDistance(lat1, lon1, lat2, lon2) {
  if(!lat1 || !lon1 || !lat2 || !lon2) {
    return;
  }
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // in metres
  // console.log(distance, lat1, lon1, lat2, lon2);
  return distance;
}














// 

// Main Component

// 
















export const NavMap = ({ currentRideStarted, useCurrentLocation = false}) => {



  const [shouldFollowUser, setShouldFollowUser] = useState(false);
  const [lastRouteRequest, setLastRouteRequest] = useState({ start: null, end: null });
  const [ start, setStart ] = useState(null);
  const [ end, setEnd ] = useState(null);
  const [ lastEnd, setLastEnd ] = useState(null)
  const [ toggleFullScreen, setToggleFullScreen ] = useState(false);

  
  const [userLocation, setUserLocation] = useState(null);
  const [directions, setDirections] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = React.useState(false);

  const onMapLoad = React.useCallback(() => {
    setMapLoaded(true);
  }, []);
  
  const mapRef = useRef();
  const {
    setNotification,
    setFiestaMessage,
    userData,
    profileImage,
    
    
  } = useContext(AuthContext);
  
  const [currentRide, setCurrentRide] = useState(userData?.currentRide || null);
  
  const [viewport, setViewport] = useState({
    // latitude: start?.longitude || 11,// initial latitude,
    // longitude: start?.latitude || 11,// initial longitude,
    // zoom: zoom || 16,// initial zoom level
    // width: 300,  // Width of the viewport
    // height: 300, // Height of the viewport

       width: '100%',  // Width of the viewport
    height: '100%', // Height of the viewport
    longitude: -122.45, // Longitude of the center of the viewport
    latitude: 37.78, // Latitude of the center of the viewport
    zoom: 17, // Zoom level
    // Optional properties
    // pitch: 6, // Pitch in degrees (0 = straight down, up to 60)
    // bearing: 0, // Bearing in degrees (0 = north, 90 = east, 180 = south, and 270 = west)

  });

  

  const [route, setRoute] = useState(null);




  useEffect(() => {
    const setUpMap = () => {
      // setStylesLoaded(true);
      // console.log('setting up map')
      // setShouldFollowUser(true);
      setStart({latitude: userData?.currentRide?.driverLocation?.lat, longitude: userData?.currentRide?.driverLocation?.lng})
      // console.log('setting up map', start)
      if(currentRideStarted && currentRideStarted === true) {
        setShouldFollowUser(true);
        setEnd(userData?.currentRide?.dropLocation)
        fetchRoute({latitude: userData?.currentRide?.driverLocation?.lat, longitude: userData?.currentRide?.driverLocation?.lng}, userData?.currentRide?.dropLocation);
        // console.log('setting up map end', start)
      } else {
        setShouldFollowUser(true);
        setEnd(userData?.currentRide?.location)
        fetchRoute({latitude: userData?.currentRide?.driverLocation?.lat, longitude: userData?.currentRide?.driverLocation?.lng}, userData?.currentRide?.location);
        // console.log('setting up map end', end)
      }

    }
   
    
    setUpMap();
  }, []); // Rerun effect if start or end changes

  

  useLayoutEffect(() => {
    // console.log('useLayoutEffect ran',toggleFullScreen)
    setViewport(prevState => ({
      ...prevState,
      width: toggleFullScreen ? '100vw' : '100vw',
      // height: toggleFullScreen ? '100vh' : '100%',
    }));
  }, [toggleFullScreen]);

  //   // 


    const resetNotifications = async () => {
      setNotification(null);
      setFiestaMessage(null);
    };
    const notifier = async (data) => {
      await setNotification(data);
    };
    const fiesta = async (data) => {
      await setFiestaMessage(data);
    };



    

    // 

  // Define the source and layer for the route line
  const geojsonSource = {
    type: 'geojson',
    data: route,
  };

  const routeLayer = {
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#09a6cd',
      'line-width': 8,
      'line-opacity': 0.8,

    },
  };

  // Function to geocode address
  const geocodeAddress = async (address, returnObject = false) => {
    if(!address) {
      return;
    }
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    );
    const json = await query.json();
    const [longitude, latitude] = json?.features[0]?.center || [];
    return returnObject ? { longitude, latitude } : json?.features[0]?.center;
  };

  const fetchRoute = async (startOverride, endOverride ) => {
    // Check if start and end are valid locations
    let startGeo = start;
    let endGeo = end;


    
    // console.log('checking route navMap', startGeo, endGeo)
    if(startOverride) {
      startGeo = startOverride;
    }
    if(endOverride) {
      endGeo = endOverride;
    }

    if (startGeo && typeof startGeo === 'object' && 'lat' in startGeo && 'lng' in startGeo) {
      startGeo = { latitude: startGeo.lat, longitude: startGeo.lng };
    }

    if (endGeo && typeof endGeo === 'object' && 'lat' in endGeo && 'lng' in endGeo) {
      endGeo = { latitude: endGeo.lat, longitude: endGeo.lng };
    }



      // If the new start and end are the same as the old start and end, return early
    if (
      lastRouteRequest.start === startGeo &&
      lastRouteRequest.end === endGeo
    ) {
      return;
    }

    // Update lastRouteRequest
    setLastRouteRequest({ start: startGeo, end: endGeo });


    // console.log('checking after Override route navMap', startGeo, endGeo)
    if(!startGeo || !endGeo) {
      return;
    }
    // console.log('both start and end', {startGeo, endGeo});
    const isString = typeof startGeo === 'string';
    const endIsString = typeof endGeo === 'string';
    if(isString) {
      startGeo = await geocodeAddress(startGeo, true);
    }
  
    if(endIsString) {
      endGeo = await geocodeAddress(endGeo, true);
    }
    // console.log('both start and end', {startGeo, endGeo})

    if(!startGeo?.longitude || !startGeo?.latitude || !endGeo?.longitude || !endGeo?.latitude) {
      return;
    }
    
    // const startGeoObjectorString = isObject;
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${startGeo.longitude},${startGeo.latitude};${endGeo.longitude},${endGeo.latitude}?geometries=geojson&steps=true&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
      );
      
      const json = await query.json();
      if(!json?.routes[0]) {
        notifier('Error Loading Map - Please refresh browser, and should resolve')
        return;
      }
      const data = json?.routes[0];
      // console.log('got route', {data})
      
    
      setRoute({
        type: 'Feature',
        properties: {},
        geometry: data.geometry,
        legs: data.legs,
      });

      
    if(!shouldFollowUser) {
      if(userLocation && userLocation.length > 0) {
        setStart({latitude: userLocation[1], longitude: userLocation[0]})
      }
      // console.log('should follow user', shouldFollowUser)
      setViewport((vp) => ({ ...vp, latitude: startGeo.latitude, longitude: startGeo.longitude }));
    }
  };

  // useEffect(() => {
  //   if (route) {
  //     const [minLng, minLat, maxLng, maxLat] = turf.bbox(route);
  //     const newViewport = new WebMercatorViewport(viewport)
  //       .fitBounds([[minLng, minLat], [maxLng, maxLat]], {
  //         padding: 100,
  //       });
  //     setViewport(newViewport);
  //   }
  // }, [route]);



  // }

  useEffect(() => {
    const geoId = navigator.geolocation.watchPosition(
      (position) => {
        if(!position) {
          return;
        }

        // console.log('watching pos mapbox', {position})
        const currentUserLocation = [position.coords.longitude, position.coords.latitude];
        setUserLocation(currentUserLocation);

        let endCheck = end;

        const lastStep = route?.legs[0]?.steps?.length - 1;
        if (route && route.legs && route.legs[0] && route.legs[0].steps && route.legs[0].steps[lastStep]) {
          const distanceToLastStep = calculateDistance(
            currentUserLocation[1],
            currentUserLocation[0],
            route.legs[0].steps[lastStep].maneuver.location[1],
            route.legs[0].steps[lastStep].maneuver.location[0]
          );

          // console.log('Distance to last step:', distanceToLastStep);
          const currentRide = userData?.currentRide;

          
          // Perform desired action based on distance to last step
          if (distanceToLastStep < YOUR_DISTANCE_THRESHOLD && currentRide["atDropOffLocation"] !== true) {
            console.log('at end of ride')
            currentRide["atDropOffLocation"] = true;

            async function getUserData() {
              const q = query(collection(kinDb, "users"), where("userId", "==", userData.userId));
            
              const querySnapshot = await getDocs(q);
              if (querySnapshot.docs.length > 0) {
                const firstDoc = querySnapshot.docs[0];
                // console.log('updating ride to end', {firstDoc, currentRide})

              await updateDoc(firstDoc.ref, {
                currentRide,
              });
                // console.log(firstDoc.id, " => ", firstDoc.data());
              } else {
                console.log("No documents found");
              }

            }


           getUserData();
          

            // const userRef = doc(db, "users", userData.userId)
            // updateDoc(userData.ref, {
            //   currentRide,
            // });
            // Do something
          } else if (distanceToLastStep > YOUR_OFF_COURSE_THRESHOLD) {
            // console.log('seems further', {currentRide})
            // Do something else
          }
        }

       


        
          // Perform desired action
        // if(currentRide.started && currentRide.started === true && endCheck !== currentRide.dropLocation) {
        //   setEnd(currentRide.dropLocation)
        //   console.log('setting up map end', currentRide.dropLocation)
        //   endCheck = currentRide.dropLocation;
        //   fetchRoute({ latitude: currentUserLocation[1], longitude: currentUserLocation[0] }, endCheck);
        // }

        // if(!currentRide.started && endCheck !== currentRide.location) {
        //   setEnd(currentRide.location)
        //   console.log('setting up map end', currentRide.location)
        //   endCheck = currentRide.location;
        //   fetchRoute({ latitude: currentUserLocation[1], longitude: currentUserLocation[0] }, endCheck);
        // }
        if(!endCheck || endCheck === null) {
          setShouldFollowUser(true)
        //   if(currentRideStarted && currentRideStarted === true) {
        //   if(endCheck !== userData?.currentRide?.dropLocation) setEnd(userData?.currentRide?.dropLocation);
        //   endCheck = userData?.currentRide?.dropLocation;
        //   console.log('geo watch end not set started true', userData?.currentRide?.dropLocation)
        // } else {
        //   if(endCheck !== userData?.currentRide?.location) setEnd(userData?.currentRide?.location);
        //   endCheck = userData?.currentRide?.location;
        //   console.log('geo watch end not set  started false', userData?.currentRide?.location)
        // }
      }

        // console.log('checking route', {route})
        if(!route || route === null) fetchRoute({ latitude: currentUserLocation[1], longitude: currentUserLocation[0] }, endCheck);



        // If you have a route and are tracking steps, check the user's distance to the next step
        // console.log('routepos', {route})
      if (route && route.legs && route.legs[0] && route.legs[0].steps && route.legs[0].steps[currentStep]) {
        const nextStep = route.legs[0].steps[currentStep];
        const distanceToNextStep = () => {
          
          const distanceCalc = calculateDistance(
          currentUserLocation[1],
          currentUserLocation[0],
          nextStep.maneuver.location[1],
          nextStep.maneuver.location[0]
        )

        // console.log('distance calc navmap is', distanceCalc);
        return distanceCalc;
        
        };

        // If the distance is below a certain threshold, advance to the next step
        if (distanceToNextStep() < YOUR_DISTANCE_THRESHOLD) {
          console.log('navmap your distance to next is closer')
          setShouldFollowUser(true);
          setCurrentStep(currentStep + 1);
        }

        // If the distance is above a certain threshold, recalculate the route
        if (distanceToNextStep() > YOUR_OFF_COURSE_THRESHOLD) {
          console.log('navmap your off course')
          setShouldFollowUser(true);
          // fetchRoute();
        }
      }
      },
      (error) => {
        console.error("Error accessing the GPS position", error);
      },
      { enableHighAccuracy: true, maximumAge: 6000 }
    );
  
    return () => navigator.geolocation.clearWatch(geoId);
  }, []);


  

  
  useEffect(() => {
    // Fetch route from Mapbox Directions API
    if(!end) {
      return;
    }
    if (lastEnd === end) return;
    setLastEnd(end)

    setShouldFollowUser(true);
    return () => fetchRoute();
  }, [end]); // Rerun effect if start or end changes
  
  
  

// useEffect(() => {
//   const checkRide = () => {
//   if (userData?.userData?.currentRide.started  !== currentRide?.started) {
//     console.log('currentRide has changed');
//     setCurrentRide(userData?.currentRide);
//   }
//   }
//   return checkRide();
// }, [currentRide, userData?.currentRide]);


useEffect(() => {

  const setMapRideUpdate = () => {
  // console.log('useEffect ran currentRide Started', currentRideStarted);
  // console.log('userData?.currentRide.started:', userData?.currentRide.started);
  // console.log('currentRide?.started:', currentRide?.started);
  // console.log('currentstart change', userData?.currentRide?.location, userData?.currentRide?.dropLocation)


  if (userData?.currentRide.started !== currentRide?.started) {
    console.log('currentRide has changed differs');
    setCurrentRide(userData?.currentRide);
  }
  
  if(currentRideStarted && currentRideStarted === true) {
    console.log('currentRide started has changed to true');
    setEnd(userData?.currentRide?.dropLocation)
    setShouldFollowUser(true);
    fetchRoute(start, userData?.currentRide?.dropLocation);
    //       console.log('setting up map end not started', userData?.currentRide?.location)
    //       fetchRoute(userData?.currentRide?.driverLocation, userData?.currentRide?.location);
    //       // fetchRoute();
    //     } else {
      
      //       setEnd(userData?.currentRide?.dropLocation)
      //       console.log('setting up map end started', userData?.currentRide?.dropLocation)
      //       fetchRoute(userData?.currentRide?.driverLocation, userData?.currentRide?.dropLocation);
    } else {
      
      console.log('currentRide started has changed to false');
      setEnd(userData?.currentRide?.location)
      setShouldFollowUser(true);
      fetchRoute(start, userData?.currentRide?.location)
    // let endCheck = end;
    // if(!end || end === null){
    //   console.log('end is not set', end)
    //   if(userData?.currentRide.started  === true) {
    //     setEnd(userData?.currentRide.dropLocation)
    //     console.log('setting up map end', userData?.currentRide.dropLocation)
    //     endCheck = userData?.currentRide.dropLocation;
    //   } else {
    //     setEnd(userData?.currentRide.location)
    //     endCheck = userData?.currentRide.location;
    //     console.log('setting up map end', userData?.currentRide.location)
    //   }
    // }
  }
}
return setMapRideUpdate()


}, [currentRideStarted]);


  // useEffect(() => {
  //   // Fetch route from Mapbox Directions API
  //   const setUpMap = () => {
  //     console.log('setting up map started change', currentRide?.started)

  //     // setStart({latitude: userData?.currentRide?.driverLocation?.lat, longitude: userData?.currentRide?.driverLocation?.lng})
  //     // console.log('setting up map', start)
  //     // setStart({latitude: userLocation[1], longitude: userLocation[0]})
      
  //     console.log(currentRide?.started, 'ride started?', userData?.currentRide.started)
  //     setShouldFollowUser(true);
  //     if(currentRide?.started && currentRide?.started === true) {
  //       setEnd(currentRide?.location)
  //       console.log('setting up map end not started', currentRide?.location)
  //       fetchRoute(currentRide?.driverLocation, currentRide?.location);
  //       // fetchRoute();
  //     } else {
        
  //       setEnd(currentRide?.dropLocation)
  //       console.log('setting up map end started', currentRide?.dropLocation)
  //       fetchRoute(currentRide?.driverLocation, currentRide?.dropLocation);
  //     }

  //   }
   
    
  //   return () => setUpMap();
    
  // }, [currentRide]); // Rerun effect if start or end changes

  


  function formatDistance(distanceInMeters) {
    const distanceInFeet = distanceInMeters * 3.28084;
    const distanceInMiles = distanceInMeters / 1609.34;
  
    if (distanceInMiles < 0.2) {
      return `${distanceInFeet.toFixed(2)} feet`;
    } else {
      return `${distanceInMiles.toFixed(2)} miles`;
    }
  }

  const onStyleLoad = (map) => {
    // setStylesLoaded(true);
    // Add your layers here
    // For example:
    // map.addLayer({
    //   id: 'route',
    //   type: 'line',
    //   source: {
    //     type: 'geojson',
    //     data: route
    //   },
    //   paint: {
    //     'line-width': 2,
    //     'line-color': '#007cbf'
    //   }
    // });
  };

  return (
    <NavMapContainer isFullscreen={toggleFullScreen}>


    <NavMapWrapper isFullscreen={toggleFullScreen}>
    <ReactMapGL
      // initialViewState={viewport}
      {...viewport}
      ref={mapRef}
      // viewState={viewport}
      // latitude={viewport.latitude}
      // longitude={viewport.longitude}
      // width="100%"
      // height="100%"
      // zoom={11}
      // pitch={viewport.pitch}
      key={setToggleFullScreen ? 'fullscreen' : 'normal'}
      mapStyle="mapbox://styles/mapbox/standard"
      // onViewstateChange={(newViewport) => setViewport(newViewport)}
      onViewportChange={(newViewport) => {
        if (shouldFollowUser) {
          setViewport(newViewport);
        }
      }}
      onLoad={onMapLoad}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      // onStyleLoad={() => onStyleLoad()}
      // scrollZoom={true}
      // dragRotate={true}
      // doubleClickZoom={true}
      
    >
      {mapLoaded && route && route.legs && route.legs[0] && route.legs[0].steps &&  (
        <>
          <Source id="route" type="geojson" data={route} key={JSON.stringify(route)} />
          <Layer {...routeLayer} />
        </>
      )}

      {userLocation && (
        <Marker latitude={userLocation[1]} longitude={userLocation[0]}>
          <DriverMarker className="driver-marker">
            <DriverImg src={
              profileImage
              ? profileImage
              : "images/ui/sidePane/space_astronaut.svg"
            } />
            🚗
          </DriverMarker>
        </Marker>
      )}
    </ReactMapGL>
    {/* {directions.length > 0 && (
      <div className="navigation-instructions">
        <p>{directions[currentStep]?.instruction}</p>
      </div>
    )} */}
      {route && route.legs && route.legs[0] && route.legs[0].steps &&  (
            <ActiveMapBanner isFullscreen={toggleFullScreen}>
              <NavDirectionsBannerContainer active >
                
                    <NavInstruction>
                        {route?.legs[0]?.steps[currentStep]?.maneuver?.instruction}
                        </NavInstruction>
                        <NavDistance>

                        {formatDistance(route?.legs[0]?.steps[currentStep]?.distance)}
                        </NavDistance>
            </NavDirectionsBannerContainer>
            
            <NavDirectionsBannerContainer next >
                
                <NavInstruction>
                    {route?.legs[0]?.steps[currentStep+1]?.maneuver?.instruction}
                    </NavInstruction>
                    <NavDistance>

                    {formatDistance(route?.legs[0]?.steps[currentStep+1]?.distance)}
                    </NavDistance>
              </NavDirectionsBannerContainer>

                  <NavFullScreenToggleButton isFullscreen={toggleFullScreen} onClick={() => {
                      setToggleFullScreen(!toggleFullScreen);
                      setShouldFollowUser(true);
                       // Trigger a resize event on the map
                       setTimeout(() => {
                        if (mapRef.current) {
                          mapRef.current.getMap().resize();
                        }
                      }, 0);
                      // fetchRoute()
                    }
                  }
                    
                    > [ ] </NavFullScreenToggleButton>
                          
            </ActiveMapBanner>
      )}
    </NavMapWrapper>

    <NavDirectionsContainer className='btn' isFullscreen={toggleFullScreen}>
    {/* {route && route.legs && route.legs[0] && route.legs[0].steps &&  (
      <NavDirectionDetailsContainer display={true} className="navigation-instructions">
        <NavInstruction>
          {route?.legs[0]?.steps[currentStep]?.maneuver?.instruction}
          </NavInstruction>
          <NavDistance>

          {formatDistance(route?.legs[0]?.steps[currentStep]?.distance)}
          </NavDistance>
      </NavDirectionDetailsContainer>
    )} */}
 

    {route && route.legs && route.legs[0] && route.legs[0].steps &&  (
      route.legs[0].steps.map((step, index) => (
           <NavDirectionDetailsContainer
            key={index+'steps'} 
            toggle={currentStep <= index + 1} 
            active={currentStep === index} 
            onClick={() => {
              setShouldFollowUser(false);
              setViewport({
                ...viewport,
                latitude: step.maneuver.location[1],
                longitude: step.maneuver.location[0],
                transitionDuration: 3000,
              });
            }}
            className="navigation-instructions">
            <NavInstruction key={index+'instruction'}>{step.maneuver.instruction}</NavInstruction>
            <NavDistance key={index+'distance'}>
             - {formatDistance(step.distance)}
          </NavDistance>
          </NavDirectionDetailsContainer>
          ))
    )}
    </NavDirectionsContainer>
    </NavMapContainer>
  );
}

export default NavMap;
