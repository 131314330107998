import React, { useCallback, useContext, useEffect, useState } from 'react';
// import { withRouter } from 'react-router';
import styled from '@emotion/styled';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { set, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthContext } from '../Auth';
import { GlowBox } from '../styles';
import { withRouter } from '../utils/withRouter';
import { PanelLogo, RegisterLink } from './LogIn';
import { OutlineContainer } from './SetMarker';
import { kinDb } from '../firebase-config';
import { updateDoc, doc, collection, query, getDocs, addDoc } from 'firebase/firestore';

const SignInContainer = styled.div`
   /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr); */
  background: #06124f;
  height: 100%;
  /* border: 10px solid #323938; */
  /* grid-area: 2/ 2 / 3 / 3;  */
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  z-index: 2;
  position: relative;
  align-items: center;


`;


const LoginForm = styled.form`
   display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 1.5em;
  
`;

const LogInButton = styled.button`
  width: 8em;
  margin: auto; 
  background: #315f88;
  color: white;
  padding: 1em 0.5em;
  margin-top: 0.25em;
`;


const FormHeader = styled.div`
  font-size: 2em;
  color: aliceblue;
  font-weight: 800;
  padding: 0.15em;
`;

const FormLabel = styled.div`
  font-size: 1em;
  color: aliceblue;
  font-weight: 600;
  padding: 0.2em 0;
`;

const SignUpButton= styled.button`
  width: 8em;
  margin: auto; 
  background: #315f88;
  max-width: 200px;
  color: white;
  font-weight: 700;
  padding: 0.75em;
  margin-top: 0.25em;
  background: #73C700;
  background: #FF9300;
  border-radius: 2px;
  margin-top: 1.5em;
  width: 100%;
  font-size: 0.88em;
  cursor: pointer;
`;

// const SignUpContainer = styled.div`
//   font-size: 0.9em;
//   color: aliceblue;
//   font-weight: 500;
//   padding: 0.5em 2.1em;
//   /* text-align: left; */
// `;

// const FormSignUpHeader = styled.div`
//   font-size: 2em;
//   color: aliceblue;
//   font-weight: 500;
//   padding: 0.5em 2.1em;
// `;


const InputContainer = styled.div`
  
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 1em;
  max-width: 600px;
  margin: auto;
  justify-content: center;
  /* width: 50%; */
  align-items: center;
  
  input {
    margin-top: 1.15em;
    /* padding: 1em; */
    background: #F2F1F1;
    border-radius: 2px;
    font-family: Metropolis, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.651em;
    line-height: 3.1;
    padding: 0.5em 0.75em;
    font-weight: 600;
    /* identical to box height */
    width: 100%;

    color: #999999;
  }
  label {
    padding: 0.2em;
  }
  
`;

export const PanelFocusText = styled.div`
  margin-top: 1em;
  color: #DACFF7;
  font-size: 0.758em;
  text-align: center;
  max-width: 300px; 
  margin: 0 auto;
  line-height: 1.2;
`;

export const ErrorField = styled.div`
  font-size: 13px;
  color: #da1e1e;
  /* color: #891407; */
  font-weight: 600;
  margin-top: 0.2em;
  line-height: 1.1;
  /* background: #f8d7da; */
  
  display: flex;
  max-width: 250px;
  padding: 0.7425em 0.7425em;
  text-shadow: 2px 4px 6px #3a343497;

  ${props => props.isEmpty && 'display: none;'}
  /* margin: 0.5em auto; */
`;


const InputRegisterLink = styled(RegisterLink)`
  color: white;
  margin-top: 1em;
`;



// const checkMatch = () => {

// }

const SignUp = ({ history }) => {

  const { inviteCode, setInviteCode } = useContext(AuthContext);
  const { register, handleSubmit, watch, formState: {errors}, getValues, clearErrors, setError, trigger } = useForm();
  const [ customError, setCustomError ] = useState(null);
  const [ showPassword, setShowPassword ] = useState(false);
  const [ showPasswordMatch, setShowPasswordMatch ] = useState(false);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const getCode = searchParams.get('code')// replace 'inviteCode' with the name of your parameter

  const watchedEmail = watch('emailSignUp');
  const watchedPassword = watch('passwordSignup');
  const watchedPasswordMatch = watch('passwordMatch');
  useEffect(() => {
    // console.log('watchedEmail', watchedEmail)
    clearErrors()
    setCustomError('');
    if (watchedEmail && !watchedEmail.includes('@')) {
      setError("emailSignUp", "invalid", "Invalid Email");
      setCustomError('Invalid Email')
    }
    if (watchedEmail && watchedEmail.includes('@')) {
      clearErrors("emailSignUp")
    }
    if (watchedPassword && watchedPassword.length < 6) {
      setError("passwordSignup", "invalid", "Password must be at least 6 characters");
      setCustomError('Password must be at least 6 characters')
    }
    if (watchedPassword && watchedPassword.length >= 6) {
      clearErrors("passwordSignup")
    }
    if (watchedPasswordMatch && watchedPassword && watchedPasswordMatch === watchedPassword) {
      clearErrors("passwordMatch");
      setCustomError('');
    }

    
    if (watchedPasswordMatch && watchedPassword && watchedPasswordMatch !== watchedPassword) {
      setError("passwordMatch", "notMatch", "Password Don't Match");
      // setError({passwordMatch: {type: 'notMatch', message: "Password Don't Match"}})
      setCustomError("Password Don't Match")
    }
  }, [watchedEmail, watchedPasswordMatch, watchedPassword, setError, clearErrors]);
  useEffect(() => {
    
    setInviteCode(getCode)
   }, [])

  // const handleSignUp = useCallback(async () => {
  //   // event.preventDefault();
  //   const { email, password } = getValues();
  //   try {
  //     await firebase
  //       .auth()
  //       .createUserWithEmailAndPassword(email, password);
  //       firebase.analytics().logEvent('Creating User');

  //     navigate('/');
  //   } catch (error) {
  //     console.log(error)
  //     setError("email", "general", error.message);
  //   }
  // }, [history]);

  // ...

const auth = getAuth();

const errorMessage = {
  'auth/email-already-in-use': 'Email already in use',
  'auth/invalid-email': 'Invalid email address',
  'auth/weak-password': 'Password must be at least 6 characters',
  'auth/operation-not-allowed': 'Operation not allowed',
  'auth/user-disabled': 'User disabled',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Wrong password',
  "not-found" : 'User not found',
}
const handleSignUp = useCallback(async () => {
  console.log('signing up')
  const { emailSignUp, passwordSignup, passwordMatch } = getValues();
  console.log('trying sign up')
  setShowPassword(false);
  setShowPasswordMatch(false);

  
  if(passwordSignup !== passwordMatch) {
    await setError("passwordMatch", "notMatch", "Password Don't Match");
    await setCustomError("Password Don't Match")
    return;
  }
  const checkEmailSignup = await trigger('emailSignUp');
  const passCheckUp = await trigger('passwordSignup');
  
  if(!checkEmailSignup || !passCheckUp) {
    setCustomError('Invalid Email or Password')
    return;
  }
  try {
    setCustomError('');
    await createUserWithEmailAndPassword(auth, emailSignUp, passwordSignup).then( async (userCredential) => {
      // Signed in
      const user = userCredential.user;
      // console.log('user', user, user.uid)
      // console.log('signed up', {userCredential})
      // ...

      const cUserRef = collection(kinDb, 'users');
      const q = query(cUserRef);
      const cUserSnapshot = await getDocs(q);
      // console.log({cUserSnapshot})

        // #Check if user exists with userId
      const cUser = cUserSnapshot.docs.find((doc) => doc.data().userId === user.uid);
      // console.log({cUser})
      if(cUser) {
        console.log('user exists')
        await updateDoc(doc(cUserRef, user.uid), {inviteCode})

      } else {
        await addDoc(collection(kinDb, 'users'), {
          inviteCode: inviteCode,
          userId: user.uid,
          email: emailSignUp,
        });
      }

      // update customers collection
      const cCustomersRef = collection(kinDb, 'customers');
      const cCustomerDoc = doc(cCustomersRef, user.uid);
      if(cCustomerDoc) {
        await updateDoc(cCustomerDoc, {
          inviteCode: inviteCode,
          userId: user.uid,
          email: emailSignUp,
        })
      } else {
        await addDoc(cCustomersRef, {
          inviteCode: inviteCode,
          userId: user.uid,
          email: emailSignUp,
        })
      }

      const decodeInviteCode = atob(inviteCode);

      console.log(decodeInviteCode);


      navigate('/');

      }).catch((error) => {
        const errorCode = error.code;
        // const errorMessage = error.message;
        const mapError = errorMessage[errorCode];
        setCustomError(mapError)
        // console.log('error', {errorCode, mapError})
        if(errorCode === 'not-found') navigate('/');
        // ..
        });
    // console.log('newUser', {newUser})
    // add inviteCode to user
    // const user = auth.currentUser;
    // await user.updateProfile({
    //   inviteCode: inviteCode
    // })
    
  } catch (error) {
    console.log('handle signup', {error})
    // setError("emailSignUp", "general", error.message);
    setCustomError(errorMessage[error?.code])
    setError("emailSignUp", "required", errorMessage[error?.code]);
    // setError("emailSignUp", "general", error.message);
  }
}, []);

  const goToLogin = () => {
    navigate('/login?signin=true&inviteCode=' + inviteCode);

  }

  


  return (
    <SignInContainer className="sign-up">
       {/* <PanelHeader>
            Social Distancing Buddy
          </PanelHeader>
          <PanelFocusText>
            COVID-19
          </PanelFocusText> */}
          <OutlineContainer>
      <LoginForm onSubmit={handleSubmit(handleSignUp)}>
     
      
      <PanelFocusText>
        You're a few steps away from getting started! 
      </PanelFocusText>
      <FormHeader>
        
      </FormHeader>
      {/* <input type="submit" /> */}
      <GlowBox>
      <InputContainer>
      <PanelLogo onClick={() => {
          navigate('/');
        
      }} src="/images/dropkins/ui/invite/dropkins_invite_large.jpg" alt=""/>
      {/* display General error */}
        {customError && <ErrorField style={{background: '#eaccdb'}}> {customError}</ErrorField> }
        {errors.emailSignUp && errors.emailSignUp.message && <ErrorField> {errors.emailSignUp.message}</ErrorField> }
        {errors.emailSignUp && <ErrorField> {errors.emailSignUp.message}</ErrorField> } 
        {/* {errors.email && errors.email.type && <ErrorField> {errors.email.message}</ErrorField> }  */}
        <input type="email" name="emailSignUp" placeholder="Email" {...register('emailSignUp', { required: 'Email is required' })}  />
       
        <div style={{ position: 'relative', width: '100%', padding: "0.5em 0.1275em", alignItems: "center", display: "flex", justifyContent: "space-between", }}>
        <input type={showPassword ? "text" : "password"}
         onChange={e => {
          const value = e.target.value
          // this will clear error by only pass the name of field
          if (value === watch('passwordMatch')) return clearErrors("passwordMatch")
          // set an error with type and message
          setError("passwordMatch", "notMatch", "Password Don't Match");
        }}
        
        name="passwordSignup"  {...register('passwordSignup',{ required: true })}  placeholder="Password" />
         <i 
          //  style={{ background:"#000", position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
           className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
           autocomplete="new-password"
              style={{
                background: "#34314a",
                position: "absolute",
                top: "50%",
                right: "10px",
                padding: "0.5em 0.1275em",
                color: "#d4c2c2",
                transform: "translateY(-50%)",
                cursor: "pointer"
              }}

           onClick={() => setShowPassword(!showPassword)}
          ></i>
          </div>

        
        {errors.password && <ErrorField>Required</ErrorField>}
        <div style={{ position: 'relative', width: '100%', padding: "0.5em 0.1275em", alignItems: "center", display: "flex", justifyContent: "space-between", }}>
          <input type={showPasswordMatch ? "text" : "password"} {...register('passwordMatch', { required: true })} name="passwordMatch" placeholder="Verify Password" 
            onChange={e => {
              const value = e.target.value
              // this will clear error by only pass the name of field
              if (value === watch('passwordSignup')) return clearErrors("passwordMatch")
              // set an error with type and message
              setError("passwordMatch", "notMatch", "Password Don't Match");
            }}
          />
          <i 
          //  style={{ background:"#000", position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', cursor: 'pointer' }}
           className={`fa ${showPasswordMatch ? "fa-eye-slash" : "fa-eye"}`}
           style={{
            background: "#34314a",
            position: "absolute",
            top: "50%",
            right: "10px",
            padding: "0.5em 0.1275em",
            color: "#d4c2c2",
            transform: "translateY(-50%)",
            cursor: "pointer"
          }}

           onClick={() => setShowPasswordMatch(!showPasswordMatch)}
          ></i>
        </div>
        {errors.passwordMatch && errors.passwordMatch.type === "required" &&
        <ErrorField>Required</ErrorField> }
        {errors.passwordMatch && errors.passwordMatch.type && <ErrorField> {errors.passwordMatch.message}</ErrorField> } 
        
        <SignUpButton type="submit" onClick={(e) =>
        {
          e.preventDefault();
         
          handleSignUp();
        }
        }>
          Sign Up
        </SignUpButton>
        <InputRegisterLink onClick={goToLogin}>
            Already have an account? <strong>Log In Here</strong>
        </InputRegisterLink>
        {inviteCode && (
        <><input type="text" name="inviteCode" placeholder="Invite Code" value={inviteCode} 
        {...register('inviteCode',{ required: true })}
        style={{ background: '#0a0c46', opacity:'40%', color:'#7875ac', border: 'none', cursor: 'not-allowed' }}
        disabled
        />
        <InputRegisterLink>
        <label htmlFor="inviteCode"
        style={{color: '#50508c', fontSize: '12px', fontWeight: 'normal', cursor: 'not-allowed'}}
        >
          <strong>Assigned Invite Code</strong>
        </label>
        </InputRegisterLink>
        </>
        )
        }
      </InputContainer>
      </GlowBox>
      
       
      </LoginForm>
      </OutlineContainer>
    </SignInContainer>
  )
}

export default withRouter(SignUp);
