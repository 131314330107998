import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import * as ui from '../../../../styles/uiVars';
import {
  HomeContentContainer,
  HomeContentText,
  HomeContentTitle,
  // HomeImageContainer,
  HomeContentWrapper,
  HomeMiddleImage,
} from './HomeMiddle';


 const HomeMiddleContainer = styled.div`
  display: flex;
  /* background: white; */
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
/* background: #2E2E75; */
background: #8997E8;
background: #060515;

@media screen and (max-width: ${ui.mobile}) {
  flex-direction: column-reverse;
  
}


`;

const HomeImageContainer = styled.div`
  /* width: 708px; */
  /* height: 528px; */
  flex-basis: 50%;

  min-height: 400px;
  background: url('/images/dropkins/landing/middle/what_is_dropkins_v001.jpg') no-repeat;
  background-size: cover;
  @media screen and (max-width: ${ui.tablet}) {
  width: 100%;
  /* background: url('/images/dropkins/landing/middle/ride_together_001.jpg') no-repeat; */

  
}
  
  `;

export const HomeContentContainerB = styled(HomeContentContainer)`
  color: black;
`;
export const  HomeImageContainerB = styled(HomeImageContainer)`
  background: url('/images/dropkins/landing/middle/what_is_dropkins_v001.jpg') no-repeat;
  background-size: cover;
`;



export const HomeMiddleB = () => {
  return (
    <HomeMiddleContainer>
     
      <HomeContentContainerB>
      <HomeContentWrapper>
        <HomeContentTitle>
          What exactly is a Dropkins?
        </HomeContentTitle>
        <HomeContentText>
          Dropkins is a platform that allows you to book a ride with people you've added to your network.
        </HomeContentText>
        </HomeContentWrapper>
      </HomeContentContainerB>
      <HomeImageContainer>
      </HomeImageContainer>
    </HomeMiddleContainer>

  )

};

export default HomeMiddleB;