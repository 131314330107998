// import { storage } from './firebase'; // import your firebase storage
// import { auth } from '../../firebase'; // import your firebase auth
import { set } from 'react-hook-form';
import { kinDb, appFirebase, cloudFunctions, providers, kindAuth } from '../../firebase-config';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, updateProfile } from "firebase/auth";
import { doc, getDoc, setDoc, collection, getDocs, query, where,limit, onSnapshot } from "firebase/firestore";
import { getUserId } from '../../firebase';




export const getUserNotifications = (userNotifications, setUserNotifications, fiesta, notifier) => {
  const userId = getUserId();
  const cUserRef = collection(kinDb, "users");
  console.log('get user not')
  
  const q = query(cUserRef, where("userId", "==", userId), limit(5));
  let notifications = [];
    onSnapshot(q, (snapshot) => {
    snapshot.forEach((doc) => {
      notifications.push( doc.data().notifications || []);
    });
    console.log('notifications', {notifications});
    // check notifications for duplicats
    const uniqueNotifications = [...new Map(notifications.map(item => [JSON.stringify(item), item])).values()];
    // compare notifications to userNotifications object array before setting new
    if(JSON.stringify(uniqueNotifications) !== JSON.stringify(userNotifications)) {
      setUserNotifications(uniqueNotifications);
    }
    // setUserNotifications(notifications);
    
    return notifications;
    // notifier(notifications);  // call the notifier function with the new notifications
  });
  // return notifications;
  // const doc = querySnapshot.docs[0];
  // notifications = doc.data().notifications || [];
  
  console.log('notifications', {notifications});
  // notifier(notifications);  // call the notifier function with the new notifications
  // return notifications;
};


export const updateProfileImage =  async (image, setProfileImage, setLoading, setTriggerShow, fiesta, notifier) => {
  // Create a storage reference
  // console.log('updating profile image', kindAuth.currentUser.uid)
  
  const auth = getAuth(appFirebase);
const user = auth.currentUser;
// console.log({user})
  // Get a reference to the storage service
  const storage = getStorage(appFirebase);
  const storageRef = ref(storage, `profile_images/${user.uid}`);


  try {
  // Upload the file
  const uploadTask = uploadBytesResumable(storageRef, image);


// // Update the user's profile with the new image URL
// await updateProfile(user, {
//   photoURL: downloadURL
// });

  uploadTask.on('state_changed', 
    (snapshot) => {
      // You can add code here to handle the upload progress
      setLoading(true)
      console.log('loading new image')
    }, 
    (error) => {
      // Handle unsuccessful uploads
      console.log('upload failed:', error)
      setTriggerShow(true)
      console.error(error);
      notifier('error')
      setLoading(false)
    }, 
    async () => {
      // Handle successful uploads on complete
      console.log('upload success getting download url')
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      // console.log('download url', downloadURL)
      const setPhotoURL = async () => {
                 // Get all documents in the users collection where userId equals user.uid
        // const usersCollection = collection(kinDb, 'users');
        // const q = query(usersCollection, where('userId', '==', user.uid));
        // const querySnapshot = await getDocs(q);

        const customersCollection = collection(kinDb, 'customers');
        const q2 = doc(customersCollection, user.uid);
        // const querySnapshot2 = await getDoc(q2);
        setDoc(q2, { photoURL: downloadURL }, { merge: true });

        // // Update each document with the new profile image URL
        // querySnapshot.forEach((doc) => {
        //   setDoc(doc.ref, { photoURL: downloadURL }, { merge: true });
        // });
          
      }


      // Update the user's profile with the new image URL
      updateProfile(user, {
        photoURL: downloadURL
      }).then(() => {
        console.log('Profile updated successfully');
        setProfileImage(downloadURL);
        
        const userDoc = doc(kinDb, 'customers', user.uid);
        setDoc(userDoc, { photoURL: downloadURL }, { merge: true });

        setPhotoURL();
       
        setLoading(false)
        setTriggerShow(true);
        fiesta('success Profile image updated successfully')
      }).catch((error) => {
        console.error(error);
        setTriggerShow(true)
        notifier('error Error updating profile image')
        setLoading(false)
      });
    }
  );

  } catch (error) {
    console.log(error)
    setTriggerShow(true)
    notifier('error Error updating profile image')
    setLoading(false)
  }
}


export const getUserDocument = async (user) => {
  const usersCollection = collection(kinDb, 'users');
  const q = query(usersCollection, where("userId", "==", user.uid));

  const querySnapshot = await getDocs(q);
  let userDocument = null;
  
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    userDocument = doc.data();
  });

  return userDocument;
}

export const getListings = () => {
  const auth = getAuth(appFirebase);
  const user = auth.currentUser;

  const listings = [];

  const getDocListings = async () => {

    const userDoc =  getUserDocument(user);
    const friends = userDoc.friends;

    friends?.forEach(async (friend) => {
        const q = query(listingsCollection, where("userId", "==", friend));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());
          listings.push(doc.data());
          });
        }
      });

    const listingsCollection = collection(kinDb, 'listings');
    const q = query(listingsCollection, where("userId", "==", user.uid));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      listings.push(doc.data());
      });
    }
  }
  
  

  
  // // Get all documents in the users collection where userId equals user.uid
  // const listingsCollection = collection(kinDb, 'listings');

  // // loop through friends and get their listings
  // const listings = [];
  // friends?.forEach(async (friend) => {
  //   const q = query(listingsCollection, where("userId", "==", friend));
  //   const querySnapshot = await getDocs(q);
  //   if (!querySnapshot.empty) {
  //     querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     console.log(doc.id, " => ", doc.data());
  //     listings.push(doc.data());
  //     });
  //   }
  // });

  // const qMe = query(listingsCollection, where("userId", "==", user.uid));
  //   const querySnapshotMe = getDocs(qMe);
  //   if (!querySnapshotMe.empty) {
  //     querySnapshotMe.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       console.log(doc.id, " => ", doc.data());
  //       listings.push(doc.data());
  //     });
  //   }



  return listings;
}
  // Get all documents in the users collection where userId equals user.uid
  // const usersCollection = collection(kinDb, 'users');

  // Get latest 30 documents by any of their friends 



export const uploadHomeImages = async (docRef, images, setHomeImages,setHomeImagesUploaded, setLoading, setTriggerShow, fiesta, notifier) => {
  // const auth = getAuth(appFirebase);
  const user = kindAuth.currentUser;
  const storage = getStorage(appFirebase);

  try {
    const uploadTasks = images.map((image) => {
      console.log('uploading home images', image, user.uid);
      const imageName = image.name.replace(/\s+/g, ''); // Remove spaces from image name
      const storageRef = ref(storage, `listings/${user.uid}/${imageName}`);
      const metadata = {
        contentType: image.type // Set the content type of the image
      };
      return uploadBytesResumable(storageRef, image, metadata);
    });

    await Promise.all(uploadTasks); // Wait for all upload tasks to complete


    const downloadURLs = await Promise.all(uploadTasks.map(async (uploadTask) => {
      // retrieve link to image from uploadTask promise
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
      // get location from downloadURL storage reference
      // const url = uploadTask.snapshot.ref.location;



      console.log('upload task', {uploadTask});
      console.log('upload task snapshot', uploadTask.snapshot);
      try {
        if (uploadTask.snapshot.ref) {
          console.log('upload task snapshot', uploadTask.snapshot.ref.location);
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('download url', downloadURL)
          return downloadURL;
        } else {
          console.log('upload task not snapshot', uploadTask);
          return null;
        }
      } catch (error) {
        console.error('Error getting download URL:', error);
        return null;
      }
    }));

    // extract the image path from downloadURLs
    const imagePaths = downloadURLs.map((url) => {
      console.log('download url', url);
      if (url) {
        const imagePath = url.split('?alt')[0];
        return imagePath;
      } else {
        return null;
      }
    });
    console.log('image paths', imagePaths);

    console.log('download urls', docRef, { downloadURLs });
    // use docref to append downloadURLs to the doc
    


    const listingsCollection = collection(kinDb, 'listings');
    const q = query(listingsCollection, where('userId', '==', user.uid));
    // const querySnapshot = await getDocs(q);

    // querySnapshot.forEach((doc) => {
    //   console.log({doc})
    //   if (downloadURLs.every((url) => url !== null)) {
    //     console.log('download urls', downloadURLs);
    //     setDoc(doc.ref, { photoURLs: downloadURLs }, { merge: true });
    //   }
    // });

    if (docRef) {
      setDoc(docRef, { listingPhotos: downloadURLs }, { merge: true })
        .then(() => {
          console.log('Profile updated successfully');
          setHomeImages(downloadURLs);
          setHomeImagesUploaded(true);
        })
        .catch((error) => {
          console.error(error);
          setTriggerShow(true);
          notifier('error Error updating profile images');
          setLoading(false);
        });
    } else {
      console.error('docRef is undefined');
    }
   

  } catch (error) {
    console.log(error);
    setTriggerShow(true);
    notifier('error Error updating profile images');
    setLoading(false);
  }
};

export const checkUserProfileImage = async (userId) => {
  
  const userDoc = doc(kinDb, 'users', userId);
  const userDocSnap = await getDoc(userDoc);

  if (userDocSnap.exists()) {
    return userDocSnap.data().photoURL || null;
  } else {
    console.log(`No document for user:`);
    return null;
  }
}