import React, { useCallback, useContext, useRef, useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import { withRouter } from '../utils/withRouter';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../Auth';
import { Terms } from './TermsAndConditions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import styled from '@emotion/styled';
import {resetPass} from '../firebase';
import { ErrorField, PanelFocusText } from './SignUp';
import { gsap } from 'gsap';
import {ClosePanel} from '../modules/general';
import { createUserWithEmailAndPassword, getRedirectResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updatePassword } from 'firebase/auth';
import {HomeScroller} from './UI/Landing/Scroller';
import { kinDb, appFirebase, providers, db, kindAuth } from '../firebase-config';
import { GlowBox, ContentBox, ActiveContentHeader } from '../styles';
import { useNavigate, useLocation } from 'react-router-dom';
import  TermsAndConditions from './UI/Shared/TermsAndConditions';
import  PrivacyPolicy from './UI/Shared/PrivacyPolicy';
import * as ui from '../styles/uiVars';
import {
  HomeTop,
  HomeMiddle,
  HomeMiddleB,
  HomeMiddleC,
  HomeLandingBanner,
} from './UI/Landing/home';

import {
  collection,
  doc,
  setDoc,
  onSnapshot,
  query,
  where,
  getFirestore,
  getDoc,
  updateDoc,
  and,
} from "firebase/firestore";

const TextSection = styled.div`
  font-size: 1.3em;
  margin: 0 5%;

  a {
    color: #ccc;
  }
  p {
    background: #343f6ccd;
    background: #3c52a9cd;
    background: #122366cd;
    border: 1px solid;
    border-radius: 4px;
    padding: 1em;
    max-width: 600px;
  }
`; 
const HeroLogoText = styled.div`
  font-size: 3.75em;
  font-weight: 700;
`;
const HeroImage = styled.img`
  max-width: 650px;
  border-radius: 6px;
  width: 100%;
  
`; 
const LoginContainer = styled.div`
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr); */
  /* background: #384274; */
  height: 100%;
  /* transition: transform 1.3s ease, top 1.3s ease; */

  /* border: 10px solid #323938; */
  /* grid-area: 2/ 2 / 3 / 3;  */
  z-index: 2;
  width: 100%;
  max-width: 100vw;
    background: white;
    align-self: center;
    margin-top: 76px;
    border-radius: 4px;
    
  position: relative;
  ${({toggleLogin}) => !toggleLogin && `
  transition: transform 1.3s ease-in-out;
      transform: translateX(0%);
  `}
  ${({toggleLogin}) => toggleLogin && `
  transition: transform 1.3s ease-in-out;
      transform: translateX(100%);
    display: none;
  `}

  @media screen and (max-width: ${ui.tablet}) {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    border-radius: 0;
  }
`;

const ForgotPasswordContainer = styled.div`
  position: relative;
   ${({toggleLogin}) => !toggleLogin && `
    display: none;
  `}
`;

const LoginFormContainer = styled.div`
  margin-top: 0em;
   ${({toggleLogin}) => !toggleLogin && `
    display: none;
  `}
`;



const LoginForm = styled.form`

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding-top: 1.5em;
    padding: 2em 0;
`;

const SignUpForm = styled.form`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding-top: 1.5em;
    padding: 2em 0;
`;

const GoBack = styled.div`
  position: absolute;
  top: 1em;
  right: 15px;
  cursor: pointer;
  color: black;
  font-size: 12px;
  padding: 0.5em;
  /* margin: 1em; */
  font-weight: 800;
  color: white;
  &:hover {
    border-radius: 4px;
    border: 1px solid #ccc;
    /* text-decoration: underline; */
  }
`;

const ForgotPasswordForm = styled.form`
color: black;
display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    padding: 1.5em;
`;

const LogInButton = styled.button`
  /* max-width: 312px;
  width: 100%;
  line-height: 1.3;
  margin: auto; 
  background: #315f88;
  padding: 0.5em;
  margin-top: 0.25em;
  background: #73C700;
  background: #FF9300;
  border-radius: 2px;
  margin-top: 1.5em;
  font-size: 0.88em; */
  color: white;
  display: flex;
  width: 140px;
  height: 48px;
  padding: 24px 40px;
  justify-content: center;
  align-items: center;
  gap: 1px;
  flex-shrink: 0;
  font-family: "Inter", sans-serif;
  
  border-radius: 10px;
background: #2E2E75;


  cursor: pointer;
`;

const ResetPasswordButton = styled.button`
    max-width: 312px;
    width: 100%;
    line-height: 1.3;
    margin: auto; 
    background: #315f88;
    color: white;
    padding: 0.5em;
    margin-top: 0.25em;
    background: #73C700;
    background: #FF9300;
    border-radius: 2px;
    margin-top: 1.5em;
    font-size: 0.88em;
    /* justify-self: flex-start; */

    cursor: pointer;
`;


const FormHeader = styled.div`
  font-size: 1em;
  color: aliceblue;
  font-weight: 800;
  padding: 0.15em;
`;

const FormLabel = styled.div`
  font-size: 1em;
  color: aliceblue;
  font-weight: 600;
  padding: 0.2em 0;
`;

const SignUpContainer = styled.div`
    padding: 1em;
    grid-area: 4 / 2 / 3 / 3; 
`;

const FormSubHeader = styled.div`
  font-size: 0.9em;
  color: aliceblue;
  font-weight: 500;
  padding: 0.5em 2.1em;
  /* text-align: left; */
`;

const FormSignUpHeader = styled.div`
  font-size: 0.9em;
  color: aliceblue;
  font-weight: 500;
  padding: 0.5em 2.1em;
`;


const LoginPage = styled.div`
  height: 100%;
  overflow: auto;
  background: #091346;
  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;
  background-size: cover;
`;
const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 5;
  background: #091346;
  background: linear-gradient(to right, #1129a0, #091346);
  background: linear-gradient(to right, #093351, #040a2d);
  background: linear-gradient(to right, #040a2d, #093351);
  background: #FFFFFF;

  justify-content: space-between;
  height: 80px;
  align-items: center;
  
  
  
  @media screen and (max-width: ${ui.tablet}){
    bottom: -1px;

    top: inherit;
    height: 70px;
    align-items: flex-start;
    /* padding-bottom: 2.6em; */
    bottom: ${({ isScrolledToBottom }) => (isScrolledToBottom ? '37px' : '0')};
    
  }
  

  @keyframes pulse {
    0% {
      /* background-color: #091346; */
      /* background: linear-gradient(to right, #093351, #040a2d); */
      opacity: 0.95;
    }
    50% {
      /* background-color: #1129a0; */
      /* background: linear-gradient(to right, #093351, #091763); */
      opacity: 0.94;
    }
    100% {
      /* background-color: #091346; */
      /* background: linear-gradient(to right, #093351, #040a2d); */
      opacity: 0.98;
    }
  }

  /* animation: pulse 5s infinite alternate ease-in-out; */

  
`;



const HeaderLogo = styled.div`
  padding-left: 2em;

  img {
    width: 50%;
    /* top: 20px; */
    position: relative;
  }

  @media screen and (max-width: ${ui.tablet}) {
      padding-left: 1em;

      img{
        top: 16px;
        min-width: 40px;
      }
  }
`;

const HeaderNav = styled.div`
  padding-right: 2em;
  display: flex;
  padding: 20px;
  gap: 10px;
  @media screen and (max-width: ${ui.tablet}) {
    /* gap: 4px; */
    /* padding: 10px 40px; */
    /* padding-right: 1em; */
    justify-content: center;
    align-items: center;
    /* margin-top: 0.65em; */
  }

`;

const HeaderLogin = styled.div`
  /* width: 100px;
  background: #00A3D4;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 0.5em;
  border-radius: 2px;
  font-size: 20px;
  text-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.75);
  font-weight: 600;
  padding:0 0.5em;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  cursor: pointer;
  background: url('/images/dropkins/ui/buttons/tech_buttons_002.jpg') no-repeat;
  background-size: cover;
  background-position: center; */
  display: flex;
/* width: 140px; */
/* height: 38px; */
padding: 24px 48px;
justify-content: center;
align-items: center;
/* gap: 1px; */
/* flex-shrink: 0; */
font-family: "Inter", sans-serif;
  border-radius: 10px;
  background: #aaaad1;
  background: #2E2E75;
  color: black;
  color: #FFFFFF;
  cursor: pointer;

  /* &:hover {
    background: #0092B9;
  } */
  &:hover {
    /* background: #02999c; */
    scale: 1.05;
    border: 1px solid #ccc;
  }

  @media screen and (max-width: ${ui.tablet}) {
    /* padding: 0.2em 0.4em; */
    /* padding:0.2em; */
    font-size: 14px;
    /* height: 30px; */
    /* height: 34px; */
padding: 10px 16px;
min-width: 60px;
  }
`;

const Footer = styled.div`
  position: relative; 
  display: flex;

  /* position: fixed; */
  bottom: 0;
  
  background: white;
  width: 100%;
  color: black;
  z-index: 13;
  

  @media screen and (max-width: ${ui.tablet}) {
    /* position: fixed; */
    /* bottom: 5.65em; */
  } 
`;

const FooterLinkHref = styled.a`
  padding: 14px;
  font-size: 10px;
  cursor: pointer;
  color: black;
`;
const FooterLink = styled.div`
  padding: 14px;
  font-size: 10px;
  cursor: pointer;
`;
// 
    // Landing Screen
// 
// 

const LandingScreen = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  height: 100%;
  justify-content: flex-start; */
  display: flex;
  flex-wrap: wrap;
  /* background: #6775BF; */

  ${({toggleLogin}) => !toggleLogin && `
    background: #6775BF url('images/home/home-form-bg.jpg');
    background-size: contain;
  `}
`;

const HomePanelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15%;
  ${({toggleLogin}) => toggleLogin && `
    display: none;
  `}
`;
const TopPanel = styled.div`
  /* background: #6775BF; */
  /* background: url('/images/home-bg-a.jpg') no-repeat; */
  background-size: cover;
  /* flex-basis: 75%; */
  padding-top: 0.4em;
  justify-content: flex-start;
  flex-direction:column;
  height: 100%;
  flex-basis: 100%;
  /* flex-grow: 1; */
  display: flex;
  position: relative;

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0;
  }
  /* padding-bottom: 40px; */

/* 
  @media screen and (max-width: 680px) {
    min-height: 25em;
    min-height: 17em;
  }
  @media screen and (min-width: 680px) {
    min-height: 9em;
  } */
`;

const TopBg = styled.div`
  position: absolute;
  width: 100%;
  height: 716px;
  left: 0;
  top: 0px;
  max-height: 100%;
  max-width: 100%;

  /* background: linear-gradient(180deg, #4F5CA0 -26.18%, #2E365D 32.62%), #2E365D; */
  mix-blend-mode: multiply;
  opacity: 0.87;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const MiddlePanel = styled.div`
  position: relative;
  /* padding: 2em; */
  background: #384274;
  background: #051f2a;
  background: #02151e;
  color: #fcfcfc;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.6;

  background: linear-gradient(180deg, #142dbe -26.18%, #040e3e 32.62%), #05114c;

  display: flex;
  justify-content: center;
  flex-basis: 100%;
  /* margin-top: 0.51em; */
  z-index: 1;


  
  
  
  @media screen and (max-width: 700px) { 
    padding:0.25em 0.01em; 
    margin: 0;
  
  }

`;

const MidBg = styled.img`
position: absolute;
    top: -15em;
    width: 100%;
    z-index: -1;

    @media screen and (max-width: 786px) {top: -9em; }
    @media screen and (max-width: 500px) {top: -7em; }
`;

const MiddlePanelCTA = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 51em; 
  max-width: 1030px;
  text-align: left; 
  font-size: 16px;
  line-height: 1.283;
  padding-top: 0.25em;

  ${({togglePanel}) => !togglePanel && `
    display: none;
  `}
`;

const MiddlePanelExpandToggle = styled.span`
  font-size: 16px; 
  font-weight: 400; 
  text-decoration: underline;
  padding-left: 0.5em;
  cursor: pointer;
  color: #00A3D4;
`;

const MiddleTogglePanel = styled.div`

  padding-top: 1em;

  display: none;
  ${({toggle}) => toggle &&`
    display: block;
  ` }
`;

export const PanelTitle = styled.div`
  color: #000;
font-family: "Inter", sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 60% */
`;

export const PanelSubHeader = styled.div`
  color: #000;
font-family: "Inter", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */

`;

export const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;

  @media screen and (max-width: ${ui.tablet}) {
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;
  }

`;

export const PanelLogo = styled.img`
  padding-top: 1em;
  max-width: 400px; 
  width: 100%;
  margin-right: 60px;
  position: relative;
  margin: auto;
`;

const FormLogo = styled(PanelLogo)`
  padding-top: 0;
`;


// const PanelSubHeader = styled.div`
//   font-family: Metropolis, sans-serif;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 0.8em;
//   line-height: 1.5;
//   display: flex;
//   align-items: center;
//   text-align: left;
//   margin-top: 1.5em;
//   flex-basis: 10%;
//   width: 80%;
//   max-width: 188px;
//   text-align: center;
//   align-self: center;

//   color: #FFFFFF;

// `;

const PanelAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: center;
  margin-top: 1.8em;


  @media screen and (max-width: ${ui.tablet}) {
    margin-top: 0;
    
  }
`;

const ImageAttribution = styled.div`

margin: auto;
    bottom: 14px;
    text-align: left;
    font-size: 9px;
    z-index: 1;
    position: absolute;
    color: #D9D9D9;
    color: #87898B;
    max-width: 70%;
    width: auto;
    /* padding-bottom:  */
  
`;

const ImageFormLink = styled.a`
  text-align: center;
  font-size: 9px;
  /* font-weight:bold; */
  color: #87898B;
  
`;

const HomeStatsContainer = styled.div`
  /* background: url('/svgs/home-covid-buddy-a.svg') no-repeat; */
  background-size: contain;
  height: 24em;
  width: 100%;
  align-self: center;
  flex-basis: 80%;
  background-position: left;
  position: absolute;
    bottom: 1em;
    left: 0;
  display: flex;
    justify-content: center;
    margin-top: 1.5em;
`;

const HomeGraphicContainer = styled.div`
    position: absolute; 
  
    bottom: -4em;
    left: 0;
    width: 320px;
    margin-left: -170px;
    left: 18%;
    z-index: 3;

/* 
    @media screen and (max-width: 400px) {
      bottom: 5em;
      left: 1em;
    }

     */
    @media screen and (min-width: 680px) {
       
       bottom: -2em;
     }

    img {
    
        /* width: 36%; */

        @media screen and (min-width: 680px) {
            width: 80%;
        }

      @media screen and (max-width: 680px) {
        width: 60%;
      }
    }
    

`;

const HomeMessageContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  margin-top: 1.5em;

  font-size: 0.61em;
    text-align: left;
    line-height: 1.4;
    color: #DACFF7;
    font-weight: 300;

    max-width: 180px;
`;

const HomeStats = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 13em;
  
`;

const StatsNumber = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  /* identical to box height */


  color: #FFFFFF;

`;

const StatsText = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;

  color: #FFFFFF;

`;

const StatsDate = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 1;
  color: white;
  margin-top: 0.2em;
`;

const BottomPanel = styled.div`
  /* align-self: flex-end;
  width: 100%;
  position:relative;
  background: url('/images/home-bg-map-b.png');
  background-position: bottom;
  display: flex;
  /* flex-basis: 25%; 
  justify-content: flex-end;
  flex-direction: column;
  min-height:7em;
  background: #fcfcfc; */
  flex-basis: 100%;
  position: relative;
  padding: 1em 4em;
  /* background: linear-gradient(180deg, #384274 0%, #2E365D 83.85%); */
  color: #FFFFFF;
  background: #384274;
  background: #051f2a;
  background: #020920;
  
  padding-top: 0;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 1.6;
  display: flex;
    /* -webkit-box-pack: center; */
    /* -webkit-justify-content: center; */
    justify-content: center;
    flex-wrap: no-wrap;
    width: 100%;
    flex-direction: column;
    z-index: 1;
    
    @media screen and (max-width: 500px) {
      padding: 0 2em;
      /* padding-bottom: 2em; */
    }

    ${({togglePanel}) => !togglePanel && `
      display: none;
    `}
  
`;

const TogetherPanel = styled(BottomPanel)`
  background: #071350;
  padding-bottom: 5vw;

  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;
  background-size: cover;
`;

const AnchorPanel = styled(BottomPanel)`
  /* background: #384274 url('images/home/home-bottom-bg.png') no-repeat; */
  background-size: cover;
  height: 21vw;
  width: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    height: 28vw;
    min-height: 200px;
  }

`;

const AlertExample = styled.div`
  max-width: 300px; 
  margin: auto;
  position: relative; 
  width: 100%;

  img {
    width: 100%;
  }
`;

const AlertPanel = styled.div`
  position: absolute;
  width: 300px;
  top: -130px;
  right: -100px;

  @media screen and (max-width: 500px) {
    right: 0;
  }
  
`;

const AlertMarker = styled(AlertPanel)`
  width: 150px;
  top: -50px;
  right: 150px;

  @media screen and (max-width: 500px) {
    right: 75px;
  }
`;


const BottomPanelContainer = styled.div`
  max-width: 51em; 
  max-width: 930px;
  text-align: left; 
  font-size: 0.88em;
  flex-basis: 100%;
  margin: auto;
  width: 85%;
`;

const TogetherPanelContainer = styled(BottomPanelContainer)`
    margin-top: 3em;
    color: #FFFFFF;
    padding-bottom: 3em;
`;

const BottomPanelHow = styled.div`
  display: flex; 
  justify-content: columns; 
  flex-wrap: wrap; 
  align-items: flex-start;
  /* border-top: 1px solid #DADADA; */
  padding-top: 1em;

`;

const ActiveHowPanel = styled(TogetherPanelContainer)`
    margin-top: 0;
    max-width: 760px;
    max-width: 901px;
    width: 100%;
    width: calc(100% - 2em);
    display: none;
    padding: 1em;
    background: #2E365D;

    ${({ active }) => active && `

      display: block;
    `}
`;

const How = styled.div`
  background: #2E365D url('/svgs/home/how/home-how-bg.svg') no-repeat;

    cursor: pointer;


  ${({ active }) => active && `
    background: #2E365D url('/svgs/home/how/home-how-bg-active.svg') no-repeat;
  `};


    flex-basis: 15%;
    flex-grow: 1;
    flex-basis: 25%;
    flex-basis: 33%;
    background-size: contain;
    background-position: top;

    display: flex;
    align-items: flex-end;
    /* padding-bottom: 2em; */
    justify-content: center;


   
    

  img {
    width: 100%;
    /* width: 80%; */
    /* max-height: 80%; */

  }


  @media screen and (max-width: 786px) {
    flex-basis: 25%;
    /* background-size: cover; */

    /* img {
      width: 85%;
    } */
  }
`;

const BottomPanelHeader = styled.div`
 /* border-bottom: 1px solid gray; */
 margin-top: 1em;
 
  img {
    width: 100%;
  }

`;

const HowHeader = styled(BottomPanelHeader)`
  max-width: 150px;
  padding-right: 6em;
  
`;
const TogetherHeader = styled(BottomPanelHeader)`
  max-width: 250px;
  position: relative;
  
`;

// const HowHeader = styled(BottomPanelHeader)`

// `;

const HowSubHeader = styled(BottomPanelHeader)`
  max-width: 250px;
  width: 90px;
`;

const SendLoveSubHeader = styled(BottomPanelHeader)`
  width: 180px;
`;

const BottomPanelCTA = styled.div`
  padding-top: 1em;
  /* border-top: 1px solid #DADADA; */
  color: #2E365D;
`;

const TogetherPanelCTA = styled(BottomPanelCTA)`
  color: #FFFFFF;
  /* border-top: 1px solid #fff; */
  padding-top: 2em;
  font-size: 14px;
`;

const HowActiveCTA = styled(TogetherPanelCTA)`
  padding-top: 1em;
  color: #FFF;
  font-size: 16px;
`;

const HowExamples = styled.div`
  display: flex;
  
  margin-top: 2em;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 680px) {
    justify-content: center;
  }
`;
const HowExample = styled.div`
  /* background: #F1F5F5;
  background: #FFF; */
  flex-basis: 19%;
  display: flex;
  max-width: 300px;
  min-width: 190px;
  justify-content: center;

  @media screen and (max-width: 680px) {
    flex-basis: 50%;
  }

  img {
    width: 100%;
  }
`;

const HowSendLoveMap = styled.div`
  background: url('/images/home/how/send-love-map.jpg') no-repeat;
  display: flex;
    justify-content: flex-end;
    padding: 2em;
    margin-top: 1em;
    

`;

const HowSendLovePanel = styled.div`
  img {
    width: 15em;
  }
`;

const AnchorZone = styled.div`
  position: absolute;
    bottom: 1em;
    max-width: 350px;
    /* margin-left: -100px; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
`;

export const RegisterLink = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.68em;
  line-height: 1;
  /* identical to box height */
  color: #384274;
  margin: 0.5em;
  position: relative;
  max-width: 300px;
  margin-bottom: 1em;
  /* color: white; */
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    background: #ccffff;
  }

  
`;

const ResetPasswordLink = styled(RegisterLink)`
margin-top: 2.5em;
  font-size: 0.6em;
  background: #F2F1F1;
  border-radius: 4px;
  padding: 0.5em 0.8em;
  align-self: flex-start;
`;

const StartTracking = styled.div` 
/* text-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.75);
  background: #ff5500; */
  /* background: #d15b01;
  background: #196ebe;
  border-radius: 2px;
  font-weight: 700;
  padding: 1em 0.4em;
  margin-bottom: 1em;
  padding: 0.35em 0.58em;
  width: 100%;
  height: 30px;
  max-width: 150px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: Roboto, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  background: url('/images/dropkins/ui/buttons/tech_buttons_001.jpg') no-repeat;
  background-size: cover;
  background-position: center; */
  display: flex;

padding: 24px 43px;
justify-content: center;
align-items: center;
gap: 1px;
/* flex-shrink: 0; */
border-radius: 8px;
/* border-bottom: 1px solid white; */
/* background: #2E2E75; */
margin-right: 5px;
  cursor: pointer;
  z-index:4;
  color: #FFFFFF;
  color: black;

  &:hover {
    /* background: #02999c; */
    scale: 1.05;
    border: 1px solid #ccc;
  }

  @media screen and (max-width: ${ui.tablet}) {
    /* padding: 0.2em 0.4em; */
    font-size: 16px;
        /* height: 38px; */
padding: 6px 18px;
max-width: 30%;

  }
`;



const ReportedCasesText = styled.div`
  color:#FFFFFF;
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  flex-basis: 50%;
`;

const SubTitle = styled.div`
  font-family: Metropolis, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  z-index: 1;
  color: #FFFFFF;
  position: relative;
`;

const UpsideDownTriangle = styled.div`
    width: 100%;
    height: 20%;
    background-color: #384274;

    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: -0.052em;
    height: 33%;
`;




// 
    // End  Landing Screen
// 
// 


export const InputContainer = styled.div`
  
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5em 0;
  /* width: 50%; */
  align-self: center;
  max-width: 557px;
  width: 100%;
background: white;
gap: 25px 131px;
border-radius: 8px;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  input {

    border-radius: 8px;
border: 1px solid #C4C4C4;
background: #FBFBFB;
width: 407px;
height: 48px;
max-width: 90%;
padding: 0 1em;
/*  
    background: #F2F1F1;
    border-radius: 2px;
    font-family: Metropolis, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 0.71em;
    line-height: 3.1;
    padding: 0 0.75em;

    width: 100%;

    color: #2d2b2b;

    background: #F2F1F1;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 2px; */

    
  }
  label {
    padding: 0.2em;
  }

  @media screen and (max-width: ${ui.tablet}) {
    input {
      width: 300px;
;
    }
  }
  
`;

export const AvatarInputContainer = styled(InputContainer)`
  margin-top: 0;
  display: flex;
  max-width: 28%;
  padding-left: 1em;
  background: initial;
  border: 0;
  color: white;
  justify-content: flex-end;
  /* align-items: flex-end; */
  cursor: pointer;
  box-shadow: initial;
  
  @media screen and (max-width: ${ui.tablet}) {
    max-width: 39%;
    /* max-width: 100%;
    padding-left: 0;
    justify-content: center;
    align-items: center; */
    
  }

  input {
    background: initial;
    border: 0;
    margin-top: 0.5em;
  }
`;

const SignInContainer = styled(LoginContainer)`
  margin-top: 76px;
  img {
    padding-top: 28px;
  }
  ${({toggleSignup}) => !toggleSignup && `
    display: none;
  `}

`;

const SignUpQuote = styled.img`
    width: 100%;
    max-width: 400px;
    margin: auto;
    position: relative;
    left: 28px;
`;

const LogInQuote = styled(SignUpQuote)`
  left: 0;
`;

const SignUpText = styled.div`
  padding: 0 10vw;
  padding-top: 48px;
  padding-bottom: 11vw;
  text-align: left;
`;

const SignUpPanel = styled(BottomPanel)`

`;




const SignUpButton= styled.button`
  /* width: 8em;
  margin: auto; 
  background: #315f88;
  color: white;
  padding: 0.5em;
  margin-top: 0.25em;
  background: #73C700;
  background: #FF9300;
  border-radius: 2px;
  margin-top: 1.5em;
  width: 100%;
  font-weight: 700;
  font-size: 0.98em; */
  cursor: pointer;

  display: flex;
width: 140px;
height: 48px;
padding: 24px 40px;
justify-content: center;
align-items: center;
gap: 1px;
flex-shrink: 0;
border-radius: 10px;
background: #2E2E75;
color: white;
`;

const LoginFooter = styled.div`
  font-size: 0.7em;
  color: white;
`;

const InputRegisterLink = styled(RegisterLink)`
  color: black;
  margin-top: 1em;
  background: #F2F1F1;
  border-radius: 4px;
  padding: 0.5em 0.8em;
  align-self: center;
`;

const TermsPanel = styled.div`
  display: none;
  position: fixed;
  background: #0e163d;
  color: black;
  bottom: 60px;
  width: 100%;
  height:70%;
  max-width: 700px;
  overflow: auto;
  max-width: 80%;
  padding: 40px;
  border: 1px solid white;
  ${({ active }) => active && `
    display: flex;
    justify-content: center;
    z-index: 1;
  `}

  iframe {
    background: #fff;
    width: 100%;
  }
`;

const TermsClose = styled.div`
  position: absolute;
  top: 12px;
  color: black;
  right: 7px;
`;


export const InputLabel = styled.label`
  color: #000;
font-family: "Inter", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
`;
export const InputWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 0;
max-width: 80%;
`;

const Login = ({ history, params }) => {

  
  
  const [hideHomePanel, setHideHomePanel] = useState(false);
  const [forgotPasswordToggle, setForgotPasswordToggle] = useState(false);
  const [middlePanelToggle, setMiddlePanelToggle] = useState(false);
  const [howDropActive, setHowDropActive] = useState(true);
  const [howSendLoveActive, setHowSendLoveActive] = useState(false);
  const [howShareActive, setHowShareActive] = useState(false);
  const [howUpdatesActive, setHowUpdatesActive] = useState(false);
  const [termsToggle, setTermsToggle] = useState(false);
  const [privacyToggle, setPrivacyToggle] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const { 
    currentUser, topRef, rtytg6, signupToggle, setSignupToggle,
    loginToggle, setLoginToggle, setFiestaMessage, setTriggerShow, setNotification,
    setReferrer, referrer,
  
  } = useContext(AuthContext);

  // const { register, handleSubmit, watch, errors, getValues, clearErrors, setError } = useForm();
  const { register, handleSubmit, watch, formState: { errors }, getValues, clearErrors, setError, trigger } = useForm();


  const topPanel = React.createRef();
  const panelLogo = React.createRef();
  const startTracking = React.createRef();
  const howPanel = React.createRef();
  const middlePanel = React.createRef();
  const mainTimeline = gsap.timeline();

    // Inside your component
  const navigate = useNavigate();

  const { pathname } = useLocation();


useEffect(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}, [pathname]);

  useEffect(() => {
    
    const urlObj = new URL(window.location.href);
    const ref = urlObj.searchParams.get('ref');
    // console.log('login page', {ref})

    if(ref && ref !== 'undefined' && ref !== referrer ) {
      setReferrer(ref);
    }


  }, []);

  useEffect(() => {
    // gsap.from( startTracking.current, { y: -10, rotateY:10, duration: 2, perspective:400, backfaceVisibility: 'hidden' });
    // gsap.from( startTracking.current, 3, {
    //   scale: 1.03, 
    //   opacity: 0.8,
    //   // y: 60,
    //   yoyo: true, 
    //   repeat: -1, 
    //   ease: "power1.inOut",
    //   delay:3,
    //   stagger: {
    //     amount: 14.5, 
    //     grid: "auto",
    //     from: "center"
    //   }
    // });
  }, []);

  const [ customError, setCustomError ] = useState(null);

  const watchedEmail = watch('emailSignUp');
  const watchedPassword = watch('passwordSignup');
  const watchedPasswordMatch = watch('passwordMatch');
  useEffect(() => {
    clearErrors();
    setCustomError('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (watchedEmail && !emailRegex.test(watchedEmail)) {
      setError("emailSignUp", "invalid", "Invalid Email");
      setCustomError('Invalid Email');
    } else {
      clearErrors("emailSignUp");
    }

    if (watchedPassword && watchedPassword.length < 6) {
      setError("passwordSignup", "invalid", "Password must be at least 6 characters");
      setCustomError('Password must be at least 6 characters');
    } else {
      clearErrors("passwordSignup");
    }

    if (watchedPasswordMatch && watchedPassword && watchedPasswordMatch === watchedPassword) {
      clearErrors("passwordMatch");
      setCustomError('');
    }

    // if (watchedPasswordMatch && watchedPassword && watchedPasswordMatch !== watchedPassword) {
    //   setError("passwordMatch", "notMatch", "Password Don't Match");
    //   setCustomError("Password Don't Match");
    // }
  }, [watchedEmail, watchedPasswordMatch, watchedPassword, setError, clearErrors]);

  const handleLogin = useCallback(
    async () => {
      // console.log('handle called')
      const { email, password } = getValues();
      // const { email, password } = event.target.elements;
      try {
        console.log('Trying to log in...');
        const userCredential = await signInWithEmailAndPassword(kindAuth, email, password);
        // console.log('User Credential:', userCredential); // Log the user credential
  
        firebase.analytics().logEvent('Logging in');
        navigate('/');
      } catch (error) {
        console.error('Error during login:', error); // Log the error
        setError("email", "general", error.message);
      }


      // try {

      //   // console.log('trying to log in')
      //   await signInWithEmailAndPassword(kindAuth, email, password);
      //   firebase.analytics().logEvent('Logging in');
      //   navigate('/');
      //   // history?.push('/');
      // } catch (error) {
      //   // console.log(error)
      //   setError("email", "general", error.message);
      // }
    }, []
  );

  const errorMessage = {
    'auth/email-already-in-use': 'Email already in use',
    'auth/invalid-email': 'Invalid email address',
    'auth/weak-password': 'Password must be at least 6 characters',
    'auth/operation-not-allowed': 'Operation not allowed',
    'auth/user-disabled': 'User disabled',
    'auth/user-not-found': 'User not found',
    'auth/wrong-password': 'Wrong password',
  }

  const handleSignUp = useCallback(async () => {
    // event.preventDefault();
    // console.log('signing up')
    // console.log('trying sign up')
    const { emailSignUp, passwordSignup, passwordMatch } = getValues();
  // console.log('trying sign up')

  const checkEmailSignup = await trigger('emailSignUp');
  const passCheckUp = await trigger('passwordMatch');
  // console.log(passwordMatch, passwordSignup)
  // console.log('checkEmailSignup', checkEmailSignup, passCheckUp)
  if(passwordSignup !== passwordMatch) {
    setError("passwordMatch", "notMatch", "Password Don't Match");
    setCustomError("Password Don't Match")
    return;
  }
  if(!checkEmailSignup || !passCheckUp) {
    return;
  }

    try {
       const newUser = await createUserWithEmailAndPassword(kindAuth, emailSignUp, passwordSignup);
      // console.log('new user', {newUser})

      // Get a reference to the Firestore document
        const docRef = doc(kinDb, "customers", newUser.user.uid);
        if(!docRef) {
          // console.log('doc ref not found, could not add free ride')
          navigate('/');
        }

        // Set the user data in Firestore
        await setDoc(docRef, {
          freeRides: 8,
          // Add any other user data you want to store
        }, { merge: true });
      

        // firebase.analytics().logEvent('Creating User');
        navigate('/');
    } catch (error) {
      // console.log('handle signup', {error})
      setError("emailSignUp", "general", error.message);
      setCustomError(errorMessage[error?.code]);
    }
  }, []);


  const handleForgotPassword = useCallback(
    async () => {
      console.log('forgot called')
      const { emailForgotten } = getValues();
      // const { emailForgotten, password } = event.target.elements;
      try {
        const passReset = await resetPass(emailForgotten);
        // console.log({passReset})
        if(passReset.success) {
         await  setCustomError('Password reset link sent to your email');
          await setError("email", "general", 'Password reset link sent to your email');
          console.log('pass reset success')
          
          // await goToLogin();
        } else {
          await  setCustomError('Failed To Send');
          await setError("email", "general", 'Failed To Send');
          console.log('Failed to send')
        }
        // firebase.analytics().logEvent('Sending Forgot Password');
        // history?.push('/');
        // navigate('/');
      } catch (error) {
        // console.log(error)
        // map error message to key
        // console.log('error', {error})
        setCustomError(errorMessage[error?.code]);
        setError("emailForgotten", "general", error.message);
      }
    }, []
  );


  const containerRef = useRef();

  

  useEffect(() => {
    const handleScroll = () => {
      // Get scroll values
      const { scrollTop, clientHeight, scrollHeight } = topRef?.current;  
  
      // Check if scrolled to bottom
      if (scrollTop + clientHeight >= scrollHeight) {
        setIsScrolledToBottom(true);
      } else {
        setIsScrolledToBottom(false); 
      }
    };
  
    // Add event listener
    topRef?.current?.addEventListener('scroll', handleScroll);
  
    // Remove on unmount
    return () => {
      topRef?.current?.removeEventListener('scroll', handleScroll);
    }
  }, []);


  const clearActiveHow = async () => {
    await setHowDropActive(false);
    await setHowSendLoveActive(false);
    await setHowShareActive(false);
    await setHowUpdatesActive(false);
  }


  

  const goToSignUp = () => {
    setSignupToggle(true);
    setLoginToggle(false);
    setHideHomePanel(true);
    
    // scroll to top of page 
    if (topRef.current) {
      topRef.current.scrollTop = 0
    } else {
      console.error('topRef is not attached to an element');
    }
    // history.push('/signup');
    // navigate('/signup');
  }

  const goToLogin = () => {
    // console.log('heey')
    // history.push('/login');
    // navigate('/login');
    setHideHomePanel(true);
    setLoginToggle(true);
    setSignupToggle(false);
    setForgotPasswordToggle(false);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    if (topRef?.current) {
    topRef.current.scrollTop = 0
  } else {
    console.error('topRef is not attached to an element');
  }
  }

  
  const goToHome = () => {
    setHideHomePanel(false);
  
    // Scroll to the top of the page
    if (topRef.current) {
    topRef.current.scrollTop = 0
    }
  
    // change route to home
    navigate('/');
  
    setLoginToggle(false);
    setSignupToggle(false);
  }

  

  const notifier = async (data) => {
    await setNotification(data);
  };



  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };

  if (currentUser) {
    // console.log('current', {currentUser})
    return <Navigate to='/' />;
  }

  
  const getDateText = () => {
    return 'March 20, 2020';
  }
  

  
  
  return (
    <LoginPage ref={topRef}>
      <Header  isScrolledToBottom={isScrolledToBottom}>
        <HeaderLogo onClick={() => goToHome()}>
          <img src='/images/dropkins/branding/dropkins.png' />
          {/* <img src='/images/dropkins/branding/dropkins_logo_v01.jpg' /> */}

        </HeaderLogo>

        
        <HeaderNav>
        <StartTracking  ref={startTracking} onClick={() => goToSignUp()}>
                Register
              </StartTracking>

          <HeaderLogin onClick={() => goToLogin()}>
            Log In
          </HeaderLogin>
        </HeaderNav>
      </Header>

      <LandingScreen toggleLogin={!hideHomePanel}>
        <TopPanel ref={topPanel}>
          {/* <TopBg /> */}

          <HomePanelContainer toggleLogin={hideHomePanel}>

           <PanelHeader>

              {/* A friends + friends of friends platform. */}


              {/* <PanelLogo ref={panelLogo} src="images/2degrees/homepage/community_powered.svg" alt=""/> */}
            </PanelHeader> 

            {/* <PanelFocusText>
              COVID-19
            </PanelFocusText> */}
            {/* <PanelSubHeader>
              

            </PanelSubHeader> */}

            <PanelAction >
             

              {/* <StartTracking  ref={startTracking} onClick={() => setLoginToggle(!loginToggle)}>
                Sign In
              </StartTracking> */}

              {/* 
              <RegisterLink onClick={goToSignUp}>
                Don’t have an account? <strong>Register here</strong>
              </RegisterLink> */}
            </PanelAction>
          
            {/* <HomeStatsContainer>
              {/* <HomeStats>
                <StatsNumber>1,000</StatsNumber>
                <StatsText>Reported Cases</StatsText>
                <StatsDate>{getDateText()}</StatsDate>
              </HomeStats> 
            </HomeStatsContainer> */}
            {/* <HomeMessageContainer>
            Help keep each other safe. Get an alert from your community and stay informed with real-time community sourced COVID info.
            </HomeMessageContainer> */}
            
            {/* <HomeGraphicContainer>
              <img src="/svgs/home-covid-buddy-b.svg" alt=""/>
              </HomeGraphicContainer> */}
          </HomePanelContainer>
          

      <LoginContainer className="login-form" toggleLogin={!loginToggle}>
        <LoginFormContainer className="forgot-password-form" toggleLogin={!forgotPasswordToggle}>
            {/* <FormLogo src="/svgs/home/login/login-header.svg" alt=""/> */}
          <GlowBox>
          <LoginForm onSubmit={handleSubmit(handleLogin)}>
        
            {/* <!-- For more features use the AutoHTML plugin window in figma ... -->  */}


          {/* <FormSubHeader>Track places you've visited, and recieve alerts if possibly exposed.</FormSubHeader> */}
              <InputContainer toggle={false}>
                {/* <FormLabel></FormLabel> */}
                <PanelHeader>
                  <PanelTitle>

              Sign In
                  </PanelTitle>
                  <PanelSubHeader>
                    Lets get you back in!
                    </PanelSubHeader>
            </PanelHeader>

                <InputWrapper>
              <InputLabel>
                Email
              </InputLabel>
                {errors.email && errors.email.type && <ErrorField> {errors.email.message}</ErrorField> } 
                {errors?.email?.type && <ErrorField> {errors.email.message}</ErrorField> } 
                {errors.message && <ErrorField>{errors.message}</ErrorField>}
                {errors.email && !errors.email.type && <ErrorField>Not quite right, try again? {errors.email.message}</ErrorField> }
                 {/* // Display general email error here */}
                <input {...register("email", { required: true })} autoComplete="off" type="email" placeholder="UserName/Email" />
                </InputWrapper>
                {/* <input autoComplete='false' type="email" name="email" placeholder="UserName/Email"  ref={register({ required: true })} /> */}
                {/* <input {...register("email", { required: true })} autoComplete="off" type="email" placeholder="UserName/Email" /> */}
                {/* <FormLabel></FormLabel> */}
                <InputWrapper>
              <InputLabel>
                Password
              </InputLabel>               
                {errors.password && <ErrorField>{errors.password.message} </ErrorField>}

                <input {...register("password", { required: true })} autoComplete="off" type="password" placeholder="Password" />
             
                <ResetPasswordLink  onClick={() => {setForgotPasswordToggle(!forgotPasswordToggle)}}>
                Forgot Password? Click here to reset!
              </ResetPasswordLink>
                </InputWrapper>
                <PanelHeader>
              <InputRegisterLink onClick={goToSignUp}>
                  Don’t have an account? <strong>Register here</strong>
              </InputRegisterLink>
              <LogInButton type="submit" onClick={() => handleLogin()} >Log In</LogInButton>
              </PanelHeader>

             

            </InputContainer>
            
          </LoginForm>
          </GlowBox>
        </LoginFormContainer>

        <ForgotPasswordContainer className="forgot-password-form" toggleLogin={forgotPasswordToggle}>
          <ForgotPasswordForm onSubmit={handleSubmit(handleForgotPassword)}>
          {/* <PanelHeader>
              Social Distancing
            </PanelHeader> */}
          {/* <FormSubHeader>Track places you've visited, and recieve alerts if possibly exposed.</FormSubHeader> */}
            {/* <FormLogo src="/svgs/home/login/login-header.svg" alt=""/> */}
            <InputContainer>
            {customError && <ErrorField>{customError}</ErrorField>}
            <ActiveContentHeader style={{color: 'black'}}>
              Enter Your Email To Receive reset.
            </ActiveContentHeader>
                {/* <FormLabel></FormLabel> */}
                {errors.emailForgotten && errors.emailForgotten.type && <ErrorField> {errors.emailForgotten.message}</ErrorField> } 
                {/* <input autoComplete='false' type="email" name="emailForgotten" placeholder="Enter Forgotten Account Email"  ref={register({ required: true })} /> */}
                <input {...register("emailForgotten", { required: true })} autoComplete="off" type="email" placeholder="Enter Forgotten Account Email" />
                {/* <FormLabel></FormLabel> */}
              
              <ResetPasswordButton type="submit" onClick={ () => {
                const check = Object.keys(errors).length;
                if (!check){ console.log(check); handleForgotPassword()}}}>
                  Send Reset Email
              </ResetPasswordButton>


              <InputRegisterLink onClick={goToSignUp}>
                Don’t have an account? <strong>Register here</strong>
            </InputRegisterLink>
            </InputContainer>
          </ForgotPasswordForm>
        </ForgotPasswordContainer>
          <GoBack onClick={() => goToHome()}>
            Close X
          </GoBack>
      </LoginContainer>

      <SignInContainer toggleSignup={signupToggle} className="sign-up">
       {/* <PanelHeader>
            Social Distancing Buddy
          </PanelHeader>
          <PanelFocusText>
            COVID-19
          </PanelFocusText> */}
          {/* <FormLogo src="/svgs/home/signup/signup-header.svg" alt=""/> */}
          <GlowBox>
            
          <SignUpForm onSubmit={handleSubmit(handleSignUp)}>
          {/* <PanelHeader>
             
            </PanelHeader> */}
          {/* <PanelFocusText>
            As a healthy alternative,
            we've set out to develop a community driven platform with emphasis on privacy. 
            As such this email serves solely for the purpose of personalizing your account.
          </PanelFocusText> */}
          <FormHeader>
            
          </FormHeader>
          
          {/* <input type="submit" /> */}

          <InputContainer>
             {/* <InputWrapper> */}
          <PanelHeader>
                  <PanelTitle>

              Register
                  </PanelTitle>
                  <PanelSubHeader>
                  Lets get you setup! :)
                    </PanelSubHeader>
            </PanelHeader>
            {/* </InputWrapper> */}
             <InputWrapper>
              {customError && <ErrorField> {customError}</ErrorField> }
              {errors.errorMessage && <ErrorField>errors.errorMessage.message</ErrorField>}
              <InputLabel>
                Email
              </InputLabel>
              {errors.emailSignUp && errors.emailSignUp.type && <ErrorField> {errors.emailSignUp.message}</ErrorField> } 
              <input {...register("emailSignUp", { required: true })} type="email" placeholder="Email" />
             </InputWrapper>
            
             <InputWrapper>
              <InputLabel>
                Password
              </InputLabel>
              <input type="password"

                onChange={e => {
                  const value = e.target.value
                  // this will clear error by only pass the name of field
                  if (value === watch('passwordMatch')) return clearErrors("passwordMatch")
                  // set an error with type and message
                  setError("passwordMatch", "notMatch", "Password Don't Match");
                }}
                
              name="passwordSignup"  {...register('passwordSignup',{ required: true })}  placeholder="Password" />
            {errors.passwordSignup && <ErrorField>Required</ErrorField>}
            </InputWrapper>
            <InputWrapper>
              <InputLabel>
                Verify Password
              </InputLabel>
            <input type="password" {...register('passwordMatch', { required: true })} name="passwordMatch" placeholder="Verify Password" 
              onChange={e => {
                const value = e.target.value
                // this will clear error by only pass the name of field
                if (value === watch('passwordSignup')) return clearErrors("passwordMatch")
                // set an error with type and message
                setError("passwordMatch", "notMatch", "Password Don't Match");
              }}
            />
            {errors.passwordMatchSignUp && errors.passwordMatchSignUp.type === "required" &&
            <ErrorField>Required</ErrorField> }
            {errors.passwordMatchSignUp && errors.passwordMatchSignUp.type && <ErrorField> {errors.passwordMatchSignUp.message}</ErrorField> } 
            </InputWrapper>

            <PanelHeader>
            <InputRegisterLink onClick={goToLogin}>
                Already have an account? <strong>Log In Here</strong>
            </InputRegisterLink>
            <SignUpButton type='submit' onClick={(e) => { 
              e.preventDefault();
               handleSignUp()}
               }>
              Sign Up
            </SignUpButton>
          </PanelHeader>

          </InputContainer>
        
        
        </SignUpForm>
        </GlowBox>
        <GoBack onClick={() => goToHome()}>
            Close X
          </GoBack>
      </SignInContainer>

            
        {/* <HomeScroller goToSignUp={goToSignUp} /> */}


        </TopPanel>
      <HomeLandingBanner />
        <HomeTop goToSignUp={goToSignUp}  />

        <HomeMiddleB />
        <HomeMiddle />
        <HomeMiddleC />
        <MiddlePanel ref={middlePanel}>
          {/* <UpsideDownTriangle /> */}
          {/* <MidBg src='svgs/home/top/cta-bg.svg'>

          </MidBg> */}
          <MiddlePanelCTA  togglePanel={!hideHomePanel}>
          {/* <ImageAttribution>
              Photo by Tim Mossholder, Oregon <ImageFormLink target="_blank" href="https://forms.gle/UHqzCXS3RGYJPJVW7">SUBMIT YOUR ART</ImageFormLink>
            </ImageAttribution> */}


  {/* <HeroImage src="images/2degrees/homepage/map_feature.jpg" alt=""/> */}



            {/* <TextSection>

  <GlowBox>
  
  <ContentBox className="typewriter"> Welcome to Dropkins! 
  <br />
  We're more than just a platform; we're your community.
  </ContentBox>
  <ContentBox className="typewriter"> 
   Our mission? To bring people closer
   through convenient services like
    transportation, while re-imagining our economy and sense of community.
    </ContentBox>
   <ContentBox className="typewriter"> 
   Imagine easily finding a ride with someone you trust – like a neighbor, coworker, or friend. 
  <br />
  <br />
   
   With Dropkins, that's a reality. Build your network and access a world of free, donation-based, or paid rides.
   </ContentBox>
   <HeroImage src="images/2degrees/homepage/fancy_catching_rides.jpg" alt=""/>
   <ContentBox className="typewriter"> 
    And it's not just about rides. We're constantly adding new 
    
    features to keep you informed about local happenings, like the whereabouts of hard-to-find items or potential hazards.
   </ContentBox>
   <ContentBox className="typewriter"> 
    By using this service and inviting your friends, you can {' '}
    <strong>earn 3%</strong> of the fees for every ride they make! 💰
   </ContentBox>
   <ContentBox className="typewriter"> 
     That's passive income for just spreading the word. 😊
   </ContentBox>
   <HeroImage src="images/2degrees/homepage/earn_prizes.jpg" alt=""/>
   <ContentBox className="typewriter"> 
   Drivers using Dropkins, keep more of what they earn, setting us apart from other platforms. And with the integration of AI, we're not just changing the way you travel; we're transforming our economy.
  </ContentBox>
  <ContentBox className="typewriter"> 
  Join Dropkins today. Together, let's drive towards a connected, prosperous community.
  </ContentBox>
  <StartTracking  ref={startTracking} onClick={() => goToSignUp()}>
  
                Join Today!
              </StartTracking>
  </GlowBox>
  
  <p>Rides can be requested both free and paid.</p>
  <p>For whatever reason, we've found a pay option,helps incentivize rides, and extends an option to get paid for doing your part to help your community.</p>
  <HeroImage src="images/2degrees/homepage/win_prizes.jpg" alt=""/>
  <p>As we are about community, there is a free option as well as a donation based option. So instead of asking friends or coworkers for a quick ride home individually, can easily ask them all at once.

     </p>
</TextSection> */}
           

            
{/*             
            With the world on the brink of signing our privacy rights away through invasive tracking, 
          we have developed a crucial alternative to large scale surveillance systems such as the Apple + Google tracing initiative. */}
            
            
            

             {/* <MiddleTogglePanel toggle={middlePanelToggle}>
             <TextSection>
           */}
    {/* <p>Say goodbye to solo drives and hello to a more sustainable and community-driven way of traveling. By sharing rides, we not only reduce traffic congestion but also minimize our carbon footprint, making a positive impact on the environment.</p> */}
    {/* <HeroImage src="images/2degrees/homepage/get_a_ride.jpg" alt=""/> */}
    {/* <p>Our intuitive app matches you with drivers within degrees of separation and based on your location, schedule, and preferences.</p> */}
    
     {/* <p>Whether you need a daily ride to work or an occasional trip to an event, our extensive network of verified users ensures a reliable and enjoyable journey.</p> */}

        {/* </TextSection> */}

        {/* <TextSection>
<p>
    Imagine a community board that serves as a hub for essential local updates and resources. 
    
    </p>
    <p>
    It's a place where people can post and find critical information such as food distribution points, 
    shelter options, fundraising efforts, safety check-ins, alerts about hazards like contaminated water, 
    and various other community notices.

</p>
    <p>
    This way efforts of any size, from established organizations to pop up or mobile efforts, can easily be shared and found.
    <br/><br/>  

    We have been working tirelessly to get this out as soon as possible. 
    <br/><br/>

   


    Please let us know if you have any other feedback on how we can improve or what additional features would be most helpful during this time. 
    <br/><br/>

    <StartTracking  ref={startTracking} onClick={() => goToSignUp()}>
  
                - Sign Up -
              </StartTracking>
</p>
 
  </TextSection> */}
            {/* </MiddleTogglePanel> */}

         

{/* <MiddlePanelExpandToggle onClick={ () =>  setMiddlePanelToggle(!middlePanelToggle)}>{middlePanelToggle ? `Read Less` : `Read More`}</MiddlePanelExpandToggle> */}
          </MiddlePanelCTA>
        </MiddlePanel>
        {/* <BottomPanel togglePanel={hideHomePanel && signupToggle}>
            <SignUpQuote src={'svgs/home/signup/signup-quote.svg'} />
            <TextSection>
            <SignUpText>
              Dropkins allows users to leverage their network of friends for rides, while also offering an ever growing feature set like location based notifications.
            </SignUpText>
            </TextSection>
        </BottomPanel> */}
        {/* <BottomPanel togglePanel={hideHomePanel && loginToggle}>
            <LogInQuote src={'svgs/home/login/login-quote.svg'} />
            <TextSection>
              <SignUpText>
                Welcome Back! Looking to catch a ride today? Or are you more of a driver? Either way, you're in the right place.
              </SignUpText>
            </TextSection>
        </BottomPanel> */}
        {/* <BottomPanel ref={howPanel} togglePanel={false}>
          <BottomPanelContainer>
            
          </BottomPanelContainer>
          <BottomPanelContainer>
            <BottomPanelHow>
              <How active={howDropActive} onClick={ async () => {
                  await clearActiveHow();
                 setHowDropActive(!howDropActive)
                 }}>
                <img src="/svgs/how-drop-a-pin.svg" alt=""/>
              </How>
              <How active={howSendLoveActive} onClick={ async() => {
                  await clearActiveHow();
                   setHowSendLoveActive(!howSendLoveActive)
                  }}>
                <img src="/svgs/how-send-love.svg" alt=""/>
              </How>
              <How active={howShareActive} onClick={ async() => {
                  await clearActiveHow();
                   setHowShareActive(!howShareActive)
                  }}>
                <img src="/svgs/how-share-resources.svg" alt=""/>
              </How>
           
            </BottomPanelHow>

          </BottomPanelContainer>


            <ActiveHowPanel active={howDropActive}>
             
              <HowSubHeader>
                <img src="/svgs/home/how/remember-header.svg" alt=""/>
              </HowSubHeader>
              <HowActiveCTA>
              Drop a pin on the map before you enter a highly trafficked public spaces to receive alerts in your area. 
              Your check-in markers are not visible to others . If you decide to alert your community the last 14 days of 
              dropped pins will show up as anonymous color coded markers.
              </HowActiveCTA>
            
             

            </ActiveHowPanel>

            <ActiveHowPanel active={howSendLoveActive}>
              <SendLoveSubHeader>
                <img src="/svgs/home/how/send-love-header.svg" alt=""/>
              </SendLoveSubHeader>
              <HowActiveCTA>
                You can thank or recommend someone with personal messages, positive reviews or in the future send them a tip!
                
              </HowActiveCTA>
           
              <HowSendLoveMap>
                <HowSendLovePanel>
                  <img src={`/images/home/how/send-love-panel.png`} />
                </HowSendLovePanel>
              </HowSendLoveMap>
              
            </ActiveHowPanel>

            <ActiveHowPanel active={howShareActive}>
              <HowHeader>
                <img src="/svgs/home/how/help-neighbor-header.svg" alt=""/>
              </HowHeader>
              <HowActiveCTA>
                We've all run into empty racks, alert your community when you see one of these much needed items. By selecting an item then submitting a location. The alert will wil be public for 30 minutes at a time. You can also submit items you’re looking for in the provided form.
              </HowActiveCTA>
              
            </ActiveHowPanel>

            

         
          <ReportedCasesText>
            

          </ReportedCasesText>

        
          
        </BottomPanel> */}

        {/* <TogetherPanel togglePanel={!hideHomePanel}>
          <TogetherPanelContainer>
              <TogetherHeader>
                <img src="/svgs/home/bottom/take-action.svg" alt=""/>
              </TogetherHeader>
              <TogetherPanelCTA>
              <TextSection>
              <HeroImage src="images/2degrees/homepage/eco_friendly.jpg" alt=""/>

    <p>Join our growing community and start saving time, money, and energy. Together, let's create a more abundant and connected world, one ride at a time, with Dropkins.</p>
    <p>We have been working tirelessly to introduce and foster this alternative, and would love your feedback on how we can make Dropkins even better.</p>
      <StartTracking  ref={startTracking} onClick={() => goToSignUp()}>
    
        - Sign Up -
      </StartTracking>
    </TextSection>
              </TogetherPanelCTA>

            </TogetherPanelContainer>
        </TogetherPanel> */}

        

        <AnchorPanel togglePanel={true}>
          <AlertExample>
            {/* <AlertPanel>
              <img src='svgs/home/bottom/alert-example-panel.svg' />
            </AlertPanel> */}
            {/* <AlertMarker>
              <img src='svgs/home/bottom/alert-marker.svg' />
            </AlertMarker> */}
          </AlertExample>

        </AnchorPanel>

        <AnchorZone>
        
          {/* <LoginFooter>
            Stay informed with real-time community sourced COVID info.
          </LoginFooter> */}
        </AnchorZone>
      </LandingScreen>
     
      <TermsPanel active={termsToggle}>
        <TermsClose>{ClosePanel(setTermsToggle)}</TermsClose>
        <TermsAndConditions isOpen={termsToggle} />
        {/* {Terms()} */}
            {/* <iframe src="https://app.termly.io/document/terms-of-use-for-website/aaf8775d-6969-4662-a8bf-fee77acb3fb4" frameBorder="0"></iframe> */}
        {/* <div name="termly-embed" data-id="dc1ec492-b2f1-4566-ab3d-df562933fafd" data-type="iframe"></div> */}
      </TermsPanel>
      <TermsPanel active={privacyToggle}>
        <TermsClose>{ClosePanel(setPrivacyToggle)}</TermsClose>
        <PrivacyPolicy isOpen={privacyToggle} />
        
        {/* <iframe src="https://app.termly.io/document/privacy-policy/dc1ec492-b2f1-4566-ab3d-df562933fafd" frameBorder="0"></iframe> */}
      </TermsPanel>
      
      <Footer>
            <FooterLink onClick={ () => setTermsToggle(!termsToggle)}>Terms & Conditions</FooterLink>
            <FooterLink onClick={ () => setPrivacyToggle(!privacyToggle)}>Privacy Policy</FooterLink>
            {/* <FooterLink id="termly-consent-preferences" onClick={ () => {
              try{

                window.displayPreferenceModal()
              } catch (error) {
                console.log(error)
              }
              
              }}>Manage Cookie Preferences</FooterLink> */}
            
             
      </Footer>
    </LoginPage>
  )
}



export default withRouter(Login);
