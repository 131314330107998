
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
// Import css files
import styled from '@emotion/styled';

import ReactMapGL, { Marker, Popup, NavigationControl, Source, Layer } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { WebMercatorViewport } from 'viewport-mercator-project';
import * as turf from '@turf/turf';
import * as ui from '../../../styles/uiVars';
import { AuthContext } from '../../../Auth';

import 'mapbox-gl/dist/mapbox-gl.css';

// Polyfill for mapbox-gl on browsers that don't support WebGL.
// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

// import MapboxWorker from 'workerize-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

// mapboxgl.workerClass = MapboxWorker;


// const YOUR_DISTANCE_THRESHOLD = 6; // in metres
const YOUR_DISTANCE_THRESHOLD = 20; // in metres

const YOUR_OFF_COURSE_THRESHOLD = 120; // in metres


 const PassengerMapContainer = styled.div`
  position: relative;
  /* top: 0; */
  /* height: 360px; */
  /* max-height: 80vh; */
  /* max-width: 70vw; */
  /* width: 60vw; */
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  /* flex-wrap: wrap; */
  
  /* top: 0;
  left: 0; */
  flex-basis: 100%;

  background: url('/images/dropkins/landing/bg/home_bg_001.jpg') no-repeat;

  border: 2px solid #21339a;
  height: auto;
  /* width: ${props => props.isFullscreen ? '80vw' : '76vw'}; */
  /* height: ${props => props.isFullscreen ? '90vh' : 'auto'}; */
  /* max-height: ${props => props.isFullscreen ? '100%' : '80vh'}; */
  /* max-width: ${props => props.isFullscreen ? '100%' : '70vw'}; */
  /* min-width: ${props => props.isFullscreen ? '66vw' : '100%'}; */
  z-index: ${props => props.isFullscreen ? '9999' : 'auto'};
  /* min-height: ${props => props.isFullscreen ? '400px' : '400px'}; */
  
  flex-direction: ${props => props.isFullscreen ? 'column' : 'row'}; 
  
  
  /* flex-direction: ${props => props.isFullscreen ? 'column' : 'row'};  */
  @media screen and (max-width: ${ui.tablet})  {
    flex-direction: column;
    /* flex-direction: column;
    /* flex-direction: ${props => props.isFullscreen ? 'row' : 'column'}; 
    min-height: 400px;
    ${({isFullscreen}) => isFullscreen && `

          height: 700px;

      `} */
  }
  /* @media screen and (max-width: ${ui.mobile})  { */
    /* flex-direction: ${props => props.isFullscreen ? 'row' : 'column'}; */
    /* min-width: 300px; */
    /* height: 500px; */
    /* ${({isFullscreen}) => isFullscreen && `

height: 100vh;

`} */
    
  /* } */

  
  
`;

const PassengerMapWrapper = styled.div`
  /* height: 100%; */
  /* min-height: 100%; */
  /* height: 360px; */

  width: ${props => props.isFullscreen ? '100%' : '100%'};
  height: ${props => props.isFullscreen ? '60vh' : '36vh'};
  
  /* flex-basis: ${props => props.isFullscreen ? '100%' : '60%'}; */

`;

 const NavDirectionDetailsContainer = styled.div`
  position: relative;
  padding: 0.25em 0.5em;
  cursor: pointer;
  flex-basis: 40%;
  line-height: 1.1;
  &:hover {
    background: #1c3258;
    color: white;
    font-weight: 600;
  }
  /* display: flex; */

  ${({ toggle }) => toggle ? `
  display: initial;

  ` : `
  display: none;
  `
  }


  ${({ active }) => !active && `
   &:nth-of-type(odd) {
      background: #333e54b9;
    }
  `}
  ${({ active }) => active && `
    background: #041431bd;
    color: white;
    font-weight: 800;
    border-right: 4px solid #33486e;
   
  `}
  


/* &:first-of-type {
    border-right: 3px solid #4d5a71;
    background: #1c222c;
  } */
`;

  const DriverImg = styled.img`
    width: 100%;
    height: auto;
    border-radius: 50%;
  `;

   const DriverMarker = styled.div`
    height: 36px;
    width: 36px;
  `

 const ActiveMapBanner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  background: #242433a0;
  
  padding: 0.75em;
  display: flex;
  flex-wrap: wrap;
  max-width:300px;

`;
 const NavInstruction = styled.div`
  position: relative;

`;
 const NavFullScreenToggleButton = styled.div`
  position: relative;
  background: #231;

position: absolute;
right: 0;
padding: 0.36em;
margin: 0.25em;
border: 1px dotted #b6b2b2;
background: ${props => props.isFullscreen ? '#324' : '#507764' };

/* &:hover {
  background: #384;
} */
`;
 const NavDistance = styled.div`
  position: relative;

`;


 const NavDirectionsContainer = styled.div`
  position: relative;
  top: 0;
  /* height: 300px; */
  /* max-height: 100%; */
  border-left: 1px dotted #ccc; 
  
   margin: 0.25em;
   margin-bottom: 0.75em;
   flex-basis: ${props => props.isFullscreen ? '100%' : '40%'};
   max-height: ${props => props.isFullscreen ? '300px' : '260px'};
   background: ${props => props.isFullscreen ? '#445c94a2' :'#203b784f'};
   /* top: ${props => props.isFullscreen ? '-860px' :'0'}; */
   /* flex-wrap: wrap; */
   
   max-width: ${props => props.isFullscreen ? '46vw' : '400px'};
  /* max-width: 400px; */
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  overflow: hidden;
  overflow-y: scroll;

  /* background: #24314f; */

  align-self: center;
  @media screen and (max-width: ${ui.tablet})  {
    /* flex-direction: row; */
    min-height: 200px;
    max-width: 100%;
  }

  
`;


function calculateDistance(lat1, lon1, lat2, lon2) {
  if(!lat1 || !lon1 || !lat2 || !lon2) {
    return;
  }
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // in metres
  return distance;
}














// 

// Main Component

// 
















export const PassengerMap = ({ currentRideStarted, useCurrentLocation = false}) => {



  const [shouldFollowUser, setShouldFollowUser] = useState(false);
  const [lastRouteRequest, setLastRouteRequest] = useState({ start: null, end: null });
  const [ start, setStart ] = useState(null);
  const [ end, setEnd ] = useState(null);
  const [ lastEnd, setLastEnd ] = useState(null)
  const [ toggleFullScreen, setToggleFullScreen ] = useState(false);

  
  const [userLocation, setUserLocation] = useState(null);
  const [directions, setDirections] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  
  
  const mapRef = useRef();
  const {
    setNotification,
    setFiestaMessage,
    userData,
    
    
  } = useContext(AuthContext);
  
  const [currentRide, setCurrentRide] = useState(userData?.activePassenger || null);
  
  const [viewport, setViewport] = useState({
 
       width: '100%',  // Width of the viewport
    height: '100%', // Height of the viewport
    longitude: -122.45, // Longitude of the center of the viewport
    latitude: 37.78, // Latitude of the center of the viewport
    zoom: 17, // Zoom level

  });

  

  const [route, setRoute] = useState(null);


  const [mapLoaded, setMapLoaded] = React.useState(false);

  const onMapLoad = React.useCallback(() => {
    setMapLoaded(true);
  }, []);
  

  useEffect(() => {
    const setUpMap = () => {
      console.log('setting up map')
      // setShouldFollowUser(true);
      setStart({latitude: userData?.activePassenger?.driverLocation?.lat, longitude: userData?.activePassenger?.driverLocation?.lng})
      // console.log('setting up map', start)
      if(currentRideStarted && currentRideStarted === true) {
        setShouldFollowUser(true);
        setEnd(userData?.activePassenger?.dropLocation)
        fetchRoute({latitude: userData?.activePassenger?.driverLocation?.lat, longitude: userData?.activePassenger?.driverLocation?.lng}, userData?.activePassenger?.dropLocation);
        // console.log('setting up map end', start)
      } else {
        setShouldFollowUser(true);
        setEnd(userData?.activePassenger?.location)
        fetchRoute({latitude: userData?.activePassenger?.driverLocation?.lat, longitude: userData?.activePassenger?.driverLocation?.lng}, userData?.activePassenger?.location);
        // console.log('setting up map end', end)
      }

    }
   
    
    setUpMap();
  }, []); // Rerun effect if start or end changes

  
useEffect(() => {

  const setMapRideUpdate = () => {
  // console.log('useEffect ran currentRide Started', currentRideStarted);
  // console.log('userData?.currentRide.started:', userData?.activePassenger.started);
  // console.log('currentstart change', userData?.activePassenger?.location, userData?.activePassenger?.dropLocation)


  if (userData?.activePassenger.started !== currentRide?.started) {
    console.log('currentRide has changed differs');
    setCurrentRide(userData?.currentRide);
  }
  
  if(currentRideStarted && currentRideStarted === true) {
    console.log('currentRide started has changed to true');
    setEnd(userData?.activePassenger?.dropLocation)
    setShouldFollowUser(true);
    fetchRoute(start, userData?.activePassenger?.dropLocation);
   
    } else {
      
      console.log('currentRide started has changed to false');
      setEnd(userData?.activePassenger?.location)
      setShouldFollowUser(true);
      fetchRoute(start, userData?.activePassenger?.location)
  
  }
}
return setMapRideUpdate()


}, [currentRideStarted]);

  useLayoutEffect(() => {
    // console.log('useLayoutEffect ran',toggleFullScreen)
    setViewport(prevState => ({
      ...prevState,
      width: toggleFullScreen ? '100vw' : '100vw',
      // height: toggleFullScreen ? '100vh' : '100%',
    }));
  }, [toggleFullScreen]);

  //   // 


    const resetNotifications = async () => {
      setNotification(null);
      setFiestaMessage(null);
    };
    const notifier = async (data: any) => {
      await setNotification(data);
    };
    const fiesta = async (data: any) => {
      await setFiestaMessage(data);
    };
    

    // 

  // Define the source and layer for the route line
  const geojsonSource = {
    type: 'geojson',
    data: route,
  };

  const routeLayer = {
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#09a6cd',
      'line-width': 8,
      'line-opacity': 0.8,

    },
  };

  // Function to geocode address
  const geocodeAddress = async (address, returnObject = false) => {
    if(!address) {
      return;
    }
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    );
    const json = await query.json();
    const [longitude, latitude] = json?.features[0]?.center || [];
    return returnObject ? { longitude, latitude } : json?.features[0]?.center;
  };

  const fetchRoute = async (startOverride, endOverride ) => {
    // Check if start and end are valid locations
    let startGeo = userData;
    let endGeo = end;


    
    // console.log('checking route PassengerMap', startGeo, endGeo)
    if(startOverride) {
      startGeo = startOverride;
    }
    if(endOverride) {
      endGeo = endOverride;
    }

    if (startGeo && typeof startGeo === 'object' && 'lat' in startGeo && 'lng' in startGeo) {
      startGeo = { latitude: startGeo.lat, longitude: startGeo.lng };
    }

    if (endGeo && typeof endGeo === 'object' && 'lat' in endGeo && 'lng' in endGeo) {
      endGeo = { latitude: endGeo.lat, longitude: endGeo.lng };
    }


      // If the new start and end are the same as the old start and end, return early
    if (
      lastRouteRequest.start === startGeo &&
      lastRouteRequest.end === endGeo
    ) {
      return;
    }

    // Update lastRouteRequest
    setLastRouteRequest({ start: startGeo, end: endGeo });


    // console.log('checking after Override route PassengerMap', startGeo, endGeo)
    if(!startGeo || !endGeo) {
      return;
    }
    // console.log('both start and end', {startGeo, endGeo});
    const isString = typeof startGeo === 'string';
    const endIsString = typeof endGeo === 'string';
    if(isString) {
      startGeo = await geocodeAddress(startGeo, true);
    }
  
    if(endIsString) {
      endGeo = await geocodeAddress(endGeo, true);
    }
    // console.log('both start and end', {startGeo, endGeo})

    if(!startGeo?.longitude || !startGeo?.latitude || !endGeo?.longitude || !endGeo?.latitude) {
      return;
    }
    

    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${startGeo.longitude},${startGeo.latitude};${endGeo.longitude},${endGeo.latitude}?geometries=geojson&steps=true&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
      );
      
      const json = await query.json();
      if(!json?.routes[0]) {
        notifier('Error Loading Map - Please refresh browser, and should resolve')
        return;
      }
      const data = json?.routes[0];
      // console.log('got route', {data})
      
    
      setRoute({
        type: 'Feature',
        properties: {},
        geometry: data.geometry,
        legs: data.legs,
      });

      
    if(!shouldFollowUser) {
      if(userLocation && userLocation.length > 0) {
        setStart({latitude: userLocation[1], longitude: userLocation[0]})
      }
      // console.log('should follow user', shouldFollowUser)
      setViewport((vp) => ({ ...vp, latitude: startGeo.latitude, longitude: startGeo.longitude }));
    }
  };

  

  
  useEffect(() => {
    // Fetch route from Mapbox Directions API
    if(!end) {
      return;
    }
    if (lastEnd === end) return;
    setLastEnd(end)

    setShouldFollowUser(true);
    return () => fetchRoute();
  }, [start, end]); // Rerun effect if start or end changes
  


  


  function formatDistance(distanceInMeters) {
    const distanceInFeet = distanceInMeters * 3.28084;
    const distanceInMiles = distanceInMeters / 1609.34;
  
    if (distanceInMiles < 0.2) {
      return `${distanceInFeet.toFixed(2)} feet`;
    } else {
      return `${distanceInMiles.toFixed(2)} miles`;
    }
  }

  const onStyleLoad = (map) => {
    // Add your layers here
    // For example:
    // map.addLayer({
    //   id: 'route',
    //   type: 'line',
    //   source: {
    //     type: 'geojson',
    //     data: route
    //   },
    //   paint: {
    //     'line-width': 2,
    //     'line-color': '#007cbf'
    //   }
    // });
  };

  return (
    <PassengerMapContainer isFullscreen={toggleFullScreen}>


    <PassengerMapWrapper isFullscreen={toggleFullScreen}>
    <ReactMapGL
      {...viewport}
      ref={mapRef}

      key={setToggleFullScreen ? 'fullscreen' : 'normal'}
      mapStyle="mapbox://styles/mapbox/standard"
      onViewportChange={(newViewport) => {
        if (shouldFollowUser) {
          setViewport(newViewport);
        }
      }}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onLoad={onMapLoad}
      
    >
      {mapLoaded && route && route.legs && route.legs[0] && route.legs[0].steps &&  (
        <Source id="route" type="geojson" data={route} key={JSON.stringify(route)}>
          <Layer {...routeLayer} />
        </Source>
      )}

      {userData?.activePassenger?.driverLocation && (
        <Marker latitude={userData?.activePassenger?.driverLocation?.lat} longitude={userData?.activePassenger?.driverLocation?.lng}>
          <DriverMarker className="driver-marker">
            <DriverImg src={
              userData?.activePassenger?.driverPhoto
              ? userData?.activePassenger?.driverPhoto
              : "images/ui/sidePane/space_astronaut.svg"
            } />
            🚗
          </DriverMarker>
        </Marker>
      )}
    </ReactMapGL>
  
      {route && route.legs && route.legs[0] && route.legs[0].steps &&  (
            <ActiveMapBanner>
              <NavInstruction>
                  {route?.legs[0]?.steps[currentStep]?.maneuver?.instruction}
                  </NavInstruction>
                  <NavDistance>

                  {formatDistance(route?.legs[0]?.steps[currentStep]?.distance)}
                  </NavDistance>

                  <NavFullScreenToggleButton isFullscreen={toggleFullScreen} onClick={() => {
                      setToggleFullScreen(!toggleFullScreen);
                      setShouldFollowUser(false);
                       // Trigger a resize event on the map
                       setTimeout(() => {
                        if (mapRef.current) {
                          mapRef.current.getMap().resize();
                        }
                      }, 0);
                      // fetchRoute()
                    }
                  }
                    
                    > [ ] </NavFullScreenToggleButton>
                          
            </ActiveMapBanner>
      )}
      </PassengerMapWrapper>

    <NavDirectionsContainer className='btn' isFullscreen={toggleFullScreen}>
  
 

    {route && route.legs && route.legs[0] && route.legs[0].steps &&  (
      route.legs[0].steps.map((step, index) => (
           <NavDirectionDetailsContainer
            key={index+'steps'} 
            toggle={currentStep <= index + 1} 
            active={currentStep === index} 
            onClick={() => {
              setShouldFollowUser(false);
              setViewport({
                ...viewport,
                latitude: step.maneuver.location[1],
                longitude: step.maneuver.location[0],
                transitionDuration: 3000,
              });
            }}
            className="navigation-instructions">
            <NavInstruction key={index+'instruction'}>{step.maneuver.instruction}</NavInstruction>
            <NavDistance key={index+'distance'}>
             - {formatDistance(step.distance)}
          </NavDistance>
          </NavDirectionDetailsContainer>
          ))
    )}
    </NavDirectionsContainer>
    </PassengerMapContainer>
  );
}

export default PassengerMap;
