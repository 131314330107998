import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import axios from "axios";
import {
  collection,
  doc,
  getDocs,
  deleteDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
  addDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { updateProfileImage, checkUserProfileImage } from "../../storage/utils";
import { set, useForm } from "react-hook-form";
import { kinDb, gKey, cloudFunctions, kindAuth } from "../../firebase-config";
import { AuthContext } from "../../Auth";
// import ReactDOM from 'react-dom';
import Draggable from "react-draggable";
import Notifcations from "../UI/Notifications";
import styled from "@emotion/styled";
import { reverseGeoCode } from "../../hooks/useMapDirections";
// import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { debounce } from "lodash";

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { httpsCallable } from "@firebase/functions";
// import { StripeLinkButton } from "../UI/stripe/elements";

import { UserListings } from '../UI/Listings'

import {
  fireAuth,
  writeMarker,
  getUserId,
  deleteCheckIn,
  addOrRemoveFriend,
  warningMarkersSnap,
  getUserName,
  updateUsername,
  updateBio,
  db,
  addFriend,
  acceptFriend,
  updateFriends,
  getFriends,
  sendChatMessage,
  loadChatMessages,
  activateDriver,
  toggleActiveRideRequest,
  activateDriverMode,
  writeToActiveRideRequest,
  setRideIntent,
  deleteFriend,
  GetRideEstimate,
  SetupIntentInterface,
} from "../../firebase";

import { ErrorField } from "../SignUp";

import {
  MarkerContainer,
  MarkerInner,
  Header,
  Body,
  Footer,
  Status,
  StatusText,
  Button,
  ThankYouText,
  WithLove,
  CovidButton,
  ExtraToggles,
  SearchCurrentLocation,
  SearchMarkerForm,
  SearchSubmitContainer,
  SubmitCheckIn,
  SearchHeaderText,
  LocationAutoComplete,
  ClearInputBtn,
  SubClearInputBtn,
  TreasureClearInputBtn,
  Prediction,
  FoodErrorField,
  LocationInputContainer,
  Location,
  SubLocation,
  SubLocationInputContainer,
  SubSpacer,
  
  SearchContainer,
  TogglePanel,
  // NavBtn,
  FriendBtn,
  ToggleBtn,
  ToggleRideBtn,
  CheckListBtn,
  HopeBtn,
  
  StatusBtn,
  TreasureBtn,
  AlertBtn,
  AlertIcon,
  AlertCount,
  AlertModalBtn,
  CloseAlertModal,
  AlertsModal,
  AlertContainer,
  AlertIconWrapper,
  AlertContentWrapper,
  AlertModalHeader,
  SearchHeader,
  SearchSubHeader,
  SearchCopy,
  CenteredTextContainer,
  SearchCopyBtn,
  SearchCancelBtn,
  SearchTextContent,
  SearchSuggestMore,
  SearchSuggestMoreLink,
  UseCurrentLocationContainer,
  SetDonationInput,
  SetDonationContainer,
  OutlineContainer,
  OutlineAvatarContainer,
  AvatarColumnContainer,
  OutlineColumnContainer,
  CheckBoxContainer,
  UseCurrentText,
  RefreshUiBtn,
  UseCurrentBtn,
  LastReportedContainer,
  LastReportedGraphic,
  LastReportedStats,
  LastReportedDate,
  LastReportedStatus,
  AlertHeader,
  AlertHeaderText,
  AlertSubHeader,
  AlertRecommendations,
  AlertRecommendation,
  AlertExposureLocations,
  AlertExposureList,
  ExposureContainer,
  WarnList,
  WarnListUGC,
  SubmitThankYouPanel,
  MarkerAddedContainer,
  MarkerAddedPin,
  MarkerAddedHeader,
  MarkerAddedSubHeader,
  SubmitThankYouMessage,
  StatusFormQuestions,
  StatusUpdateBtn,
  FormQuestion,
  FormBox,
  FormLabel,
  SelectItemContainer,
  Treasure,
  TreasureForm,
  HopeForm,
  FoodBankForm,
  HopeFoodBank,
  SelectedHopeFoodBank,
  SelectedFoodBankSent,
  GoBack,
  FoodBankLogo,
  FoodBankContent,
  SelectedFoodBankContent,
  SelectedFoodBankForm,
  SelectedFoodSubmit,
  FieldTitle,
  FieldInput,
  FoodBankHeader,
  FoodSearchCopy,
  FoodSearchDetails,
  FoodSearchDetailsRed,
  FoodBankDetails,
  FoodBankTel,
  FoodBankArrow,
  TreasureInputContainer,
  SelectedTitleInput,
  SelectedHidden,
  SetTreasureButton,
  AppPanel,
  TreasurePanel,
  AlertPanel,
  StatusPanel,
  FriendPanel,
  SearchPanel,
  AdminPanel,
  HopePanel,
  HopeHeader,
  CheckInForm,
  CheckInPanel,
  SubPanel,
  CheckInsList,
  CheckedLocation,
  CheckInText,
  CheckInDelete,
  ClosePanel,
  AdminInput,
  // AdminBtn,
} from "./styles";
// import { get } from 'http';

import {
  AvatarContainer,
  Avatar,
  UserNameContainer,
  UserName,
  UpdateUsernameContainer,
  FriendContentText,
  AvatarImageContainer,
  UserNameTextArea,
  UserNameInput,
  UserNamesContainer,
  UserNameButton,
  FriendPanelContainer,
  FriendInputContainer,
  PanelHeader,
  PanelSubHeader,
  PanelBreadCrumbs,
  UserNameContainerPanel,
  ActiveFriendPanelContainer,
  ActiveFriendContent,
  ActiveContentText,
  ActiveContentHeader,
  ActivePanelButton,
  ManageFriendListContainer,
  ManageFriendListWrapper,
  ManageFriendListAvatar,
  ManageFriendListUserName,
  ManageFriendListChat,
  ManageFriendListClose,

  // Notifcations,
  AlertsContainer,
  SuccessContainer,
  ActivePanel,
  FriendContainer,
  FriendListContainer,
  ApprovedDriverToggle,
  AcceptButton,
  FriendPanelTitle,
  PendingButton,
  ChatPanel,
  ChatPanelCloseBtn,
  ChatPanelHeader,
  ChatPanelHeaderImg,
  ChatPanelHeaderText,
  ChatPanelBody,
  ChatPanelBodyText,
  ChatPanelFooter,
  ChatPanelFooterInput,
  ChatPanelFooterBtn,
  ChatMessageContainer,
  ChatMessageText,
  UserNameText,
  SelectInputContainer,
  GlowBox,
  PassengerCountContainer,
  PassengerCountBtn,
  PassengerCountBtnContent,
  RideCostEstimate,
  SubscriptionGate,
  SubscriptionGateHeader,
  SubscriptionGateSubHeader,
  SubscriptionGateContent,
  SubscriptionGateText,
  SubscriptionGateImg,
} from "../../styles";

import NotificationWrapper from "../UI/Notifications";
import SubscriptionGateBtn from "../UI/stripe/SubscriptionGate";

import {
  MainNavText,
  TreasureLabel,
  ShareFormContainer,
  ShareForm,
  ShareFormHeader,
  ShareFormHeaderText,
  ShareFormSubHeaderText,
  ShareFormSubHeader,
} from "../../styles/ui";

import { LinkBtn, LoadingContainer, LoadingSpinner } from "../../styles/shared";
import { InputContainer, AvatarInputContainer } from "../LogIn";

const SetMarker = ({
  // position, setPosition,
  // location, setLocation,
  error,
  updateError,
  // grabLocation, triggerLocationGrab
  resetMarkers,
  // triggerMarkerReset,
  // resetMarker,
  setResetMarker,
  resetWarningMarker,
  setResetWarningMarker,
  resetContagionMarker,
  setResetContagionMarker,
  currentWarnings,
  setCurrentWarnings,
  currentWarningsUGC,
  setCurrentWarningsUGC,
  searchPanelToggle,
  setSearchPanelToggle,
  setFriendPanelToggle,
  friendPanelToggle,
  statusPanelToggle,
  setStatusPanelToggle,
  activateAlertModal,
  setActivateAlertModal,
  heartCount,
  latestMarker,
  setLatestMarker,
  hopePanelToggle,
  setHopePanelToggle,
  hopeData,
  foodBankData,
}) => {
  const [dropLocation, setDropLocation] = useState("");
  const [resourceName, setResourceName] = useState("");
  const [resourcePhone, setResourcePhone] = useState("");
  const [resourceUrl, setResourceUrl] = useState("");
  const [resourceDetails, setResourceDetails] = useState("");
  const [hopeLocation, setHopeLocation] = useState("");
  // const [error, updateError] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const [dropPredictions, setDropPredictions] = useState([]);
  const [predictionsToggle, setPredictionsToggle] = useState(false);
  const [predictionsDropToggle, setPredictionsDropToggle] = useState(false);

  const [lastLocation, setLastLocation] = useState(false);
  const [lastHopeLocation, setLastHopeLocation] = useState(false);

  const [covid, setCovid] = useState(false);
  const [userContractedCovid, toggleUserContracted] = useState(false);
  const [resetUserContracted, setResetUserContracted] = useState(false);
  const [useCurrent, setUseCurrent] = useState(false);

  const [donationAmount, setDonationRideToggle] = useState(false);
  const [paidRideToggle, setPaidRideToggle] = useState(false);
  const [checkInPanelToggle, setCheckInPanelToggle] = useState(false);
  const [adminPanelToggle, setAdminPanelToggle] = useState(false);
  const [checkIns, setCheckIns] = useState([]);
  const [alertPanelToggle, setAlertPanelToggle] = useState(false);
  const [warningsPanelToggle, toggleWarningsPanel] = useState(true);
  const [thankYouToggle, setThankYouToggle] = useState(false);

  const [statusThankYouToggle, setStatusThankYouToggle] = useState(false);
  const [treasurePanelToggle, setTreasurePanelToggle] = useState(false);

  const [mandatoryQuarantine, setMandatoryQuarantine] = useState(false);
  const [selfQuarantined, setSelfQuarantined] = useState(false);
  const [showingSymptoms, setShowingSymptoms] = useState(false);
  const [covidPositive, setCovidPositive] = useState(false);
  const [covidUpdatedDate, setCovidUpdatedDate] = useState(0);
  const [myLoveCount, setMyLoveCount] = useState();
  const [messageFoodBank, setMessageFoodBank] = useState(false);
  const [selectedFoodBank, setSelectedFoodBank] = useState({});
  const [messageFoodSent, setMessageFoodSent] = useState(false);
  const [foodSentMessage, setFoodSentMessage] = useState(false);

  const [userId, setUserId] = useState(getUserId());

  const [chatPanelText, setChatPanelText] = useState("");
  const [chatPanelInput, setChatPanelInput] = useState("");

  // const [chatPanelInput, setChatPanelInput] = useState('');
  const [friendsUsername, setFriendsUsername] = useState();

  const [isCardEmpty, setIsCardEmpty] = useState(true);

  const handleCardDetailsChange = (event) => {
    setIsCardEmpty(event.empty);
  };
  // const stripe = useStripe();
  // const elements = useElements();

  const checkCardRequirements = () => {
    const paymentRequired =
      freeRideToggle === "paid" || freeRideToggle === "donation" ? true : false;
    if (!isCardEmpty || !paymentRequired) return true;
    if (isCardEmpty && paymentRequired) return false;
    return false;
  };

  const stripe = useStripe();
  const elements = useElements();
  const RIDE_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "16px",
        color: "#2f3566",
        margin: "0.5em",
        backgroundColor: "#fff",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const {
    currentUser,
    activeFriends,
    setActiveFriends,
    activeFriendsUpdate,
    setActiveFriendsUpdate,
    resetDriverMarker,
    setResetDriverMarker,
    driverMode,
    setDriverMode,
    startLocation,
    setStartLocation,
    destination,
    setDestination,
    ridePanelToggle,
    setRidePanelToggle,
    activeFriendPanel,
    setActiveFriendPanel,
    friends,
    setFriends,
    notification,
    setNotification,
    fiestaMessage,
    setFiestaMessage,
    userName,
    setUserName,
    location,
    setLocation,
    position,
    setPosition,
    friendId,
    setFriendId,
    chatPanelUser,
    setChatPanelUser,
    chatMessages,
    setMessages,
    chatId,
    setChatId,
    chatPanelToggle,
    setChatPanelToggle,
    userData,
    setUserData,
    clearTogglesActive,
    setClearTogglesActive,
    triggerShow,
    setTriggerShow,
    pendingFriends,
    setPendingFriends,
    grabLocation,
    triggerLocationGrab,
    freeRideToggle,
    setFreeRideToggle,
    rideEstimate,
    setRideEstimate,
    rideDistanceEstimate,
    setRideDistanceEstimate,
    setupIntent,
    setSetupIntent,
    clientSecret,
    setClientSecret,
    userEmail,
    setUserEmail,
    onConfirm,
    setOnConfirm,
    onCancel,
    setOnCancel,
    toggleConfirmationModal,
    setToggleConfirmationModal,
    confirmationModalText,
    setConfirmationModalText,
    confirmationAlertText,
    setConfirmationAlertText,
    confirmationHeaderText,
    setConfirmationHeaderText,
    termsToggle,
    setTermsToggle,
    termsPanelToggle,
    setTermsPanelToggle,
    profileImage,
    setProfileImage,
    loading,
    setLoading,
    inviteCode,
    setInviteCode,
    passengerCount,
    setPassengerCount,
    stripeAccountStatus,
    setStripeAccountStatus,
    customerData,
    paymentMethodId,
    setPaymentMethodId,
    panelTrigger,
    setPanelTrigger,
    panelTriggerCount,
    setPanelTriggerCount,
    manageFriendToggle, setManageFriendToggle,
    messageToken,
    setMessageToken,
    openSpeedModal,
  } = useContext(AuthContext);

  const [newUserName, setNewUsername] = useState(userName);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    clearError,
    setError,
    setValue,
    trigger,
    clearErrors,
  } = useForm();

  //  AutoComplete

  const resetNotifications = async () => {
    setNotification(null);
    setFiestaMessage(null);
  };
  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };
  const autocomplete = useRef();

  if (!autocomplete.current) {
    // autocomplete.current = new window.google.maps.places.AutocompleteService();
    autocomplete.current = window.google?.maps?.places?.AutocompleteService
      ? new window.google.maps.places.AutocompleteService()
      : null;
  }

  const getPlacePredictions = async (input) => {
    if (!kindAuth.currentUser) return;
    if (
      input &&
      input.length > 3 &&
      watchedPredictionInputSelected !== input.value
    ) {
      // setPredictionsToggle(true);
      await autocomplete.current.getPlacePredictions(
        { input },
        (predictions) => {
          // console.log(predictions);
          predictions &&
            setPredictions(
              predictions
                .map((prediction) => prediction.description)
                .slice(0, 3)
            );
        }
      );
    }
    // console.log(predictions)
  };
  const getDropPlacePredictions = async (input) => {
    if (!kindAuth.currentUser) return;
    // console.log("getDropPlacePredictions", input);
    if (input && input.length > 3) {
      setPredictionsDropToggle(true);
      // console.log("getting drop predictions");
      await autocomplete.current.getPlacePredictions(
        { input },
        (dropPredictions) => {
          // console.log("setting pred", { dropPredictions });
          if (dropPredictions?.length > 0) {
            setDropPredictions(
              dropPredictions.map((prediction) => prediction.description)
              .slice(0, 3)
            );
          }
        }
      );
    }

    // console.log(predictions)
  };

  useEffect(() => {
    setValue("addBioField", userData?.bioField);
    setValue("addFirstName", userData?.firstName);
    setValue("addLastName", userData?.lastName);
  }, [userData, setValue]); 



  useEffect(() => {
    const fetchProfileImage = async () => {
      if (!currentUser?.uid) {
        console.log("No current user");
        return;
      }

      

      // console.log("fetch im", currentUser?.uid);
      try {
        const profileImageURL = await checkUserProfileImage(currentUser?.uid);
        if (profileImageURL) {
          // console.log("profileImageURL", profileImageURL);
          setProfileImage(profileImageURL);
        }
      } catch (error) {
        console.error("Failed to fetch profile image", error);
      }
    };

    fetchProfileImage();
  }, [currentUser]);

  useEffect(() => {
    // console.log("settingstatuspanel", { panelTrigger })

    const toggles = {
      search: async () => {
        clearToggles();
        await setSearchPanelToggle(!searchPanelToggle);
        await setFriendPanelToggle(false);
        await setRidePanelToggle(false);
      },
      friend: async () => {
        await clearToggles();
        await setFriendPanelToggle(!friendPanelToggle);
        await setRidePanelToggle(false);
        await setSearchPanelToggle(false);
      },
      chat: async () => {
        await clearToggles();
        setChatPanelToggle(!chatPanelToggle);
      },
      checkIns: async () => {
        await clearToggles();
        setCheckInPanelToggle(!checkInPanelToggle);
      },
      alerts: async () => {
        await clearToggles();
        setAlertPanelToggle(!alertPanelToggle);
      },
      status: async () => {
        await clearToggles();
        setStatusPanelToggle(!statusPanelToggle);
      },
      treasure: async () => {
        await clearToggles();
        setTreasurePanelToggle(!treasurePanelToggle);
      },
      hope: async () => {
        await clearToggles();
        setHopePanelToggle(!hopePanelToggle);
      },
      admin: async () => {
        await clearToggles();
        setAdminPanelToggle(!adminPanelToggle);
      },
      ridePanel: async () => {
        await clearToggles();
        await setSearchPanelToggle(false);
        await setFriendPanelToggle(false);
        await setRidePanelToggle(!ridePanelToggle);
      },
      clearPanel: async () => {
        await clearToggles();
        await setSearchPanelToggle(false);
        await setFriendPanelToggle(false);
        await setRidePanelToggle(false);
        await setAdminPanelToggle(false);
      }
    };

    // clearToggles();
    // await setSearchPanelToggle(false);
    // await setCheckInPanelToggle(false);
    // const run = toggles[`${panel}`]();
   
    // console.log('settingstatuspanel', {panelTrigger})
    if (panelTrigger && typeof toggles[panelTrigger] === 'function') {
      toggles[panelTrigger]();
    }
  
    return () => {
      // Cleanup code here, if needed
    };
  }, [panelTrigger, panelTriggerCount]);

  const watchUpdateUserNameField = watch("updateUserNameField");

  useEffect(() => {
    const unsubscribe = () => {
      // if (watchUpdateUserNameField && watchUpdateUserNameField.length > 0) {
      //   console.log("watchUpdateUserNameField", watchUpdateUserNameField);
      //   setNewUsername(watchUpdateUserNameField);
      // }
    };
    return unsubscribe();
  }, [watchUpdateUserNameField]);

  // useEffect(() => {
  // const friendsRef = db.collection('my_friends', "users/" + userId + "/friends");

  // const unsubscribe = async () => await query(collection(db, 'my_friends', userId));
  // onSnapshot(collection(db, "my_friends"), (doc) => {
  //   const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
  //   console.log(source, " data: ", doc.val());
  //   setFriends(doc.val());

  // });

  // return () => unsubscribe

  // }, [userName]);

  // useEffect(() => {
  // const friendsRef = db.collection('my_friends', "users/" + userId + "/friends");

  // const unsubscribe = async () => await query(collection(db, 'my_friends'));
  // onSnapshot(collection(db, "my_friends"), (doc) => {
  //   const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
  //   console.log(source, " data: ", doc);

  //   // setFriends(doc.val());
  // });

  // return () => unsubscribe

  // }, [userId]);

  useEffect(() => {
    if (!kindAuth.currentUser) return;
    const unsub = getPlacePredictions(hopeLocation);
    return () => unsub;
  }, [hopeLocation]);

  useEffect(() => {
    // console.log(hopePanelToggle);
    if (hopePanelToggle) {
      clearCustomToggles("hope");
      // setHopePanelToggle(true);
    }
  }, [hopePanelToggle]);

  useEffect(() => {
    // console.log('settingstatuspanel')
    if (statusPanelToggle) {
      const settingLoveCount = async () => {
        const qUgc = query(
          collection(db, "send-love"),
          where("userId", "==", getUserId())
        );
        const res = await getDocs(qUgc);

        // db.collection('send-love').where('userId', '==', getUserId()).get()
        // .then((res) => {
        if (!res.empty) {
          // console.log("settingstatuspanel", res);
          res.forEach(function (doc) {
            // console.log('statuspanel default', doc.data())
            const loveDoc = doc.data();
            setMyLoveCount(loveDoc.count);
          });
        }
        // });
      };
      settingLoveCount();

      const populateUgcData = async () => {
        const q = query(
          collection(db, "covid-ugc"),
          where("userId", "==", getUserId())
        );
        const res = await getDocs(q)
          // db.collection('covid-ugc').where('userId', '==', getUserId()).get()
          .then((res) => {
            if (!res.empty) {
              res.forEach(function (doc) {
                // console.log('statuspanel default', doc.data())
                const covidUGC = doc.data();
                setSelfQuarantined(covidUGC.selfQuarantined);
                setShowingSymptoms(covidUGC.showingSymptoms);
                setMandatoryQuarantine(covidUGC.mandatoryQuarantine);
                setCovidPositive(covidUGC.covidPositive);
                setCovidUpdatedDate(covidUGC.time);
              });
            } else {
              setSelfQuarantined(false);
              setShowingSymptoms(false);
              setCovidPositive(false);
              setCovidUpdatedDate(false);
            }
            // }).catch(function(error) {
            //   console.error("Error adding ugc: ", error);
            // });
          });
      };
      populateUgcData();
    }
  }, [statusPanelToggle]);

  //
  // AutoComplete End

  ////

  useEffect(() => {
    if (errors) {
      console.log({ errors });
    }
  }, [errors]);

  //
  // nav Geo Location Request

  ////

  const messageAnchor = useRef();

  useEffect(() => {
    messageAnchor.current.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  const geoChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });

    // console.log('settingpos', {coords})
  };
  const onError = (error) => {
    updateError(error.message);
  };

  const watchedAmount = watch("amount");
  // Watch the value of 'setDropLocation' input
  const watchedDropLocation = watch("setDropLocation");

  // Now, `watchedDropLocation` will hold the current value of 'setDropLocation' input
  // You can use it in your component or in effects to perform actions when the value changes

  // const inputDropChange = (e) => {
  //   // Debounce
  //   clearErrors("setDropLocation");
  //   clearErrors("predictionDropInputSelected");
  //   // setDropLocation(watchedDropLocation);
  //   setPredictionsDropToggle(true);
  //   console.log("inputDropChange", watchedDropLocation);
  //   // setUseCurrent(false);
  // };

  // const debounceInputDropChange = debounce(inputDropChange, 200);

  useEffect(() => {
    // This effect will run whenever `watchedDropLocation` changes
    const unsubscribe = async () => {
      // setPredictionsDropToggle(true);

      await clearErrors("setDropLocation");
      await clearErrors("predictionDropInputSelected");
      // const dropLoc = getValues("setDropLocation");
      // setDropLocation(dropLoc);
      //check min length trigger
      if (watchedDropLocation?.length < 3) {
        if (watchedDropPredictionInputSelected?.length > 0) {
          await setValue("predictionDropInputSelected", "");
        }
        setError("setDropLocation", {
          type: "minLength",
          message: "I'll start predicting after 3 characters",
        });
      }
      // inputDropChange();
      // console.log("inputDropChange", watchedDropLocation);
    };
    // if(watchedDropLocation && watchedDropLocation.length > 0)
    return () => unsubscribe();
  }, [watchedDropLocation]);

  // Register the input and watch its value
  const watchedLocation = watch("setPickupInput");

  useEffect(() => {
    // This effect will run whenever `watchedLocation` changes
    const unsubscribe = async () => {
      // console.log("watchedLocation", watchedLocation);
      // const pickupInput = getValues("setPickupInput");
      // setLocation(pickupInput);

      // setValue("setPickupInput", pickupInput);
      await clearErrors("setPickupInput");
      // clearErrors("predictionInputSelected");
      await setUseCurrent(false);
      if (watchedLocation?.length < 3) {
        if (watchedPredictionInputSelected?.length > 0) {
          await setValue("predictionInputSelected", "");
        }

        //   await setPredictionsToggle(true);
      }
    };
    return () => unsubscribe();
  }, [watchedLocation]);

  useEffect(() => {
    const unsub = async () => {
      if (!kindAuth.currentUser) return;
      // console.log("effect dropl", watchedDropLocation);
      if (watchedDropLocation && watchedDropLocation.length <= 3) {
        await setPredictionsDropToggle(false);
        // setValue("predictionDropInputSelected", "");
        return;
      } else {
        if (
          watchedDropLocation?.length &&
          watchedDropLocation === watchedDropPredictionInputSelected
        ) {
          await setPredictionsDropToggle(false);
        } else {
          //  await setPredictionsDropToggle(true);
          await getDropPlacePredictions(watchedDropLocation);
        }
      }
    };
    // if (watchedDropLocation && watchedDropLocation.length > 0) {
    return () => unsub();
    // }
  }, [watchedDropLocation]);

  const watchedPredictionInputSelected = watch("predictionInputSelected");
  const watchedDropPredictionInputSelected = watch(
    "predictionDropInputSelected"
  );

  useEffect(() => {
    const unsubscribe = async () => {
      const checkPred = await trigger("watchedPredictionInputSelected");
      const checkDropPred = await trigger("watchedDropPredictionInputSelected");
      const setPickupInput = getValues("setPickupInput");
      const setDropLocationInput = getValues("setDropLocation");
      const dropPred = getValues("predictionDropInputSelected");
      const pred = getValues("predictionInputSelected");
      // console.log({ pred, dropPred, setPickupInput, setDropLocationInput });
      // console.log(
      //   checkPred,
      //   checkDropPred,
      //   setPickupInput,
      //   setDropLocationInput,
      //   { watchedPredictionInputSelected, watchedDropPredictionInputSelected }
      // );
      if (
        checkPred &&
        checkDropPred &&
        pred?.length > 3 &&
        dropPred?.length > 3
      ) {
        setTriggerShow(true);
        await GetRideEstimate(
          rideEstimate,
          setRideEstimate,
          setPickupInput,
          setDropLocationInput,
          position,
          rideDistanceEstimate,
          setRideDistanceEstimate,
          notifier,
          fiesta
        );
        // console.log({ checkPred, checkDropPred });
      }
    };
    return () => unsubscribe();
  }, [watchedPredictionInputSelected, watchedDropPredictionInputSelected]);

  // useEffect(() => {
  //   const unsub = async () => {
  //     const predictionInputSelected = getValues("predictionInputSelected");
  //     const locationInput = getValues("setPickupInput");
  //     if (!kindAuth.currentUser) return;
  //     console.log("### watched toggle", {
  //       watchedLocation,
  //       watchedPredictionInputSelected,
  //     });
  //     if (
  //       locationInput?.length <= 3 ||
  //       locationInput === predictionInputSelected
  //     ) {
  //       await setPredictionsToggle(false);
  //       return;
  //     } else {
  //       await setPredictionsToggle(true);
  //     }
  //     if (
  //       locationInput !== predictionInputSelected &&
  //       locationInput?.length > 3
  //     ) {
  //       // setPredictionsToggle(true);
  //       // setValue("predictionInputSelected", "");
  //       // setPredictionsToggle(true);
  //       // console.log("locationInput", locationInput);
  //       // const pickupInput = getValues("setPickupInput");
  //       await getPlacePredictions(locationInput);
  //     }

  //     // if(locationInput?.length > 3 && locationInput !== predictionInputSelected) {
  //     // }
  //   };
  //   return () => unsub();
  //   // return () => unsub();
  // }, [watchedLocation, watchedPredictionInputSelected]);

  // const inputChange = (e) => {
  //   console.log(watchedLocation);
  //   // Debounce
  //   setLocation(watchedLocation);
  //   setUseCurrent(false);
  //   clearErrors("setPickupInput");
  //   clearErrors("predictionInputSelected");
  //   setPredictionsToggle(true);
  // };

  // const inputUserNameChange = (e) => {
  //   console.log(e.target.value);
  //   // Debounce
  //   // setLocation(e.target.value);
  //   // setUseCurrent(false);
  //   // clearErrors('setLocation');
  //   // clearErrors('predictionInputSelected');
  //   // setPredictionsToggle(true);
  // };

  const inputHopeChange = (e) => {
    // Debounce

    setHopeLocation(e.target.value);
    clearErrors("setHopeLocation");
    clearErrors("hopePredictionInputSelected");
    setPredictionsToggle(true);
  };

  const watchedLocationTreasure = watch("setLocationTreasure");
  useEffect(() => {
    const unsubscribe = () => {
      // console.log(e.target.value)
      setLocation(watchedLocationTreasure);
      // setValue('predictionSelectedTreasure', watchedLocationTreasure);
      // setValue('predictionSelectedTreasure', watchedLocationTreasure);
      // setUseCurrent(false);
      clearErrors("setLocationTreasure");
      clearErrors("predictionSelectedTreasure");
      setPredictionsToggle(true);
    };
    return unsubscribe();
  }, [watchedLocationTreasure]);

  const watchedResourceName = watch("setResourceName");

  useEffect(() => {
    // console.log(e.target.value)
    const unsubscribe = () => {
      clearErrors("setResourceName");
      setResourceName(watchedResourceName);
      trigger("setResourceName");
    };
    return unsubscribe();
    // setValue('predictionSelectedTreasure', e.target.value);
  }, [resourceName]);

  const watchedResourcePhone = watch("setResourcePhone");
  useEffect(() => {
    const unsubscribe = () => {
      // console.log(e.target.value)
      clearErrors("setResourcePhone");
      setResourcePhone(watchedResourcePhone);
      // setValue('predictionSelectedTreasure', e.target.value);
    };
    return unsubscribe();
  }, [resourcePhone]);

  const watchedResourceUrl = watch("setResourceUrl");
  useEffect(() => {
    const unsubscribe = () => {
      // console.log(e.target.value)
      setResourceUrl(watchedResourceUrl);
      // setValue('predictionSelectedTreasure', e.target.value);
      clearErrors("setResourceUrl");
    };
    return unsubscribe();
  }, [resourceUrl]);

  const watchedResourceDetails = watch("setResourceDetails");
  useEffect(() => {
    const unsubscribe = () => {
      // console.log(e.target.value)
      setResourceDetails(watchedResourceDetails);
      // setValue('predictionSelectedTreasure', e.target.value);
      clearErrors("setResourceDetails");
    };
    return unsubscribe();
  }, [resourceDetails]);

  useEffect(() => {
    const unsubscribe = () => {
      // console.log('grabbing geo')
      if (!kindAuth.currentUser) return;
      setPredictionsToggle(false);
      const geo = navigator.geolocation;
      if (!geo) {
        updateError("Geolocation is not supported");
        return;
      }

      if (watchedLocation === "Current Location") {
        const watcher = geo.watchPosition(geoChange, onError);
        return () => geo.clearWatch(watcher);
      }
    };
    return unsubscribe();
  }, [grabLocation]);

  //
  // Grab Nav Geo End

  ////

  //
  // Reverse GeoCode Prediction

  ////
  const getGeoCode = async (write = false) => {
    const key = gKey;
    if (!kindAuth.currentUser) return;
    try {
      // setLoading(true);
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
          watchedLocation
        )}&key=${key}`
      );
      const result = res.data.results[0].geometry.location;
      // console.log(watchedLocation, result.lat, result.lng);

      if (result.lat !== null && result.lng !== null) {
        await setDestination(watchedDropLocation);
        await setLatestMarker({ latitude: result.lat, longitude: result.lng });
        // await setStartLocation({lat: result.lat, lng: result.lng})
        await setStartLocation(watchedLocation);
        await setPosition({ latitude: result.lat, longitude: result.lng });
        await setSearchPanelToggle(false);
        await writeToActiveRideRequest(
          watchedLocation,
          watchedDropLocation,
          userData,
          setUserData,
          freeRideToggle,
          passengerCount,
          rideEstimate,
          rideDistanceEstimate,
          notifier,
          fiesta,
          setTriggerShow,
          setLoading,
          setMessageToken,
        );
        await activateDriverMode(
          false,
          setDriverMode,
          setResetDriverMarker,
          notifier,
          fiesta,
          triggerLocationGrab,
          setMessageToken,
        );

        // clearInput();
        // await setThankYouToggle(true);

        await setRidePanelToggle(true);
        // write &&
        //   (await writeMarker(
        //     location,
        //     result.lng,
        //     result.lat,
        //     covid,
        //     "marker"
        //   ));
        await toggleActiveRideRequest(
          true,
          userData,
          setUserData,
          setClearTogglesActive,
          setTriggerShow,
          setLoading,
          notifier,
          fiesta
        );
      } else {
        updateError(true);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log(error);
      updateError(true);
    }
  };

  const getTreasureGeoCode = async (selectedItem) => {
    const key = gKey;
    try {
      // setLoading(true);
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
          location
        )}&key=${key}`
      );
      const result = res.data.results[0].geometry.location;
      // console.log(location, result.lat, result.lng)

      if (result.lat !== null && result.lng !== null) {
        // setLatestMarker({latitude: result.lat, longitude: result.lng})
        setPosition({ latitude: result.lat, longitude: result.lng });
        setTreasurePanelToggle(false);
        clearInput();

        addDoc(collection(kinDb, "treasureMarkers"), {
          location,
          longitude: result.lng,
          latitude: result.lat,
          resourceName,
          resourcePhone,
          resourceUrl,
          resourceDetails,
          userId: getUserId(),
          time: Date.now(),
          selectedItem,
          confirmations: 0,
          contested: 0,
        });
      } else {
        updateError(true);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log(error);
      updateError(true);
    }
  };

  //
  //  End Reverse GeoCode

  ////

  //
  //  Set User Status To UGC contagion markers

  ////

  async function ugcStats() {
    if (userContractedCovid) {
      const uid = getUserId();
      if (!uid) return;
      const q = query(
        collection(kinDb, "covid-ugc"),
        where("userId", "==", uid)
      );
      const res = getDocs(q);
      // kinDb
      // .collection('covid-ugc').where('userId', '==', getUserId()).get()
      // .then((res) => {

      // console.log(address);
      if (res.empty) {
      } else {
        // //
        res.forEach(function (userStatus) {
          const status = userStatus?.data();

          setSelfQuarantined(status?.selfQuarantined);
          // setShowingSymptoms(status.showingSymptoms);
          // setMandatoryQuarantine(status.mandatoryQuarantine);
          // setCovidPositive(status.covidPositive);
          // console.log(userStatus?.data());
        });
      }
      // console.log(res.empty);
    } else {
    }
  }

  // useEffect(() => {
  //   // const unsubscribe =

  //   // console.log('effect covid status', userContractedCovid)
  //   // const q = query(collection(kinDb, 'allMarkers'), where('userId', '==', getUserId() ));

  //   const unsubscribe = warningMarkersSnap(setResetWarningMarker);
  //   return () => unsubscribe;
  //   // db.collection(collection).add({
  // }, [userContractedCovid]);

  useEffect(() => {
    // const unsubscribe =

    // console.log('effect covid status', userContractedCovid)
    // const q = query(collection(kinDb, 'allMarkers'), where('userId', '==', getUserId() ));
    // console.log({inviteCode}, {currentUser})
    // console.log("inviteCode", currentUser?.inviteCode);
    if (currentUser?.inviteCode !== undefined) return;
    // if(!kindAuth?.currentUser) return;

    const unsubscribe = async () => {
      if (!inviteCode) return;
      // console.log({ inviteCode }, { currentUser }, currentUser?.uid);
      // if(!currentUser?.inviteCode) {
      //   db.collection('users').doc(currentUser.uid).update({inviteCode})
      // }
      // if (!currentUser?.inviteCode) {
      //   console.log('thinks')
      //   const cUserRef = collection(kinDb, 'users');
      //   const q = query(cUserRef);
      //   const cUserSnapshot = await getDocs(q);
      //   console.log({cUserSnapshot})

      //   // #Check if user exists with userId
      //   const cUser = cUserSnapshot.docs.find((doc) => doc.data().userId === currentUser.uid);
      //   console.log({cUser})
      //   if(cUser) {
      //     console.log('user exists')
      //     await updateDoc(doc(cUserRef, cUser.id), {inviteCode})

      //   } else {

      //   await addDoc(collection(kinDb, 'users'), {
      //     inviteCode,
      //     userId: currentUser.uid,
      //     email: currentUser.email,
      //   });
      // }
      // }
    };

    return () => unsubscribe();
    // db.collection(collection).add({
  }, [inviteCode]);

  // useEffect(() => {

  // }, [])
  useEffect(() => {
    // console.log({ userName });
    // const unsubscribe = async () => await updateFriends(setFriends, setPendingFriends, fiesta)

    // console.log("change detected load", userName, userId);
    // return () => unsubscribe;
  }, [userName, userId]);

  useEffect(() => {
    // console.log('inital load',userName, userId )
    const unsubscribe = getUserName(
      getUserId(),
      setUserName,
      setFriends,
      setPendingFriends,
      notifier,
      fiesta
    );

    // let curUserName;
    // if(getUserId()) {
    //   curUserName =  getUserName(getUserId(), setUserName)
    // } else {
    //   curUserName = 'Anonymous'
    // }
    // // const curUserName =  getUserName(getUserId())
    // const unsubscribe = async () => await setUserName(curUserName)

    return () => unsubscribe;
  }, []);
  //
  //  End Set User Status To UGC contagion markers

  ////

  //
  // Check In Marker and add to map

  ////
  const setMarker = (e) => {
    e.preventDefault();
    // console.log('setMarker stop sub')
  };

  const grabCurrentLocation = () => {
    triggerLocationGrab(!grabLocation);
    // setLocation("Current Location");
    setValue("setPickupInput", "Current Location");
    setValue("predictionInputSelected", "Current Location");
    setPredictionsToggle(false);
    // console.log(position)
  };

  const selectHopePrediction = (prediction) => {
    // console.log(prediction)
    setHopeLocation(prediction);
    setPredictionsToggle(false);
  };

  const selectPrediction = async (prediction) => {
    // console.log(prediction)
    await setPredictionsToggle(false);

    await Promise.all([
      setValue("setPickupInput", prediction),
      setValue("predictionInputSelected", prediction),
      setPredictionsToggle(false),
      clearErrors("predictionInputSelected"),
      clearErrors("setPickupInput"),
      // setLocation(prediction),
      trigger("predictionInputSelected"),
    ]);

    // await setValue("setPickupInput", prediction);
    // await setValue("predictionInputSelected", prediction);
    // await setPredictionsToggle(false);
    // await clearErrors("predictionInputSelected");
    // await clearErrors("setPickupInput");
    // // setLocation(prediction);
    // await trigger("predictionInputSelected");
  };
  const selectTreasurePrediction = async (prediction) => {
    // console.log(prediction)

    clearErrors("setLocationTreasure");
    clearErrors("predictionSelectedTreasure");
    // clearErrors("setPickupInput");
    setValue("setLocationTreasure", prediction);
    setValue("predictionSelectedTreasure", prediction);
    // setLocation(prediction);
    await trigger("setLocationTreasure");
    setPredictionsToggle(false);
  };

  const selectDropPrediction = async (dropPrediction) => {
    await setValue("setDropLocation", dropPrediction);
    await setValue("predictionDropInputSelected", dropPrediction);
    await setPredictionsDropToggle(false);

    console.log("started selectDropPrediction");
    clearErrors("predictionDropInputSelected");
    clearErrors("setDropLocation");
    console.log("finished selectDropPrediction");
    await trigger("predictionDropInputSelected");

    // trigger('setDropLocation');
  };

  const addHope = () => {
    if (hopeLocation !== lastHopeLocation) {
      // console.log('addmarker' position)
      setLastHopeLocation(hopeLocation);
      if (hopeLocation !== "Current Location") {
        // const lngLat = getGeoCode(true);
        submitHope();

        // console.log(lngLat)
      } else {
        // console.log('adding current hopeLocation marker')
        const { latitude, longitude } = position;
        if (latitude && longitude) {
          setSearchPanelToggle(false);
          clearInput();
          // setThankYouToggle(true);
          // writeMarker(hopeLocation, longitude, latitude, covid, 'marker');
          // firebase.analytics().logEvent('Adding Current hopeLocation Checkin');
        }
      }
    }
  };

  const addMarker = async (paymentMethodId) => {
    // console.log( location, lastLocation)
    // if (location !== lastLocation) {
    console.log("!!! add marker");
    // console.log('addmarker', position)
    // console.log({ paymentMethodId });

    if (watchedLocation && watchedLocation !== "Current Location") {
      await setLastLocation(watchedLocation);
      await setDestination(watchedDropLocation);

      if (freeRideToggle !== "free") {
        await setRideIntent(
          paymentMethodId,
          rideEstimate,
          rideDistanceEstimate,
          passengerCount,
          userData,
          notifier,
          fiesta,
          setTriggerShow
        );
      }
      setLoading(true);
      const lngLat = getGeoCode(true);

      // firebase.analytics().logEvent('Adding Checkin');
      // console.log(lngLat)
    } else {
      console.log("adding current location marker");
      const { latitude, longitude } = position;
      // const latitudeD = dropLocation?.latitude;
      // const longitudeD = dropLocation?.longitude;
      // console.clear();
      // console.log("*addMarker", { latitude, longitude }, position);

      if (latitude && longitude) {
        if (freeRideToggle !== "free") {
          await setRideIntent(
            paymentMethodId,
            rideEstimate,
            rideDistanceEstimate,
            passengerCount,
            freeRideToggle,
            userData,
            notifier,
            fiesta,
            setTriggerShow
          );

          // Write to customers collection
        }

        await setDestination(dropLocation);
        await setStartLocation({ lat: latitude, lng: longitude });
        // await setStartLocation(location);

        await setSearchPanelToggle(false);
        // await clearInput();
        // await setThankYouToggle(true);
        // await setRidePanelToggle(true);
        // console.log("getting reverse geo", { latitude, longitude });
        const reverseGeo = await reverseGeoCode({
          lat: latitude,
          lng: longitude,
        });
        // console.log({ reverseGeo });

        await writeMarker(reverseGeo, longitude, latitude, covid, "marker");

        await setPosition({ latitude, longitude });
        await writeToActiveRideRequest(
          reverseGeo,
          dropLocation,
          userData,
          setUserData,
          freeRideToggle,
          passengerCount,
          rideEstimate,
          rideDistanceEstimate,
          notifier,
          fiesta,
          setTriggerShow,
          setLoading,
          setMessageToken,
        );
        // await setRideIntent(rideEstimate, rideDistanceEstimate, userData, setUserData, notifier, fiesta, setTriggerShow)

        await activateDriverMode(
          false,
          setDriverMode,
          setResetDriverMarker,
          notifier,
          fiesta,
          triggerLocationGrab,
          setMessageToken,
        );
        await toggleActiveRideRequest(
          true,
          userData,
          setUserData,
          setClearTogglesActive,
          setTriggerShow,
          setLoading,
          notifier,
          fiesta
        );

        setLoading(false);
        // firebase.analytics().logEvent('Adding Current Location Checkin');
      } else {
        setTriggerShow(true);
        setLoading(false);
        notifier("Please enable location services");
      }
    }
    // }
  };

  const toggleCovid = () => {
    // console.log('clicked', covid)
    setCovid(!covid);
  };

  const updateContracted = async () => {
    const q = query(
      collection(kinDb, "covid-ugc"),
      where("userId", "==", getUserId())
    );
    const res = await getDocs(q);
    // await db.collection('covid-ugc').where('userId', '==', getUserId()).get()
    // .then( function (res) {

    if (res.empty) {
      toggleStatus();
    } else {
      res.forEach(function (covidUgc) {
        // console.log(covidUgc.data())
        updateDoc(doc(kinDb, "covid-ugc", covidUgc.id), {
          selfQuarantined,
          showingSymptoms,
          covidPositive,
          mandatoryQuarantine,
          time: Date.now(),
        });
      });
    }

    // });

    setStatusThankYouToggle(true);
  };

  const toggleStatus = async () => {
    // await setResetUserContracted(userContractedCovid)

    // console.log('userContracted', userContractedCovid)
    // debugger
    if (selfQuarantined || mandatoryQuarantine) {
      // Remove all unconfirmed markers

      // console.log('resetingUserContracted')
      const q = query(
        collection(db, "covid-ugc"),
        where("userId", "==", getUserId())
      );
      const res = await getDocs(q);
      // await db.collection('covid-ugc').where('userId', '==', getUserId()).get()
      // .then((res) => {
      if (!res.empty) {
        res.forEach(async function (user) {
          // console.log(user.id)
          // selfQuarantined || showingSymptoms || covidPositive || mandatoryQuarantine;

          // db.collection('covid-ugc').doc(user.id).delete()
          updateDoc(doc(db, "covid-ugc", user.id), {
            // db.collection('covid-ugc').doc(user.id).update({
            selfQuarantined,
            showingSymptoms,
            covidPositive,
            mandatoryQuarantine,
            time: Date.now(),
          }).then(async (done) => {
            // console.log('supposedly deleted')

            const q = query(
              collection(db, "covid-ugc-markers"),
              where("userId", "==", getUserId())
            );
            const ugcMarkers = await getDocs(q);

            //  db.collection('covid-ugc-markers').where('userId', '==', getUserId()).get()
            //   .then( async (ugcMarkers) => {
            if (ugcMarkers && !ugcMarkers.empty) {
              ugcMarkers.forEach(function (marker) {
                setResetContagionMarker(marker.id);
                // db.collection('covid-ugc-markers').doc(marker.id).delete();
                deleteDoc(doc(db, "covid-ugc-markers", marker.id));
              });
            }
            toggleUserContracted(false);
            await clearToggles();
            setStatusThankYouToggle(true);
          });
          // });
        });
      }

      //   toggleUserContracted(false);
      // }

      // };

      const qAll = query(
        collection(db, "allMarkers"),
        where("userId", "==", getUserId())
      );
      const resQ = await getDocs(qAll);
      // db.collection('allMarkers').where('userId', '==', getUserId()).get()
      // .then((resQ) => {
      resQ.forEach(function (marker) {
        // console.log({marker})
        setResetMarker({
          id: marker.id,
          status: selfQuarantined ? "self" : "mandatory",
        });
        updateDoc(doc(db, "allMarkers", marker.id), {
          convertDate: Date.now(),
        });
        // db.collection('allMarkers').doc(marker.id).update({convertDate: Date.now(), });
      });
      // })
    } else {
      // Populate unconfirmed Markers by thi
      const q = query(
        collection(db, "covid-ugc"),
        where("userId", "==", getUserId())
      );
      const res = await getDocs(q);
      // await db.collection('covid-ugc').where('userId', '==', getUserId()).get()
      // .then( async function (res) {

      if (res.empty) {
        addDoc(collection(db, "covid-ugc"), {
          // await db.collection('covid-ugc').add({
          userId: getUserId(),
          time: Date.now(),
          covidPositive,
          selfQuarantined,
          showingSymptoms,
          mandatoryQuarantine,
        })
          .then(function (docRef) {
            // console.log("Document written with userUGC: ", docRef.id);

            const q = query(
              collection(db, "allMarkers"),
              where("userId", "==", getUserId())
            );
            const res = getDocs(q)
              // db.collection('allMarkers').where('userId', '==', getUserId()).get()
              .then((res) => {
                res.forEach(async function (marker) {
                  // console.log({marker})
                  // setResetMarker(marker.id);
                  setResetMarker({
                    id: marker.id,
                    status: showingSymptoms ? "symptoms" : "recovering",
                  });

                  updateDoc(doc(db, "allMarkers", marker.id), {
                    convertDate: Date.now(),
                  });
                  // db.collection('allMarkers').doc(marker.id).update(

                  setDoc(doc(db, "covid-ugc-markers", marker.id), {
                    ...marker.data(),
                    convertDate: Date.now(),
                  })
                    // db.collection('covid-ugc-markers').doc(marker.id)
                    //   .set({...marker.data(), convertDate: Date.now(), })
                    .then(async function () {
                      // console.log("Document written with userUGC: ", marker.id);
                      toggleUserContracted(true);
                      await clearToggles();
                      setStatusThankYouToggle(true);
                    })
                    .catch(function (error) {
                      console.error("Error adding userUGC: ", error);
                    });

                  // console.log(marker.data())
                });
              });
          })
          .catch(function (error) {
            console.error("Error adding userUGC: ", error);
          });
      } else {
        res.forEach(async function (covidUgc) {
          await updateDoc(doc(db, "covid-ugc", covidUgc.id), {
            selfQuarantined,
            showingSymptoms,
            covidPositive,
            mandatoryQuarantine,
            time: Date.now(),
          }).then(async () => {
            // console.log(covidUgc.data(), showingSymptoms, covidPositive)
            // db.collection('covid-ugc').doc(covidUgc.id).update({
            //   selfQuarantined,
            //   showingSymptoms,
            //   covidPositive,
            //   mandatoryQuarantine,
            //   time: Date.now(),
            // }).then(() => {
            if (showingSymptoms || covidPositive) {
              const q = query(
                collection(db, "allMarkers"),
                where("userId", "==", getUserId())
              );
              const res = await getDocs(q)
                // db.collection('allMarkers').where('userId', '==', getUserId()).get()
                .then((res) => {
                  res.forEach(async function (marker) {
                    await updateDoc(doc(db, "allMarkers", marker.id), {
                      convertDate: Date.now(),
                    });

                    // db.collection('allMarkers').doc(marker.id).update({convertDate: Date.now(), });
                    // console.log({marker})
                    // setResetMarker(marker.id);
                    setResetMarker({
                      id: marker.id,
                      status: showingSymptoms ? "symptoms" : "recovering",
                    });

                    await updateDoc(doc(db, "covid-ugc-markers", marker.id), {
                      convertDate: Date.now(),
                    });

                    setDoc(doc(db, "covid-ugc-markers", marker.id), {
                      ...marker.data(),
                      convertDate: Date.now(),
                    })
                      // db.collection('covid-ugc-markers').doc(marker.id)
                      // .set({...marker.data(), convertDate: Date.now(), })
                      .then(async function () {
                        // console.log("Document written with userUGCMarkers: ", marker.id);
                        await clearToggles();
                        setStatusThankYouToggle(true);
                      })
                      .catch(function (error) {
                        console.error("Error adding userUGC: ", error);
                      });
                  });
                });
            }
          });
        });
      }

      // });
    }
  };

  useEffect(() => {
    const warningMarkerRef = collection(kinDb, "warning-markers");
    const q = query(warningMarkerRef, where("userId", "==", getUserId()));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // onSnapshot(q)

      // .then((snapShot) => {
      if (!querySnapshot.empty) {
        // .collection('warningMarkers').where("userId", "==", fireAuth.currentUser.uid )
        // .onSnapshot((snapShot) => {
        const warnings = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCurrentWarnings([]);
        // console.log('settingWarning Message', {warnings})
        // setCurrentWarnings(warnings);
      } else {
        setCurrentWarnings([]);
      }
    });
    return () => unsubscribe;
  }, []);

  useEffect(() => {
    // const q = query(
    //   collection(kinDb, "warning-markers-ugc"),
    //   where("userId", "==", getUserId())
    // );
    // const unsubscribe = onSnapshot(q, (snapShot) => {
    //   // const unsubscribe = kinDb
    //   // .collection('warning-markers-ugc').where("userId", "==", fireAuth.currentUser.uid )
    //   // .onSnapshot((snapShot) => {
    //   const warnings = snapShot.docs.map((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }));
    //   // console.log('settingWarning Message', {warnings})
    //   if (currentWarningsUGCLength < warnings.length) {
    //     setActivateAlertModal(true);
    //   }
    //   setCurrentWarningsUGC(warnings);
    // });
    // return () => unsubscribe();
  }, []);

  useEffect(() => {
    toggleWarningsPanel(!currentWarningsLength);
  }, [currentWarnings]);

  useEffect(() => {
    toggleWarningsPanel(!currentWarningsUGCLength);
  }, [currentWarningsUGC]);

  const toggleWarnings = (warnings) => {
    return warnings && warnings.length ? true : false;
  };

  const checkWarningLengths = () => {
    // console.log('checkWarnLengt', !currentWarningsLength , !currentWarningsUGCLength)
    return !currentWarningsLength && !currentWarningsUGCLength;
  };

  const currentWarningsLength = currentWarnings && currentWarnings.length;
  const currentWarningsUGCLength =
    currentWarningsUGC && currentWarningsUGC.length;

  const removeCheckIn = async (checkIn) => {
    // setResetMarker(checkIn.id)
    setResetMarker({ id: checkIn.id, status: "delete" });
    const callbackRemove = await deleteCheckIn(checkIn.id, checkIn.address);
    setResetWarningMarker(callbackRemove);
    // console.log('after rem', callbackRemove)
    // console.log(resetMarkers)
    // triggerMarkerReset(true);
    // triggerMarkerReset(false);
  };

  const clearToggles = async () => {
    await setSearchPanelToggle(false);
    await setFriendPanelToggle(false);
    await setCheckInPanelToggle(false);
    await setAlertPanelToggle(false);
    await setThankYouToggle(false);
    await setStatusPanelToggle(false);
    await setStatusThankYouToggle(false);
    await setTreasurePanelToggle(false);
    await setHopePanelToggle(false);
    await setAdminPanelToggle(false);
    await setRidePanelToggle(false);
    // console.log('attempted to clear', thankYouToggle)
  };
  const clearCustomToggles = async (type) => {
    const paneToggles = {
      hope: async () => {
        //console.log('clearing hope')
        await setSearchPanelToggle(false);
        await setFriendPanelToggle(false);
        await setCheckInPanelToggle(false);
        await setAlertPanelToggle(false);
        await setThankYouToggle(false);
        await setStatusPanelToggle(false);
        await setStatusThankYouToggle(false);
        await setTreasurePanelToggle(false);
        await setAdminPanelToggle(false);
      },
    };

    const clear = paneToggles[type]();
  };

  const setPanel = async (panel) => {
    // console.log('setpa', panel, checkInPanelToggle)
    const toggles = {
      search: () => {
        clearToggles();
        setSearchPanelToggle(!searchPanelToggle);
      },
      friend: async () => {
        await clearToggles();
        setFriendPanelToggle(!friendPanelToggle);
      },
      chat: async () => {
        await clearToggles();
        setChatPanelToggle(!chatPanelToggle);
      },
      checkIns: async () => {
        await clearToggles();
        setCheckInPanelToggle(!checkInPanelToggle);
      },
      alerts: async () => {
        await clearToggles();
        setAlertPanelToggle(!alertPanelToggle);
      },
      status: async () => {
        await clearToggles();
        setStatusPanelToggle(!statusPanelToggle);
      },
      treasure: async () => {
        await clearToggles();
        setTreasurePanelToggle(!treasurePanelToggle);
      },
      hope: async () => {
        await clearToggles();
        setHopePanelToggle(!hopePanelToggle);
      },
      admin: async () => {
        await clearToggles();
        setAdminPanelToggle(!adminPanelToggle);
      },
      ridePanel: async () => {
        await clearToggles();
        setRidePanelToggle(!ridePanelToggle);
      },
    };

    // clearToggles();
    // await setSearchPanelToggle(false);
    // await setCheckInPanelToggle(false);
    const run = toggles[`${panel}`]();

    return;
  };

  const getCurrentDate = () => {
    return Date.now();
  };

  

  
  React.useEffect(() => {
    const q = query(
      collection(kinDb, "allMarkers"),
      where("userId", "==", getUserId())
    );
    const unsubscribe = onSnapshot(q, (snapShot) => {
      // const unsubscribe =  kinDb
      // .collection('allMarkers').where("userId", "==", getUserId() )
      // .onSnapshot((snapShot) => {
      const CheckIns = snapShot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCheckIns(CheckIns);

      if (CheckIns && CheckIns.length) {
        // console.log(CheckIns)
        let CheckInsClone = [...CheckIns];
        const fin = CheckInsClone.pop();
        const { latitude, longitude } = fin;
        // console.log({fin})
        setLatestMarker({ latitude, longitude });
      }

      // console.log(CheckIns);
    });
    return () => unsubscribe();
    // fetchData();
  }, []);

  const submitTreasure = async () => {
    const { selectedItemTreasure } = getValues();
    const { latitude, longitude } = position;

    // console.log( location, lastLocation)
    if (location !== lastLocation) {
      // console.log('addmarker', position)
      setLastLocation(location);
      if (location !== "Current Location") {
        const lngLat = getTreasureGeoCode(selectedItemTreasure);
        // console.log('not current?', location,lngLat)
      } else {
        // console.log('adding current location marker')
        // const lngLat = await getTreasureGeoCode(selectedItem);
        if (!position) {
        }
        const { latitude, longitude } = position;
        // console.log('not current?', location,lngLat)
        addDoc(collection(kinDb, "treasureMarkers"), {
          // db.collection('treasureMarkers').add({
          location,
          longitude,
          latitude,
          resourceName,
          resourcePhone,

          resourceDetails,
          confirmations: 0,
          contested: 0,
          userId: getUserId(),
          time: Date.now(),
          selectedItemTreasure,
        });
      }
    }
  };

  const submitHope = async (e) => {
    const { hopeTitle, hopeSubTitle, hopeKey } = getValues();
    const { latitude, longitude } = position;

    if (hopeLocation !== lastHopeLocation) {
      const key = gKey;
      try {
        // setLoading(true);
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
            hopeLocation
          )}&key=${key}`
        );
        const result = res.data.results[0].geometry.location;
        // console.log(location, result.lat, result.lng)

        if (result.lat !== null && result.lng !== null) {
          // setLatestMarker({latitude: result.lat, longitude: result.lng})
          setPosition({ latitude: result.lat, longitude: result.lng });
          setHopePanelToggle(false);
          clearInput();

          addDoc(collection(kinDb, "hopeMarkers"), {
            // db.collection('hopeMarkers').add({
            hopeTitle,
            hopeSubTitle,
            hopeKey,
            hopeLocation,
            longitude: result.lng,
            latitude: result.lat,
            userId: getUserId(),
            time: Date.now(),
            active: 1,
            formActive: 1,
          }).then(() => {
            setThankYouToggle(true);
          });
        } else {
          updateError(true);
        }
        // setLoading(false);
      } catch (error) {
        // setLoading(false);
        // console.log(error)
        updateError(true);
      }
    }
  };

  const submitFoodBank = async (e) => {
    const {
      foodBankName,
      foodBankDesc,
      foodBankTel,
      foodBankCampaignKey,
      foodBankLogo,
    } = getValues();
    addDoc(collection(kinDb, "foodBanks"), {
      // db.collection('foodBanks').add({
      foodBankLogo,
      foodBankName,
      foodBankDesc,
      foodBankTel,
      foodBankCampaignKey,
      userId: getUserId(),
      time: Date.now(),
      active: 1,
    }).then(() => {
      setThankYouToggle(true);
    });
  };

  const submitFoodRequest = async () => {
    const {
      foodFormFullName,
      foodFormAddress,
      foodFormTelephone,
      foodFormEmail,
      foodFormPeopleCount,
      foodFormMinorsCount,
    } = getValues();

    setFoodSentMessage(foodFormFullName);
    addDoc(collection(kinDb, "foodFormRequest"), {
      // db.collection('foodFormRequest').add({
      foodFormFullName,
      foodFormAddress,
      foodFormTelephone,
      foodFormEmail,
      foodFormPeopleCount,
      foodFormMinorsCount,
      foodBankName: selectedFoodBank.foodBankName,
      foodBankCampaignKey: selectedFoodBank.foodBankCampaignKey,
      userId: getUserId(),
      time: Date.now(),
      active: 1,
    }).then(() => {
      setMessageFoodSent(true);
    });
  };

  const setSelectedValue = (item) => {
    const getTitle = {
      tp: "Let them know you found Toilet Paper!",
      lysolSpray: "Let them know you found Disinfectant Spray!",
      lysolWypes: "Let them know you found Disinfectant Wipes!",
      yeast: "Let them know you found Baking Yeast!",
      batteries: "Let them know you found Batteries!",
      waterBottle: "Help mark free water locations",
      freeShelter: "Help mark free Shelter locations",
      fundsRequest: "Share gofundme and other active donation requests",
      alertMarker: "Alert others of dangers based on the locations",
      safeList: "Post shared list to account for missing people",
      foodDonation: "Help mark Food Donation locations",
      freeFood: "Help mark free food locations",
      requestHelp:
        "Mark your location and details of help needed, incase others can possibly help you",
      medicStation: "Help mark medical aid locations",
      protest: "Demark active protests occurring globally in support",
    };

    setValue("selectedItemName", getTitle[item]);
    setValue("selectedItemTreasure", item);
    clearErrors("selectedItemTreasure");
  };

  const clearInput = async () => {
    // setLocation("");
    await setValue("setPickupInput", "");
    await setValue("predictionInputSelected", "");
    setUseCurrent(false);
    await setPredictionsToggle(false);
    clearErrors();
  };
  const clearDropInput = async () => {
    // setDropLocation("");
    await setValue("setDropLocation", "");
    await setValue("predictionDropInputSelected", "");
    await setPredictionsDropToggle(false);
    clearErrors();
  };

  const checkAlertsModalDisplay = () => {
    // console.log('alsrts', activateAlertModal, activateAlertModal ? checkWarningLengths() : true)
    return activateAlertModal ? checkWarningLengths() : true;
  };

  const clearChecks = () => {
    setSelfQuarantined(false);
    setShowingSymptoms(false);
    setMandatoryQuarantine(false);
    setCovidPositive(false);
    setCovidUpdatedDate(false);
  };

  const checkStatus = () => showingSymptoms || covidPositive;
  const selected =
    selfQuarantined || showingSymptoms || covidPositive || mandatoryQuarantine;
  const checkDefaultStatus = () => {
    return selected ? !checkStatus() && selfQuarantined : true;
  };
  const getStatusBtnText = () =>
    checkStatus() ? ` Update and Share` : "Update My Status";

  const setStatus = () => {
    toggleStatus();
    // !checkStatus() && !mandatoryQuarantin
    // mandatoryQuarantine
    // showingSymptoms
    // covidPositive
  };
const fileInputRef = useRef(null);
  
  useEffect(() => {
    const unsub = async () => {
      // console.log("activeRideRequest", userData?.activeRideRequest);
      if (clearTogglesActive) {
        // console.log("!!! Clearing", { startLocation, destination });
        if (startLocation?.length > 0) {
          await clearInput();
        }
        if (destination?.length > 0) {
          await clearDropInput();
        }
        setClearTogglesActive(false);
      }
    };
    return () => unsub();
    // getPlacePredictions(startLocation, startLocation);
  }, [userData?.activeRideRequest]);

  const handleImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      // setTriggerShow(true);
      updateProfileImage(
        event.target.files[0],
        setProfileImage,
        setLoading,
        setTriggerShow,
        fiesta,
        notifier
      );
    }
  };

  return (
    <>
      <AlertsModal toggle={checkAlertsModalDisplay()}>
        <AlertContainer>
          <CloseAlertModal
            onClick={() => {
              setActivateAlertModal(false);
            }}
          >
            <img src="/svgs/close-btn.svg" alt="" />
          </CloseAlertModal>
          <AlertIconWrapper>
            <AlertIcon
              active={alertPanelToggle}
              toggle={checkWarningLengths()}
              onClick={() => setPanel("alerts")}
            >
              <img src="/svgs/panels/alert/icon.svg" alt="" />
            </AlertIcon>
          </AlertIconWrapper>
          <AlertContentWrapper>
            <AlertModalHeader>
              Proximity to {currentWarningsLength} verified case's and{" "}
              {currentWarningsUGCLength} user reported case's of COVID19.
            </AlertModalHeader>

            <AlertModalBtn
              onClick={() => {
                setActivateAlertModal(false);
                setPanel("alerts");
              }}
            >
              More Details
            </AlertModalBtn>
          </AlertContentWrapper>
        </AlertContainer>
      </AlertsModal>
      <SearchContainer>
        {/* <TogglePanel> */}
          {/* <FriendBtn
            active={friendPanelToggle}
            onClick={() => setPanel("friend")}
          >
            <img src="/svgs/chat-nav.svg" alt="Chat Window" />
            <MainNavText>Manage Network</MainNavText>
          </FriendBtn> */}
          {/* <ToggleBtn
            active={searchPanelToggle}
            onClick={() => setPanel("search")}
          >
            <img src="/svgs/nav-ride-request.svg" alt="" />
            <MainNavText>Need a Ride?</MainNavText>
          </ToggleBtn> */}
          {/* <ToggleRideBtn
            active={ridePanelToggle}
            onClick={() => setPanel("ridePanel")}
          >
            <img src="/images/ui/sidePane/vr_woman_nav.png" alt="" />
            <MainNavText>Dropkins Dash</MainNavText>
          </ToggleRideBtn> */}
          {/* <StatusBtn active={statusPanelToggle} onClick={() => setPanel('status')}> 
            <img src="/svgs/nav-status-btn.svg" alt=""/>{checkStatus() && myLoveCount && <AlertCount> {myLoveCount}</AlertCount>}
          </StatusBtn> */}
          {/* Todo - Fix Share Panel */}
          {/* <TreasureBtn
            active={treasurePanelToggle}
            onClick={() => setPanel("treasure")}
          >
            <img src="/images/treasure/share-icon.svg" alt="" />
            <MainNavText>Share Finds</MainNavText>
          </TreasureBtn> */}
          {/* <AlertBtn
            active={alertPanelToggle}
            toggle={checkWarningLengths()}
            onClick={() => setPanel("alerts")}
          >
            <img src="/svgs/nav-alert-btn.svg" alt="" />
            <AlertCount>
              {currentWarningsLength + currentWarningsUGCLength}
            </AlertCount>
          </AlertBtn>
          <CheckListBtn
            active={checkInPanelToggle}
            onClick={() => setPanel("checkIns")}
          >
            <img src="/svgs/toggle-btn-bottom.svg" alt="" />
            <AlertCount>{checkIns && checkIns.length}</AlertCount>
          </CheckListBtn>

          {foodBankData && (
            <HopeBtn active={hopePanelToggle} onClick={() => setPanel("hope")}>
              <img src="/svgs/nav/hope-icon-text.svg" alt="" />
            </HopeBtn>
          )}
        </TogglePanel> */}

        <FriendPanel toggle={friendPanelToggle}>
          <ClosePanel onClick={() => setFriendPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>

          {/* <SearchHeader>Manage Friends</SearchHeader> */}
          {/* <SearchSubHeader>Add / Remove</SearchSubHeader> */}
          {/* <SearchCopy></SearchCopy> */}

          <ActivePanel active={1 === activeFriendPanel}>
            {triggerShow && (
              <NotificationWrapper
                setFiestaMessage={setFiestaMessage}
                setNotification={setNotification}
                setTriggerShow={setTriggerShow}
                triggerShow={triggerShow}
                alert={notification?.length > 0}
                success={fiestaMessage?.length > 0}
              >
                {!!fiestaMessage?.length && fiestaMessage}

                {!!notification?.length && notification}
              </NotificationWrapper>
            )}
            {/* <GlowBox> */}
              {/* <FriendPanelContainer> */}
                <AvatarContainer>
                  <OutlineAvatarContainer>
                <PanelSubHeader>My Profile</PanelSubHeader>
                <ClosePanel onClick={() => setFriendPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
                    <AvatarColumnContainer>
                {/* <AvatarInputContainer style={{marginRight: '10px'}}>
                    {userName?.length > 0 && <PanelSubHeader>

                       <span>Welcome {userName}!</span>
                    </PanelSubHeader>
                    }


                      </AvatarInputContainer> */}
                      <AvatarImageContainer >
                      <Avatar
                        src={
                          profileImage
                            ? profileImage
                            : "images/ui/sidePane/space_astronaut.svg"
                        }
                      />
                      </AvatarImageContainer>
                      {/* <input type="file" accept="image/*" onChange={(event) => setProfileImage(event.target.files[0])} /> */}
                      <AvatarInputContainer>
                        <input
                          type="file"
                          accept="image/*"
                          title="Upload Image"
                          onChange={handleImageUpload}
                          style={{ display: 'none' }} // Hide the input element
                          ref={fileInputRef} // Add a ref to the input element
                        />
                        <ActiveContentText onClick={() => fileInputRef.current.click()}>Edit Profile Image</ActiveContentText> 
                        <ActiveContentText onClick={ () => { setActiveFriendPanel(2)}}>View Friends</ActiveContentText> 
                        <ActiveContentText onClick={ () => { setActiveFriendPanel(3)}}>Pending Friends</ActiveContentText> 
                      </AvatarInputContainer>
                    </AvatarColumnContainer>

                    <ActiveFriendPanelContainer>

                  
                      <FriendInputContainer>
                   

                    <UserName>Update Username</UserName>
                    <UpdateUsernameContainer>
                      {/* <CheckInForm onSubmit={handleSubmit(updateUsername)}> */}
                      <FriendContentText>
                        Your username is how your friends will find you and must
                        be unique.
                      </FriendContentText>
                      
                        {errors.updateUserNameField &&
                          errors.updateUserNameField.type === "required" &&
                          <ErrorField>
                          Username is required
                          </ErrorField>}
                        {/* {errors.updateUserNameField &&
                          errors.updateUserNameField.type === "pattern" &&
                          "Username must be 4 - 20 characters; Only contain lowercase letters, numbers, and underscores"} */}
                      
                      <LoadingContainer loading={!!loading}>
                        {loading && <LoadingSpinner />}
                      </LoadingContainer>
                      <UserNameInput
                        // onChange={(event) => {
                        //   setNewUsername(event.target.value);
                        //   // resetNotifications();
                        // }}
                        {...register("updateUserNameField", {
                          required: true,
                          pattern: /^[a-zA-Z0-9_]{3,20}$/,
                          // pattern: /^[a-z0-9_]{0,20}$/,s
                        })}
                        // value={newUserName}
                        placeholder={userName}
                        type="text"
                        name={"updateUserNameField"}
                      />
                      <ActivePanelButton
                        onClick={async () => {
                          // await trigger('updateUserNameField');
                          const check = await trigger("updateUserNameField"); //Object.keys(errors).length;
                          if (check) {
                            // const { updateUserNameField } = getValues();
                            const updatedUsername = getValues(
                              "updateUserNameField"
                            );
                            if (
                              updatedUsername &&
                              updatedUsername !== userName
                            ) {
                              // console.log(check, updatedUsername);
                              setLoading(true);
                              await updateUsername(
                                updatedUsername,
                                setUserName,
                                userData,
                                setUserData,
                                setTriggerShow,
                                setLoading,
                                notifier,
                                fiesta
                              );
                            } else {
                              setTriggerShow(true);
                              notifier("No change detected", "error");
                            }
                          } else {
                            setTriggerShow(true);
                            notifier("Please enter a valid username", "error");
                            // setError("updateUserNameField", {
                            //   type: "pattern",
                            //   message:
                            //     "Username must be 4 - 20 characters; only contain lowercase letters, numbers, and underscores",
                            // });
                          }
                        }}
                      >
                        Update
                      </ActivePanelButton>
                      {/* </CheckInForm> */}

                      {/* <button onClick={() => addOrRemoveFriend(true, friendId, setFriendId, friends)}>Update Usernme</button> */}
                    </UpdateUsernameContainer>

                    <FriendInputContainer style={{marginTop: '1em', border: "1px solid white"}}>
                    <UserName>

                      Update Profile
                    </UserName>

                     


                      {/* {errors?.addFirstName && (
                      <ErrorField>
                        {" "}
                        - {`${errors?.firstName ? 'last ' : ''}`} {`${errors?.lastName ? ' and last ' : ''}`} name is required
                      </ErrorField>
                    )} */}
                      {errors?.addFirstName && (
                      <ErrorField>
                        {" "}
                        - First name is required
                      </ErrorField>
                    )}
                    
                      {errors?.addBioField && (
                      <ErrorField>
                        {" "}
                        - Biography is required
                      </ErrorField>
                    )}
                      <UserNamesContainer style={{}}>

                      <UserNameInput 
                            style={{textTransform: 'capitalize'}}
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            {...register("addFirstName", {
                              required: true,
                            })}
                            placeholder="first name"
                            type="textarea"
                            name={"addFirstName"}
                          />
                     
                     
                      <UserNameInput
                            style={{textTransform: 'capitalize'}}
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            {...register("addLastName", {
                              required: true,
                            })}
                            placeholder="Last name"
                            type="textarea"
                            name={"addLastName"}
                          />
                     
                          </UserNamesContainer>
                     
                      <UserNameInput
                            // style={{textTransform: 'capitalize'}}
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            {...register("mayorHeadlineField", {
                              required: true,
                            })}
                            placeholder="Set Mayor Headline"
                            type="textarea"
                            name={"mayorHeadlineField"}
                          />
                     



                      <UserNameTextArea
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            {...register("addBioField", {
                              // required: true,
                            })}
                            placeholder="Add bio about yourself"
                            type="textarea"
                            name={"addBioField"}
                          />



                        <ActivePanelButton 
                        style={{    alignSelf: 'flex-start'}}
                        onClick={async () => {
                          // await trigger('updateUserNameField');
                          const check = await trigger("addBioField"); //Object.keys(errors).length;
                          const checkFirst = await trigger("addFirstName"); //Object.keys(errors).length;
                          const checkLast = await trigger("addLastName"); //Object.keys(errors).length;
                          if (checkFirst) {
                            console.log('passed bio check')
                            // const { addBioField } = getValues();
                            const bioField = getValues(
                              "addBioField"
                            ) || '';
                            const firstName = getValues(
                              "addFirstName"
                            );
                            const lastName = getValues(
                              "addLastName"
                            ) || '';

                            const mayorHeadlineField = getValues(
                              "mayorHeadlineField"
                            ) || '';

                            if (
                              firstName 
                            ) {
                              // console.log(check, updatedUsername);
                              setLoading(true);
                              await updateBio(
                                bioField,
                                firstName,
                                lastName,
                                mayorHeadlineField,
                                setTriggerShow,
                                setLoading,
                                notifier,
                                fiesta
                              );
                            
                          } else {
                            setError({
                              message: "Bio field is required"
                            });
                            setTriggerShow(true);
                            notifier("Bio field is required", "error");
                          }
                        }}}
                      > Update Profile </ActivePanelButton>
                    </FriendInputContainer>

                    {/* {userName?.length > 0 && */}
                    { false &&
                        <UpdateUsernameContainer toggle={userName?.length < 0}>
                        <UserName>Add Friend</UserName>
                          {/* <CheckInForm onSubmit={handleSubmit(updateUsername)}> */}

                          <UserNameInput
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            // value={friendsUsername}
                            {...register("addFriendField", {
                              required: true,
                              pattern: /^[a-z0-9_]{4,20}$/,
                            })}
                            placeholder="Email or Username"
                            type="text"
                            name={"addFriendField"}
                          />
                          <ActivePanelButton
                            onClick={() => {
                              // await trigger('updateUserNameField');
                              const check = 0; //Object.keys(errors).length;
                              const friendUser = getValues('addFriendField');
                              // console.log(friendUser);
                              if (!check && friendUser?.length) {
                                // const { updateUserNameField } = getValues();
                                // console.log(check, newUserName);
                                setLoading(true);
                                setTriggerShow(true);
                                addFriend(
                                  friendUser,
                                  setActiveFriendPanel,
                                  setFriends,
                                  setPendingFriends,
                                  setLoading,
                                  notifier,
                                  fiesta,
                                  setMessageToken,
                                );
                              }
                            }}
                          >
                            +
                          </ActivePanelButton>
                          {/* </CheckInForm> */}

                          {/* <button onClick={() => addOrRemoveFriend(true, friendId, setFriendId, friends)}>Update Usernme</button> */}
                        </UpdateUsernameContainer>
}


{/*  Temp Hide Errands Component until finished */}
                    {/* <UserName>Manage Posts</UserName>
                    <UpdateUsernameContainer>
                      <FriendContentText>
                        Need to move a couch, or help with an ikea desk? 
                      </FriendContentText>
                      <FriendContentText>
                        Set your rate, and see if someone in your network can help!
                      </FriendContentText>
                      </UpdateUsernameContainer> */}
                      {/* <UserListings /> */}

                  </FriendInputContainer>
                     
                    </ActiveFriendPanelContainer>
                  </OutlineAvatarContainer>

                 
                </AvatarContainer>
                <UserNameContainer></UserNameContainer>
                {/* <UserNameContainerPanel> */}
                  {/* <UpdateUsernameContainer>

<UserNameButton onClick={ async () => {  await getFriends(setFriends, setPendingFriends); resetNotifications(); setActiveFriendPanel(2) }}
>
View Friends
</UserNameButton>



</UpdateUsernameContainer> */}
  </ActivePanel>
 <ActivePanel active={2 === activeFriendPanel }> 
 <AvatarContainer>
 <OutlineAvatarContainer>
 <PanelBreadCrumbs onClick={ () =>  setActiveFriendPanel(1) }>  
                <i className="fas fa-chevron-left"></i>
                 - Back to My Profile
              </PanelBreadCrumbs>
 <PanelSubHeader>Manage Friends</PanelSubHeader>
                  <ActiveFriendPanelContainer>
                    
  {/* ActiveFriendContent, */}

                     <AvatarColumnContainer>
                     {/* <ActiveContentHeader
                      style={{marginRight: '20px'}}
                     >
                            Your Network!
                          </ActiveContentHeader> */}
                          <AvatarImageContainer >
                      <Avatar 
                        src={
                          profileImage
                            ? profileImage
                            : "images/ui/sidePane/space_astronaut.svg"
                        }
                      />
                      </AvatarImageContainer>
                      <AvatarInputContainer>
                      <ActiveContentText onClick={ () =>  setActiveFriendPanel(1) }>{ `Back to My Profile`}</ActiveContentText> 
                      <ActiveContentText onClick={ () =>  setActiveFriendPanel(3) }>Pending Friends</ActiveContentText> 
                      </AvatarInputContainer>
                      {/* <input type="file" accept="image/*" onChange={(event) => setProfileImage(event.target.files[0])} /> */}
                </AvatarColumnContainer>
                

                    <FriendListContainer>

                    <FriendInputContainer toggle={userName?.length > 0}>
                    {/* <PanelHeader>
                      Aloha, <span>{userName}!</span>
                    </PanelHeader> */}
                        <UserName>Add New Friend</UserName>
                        <UpdateUsernameContainer>
                          {/* <CheckInForm onSubmit={handleSubmit(updateUsername)}> */}

                          <UserNameInput
                            onChange={(event) => {
                              // setFriendsUsername(
                              //   event.target.value?.toLowerCase()
                              // );
                              // resetNotifications();
                            }}
                            // value={friendsUsername}
                            {...register("addFriendFieldB", {
                              required: true,
                              pattern: /^[a-z0-9_]{4,20}$/,
                            })}
                            placeholder="Email or Username"
                            type="text"
                            name={"addFriendFieldB"}
                          />
                          <ActivePanelButton
                            onClick={() => {
                              // await trigger('updateUserNameField');
                              const check = 0; //Object.keys(errors).length;
                              // console.log('add f')
                              const friendUser = getValues('addFriendFieldB');
                              // console.log(friendUser);
                              if (!check && friendUser?.length) {
                                // const { updateUserNameField } = getValues();
                                // console.log(check, newUserName);
                                setLoading(true);
                                setTriggerShow(true);
                                addFriend(
                                  friendUser,
                                  setActiveFriendPanel,
                                  setFriends,
                                  setPendingFriends,
                                  setLoading,
                                  notifier,
                                  fiesta,
                                  setMessageToken,
                                );

                              }
                            }}
                          >
                            +
                          </ActivePanelButton>
                          {/* </CheckInForm> */}

                          {/* <button onClick={() => addOrRemoveFriend(true, friendId, setFriendId, friends)}>Update Usernme</button> */}
                        </UpdateUsernameContainer>
                      </FriendInputContainer>


                      
                      <ActiveFriendContent key={"friend-list-header"}>
                          
                          {/* <ApprovedDriverToggle
                            approved={true}
                            src={
                              true
                                ? "/images/ui/driver_active.svg"
                                : "/images/ui/driver_default.svg"
                            }
                          /> */}




                      
                          <ActiveContentText>
                          Click on the icon by their name to approve them as a
                          driver.
                          </ActiveContentText>

                      </ActiveFriendContent>

                      

                      {friends?.length > 0 && <ManageFriendListWrapper>

                        {friends?.map((friend) => {
                          console.log({ friend }, friend?.id, friends?.length);
                          // let friendList = [];

                          return (
                            <ManageFriendListContainer key={friend?.id}>
                                <ManageFriendListAvatar approved={friend?.approved} >
                                <ApprovedDriverToggle
                                  approved={friend?.approved}
                                  onClick={() =>
                                    activateDriver(
                                      friend?.approved,
                                      friend?.id,
                                      setFriends,
                                      activeFriends,
                                      setActiveFriends,
                                      setTriggerShow,
                                      notifier,
                                      fiesta
                                    )
                                  }
                                  src={
                                    friend?.profilePhoto
                                      ? friend?.profilePhoto
                                      : "/images/ui/driver_default.svg"
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/images/ui/driver_default.svg";
                                  }}
                                />
                                </ManageFriendListAvatar>
                                <ManageFriendListUserName>
                                  {friend?.userName}
                                </ManageFriendListUserName>
                                <ActivePanelButton  onClick={async () => {
                                  // console.log({ friend });
                                  await setFriendId(friend?.id);
                                  await setChatPanelUser(friend?.userName);
                                  await loadChatMessages(
                                    `${friend?.id}`,
                                    setMessages,
                                    setChatId,
                                    chatId,
                                    setChatPanelUser,
                                  );
                                  // setFriendPanelToggle(false);
                                  setChatPanelToggle(true);
                                }}
                              >
                                Chat

                                </ActivePanelButton>
                                <ManageFriendListClose onClick={() => {
                                    deleteFriend(
                                      friend?.id,
                                      setFriends,
                                      setActiveFriends,
                                      friends,
                                      activeFriends,
                                      notifier,
                                      fiesta
                                    );
                                    setTriggerShow(true);
                                  }}
                                >
                                  X

                                </ManageFriendListClose>
                              </ManageFriendListContainer>
                        )
                        })
                        }
                  </ManageFriendListWrapper>}





                      {/* {friends?.length > 0 &&
                        friends?.map((friend) => {
                          console.log({ friend }, friend?.id, friends?.length);
                          // let friendList = [];

                          return (
                            <FriendContainer key={friend?.id}>
                              <UserNameButton>
                                <div
                                  onClick={() => {
                                    deleteFriend(
                                      friend?.id,
                                      setFriends,
                                      setActiveFriends,
                                      friends,
                                      activeFriends,
                                      notifier,
                                      fiesta
                                    );
                                    setTriggerShow(true);
                                  }}
                                >
                                  {" "}
                                  X Delete{" "}
                                </div>
                              </UserNameButton>
                              <UserNameText> {friend?.userName}</UserNameText>
                              <OutlineAvatarContainer>
                                <ApprovedDriverToggle
                                  approved={friend?.approved}
                                  onClick={() =>
                                    activateDriver(
                                      friend?.approved,
                                      friend?.id,
                                      setFriends,
                                      activeFriends,
                                      setActiveFriends,
                                      setTriggerShow,
                                      notifier,
                                      fiesta
                                    )
                                  }
                                  src={
                                    friend?.approved
                                      ? "/images/ui/driver_active.svg"
                                      : "/images/ui/driver_default.svg"
                                  }
                                />
                              </OutlineAvatarContainer>
                              <UserNameButton
                                onClick={async () => {
                                  console.log({ friend });
                                  await setFriendId(friend?.id);
                                  await setChatPanelUser(friend?.userName);
                                  await loadChatMessages(
                                    `${friend?.id}`,
                                    setMessages,
                                    setChatId,
                                    chatId
                                  );
                                  // setFriendPanelToggle(false);
                                  setChatPanelToggle(true);
                                }}
                              >
                                Chat
                              </UserNameButton>
                            </FriendContainer>
                          );
                        })} */}
                    </FriendListContainer>
                  </ActiveFriendPanelContainer>
                  </OutlineAvatarContainer>
                  </AvatarContainer>


                  {/* <h1>Enter a friend's ID:</h1>
            <input type="text" value={userName} onChange={(event) => setFriendId(event.target.value)} />
            <button onClick={() => addOrRemoveFriend(true, friendId, setFriendId, friends)}>Add Friend</button>
            <h2>Your friends:</h2>
            <ul>
            {friends.map((friend) => (
              <li key={friend.id}>{friend.id}</li>
              ))}
              </ul>
            <button onClick={() => addOrRemoveFriend(false, friendId, setFriendId, friends)}>Remove Friend</button> */}
                {/* </UserNameContainerPanel> */}
              {/* </FriendPanelContainer> */}
            {/* </GlowBox> */}
          </ActivePanel>

          {/*  Active Friends View */}
          {/* <ActivePanel active={1 === activeFriendPanel || true}> */}
          {/* <UserNameButton onClick={ () => { setActiveFriendPanel(1) }}
              >
                Add Friend
              </UserNameButton> */}

          {/* 
                <UpdateUsernameContainer>

              <UserNameButton onClick={ async () => {  await getFriends(setFriends, setPendingFriends); resetNotifications(); setActiveFriendPanel(3) }}
                >
                  View Pending Friends
                </UserNameButton> 

              


              </UpdateUsernameContainer> */}

          {/* </ActivePanel> */}

          {/*  Pending Friends View */}
          <ActivePanel active={3 === activeFriendPanel}>
            {/* <UserNameButton onClick={ () => { setActiveFriendPanel(1); setUserName(userName)}}
              >
                Back
              </UserNameButton> */}
              <AvatarContainer>
<OutlineAvatarContainer>

              <PanelSubHeader>Pending Friends</PanelSubHeader>
              <PanelBreadCrumbs onClick={ () =>  setActiveFriendPanel(1) }>  
                <i className="fas fa-chevron-left"></i>
                 - Back to My Profile
              </PanelBreadCrumbs>
            <ActiveFriendPanelContainer>
              <AvatarColumnContainer>
              <AvatarImageContainer className="w-150 h-150">
                      <Avatar 
                        src={
                          profileImage
                            ? profileImage
                            : "images/ui/sidePane/space_astronaut.svg"
                        }
                      />
                      </AvatarImageContainer>
                      <AvatarInputContainer>
                        <ActiveContentText onClick={ () =>  setActiveFriendPanel(1) }>Back to My Profile</ActiveContentText> 
                        <ActiveContentText onClick={ () =>  setActiveFriendPanel(2) }>View Friends</ActiveContentText> 
                      </AvatarInputContainer>
                      {/* <input type="file" accept="image/*" onChange={(event) => setProfileImage(event.target.files[0])} /> */}
                </AvatarColumnContainer>


              {pendingFriends?.length > 0 && (
                <FriendListContainer>
                  {pendingFriends?.map((pendingFriend, i) => {
                    // console.log(pendingFriends.length, 'pend', {friend}, friend?.id)
                    // let friendList = [];

                    return (
                      <OutlineColumnContainer key={"key" + i}>
                        <UpdateUsernameContainer>
                          <AvatarImageContainer small>
                            <Avatar  src={pendingFriend?.profilePhoto || `/images/ui/driver_default.svg`} />
                          </AvatarImageContainer>
                        </UpdateUsernameContainer>
                        <UpdateUsernameContainer>
                          {pendingFriend?.userName}
                        </UpdateUsernameContainer>
                        {/* Add a remove request button */}
                        <AcceptButton
                          active={pendingFriend?.accepted}
                          onClick={async () => {
                            await acceptFriend(
                              pendingFriend?.id,
                              setPendingFriends,
                              setActiveFriendPanel,
                              setFriends,
                              notifier,
                              fiesta
                            );
                            setTriggerShow(true);
                          }}
                        >
                          Accept
                        </AcceptButton>
                        {pendingFriend?.accepted && (
                          <PendingButton
                            disabled={true}
                            active={!pendingFriend?.accepted}
                          >
                            Pending
                          </PendingButton>
                        )}
                      </OutlineColumnContainer>
                    );
                  })}
                </FriendListContainer>
              )}






            </ActiveFriendPanelContainer>
            
            </OutlineAvatarContainer>
            </AvatarContainer>
            {/* 
            <UpdateUsernameContainer>
              <UserNameButton
                onClick={() => {
                  setActiveFriendPanel(2);
                }}
              >
                View Active Friends
              </UserNameButton>
            </UpdateUsernameContainer> */}
          </ActivePanel>
        </FriendPanel>
        <SearchPanel toggle={searchPanelToggle}>
          <ClosePanel onClick={() => setSearchPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          {!userData?.userName && (
            <OutlineContainer>
              <GlowBox>
                <SearchSubHeader>
                  Must set a username to get started
                </SearchSubHeader>
              </GlowBox>
              <SearchCopy>
                Required: Click on Manage Network to define your unique
                username.
                <br />
                Your Username is how people can find and add you to their
                network.
              </SearchCopy>
              <LinkBtn
                active={friendPanelToggle}
                onClick={() => setPanel("friend")}
              >
                Manage Network
              </LinkBtn>
            </OutlineContainer>
          )}

          {userData?.userName && (
            <>
              {/* <SearchSubHeader>Enter your trip details below</SearchSubHeader> */}
              {/* <SearchSubHeader>Activate to alert network</SearchSubHeader> */}

              {/* Check if existing start and end location then print them out letting them know there's an existing ride */}
              {/* <SearchCopy>Existing Ride Request</SearchCopy> */}
              {/* <SearchCopy>Start: {startLocation}</SearchCopy>
          <SearchCopy>End: {endLocation}</SearchCopy> */}
              <SearchCopy>
                <strong>
                  {" "}
                  {startLocation && destination
                    ? "Existing Ride Request"
                    : userData?.activePassenger?.status === "accepted"
                    ? "Currently on a ride"
                    : "Enter Your Ride Request Details:"}
                </strong>
                {/* - Requesting a new one will cancel the existing one. */}
              </SearchCopy>

              <SearchTextContent>
                {startLocation && destination
                  ? `Start: ${
                      location === "Current Location" ? location : startLocation
                    }`
                  : ""}
              </SearchTextContent>
              <SearchTextContent>
                {startLocation && destination ? `End: ${destination}` : ""}
              </SearchTextContent>

              {/* Cancel Ride Request */}
              {startLocation && destination && (
                <SearchCopy>
                  <SearchCancelBtn
                    onClick={async () => {
                      // await setStartLocation("");
                      // await setDestination("");
                      await setTriggerShow(true);
                      await toggleActiveRideRequest(
                        false,
                        userData,
                        setUserData,
                        setClearTogglesActive,
                        setTriggerShow,
                        setLoading,
                        notifier,
                        fiesta
                      );
                    }}
                  >
                    Cancel Ride Request
                  </SearchCancelBtn>
                </SearchCopy>
              )}

              <CheckInForm onSubmit={handleSubmit(addMarker)}>
                {errors && errors?.message}
                <SelectedHidden
                  {...register("predictionInputSelected", {
                    required:
                      watchedLocation === "Current Location" ? false : true,
                    minLength: 4,
                  })}
                />
                <SelectedHidden
                  {...register("predictionDropInputSelected", {
                    required: true,
                    minLength: 4,
                  })}
                />

                <OutlineContainer>
                  <UseCurrentText>Set Destination: - </UseCurrentText>
                  <RefreshUiBtn onClick={() => window.location.reload(false)}>
                    Refresh UI
                  </RefreshUiBtn>
                  <LocationInputContainer key={2}>
                    <Location
                      type="text"
                      // value={dropLocation}
                      // autocomplete="off"
                      // name="setDropLocation"
                      // onBlur={(e) => {
                      //   // e.preventDefault();
                      //   const checkErrors = async () => {
                      //     const dropCheck = await trigger("setDropLocation");
                      //     console.log("dropCheck", dropCheck);
                      //   };
                      //   checkErrors();
                      // }}
                      placeholder="Where do you want to go?"
                      {...register("setDropLocation", {
                        required: true,
                      })}
                    />
                    {errors?.setDropLocation &&
                      errors?.setDropLocation.type === "minLength" && (
                        <ErrorField>
                          {"Must be longer than 4 Characters - "}
                          <br />
                          {errors?.setDropLocation.message}
                        </ErrorField>
                      )}
                    {errors?.predictionDropInputSelected && (
                      <ErrorField>
                        {" "}
                        Please Enter Location and Select address from the list
                        bellow
                      </ErrorField>
                    )}
                    <LocationAutoComplete>
                      {predictionsDropToggle &&
                        dropPredictions &&
                        dropPredictions.map((dropPrediction) => (
                          <Prediction
                            key={dropPrediction}
                            onClick={(e) => {
                              e.preventDefault();
                              selectDropPrediction(dropPrediction);
                              // setValue(
                              //   "predictionDropInputSelected",
                              //   dropPrediction
                              // );
                            }}
                          >
                            {dropPrediction}
                          </Prediction>
                        ))}
                    </LocationAutoComplete>
                    <ClearInputBtn
                      onClick={() => {
                        clearDropInput();
                        clearErrors("predictionDropInputSelected");
                        // GetRideEstimate(
                        //   rideEstimate,
                        //   setRideEstimate,
                        //   location,
                        //   dropLocation,
                        //   position,
                        //   rideDistanceEstimate,
                        //   setRideDistanceEstimate,
                        //   notifier,
                        //   fiesta
                        // );
                      }}
                    >
                      <img src="/svgs/close-btn.svg" alt="" />
                    </ClearInputBtn>
                  </LocationInputContainer>
                </OutlineContainer>

                <OutlineContainer>
                  <UseCurrentText>Set Pickup Location:</UseCurrentText>
                  {/* TODO - Enable Current Location when it works :p  */}
                  <UseCurrentLocationContainer
                    onClick={(e) => {
                      grabCurrentLocation();
                      setValue("predictionInputSelected", "Current Location");
                      setUseCurrent(!useCurrent);
                    }}
                  >
                    <UseCurrentBtn useCurrent={useCurrent}>
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>Use current location</UseCurrentText>
                  </UseCurrentLocationContainer>

                  <LocationInputContainer key={1}>
                    <Location
                      type="text"
                      // value={location}
                      autocomplete="off"
                      // onBlur={(e) => {
                      //   // e.preventDefault();
                      //   const checkErrors = async () => {
                      //     const pickupCheck = await trigger("setPickupInput");
                      //     console.log("pickupCheck", pickupCheck);
                      //   };
                      //   checkErrors();
                      //   // console.log('blur', e.target.value)
                      //   // if (e.target.value.length > 0) {
                      //   //   setValue('predictionInputSelected', e.target.value)
                      //   // }
                      // }}
                      placeholder="Where should they pick you up?"
                      {...register("setPickupInput", {
                        required: true,
                      })}
                    />
                    {errors?.setPickupInput &&
                      errors?.setPickupInput.type === "minLength" && (
                        <ErrorField>
                          {" "}
                          {errors?.setPickupInput.message}
                        </ErrorField>
                      )}
                    {errors?.predictionInputSelected && (
                      <ErrorField>
                        {" "}
                        Please Enter Location and Select address from the list
                        bellow
                      </ErrorField>
                    )}
                    <LocationAutoComplete>
                      {predictionsToggle &&
                        predictions &&
                        predictions.map((prediction) => (
                          <Prediction
                            key={prediction}
                            onClick={(e) => {
                              e.preventDefault();
                              selectPrediction(prediction);
                              // setValue("predictionInputSelected", prediction);
                            }}
                          >
                            {prediction}
                          </Prediction>
                        ))}
                    </LocationAutoComplete>
                    <ClearInputBtn
                      onClick={() => {
                        clearInput();
                      }}
                    >
                      <img src="/svgs/close-btn.svg" alt="" />
                    </ClearInputBtn>
                  </LocationInputContainer>
                </OutlineContainer>

                <OutlineContainer>
                  <UseCurrentText>
                    Set Passenger Count: {passengerCount}
                  </UseCurrentText>
                  <PassengerCountContainer>
                    <PassengerCountBtn
                      seats={1}
                      active={passengerCount === 1}
                      onClick={() => setPassengerCount(1)}
                    >
                      <PassengerCountBtnContent>1</PassengerCountBtnContent>
                    </PassengerCountBtn>
                    {/* <PassengerCountBtn seats={2}
                      active={passengerCount === 2}
                      onClick={() => setPassengerCount(2)}
                    >
                      <PassengerCountBtnContent>
                        2
                      </PassengerCountBtnContent>
                    </PassengerCountBtn> */}
                    <PassengerCountBtn
                      seats={3}
                      active={passengerCount === 3}
                      onClick={() => setPassengerCount(3)}
                    >
                      <PassengerCountBtnContent>3</PassengerCountBtnContent>
                    </PassengerCountBtn>
                    <PassengerCountBtn
                      seats={4}
                      active={passengerCount === 4}
                      onClick={() => setPassengerCount(4)}
                    >
                      <PassengerCountBtnContent>4</PassengerCountBtnContent>
                    </PassengerCountBtn>
                    <PassengerCountBtn
                      seats={6}
                      active={passengerCount === 6}
                      onClick={() => setPassengerCount(6)}
                    >
                      <PassengerCountBtnContent>6</PassengerCountBtnContent>
                    </PassengerCountBtn>
                  </PassengerCountContainer>
                </OutlineContainer>

                {!customerData?.subscription?.active && (
                  <SubscriptionGate toggle={freeRideToggle === "free"}>
                    <OutlineContainer>
                      <SubscriptionGateHeader>
                        Sign up for unlimited monthly requests
                      </SubscriptionGateHeader>
                      <SubscriptionGateContent>
                        <SubscriptionGateImg src="/images/dropkins/ui/rides/sub_required_v001.jpg" />
                        <SubscriptionGateText>
                          <strong>Missing Subscription</strong>
                        </SubscriptionGateText>
                        <SubscriptionGateBtn />
                      </SubscriptionGateContent>
                      å
                    </OutlineContainer>
                  </SubscriptionGate>
                )}

                <OutlineContainer
                  toggle={
                    !watchedPredictionInputSelected?.length > 0 &&
                    !watchedDropPredictionInputSelected?.length > 0
                  }
                >
                  <SetDonationContainer>
                    <UseCurrentText toggle={false}>
                      - Ride Summary -{" "}
                      <OutlineContainer>
                        <strong>{freeRideToggle}</strong>
                      </OutlineContainer>
                      <GlowBox
                        onClick={(e) => {
                          setFreeRideToggle(freeRideToggle);
                          setTriggerShow(true);
                          // // trigger('setPickupInput');
                          // // trigger('setDropLocation');

                          GetRideEstimate(
                            rideEstimate,
                            setRideEstimate,
                            watchedLocation,
                            watchedDropLocation,
                            position,
                            rideDistanceEstimate,
                            setRideDistanceEstimate,
                            notifier,
                            fiesta
                          );
                        }}
                      >
                        Request Ride Estimates
                      </GlowBox>
                    </UseCurrentText>
                    <UseCurrentText toggle={false}>
                      Ride Request set as: <strong>{freeRideToggle}</strong>
                    </UseCurrentText>
                    {rideDistanceEstimate?.duration?.text && (
                      <UseCurrentText toggle={!freeRideToggle?.length}>
                        Est. Ride Length is:{" "}
                        <strong>{rideDistanceEstimate?.duration?.text}</strong>
                      </UseCurrentText>
                    )}
                    {rideDistanceEstimate?.distance?.text && (
                      <UseCurrentText toggle={!freeRideToggle}>
                        Est. Ride Distance is:{" "}
                        <strong>{rideDistanceEstimate?.distance?.text}</strong>
                      </UseCurrentText>
                    )}
                    {rideEstimate && (
                      <RideCostEstimate toggle={freeRideToggle !== "free"}>
                        <OutlineContainer>
                          <UseCurrentText toggle={freeRideToggle !== "free"}>
                            Projected Ride Cost:{" "}
                            <strong>GoodWill and cheer</strong>
                          </UseCurrentText>
                        </OutlineContainer>
                      </RideCostEstimate>
                    )}
                    {rideEstimate > 0 && (
                      <RideCostEstimate toggle={freeRideToggle !== "paid"}>
                        <OutlineContainer>
                          <UseCurrentText toggle={freeRideToggle !== "paid"}>
                            Estimated Ride Total
                          </UseCurrentText>
                          {/* <GlowBox> */}
                          <strong>${rideEstimate}</strong>
                          {/* </GlowBox> */}
                        </OutlineContainer>
                      </RideCostEstimate>
                    )}
                    {rideEstimate > 0 && (
                      <UseCurrentText toggle={freeRideToggle !== "donation"}>
                        Minimum ammount: <strong>$3.00</strong>
                      </UseCurrentText>
                    )}
                    {rideEstimate > 0 && (
                      <RideCostEstimate toggle={freeRideToggle !== "donation"}>
                        <OutlineContainer>
                          <UseCurrentText
                            toggle={freeRideToggle !== "donation"}
                          >
                            Offer Amount <strong>${watchedAmount}</strong>
                          </UseCurrentText>
                        </OutlineContainer>
                      </RideCostEstimate>
                    )}
                  </SetDonationContainer>
                  <SetDonationInput
                    toggle={freeRideToggle === "donation" ? true : false}
                    type="text"
                    className="amount"
                    placeholder="$5.00"
                    {...register("amount", {
                      required: freeRideToggle === "donation" ? true : false,
                      pattern: {
                        value: /^\d+(\.\d{0,2})?$/,
                        message: "Invalid currency format",
                      },
                      minLength: {
                        value: 1,
                        message: "Must be over 1 characters",
                      },
                      min: {
                        value: 3.0,
                        message: "Minimum value is 3.00",
                      },
                    })}
                    onBlur={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        // console.log("value", value.toFixed(2));
                        const honorMin =
                          value.toFixed(2) > 3.0 ? value.toFixed(2) : 3.0;
                        const honorTax = honorMin + honorMin * 0.1;
                        setValue("amount", honorMin);
                        setRideEstimate(honorMin);
                      } else {
                        setValue("amount", 3.0);
                        setRideEstimate(3.0);
                      }
                      trigger("amount");
                    }}
                  />
                </OutlineContainer>
                <OutlineColumnContainer
                  toggle={
                    !watchedPredictionInputSelected &&
                    !watchedDropPredictionInputSelected
                  }
                >
                  <CheckBoxContainer
                    active={freeRideToggle === "free"}
                    onClick={(e) => {
                      setFreeRideToggle("free");

                      // setTriggerShow(true);
                      // GetRideEstimate(
                      //   rideEstimate,
                      //   setRideEstimate,
                      //   location,
                      //   dropLocation,
                      //   position,
                      //   rideDistanceEstimate,
                      //   setRideDistanceEstimate,
                      //   notifier,
                      //   fiesta
                      // );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggle === "free"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>Free Ride</UseCurrentText>
                  </CheckBoxContainer>

                  <CheckBoxContainer
                    active={freeRideToggle === "donation"}
                    toggle={false}
                    onClick={(e) => {
                      setFreeRideToggle("donation");
                      // setTriggerShow(true);
                      // GetRideEstimate(
                      //   rideEstimate,
                      //   setRideEstimate,
                      //   location,
                      //   dropLocation,
                      //   position,
                      //   rideDistanceEstimate,
                      //   setRideDistanceEstimate,
                      //   notifier,
                      //   fiesta
                      // );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggle === "donation"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>$ Offer Donation</UseCurrentText>
                  </CheckBoxContainer>

                  <CheckBoxContainer
                    active={freeRideToggle === "paid"}
                    toggle={false}
                    onClick={(e) => {
                      setFreeRideToggle("paid");
                      // setTriggerShow(true);
                      GetRideEstimate(
                        rideEstimate,
                        setRideEstimate,
                        watchedLocation,
                        watchedDropLocation,
                        position,
                        rideDistanceEstimate,
                        setRideDistanceEstimate,
                        notifier,
                        fiesta
                      );
                    }}
                  >
                    <UseCurrentBtn
                      clearMargin
                      useCurrent={freeRideToggle === "paid"}
                    >
                      <img src="svgs/green-check.svg" alt="" />
                    </UseCurrentBtn>
                    <UseCurrentText>$ Paid Ride</UseCurrentText>
                  </CheckBoxContainer>
                </OutlineColumnContainer>

                <OutlineContainer>
                  <UseCurrentText toggle={freeRideToggle === "free"}>
                    Enter Payment Details:
                  </UseCurrentText>

                  {/* {freeRideToggle !== "free" && (
                    <CardElement
                      options={RIDE_ELEMENT_OPTIONS}
                      onChange={handleCardDetailsChange}
                    />
                  )} */}
                </OutlineContainer>

                <CenteredTextContainer>
                  Think of a good joke for the ride! 😄
                </CenteredTextContainer>

                <SearchSubmitContainer
                  toggle={
                    watchedLocation &&
                    watchedDropLocation &&
                    freeRideToggle === "free"
                      ? customerData?.subscription?.active
                      : true
                  }
                >
                  {/* onClick={e =>{
                  e.preventDefault();
                  addMarker();
                }} */}
                  <GlowBox>
                    <SubmitCheckIn
                      active={
                        freeRideToggle === "free"
                          ? true
                          : !!stripe || checkCardRequirements
                      }
                      type="submit"
                      onClick={async (e) => {
                        e.preventDefault();

                        if (!stripe || !elements) {
                          return;
                        }
                        setLoading(true);
                        const predCheck = await trigger("setPickupInput");
                        const predDropCheck = await trigger("setDropLocation");

                        const predOfficialCheck = await trigger(
                          "predictionInputSelected"
                        );

                        const predOfficialDropCheck = await trigger(
                          "predictionDropInputSelected"
                        );

                        if (predCheck || predDropCheck) {
                          console.log("Validation passed");
                        } else {
                          console.log("Validation failed");
                        }
                        if (predOfficialDropCheck || predOfficialCheck) {
                          console.log("Validation passed");
                        } else {
                          console.log("Validation failed");
                        }
                        // console.log("errors", errors);

                        const donationCheck = await trigger("amount");
                        if (freeRideToggle === "donation" && !donationCheck) {
                          setTriggerShow(true);
                          notifier("Please enter proper donation amount");
                        }

                        // / Check for errors
                        if (errors?.predictionInputSelected) {
                          console.log(
                            "errors pred",
                            errors?.predictionInputSelected?.message
                          );
                          setTriggerShow(true);
                          notifier("Please re-select a pickup location");
                        }

                        const errorsList =
                          predCheck &&
                          predDropCheck &&
                          donationCheck &&
                          predOfficialCheck &&
                          predOfficialDropCheck;

                        const check = Object.keys(errors).length;
                        if (errorsList) {
                          setOnCancel(
                            () => () => setToggleConfirmationModal(false)
                          ); // Update the function reference
                          setConfirmationModalText(
                            `Submitting Ride request will alert approved drivers in your network.
                              Pick up at: ${watchedLocation} and drop off at: ${watchedDropLocation}.
                              Only ride with people you trust, you are responsible for your network. We simply help to connect.
                            `
                          );
                          setConfirmationAlertText(
                            "By submitting this ride request you agree to the terms and conditions of the app."
                          );
                          setConfirmationHeaderText("Submitting Ride request");
                          setToggleConfirmationModal(true);
                          setTermsToggle(true);

                          setOnConfirm(() => async () => {
                            setToggleConfirmationModal(false);
                            setTermsToggle(false);
                            if (freeRideToggle !== "free") {
                              // console.log("Aint Free");
                              const cardElement =
                                elements.getElement(CardElement);

                              await setSetupIntent(true);

                              // console.log("clientSecret", clientSecret);
                              if (userEmail && clientSecret) {
                                const {
                                  error,
                                  setupIntent: confirmedSetupIntent,
                                } = await stripe.confirmCardSetup(
                                  clientSecret,
                                  {
                                    payment_method: {
                                      card: cardElement,
                                      billing_details: { email: userEmail }, // Replace with actual user email
                                    },
                                  }
                                );

                                if (error) {
                                  // console.log("Error:", error);
                                  // console.log(
                                  //   error?.setup_intent?.last_payment_error
                                  //     ?.message
                                  // );
                                  // console.log(error?.setup_intent?.status);

                                  if (
                                    error?.setup_intent?.status === "succeeded"
                                  ) {
                                    // console.log(
                                    //   "SetupIntent confirmed - succeeded:",
                                    //   error?.setup_intent?.payment_method
                                    // );
                                    // store payment intent id in customers collection
                                    const paymentMethod =
                                      error?.setup_intent?.payment_method;
                                    // const paymentIntentId = paymentIntent;
                                    setPaymentMethodId(paymentMethod);

                                    if (paymentMethod) {
                                      addMarker(paymentMethod);
                                    } else {
                                      setLoading(false);
                                      setTriggerShow(true);
                                      notifier(
                                        error?.setup_intent?.last_payment_error
                                          ?.message
                                      );
                                    }
                                    return;
                                  } else {
                                    setLoading(false);
                                    setTriggerShow(true);
                                    notifier(error?.message);
                                  }
                                } else {
                                  // console.log(
                                  //   "SetupIntent confirmed:",
                                  //   confirmedSetupIntent
                                  // );
                                  // Optionally handle further logic here
                                  const paymentMethod =
                                    confirmedSetupIntent?.payment_method;
                                  setPaymentMethodId(paymentMethod);
                                  addMarker(paymentMethod);
                                }
                              } else {
                                // console.log("no email or client secret");

                                const createSetupIntentCall = httpsCallable(
                                  cloudFunctions,
                                  "createSetupIntent"
                                );
                                const setupIntentResponse =
                                  await createSetupIntentCall({
                                    email: userEmail,
                                    userId: userId,
                                    stripeCustomerId:
                                      customerData?.stripeCustomerId,
                                  }); // Set the amount
                                // console.log(
                                //   "setupIntentResponse",
                                //   setupIntentResponse
                                // );
                                // console.log(
                                //   "setupIntentResponse",
                                //   setupIntentResponse?.data?.clientSecret
                                // );

                                await setClientSecret(
                                  setupIntentResponse?.data?.clientSecret
                                );

                                const {
                                  error,
                                  setupIntent: confirmedSetupIntent,
                                } = await stripe.confirmCardSetup(
                                  setupIntentResponse?.data?.clientSecret,
                                  {
                                    payment_method: {
                                      card: cardElement,
                                      billing_details: { email: userEmail },
                                    },
                                  }
                                );

                                if (error) {
                                  // console.log("no secret Error: -", error);
                                  // setPaymentMethodId(error?.setup_intent?.payment_method);

                                  if (
                                    error?.setup_intent?.status === "succeeded"
                                  ) {
                                    // console.log(
                                    //   "SetupIntent confirmed - succeeded:",
                                    //   error?.setup_intent?.payment_method
                                    // );
                                    // store payment intent id in customers collection
                                    const paymentMethod =
                                      error?.setup_intent?.payment_method;
                                    // const paymentIntentId = paymentIntent;
                                    setPaymentMethodId(
                                      error?.setup_intent?.payment_method
                                    );

                                    if (error?.setup_intent?.payment_method) {
                                      addMarker(
                                        error?.setup_intent?.payment_method
                                      );
                                    } else {
                                      setLoading(false);
                                      setTriggerShow(true);
                                      notifier(
                                        error?.setup_intent?.last_payment_error
                                          ?.message
                                      );
                                    }
                                    return;
                                  } else {
                                    setLoading(false);
                                    setTriggerShow(true);
                                    notifier(error?.message);
                                  }
                                } else {
                                  // console.log(
                                  //   "SetupIntent confirmed no secret no error:",
                                  //   confirmedSetupIntent
                                  // );
                                  const paymentMethod =
                                    confirmedSetupIntent?.payment_method;
                                  setPaymentMethodId(paymentMethod);
                                  addMarker(paymentMethod);
                                }
                              }
                            } else {
                              setLoading(false);
                              addMarker();
                            }
                          });
                        } else {
                          setLoading(false);
                          setTriggerShow(true);
                          notifier("Please check errors and retry");
                        }
                        console.log(check);
                      }}
                    >
                      Request Ride
                    </SubmitCheckIn>
                  </GlowBox>
                </SearchSubmitContainer>
              </CheckInForm>
            </>
          )}
        </SearchPanel>

        <HopePanel toggle={hopePanelToggle}>
          <ClosePanel onClick={() => setHopePanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          {/* go to hope panel */}
          <HopeHeader>
            {hopeData && <SearchHeader>{hopeData.title}</SearchHeader>}
            {hopeData.subTitle && (
              <SearchSubHeader>{hopeData.subTitle}</SearchSubHeader>
            )}
          </HopeHeader>

          {foodBankData &&
            !messageFoodSent &&
            foodBankData.map((bank) => {
              // return;
              return (
                <HopeFoodBank
                  active={messageFoodBank}
                  onClick={() => {
                    setMessageFoodBank(!messageFoodBank);
                    setSelectedFoodBank(bank);
                    setMessageFoodSent(false);
                  }}
                >
                  <FoodBankLogo>
                    <img src={`${bank.foodBankLogo}`} />
                  </FoodBankLogo>
                  <FoodBankContent>
                    <FoodBankHeader>{bank.foodBankName}</FoodBankHeader>
                    <FoodBankDetails>{bank.foodBankDesc}</FoodBankDetails>
                    <FoodBankTel>{bank.foodBankTel}</FoodBankTel>
                    <FoodBankArrow>
                      <img src="/svgs/right-arrow.svg" alt="" />
                    </FoodBankArrow>
                  </FoodBankContent>
                </HopeFoodBank>
              );
            })}

          {messageFoodSent ? (
            <SelectedHopeFoodBank>
              <GoBack
                onClick={() => {
                  setMessageFoodBank(!messageFoodBank);
                  setSelectedFoodBank({});
                  setMessageFoodSent(false);
                }}
              >
                <img src={`svgs/general/go-back.svg`} />
              </GoBack>

              <SelectedFoodBankSent active={messageFoodSent}>
                <FoodBankHeader>{foodSentMessage}</FoodBankHeader>
                <FoodSearchCopy>
                  Your request will be sent to the Samenwerkende Hulp
                  Organisaties Curaçao:
                </FoodSearchCopy>
                <FoodSearchDetails>
                  Voedselbank Curacao,Fundashon Korsou Solidario i Duradero,
                  Unidad di Bario Korsou, Aliansa Curacao,Human Rights Defense
                  Curacao, Caritas (Pastoral Social Caritas Willemstad) and
                  Rotary Club Willemstad
                </FoodSearchDetails>
                <FoodSearchDetailsRed>
                  Si bo ta den situashon di nesesidat URGENTE i Bo no tin
                  kuminda. Yama 529-1919
                </FoodSearchDetailsRed>
              </SelectedFoodBankSent>
            </SelectedHopeFoodBank>
          ) : (
            <SelectedHopeFoodBank active={!messageFoodBank && selectedFoodBank}>
              <GoBack onClick={() => setMessageFoodBank(!messageFoodBank)}>
                <img src={`svgs/general/go-back.svg`} />
              </GoBack>
              <SelectedFoodBankContent>
                <FoodBankHeader>{selectedFoodBank.foodBankName}</FoodBankHeader>
                <FoodBankDetails>
                  {selectedFoodBank.foodBankDesc}
                </FoodBankDetails>
                <FoodBankTel>{selectedFoodBank.foodBankTel}</FoodBankTel>
              </SelectedFoodBankContent>
              <SelectedFoodBankForm onSubmit={handleSubmit(submitFoodRequest)}>
                <label>
                  <FieldTitle>Name + Last Name - *</FieldTitle>
                  {errors?.foodFormFullName && (
                    <FoodErrorField>Required Field</FoodErrorField>
                  )}
                  <FieldInput
                    {...register("foodFormFullName", { required: true })}
                  />
                </label>
                <label>
                  <FieldTitle>Address - * </FieldTitle>
                  {errors?.foodFormAddress && (
                    <FoodErrorField>Required Field</FoodErrorField>
                  )}
                  <FieldInput
                    {...register("foodFormAddress", { required: true })}
                  />
                </label>
                <label>
                  <FieldTitle>Telefoonnummer</FieldTitle>
                  <FieldInput
                    type="tel"
                    {...register("foodFormTelephone", { required: false })}
                  />
                </label>
                <label>
                  <FieldTitle>e-mail</FieldTitle>
                  <FieldInput
                    type="email"
                    {...register("foodFormEmail", { required: false })}
                  />
                </label>
                <label>
                  <FieldTitle>Amount of people in household -*</FieldTitle>
                  {errors?.foodFormPeopleCount && (
                    <FoodErrorField>Required Field</FoodErrorField>
                  )}
                  <FieldInput
                    type="number"
                    {...register("foodFormPeopleCount", { required: true })}
                  />
                </label>
                <label>
                  <FieldTitle>
                    Amount of underage children in household -*
                  </FieldTitle>
                  {errors?.foodFormMinorsCount && (
                    <FoodErrorField>Required Field</FoodErrorField>
                  )}
                  <FieldInput
                    type="number"
                    {...register("foodFormMinorsCount", { required: true })}
                  />
                </label>
                <SelectedFoodSubmit
                  onClick={async () => {
                    await trigger("foodFormFullName");
                    await trigger("foodFormAddress");
                    await trigger("foodFormPeopleCount");
                    await trigger("foodFormMinorsCount");

                    // const check = errors?.length
                    const check = Object.keys(errors).length;
                    if (!check) {
                      console.log(check);
                      submitFoodRequest();
                    }
                  }}
                >
                  Send
                </SelectedFoodSubmit>
              </SelectedFoodBankForm>
            </SelectedHopeFoodBank>
          )}
        </HopePanel>

        <AdminPanel toggle={adminPanelToggle}>
          <ClosePanel onClick={() => setAdminPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <SearchHeader>Admin Panel</SearchHeader>
          <SearchSubHeader>Add Custom Markers</SearchSubHeader>

          <SubPanel>
            <SearchCopy>Hope Marker Panel </SearchCopy>
            <HopeForm onSubmit={handleSubmit(submitHope)}>
              <SelectedHidden
                // name="hopePredictionInputSelected"
                {...register("hopePredictionInputSelected", {
                  required: true,
                  minLength: {
                    value: 4,
                    message: "Must be over 4 characters", // <p>error message</p>
                  },
                })}
              />
              {errors?.hopeTitle && (
                <ErrorField>Please Enter Location</ErrorField>
              )}
              <AdminInput
                placeholder={"Panel Title"}
                name="hopeTitle"
                {...register("hopeTitle", { required: true })}
              />
              {errors?.hopeSubTitle && (
                <ErrorField>Please Enter Location</ErrorField>
              )}
              <AdminInput
                placeholder={"Display subtitle"}
                name="hopeSubTitle"
                {...register("hopeSubTitle", { required: true })}
              />
              {errors?.setHopeLocation && (
                <ErrorField>Please Enter Location</ErrorField>
              )}
              <AdminInput
                placeholder={"campaign key"}
                name="hopeKey"
                {...register("hopeKey", { required: true })}
              />
              <SubLocationInputContainer>
                <SubLocation
                  type="text"
                  value={hopeLocation}
                  autocomplete="off"
                  name="setHopeLocation"
                  onChange={(e) => {
                    e.preventDefault();
                    inputHopeChange(e);
                  }}
                  placeholder="Set Location"
                  {...register("setHopeLocation", {
                    required: true,
                    minLength: {
                      value: 4,
                      message: "Must be over 4 characters", // <p>error message</p>
                    },
                  })}
                />
                {errors?.setHopeLocation && (
                  <ErrorField>Please Enter Location</ErrorField>
                )}
                {errors?.setHopeLocation &&
                  errors?.setHopeLocation.type === "minLength" && (
                    <ErrorField> {errors?.setHopeLocation.message}</ErrorField>
                  )}
                {!errors?.setHopeLocation &&
                  errors?.hopePredictionInputSelected && (
                    <ErrorField>Then Select address bellow</ErrorField>
                  )}
                <LocationAutoComplete>
                  {predictionsToggle &&
                    predictions &&
                    predictions.map((prediction) => (
                      <Prediction
                        key={prediction}
                        onClick={(e) => {
                          e.preventDefault();
                          selectHopePrediction(prediction);
                          setValue("hopePredictionInputSelected", prediction);
                        }}
                      >
                        {prediction}
                      </Prediction>
                    ))}
                </LocationAutoComplete>
                <SubClearInputBtn
                  onClick={() => {
                    clearInput();
                  }}
                >
                  <img src="/svgs/close-btn-orange.svg" alt="" />
                </SubClearInputBtn>
              </SubLocationInputContainer>
              <SubSpacer />

              <SearchSubmitContainer>
                {/* onClick={e =>{
                  e.preventDefault();
                  addMarker();
                }} */}
                <SubmitCheckIn
                  type="submit"
                  onClick={async () => {
                    await trigger("hopePredictionInputSelected");
                    await trigger("hopeTitle");
                    await trigger("hopeSubTitle");
                    await trigger("hopeKey");
                    const check = Object.keys(errors).length;
                    if (!check) {
                      console.log(check);
                      addHope();
                    }
                  }}
                >
                  {" "}
                  Add Hope{" "}
                </SubmitCheckIn>
              </SearchSubmitContainer>
            </HopeForm>
          </SubPanel>

          <SubPanel>
            <SearchCopy>Hope Food Bank Panel </SearchCopy>
            <FoodBankForm onSubmit={handleSubmit(submitFoodBank)}>
              <AdminInput
                placeholder={"FoodBank Logo"}
                name="foodBankLogo"
                {...register("foodBankLogo", { required: true })}
              />
              <AdminInput
                placeholder={"FoodBank Name"}
                name="foodBankName"
                {...register("foodBankName", { required: true })}
              />
              <AdminInput
                placeholder={"Short Description"}
                name="foodBankDesc"
                {...register("foodBankDesc", { required: true })}
              />
              <AdminInput
                placeholder={"Telephone Number"}
                name="foodBankTel"
                {...register("foodBankTel", { required: true })}
              />
              <AdminInput
                placeholder={"campaign-key"}
                name="foodBankCampaignKey"
                {...register("foodBankCampaignKey", { required: true })}
              />
              <SubmitCheckIn
                type="submit"
                onClick={async () => {
                  await trigger("foodBankLogo");
                  await trigger("foodBankName");
                  await trigger("foodBankDesc");
                  await trigger("foodBankTel");
                  await trigger("foodBankCampaignKey");
                  const check = Object.keys(errors).length;
                  if (check) {
                    console.log(check);
                    submitFoodBank();
                  }
                }}
              >
                {" "}
                Add FoodBank{" "}
              </SubmitCheckIn>
            </FoodBankForm>
          </SubPanel>
          {/* <CheckInsList>
                {checkIns.map(checkIn => {
                return (<CheckedLocation key={checkIn.id}>
                      <CheckInText>{checkIn.address} </CheckInText>
                      <CheckInDelete onClick={() => {removeCheckIn(checkIn)}}>
                        <img src="/svgs/small-x.svg" alt=""/>
                      </CheckInDelete>
                  </CheckedLocation>)
              })}
          </CheckInsList> */}
        </AdminPanel>

        <SubmitThankYouPanel toggle={thankYouToggle}>
          <ClosePanel onClick={() => setThankYouToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <MarkerAddedContainer>
            <MarkerAddedPin>
              <img src="svgs/thank-you-pin.svg" />
            </MarkerAddedPin>
            <MarkerAddedHeader>Ride Request Status Updated!</MarkerAddedHeader>
            <MarkerAddedSubHeader>
              {Date(getCurrentDate())}
            </MarkerAddedSubHeader>
          </MarkerAddedContainer>
          <SubmitThankYouMessage>
            Continue to see a list of possible rides to
          </SubmitThankYouMessage>
          <ThankYouText></ThankYouText>
          <WithLove>
            <strong>Dropkins Family,</strong>
            <div>Together we'll achieve anything.</div>
          </WithLove>
        </SubmitThankYouPanel>

        <SubmitThankYouPanel toggle={statusThankYouToggle}>
          <ClosePanel onClick={() => setStatusThankYouToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <MarkerAddedContainer>
            <MarkerAddedPin>
              <img src="svgs/thank-you-pin.svg" />
            </MarkerAddedPin>
            <MarkerAddedHeader>Status Has Been Updated</MarkerAddedHeader>
            <MarkerAddedSubHeader>
              {Date(getCurrentDate())}
            </MarkerAddedSubHeader>
          </MarkerAddedContainer>
          <SubmitThankYouMessage>
            {checkStatus() && (
              <>
                Thank you for up :) dating your status. By informing your
                community your marker and location will be made available to the
                COVID-19 Map.
              </>
            )}
          </SubmitThankYouMessage>
          <ThankYouText>Thank You</ThankYouText>
          <WithLove>
            <strong>Hasham Family,</strong>
            <div>Together we'll achieve anything.</div>
          </WithLove>
        </SubmitThankYouPanel>

        <CheckInPanel toggle={checkInPanelToggle}>
          <ClosePanel onClick={() => setCheckInPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <SearchHeader>My Checkins List</SearchHeader>
          <SearchSubHeader>Add / remove your ride requests</SearchSubHeader>
          <SearchCopy>
            {" "}
            Refine your trackers and hone in on specific areas in your
            community. Need help call your doctor. Find help in your area here.{" "}
          </SearchCopy>
          <CheckInsList>
            {checkIns.map((checkIn) => {
              return (
                <CheckedLocation key={checkIn.id}>
                  <CheckInText>{checkIn.address} </CheckInText>
                  <CheckInDelete
                    onClick={() => {
                      removeCheckIn(checkIn);
                    }}
                  >
                    <img src="/svgs/small-x.svg" alt="" />
                  </CheckInDelete>
                </CheckedLocation>
              );
            })}
          </CheckInsList>
        </CheckInPanel>
        <StatusPanel toggle={statusPanelToggle}>
          <ClosePanel onClick={() => setStatusPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <SearchHeader>My Status Panel</SearchHeader>
          <SearchSubHeader>
            {" "}
            Update your status and alert your community.
          </SearchSubHeader>
          <SearchCopy>
            {/* When a user updates their status it will populate uncofirmed markers and alerts, helping to protecting your community.
            If you return to that window we will also help you track how many days its been since you've updated your status. Unchecking them will reset those alerts. */}
          </SearchCopy>

          <StatusText>
            {checkStatus() && myLoveCount && (
              <>
                {" "}
                {myLoveCount} people sent you Love! And thank you for helping to
                protect them.
              </>
            )}
          </StatusText>

          <LastReportedContainer>
            {/* <LastReportedGraphic>
              { !checkStatus() && <img src="images/you-pin.png" alt=""/>}
              { checkStatus() && <img src="/svgs/alert-graphic.svg" alt=""/>}

            </LastReportedGraphic>
            <StatusLastReportedStats>
              {covidUpdatedDate && Date(covidUpdatedDate)}
            </StatusLastReportedStats> */}
            <LastReportedGraphic>
              {!checkStatus() && !mandatoryQuarantine && (
                <img src="/svgs/status/status-self-safe.svg" />
              )}
              {mandatoryQuarantine && (
                <img src="/svgs/status/status-mandatory-safe.svg" />
              )}
              {showingSymptoms && (
                <img src="/svgs/status/status-mandatory-symptoms.svg" />
              )}
              {covidPositive && (
                <img src="/svgs/status/status-recovering.svg" />
              )}
              {/* { !checkStatus() && <img src="images/you-pin.png" alt=""/>}
              { checkStatus() && <img src="/svgs/alert-graphic.svg" alt=""/>} */}
            </LastReportedGraphic>
          </LastReportedContainer>

          {/* <StatusFormHeader>
            Has Anything Changed?
          </StatusFormHeader> */}
          <StatusFormQuestions>
            <FormQuestion
              onClick={() => {
                clearChecks();
                setSelfQuarantined(!selfQuarantined);
              }}
            >
              <FormBox active={checkDefaultStatus()} />{" "}
              <FormLabel>Self Quarantined and Safe</FormLabel>
            </FormQuestion>

            <FormQuestion
              onClick={() => {
                clearChecks();
                setMandatoryQuarantine(!mandatoryQuarantine);
              }}
            >
              <FormBox active={!checkStatus() && mandatoryQuarantine} />{" "}
              <FormLabel> Mandatory isolation and Safe</FormLabel>
            </FormQuestion>
            <FormQuestion
              onClick={() => {
                clearChecks();
                setShowingSymptoms(!showingSymptoms);
              }}
            >
              <FormBox active={showingSymptoms} />{" "}
              <FormLabel> Mandatory isolation with symptoms</FormLabel>
            </FormQuestion>
            <FormQuestion
              onClick={() => {
                clearChecks();
                setCovidPositive(!covidPositive);
              }}
            >
              <FormBox active={covidPositive} />{" "}
              <FormLabel>Recovering</FormLabel>
            </FormQuestion>
          </StatusFormQuestions>

          <StatusUpdateBtn onClick={() => setStatus()} enabled={checkStatus()}>
            {getStatusBtnText()}
          </StatusUpdateBtn>

          {/* <AlertRecommendations>
          <AlertRecommendation>
            <a target="_blank" href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/symptoms.html">
              1. Check Symptoms
            </a>
          </AlertRecommendation>
          <AlertRecommendation>
            <a target="_blank" href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html">
              2. What to do if sick?
            </a>
          </AlertRecommendation>
          <AlertRecommendation contracted={userContractedCovid} onClick={ e => toggleStatus(e)}>
            3. Update your Status
          </AlertRecommendation>
        </AlertRecommendations> */}
        </StatusPanel>
        <AlertPanel toggle={alertPanelToggle}>
          <ClosePanel onClick={() => setAlertPanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <SearchHeader>My Alerts</SearchHeader>
          <SearchSubHeader>Your Alert Notifications</SearchSubHeader>
          <LastReportedDate>{Date(getCurrentDate())}</LastReportedDate>
          <AlertHeader>
            <AlertHeaderText>
              {currentWarningsLength} Confirmed Cases
            </AlertHeaderText>
            <AlertHeaderText>
              {currentWarningsUGCLength} Unconfirmed Cases
            </AlertHeaderText>
          </AlertHeader>
          <AlertSubHeader>
            You may want to contact your primary healthcare physician after
            following the next steps.
          </AlertSubHeader>

          <LastReportedContainer>
            <LastReportedGraphic>
              <img src="/svgs/alert-graphic.svg" alt="" />
            </LastReportedGraphic>
            <LastReportedStats>
              <LastReportedDate>
                <strong>Current Date:</strong>
                <span>{Date(getCurrentDate())}</span>
              </LastReportedDate>
              <LastReportedStatus>
                <strong>My Status:</strong>
                <span>
                  {checkStatus() ? "Possibly Contracted" : "So far so good"}
                </span>
              </LastReportedStatus>

              <AlertRecommendations>
                <AlertRecommendation
                  contracted={userContractedCovid}
                  onClick={(e) => setPanel("status")}
                >
                  Update Status
                </AlertRecommendation>
              </AlertRecommendations>
            </LastReportedStats>
          </LastReportedContainer>

          <AlertExposureLocations>
            {currentWarningsLength ? (
              <AlertExposureList>
                <SearchSubHeader>
                  {currentWarningsLength} Confirmed Reports
                </SearchSubHeader>
                <ExposureContainer>
                  {currentWarningsLength &&
                    currentWarnings.map((warn, index) => {
                      return <WarnList key={index}>{warn.address}</WarnList>;
                    })}
                </ExposureContainer>
              </AlertExposureList>
            ) : null}
            <AlertExposureList>
              {currentWarningsUGCLength && (
                <SearchSubHeader>
                  {currentWarningsUGCLength} Unverified Reports
                </SearchSubHeader>
              )}
              <ExposureContainer>
                {currentWarningsUGC &&
                  currentWarningsUGC.map((warn, index) => {
                    return (
                      <WarnListUGC key={index}>{warn.address}</WarnListUGC>
                    );
                  })}
              </ExposureContainer>
            </AlertExposureList>
          </AlertExposureLocations>
          <AlertRecommendations>
            <AlertSubHeader>Here are some useful resources</AlertSubHeader>
            <AlertRecommendation>
              <a
                target="_blank"
                href="https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/index.html#cdc-chat-bot-open"
              >
                1. Symptoms Chat Bot
              </a>
            </AlertRecommendation>
            <AlertRecommendation>
              <a
                target="_blank"
                href="https://www.cdc.gov/coronavirus/2019-ncov/if-you-are-sick/steps-when-sick.html"
              >
                2. What to do if sick?
              </a>
            </AlertRecommendation>
          </AlertRecommendations>
        </AlertPanel>

        <TreasurePanel toggle={treasurePanelToggle}>
          <ClosePanel onClick={() => setTreasurePanelToggle(false)}>
            <img src="/svgs/close-btn.svg" alt="" />
          </ClosePanel>
          <SearchHeader>Found A Resource?</SearchHeader>
          <SearchSubHeader>
            Help others find resources they need
          </SearchSubHeader>
          {/* <SearchCopy>In solidarity with protests we've added a water bottle and medic icon, drop markers to help guide protesters to either resource.</SearchCopy> */}
          <SearchCopy>
            Alert your community about important resources or information, this
            is a public board -please be mindful.{" "}
            <strong>
              Misuse can result in a ban from all services at our discretion.
            </strong>
          </SearchCopy>
          <SearchSuggestMore>
            Suggest more items
            <SearchSuggestMoreLink
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSeUH2qEyytEvg02L_9lPgjL9Nf3zNOPcXT4KGx-pwlhjVwmug/viewform?usp=sf_link"
            >
              By filing out our form!
            </SearchSuggestMoreLink>
          </SearchSuggestMore>
          <TreasureForm onSubmit={handleSubmit(submitTreasure)}>
            {errors?.selectedItem && (
              <ErrorField>Must Select an item</ErrorField>
            )}
            <SelectedTitleInput
              active={watch("selectedItemTreasure")}
              name="selectedItemName"
              {...register("selectedItemName", { required: false })}
            />
            <SelectedHidden
              name="selectedItemTreasure"
              {...register("selectedItemTreasure", { required: true })}
            />
            <SelectedHidden
              name="predictionSelectedTreasure"
              {...register("predictionSelectedTreasure", { required: true })}
            />
            <SelectItemContainer>
              {/* <Treasure active={watch('selectedItem') == 'protest'} key={'protest'} onClick={() => setSelectedValue('protest')}>
                <img src="/images/treasure/blackpower.png" alt=""/>
              </Treasure> */}
              <Treasure
                active={watch("selectedItem") == "waterBottle"}
                key={"waterBottle"}
                onClick={() => setSelectedValue("waterBottle")}
              >
                <img src="/images/treasure/water-bottle.png" alt="" />
                <TreasureLabel>Water</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "freeFood"}
                key={"freeFood"}
                onClick={() => setSelectedValue("freeFood")}
              >
                <img src="/images/treasure/free-food.svg" alt="" />
                <TreasureLabel>Free Food</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "fundsRequest"}
                key={"fundsRequest"}
                onClick={() => setSelectedValue("fundsRequest")}
              >
                <img src="/images/treasure/funds-request.svg" alt="" />
                <TreasureLabel>Go Fund Me</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "freeShelter"}
                key={"freeShelter"}
                onClick={() => setSelectedValue("freeShelter")}
              >
                <img src="/images/treasure/free-shelter.svg" alt="" />
                <TreasureLabel>Free Shelter</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "medicStation"}
                key={"medicStation"}
                onClick={() => setSelectedValue("medicStation")}
              >
                <img src="/images/treasure/medic-icon.png" alt="" />
                <TreasureLabel>Medic Station</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "safeList"}
                key={"safeList"}
                onClick={() => setSelectedValue("safeList")}
              >
                <img src="/images/treasure/safe-list.svg" alt="" />
                <TreasureLabel>URL for emergency Mark Safe Lists</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "alertMarker"}
                key={"alertMarker"}
                onClick={() => setSelectedValue("alertMarker")}
              >
                <img src="/images/treasure/alert-marker.svg" alt="" />
                <TreasureLabel>Add hazard Markers</TreasureLabel>
              </Treasure>
              <Treasure
                active={watch("selectedItem") == "requestHelp"}
                key={"requestHelp"}
                onClick={() => setSelectedValue("requestHelp")}
              >
                <img src="/images/treasure/request-help.svg" alt="" />
                <TreasureLabel>ADD SOS Marker</TreasureLabel>
                <TreasureLabel>Dial 911 first</TreasureLabel>
                <TreasureLabel>(Emergency only)</TreasureLabel>
              </Treasure>
              {/* <Treasure active={watch('selectedItem') == 'lysolWypes'} key={'lysolWypes'} onClick={() => setSelectedValue('lysolWypes')}>
                <img src="/images/wypes-icon-small-b.png" alt=""/>
              </Treasure> */}
              {/* <Treasure active={watch('selectedItem') == 'lysolSpray'} key={'lysolSpray'} onClick={() => setSelectedValue('lysolSpray')}>
                <img src="/images/lysol-icon-small-a.png" alt=""/>
              </Treasure> */}
              {/* <Treasure active={watch('selectedItem') == 'yeast'} key={'yeast'} onClick={() => setSelectedValue('yeast')}>
                <img src="/images/yeast-icon-small-c.png" alt=""/>
              </Treasure> */}
              {/* <Treasure active={watch('selectedItem') == 'batteries'} key={'batteries'} onClick={() => setSelectedValue('batteries')}>
                <img src="/images/treasure/batteries.png" alt=""/>
              </Treasure> */}
              {/* <Treasure active={watch('selectedItem') == 'tp'} key={'tp'} onClick={() => setSelectedValue('tp')}>
                <img src="/images/tp-icon-small.png" alt=""/>
              </Treasure> */}
            </SelectItemContainer>
            {/* <UseCurrentLocationContainer onClick={ (e) => {  grabCurrentLocation(); setValue('setLocationTreasure', 'Current Location'); setValue('predictionSelectedTreasure', 'Current Location'); setUseCurrent(!useCurrent); }}>
              <UseCurrentText>
                Use current location
              </UseCurrentText>
              <UseCurrentBtn useCurrent={useCurrent} >
                <img src="svgs/green-check.svg" alt=""/>
              </UseCurrentBtn>
            </UseCurrentLocationContainer> */}

            <ShareFormContainer>
              <ShareFormHeader>
                <ShareFormHeaderText>
                  Details in the form below will populate a marker on the map.
                </ShareFormHeaderText>

                <ShareFormSubHeaderText>
                  Please be mindful, misuse can result in a ban from all
                  services at our discretion.
                </ShareFormSubHeaderText>
              </ShareFormHeader>
            </ShareFormContainer>

            <SelectInputContainer>
              <TreasureInputContainer>
                {/* Add input for name of resource */}
                <Location
                  type="text"
                  // value={resourceName}
                  autoComplete="off"
                  // name="setResourceName"
                  placeholder="Enter Title: (name of resource)"
                  {...register("setResourceName", {
                    required: false,
                    minLength: {
                      value: 3,
                      message: "Must be over 3 characters", // <p>error message</p>
                    },
                  })}
                />
                {errors?.setResourceName && (
                  <ErrorField>Please Enter Name</ErrorField>
                )}
                {errors?.setResourceName &&
                  errors?.setResourceName.type === "minLength" && (
                    <ErrorField> {errors?.setResourceName.message}</ErrorField>
                  )}
              </TreasureInputContainer>

              <TreasureInputContainer>
                {/* Add input for name of resource */}
                <Location
                  type="text"
                  // value={resourcePhone}
                  autoComplete="off"
                  // name="setResourcePhone"

                  placeholder="Phone Number: ..."
                  {...register("setResourcePhone", {
                    required: false,
                    minLength: {
                      minLength: 3,
                      message: "Must be over 3 characters", // <p>error message</p>
                    },
                  })}
                />
                {errors?.setResourcePhone && (
                  <ErrorField>Please Enter Phone Number</ErrorField>
                )}
                {errors?.setResourcePhone &&
                  errors?.setResourcePhone.type === "minLength" && (
                    <ErrorField> {errors?.setResourcePhone.message}</ErrorField>
                  )}
              </TreasureInputContainer>

              <TreasureInputContainer>
                {/* Add input for name of resource */}
                <Location
                  type="text"
                  autoComplete="off"
                  name="setResourceUrl"
                  placeholder="Website: https://..."
                  {...register("setResourceUrl", {
                    required: false,
                    minLength: {
                      value: 3,
                      message: "Must be over 3 characters", // <p>error message</p>
                    },
                  })}
                />
                {errors?.setResourceUrl && (
                  <ErrorField>Please Enter Link Url</ErrorField>
                )}
                {errors?.setResourceUrl &&
                  errors?.setResourceUrl.type === "minLength" && (
                    <ErrorField> {errors?.setResourceUrl.message}</ErrorField>
                  )}
              </TreasureInputContainer>

              <TreasureInputContainer>
                {/* Add input for name of resource */}
                <Location
                  type="text"
                  autoComplete="off"
                  name="setResourceDetails"
                  placeholder="Useful Details: (hours, instructions...)"
                  {...register("setResourceDetails", {
                    required: false,
                    minLength: {
                      value: 3,
                      message: "Must be over  characters", // <p>error message</p>
                    },
                  })}
                />
                {errors?.setResourceDetails && (
                  <ErrorField>Please Enter Any additional Details</ErrorField>
                )}
                {errors?.setResourceDetails &&
                  errors?.setResourceDetails.type === "minLength" && (
                    <ErrorField>
                      {" "}
                      {errors?.setResourceDetails.message}
                    </ErrorField>
                  )}
              </TreasureInputContainer>

              <TreasureInputContainer>
                <Location
                  type="text"
                  autoComplete="off"
                  name="setLocationTreasure"
                  placeholder="Set Location"
                  {...register("setLocationTreasure", {
                    required: true,
                    minLength: {
                      value: 4,
                      message: "Must be over 4 characters", // <p>error message</p>
                    },
                  })}
                />
                {errors?.setLocationTreasure && (
                  <ErrorField>Please Enter Location</ErrorField>
                )}
                {errors?.setLocationTreasure &&
                  errors?.setLocationTreasure.type === "minLength" && (
                    <ErrorField>
                      {" "}
                      {errors?.setLocationTreasure.message}
                    </ErrorField>
                  )}
                {!errors?.setLocationTreasure &&
                  errors?.predictionSelectedTreasure && (
                    <ErrorField>Then Select address bellow</ErrorField>
                  )}
                <LocationAutoComplete>
                  {predictionsToggle &&
                    predictions &&
                    predictions.map((prediction) => (
                      <Prediction
                        key={prediction}
                        onClick={(e) => {
                          e.preventDefault();
                          // selectPrediction(prediction);
                          selectTreasurePrediction(prediction);
                          // setValue("predictionSelectedTreasure", prediction);
                        }}
                      >
                        {prediction}
                      </Prediction>
                    ))}
                </LocationAutoComplete>
                <TreasureClearInputBtn
                  onClick={() => {
                    // clearInput();
                    setValue("setLocationTreasure", "");
                    setValue("predictionSelectedTreasure", "");
                    setPredictionsToggle(false);
                    setUseCurrent(false);
                    clearErrors("setLocationTreasure");
                  }}
                >
                  <img src="/svgs/close-btn.svg" alt="" />
                </TreasureClearInputBtn>
              </TreasureInputContainer>
            </SelectInputContainer>
            <SearchSubmitContainer toggle>
              <SetTreasureButton
                onClick={async (e) => {
                  e.preventDefault();
                  const selectedItemCheck = await trigger(
                    "selectedItemTreasure"
                  );
                  const selectedPredictioncheck = await trigger(
                    "predictionSelectedTreasure"
                  );
                  const check = Object.keys(errors).length;
                  if (selectedItemCheck && selectedPredictioncheck) {
                    console.log(check);
                    submitTreasure();
                  } else {
                    console.log({ errors });
                  }
                }}
              >
                Submit Resource
              </SetTreasureButton>
            </SearchSubmitContainer>
          </TreasureForm>
        </TreasurePanel>
      </SearchContainer>
      <Draggable cancel=".btn">
        <ChatPanel toggle={chatPanelToggle} openSpeedModal={openSpeedModal}>
          {/* #create a chat panel that shows a chat between 2 people */}
          <ChatPanelCloseBtn
            className={"btn"}
            onClick={() => {
              setChatPanelToggle(!chatPanelToggle);
            }}
          >
            <img src="/svgs/close-btn.svg" alt="" />
          </ChatPanelCloseBtn>

          {/* <NotificationWrapper success={fiestaMessage?.length} alert={notification?.length}>
            <>{fiestaMessage?.length && (
              <FriendPanelContainer>{fiestaMessage}</FriendPanelContainer>
            )}
            {notification?.length && (
              <FriendPanelContainer>{notification}</FriendPanelContainer>
            )}</>
          </NotificationWrapper> */}

          <ChatPanelHeader>
            <ChatPanelHeaderImg src="/images/lysol-icon-small-a.png" alt="" />
            <ChatPanelHeaderText>Chat with {chatPanelUser}</ChatPanelHeaderText>
          </ChatPanelHeader>
          <ChatPanelBody className={"btn"}>
            <ChatPanelBodyText>{chatPanelText}</ChatPanelBodyText>
            {chatMessages &&
              chatMessages?.map((chatMessage) => {
                return (
                  <ChatMessageContainer
                    isUser={chatMessage.friendId === friendId}
                  >
                    <ChatMessageText>{chatMessage.message}</ChatMessageText>
                  </ChatMessageContainer>
                );
              })}
            <span ref={messageAnchor}></span>
          </ChatPanelBody>
          <ChatPanelFooter className={"btn"}>
            <ChatPanelFooterInput
              className={"btn"}
              type="text"
              value={chatPanelInput}
              autoComplete="off"
              name="chatPanelInput"
              onChange={(e) => {
                // e.preventDefault();
                setChatPanelInput(e.target.value);
              }}
              placeholder="Type Message"
            />
            <ChatPanelFooterBtn
              className={"btn"}
              onClick={(e) => {
                e.preventDefault();
                sendChatMessage({
                  chatId,
                  message: chatPanelInput,
                  friendId,
                  setChatId,
                  setMessages,
                  clearInput: () => setChatPanelInput(""),
                  notifier,
                  fiesta,
                  messageToken,
                  customerData,
                });
              }}
            >
              Send
            </ChatPanelFooterBtn>
          </ChatPanelFooter>
        </ChatPanel>
      </Draggable>

      {/* 
      <SearchMarkerForm onSubmit={e => e.preventDefault()}>
      <ExtraToggles>
        <SearchCurrentLocation>
          <button onClick={grabCurrentLocation}>
              X-Use Current Location-X
            </button>
        </SearchCurrentLocation>
        <CovidButton  covid={covid} >
          <button onClick={ e => toggleCovid(e)}>
            X Add Verified covid X
          </button>
          
        </CovidButton>
        <CovidButton  contracted={userContractedCovid} >
          <button onClick={ e => toggleStatus(e)}>
            X Toggle User Has Covid X
          </button>
          
        </CovidButton>
      </ExtraToggles>
        
        <label>
        <SearchHeaderText>Set Location</SearchHeaderText>
          <LocationInputContainer>
            <Location 
              type="text"
              value={location}
              autocomplete="off"
              name="setLocation"
              onChange={e => {
                e.preventDefault();
                inputChange(e)
              }}
              placeholder="Set Location"
            />
            {predictionsToggle && predictions && 
              predictions.map(prediction => 
                <Prediction key={prediction} onClick={e => {
                  e.preventDefault();
                  selectPrediction(prediction);
                }}>
                  {prediction}
                </Prediction>
                )
            }
          </LocationInputContainer>
        </label>
        <SearchSubmitContainer>
          <SubmitCheckIn onClick={e =>{
            e.preventDefault();
            addMarker();
          }}> Submit Location</SubmitCheckIn>
        </SearchSubmitContainer>
      </SearchMarkerForm> */}
    </>
  );
};
export default SetMarker;
