import React, { useContext } from "react";

import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
  getFirestore,
  getDoc,
  updateDoc,
  and,
} from "firebase/firestore";

import {
  kinDb,
  appFirebase,
  providers,
  kindAuth,
  cloudFunctions,
} from "../firebase-config";
import { getUserId } from "../firebase";
import useDirectionsMap from "../hooks/useMapDirections";
import { getGeoCode } from "../hooks/useGoogleMap";
import app from "firebase/compat/app";
import { httpsCallable } from "firebase/functions";
import { connectStorageEmulator } from "firebase/storage";

const db = getFirestore(appFirebase);
const COST_PER_MILE = 0.22;
const COST_PER_MINUTE = 0.66;
// export const getRideEstimate = async (rideEstimate: any, setRideEstimate: any, location: any, dropLocation: any) => {

export const GetRideEstimate = async (
  rideEstimate: any,
  setRideEstimate: any,
  location: any,
  dropLocation: any,
  position: any,
  rideDistanceEstimate: any,
  setRideDistanceEstimate: any,
  notifier: any,
  fiesta: any
) => {
  // console.log("getting ride estimate", { location, position, dropLocation });

  // if(!location || !dropLocation) return;
  // if(position === lastPosition && dropLocation === lastDropLocation) {
  //   notifier('Ride Estimate unchanged Updated');
  //   return;

  // }
  // setLastPosition(position);
  // setLastDropLocation(dropLocation);
  const clamp = (value: any, min: any, max: any) => {
    return value <= min ? min : value >= max ? max : value;
  };

  const distanceEstimate = useDirectionsMap({
    startLocation:
      location === "Current Location"
        ? { lat: position?.latitude, lng: position?.longitude }
        : location,
    destination: dropLocation,
    moreData: true,
  }).then((rideEstimateResponse: any) => {
    // console.log("ride estimate", { rideEstimateResponse });
    const rideDistance = rideEstimateResponse?.distance?.text;
    const distanceCostEstimate = (
      (rideDistance / 1609) * COST_PER_MILE || 3.0
    ).toFixed(2); //&& Number(res).toFixed(2);
    const timeCostEstimate = (
      (rideEstimateResponse?.duration?.value / 60) * COST_PER_MINUTE || 3.0
    ).toFixed(2); //&& Number(res).toFixed(2);
    // console.log(
    //   "distanceCostEstimate",
    //   { distanceCostEstimate },
    //   { timeCostEstimate }
    // );
    const newRideEstimate =
      Number(distanceCostEstimate) + Number(timeCostEstimate);
    if (newRideEstimate !== rideEstimate) {
      // console.log(
      //   "setting ride estimate",
      //   clamp(newRideEstimate, 3.0, 99999.99).toFixed(2)
      // );
      setRideEstimate(clamp(newRideEstimate, 3.0, 99999.99).toFixed(2));
    }
    if (rideEstimateResponse !== rideDistanceEstimate) {
      // console.log("setting ride Estimate", { rideEstimateResponse });
      setRideDistanceEstimate(rideEstimateResponse);
    }
    return newRideEstimate;
  });
  notifier("Ride Estimate Updated");
  return distanceEstimate;
};

export const confirmDropOff = async (
  passengerId: any,
  stripeCustomerId: any,
  setUserData: any,
  setTriggerShow: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // console.log("$$$  - confirming pickup", { passengerId });
  const cUserRef = collection(db, "users");
  const cCustomerRef = doc(db, "customers", uid);
  const cCustomerSnap = await getDoc(cCustomerRef);
  const cCustomerData = cCustomerSnap.data();
  const cCustomerStripeCustomerId = cCustomerData?.stripeCustomerId;
  const cCustomerStripeAccountId = cCustomerData?.stripeAccountId;
  const cCustomerStripeAccount = cCustomerData?.stripeAccount;
  const cCustomerPaymentIntentId = cCustomerData?.paymentIntentId;

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);

  for (const userSnap of userSnaps.docs) {
    const userSnapData = userSnap.data();
    // console.log("#$#$#$#-", { userSnapData }, { passengerId });
    const passengerSnap = await getDoc(doc(db, "users", passengerId));
    const passengerData = passengerSnap.data();
    const currentRide = { ...userSnapData?.currentRide };
    const activePassenger = { ...passengerData?.activePassenger };

    const cCustomerPassengerRef = doc(db, "customers", passengerId);
    const cCustomerPassengerSnap = await getDoc(cCustomerPassengerRef);
    const cPassCustomerData = cCustomerPassengerSnap.data();
    const paymentMethodId = cPassCustomerData?.paymentMethodId;

    // console.log("passengerData", { passengerData });
    currentRide["finishTime"] = new Date();
    currentRide["started"] = true;
    currentRide["finalDriverLocation"] = userSnapData?.location;
    activePassenger["started"] = true;
    activePassenger["finishTime"] = new Date();
    activePassenger["finalDriverLocation"] = userSnapData?.location;

    // Rest of your code...
    // calculate ride cost
    const rideCost = Number(currentRide?.rideEstimate) || 0;
    // const driverId = activePassenger?.driverId;
    const driverSnap = await getDoc(doc(db, "users", uid));
    const driverData = userSnapData;
    const passengerUserId = passengerData?.userId;
    const passengerPaymentIntentId = cPassCustomerData?.paymentIntentId;
    const driverUserId = driverData?.id;
    const driverCustomerId = driverData?.customerId;
    const driverStripeAccountId = cCustomerData?.stripeAccountId;
    const driverStripeAccount = cCustomerData?.stripeAccount;
    const startTime = new Date(currentRide?.startTime?.toDate());
    const finishTime = new Date(currentRide?.finishTime);
    // console.log(startTime?.getTime(), finishTime?.getTime());
    // compare starTime and finishTime to get ride duration

    const rideDuration = (finishTime?.getTime() - startTime?.getTime()) / 1000;

    // calculate distance cost
    const rideDistance = Number(currentRide?.driveDistance?.split(" ")[0]) || 0;

    const distanceCostEstimate =
      ((rideDistance / 1609) * COST_PER_MILE).toFixed(2) || 3.0; //&& Number(res).toFixed(2);
    const timeCostEstimate = (
      (rideDuration / 60) * COST_PER_MINUTE || 3.0
    ).toFixed(2); //&& Number(res).toFixed(2);
    const newRideEstimate =
      Number(distanceCostEstimate) + Number(timeCostEstimate);

    // console.log(
    //   "finalizing payment",
    //   distanceCostEstimate,
    //   timeCostEstimate,
    //   newRideEstimate
    // );

    // Create a reference to the callable function
    const finalizePayment = httpsCallable(cloudFunctions, "finalizePayment");
    // console.log(
    //   "finalizing payment",
    //   distanceCostEstimate,
    //   timeCostEstimate,
    //   newRideEstimate,
    //   paymentMethodId,
    //   passengerPaymentIntentId,
    //   driverUserId,
    //   driverStripeAccountId
    // );
    if (!passengerPaymentIntentId || !stripeCustomerId || !paymentMethodId) {
      setTriggerShow(true);
      notifier(
        "Required client info not found, please check your account settings"
      );
      return;
    }
    if (!driverUserId || !driverStripeAccountId) {
      setTriggerShow(true);
      notifier(
        "Required payment info not found, please check your account settings"
      );
      return;
    }

    // Call the function
    finalizePayment({
      amount: newRideEstimate,
      customerId: stripeCustomerId,
      passengerUserId: passengerUserId,
      paymentMethodId: paymentMethodId,
      paymentIntentId: passengerPaymentIntentId,
      driverUserId: driverUserId,
      stripeAccountId: driverStripeAccountId,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        // console.log(result.data);
      })
      .catch((error) => {
        // Getting the Error details.
        console.error("Error calling finalizePayment:", error);
      });

    await updateDoc(userSnap.ref, {
      currentRide: null,
      activePassenger: null,
      activeRide: false,
      currentRideRequest: null,
      completedDrives: app.firestore.FieldValue.arrayUnion(currentRide),
    });

    if (passengerSnap.exists()) {
      await updateDoc(passengerSnap.ref, {
        completedRides: app.firestore.FieldValue.arrayUnion(activePassenger),
        activePassenger: null,
        activeRide: false,
        currentRideRequest: null,
        currentRide: null,
      });
    }
    // setUserData(userSnapData);
    setTriggerShow(true);
    fiesta("Ride Completed! :)");
  }
};

// export const confirmDropOff = async (passengerId: any, stripeCustomerId: any, setUserData: any, setTriggerShow: any, notifier?: any, fiesta?: any ) => {
//   const uid = getUserId();
//   if(!uid) return;
//   // const userRef = doc(db, "users", `${userId}`);
//   console.log('$$$  - confirming pickup', {passengerId})
//   const cUserRef = collection(db, 'users');

//   const q = query(cUserRef, where("userId", "==", uid));

//   const userSnaps = await getDocs(q);

//   await userSnaps?.forEach(async (userSnap) => {
//     const userSnapData = userSnap.data();
//     // console.log({userSnapData});
//     // userSnapData.activeRideRequest = false;
//     console.log('#$#$#$#-', {userSnapData}, {passengerId});
//     // #query passengerId and update activePassenger to null
//     const passengerSnap = await getDoc(doc(db, "users", passengerId));
//     const passengerData = passengerSnap.data();
//     const currentRide = userSnapData?.currentRide;
//     const activePassenger = passengerData?.activePassenger;
//     console.log('passengerData', {passengerData});
//     currentRide['finishTime'] = new Date();
//     currentRide['started'] = true;
//     currentRide['finalDriverLocation'] = userSnapData?.location;
//     activePassenger['started'] = true;
//     activePassenger['finishTime'] = new Date();
//     activePassenger['finalDriverLocation'] = userSnapData?.location;

//     // calculate ride cost
//     const rideCost = Number(currentRide?.rideEstimate) || 0;
//     const driverId = activePassenger?.driverId;
//     const driverSnap = await getDoc(doc(db, "users", driverId));
//     const driverData = driverSnap.data();

//     const driverPaymentIntentId = driverData?.paymentIntentId;
//     const driverUserId = driverData?.userId;
//     const driverCustomerId = driverData?.customerId;
//     const driverStripeAccountId = driverData?.stripeAccountId;
//     const driverStripeAccount = driverData?.stripeAccount;
//     const startTime = new Date(currentRide?.startTime?.toDate());
//     const finishTime = new Date(currentRide?.finishTime);
//     console.log(startTime?.getTime(), finishTime?.getTime());
//     // compare starTime and finishTime to get ride duration

//     const rideDuration = (finishTime?.getTime() - startTime?.getTime()) / 1000;

//     // calculate distance cost
//     const rideDistance = Number(currentRide?.driveDistance?.split(' ')[0]) || 0;

//     const distanceCostEstimate = ((rideDistance / 1609 ) * 0.44).toFixed(2)  || 3.00; //&& Number(res).toFixed(2);
//     const timeCostEstimate = ((rideDuration / 60 ) * 0.77 || 3.00).toFixed(2); //&& Number(res).toFixed(2);
//     const newRideEstimate = Number(distanceCostEstimate) + Number(timeCostEstimate);

//     console.log('finalizing payment', distanceCostEstimate, timeCostEstimate, newRideEstimate)

//     // Create a reference to the callable function
//     const finalizePayment = httpsCallable(cloudFunctions, 'finalizePayment');

//     // Call the function
//     finalizePayment({ amount: newRideEstimate, customerId: stripeCustomerId, paymentIntentId: driverPaymentIntentId, userId: driverUserId, stripeAccountId: driverStripeAccountId, stripeAccount: driverStripeAccount })
//       .then((result) => {
//         // Read result of the Cloud Function.
//         console.log(result.data);
//       })
//       .catch((error) => {
//         // Getting the Error details.
//         console.error('Error calling finalizePayment:', error);
//       });

//     await updateDoc(userSnap.ref, {
//       currentRide: null,
//       activePassenger: null,
//       activeRide: false,
//       currentRideRequest: null,
//       completedDrives: app.firestore.FieldValue.arrayUnion(currentRide),

//     });

//     if(passengerSnap.exists()) {
//       await updateDoc(passengerSnap.ref, {
//         completedRides: app.firestore.FieldValue.arrayUnion(activePassenger),
//         activePassenger: null,
//         activeRide: false,
//         currentRideRequest: null,
//         currentRide: null,

//       });
//     }
//     // setUserData(userSnapData);
//     setTriggerShow(true);
//     fiesta('Ride Completed! :)');
//   })
// }

export const confirmPickup = async (
  passengerId: any,
  setUserData: any,
  setTriggerShow: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("$$$  - confirming pickup", { passengerId });
  const cUserRef = collection(db, "users");

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);

  await userSnaps?.forEach(async (userSnap) => {
    const userSnapData = userSnap.data();
    // console.log({userSnapData});
    // userSnapData.activeRideRequest = false;
    // console.log("#$#$#$#-", { userSnapData }, { passengerId });
    // #query passengerId and update activePassenger to null
    const passengerSnap = await getDoc(doc(db, "users", passengerId));
    const passengerData = passengerSnap.data();
    const currentRide = userSnapData?.currentRide;
    const activePassenger = passengerData?.activePassenger;
    // console.log("passengerData", { passengerData });
    currentRide["startTime"] = new Date();
    currentRide["started"] = true;
    currentRide["initialDriverLocation"] = userSnapData?.location;
    activePassenger["started"] = true;
    activePassenger["startTime"] = new Date();
    activePassenger["initialDriverLocation"] = userSnapData?.location;

    await updateDoc(userSnap.ref, {
      currentRide,
    });

    if (passengerSnap.exists()) {
      await updateDoc(passengerSnap.ref, {
        activePassenger,
      });
    }
    // setUserData(userSnapData);
    setTriggerShow(true);
    fiesta("Ride Request Cancelled");
  });
};

export const endRide = async (
  driverId: any,
  passengerId: any,
  setTriggerShow: any,
  setLoading: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("$$$  - ending ride", { passengerId, driverId });
  const cUserRef = collection(db, "users");
  const cCustomersRef = collection(db, "customers");
  const driverDoc = await getDoc(doc(db, "users", driverId));
  // console.log("driverid", { driverId });
  const q = query(cUserRef, where("userId", "==", driverId));
  const userSnaps = driverDoc;

  const userSnapsData = userSnaps?.data();
  // console.log("we got usersnaps", { userSnapsData });
  const driverFreeRide = userSnapsData?.freeRide;

  const driverCustomerId = userSnapsData?.userId || driverId;
  // console.log("driverCustomerId", { driverCustomerId, userSnapsData });
  const passengerSnap = await getDoc(doc(db, "users", passengerId));
  const passengerData = passengerSnap.data();
  // console.log("passengerData", { passengerData });

  const passengerCustomerSnap = await getDoc(
    doc(db, "customers", passengerData?.userId)
  );

  const passengerCustomerSnapData = passengerCustomerSnap.data();
  const passFreeRide = passengerData?.freeRide;

  const activePassenger = { ...passengerData?.activePassenger };

  let cCustomerData: any = null;
  let currentRide: any = null;
  const cCustomerRef = doc(cCustomersRef, driverCustomerId);
  const cCustomerSnap = await getDoc(cCustomerRef);

  if (cCustomerSnap.exists()) {
    cCustomerData = cCustomerSnap.data();
    currentRide = userSnapsData?.currentRide;
    // ...
  } else {
    console.log(`No document found with ID:`);
  }

  const passengerPaymentIntentId = passengerCustomerSnapData?.paymentIntentId;
  const passengerPaymentMethodId = passengerCustomerSnapData?.paymentMethodId;
  const driverUserId = driverCustomerId;
  const stripeCustomerId = passengerCustomerSnapData?.stripeCustomerId;

  const driverStripeAccountId = cCustomerData?.stripeAccountId;
  const driverStripeAccount = cCustomerData?.stripeAccount;
  const startTime = new Date(currentRide?.startTime?.toDate());
  const finishTime = new Date();
  // console.log(startTime?.getTime(), finishTime?.getTime());

  // console.log("passengerData", {
  //   passengerPaymentMethodId,
  //   cCustomerData,
  //   passengerData,
  //   passengerCustomerSnapData,
  //   passengerPaymentIntentId,
  //   driverUserId,
  //   stripeCustomerId,
  //   driverStripeAccountId,
  //   driverStripeAccount,
  //   startTime,
  //   finishTime,
  // });

  const rideDuration = (finishTime?.getTime() - startTime?.getTime()) / 1000;

  // calculate distance cost
  const rideDistance = Number(currentRide?.driveDistance?.split(" ")[0]) || 0;

  currentRide["finishTime"] = finishTime;
  activePassenger["finishTime"] = finishTime;
  currentRide["duration"] = rideDuration;
  activePassenger["duration"] = rideDuration;


  if(currentRide.rideType === 'free') {

    await updateDoc(userSnaps.ref, {
      currentRide: null,
      activePassenger: null,
      activeRide: false,
      // freeRides: driverFreeRide + 2,
      currentRideRequest: null,
      completedDrives: app.firestore.FieldValue.arrayUnion(currentRide),
    });
  
    if (passengerSnap.exists()) {
      await updateDoc(passengerSnap.ref, {
        completedRides: app.firestore.FieldValue.arrayUnion(activePassenger),
        activePassenger: null,
        freeRides: passFreeRide - 1,
        activeRide: false,
        currentRideRequest: null,
        currentRide: null,
      });
    }
    // setUserData(userSnapData);
    setLoading(false);
    setTriggerShow(true);
    fiesta("Ride Completed! :)");

    return;
  }

  const distanceCostEstimate =
    ((rideDistance / 1609) * COST_PER_MILE).toFixed(2) || 3.0; //&& Number(res).toFixed(2);
  const timeCostEstimate = (
    (rideDuration / 60) * COST_PER_MINUTE || 3.0
  ).toFixed(2); //&& Number(res).toFixed(2);
  const newRideEstimate =
    Number(distanceCostEstimate) + Number(timeCostEstimate);

    currentRide["distanceCostEstimate"] = distanceCostEstimate;
    currentRide["timeCostEstimate"] = timeCostEstimate;
    currentRide["newRideEstimate"] = newRideEstimate;
    activePassenger["distanceCostEstimate"] = distanceCostEstimate;
    activePassenger["timeCostEstimate"] = timeCostEstimate;
    activePassenger["newRideEstimate"] = newRideEstimate;

  // console.log(
  //   "finalizing payment",
  //   distanceCostEstimate,
  //   timeCostEstimate,
  //   passengerPaymentMethodId,
  //   newRideEstimate,
  //   passengerPaymentIntentId
  // );

  // Create a reference to the callable function
  const finalizePayment = httpsCallable(cloudFunctions, "finalizePayment");
  // console.log(
  //   "finalizing payment",
  //   distanceCostEstimate,
  //   timeCostEstimate,
  //   newRideEstimate,
  //   passengerPaymentIntentId,
  //   stripeCustomerId,
  //   driverUserId,
  //   driverStripeAccountId,
  //   driverStripeAccount
  // );
  if (!passengerPaymentIntentId) {
    setTriggerShow(true);
    notifier(
      "Required client info not found, please check your account settings"
    );
    return;
  }
  if (!driverUserId || !driverStripeAccountId) {
    setTriggerShow(true);
    notifier(
      "Required payment info not found, please check your account settings"
    );
    return;
  }
  // Call the function
  finalizePayment({
    amount: newRideEstimate,
    customerId: stripeCustomerId,
    passengerUserId: passengerId,
    paymentMethodId: passengerPaymentMethodId,
    paymentIntentId: passengerPaymentIntentId,
    driverUserId: driverUserId,
    stripeAccountId: driverStripeAccountId,
  })
    .then((result) => {
      // Read result of the Cloud Function.
      // console.log(result.data);
    })
    .catch((error) => {
      // Getting the Error details.
      console.error("Error calling finalizePayment:", error);
    });

  await updateDoc(userSnaps.ref, {
    currentRide: null,
    activePassenger: null,
    activeRide: false,
    currentRideRequest: null,
    completedDrives: app.firestore.FieldValue.arrayUnion(currentRide),
  });

  if (passengerSnap.exists()) {
    await updateDoc(passengerSnap.ref, {
      completedRides: app.firestore.FieldValue.arrayUnion(activePassenger),
      activePassenger: null,
      activeRide: false,
      currentRideRequest: null,
      currentRide: null,
    });
  }
  // setUserData(userSnapData);
  setTriggerShow(true);
  fiesta("Ride Completed! :)");
};

// userSnaps?.forEach(async (userSnap) => {
//   const userSnapData = userSnap.data();
//   // console.log({userSnapData});
//   // userSnapData.activeRideRequest = false;
//   console.log("#$#$#$#-", { userSnapData }, { passengerId });
//   // #query passengerId and update activePassenger to null
//   const passengerSnap = await getDoc(doc(db, "users", passengerId));
//   const passengerData = passengerSnap.data();
//   const currentRide = userSnapData?.currentRide;
//   const activePassenger = passengerData?.activePassenger;

//   // Call stripe function to finalize payment

//   const driverStripeAccountId = cCustomerData?.stripeAccountId;
//   const driverStripeAccount = cCustomerData?.stripeAccount;
//   const startTime = new Date(currentRide?.startTime?.toDate());
//   const finishTime = new Date(currentRide?.finishTime);
//   console.log(startTime?.getTime(), finishTime?.getTime());

//   console.log("passengerData", { passengerData });
//   currentRide["finishTime"] = new Date();
//   currentRide["started"] = false;
//   currentRide["finalDriverLocation"] = userSnapData?.location;
//   activePassenger["finishTime"] = new Date();
//   activePassenger["started"] = false;
//   activePassenger["finalDriverLocation"] = userSnapData?.location;

//   await updateDoc(userSnap.ref, {
//     currentRide,
//   });
//   if (passengerSnap.exists()) {
//     await updateDoc(passengerSnap.ref, {
//       activePassenger,
//     });
//   }
//   setTriggerShow(true);
//   fiesta("Ride Ended");
//   setLoading(false);

// const driverPaymentIntentId = userSnapData?.driverPaymentIntentId;
// const driverStripeAccountId = userSnapData?.driverStripeAccountId;
// const driverStripeAccount = userSnapData?.driverStripeAccount;
// const driverUserId = userSnapData?.driverUserId;
// const driverUserRef = doc(db, "users", driverUserId);
// const driverSnap = await getDoc(driverUserRef);
// const driverSnapData = driverSnap.data();

export const cancelActiveRide = async (
  passengerId: any,
  setUserData: any,
  setTriggerShow: any,
  setLoading: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("$$$  - cancelling active ride", { passengerId });
  const cUserRef = collection(db, "users");

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);

  userSnaps?.forEach(async (userSnap) => {
    const userSnapData = userSnap.data();

    // # loop through friends and remove ride request
    const friends = userSnapData?.friends;
    // console.log(
    //   "prepping to remove ride request from friends",
    //   { friends },
    //   uid
    // );

    if (friends) {
      friends?.map(async (friend: any) => {
        const friendSnap = await getDoc(doc(db, "users", friend?.id));
        // console.log(
        //   "prepping to remove ride request from friend",
        //   { friends },
        //   { friend },
        //   { friendSnap }
        // );
        if (friendSnap?.exists()) {
          const friendData = friendSnap?.data();

          if (friendData?.friendRideRequests) {
            const curRideRequests = friendData?.friendRideRequests || [];
            // #check if uid is in friendRideRequests and filter out
            const filteredRideRequests = curRideRequests?.filter(
              (f: any) => f.id !== uid
            );
            // console.log("filteredRideRequests", { filteredRideRequests });

            if (
              friendSnap?.ref &&
              filteredRideRequests?.length !==
                friendData?.friendRideRequests?.length
            ) {
              await updateDoc(friendSnap?.ref, {
                friendRideRequests: filteredRideRequests,
              });
            }
            // const friendIndex = friendFriends.findIndex((f: any) => f.id === uid);
            // if(friendIndex > -1) {
            //   friendFriends[friendIndex].currentRideRequest = null;
            //   await updateDoc(friendSnap.ref, {
            //     currentRideRequest: friendFriends,
            //   });
            // }
          }
        }
      });
    }

    // console.log({userSnapData});
    userSnapData.activeRideRequest = false;
    // console.log("#$#$#$#-", { userSnapData }, { passengerId });

    // if(   ) {
    //  setClearTogglesActive(true);

    if (userSnap.exists() && userSnapData?.currentRide?.status === "accepted") {
      await updateDoc(userSnap.ref, {
        activeRideRequest: false,
        currentRide: null,
        activePassenger: null,
        activeRide: false,
      });

      // call destroyPaymentIntent
      const destroyPaymentIntent = httpsCallable<unknown, any>(
        cloudFunctions,
        "destroyPaymentIntent"
      );
      await destroyPaymentIntent({
        paymentIntentId: userSnapData?.paymentIntentId,
        userId: uid,
      });

      // #query passengerId and update activePassenger to null
      const passengerSnap = await getDoc(doc(db, "users", passengerId));
      const passengerData = passengerSnap.data();
      // console.log("passengerData", { passengerData });
      if (passengerSnap.exists()) {
        await updateDoc(passengerSnap.ref, {
          activePassenger: null,
          activeRide: false,
        });
      }
      // setUserData(userSnapData);
      setTriggerShow(true);
      setLoading(false);
      fiesta("Ride Request Cancelled");
      return;
    } else if (
      userSnap.exists() &&
      userSnapData?.activePassenger?.status === "accepted"
    ) {
      await updateDoc(userSnap.ref, {
        activeRide: false,
        activeRideRequest: false,
        activePassenger: null,
        currentRide: null,
      });

      // # loop through friends and remove ride request
      const friends = userSnapData?.friends;
      // console.log(
      //   "prepping to remove ride request from friends",
      //   { friends },
      //   uid
      // );

      if (friends) {
        friends?.map(async (friend: any) => {
          const friendSnap = await getDoc(doc(db, "users", friend?.id));
          // console.log(
          //   "prepping to remove ride request from friend",
          //   { friends },
          //   { friend },
          //   { friendSnap }
          // );
          if (friendSnap?.exists()) {
            const friendData = friendSnap?.data();

            if (friendData?.friendRideRequests) {
              const curRideRequests = friendData?.friendRideRequests || [];
              // #check if uid is in friendRideRequests and filter out
              const filteredRideRequests = curRideRequests?.filter(
                (f: any) => f.id !== uid
              );
              // console.log("filteredRideRequests", { filteredRideRequests });

              if (
                friendSnap?.ref &&
                filteredRideRequests?.length !==
                  friendData?.friendRideRequests?.length
              ) {
                await updateDoc(friendSnap?.ref, {
                  friendRideRequests: filteredRideRequests,
                });
              }
              // const friendIndex = friendFriends.findIndex((f: any) => f.id === uid);
              // if(friendIndex > -1) {
              //   friendFriends[friendIndex].currentRideRequest = null;
              //   await updateDoc(friendSnap.ref, {
              //     currentRideRequest: friendFriends,
              //   });
              // }
            }
          }
        });
      }

      // #query passengerId and update activePassenger to null
      const passengerSnap = await getDoc(doc(db, "users", passengerId));
      const passengerData = passengerSnap.data();
      // console.log("passengerData", { passengerData });
      if (passengerSnap.exists()) {
        await updateDoc(passengerSnap.ref, {
          currentRide: null,
          activeRide: false,
          activeRideRequest: false,
          currentRideRequest: null,
          activePassenger: null,
        });
      }
      setTriggerShow(true);
      setLoading(false);
      fiesta("Ride Request Cancelled");
    }
    // }
  });
};

export const updateRideDistances = async (
  customerData: any,
  updatedDriverDistance: any,
  triggerUpdateDriverDistance: any,
  setStartLocation: any,
  setDestination: any,
  setPosition: any,
  setTriggerShow: any
) => {
  // console.log("attempting to update dirver distance ", { customerData });
  // #get the current user's location and update the currentRide collection
  const uid = customerData?.id;
  // console.log("getting user id for update ride distance", uid);
  if (!uid) return;
  const cUserRef = collection(db, "users");
  console.log("updating ride distances");
  const q = query(cUserRef, where("userId", "==", uid));
  const userSnaps = await getDocs(q);

  if (!userSnaps) return;
  // console.log("updating ride distances", { userSnaps });

  let driverDistanceVal: number;
  userSnaps?.forEach(async (userSnap) => {
    const userSnapData = userSnap.data();
    const currentRide = userSnapData?.currentRide;
    const activePassenger = userSnapData?.activePassenger;

    if (activePassenger?.status === "accepted") {
      console.log("updating active passenger distance");
      await useDirectionsMap({
        startLocation: activePassenger?.location,
        destination: activePassenger?.dropLocation,
      }).then((res: any) => {
        // console.log({ res });
        const driveDistance = res?.text; //&& Number(res).toFixed(2);
        driverDistanceVal = res?.value;
        console.log("update driver distance", { driveDistance });
        activePassenger["driveDistance"] = driveDistance;
        return driveDistance;
      });

      const driverId = activePassenger?.driverId;
      const driverSnap = await getDoc(doc(db, "users", driverId));
      const driverData = driverSnap.data();
      const driverLocation = driverData?.location;
      // console.log({ driverData }, { driverLocation });

      if (!activePassenger?.started || activePassenger?.started === false) {
        // console.log(
        //   "riide not started yet",
        //   activePassenger?.location,
        //   driverLocation
        // );
        await useDirectionsMap({
          startLocation: driverLocation,
          destination: activePassenger?.location,
        }).then((res: any) => {
          // console.log({ res });
          const driverDistance = res?.text; //&& Number(res).toFixed(2);
          driverDistanceVal = res?.value;
          // console.log("a update driver distance", { driverDistance });
          activePassenger["driverDistance"] = driverDistance;
          return driverDistance;
        });
      } else {

        console.log('rides started check')
        const driverDistance = await useDirectionsMap({
          startLocation: driverLocation,
          destination: activePassenger?.dropLocation,
        }).then((res: any) => {
          // console.log({ res });
          const driverDistance = res?.text; //&& Number(res).toFixed(2);
          driverDistanceVal = res?.value;
          activePassenger["driverDistance"] = driverDistance;
          // console.log("b update driver distance", { driverDistance });
          return driverDistance;
        });

        // console.log("c update driver distance", { driverDistance });
      }

      activePassenger["driverLocation"] = driverLocation;
      await updateDoc(userSnap.ref, {
        activePassenger,
      });

      await setStartLocation(activePassenger?.location);
      await setDestination(activePassenger?.dropLocation);
    }

    if (currentRide?.status === "accepted" && userSnapData?.location) {
      // if(!currentRide || !userSnapData?.location) return;
      // console.log({ currentRide }, { userSnapData });
      if (currentRide?.status === "accepted") {
        const distanceFromPickup = await useDirectionsMap({
          startLocation: {
            lng: userSnapData?.location?.lng,
            lat: userSnapData?.location?.lat,
          },
          destination: currentRide?.location,
        }).then(async (res: any) => {
          // console.log({ res });
          const driverDistance = res?.text; //&& Number(res).toFixed(2);
          const driverDistanceToNumber = res.value;
          // console.log('ride pos check', driverDistanceToNumber);
          driverDistanceVal = driverDistanceToNumber;
          console.log('distance from pickup', driverDistanceVal);
          currentRide["distanceFromPickup"] = driverDistance;
          currentRide["atPickupLocation"] =
            driverDistanceToNumber <= 1.1 ? true : false;

          // await updateDoc(userSnap.ref, {
          //   currentRide,
          // });
          return driverDistance;
        });

        // console.log("after distance from pickup update", { currentRide });
        if (!currentRide?.started || currentRide?.started === false) {
          console.log("riide not started yet");
          // console.log("update driver distance", { currentRide });
          await useDirectionsMap({
            startLocation: currentRide?.location,
            destination: currentRide?.dropLocation,
          }).then((res: any) => {
            // console.log({ res });
            const driveDistance = res?.text; // && Number(res).toFixed(2);
            driverDistanceVal = res?.value;
            // console.log("update driver distance", { driveDistance });
            currentRide["driveDistance"] = driveDistance;
            return driveDistance;
          });

          // # parse the first numbers of the string leaving out the mi not returning NaN
          // const driverDistanceToNumber = Number(distanceFromPickup?.split(' ')[0]);

          // const parsedDriverDistance = driverDistanceToNumber;
          // console.log('parsedDriverDistance', {parsedDriverDistance})
          // if(distanceFromPickup < 6) {

          // }
          // console.log("check distance from pickup", { distanceFromPickup });
          if (
            distanceFromPickup !== null ||
            distanceFromPickup !== undefined ||
            distanceFromPickup !== "NaN"
          ) {
            await updateDoc(userSnap.ref, {
              currentRide,
            });

            await setStartLocation({
              lng: userSnapData?.location?.lng,
              lat: userSnapData?.location?.lat,
            });
            await setDestination(currentRide?.location);
          }
        } else {
          // console.log("update d *ride started", { userSnapData });
          await useDirectionsMap({
            startLocation: {
              lng: userSnapData?.location?.lng,
              lat: userSnapData?.location?.lat,
            },
            destination: currentRide?.dropLocation,
          }).then(async (res: any) => {
            // console.log({ res });
            const driverDistance = res?.text; //&& Number(res).toFixed(2);
            const driverDistanceToNumber = res.value;

            console.log("is it the end?", { driverDistanceToNumber });
            console.log("**distance to number", driverDistanceToNumber);
            currentRide["distanceFromPickup"] = 0;
            currentRide["driveDistance"] = driverDistance;
            currentRide["driverLocation"] = userSnapData?.location;
            currentRide["atPickupLocation"] = false;
            currentRide["atDropOffLocation"] =
              driverDistanceToNumber / 1609 <= 1.1 ? true : false;

            await updateDoc(userSnap.ref, {
              currentRide,
            });
            return driverDistance;
          });
        }
      }
    }
  });
};

export const acceptRideRequest = async (
  friendId: string,
  setLoading: any,
  setTriggerShow: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("$$$  - accepting ride request", friendId);
  const cUserRef = collection(db, "users");

  const q = query(cUserRef, where("userId", "==", friendId));

  const friendSnapActives = await getDocs(q);
  let driverDistanceVal: number;

  friendSnapActives?.forEach(async (friendSnapActive) => {
    const friendSnapActiveData = friendSnapActive.data();
    // console.log({ friendSnapActiveData });
    friendSnapActiveData.activeRideRequest = false;
    // console.log("#$#$#$#-", { friendSnapActiveData }, friendId);
    // #query the user with friendId and update currentRideRequest to accepted

    if (friendSnapActive?.exists()) {
      const driveDistance = await useDirectionsMap({
        startLocation: friendSnapActiveData?.currentRideRequest?.location,
        destination: friendSnapActiveData?.currentRideRequest?.dropLocation,
      }).then((res: any) => {
        // console.log({ res });
        const driverDistance = res?.text; //&& Number(res).toFixed(2);
        // driverDistanceVal = res?.value;
        // console.log('suup',{friend, driverDistance});
        //  console.log({driverRebuild})
        // return  driverRebuild;
        return driverDistance;
        // return driverRebuild;
      });

      // #get the current user and update activeRide to currentRideRequest
      const cUserRef = collection(db, "users");
      const q = query(cUserRef, where("userId", "==", uid));
      const userSnaps = await getDocs(q);
      userSnaps?.forEach(async (userSnap) => {
        const userData = userSnap.data();
        const friendData = friendSnapActive.data();
        const currentRideRequest = friendData?.currentRideRequest;

        if (!currentRideRequest) {
          setLoading(false);
          setTriggerShow(true);
          notifier("Ride Request no longer available");
          return;
        }

        const distance = await useDirectionsMap({
          startLocation: {
            lng: userData?.location?.lng,
            lat: userData?.location?.lat,
          },
          destination: currentRideRequest?.location,
        }).then((res: any) => {
          // console.log({ res });
          const driverDistance = res?.text; //&& Number(res).toFixed(2);
          driverDistanceVal = res?.value;
          // console.log('suup',{friend, driverDistance});
          //  console.log({driverRebuild})
          // console.log(
          //   "checking Distance from pickup",
          //   res?.value,
          //   res.value <= 19.1 ? true : false
          // );
          // return  driverRebuild;
          currentRideRequest["atPickupLocation"] =
            res.value <= 1.1 ? true : false;
          return driverDistance;
          // return driverRebuild;
        });

        // console.log("checking pickup distance", { currentRideRequest });
        // console.log(distance, driveDistance, distance <= 19.1 ? true : false)
        currentRideRequest["status"] = "accepted";
        if (friendData?.photoURL) {
          currentRideRequest["passengerPhoto"] = friendData?.photoURL;
        }
        if (userData?.photoURL) {
          currentRideRequest["driverPhoto"] = userData?.photoURL;
        }
        currentRideRequest["driverId"] = userSnap?.id;
        currentRideRequest["passengerId"] = friendSnapActive?.id;
        currentRideRequest["acceptedTime"] = new Date();
        currentRideRequest["driverName"] = userData?.userName;
        currentRideRequest["passengerName"] = friendData?.userName;
        currentRideRequest["driveDistance"] = driveDistance;
        currentRideRequest["distanceFromPickup"] = distance;
        currentRideRequest["driverLocation"] = userData?.location;
        currentRideRequest["initialDriverLocation"] = userData?.location;
        currentRideRequest["atPickupLocation"] =
          driverDistanceVal <= 666 ? true : false;

        // currentRideRequest['driverAvatar'] = userSnap?.data()?.avatar;
        // console.log("updating status", currentRideRequest);
        await updateDoc(friendSnapActive.ref, {
          currentRideRequest: null,
          activePassenger: currentRideRequest,
          activeRideRequest: false,
        });

        const userSnapData = userSnap.data();
        // console.log({ userSnapData });
        userSnapData.activeRideRequest = true;
        // console.log("#$#$#$#-", { userSnapData }, { friendId });
        // #query the user with friendId and update currentRideRequest to accepted

        if (userSnap?.exists()) {
          // console.log("updating status", currentRideRequest);
          await updateDoc(userSnap.ref, {
            activeRide: true,
            currentRide: currentRideRequest,
          });
          // #filter out friendid from friendRideRequests
          const friendRideRequests = userSnapData?.friendRideRequests;
          const filteredFriendRideRequests = friendRideRequests.filter(
            (f: any) => f.id !== friendId
          );
          await updateDoc(userSnap.ref, {
            friendRideRequests: filteredFriendRideRequests,
          });
          setLoading(false);
          setTriggerShow(true);
          fiesta("Ride Request Accepted");
        }
      });
    }

    // if( userData?.activeRideRequest !== userSnapData?.activeRideRequest ) {
    //    setClearTogglesActive(true);

    //   if (userSnap.exists()) {
    //     await updateDoc(userSnap.ref, {
    //       activeRideRequest: true,
    //     });
    //     // setUserData(userSnapData);
    //     fiesta('Ride Request Accepted');
    //     return;
    //   }
    // }
  });

  const qMe = query(cUserRef, where("userId", "==", uid));
  const userSnaps = await getDocs(qMe);
  const friends = userSnaps.docs[0].data().friends;
  if (friends) {
    for (const friend of friends) {
      const friendSnap = await getDoc(doc(db, "users", friend.id));
      // console.log(
      //   "prepping to remove ride request from friend",
      //   { friends },
      //   { friend },
      //   { friendSnap }
      // );
      if (friendSnap.exists()) {
        const friendData = friendSnap.data();

        if (friendData.friendRideRequests) {
          const curRideRequests = friendData.friendRideRequests || [];
          // #check if uid is in friendRideRequests and filter out
          const filteredRideRequests = curRideRequests.filter(
            (f: any) => f.id !== uid
          );
          // console.log("filteredRideRequests", { filteredRideRequests });

          if (
            friendSnap.ref &&
            filteredRideRequests.length !== friendData.friendRideRequests.length
          ) {
            await updateDoc(friendSnap.ref, {
              friendRideRequests: filteredRideRequests,
            });
          }
        }
      }
    }
  }
};

export const toggleActiveRideRequest = async (
  active: boolean,
  userData: any,
  setUserData: any,
  setClearTogglesActive: any,
  setTriggerShow: any,
  setLoading: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("$$$  - toggling active ride request", active, { userData });
  const cUserRef = collection(db, "users");

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);
  if (userSnaps?.empty) {
    console.log("No matching documents.");
    return;
  }

  userSnaps?.forEach(async (userSnap) => {
    if (!userSnap.exists()) {
      console.log("No matching documents.");
      return;
    }
    const userSnapData = userSnap.data();
    // console.log("#$#$#$#-", active, { userSnapData }, { userData });
    // console.log({userSnapData});
    userSnapData.activeRideRequest = active;

    setClearTogglesActive(!active);
    if (userData?.activeRideRequest !== userSnapData?.activeRideRequest) {
      if (userSnap?.exists()) {
        // await updateDoc(userSnap?.ref, {
        //   activeRideRequest: active,
        // });
        if (!active) {
          await updateDoc(userSnap?.ref, {
            currentRideRequest: null,
            activeRideRequest: false,
            currentRide: null,
            activePassenger: null,
            activeRide: false,
          });

          // call destroyPaymentIntent
          const destroyPaymentIntent = httpsCallable<unknown, any>(
            cloudFunctions,
            "destroyPaymentIntent"
          );
          await destroyPaymentIntent({
            paymentIntentId: userSnapData?.paymentIntentId,
            userId: uid,
          });

          // # loop through friends and remove ride request
          const friends = userSnapData?.friends;
          // console.log(
          //   "prepping to remove ride request from friends",
          //   { friends },
          //   uid
          // );

          if (friends) {
            friends?.map(async (friend: any) => {
              const friendSnap = await getDoc(doc(db, "users", friend?.id));
              // console.log(
              //   "prepping to remove ride request from friend",
              //   { friends },
              //   { friend },
              //   { friendSnap }
              // );
              if (friendSnap?.exists()) {
                const friendData = friendSnap?.data();

                if (friendData?.friendRideRequests) {
                  const curRideRequests = friendData?.friendRideRequests || [];
                  // #check if uid is in friendRideRequests and filter out
                  const filteredRideRequests = curRideRequests?.filter(
                    (f: any) => f.id !== uid
                  );
                  // console.log("filteredRideRequests", { filteredRideRequests });

                  if (
                    friendSnap?.ref &&
                    filteredRideRequests?.length !==
                      friendData?.friendRideRequests?.length
                  ) {
                    await updateDoc(friendSnap?.ref, {
                      friendRideRequests: filteredRideRequests,
                    });
                  }
                  // const friendIndex = friendFriends.findIndex((f: any) => f.id === uid);
                  // if(friendIndex > -1) {
                  //   friendFriends[friendIndex].currentRideRequest = null;
                  //   await updateDoc(friendSnap.ref, {
                  //     currentRideRequest: friendFriends,
                  //   });
                  // }
                }
              }
            });
          }
        } else {
          await updateDoc(userSnap?.ref, {
            activeRideRequest: active,
          });
        }
        // setUserData(userSnapData);
        fiesta(active ? "Ride Requested" : "Ride Request Cancelled");
        return;
      }
    }
  });
};

export const updateCurrentActiveRide = async (
  cancel: boolean,
  startLocation: any,
  destination: any,
  userData: any,
  setUserData: any,
  setClearTogglesActive: any,
  setTriggerShow: any,
  notifier?: any,
  fiesta?: any
) => {
  const uid = getUserId();
  if (!uid) return;
  // const userRef = doc(db, "users", `${userId}`);
  // console.log("$$$  - Updating active ride request", cancel, { userData });
  const cUserRef = collection(db, "users");

  const q = query(cUserRef, where("userId", "==", uid));

  const userSnaps = await getDocs(q);

  await userSnaps?.forEach(async (userSnap) => {
    const userSnapData = userSnap.data();
    // console.log({userSnapData});
    userSnapData.activeRideRequest = cancel;
    // console.log("#$#$#$#-", cancel, { userSnapData }, { userData });
    const currentRide = cancel
      ? null
      : {
          timeStamp: new Date(),
          startLocation,
          destination,
        };
    userSnapData.currentRide = currentRide;

    if (userData?.currentRide !== userSnapData?.currentRide) {
      //  setClearTogglesActive(true);

      if (userSnap.exists()) {
        await updateDoc(userSnap.ref, {
          activeRideRequest: cancel,
          currentRide,
        });
        // setUserData(userSnapData);
        setTriggerShow(true);
        fiesta(cancel ? "Ride Requested" : "Ride Request Cancelled");
        return;
      }
    }
  });
};
