import React from "react";
import styled from "@emotion/styled";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import "react-awesome-slider/dist/styles.css";
import * as ui from "../../../../styles/uiVars";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import HorizontalScroller from "../../Shared/HorizontalScroller";
import Home from "../../../Home";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const SliderVideo = styled.div`
  position: absolute;
  /* padding: 1em; */
  /* margin: 1em; */
  top: 0;
  height: 100%;
  width: 100%;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 0;

  @media screen and (max-width: ${ui.tablet}) {
    padding: 0;
    margin: 0;
  }
`;
export const SliderContentContainerLow = styled.div`
  position: absolute;
  padding: 1em;
  margin: auto;
  top: 2em;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 640px) {
    max-width: 50%;
    top: 0;
  }
`;

export const SliderContentContainer = styled.div`
  position: absolute;
  padding: 1em;
  margin: 1em;
  top: 1.5em;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${ui.tablet}) {
    top: 0.15em;
    padding: 0;
    /* display: none; */
  }
`;

export const SliderContainer = styled.div`
  /* max-width: 80vw; */
  /* height: 40vw; */
  /* margin: 0.25em; */
  /* max-height: 40vh; */
  /* padding: 1em 0; */
  padding-bottom: 2.5em;
  position: relative;
  /* left: 4vw; */
  margin-top: 2em;
  @media screen and (max-width: 600px) {
    /* padding: 0; */
    margin-top: 1em;
  }
  .awssld {
    --content-background-color: #00000030;
    --organic-arrow-color: #030f31;
    --control-bullet-color: #2d3985;
    --control-bullet-active-color: #1d1a65;
    --slider-height-percentage: 20%;
    --slider-width-percentage: 40%;
  }
  .awssld__controls button {
    z-index: 11;
  }

  @media screen and (max-width: ${ui.desktop}) {
    .awssld {
      --slider-height-percentage: 30%;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    /* padding: 0;
  margin: 0;
  .awssld {
  } */
  }
`;

export const SliderImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const HomeTopScrollerCard = styled.div`
  display: flex;
  width: 280px;
  max-width: 80vw;
  padding-bottom: 2em;
  /* height: 488px; */
  /* padding: 0px 32.797px 32.797px 0px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  /* flex-shrink: 0; */
  background: #1D1A65;

  border-radius: 20px;
  background: #2E2E75;
  box-shadow: 0px 0.82px 2.46px 0px rgba(34, 34, 34, 0.08), 0px 2.46px 1.64px 0px rgba(34, 34, 34, 0.04);

  @media screen and (max-width: ${ui.mobile}) {
    max-width: 68vw;
    
  }
`;

export const HomeTopImage = styled.img`
  max-width: 100%;
  /* max-height: 15vh; */
  width: 100%;
  border-radius: 8px 8px 0 0;
`;

export const HomeTopContentContainer = styled.div`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const HomeCardTitle = styled.div`
  display: flex;
  text-align: left;
  /* height: 53px; */
  flex-direction: column;
  justify-content: flex-end;
  /* align-items: center; */
  gap: 8px;

  color: #FFF;
/* text-align: center; */
font-family: 'Inter' sans-serif;
font-size: 32px;
font-style: normal;
font-weight: 800;
line-height: 40px; /* 125% */
`;
// ... (other styled components)

export const HomeCardText = styled.div`
  display: flex;
  text-align: left;
  color: #D9D9D9;
font-family: "Inter", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
height: auto;


  flex-direction: column;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #2E2E75;
  box-shadow: 0px 0.82px 2.46px 0px rgba(34, 34, 34, 0.08), 0px 2.46px 1.64px 0px rgba(34, 34, 34, 0.04);
`;

export const TopScroller = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };
  return (
    <SliderContainer>
      <HorizontalScroller>

      <HomeTopScrollerCard>
          <HomeTopImage src="/images/dropkins/landing/middle/make_requests_001.jpg" />
          <HomeTopContentContainer>
            <HomeCardTitle>
            Ride with peace of mind
            </HomeCardTitle>
            <HomeCardText>
            We want the world to have an alternative to riding with complete strangers. Now we can ask friends for rides and not feel like a burden.
              <br /><br />
              Only approved friends in your network will be notified.  Enabling the true benefits and safety of community.
              
              
            </HomeCardText>

          </HomeTopContentContainer>
        </HomeTopScrollerCard>

      <HomeTopScrollerCard>
          <HomeTopImage src="/images/dropkins/landing/scroll/top/home_card_image_001.jpg" />
          <HomeTopContentContainer>
            <HomeCardTitle>
              Add Your Community
            </HomeCardTitle>
            <HomeCardText>
            By adding your neighbors, coworkers, and friends. You can enjoy safer, more social rides.
            <br />
            <br />
            {/* Transform every journey into a chance for unexpected connections. */}
              Dropkins helps you connect with people you know and trust, and enable opportunities which enrich us all.
            </HomeCardText>

          </HomeTopContentContainer>
        </HomeTopScrollerCard>

      


      

        {/* <HomeTopScrollerCard>
          <HomeTopImage src="/images/dropkins/landing/scroll/top/home_card_image_003.jpg" />
          <HomeTopContentContainer>
            <HomeCardTitle>
              Peace of Mind
            </HomeCardTitle>
            <HomeCardText>
            Dropkins is designed to help you connect with people you know and trust.
            <br /><br />
            So, we can catch rides knowing we're in good company.

            </HomeCardText>

          </HomeTopContentContainer>
        </HomeTopScrollerCard> */}

<HomeTopScrollerCard>
          <HomeTopImage src="/images/dropkins/landing/scroll/top/home_card_image_002.jpg" />
          <HomeTopContentContainer>
            <HomeCardTitle>
             Earn some money
            </HomeCardTitle>
            <HomeCardText>
              Dropkins allows you to accept paid ride requests from people in your network.
              <br /><br />
              Paid rides can be a custom amount, or use the a flat donation amount set they define.
            </HomeCardText>

          </HomeTopContentContainer>
        </HomeTopScrollerCard>

        <HomeTopScrollerCard>
          <HomeTopImage src="/images/dropkins/landing/scroll/top/home_card_image_004.jpg" />
          <HomeTopContentContainer>
            <HomeCardTitle>
              Passive Income
            </HomeCardTitle>
            <HomeCardText>
            Generate passive income through referrals! By inviting friends, you stand to earn 3% on every paid ride they complete.
            <br /><br />
            Expand your network and reap rewards from every paid trip your referrals make!

            </HomeCardText>

          </HomeTopContentContainer>
        </HomeTopScrollerCard>
        
        <HomeTopScrollerCard>
        <HomeTopImage src="/images/dropkins/landing/middle/free_requests_001.jpg" />
          <HomeTopContentContainer>
            <HomeCardTitle>
              Car Pool Requests
            </HomeCardTitle>
            <HomeCardText>
              Friends can also ride free! Start with 10 free ride requests.
              <br />
              <span>Enjoy unlimited free ride requests and other great features for just <strong>$3/month</strong>.</span>
              <br /><br />
              
            </HomeCardText>

          </HomeTopContentContainer>
        </HomeTopScrollerCard>
       
       
       
       
        
      </HorizontalScroller>
    </SliderContainer>
    // <SliderContainer>
    //   <AutoplaySlider
    //     {...settings}
    //     play={true}
    //     cancelOnInteraction={true} // should stop playing on user interaction
    //     interval={6000}
    //   >
    //     <HomeTopScrollerCard>

    //       {/* <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_003.jpg" /> */}
    //     </HomeTopScrollerCard>
    //     <HomeTopScrollerCard>
    //       {/* <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_003.jpg" /> */}
    //     </HomeTopScrollerCard>
    //     <HomeTopScrollerCard>
    //       {/* <SliderImage src="/images/dropkins/landing/scroll/dropkins_scroll_item_003.jpg" /> */}
    //     </HomeTopScrollerCard>
    //   </AutoplaySlider>
    // </SliderContainer>
  );
};

export default TopScroller;
