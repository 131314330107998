import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import * as ui from '../../../../styles/uiVars';
import {
  HomeContentContainer,
  HomeContentText,
  HomeContentTitle,
  HomeMiddleImage,
  HomeContentWrapper,
} from './HomeMiddle';
import { ActivePanelButton } from '../../../../styles';
import { AuthContext } from '../../../../Auth';

import {
  LinkBtn,
  LinkHrefBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  LoadingContainer,
  LoadingSpinner,
} from "../../../../styles/shared";


 const HomeMiddleContainer = styled.div`
  display: flex;
  /* background: white; */
  width: 100%;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #EDEDED; */
background: #2E2E75;
background: #060515;
@media screen and (max-width: ${ui.mobile}) {
  flex-direction: column;
  
}

`;
const HomeImageContainer = styled.div`
  /* width: 708px; */
  /* height: 528px; */
  flex-basis: 50%;

  background: url('/images/dropkins/landing/middle/how_it_works_001.jpg') no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 400px;
  @media screen and (max-width: ${ui.tablet}) {
    /* height: 100%; */
  width: 100%;
  /* background: url('/images/dropkins/landing/middle/ride_together_001.jpg') no-repeat; */

  
}
  
  `;

export const HomeContentContainerC = styled(HomeContentContainer)`

/* align-items: center; */
/* width: 100%; */

@media screen and (max-width: ${ui.tablet}) {
/* text-align: left; */
}

`;


export const HomeContentTitleB = styled(HomeContentTitle)`
max-width: 80%;
`

export const HomeContentTextB = styled(HomeContentText)`
max-width: 80%;
`;


export const HomeMiddleC = () => {

  const {
    setSignupToggle,
    setLoginToggle,
    setHideHomePanel,
    signupToggle,
    loginToggle,
    hideHomePanel,
    topRef,
  } = useContext(AuthContext)

  const goToSignUp = () => {
    setSignupToggle(true);
    setLoginToggle(false);

    
    if (topRef.current) {
      topRef.current.scrollTop = 0
    }
    // history.push('/signup');
    // navigate('/signup');
  }


  return (
    <HomeMiddleContainer>
      <HomeContentContainerC>
        <HomeContentWrapper> 
          <HomeContentTitleB>
            What To Expect
          </HomeContentTitleB>
          <HomeContentTextB>
          With Dropkins, every journey is more than just a ride—it's a chance to connect, share, and earn, all while getting where you need to go. 
          {/* <br />
          <br />
          Join Dropkins today and be a part of transforming daily commutes into a community experience! */}
          </HomeContentTextB>

          <LinkBtn onClick={goToSignUp}> Get Started </LinkBtn>
        </HomeContentWrapper>

      </HomeContentContainerC>
      <HomeImageContainer>
        {/* <HomeMiddleImage src={"/images/dropkins/landing/middle/how_it_works_001.jpg"} /> */}
      </HomeImageContainer>
      
    </HomeMiddleContainer>

  )

};

export default HomeMiddleC;