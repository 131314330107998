// Import Swiper React components
import React from 'react';
// Import css files
import styled from '@emotion/styled';

export const LinkBtn = styled.div`
  display: flex;
  cursor: pointer;
  max-width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 0.78em;
  align-items: center;
  color: #384274;
  padding: 0.5em;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  background: #081c8e;
  background: #7dede0;
  background: #3bf5df;
  background: #DFF202;
  margin-top: 1em;
  margin-bottom: 0.81em;
  border: 1px solid #fff;
  padding: 0.75em;
  min-width: 8em;
  border-radius: 10px;
  /* background: rgb(45 65 164); */
  &:hover {
    background: #4F5CA0;
    background: #384274;
    background: #253cb3;
    background: #6eedde;
    border: 1px solid #fff;
    animation: hoverAnimation 0.3s ease-in-out;
  }

  ${({ small }) => small && `
    padding: 0.5em;
    font-weight: 600;
    font-size: 0.8em;
  `}

  ${({ tiny }) => tiny && `
    padding: 0.0125em;
    font-weight: 300;
    background: initial;
    font-size: 0.768em;

  `}

  @keyframes hoverAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  `;

  export const LinkHrefBtn = styled.a`

display: flex;
  cursor: pointer;
  max-width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  min-height: 0.78em;
  align-items: center;
  color: #384274;
  padding: 0.5em;
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 800;
  background: #081c8e;
  background: #7dede0;
  background: #0af3d8;
  margin: 0.75em;
  border: 1px solid #fff;
  padding: 0.75em;
  min-width: 10em;
  /* background: rgb(45 65 164); */
  &:hover {
    background: #4F5CA0;
    background: #384274;
    background: #253cb3;
    background: #6eedde;
    border: 1px solid #fff;
    animation: hoverAnimation 0.3s ease-in-out;
  }

  @keyframes hoverAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }

  `;


  