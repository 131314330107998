import React, {useContext} from 'react';

import { collection, getDocs, addDoc, doc, deleteDoc, 
  QuerySnapshot, DocumentData, where, CollectionReference, and, getDoc, updateDoc } from 'firebase/firestore/lite';
import 'firebase/compat/auth';
import 'firebase/firestore';
import firebase from "firebase/compat/app";
import {  appFirebase, providers, kindAuth, kinDb } from '../firebase-config';
import userEvent from '@testing-library/user-event';
import { query, setDoc, getFirestore, onSnapshot } from 'firebase/firestore';
import { createUserWithEmailAndPassword, getRedirectResult, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, updatePassword } from 'firebase/auth';

import { getUserId } from '../firebase';

 const db = getFirestore(appFirebase);



 export function startDataListeners() {
  const uid = getUserId();
  if(!uid) return;
  const q = query(collection(db, 'products'), where("active", "==", true));
  const querySnapshot = onSnapshot(q, (querySnapshot: any) => {
    const products: any = [];
    querySnapshot.forEach((snap: any) =>  {
      products.push({
        id: snap.id,
        ...snap.data(),
      })
    })
    // console.log(products);

    querySnapshot.forEach(async function (doc: any) {
      // console.log(doc.id, ' => ', doc.data());
      const priceSnap = await doc.ref.collection('prices').get();
      priceSnap.docs.forEach((doc: any) => {
        // console.log(doc.id, ' => ', doc.data());
      });
    });
    return products;
  })


  // console.log({querySnapshot});

}