import React, { useState, useContext } from "react";
import { httpsCallable } from "@firebase/functions";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
  getFirestore,
  getDoc,
  updateDoc,
  and,
} from "firebase/firestore";
import { getUserId } from "../../../firebase"; // Adjust the path according to your setup
import {
  kinDb,
  appFirebase,
  providers,
  db,
  kindAuth,
  messaging,
  cloudFunctions,
} from "../../../firebase-config";
import { AuthContext } from "../../../Auth";
import styled from "@emotion/styled";

import {
  ActivePanelButton,
} from "../../../styles";

import {
  LinkBtn,
  LinkHrefBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  LoadingContainer,
  LoadingSpinner,
} from "../../../styles/shared";


export const SetUpButton = styled.button`
  display: flex;
  cursor: pointer;
  max-width: 200px;
  background: #09175d;
  margin-top: 1.2em;
  color: white;
  padding: 0.5em;

  &:hover {
    background: #4f5ca0;
    background: #384274;
    background: #253cb3;
    border: 1px solid #fff;
    animation: hoverAnimation 0.3s ease-in-out;
  }
`;

export const StripeLinkButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { currentUser, customerData } = useContext(AuthContext);

  const generateStripeLink = async () => {
    setLoading(true);
    setError("");
    const uid = getUserId();

    try {
      // Assuming you have the user's Stripe account ID stored in their profile
      const user = currentUser;
      // console.log("### user", user, uid);

      // #get doc from collection users with uid
      // const userDoc = await db.collection('users').doc(uid).get();
      const cUserRef = collection(db, "users");
      const q = query(cUserRef, where("userId", "==", uid));
      const cUserSnapshot = await getDocs(q);
      const cUserDoc = cUserSnapshot.docs[0];

      const cCustomerRef = collection(db, "customers");
      const qCustomer = doc(cCustomerRef, uid);
      const cCustomerSnapshot = await getDoc(qCustomer);
      const cCustomerData = cCustomerSnapshot.data();

      // console.log("### c customer data", cCustomerData);

      // console.log("### cUserDoc", cUserDoc?.data());
      // const stripeAccountId = cUserSnapshot.docs.map(doc => doc.data().stripeAccountId);
      let stripeAccountId =
        customerData?.stripeAccountId || cCustomerData?.stripeAccountId;
      // console.log("### stripeAccountId", stripeAccountId);

      const userId = cUserDoc?.data()?.userId;
      if (!stripeAccountId) {
        // console.log("### no stripeAccountId");
        // Trigger Cloud Function to create a Stripe Connect account
        const createUserFunction = httpsCallable(
          cloudFunctions,
          "retrieveAccountID"
        );
        const result = await createUserFunction({
          userId: uid,
          email: user?.email,
          createIfMissing: true,
        });
        // console.log("Stripe account created:", result?.data);
        stripeAccountId = result?.data?.accountId;
        window.location.href = result.data.url; // Redirect to Stripe
      } else {
        // const userData = cUserSnapshot.data()
        // const userProfile = userData;
        // const stripeAccountId = userProfile?.stripeAccountId;
        const createAccountLink = httpsCallable(
          cloudFunctions,
          "createAccountLink"
        );
        const response = await createAccountLink({
          userId: uid,
          stripeAccountId: stripeAccountId,
        });
        // console.log("### response", response);
        if (response?.data?.url) {
          window.location.href = response.data.url; // Redirect to Stripe
        } else {
          // setTriggerShow(true);
          // notifier('Failed to generate Stripe link. Please try again.')
        }
        // window.location.href = response.data.url; // Redirect to Stripe
      }
    } catch (err) {
      console.error("Error generating Pay Accountt link:", err);
      setError("Failed to generate Pay Accountt link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <LinkBtn onClick={generateStripeLink} disabled={loading}>
        {loading ? "Loading..." : "Setup pay account"}
      </LinkBtn>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export const StripeUpdateButton = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    currentUser,
    stripeAccountStatus,
    setTriggerShow,
    setNotification,
    setFiestaMessage,
    customerData,
  } = useContext(AuthContext);

  const resetNotifications = async () => {
    setNotification(null);
    setFiestaMessage(null);
  };
  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };

  const generateStripeLink = async () => {
    setLoading(true);
    setError("");
    const uid = getUserId();

    try {
      // Assuming you have the user's Stripe account ID stored in their profile
      const user = currentUser;
      // console.log("### user", user, uid);

      // #get doc from collection users with uid
      // const userDoc = await db.collection('users').doc(uid).get();
      const cUserRef = collection(db, "customers");
      const custDoc = doc(cUserRef, uid);
      const custDocSnap = await getDoc(custDoc);
      const cUserData = customerData;

      const cCustomerRef = collection(db, "customers");
      const qCustomer = doc(cCustomerRef, uid);
      const cCustomerSnapshot = await getDoc(qCustomer);
      const cCustomerData = cCustomerSnapshot.data();

      // console.log("### c customer data", cCustomerData);

      // console.log('### cUserDoc', cUserDoc?.data());
      // const stripeAccountId = cUserSnapshot.docs.map(doc => doc.data().stripeAccountId);
      let stripeAccountId = cCustomerData?.stripeAccountId || customerData?.stripeAccountId;
      // console.log("### stripeAccountId", stripeAccountId);

      const userId = cUserData?.userId;
      if (!stripeAccountId) {
        //     console.log('### no stripeAccountId')
        //     // Trigger Cloud Function to create a Stripe Connect account
        //     const createUserFunction = httpsCallable(cloudFunctions, 'createStripeConnectAccount');
        //     const result = await createUserFunction({ userId: userId, email: user?.email });
        //     console.log('Stripe account created:', result?.data);
        //     stripeAccountId = result?.data?.accountId
        //     // window.location.href = result.data.url; // Redirect to Stripe
        //   } else {
        // check existing stripe account and retrieve account id to store it in users
        const retrieveAccountID = httpsCallable(
          cloudFunctions,
          "retrieveAccountID"
        );
        const result = await retrieveAccountID({
          userId: uid,
          email: user?.email,
          stripeId: stripeAccountId,
          createIfMissing: true,
        });
        // console.log("Stripe account retrieved:", result?.data, stripeAccountId);
        stripeAccountId = result?.data?.accountId;
        const resultData = result?.data;
        if (resultData?.success && resultData?.accountId) {
          // update stripeAccountId in users
          const userDoc = doc(kinDb, "customers", uid);
          await updateDoc(userDoc, { stripeAccountId: resultData.accountId });
          window.location.href = result.data.url; // Redirect to Stripe
        } else {
          // setTriggerShow(true);
          // notifier('Failed to generate Stripe link. Please try again.')
        }


      } else {
        // const userData = cUserSnapshot.data()
        // const userProfile = userData;
        // const stripeAccountId = userProfile?.stripeAccountId;
        if(!uid) {
            // console.log("### no uid");
            setTriggerShow(true);
            notifier("Failed to generate Pay Account link. Please try again.");
            setError("Failed to generate Pay Account link. Please try again.");
        };

        const updateAccountLink = httpsCallable(
          cloudFunctions,
          "updateAccountLink"
        );
        const response = await updateAccountLink({
          userId: uid,
          stripeAccountId: stripeAccountId,
        });


        if (!cCustomerData?.customerId) {
          const createCustomer = httpsCallable(
            cloudFunctions,
            "createCustomer"
          );
          const responseCustomer = await createCustomer({
            userId: uid,
            email: user?.email
          });
          if (responseCustomer?.data?.success && responseCustomer?.data?.customerId) {
            let stripeCustomerId = responseCustomer.data.customerId;

              // Store stripeAccountId in the database
            const customerDoc = doc(kinDb, "customers", uid);
            await updateDoc(customerDoc, { stripeCustomerId: stripeCustomerId });
            
          } else {
            setTriggerShow(true);
            notifier("Failed to create customer. Please try again.");
            setError("Failed to create customer. Please try again.");
          }
        }

        // console.log("### response", response);
        if (response?.data?.success && response?.data?.url) {
            window.location.href = response.data.url; // Redirect to Stripe
        } else {
          // get stripe link with account id
          const createAccountLink = httpsCallable(
            cloudFunctions,
            "createAccountLink"
          );
          const responseLink = await createAccountLink({
            userId: uid,
            stripeAccountId: stripeAccountId,
          });
          // error check
          // console.log({ responseLink });
          // console.log({ responseLinkError: responseLink?.error });
          if (responseLink?.error || !responseLink?.data?.url) {
            setTriggerShow(true);
            notifier("Failed to generate Pay Account link. Please try again.");
            setError("Failed to generate Pay Account link. Please try again.");
          } else {
             window.location.href = responseLink.data.url; // Redirect to Stripe
          }

        //   setTriggerShow(true);
        //   notifier("Failed to generate Stripe link. Please try again.");
        //   setError("Failed to generate Stripe link. Please try again.");
        }
      }
    } catch (err) {
      // console.error("Error generating Pay Account link:", err);
      setError("Failed to generate Stripe link. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ActivePanelButton onClick={generateStripeLink} disabled={loading}>
        {loading ? "Loading..." : "Configure Payments"}
      </ActivePanelButton>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default StripeLinkButton;
