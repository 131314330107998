import React, { useEffect, useState, useContext } from 'react';
import { getUserNotifications } from '../../../storage/utils/user';
import { loadChatMessages } from '../../../firebase';
import { AuthContext } from '../../../Auth';
import * as ui from '../../../styles/uiVars';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import styled from  '@emotion/styled'

const StyledIconButton = styled.div`
  /* gap: 10px; */
  padding: 15px;
  /* margin-left: auto; */
  /* margin-right: 10px; */
  color: white;
  background: #09175d;
  border-radius: 5%;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background: #000;
  }
`;

const UserNotificationText = styled(Typography)`
font-size: 1.2em;
  font-weight: 600;
  color: white;
  padding: 0.5em;
  text-transform: capitalize;
`;


const ResponsiveCard = styled.div`
  /* padding: 8px;
  margin: 8px 0; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 10px; */

 
  // Desktop breakpoint
  @media (min-width: 900px) {
    /* padding: 16px; // Example change for desktop */
  }
`;

const StyledCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  background: #0d0f0f;
  border-radius: 5%;
  padding: 0px;
  padding: 10px;
  margin-bottom: 10px;
  color: #ccc;
  /* align text left */


   // Tablet breakpoint
   @media (min-width: ${ui.tablet} ) {
    flex-direction: row; // Example change for tablet
  }

`;

const UserNotificationList = () => {
  const [readStatus, setReadStatus] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    userNotifications,
    setUserNotifications,
    setFiestaMessage,
    setNotification,
    chatId,
    setPanelTrigger,
    setChatId,
    setFriendId,
    setChatPanelUser,
    setChatPanelToggle,
    setMessages,
  } = useContext(AuthContext);

  const notifier = async (data) => {
    await setNotification(data);
  };
  const fiesta = async (data) => {
    await setFiestaMessage(data);
  };

  useEffect(() => {
    setLoading(true);
    // create async wrapper function to getusernotifications
    const userNotes = async () => {
      await getUserNotifications(userNotifications, setUserNotifications, fiesta, notifier)
      setLoading(false);
    };
    userNotes();
    // const userNotes = async (() => )
    // getUserNotifications(userNotifications, setUserNotifications, fiesta, notifier)?.then(() => {
    //   setLoading(false);
    // });
  }, []);

  useEffect(() => {
    const notificationsFlat = [...new Set(userNotifications.flat())];
    setNotifications(notificationsFlat);
  }, [userNotifications]);

  const toggleReadStatus = (id) => {
    setReadStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  return (
    <div style={{ overflow: 'hidden', maxWidth: '100%', maxHeight: '400px' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <div style={{ overflowY: 'auto', overflowX: 'hidden', maxWidth: '100%', maxHeight: '400px' }}>
          <Typography variant="h6" component="h2" style={{ margin: '8px' }}>
            Notifications ({notifications?.flat().length || 0})
          </Typography>
          {notifications?.flat().reverse().map((notification, i) => {
            const { sender, type, message, createdAt } = notification;
            return (
              <ResponsiveCard key={i} className="notification-card">
                <StyledCardContent>
                  <Avatar src={sender.avatar} style={{ width: 30, height: 30 }} />
                  <div style={{ flex: 1, padding: '10px' }}>
                    <UserNotificationText>
                      {type}: {message.length > 50 ? `${message.substring(0, 50)}...` : message}
                    </UserNotificationText>
                    <UserNotificationText>
                      {new Date(createdAt).toLocaleString()}
                    </UserNotificationText>
                  </div>
                  <StyledIconButton
                    onClick={async () => {
                      await setFriendId(sender);
                      await setPanelTrigger('clearPanel');
                      await loadChatMessages(
                        `${sender}`,
                        setMessages,
                        setChatId,
                        chatId,
                        setChatPanelUser,
                      );
                      setChatPanelToggle(true);
                    }}
                  >
                    Open Chat
                  </StyledIconButton>
                </StyledCardContent>
              </ResponsiveCard>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UserNotificationList;