import React, { useContext } from "react";

import "firebase/firestore";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
  getFirestore,
  getDoc,
  updateDoc,
  and,
  addDoc,
} from "firebase/firestore";

import {
  kinDb,
  appFirebase,
  cloudFunctions,
  providers,
  kindAuth,
} from "../firebase-config";
import { getUserId } from "../firebase";
import useDirectionsMap from "../hooks/useMapDirections";
import { getGeoCode } from "../hooks/useGoogleMap";
import app from "firebase/compat/app";
import {
  onCurrentUserSubscriptionUpdate,
  getProducts,
  createCheckoutSession,
} from "@stripe/firestore-stripe-payments";
import { get } from "http";

import { httpsCallable } from "firebase/functions";
export interface PaymentIntentResponse {
  paymentIntentId: string;
  paymentMethodId: string;
  clientSecret?: string;
  paymentIntentStatus: string;
}

export interface SetupIntentInterface {
  email: string;
}

const db = getFirestore(appFirebase);

export const handlePaymentCheck = async (userId: any, amount: any) => {
  const currentUserId = getUserId();
  // try {
  //     const intentId = await checkPaymentInfoAndCreateIntent(currentUserId,amount);
  //     if (intentId) {
  //         console.log('Payment Intent Created:', intentId);
  //         // Handle the created payment intent
  //     } else {
  //         console.log('User already has payment info');
  //         // Handle existing payment info
  //     }
  // } catch (error) {
  //     console.error('Error in payment process:', error);
  // }
};

export const checkPaymentInfoAndCreateIntent = async (
  userId: any,
  amount: number,
  paymentMethodId: any
) => {
  // const db = firebase.firestore();
  // const userRef = db.collection('users').doc(userId);
  const currentUserId = `` + getUserId();
  const userRef = collection(db, "users");
  const customerRef = collection(db, "customers");

  const customerDoc = doc(customerRef, currentUserId);
  const customerDocSnap = await getDoc(customerDoc);
  const customerData = customerDocSnap?.data();

  // query users for userId matches currentUserId
  const q = query(userRef, where("userId", "==", currentUserId));
  const userSnaps = await getDocs(q);
  // console.log("userSnaps", userSnaps);
  // get ref for the first doc
  const userSnap = userSnaps.docs[0];
  // const userData = userSnap.data();
  // const userPaymentIntentId = paymentIntentId;

  // const customerRef = doc(db, "customers", currentUserId);
  // const customerSnap = await getDoc(customerRef);
  // console.log("!* customerSnap", customerSnap?.data());
  // const customerData = customerSnap?.data();
  const stripeCustomerId = customerData?.stripeCustomerId;
  const userPaymentIntentId = customerData?.paymentIntentId;
  // console.log("#! userPaymentIntentId", {
  //   userPaymentIntentId,
  //   paymentMethodId,
  // });
  try {
    // const userDoc = await getDoc(userRef);
    // if (!userDoc.exists) {
    //     throw new Error('User not found');
    // }

    // const userData = userDoc.data();
    // console.log('userData', {userData})

    // if (!userData?.paymentInfo) {
    // User has no payment info, create payment intent
    const createPaymentIntent = httpsCallable<unknown, PaymentIntentResponse>(
      cloudFunctions,
      "createPaymentIntent"
    );
    const paymentIntent = await createPaymentIntent({
      amount: Number(amount * 100),
      currency: "usd",
      userId: currentUserId,
      paymentMethodId: paymentMethodId,
      paymentIntendId: userPaymentIntentId,
      stripeCustomerId,
    }); // Set the amount
    // console.log("paymentIntent", { paymentIntent });
    // store payment intent id in user doc
    await updateDoc(customerDocSnap?.ref, {
      paymentIntentId: paymentIntent?.data?.paymentIntentId,
      paymentMethodId: paymentMethodId,
    });
    // return paymentIntent?.data?.paymentIntentId;
    // } else {
    //     console.log('User already has payment info');
    //     // User already has payment info
    //     return null;
    // }
  } catch (error) {
    console.error("Error checking payment info:", error);
    throw error;
  }
};

export const setRideIntent = async (
  paymentMethodId: any,
  rideEstimate: any,
  rideDistanceEstimate: any,
  passengerCount: any,
  freeRideToggle: any,
  userData: any,
  notifier: any,
  fiesta: any,
  setTriggerShow: any
) => {
  const uid = "" + getUserId();
  // if(!uid) return;
  // const userRef = doc(db, 'users', uid);
  // console.log("update rideEstimate", rideEstimate);
  // console.log("update rideDistanceEstimate", rideDistanceEstimate);
  // if (!kindAuth.currentUser) return;

  // console.log("updating payment method", { paymentMethodId });
  // const products = await getProducts(payments, {
  //   includePrices: true,
  //   activeOnly: true,
  // });
  // for (const product of products) {
  //   // ...
  //   console.log('!* product', product);
  // }
  // #add  a checkout session to the customers collection for the current user
  const customerRef = doc(db, "customers", uid);
  const customerSnap = await getDoc(customerRef);
  // console.log("!* customerSnap", customerSnap?.data());

  // if () {

  //   await updateDoc(customerSnap.ref, {
  //     paymentIntentId: paymentIntentId,
  //   });
  // } else {
  //   const customerData = customerSnap?.data();

  //   await updateDoc(customerSnap.ref, {
  //     paymentIntentId: paymentIntentId,
  //   });
  // #Update customer collection with new checkout session
  // #add doc called checkout_sessions
  // #add a subcollection called checkout_sessions

  const paymentIntentsRef = collection(customerRef, "checkout_sessions");
  const paymentIntentsSnap = await getDocs(paymentIntentsRef);
  // console.log("!* paymentIntentsSnap", paymentIntentsSnap);

  checkPaymentInfoAndCreateIntent(
    userData?.userId,
    rideEstimate,
    paymentMethodId
  );

  // await addDoc(paymentIntentsRef, {
  //   mode: "subscription",
  //   success_url: window.location.origin,
  //   cancel_url: window.location.origin,
  //   line_items: [{ price: 'price_0OMSjps9aDFvbawNwkfk7xiS', quantity: 2 }],
  //   currency: 'usd',
  //   payment_method_types: ['card'],
  //   automatic_payment_methods: {
  //     enabled: true,
  //   },
  // }
  // ).then(async(docRef) => {
  //   console.log("Document written with ID: ", docRef.id);
  //   console.log('returned payment intent', {docRef});

  //   const paymentIntentId = docRef.id;
  //   await updateDoc(customerRef, {
  //     payment_intent_id: paymentIntentId,
  //   });
  //   // #Update customer collection with new checkout session
  //   // #add doc called checkout_sessions
  //   // #add a subcollection called checkout_sessions
  //   // const paymentIntentRef = doc(db, 'customers', userData?.userId, 'payment_intents', paymentIntentId);

  //   await setTriggerShow(true);
  //   await notifier('Your ride intent was successfully updated');
  // }
  // ).catch((error) => {
  //   console.error("Error adding document: ", error);
  // });

  // const checkoutSessionsRef = collection(customerRef, 'checkout_sessions');
  // const checkoutSessionsSnap = await getDocs(checkoutSessionsRef);
  // console.log('!* checkoutSessionsSnap', checkoutSessionsSnap);

  // await addDoc(checkoutSessionsRef, {
  //   mode: "setup",
  //   success_url: window.location.origin,
  //   cancel_url: window.location.origin,
  // }
  // );
  // const checkoutSessionsData = checkoutSessionsSnap?.data();
  // console.log('!* checkoutSessionsData', checkoutSessionsData);
  // if (!checkoutSessionsSnap.exists) {
  //   await addDoc(checkoutSessionsRef, {
  //     checkout_sessions: {
  // }

  // const session = await createCheckoutSession(payments, {
  //   price: 'price_0OMSjps9aDFvbawNwkfk7xiS',
  //   mode: 'subscription',
  //   success_url: 'https://dropkins.com/success',
  //   cancel_url: 'https://dropkins.com/cancel',
  // });
  // console.log('!* session', session);
  // window.location.assign(session.url);
};
