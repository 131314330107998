

import React, {useContext} from 'react';
import moment from 'moment';
import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeApp } from 'firebase/app';
import { httpsCallable } from "@firebase/functions";

import 'firebase/compat/auth';
import 'firebase/firestore';
import { kinDb, appFirebase, providers, cloudFunctions } from '../firebase-config';
// import { getFirestore, collection, getDocs, addDoc, doc, deleteDoc, QuerySnapshot, DocumentData, where, CollectionReference, and, getDoc, updateDoc } from 'firebase/firestore/lite';
import userEvent from '@testing-library/user-event';
import { collection, doc, getDocs, onSnapshot,addDoc, query, setDoc, where, updateDoc, getDoc, getFirestore, documentId } from 'firebase/firestore';
import { getUserId  } from '../firebase';
import firebase from 'firebase/compat/app';
import { AuthContext } from "../Auth";




const db = getFirestore(appFirebase);


export const sendChatMessage = async ({
  chatId,
  message,
  friendId,
  setChatId,
  setMessages,
  clearInput,
  notifier,
  fiesta,
  messageToken,
  customerData,
}: any) => {
  // message: string, friendId: string, chatId?: string, setChatId?: any, notifier?: any, fiesta?: any) => {
  
  const sendTextMessage = httpsCallable(cloudFunctions, "sendTextMessage");
  const chatRef = collection(db, "chats");

  const collectionRef = collection(db, "users");
  const sendPushNotification = httpsCallable(cloudFunctions, "sendPushNotification");
  const userDoc = doc(collectionRef, friendId);
  const userSnap = await getDoc(userDoc);
  const friendData = userSnap?.data();
  console.log({friendData})
  let friendUserName = friendData?.userName;

  const docRef = doc(db, 'users', friendId);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {

    const friendData = docSnap.data() as { userName: string };
    friendUserName = friendData?.userName;
    console.log('name of friend', {friendUserName});
    // return friendUserName;
  }
  
  
  // console.log(chatId, message, friendId)
  let userData: any;
  let userRefId;
  // # Check if chat id if not create new chat

  
  if (!chatId) {
    console.log('no chatid')
    const q = query(collectionRef, where("userId", "==", getUserId()));

    // const docSnap = await getDocs(q);

    let username;
    await getDocs(q).then(async (querySnapshot) => {
      if (querySnapshot.empty) return;
      userData = querySnapshot.docs[0].data();
      querySnapshot.forEach((doc) => {
        userRefId = doc.id;
        username = doc.data().username;
      });
    });

    // Query for user
    const userQuery = query(collectionRef, where(firebase.firestore.FieldPath.documentId(), '==', userRefId));
    const userQuerySnapshot = await getDocs(userQuery);
    let userDocRef;
    if (!userQuerySnapshot.empty) {
      userDocRef = userQuerySnapshot.docs[0].ref;
    }


        // Query for friend
    const friendQuery = query(collectionRef, where(firebase.firestore.FieldPath.documentId(), '==', friendId));
    const friendQuerySnapshot = await getDocs(friendQuery);
    let friendDocRef;
    if (!friendQuerySnapshot.empty) {
      friendDocRef = friendQuerySnapshot.docs[0].ref;
    }


      const chats = userData?.chats;
      // console.log(chats)
      chatId = chats && chats[friendId] ? chats[friendId] : null;

      if (chatId) {
      // if (chats?.length === 0 || !chats) {
        // clearInput();
        // create new chat
        // const newChatDoc = await addDoc(chatRef, {
        //   members: [friendId, userRefId],
        //   createdAt: Date.now(),
        //   messages: [],
        // });

        // chatId = newChatDoc.id;
      //  await setChatId(chatId);
        

        // const newChat = await addDoc(chatRef, {
        //   messages: [],
        //   members: [friendId, userRefId],
        // });
        // chatId = newChat.id;
        // setChatId(chatId);
      } else {
        // const chatIdRef = doc(chatRef, chatId);
      
          // throw new Error("doc cannot be set with an empty path");
          const newChatDoc = await addDoc(chatRef, {
            members: [friendId, userRefId],
            createdAt: Date.now(),
            messages: [],
          });
    
          chatId = newChatDoc.id;
        //  await setChatId(chatId);
        }

         
  if (userDocRef) {
    console.log('update user chats', {chats}, chats[friendId]);
    // #check if chat already exists
    if (chatId && userData?.chats[friendId]) {
      console.log('chat exists', chatId)

      

    } else {
      console.log('missing chatId or existing chat', chatId);

      await updateDoc(userDocRef, {
        chats: {
          ...userData?.chats,
          [friendId]: chatId,
          },
        });
        clearInput();
    // await updateDoc(userDocRef, {
    //   chats: {
    //     ...userData?.chats,
    //     [friendId]: chatId
    //   }
    // });
    }
  }

  console.log('update friend chats', chatId);

  if (friendDocRef) {
    console.log('update user chats', {chats}, chats[''+userRefId]);

    if (chatId && userData?.chats[''+userRefId]) {
     
    } else {
      console.log('missing chatId or existing chat', chatId);
    await updateDoc(friendDocRef, {
      chats: {
        ...userData?.chats,
        [''+userRefId]: chatId
      }
    });
    clearInput();
  }


  const friendQuerySnapshotB = await getDocs(friendQuery);
  console.log(friendQuerySnapshotB?.docs[0]?.data())
  // const friendUserName = friendQuerySnapshotB.docs[0].data().username;
  const newMessage = {
    message,
    friendId,
    time: Date.now(),
  };
  

  const notificationMessage = `Chat from: ${friendUserName} - ${message}`;
  // write to notifications collection for the recipient

  


    await updateDoc(friendDocRef, {
      notifications: firebase.firestore.FieldValue.arrayUnion({
      message: notificationMessage,
      createdAt: Date.now(),
      read: false,
      type: 'chat',
      sender: userRefId,
      recipient: friendId,
      })
    });

          
      //   } else {

        
      //   const chatSnap = await getDoc(chatDoc);
      //   if (chatSnap.exists()) {
      //     console.log('chat exists', chatId)
      //     const chat = chatSnap.data();
      //     const messages = chat?.messages;
      //     const newMessage = {
      //       message,
      //       friendId,
      //       time: Date.now(),
      //     };
      //     messages?.push(newMessage);
      //     await updateDoc(chatDoc, { messages });
      //   }
      // }
      // collection(chatRef, chatId);
      // const messageRef = doc(chatRef);
      // const messageSnap = await getDoc(messageRef);
    
  } 

  // if (chatId) {
    console.log('has chat id', chatId)
    const chatDoc = doc(chatRef, chatId);
    if (!chatDoc) {
      console.log("doc cannot be set with an empty path");
      notifier("Error Sending Message");

    } else {

      const chatSnap = await getDoc(chatDoc);
      if (chatSnap.exists()) {
        const chat = chatSnap.data();
        const messages = chat?.messages;
        const newMessage = {
          message,
          friendId,
          time: Date.now(),
        };
        messages?.push(newMessage);
        await updateDoc(chatDoc, { messages });
        clearInput();

        const notificationMessage = `Chat from: ${friendUserName} - ${message}`;
        // write to notifications collection for the recipient
        console.log(notificationMessage);
        if(customerData?.smsEnabled) {
          sendTextMessage({message: notificationMessage, phone: customerData?.smsPhone, name: customerData?.smsName, fromName: friendUserName });
        }

        sendPushNotification({ token: messageToken, userId: friendId,  title: 'New Message for ' + friendUserName,
          body: "You've received a new message."  }).catch((error: Error) => {
            console.log({error})
            console.error(`Failed to confirm checkout: ${error.message}`);
        });
   }

  
  

   loadChatMessages(friendId, setMessages, setChatId, chatId);
  }
  
  } else {
    console.log('has chat id', chatId, friendId, userRefId)

    const collectionRef = collection(db, "users");
    const q = query(collectionRef, where("userId", "==", getUserId()));

    // const docSnap = await getDocs(q);

    let username;
    await getDocs(q).then(async (querySnapshot) => {
      if (querySnapshot.empty) return;
      userData = querySnapshot.docs[0].data();
      querySnapshot.forEach((doc) => {
        userRefId = doc.id;
        username = doc.data().username;
      });
    });

    const chats = userData?.chats;
    console.log({userRefId, friendId, chats})
    // Query for user
    const userQuery = query(collectionRef, where(firebase.firestore.FieldPath.documentId(),'==', userRefId));
    
    const userQuerySnapshot = await getDocs(userQuery);
    let userDocRef;
    if (!userQuerySnapshot.empty) {
      userDocRef = userQuerySnapshot.docs[0].ref;
    }


        // Query for friend
    // const friendQuery = query(collectionRef, where('userId', '==', friendId));
    const friendQuery = query(collectionRef, where(firebase.firestore.FieldPath.documentId(), "==", friendId+''));
    
   


    const friendQuerySnapshot = await getDocs( friendQuery);
    const friendData = friendQuerySnapshot.docs;
    console.log({friendData})
    let friendDocRef;
    if (!friendQuerySnapshot.empty) {
      friendDocRef = friendQuerySnapshot.docs[0].ref;
    }


    console.log('update user chats', {chats}, chats[friendId]);
    if(!chats[friendId] && userDocRef) {
      console.log('missing chatId or existing chat', chatId);
      await updateDoc(userDocRef, {
        chats: {
          ...userData?.chats,
          [friendId]: chatId,
          },
        });
    }

    console.log('update friend chats', {chats, userRefId}, chats[userRefId+'']);
    if(!chats[userRefId+''] && friendDocRef) {
      console.log('missing chatId or existing chat', chatId, userRefId);
      // await updateDoc(friendDocRef, {
      //   chats: {
      //     [''+userRefId]: chatId
      //   }
      // });
    }

    const chatDoc = doc(chatRef, chatId);
    const chatSnap = await getDoc(chatDoc);
    if (chatSnap.exists()) {
      const chat = chatSnap.data();
      const messages = chat?.messages;
      const newMessage = {
        message,
        friendId,
        time: Date.now(),
      };
      messages?.push(newMessage);
      await updateDoc(chatDoc, { messages });
      sendPushNotification({ token: messageToken, userId: friendId,  title: 'Chat From ' + friendUserName,
      body: `${friendUserName} - ${message}`  }).catch(error => {
        console.error(`Failed to confirm checkout: ${error.message}`);
      });
    }

    loadChatMessages(friendId, setMessages, setChatId, chatId);
  }
}












export const loadChatMessages = async (friendId: string, setMessages: any, setChatId?: any, chatId?: string, setChatPanelUser?: any) => {
  const chatRef = collection(db, 'chats');
  let userRefId: any;
  // console.log({userRefId, friendId, chatId});
  let friendUserName;

  if(friendId) {

    // Query for friend
    // console.log('check friendid')

    const docRef = doc(db, 'users', friendId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const friendData = docSnap.data() as { userName: string };
      friendUserName = friendData?.userName;
      // console.log('name of friend', {friendUserName});
      setChatPanelUser && setChatPanelUser(friendUserName);
      // return friendUserName;
    }
    // await getDocs(qF).then((querySnapshot) => {
    //   if (querySnapshot.empty) return;
    //   const friendData = querySnapshot.docs[0].data() as { userName: string };
    //   friendUserName = friendData.userName;
    //   console.log('name of friend', {friendUserName});
    //   setChatPanelUser(friendUserName);
    //   return friendUserName;
    // });
    // const collectionRef = collection(db, 'users');
    // const qF = query(collectionRef, where("userId", "==", friendId));

    // await getDocs(qF).then( (querySnapshot) => {
    //   if (querySnapshot.empty) return;
    //   const friendData = querySnapshot.docs[0].data() as { userName: string };
    //   friendUserName = friendData.userName;
    //   console.log(friendUserName);
    //   setChatPanelUser(friendUserName);

    //   return friendUserName;

    //   // const userId = querySnapshot.docs[0].id; // get the user ID from the snapshot
    //   // const userRef = doc(db, 'users', userId); // create the document reference
    // });

  //   if(!friendSnap.empty) {
  //     const friendData = friendSnap.docs[0].data();
  //     // friendId = friendData.userId;
  //     friendUserName = friendData.username;
  //     setChatPanelUser(friendUserName);
  // }
}
  if (chatId) {
    const chatDoc = doc(chatRef, chatId);
    onSnapshot(chatDoc, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const messages = data.messages;
        setMessages(messages);
        // if (setChatId) setChatId(chatId);
      }
    });
  } else {


    const userId: any = getUserId();
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);
    const collectionRef = collection(db, 'users');
    const q = query(collectionRef, where("userId", "==", userId));
    await getDocs(q).then(async (querySnapshot) => {
      let userRefId;
      if (querySnapshot.empty) return;
      const userData = querySnapshot.docs[0].data();
      querySnapshot.forEach((doc) => {
      // console.log(doc);
      userRefId = doc.id;
      });
      const chats = userData?.chats;
      chatId = chats ? chats[friendId] : null;
      // console.log('loading messages chatId', chatId);
      setMessages([]); // Add this line to clear the messages

      if (chatId) {
      const chatDoc = doc(chatRef, chatId);
      onSnapshot(chatDoc, (doc) => {
        if (doc.exists()) {
        const data = doc.data();
        const messages = data.messages;
        setMessages(messages);
        // if (setChatId) setChatId(chatId);
        }
      });
      } else {
      // console.log('no user reference of chat with friendId', friendId, chatId, chats);
      const friendChatId = chats ? chats[friendId] : null;
      // console.log('friend chat id', friendChatId);
      // Create new chat
      // const newChatDoc = await addDoc(chatRef, {
      //   members: [friendId, userRefId],
      //   createdAt: Date.now(),
      //   messages: [],
      // });
      // const newChatId = newChatDoc.id;
      friendChatId && setChatId(friendChatId);
      }
    });
  }
  //   const chatRef = collection(db, 'chats');
//   let userRefId: any; 

//   setMessages([]);
//   // if(chatId) {
//   //   console.log({chatId})
//   //   const chatDoc = doc(chatRef, chatId);
//   //   //#onsnapshot to listen for changes
//   //   onSnapshot(chatDoc, (doc) => {
//   //     if(doc.exists()) {
//   //       const data = doc.data();
//   //       const messages = data.messages;
//   //       setMessages(messages);
//   //       if(setChatId) setChatId(chatId);
//   //     }
//   //   })
//   //   // const chatSnap = await getDoc(chatDoc);
//   //   // if(chatSnap.exists()) {
//   //   //   const chatData = chatSnap.data()!;
//   //   //   if(chatData.messages) {
//   //   //     console.log(chatData?.messages)
//   //   //     setMessages(chatData?.messages);
//   //   //   }
//   //   // }
//   // } else {

//     // console.log('no chat id')
//     console.log('friend id', friendId)

//     // #check logged in user for record of chat with friendId
//     const userId: any = getUserId();
//     const userRef = doc(db, 'users', userId);
//     const userSnap = await getDoc(userRef);
//     let userData = userSnap?.data()?.empty ? null : userSnap.data(); 
//     // userRefId = userSnap?.id;

//     const collectionRef = collection(db, 'users');
//     const q = query(collectionRef, 
//       where("userId", "==", userId),
//     )
  
//     const docSnap = await getDocs(q);

//     await getDocs(q).then(async (querySnapshot) => {
//       let username;
//       if(querySnapshot.empty) return;
//       userData = querySnapshot.docs[0].data();
//       querySnapshot.forEach((doc) => {
//         console.log(doc)
//         userRefId = doc.id;
//         username = doc.data().username;
//       });
//     })
//     // console.log('## friend id', friendId, userRefId)
//     const chats = userData?.chats;
//     console.log({chats});
//     chatId = chats ? chats[friendId] : null;

    
//     if(chatId) {
//       console.log('loading messages chatId', chatId)
//       const chatDoc = doc(chatRef, chatId);
//       // const chatSnap = await getDoc(chatDoc);
//       //# onsnapshot to listen for changes
//       onSnapshot(chatDoc, (doc) => {
//         if(doc.exists()) {
//           const data = doc.data();
//           const messages = data.messages;
//           setMessages(messages);
//           if(setChatId) setChatId(chatId);
//         }
//       })
//       // if(chatSnap.exists()) {
//       //   const chatData = chatSnap.data()!;
//       //   setMessages(chatData.messages);
//       //   setChatId(chatId);
//       // }
//     } else {

//       console.log('no user reference of chat with friendId',friendId, chatId, chats)

//       const friendChatId = chats[friendId];
//       console.log('friend chat id', friendChatId)


//       // console.log(friendId, userRefId, chats)

//       // create new chat
//       // const newChatDoc = await addDoc(chatRef, {
//       //   // members: [userId, friendId],
//       //   members: [friendId, userRefId],
//       //   createdAt: Date.now(),
//       //   messages: [],
//       // });

//       // const newChatId = newChatDoc.id;
//       // setChatId(newChatId);
//       // console.log('loadchatmessages newChatId', newChatId)
//       const usersCollection = collection(db, 'users');

// // Query for user
// const userQuery = query(usersCollection, where(firebase.firestore.FieldPath.documentId(), '==', userRefId));
// const userQuerySnapshot = await getDocs(userQuery);
// let userDocRef;
// if (!userQuerySnapshot.empty) {
//   userDocRef = userQuerySnapshot.docs[0].ref;
// }


//      // Query for friend
// const friendQuery = query(usersCollection, where(firebase.firestore.FieldPath.documentId(), '==', friendId));
// const friendQuerySnapshot = await getDocs(friendQuery);
// let friendDocRef;
// if (!friendQuerySnapshot.empty) {
//   friendDocRef = friendQuerySnapshot.docs[0].ref;
// }

// if (chats) {
//   console.log('update user chats', {chats});

//   // add chat id to users
//   if (userDocRef && !chats[friendId]) {
//     console.log('setting user chats userRefId', userRefId,  friendId, chatId);
//     await updateDoc(userDocRef, {
//       chats: {
//         ...userData?.chats,
//         [friendId]: chatId
//       }
//     });
//   }

//   console.log('update friend chats userRefId', userRefId);

//   if (friendDocRef && !chats[userRefId]) {
//     console.log('setting user chats',  userRefId, chatId);
//     await updateDoc(friendDocRef, {
//       chats: {
//         ...userData?.chats,
//         [userRefId]: chatId
//       }
//     });
//   } else {
//     console.log('no friend doc ref', userRefId, friendId, chats[userRefId], chats[friendId]);
//   }

//   console.log('done updating chats');

// } else {
//   console.log('create new chats');

//   const chatDoc = doc(chatRef, chatId);

//   if (userDocRef) {
//     await updateDoc(userDocRef, {
//       chats: {
//         [friendId]: chatDoc.id
//       }
//     });
//   }

//   console.log('update friend chats');

//   if (friendDocRef) {
//     await updateDoc(friendDocRef, {
//       chats: {
//         [userRefId]: chatDoc.id
//       }
//     });
//   }
// }

//     // const newChatDoc = await addDoc(chatRef, {
//     //   friendId,
//     //   createdAt: Date.now(),
//     //   messages: []
//     // });
//     // const newChatId = newChatDoc.id;
//     // setChatId(newChatId);
//   }


//   // const chatDoc = doc(chatRef, chatId);
//   // const chatSnap = await getDoc(chatDoc);
//   // const chat = chatSnap.data();
//   // const messages = chat?.messages;
//   // setMessages(messages);
// // }
// }
};


//   export const loadChatMessages = async (friendId: string, setMessages: any, setChatId?: any, chatId?: string) => {
//   const chatRef = collection(db, 'chats');
//   let userRefId: any; 

//   setMessages([]);
//   // if(chatId) {
//   //   console.log({chatId})
//   //   const chatDoc = doc(chatRef, chatId);
//   //   //#onsnapshot to listen for changes
//   //   onSnapshot(chatDoc, (doc) => {
//   //     if(doc.exists()) {
//   //       const data = doc.data();
//   //       const messages = data.messages;
//   //       setMessages(messages);
//   //       if(setChatId) setChatId(chatId);
//   //     }
//   //   })
//   //   // const chatSnap = await getDoc(chatDoc);
//   //   // if(chatSnap.exists()) {
//   //   //   const chatData = chatSnap.data()!;
//   //   //   if(chatData.messages) {
//   //   //     console.log(chatData?.messages)
//   //   //     setMessages(chatData?.messages);
//   //   //   }
//   //   // }
//   // } else {

//     // console.log('no chat id')
//     console.log('friend id', friendId)

//     // #check logged in user for record of chat with friendId
//     const userId: any = getUserId();
//     const userRef = doc(db, 'users', userId);
//     const userSnap = await getDoc(userRef);
//     let userData = userSnap?.data()?.empty ? null : userSnap.data(); 
//     // userRefId = userSnap?.id;

//     const collectionRef = collection(db, 'users');
//     const q = query(collectionRef, 
//       where("userId", "==", userId),
//     )
  
//     const docSnap = await getDocs(q);

//     await getDocs(q).then(async (querySnapshot) => {
//       let username;
//       if(querySnapshot.empty) return;
//       userData = querySnapshot.docs[0].data();
//       querySnapshot.forEach((doc) => {
//         console.log(doc)
//         userRefId = doc.id;
//         username = doc.data().username;
//       });
//     })
//     // console.log('## friend id', friendId, userRefId)
//     const chats = userData?.chats;
//     console.log({chats});
//     chatId = chats ? chats[friendId] : null;

    
//     if(chatId) {
//       console.log('loading messages chatId', chatId)
//       const chatDoc = doc(chatRef, chatId);
//       // const chatSnap = await getDoc(chatDoc);
//       //# onsnapshot to listen for changes
//       onSnapshot(chatDoc, (doc) => {
//         if(doc.exists()) {
//           const data = doc.data();
//           const messages = data.messages;
//           setMessages(messages);
//           if(setChatId) setChatId(chatId);
//         }
//       })
//       // if(chatSnap.exists()) {
//       //   const chatData = chatSnap.data()!;
//       //   setMessages(chatData.messages);
//       //   setChatId(chatId);
//       // }
//     } else {

//       console.log('no user reference of chat with friendId',friendId, chatId, chats)

//       const friendChatId = chats[friendId];
//       console.log('friend chat id', friendChatId)


//       // console.log(friendId, userRefId, chats)

//       // create new chat
//       // const newChatDoc = await addDoc(chatRef, {
//       //   // members: [userId, friendId],
//       //   members: [friendId, userRefId],
//       //   createdAt: Date.now(),
//       //   messages: [],
//       // });

//       // const newChatId = newChatDoc.id;
//       // setChatId(newChatId);
//       // console.log('loadchatmessages newChatId', newChatId)
//       const usersCollection = collection(db, 'users');

// // Query for user
// const userQuery = query(usersCollection, where(firebase.firestore.FieldPath.documentId(), '==', userRefId));
// const userQuerySnapshot = await getDocs(userQuery);
// let userDocRef;
// if (!userQuerySnapshot.empty) {
//   userDocRef = userQuerySnapshot.docs[0].ref;
// }


//      // Query for friend
// const friendQuery = query(usersCollection, where(firebase.firestore.FieldPath.documentId(), '==', friendId));
// const friendQuerySnapshot = await getDocs(friendQuery);
// let friendDocRef;
// if (!friendQuerySnapshot.empty) {
//   friendDocRef = friendQuerySnapshot.docs[0].ref;
// }

// if (chats) {
//   console.log('update user chats', {chats});

//   // add chat id to users
//   if (userDocRef && !chats[friendId]) {
//     console.log('setting user chats userRefId', userRefId,  friendId, chatId);
//     await updateDoc(userDocRef, {
//       chats: {
//         ...userData?.chats,
//         [friendId]: chatId
//       }
//     });
//   }

//   console.log('update friend chats userRefId', userRefId);

//   if (friendDocRef && !chats[userRefId]) {
//     console.log('setting user chats',  userRefId, chatId);
//     await updateDoc(friendDocRef, {
//       chats: {
//         ...userData?.chats,
//         [userRefId]: chatId
//       }
//     });
//   } else {
//     console.log('no friend doc ref', userRefId, friendId, chats[userRefId], chats[friendId]);
//   }

//   console.log('done updating chats');

// } else {
//   console.log('create new chats');

//   const chatDoc = doc(chatRef, chatId);

//   if (userDocRef) {
//     await updateDoc(userDocRef, {
//       chats: {
//         [friendId]: chatDoc.id
//       }
//     });
//   }

//   console.log('update friend chats');

//   if (friendDocRef) {
//     await updateDoc(friendDocRef, {
//       chats: {
//         [userRefId]: chatDoc.id
//       }
//     });
//   }
// }

//     // const newChatDoc = await addDoc(chatRef, {
//     //   friendId,
//     //   createdAt: Date.now(),
//     //   messages: []
//     // });
//     // const newChatId = newChatDoc.id;
//     // setChatId(newChatId);
//   }


//   // const chatDoc = doc(chatRef, chatId);
//   // const chatSnap = await getDoc(chatDoc);
//   // const chat = chatSnap.data();
//   // const messages = chat?.messages;
//   // setMessages(messages);
// // }
// }










