

// All the variables used in the app are defined here
export const desktop = '1024px';
export const tablet = '768px';
// export const mobile = '480px';
export const mobile = '650px';
export const mobileS = '320px';
export const mobileM = '375px';
export const mobileL = '425px';
export const mobileXL = '768px';
export const mobileXXL = '1024px';
export const mobileXXXL = '1440px';
export const mobileXXXXL = '2560px';
export const mobileXXXXXL = '3840px';
export const mobileXXXXXXL = '4096px';
export const mobileXXXXXXXL = '5120px';
export const mobileXXXXXXXXL = '6144px';
export const mobileXXXXXXXXXL = '7168px';

export const colors = {
  primary: '#0d0c1d',
  secondary: '#ffed4b',
  tertiary: '#ffffff',
  quaternary: '#ff523b',
  quinary: '#DA0037',
  senary: '#141414',
  septenary: '#f7f7f7',
  octonary: '#000000',
  nonary: '#141414',
  denary: '#141414',
  black: '#000000',
  white: '#ffffff',
  gray: '#9c9c9c',
  gray1: '#f7f7f7',
  gray2: '#eaeaea',
  gray3: '#dbdbdb',
  gray4: '#a9a9a9',
  gray5: '#6d6d6d',
};



