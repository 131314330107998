import React from 'react';

export const PrivacyPolicy = ({ isOpen }: { isOpen: boolean }) => {
  if (!isOpen) return null;
  return (
    <div className="privacy-policy" style={{ color: 'white', textAlign: 'left' }}>
      <div>
        <h2>Privacy Policy</h2>
        <p>Last updated: July 2024</p>
        <hr />
        <h3>Introduction</h3>
        <p>
          This Privacy Policy ("Policy") explains how we collect, use, and disclose your personal information when you access or use our Services. By using our Services, you agree to be bound by this Policy. If you do not agree to this Policy, you may not use our Services.
        </p>
        <h3>Information We Collect</h3>
        <p>
          We collect certain personal information from you when you use our Services. This may include your name, email address, and other contact information. We also collect information about your usage of our Services, such as your IP address and device information.
        </p>
        <h3>Use of Your Information</h3>
        <p>
          We use the information we collect to provide and improve our Services, personalize your experience, and communicate with you. We may also use your information for research and analytics purposes.
        </p>
        <h3>Disclosure of Your Information</h3>
        <p>
          We may disclose your personal information to third parties in certain circumstances, such as when required by law or to protect our rights. We may also share your information with service providers who assist us in operating our Services.
        </p>
        <h3>Security</h3>
        <p>
          We take reasonable measures to protect your personal information from unauthorized access or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.
        </p>
        <h3>Cookies</h3>
        <p>
          Our Services use "cookies" to enhance user experience. A cookie is a small piece of data sent from a website and stored on the user's computer by the user's web browser. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.
        </p>
        <h3>Changes to this Policy</h3>
        <p>
          We reserve the right to modify or replace this Policy at any time. If we make material changes, we will provide notice through our Services or by other means.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or concerns about this Policy, please contact us at info@dropkins.com.
        </p>
        <hr />
        <p>
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
