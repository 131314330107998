import React, { useCallback, useState, useEffect, useRef } from 'react';
import  { getUserId, deleteCheckIn } from '../firebase';

import styled from '@emotion/styled';
import { kinDb } from '../firebase-config';
import { query, where, collection, doc, addDoc, updateDoc, onSnapshot } from 'firebase/firestore';


const disabled = false;
 const CheckInListContainer = styled.ul`
  z-index:1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  /* background: #83b0d8; */
  background: rgba(89, 187, 178, 0.43);
  grid-area: 2/ 2 / 3 / 3; 
  padding: 0.4em;
  margin: 0.25em;
  overflow: auto;
  height: 50vh;
  opacity: 1;

  ${({ toggle }) => toggle && `
    display: none;
    opacity: 0;
  `}
  
 `;

 

  const CheckedLocation = styled.li`
    padding: 0.41em;
    background: rgba(0,0,0,0.7);
    margin: 0.051em;
    font-size: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &:hover {
      background: rgba(0,0,0,0.6);
    }
  `;

const CheckInDelete = styled.button`
  padding: 0.3em; 
  background: #315f88;
  color: white;
  font-weight: 700;
  margin: 0.25em;
`;

const CheckInText = styled.div`
  
`;

const ToggleCheckIns = styled.div`
  padding: 0.3em; 
  background: rgba(0,0,0,0.6);
  padding: 0.25em;
  display: flex;
  width: 20%;
  text-align: center;
  cursor: pointer;
  min-width: 3em;
  justify-content: center;
  border: 2px solid white;
  &:hover {
    background: rgba(0,0,0,0.8);
  }
`;

const ToggleContainer = styled.div`
  margin-top: 1em;
  padding: 0.3em;
  /* background: rgba(0,0,0,0.5); */
  /* width: 4em; */
  position: absolute;
  display: block;
  bottom: 2em;
  left: 0;
  z-index: 2;
  right: 5em;
  color: white;

  * {
    -webkit-transition: all 1 ease;
    -moz-transition:    all 1 ease;
    -ms-transition:     all 1 ease;
    -o-transition:      all 1 ease;
    transition:      all 1 ease;
  }
`;

export const CheckHeader = styled.div`
  display:flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 700;
`;

export const CheckBody = styled.div`
  display:flex;
`;

export const CheckInsList = ({
  resetMarkers,
  triggerMarkerReset,
  resetMarker,
  setResetMarker,
  resetWarningMarker,
  setResetWarningMarker, 
}) => {
  const [markers, setMarkers] = React.useState([{
    id:0,
    address: "88 South Garfield Avenue, Alhambra, CA, USA",
    latitude: 34.094017199999996,
    longitude: -118.1259759,
    time: 1584328854229}])

  React.useEffect(() => {
    const q = query(collection(kinDb, 'allMarkers'), where("userId", "==", getUserId()));
    onSnapshot(q, (snapShot) => {
    // kinDb
    // .collection('allMarkers').where("userId", "==", getUserId() )
    // .onSnapshot((snapShot) => {
      const markers = snapShot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMarkers(markers);
    })
    // fetchData();
  }, []);

  const [checkInToggle, toggleCheckIn] = useState(true);
  

  // useEffect(() => {
   
  // }, [warningZone]);

  const removeCheckIn = async (checkIn) => {
    setResetMarker(checkIn.id)
    const callbackRemove = await deleteCheckIn(checkIn.id, checkIn.address);
    setResetWarningMarker(callbackRemove);
    // console.log('after rem', callbackRemove)
    // console.log(resetMarkers)
    // triggerMarkerReset(true);
    // triggerMarkerReset(false);
  }

  const getDateString = (time) => {
    // console.log(Date(time));
    // return Date(time);
  }
  return (
    <ToggleContainer>
      <ToggleCheckIns onClick={() => toggleCheckIn(!checkInToggle)}> Ride Requests </ToggleCheckIns>
      <CheckInListContainer toggle={checkInToggle}>
        {markers.map(checkIn => {
          return (<CheckedLocation key={checkIn.id}>
            <CheckHeader>
              <CheckInText>{checkIn.address} </CheckInText>
              <CheckInDelete onClick={() => {removeCheckIn(checkIn)}}>Delete</CheckInDelete>
            </CheckHeader>
            <CheckBody>
              Date: {getDateString(checkIn.time)}
            </CheckBody>
            </CheckedLocation>)
        })}
      </CheckInListContainer>
    </ToggleContainer>
  )
};

export default CheckInsList;
