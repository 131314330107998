
import React, { useEffect, useState, useContext } from "react";
// import firebase from 'firebase';
import { db, kinDb, kindAuth, cloudFunctions } from "../../../firebase-config";
import styled from "@emotion/styled";

export const EmojiPickerTray = styled.div`
max-width: 300px;
pointer-events: all;
margin-top: 1em;

transition: all 0.3s ease-in-out;

`;

export const EmojiHeader = styled.div`
  animation: floatUpDown 6s infinite ease-in-out;
  pointer-events: all;

@keyframes floatUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

`;

export const EmojiPickerWrapper = styled.div`
pointer-events: none;
  position: relative;
    display: flex;
    align-items: flex-end;
    top: -33px;
    right: 10px;

    width: 100%;
    flex-direction: column;
`;


export const EmojiPicker = ({inputId, fieldName, isExpanded, setIsExpanded, getValues, setValue}) => {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  

  const emojis = [
    {
      name: "Grinning Face",
      emoji: "😀",
    },
    {
      name: "Face Blowing a Kiss",
      emoji: "😘",
    },
    {
      name: "Rolling on the Floor Laughing",
      emoji: "🤣",
    },
    {
      name: "Smiling Face with Heart-Eyes",
      emoji: "😍",
    },
    {
      name: "Face With Tears of Joy",
      emoji: "😂",
    },
    {
      name: "Winking Face",
      emoji: "😉",
    },
    {
      name: "Thumbs Up",
      emoji: "👍",
    },
    {
      name: "Thumbs Down",
      emoji: "👎",
    },
    {
      name: "Fire",
      emoji: "🔥",
    },
    {
      name: "Heart",
      emoji: "❤️",
    },
    {
      name: "Smiling Face with Smiling Eyes",
      emoji: "😊",
    },
    {
      name: "Face with Rolling Eyes",
      emoji: "🙄",
    },
    {
      name: "Thinking Face",
      emoji: "🤔",
    },
    {
      name: "Clapping Hands",
      emoji: "👏",
    },
    {
      name: "Party Popper",
      emoji: "🎉",
    },
    {
      name: "Rocket",
      emoji: "🚀",
    },
    {
      name: "Rainbow",
      emoji: "🌈",
    },
    {
      name: "Sun",
      emoji: "☀️",
    },
    {
      name: "Moon",
      emoji: "🌙",
    },
    {
      name: "Star",
      emoji: "⭐️",
    },
    {
      name: "Smiling Face with Sunglasses",
      emoji: "😎",
    },
    {
      name: "Face Blowing a Kiss",
      emoji: "😗",
    },
    {
      name: "Smiling Face with Heart-Eyes",
      emoji: "",
    },
    {
      name: "Smiling Face with Halo",
      emoji: "😇",
    },
    {
      name: "Face Screaming in Fear",
      emoji: "😱",
    },
    {
      name: "Smiling Face with Horns",
      emoji: "😈",
    },
    {
      name: "Ghost",
      emoji: "👻",
    },
    {
      name: "Skull",
      emoji: "💀",
    },
    {
      name: "Pile of Poo",
      emoji: "💩",
    },
    {
      name: "Robot Face",
      emoji: "🤖",
    },
    {
      name: "Alien Monster",
      emoji: "👽",
    },
    {
      name: "Exploding Head",
      emoji: "🤯",
    },
    {
      name: "Cowboy Hat Face",
      emoji: "🤠",
    },
    {
      name: "Clown Face",
      emoji: "🤡",
    },
    {
      name: "New Emoji 1",
      emoji: "😺",
    },
    {
      name: "New Emoji 2",
      emoji: "😸",
    },
    {
      name: "New Emoji 3",
      emoji: "😹",
    },
  ];

  const handleEmojiClick = (emoji) => {
    setChosenEmoji(emoji);
    // setIsExpanded(!isExpanded);
    const fieldValue = getValues(fieldName);
    // console.log(getValues(fieldName))
    setValue(fieldName, fieldValue + emoji);
  };

  return (
    <EmojiPickerWrapper>
      <EmojiHeader onClick={() => setIsExpanded(!isExpanded)}>😀</EmojiHeader>

      {isExpanded && (
        <EmojiPickerTray>
          {emojis.map((emoji) => (
            <span
              key={emoji.name}
              onClick={() => handleEmojiClick(emoji.emoji)}
              style={{ fontSize: "24px", cursor: "pointer" }}
            >
              {emoji.emoji}
            </span>
          ))}

          {/* {chosenEmoji && <EmojiPickerTray>You picked: {chosenEmoji}</EmojiPickerTray>} */}
        </EmojiPickerTray>
      )}
    </EmojiPickerWrapper>
  );
};
