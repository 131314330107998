import React, {useContext} from 'react';
// import logo from './logo.svg';
import './App.css';
import styled from '@emotion/styled';
import LoginButton from './components/FireSignIn';
import Home from './components/Home';
import SignUp from './components/SignUp';
import LogIn from './components/LogIn';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import {Navigate, BrowserRouter as Router, Route, Routes, useRoutes} from 'react-router-dom';
import { AuthProvider } from './Auth';
import PrivateRoute from './PrivateRoute';
import { AuthContext } from './Auth';



// firebase.firestore().collection('infections').add({
//   location: 'yo mammas house',
//   user: 'not me'
// })

const MainContentPanel = styled.div`
  /* display: grid; */
  color: #fff;;
  font-family: Metropolis, Arial, Helvetica, sans-serif;
  height: 100vh;
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-area: 1 / 1 / 3 / 3; 
  background: #131b8520; */
`;

const CenteredPanel = styled.div`
  grid-area: 2 / 2 / 3 / 3; 
`;


function App() {
  return (
    <AuthProvider>
      <Router>
        <MainContentPanel className="App">
          <RoutesComponent />
        </MainContentPanel>
      </Router>
    </AuthProvider>
  );
}

function RoutesComponent() {
  const { currentUser } = useContext(AuthContext);
  // console.log('currentUser routes', currentUser)
  let routes = useRoutes([
    { path: '/', element: currentUser ? <Home /> : <Navigate to="/login" /> },
    { path: '/signup', element: <SignUp /> },
    { path: '/login', element: <LogIn /> },
    { path: '/success', element: <SubscriptionSuccess /> },
    // { path: '/invite', element: <LogIn /> },
  ]);

  return routes;
}
 /* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */
export default App;
