import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ChatPanel = styled.div<{toggle: boolean, openSpeedModal: boolean}>`
  border-top: 2px solid #72f4dada;
  display: flex;
  z-index: 13;
  /* background: #384274; */
  background: #031156;
  padding: 0.5em;
  border-radius: 0.5em;
  overflow: hidden;
  position: fixed;
  top: 10vh;
  margin: auto;
  left: 15%;
  flex-direction: column;
  width: 40%;
  min-width: 300px;
  max-width: 500px;

   ${({ toggle }) => !toggle && `
    display: none;
    opacity: 0;
  `}
   ${({ openSpeedModal }) => openSpeedModal && `
    z-index: 14;
    // background: rgba(48, 186, 152, 0.5);
  `}

  @media screen and (max-width: 768px) {
    padding: 0.5em;
  }


`;

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  
  align-items: center;
  padding: 0.75em;
  border-radius: 20px;
  margin: 0.5em;
  border: 1px solid #4217b0;
  /* add gradient bg */
  /* background: linear-gradient(180deg, #0d0d0d00 10%, #0d0d0d00), #0e064e; */
  /* background: linear-gradient(180deg, #2b7c8961 10%, #0d0d0d00), #0e064e */
  background: white;
  color: black;
`;

export const NotificationListContainer = styled.div`
  height: 200px;
  max-height: 40vh;
  overflow-y: auto;
  /* padding: 0.35em; */
/* display:flex; */
  scrollbar-color: #72f4dada #0923a5;
  scrollbar-width: thin;
  
`;




export const ChatPanelCloseBtn  = styled.div`
  position: absolute;
  right: 10px; 
  top: 10px;
  cursor: pointer; 
`;

export const ChatPanelHeader = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 1.5rem;
`;
export const ChatPanelHeaderImg = styled.div``;
export const ChatPanelHeaderText = styled.div``;

export const ChatPanelBody = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-flow: wrap; */
  overflow-y: scroll;
  height: 222px;
`;
export const ChatPanelBodyText = styled.div``;



export const ChatPanelFooter = styled.div`
      display: flex;
    flex-direction: column;
`;
export const ChatPanelFooterInput = styled.input`
  min-height: 2em;
  padding: 0.25em;
  border-radius: 0.25em;
  margin-bottom: 0.25em;
  margin-top: 0.95em;


`;

export const ChatPanelFooterBtn = styled.div`
  width: 80%;
  padding: 0.5em;
  align-self: center;
  border-radius: 0.25em;
  margin-bottom: 0.5em;
  margin-top: 0.95em;
  background: #639493;
  color: black;
  cursor: pointer;
  text-align: center;
  &:hover {
    background: #818ff1;
  }

`;



export const ChatPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ChatMessageContainer = styled.div<{isUser: boolean}>`
  margin: 0.5em;
  background: #81f1ef;
  color: black;
  border-radius: 0.25em;
  padding: 0.25em;
  width: 80%;
  display: flex;
  flex-direction: row;
  ${({isUser}) => isUser && css`
    align-self: end;
    background: #898b9fe1;
    position: relative;
    margin-top: 0.5em;

  `}
  
`;
export const ChatMessageText = styled.div`
  padding: 0.25em;
`;
