// write a component to display terms and conditions

import React from 'react';
//
// import { Modal, ModalBody, ModalFooter } from 'reactstrap';

export const TermsAndConditions = ({isOpen}: {isOpen: boolean}) => {
  if(!isOpen) return;
  return (
    <div className="terms-and-conditions" style={{color: 'white', textAlign: 'left'}}>
      <div>
        <h2>Terms and Conditions</h2>
        <p>Last updated: July 2024</p>
        <hr />
        <h3>Introduction</h3>
        <p>
          These Terms and Conditions ("Terms") govern your use of the Dropkins community-based ride-sharing platform and any other services we provide (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you may not use our Services.
        </p>
        <h3>Acceptance of Terms</h3>
        <p>
          By using our Services, you confirm that you have read, understood, and agree to be bound by these Terms. If you are using the Services on behalf of an organization or entity, you represent that you have the authority to bind that organization to these Terms.
        </p>
        <h3>Use of Services</h3>
        <p>
          Dropkins provides a platform for community-based ride-sharing. Users must conduct themselves in a respectful and lawful manner at all times. Dropkins reserves the right to suspend or terminate your access to the Services for any violation of these Terms.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          All content and services provided by Dropkins, including but not limited to text, graphics, logos, and software, are the exclusive property of Dropkins or its licensors and are protected by copyright and other intellectual property laws.
        </p>
        <h3>Cookies</h3>
        <p>
          Our Services use "cookies" to enhance user experience. A cookie is a small piece of data sent from a website and stored on the user's computer by the user's web browser. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify, defend, and hold harmless Dropkins, its officers, directors, employees, agents, licensors, and suppliers from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the Services using your account.
        </p>
        <h3>Dispute Resolution</h3>
        <p>
          Any disputes arising out of or in connection with these Terms shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The place of arbitration shall be California.
        </p>
        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of the State of California, without giving effect to any principles of conflicts of law.
        </p>
        <h3>Changes to Terms</h3>
        <p>
          Dropkins reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          These Terms constitute the entire agreement between you and Dropkins regarding the use of our Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding such subject matter.
        </p>
        <hr />
        <p>
          By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;