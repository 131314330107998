import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import moment from "moment";
import Map from "./Map";
import Marker from "./Marker";
import StateStat, { StateOverlay } from "./StateStat";
import axios from "axios";
import { USStates, USStatesLong } from "../utils/US_states";
import firebase, {
  writeMarker,
  fireAuth,
  getUserId,
  updateStateData,
  getStateData,
  treasureMarkersSnap,
  hopeMarkersSnap,
  covidMarkersSnap,
  warningMarkersSnap,
  allMarkersSnap,
  covidMarkersSnapUGC,
  setContagionMarkersSnap,
  activeFriendsOnSnapshot,
  activateDriverMode,
  getDriverMode,
  setUsersLocation,
  getUserLocation,
  updateRideDistances,
} from "../firebase";

import { AuthContext } from "../Auth";

import useDirectionsMap from "../hooks/useMapDirections";
import { db, kinDb, kindAuth } from "../firebase-config";

import styled from "@emotion/styled";
import {
  query,
  updateDoc,
  addDoc,
  collection,
  where,
  onSnapshot,
  Firestore,
} from "firebase/firestore";
import { act } from "@testing-library/react";

const StateData = styled.div`
  display: block;
  color: black;
  padding: 14px;
  line-height: 1.5;
  max-width: 250px;
  font-size: 12px;

  font-family: Metropolis, "sans-serif";
`;

const StateDataField = styled.div`
  padding: 5px;
  /* background: #3842743b; */
`;

const StateDataFieldStat = styled.div`
  padding: 5px;

  /* color: white; */
`;

export const SpaceMap = ({
  resetMarkers,
  triggerMarkerReset,
  resetMarker,
  setResetMarker,
  setResetRideMarker,
  resetWarningMarker,
  setResetWarningMarker,
  resetActiveFriendsMarker,
  setResetActiveFriendsMarker,
  setOverlayData,
  setOverlayToggle,
  resetContagionMarker,
  setResetContagionMarker,
  setTreasurePanel,
  setHopePanelToggle,
  latestMarker,
  setLatestMarker,
  hopeData,
  setHopeData,
  setFoodBankData,
}) => {
  const {
    activeFriends,
    setActiveFriends,
    activeFriendsUpdate,
    setActiveFriendsUpdate,
    resetDriverMarker,
    setResetDriverMarker,
    driverMode,
    setDriverMode,
    startLocation,
    setStartLocation,
    destination,
    setDestination,
    activeDriverData,
    setActiveDriverData,
    ridePanelToggle,
    setRidePanelToggle,
    userData,
    setUserData,
    updatedDriverDistance,
    triggerUpdateDriverDistance,
    position,
    setPosition,
    triggerShow,
    setTriggerShow,
    customerData,
    setCustomerData,
  } = useContext(AuthContext);

  const [placeIndex, setPlaceIndex] = useState(0);
  const [bound, setBound] = useState({});
  const [zoom, setZoom] = useState(null);
  const [warningZone, setWarningZone] = useState([]);

  const [markers, setMarkers] = React.useState([]);
  const [selfMarkers, setSelfMarkers] = React.useState([]);
  const [mandatoryMarkers, setMandatoryMarkers] = React.useState([]);
  const [symptomsMarkers, setSymptomsMarkers] = React.useState([]);
  const [recoveringMarkers, setRecoveringMarkers] = React.useState([]);
  const [hopeMarkers, setHopeMarkers] = React.useState([]);

  const [watchActive, setWatchActive] = React.useState([]);
  const [lastPosition, setLastPosition] = useState(null);

  const [mapDirections, setMapDirections] = useState(false);

  const [stateToday, setStateToday] = useState({});

  const [treasures, setTreasures] = React.useState([]);

  const [contagionMarkers, setContagionMarker] = React.useState([]);
  const [contagionMarkersUGC, setContagionMarkersUGC] = React.useState([]);
  const [contagionMarkersYours, setContagionMarkersYours] = React.useState([]);

  const [warningMarkers, setWarningMarker] = useState([]);
  const [warningMarkersUGC, setWarningMarkerUGC] = useState([]);
  const [tempUGCStatus, setTempUGCStatus] = useState({});

  const [uSAStateMarkers, setUSAStateMarkers] = useState([]);
  const [concatStateMarkers, setConcatStateMarkers] = useState([]);
  const [notification, setNotification] = useState([]);
  const [fiestaMessage, setFiestaMessage] = useState([]);
  const [mapLat, setMapLat] = useState(33.6);
  const [mapLng, setMapLng] = useState(-116.6);

  const disabled = false;

  // const mapLat = markers && markers[placeIndex] ? markers[placeIndex].latitude : 20.88;
  // const mapLng = markers && markers[placeIndex] ? markers[placeIndex].longitude : -156.5;

  const resetNotifications = async () => {
    setNotification(null);
    setFiestaMessage(null);
  };
  const notifier = async (data: any) => {
    await setNotification(data);
  };
  const fiesta = async (data: any) => {
    await setFiestaMessage(data);
  };

  useEffect(() => {
    const unsubscribe = treasureMarkersSnap(setTreasures);

    return () => unsubscribe;
  }, [disabled]);

  useEffect(() => {
    // setResetDriverMarker(false)
    // console.log("active friend snap", driverMode, resetDriverMarker);
    const unsubscribe = () =>
      activeFriendsOnSnapshot(
        activeFriends,
        setActiveFriends,
        resetDriverMarker,
        setResetDriverMarker,
        driverMode
      );

    return () => unsubscribe();
  }, [driverMode]);

  const setDriverDetais = async (friend) => {
    const activeFriendsSnap = activeFriends.map(async (friend) => {
      // console.log({ friend });
      // const distance =  useDirectionsMap({startLocationHome, destination:`${friend.location?.lng},${friend.location?.lat}`});
      // return {...friend, distanceFromStart: distance}
    });
    Promise.all(activeFriendsSnap).then((results) => {
      // console.log({results})
      // setActiveFriends(results);
    });
  };

  useEffect(() => {
    // console.log({ activeFriends });
    if (activeFriends.length) {
      if (startLocation && destination) {
        let driverDistance;
        let driverRebuild;
        //  const updateActive =
        // GetDistance();
        //  console.log({driverRebuild, udm})
        // return  driverRebuild;

        // });

        // console.log({updateActive});
        // setActiveFriends(updateActive)
        const updatedFriends = Promise.all(
          activeFriends.map(async (friend, index) => {
            // const GetDistance = async () => {

            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useDirectionsMap({
              startLocation,
              destination: { lng: friend?.lng, lat: friend?.lat },
            }).then((res) => {
              // console.log({res});
              // driverDistance = Math.round(res?.text);
              driverDistance = res?.text;
              // console.log('suup',{friend, driverDistance});
              driverRebuild = { ...friend, distanceFromStart: driverDistance };
              //  console.log({driverRebuild})
              // return  driverRebuild;
              return driverRebuild;
            });
          })
        );

        updatedFriends.then(
          (result) => {
            // here you can use the result of promiseB
            // console.log({ result });
            // if(activeFriends !== result && result.length) setActiveFriends(result);
            // console.log('inn', {result})
          },
          (err) => {
            // promiseB rejected
            console.log({ err });
          }
        );

        // Promise.all(updatedFriends).then((results) => {
        //   console.log({results})
        //   // setActiveFriendsUpdate(results);
        //   console.log(updatedFriends, 'upps')
        // })
        // return() => updateActive
        // const distance =
        //   // eslint-disable-next-line react-hooks/rules-of-hooks
        //   useDirectionsMap({startLocation, destination:{lng: m.lng, lat: m.lat}});
      }
    } else {
      setActiveFriends([]);
      if (!activeFriends.length) return;
    }

    //  setDriverDetais();
  }, [startLocation, destination]);

  //   useEffect(() => {
  //     console.log({activeFriendsUpdate})
  //     // if (setActiveFriends.length && activeFriendsUpdate !== setActiveFriends) {
  //     //   setActiveFriendsUpdate(setActiveFriends);
  //     // }
  // }, [activeFriendsUpdate, activeFriends]);

  const updateFriendsSnap = async (friend) => {
    // console.log({activeFriends, activeFriends})

    // console.log({ friend });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    // const distance = await useDirectionsMap({startLocationHome, destination:`${friend.location?.lng},${friend.location?.lat}`});
    // return {...friend, distanceFromStart: distance}
  };

  useEffect(() => {
    // console.log("space map", { driverMode });

    if (!kindAuth.currentUser) return;

    const unsubscribe = () => {
      // console.log("driver mode updated grabbing geo", driverMode);
      kindAuth?.currentUser &&
        driverMode &&
        navigator.geolocation.watchPosition((position) => {
          // console.log("space map watch position", position);
          if (driverMode && navigator.geolocation) {
            // console.log("space map updating pos grabbing geo", { position });
            if (position !== lastPosition) {
              //  await setUsersLocation(position)
              setMapLat(position.coords.latitude);
              setMapLng(position.coords.longitude);
              setUsersLocation({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
              setLastPosition(position);

              if (userData?.activePassenger?.status === "accepted") {
                triggerUpdateDriverDistance(position);
              }
              // console.log("should call set users");
            }

            // console.log({position})
            // console.log("Latitude is :", position.coords.latitude);
            // console.log("Longitude is :", position.coords.longitude);
          }
        });
    };

    return () => unsubscribe();
    //
  }, [driverMode]);

  useEffect(() => {
    // console.log({ updatedDriverDistance });
    if (userData?.activePassenger?.status === "accepted") return;
    const unsubscribe = updateRideDistances(
      customerData,
      updatedDriverDistance,
      triggerUpdateDriverDistance,
      setStartLocation,
      setDestination,
      setPosition,
      setTriggerShow
    );
    return () => unsubscribe;
  }, [updatedDriverDistance, customerData?.id]);

  useEffect(() => {
    const unsubscribe = hopeMarkersSnap(setHopeMarkers);

    return () => unsubscribe;
  }, [disabled]);

  // useEffect(() => {
  //   const unsubscribe = getUserLocation(true, setMapLat, setMapLng);

  //   return () => unsubscribe;
  // }, [disabled]);

  useEffect(() => {
    //Your markers
    // setMarkers([]);
    const unsubscribe = allMarkersSnap({
      setMarkers,
      setSelfMarkers,
      setRecoveringMarkers,
      setSymptomsMarkers,
    });

    return () => unsubscribe;
  }, [resetMarkers]);

  useEffect(() => {
    const unsubscribe = covidMarkersSnap({ setContagionMarker });
    return () => unsubscribe;
  }, [disabled]);

  useEffect(() => {
    const unsubscribe = warningMarkersSnap({ setWarningMarker });
    return () => unsubscribe;
  }, [disabled]);

  // useEffect(() => {
  //   const q = query(collection(kinDb, 'warning-markers-ugc'), where("userId", "==", kindAuth.currentUser.uid ))
  //   const unsubscribe = onSnapshot(q, (snapShot) => {
  //   // const unsubscribe = kinDb
  //   // .collection('warning-markers-ugc').where("userId", "==", fireAuth.currentUser.uid )
  //   // .onSnapshot((snapShot) => {
  //     const markers = snapShot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     console.log('settingWarning Mar', {markers})
  //     setWarningMarkerUGC(markers);
  //   })
  //   return ()=> unsubscribe();

  // }, [disabled]);

  // useEffect(() => {
  //   const unsubscribe = covidMarkersSnapUGC({setContagionMarkersUGC, setTempUGCStatus});
  //   return ()=> unsubscribe;

  // }, [disabled]);

  // useEffect(() => {
  //   const unsubscribe = setContagionMarkersSnap({setContagionMarkersYours})
  //   return ()=> unsubscribe;

  // }, [disabled]);

  const triggerWarning = (warning, associatedAddress) => {
    writeMarker(
      warning.address,
      warning.longitude,
      warning.latitude,
      warning.covid,
      "warning",
      associatedAddress
    );
    setWarningZone(!warningZone);
  };

  const triggerWarningUGC = (warning, associatedAddress) => {
    setWarningZone(true);
    writeMarker(
      warning.address,
      warning.longitude,
      warning.latitude,
      warning.covid,
      "warning-ugc",
      associatedAddress
    );
  };

  // const getGeoCode = async (write = false) => {
  //   const key = 'AIzaSyAalCGPrquwmaUguvk6nGaAIvPqfoXLB7E';
  //     try {
  //           // setLoading(true);
  //           const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(location)}&key=${key}`);
  //           const result = res.data.results[0].geometry.location;
  //           // console.log(location, result.lat, result.lng)

  //           if (result.lat !== null && result.lng !== null) {

  //           }
  //           else {
  //             updateError(true);
  //           }
  //           // setLoading(false);
  //       }
  //     catch (error) {
  //       // setLoading(false);
  //       console.log(error)
  //       updateError(true);
  //     }

  // }

  // const showLove = () => {
  //   toggleLovePanel();
  // }

  useEffect(() => {
    contagionMarkers.map((m, index) => {
      const warnings = markers.filter((marker) => {
        const latRange =
          marker.latitude >= m.latitude - 0.003 &&
          marker.latitude <= m.latitude + 0.003;
        // console.log(m.address, marker.latitude, m.latitude)
        // console.log(marker.longitude, m.longitude)
        const lngRange =
          marker.longitude >= m.longitude - 0.03 &&
          marker.longitude <= m.longitude + 0.01;
        // console.log(latRange, lngRange)
        return latRange && lngRange;
      });
      warnings.length &&
        warnings.map((warning) => {
          triggerWarning(warning, warning.address);
        });
    });
  }, [markers, contagionMarkers]);

  useEffect(() => {
    contagionMarkersUGC.map((m, index) => {
      const warningsUGC = markers.filter((markerUGC) => {
        if (m.userId == fireAuth.currentUser.uid) {
          return false;
        }
        const latRange =
          markerUGC.latitude >= m.latitude - 0.003 &&
          markerUGC.latitude <= m.latitude + 0.003;
        // console.log(m.address, markerUGC.latitude, m.latitude)
        // console.log(markerUGC.longitude, m.longitude)
        const lngRange =
          markerUGC.longitude >= m.longitude - 0.03 &&
          markerUGC.longitude <= m.longitude + 0.03;
        // console.log(latRange, lngRange)
        return latRange && lngRange;
      });
      warningsUGC.length &&
        warningsUGC.map((warning) => {
          triggerWarningUGC(warning, warning.address);
        });
    });
  }, [markers, contagionMarkersUGC]);

  useEffect(() => {
    // console.log(getStateData())
    //       const stateData = getStateData() || [];
    // let stateData;
    // const currentDay = moment().format('MM/DD/YY');
    // // console.log(currentDay)
    // const yesterday = moment().subtract(1, 'days').format('MM/DD/YY');
    // console.log(currentDay)
    // const getState = db.collection('state-data').where('date', '==', currentDay).get()
    // .then( async function (res) {
    //   if(!res.empty) {
    //     const states = await res.docs.map((stateObject) => {
    //       const stateData = stateObject.data();
    //       console.log(stateData)
    //       const { state, positive, negative, recovered, hospitalizedCurrently,casesPerOneMillion, activePerOneMillion, lat, lng } = stateData;
    //       return (<StateStat
    //                 key={state}
    //                 position={{lat, lng}}
    //                 // content={`<img id="parrot" src="https://cultofthepartyparrot.com/parrots/hd/parrot.gif">`}
    //                 // content={``}
    //                 events={{
    //                   onClick: () => console.log(`overlay clicked`),
    //                   onZoomChanged: arg => setZoom(arg),
    //                 }}
    //               >
    //                 <StateData className='wing'>
    //                 <StateDataField>{state}</StateDataField>
    //                 <StateDataFieldStat> {casesPerOneMillion / 10000 } in 1000 people have covid-19 </StateDataFieldStat>
    //                 {/* {positive && <StateDataField> {positive} confirmed cases with {negative} who tested negative.  </StateDataField>}
    //                 {recovered && <StateDataField>{recovered} people have recovered with {hospitalizedCurrently} currently in icu</StateDataField>} */}
    //                 </StateData>
    //               </StateStat>)
    //           })
    //           setUSAStateMarkers( states);
    //     };
    //     // console.log(states)
    //     // stateData = states;
    //     // console.log(stateData, yesterday)
    //     // return res.docs.map((state) => state.data());
    // //   }
    // })
    // console.log(states, yesterday)
    //  const StateStatus =  stateData.map((result) => {
    //   console.log({result})
    //   const { state, positive, negative, recovered, hospitalizedCurrently, lat, lng } = result;
    //   return (<StateStat
    //             key={state}
    //             position={{lat, lng}}
    //             // content={`<img id="parrot" src="https://cultofthepartyparrot.com/parrots/hd/parrot.gif">`}
    //             // content={``}
    //             events={{
    //               onClick: () => console.log(`overlay clicked`),
    //               onZoomChanged: arg => setZoom(arg),
    //             }}
    //           >
    //             <StateData className='wing'>
    //             <StateDataField>{state}</StateDataField>
    //             {positive && <StateDataField> {positive} confirmed cases with {negative} who tested negative.  </StateDataField>}
    //             {recovered && <StateDataField>{recovered} people have recovered with {hospitalizedCurrently} currently in icu</StateDataField>}
    //             </StateData>
    //           </StateStat>)
    //       })
    //       console.log(StateStatus)
  }, [disabled]);

  // useEffect(() => {
  //   fetch('https://disease.sh/v2/states?sort=todayDeaths').then(dRes => dRes.json()).then( (dResults) => {

  //   fetch('https://disease.sh/v2/states?sort=todayDeaths&yesterday=1').then(dRes => dRes.json()).then( (yesterdayResults) => {
  //     console.log(dResults, yesterdayResults);
  //     // fetch("https://covidtracking.com/api/v1/states/current.json")
  //     // .then(res => res.json())
  //     // .then( async (results) => {
  //         // setIsLoaded(true);
  //        const StateStatus =  dResults.map((result) => {

  //            const { state, positive, negative, recovered, hospitalizedCurrently } = result;

  //           const stateGeo = USStatesLong[state] || {};

  //           const filterState = dResults.filter((drez) => drez.state === state );
  //           const matchState = filterState && filterState[0];
  //           console.log(matchState)
  //           // console.log( dResults.filter((drez) => drez.state === state ));
  //           if (stateGeo.lat !== null && stateGeo.lng !== null) {
  //             const stateContent = {
  //               state,
  //               lng: stateGeo.lng,
  //               lat: stateGeo.lat,
  //               // positive,
  //               // todayCases: matchState.todayCases || 0,
  //               // negative,
  //               // recovered,
  //               // hospitalizedCurrently,
  //               ...matchState,
  //             }

  //             updateStateData(stateContent);

  //             const {  lat, lng, } = stateContent;
  //             return (<StateStat
  //                 key={state}
  //                 position={{lat, lng}}
  //                 // content={`<img id="parrot" src="https://cultofthepartyparrot.com/parrots/hd/parrot.gif">`}
  //                 // content={``}
  //                 events={{
  //                   onClick: () => console.log(`overlay clicked`),
  //                   onZoomChanged: arg => setZoom(arg),
  //                 }}
  //               >
  //                 <StateData className='wing'>
  //                 <StateDataField>{state}</StateDataField>
  //                 {positive && <StateDataField> {positive} confirmed cases with {negative} who tested negative.  </StateDataField>}
  //                 {recovered && <StateDataField>{recovered} people have recovered with {hospitalizedCurrently} currently in icu</StateDataField>}
  //                 </StateData>
  //               </StateStat>)
  //             ;

  //           }

  //         })

  //         // console.log(StateStatus)
  //         setUSAStateMarkers( StateStatus);
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         // setIsLoaded(true);
  //         // setError(error);
  //       }
  //     )

  //   setStateToday(dResults); return dResults;

  //     // })

  // }

  //   )

  // }, [])

  const getContagionMarkers = contagionMarkers.map((m, index) => {
    // console.log(index)
    return (
      <Marker
        key={m.id}
        active={placeIndex === index}
        title={"contagion: " + m.id}
        type={"covid"}
        // resetContagionMarker={resetContagionMarker}
        position={{ lat: m.latitude + 0.0001, lng: m.longitude + 0.0001 }}
        events={{
          onClick: () => console.log(`marker ${m.address} clicked`),
        }}
      />
    );
  });

  const getFoodBanks = (hopeKey) => {
    setFoodBankData([]);
    if (hopeKey) {
      //  db.collection('foodBanks').where('foodBankCampaignKey', '==', hopeKey)
      // .onSnapshot((snapShot) => {
      //   const foods = snapShot.docs.map((doc) => ({
      //     id: doc.id,
      //     ...doc.data(),
      //   }));
      //   setFoodBankData(foods);
      //   // console.log('settingContagionYours Mar', {markers})
      // })
    }
  };

  // const getWarningMarkers = warningMarkers.map((m, index) => {
  //   // console.log('trying to rerender warningmarkers')
  //   return (
  //     <Marker
  //       key={m.id}
  //       active={placeIndex === index}
  //       title={"marker: " + m.address}
  //       type={'warning'}
  //       resetWarningMarker={resetWarningMarker}
  //       position={{ lat: m.latitude, lng: m.longitude }}
  //       events={{
  //         onClick: () => window.alert(`marker ${m.address} clicked`)
  //       }}
  //     />
  // )})

  // const getWarningMarkersUGC = warningMarkersUGC.map((m, index) => {
  //   // console.log('trying to rerender warningmarkers')
  //   return (
  //     <Marker
  //       key={m.id}
  //       active={placeIndex === index}
  //       title={"marker: " + m.address}
  //       type={'warning-ugc'}
  //       resetWarningMarker={resetWarningMarker}
  //       position={{ lat: m.latitude, lng: m.longitude }}
  //       events={{
  //         onClick: () => window.alert(`marker ${m.address} clicked`)
  //       }}
  //     />
  // )})

  const getContagionMarkersYours = contagionMarkersYours.map((m, index) => {
    // console.log(index)
    return (
      <Marker
        key={m.id}
        active={placeIndex === index}
        title={"contagion: " + m.id}
        type={"covid-yours"}
        resetContagionMarker={resetContagionMarker}
        address={m.address}
        position={{ lat: m.latitude + 0.0001, lng: m.longitude + 0.0001 }}
        events={{
          onClick: async () => {
            await setOverlayData({
              address: m.address,
              showLoveContainer: true,
              markerId: m.id,
              userId: m.userId,
            });
            await setOverlayToggle(true);
            // window.alert(`marker ${m.address} clicked`);
          }, //showLove(m.id);}
        }}
      />
    );
  });

  const getContagionMarkersUGC = contagionMarkersUGC.map((m, index) => {
    // console.log(index)
    // console.log({m})
    return (
      <Marker
        key={m.id}
        active={placeIndex === index}
        title={"contagion: " + m.id}
        type={m.status}
        // resetContagionMarker={resetContagionMarker}
        address={m.address}
        position={{ lat: m.latitude + 0.0001, lng: m.longitude + 0.0001 }}
        events={{
          onClick: async () => {
            await setOverlayData({
              address: m.address,
              showLoveContainer: true,
              markerId: m.id,
              userId: m.userId,
            });
            await setOverlayToggle(true);
            // window.alert(`marker ${m.address} clicked`);
          }, //showLove(m.id);}
        }}
      />
    );
  });
  // console.log(getContagionMarkers)

  // const getUSAStateMarkers =

  return (
    <Map
      zoom={10}
      center={{ lat: mapLat, lng: mapLng }}
      events={{
        onBoundsChanged: (arg) => setBound(arg),
        onZoomChanged: (arg) => setZoom(arg),
      }}
      mapHook={latestMarker}
      // mapDirections={mapDirections}
    >
      {/* {useDirectionsMap({mapDirections, startLocation, destination})} */}
      {uSAStateMarkers &&
        uSAStateMarkers.map((states) => {
          return states;
        })}
      {/* {contagionMarkersYours && getContagionMarkersYours} */}
      {contagionMarkers && getContagionMarkers}

      {/* {warningMarkers && getWarningMarkers} */}
      {contagionMarkersUGC && getContagionMarkersUGC}
      {/* {warningMarkersUGC && getWarningMarkersUGC} */}
      {markers &&
        markers.map((m, index) => {
          // console.log('trying to re-render markers', {markers})

          return (
            <Marker
              key={m.id + index}
              active={placeIndex === index}
              title={"marker id: " + m.id}
              type={{
                mandatoryQuarantine: m.mandatoryQuarantine,
                selfQuarantined: m.selfQuarantined,
                showingSymptoms: m.showingSymptoms,
                covidPositive: m.covidPositive,
              }}
              resetMarker={resetMarker}
              address={m.address}
              position={{ lat: m.latitude, lng: m.longitude }}
              events={{
                onClick: () => console.log(`marker ${m.address} clicked`),
              }}
            />
          );
        })}

      {selfMarkers &&
        selfMarkers.map((m, index) => {
          // console.log('trying to re-render markers', {markers})

          return (
            <Marker
              key={m.id + index}
              active={placeIndex === index}
              title={"marker id: self" + m.id}
              type={{
                mandatoryQuarantine: m.mandatoryQuarantine,
                selfQuarantined: m.selfQuarantined,
                showingSymptoms: m.showingSymptoms,
                covidPositive: m.covidPositive,
              }}
              resetMarker={resetMarker}
              address={m.address}
              position={{ lat: m.latitude, lng: m.longitude }}
              events={{
                onClick: () => console.log(`marker ${m.address} clicked`),
              }}
            />
          );
        })}

      {mandatoryMarkers &&
        mandatoryMarkers.map((m, index) => {
          // console.log('trying to re-render markers', {markers})

          return (
            <Marker
              key={m.id + index}
              active={placeIndex === index}
              title={"marker id: mandatory" + m.id}
              type={{
                mandatoryQuarantine: m.mandatoryQuarantine,
                selfQuarantined: m.selfQuarantined,
                showingSymptoms: m.showingSymptoms,
                covidPositive: m.covidPositive,
              }}
              resetMarker={resetMarker}
              address={m.address}
              position={{ lat: m.latitude, lng: m.longitude }}
              events={{
                onClick: () => console.log(`marker ${m.address} clicked`),
              }}
            />
          );
        })}

      {symptomsMarkers &&
        symptomsMarkers.map((m, index) => {
          // console.log('trying to re-render markers', {markers})

          return (
            <Marker
              key={m.id + index}
              active={placeIndex === index}
              title={"marker id: symptoms" + m.id}
              type={{
                mandatoryQuarantine: m.mandatoryQuarantine,
                selfQuarantined: m.selfQuarantined,
                showingSymptoms: m.showingSymptoms,
                covidPositive: m.covidPositive,
              }}
              resetMarker={resetMarker}
              address={m.address}
              position={{ lat: m.latitude, lng: m.longitude }}
              events={{
                onClick: () => console.log(`marker ${m.address} clicked`),
              }}
            />
          );
        })}

      {/* {recoveringMarkers && recoveringMarkers.map((m, index) => {
          // console.log('trying to re-render markers', {markers})

          return (
            <Marker
              key={m.id+index}
              active={placeIndex === index}
              title={"marker id: recovering" + m.id}
              type={{
                mandatoryQuarantine: m.mandatoryQuarantine,
                selfQuarantined: m.selfQuarantined,
                showingSymptoms: m.showingSymptoms,
                covidPositive: m.covidPositive,
              }}
              resetMarker={resetMarker}
              address={m.address}
              position={{ lat: m.latitude, lng: m.longitude }}
              events={{
                onClick: () => console.log(`marker ${m.address} clicked`)
              }}
            />
        )})} */}

      {/* {hopeMarkers.map((m, index) => {
          // console.log('trying to re-render markers')
          return (
            <Marker
              key={m.id}
              active={placeIndex === index}
              title={"hope id: " + m.id}
              type={'hopeMarker'}
              // resetMarker={resetMarker}
              address={m.address}
              position={{ lat: m.latitude, lng: m.longitude }}
              events={{
                onClick: async () => {
                  setHopeData({});
                  await setHopeData({
                    title: m.hopeTitle,
                    subTitle: m.hopeSubTitle,
                    address: m.location,
                    hopeKey: m.hopeKey,
                    showHopeContainer: true,
                    time: m.time,
                    hopeId: m.id,
                    showForm: m.formActive,

                  });
                  getFoodBanks(m.hopeKey);
                  setHopePanelToggle(true)
                  // console.log(`marker ${m.address} ${m.selectedItem} clicked`);

                }
              }}
            />
        )})} */}

      {activeFriends?.map((m, index) => {
        // console.log('friends ma', m)

        // console.log('active mapp location', resetDriverMarker, activeFriends)
        return (
          <Marker
            key={m.id + index}
            active={placeIndex === index}
            title={"ride id: " + m.friendUserName}
            type={"ride"}
            // resetMarker={resetRideMarker}
            resetDriverMarker={
              resetDriverMarker == "hide" || m.resetDriverMarker === "hide"
            }
            address={m.address}
            position={{ lat: m.lat, lng: m.lng }}
            events={{
              onClick: async () => {
                // setHopeData({});
                await setActiveDriverData({
                  title: `${m.friendUserName} is driving`,
                  address: m.location,
                  time: m.time,
                  driverId: m.id,
                });
                setRidePanelToggle(true);
                // console.log(`marker ${m.address} ${m.selectedItem} clicked`);
              },
            }}
          />
        );
      })}

      {treasures.map((m, index) => {
        // console.log('trying to re-render markers')
        return (
          <Marker
            key={m.id}
            active={placeIndex === index}
            title={"treasure id: " + m.id}
            type={m.selectedItem}
            resetMarker={resetMarker}
            address={m.address}
            position={{ lat: m.latitude, lng: m.longitude }}
            events={{
              onClick: async () => {
                await setOverlayData({
                  title: m.resourceName,
                  address: m.location,
                  phone: m.resourcePhone,
                  url: m.resourceUrl,
                  details: m.resourceDetails,
                  selectedItem: m.selectedItem,
                  showTreasureContainer: true,
                  time: m.time,
                  treasureId: m.id,
                });
                setOverlayToggle(true);
                setTreasurePanel(true);
                // console.log(`marker ${m.address} ${m.selectedItem} clicked`);
              },
            }}
          />
        );
      })}
    </Map>
  );
};

export default SpaceMap;
