import React, {  } from 'react';
// import { useLocation } from 'react-router-dom';
// import { withRouter } from '../utils/withRouter';
// import { AuthContext } from '../Auth';
// import { httpsCallable } from "@firebase/functions";
// import {
//   kinDb,
//   appFirebase,
//   providers,
//   db,
//   kindAuth,
//   messaging,
//   cloudFunctions,
// } from "../firebase-config";
import styled from '@emotion/styled';
import * as ui from '../../../../styles/uiVars';
import { keyframes } from '@emotion/react';
import {
  LinkBtn,
  DashboardPanel,
  InviteForm,
  InputContainer,
  LoadingContainer,
  LoadingSpinner,
  FullWidthContainer,
} from "../../../../styles/shared";

export const HowItWorksContainer = styled.div`
   display: flex;
   background: #070707;
   padding: 3em;

   @media screen and (max-width: ${ui.tablet}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 3.3em; */
    
  }
`;
    

const Headline = styled.div`
  color: var(--Schemes-On-Primary, #FFF);
  /* color: #040404; */
font-family: "Inter", sans-serif;
font-size: 6em;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
font-style: normal;
font-weight: 500;
text-align: left;
line-height: 100px; /* 83.333% */
margin-bottom: 0.4em;

@media screen and (max-width: ${ui.desktop}) {
  font-size: 3em;
  line-height: 1.2;
  /* padding: 0.5em;  */
  
}
`;

 const HowItWorksContent = styled.div`
  color: #FFF;
  font-family: 'Inter' sans-serif;
  font-size: 1.5em;
  text-align: left;
  color: var(--Schemes-On-Primary, #FFF);
margin-bottom: 1em;
font-size: 20px;
font-style: normal;
font-weight: 500;
/* add dropshadow */
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
line-height: 32px; /* 160% */
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;  
 const HowItWorksImageWrapper = styled.div`
  width: 467px;
  display: flex;
  height: 467px;
  flex-basis: 50%;
  background: url('/images/dropkins/landing/middle/dropkins_how_it_works.png') no-repeat;
  background-size: contain;

  
  animation: ${floatAnimation} 4s infinite;  
  @media screen and (max-width: ${ui.tablet}) {
    min-height: 360px;
    background: url('/images/dropkins/landing/middle/dropkins_how_it_works.png') no-repeat;
    background-size: contain;
    background-position: center;
  align-self:normal;
  width: auto;
  }
`;

 const HowItWorksContentContainer = styled.div`
  max-width: 80%;
  
 `;
 const HowItWorksContentWrapper = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  align-items: center;
`;

 const HowItWorksImage = styled.img`
    width: 100%;

`;

export const HowItWorks = ({goToSignUp}) => {


  return (
    <HowItWorksContainer>
      <HowItWorksContentWrapper>
      <HowItWorksContentContainer>

      <Headline>
        How It Works
      </Headline>

          {/* <HowItWorksContent>
          Unlike traditional ride-sharing, Dropkins connects you with a trusted network of friends and community members for safer, more enjoyable rides.
          <br /><br />
           Imagine always knowing your driver and passengers, creating a community-based, friendly travel experience. 
          </HowItWorksContent> */}
          <HowItWorksContent>

          <strong>Trust & Safety:</strong> Dropkins enables us to connect and request rides from people we already know and trust.
          </HowItWorksContent>
          <HowItWorksContent>
          <strong>Opportunities:</strong> Dropkins was designed to create earning opportunities like paid rides, and passive income within your communities. 

          </HowItWorksContent>
          <HowItWorksContent>
          <strong>Support your community:</strong> Chances are your friend could use that opportunity, and Dropkins helps you connect.
          <LinkBtn onClick={goToSignUp}> Okay, Let's ride! </LinkBtn>
          </HowItWorksContent>
         
          {/* <HowItWorksContent>
            <ol>
              <li>Earning Opportunities</li>
              <li>Safe</li>
              <li>Fosters Community</li>
            </ol>

          </HowItWorksContent> */}

      </HowItWorksContentContainer>
      </HowItWorksContentWrapper>
      <HowItWorksImageWrapper>
        {/* <HowItWorksImage  /> */}
      </HowItWorksImageWrapper>
    </HowItWorksContainer>
  );
};

export default HowItWorks;
